import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import Message from "../components/Message";

import { newsletterElementList as elementList } from "../components/data";
import { listMediaFiles } from "../actions/mediaActions";

const CreateNewsletterScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const [elements, setElements] = useState(
    JSON.parse(localStorage.getItem("newsletterElements")) || [],
  );

  const [newsletterBanner, setNewsletterBanner] = useState(
    JSON.parse(localStorage.getItem("newsletterBanner")) || ``,
  );

  const [elementType, setElementType] = useState(``);
  const [elementContent, setElementContent] = useState(``);
  const [elementLinkInterface, setElementLinkInterface] = useState(``);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (elementType === "image") {
      if (!files || files.length === 0) {
        dispatch(listMediaFiles());
      }
    }
    // eslint-disable-next-line
  }, [dispatch, elementType]);

  const resetFields = () => {
    setElementType(``);
    setElementContent(``);
  };

  const removeElement = (e) => {
    e.preventDefault();
    setElements(
      elements.filter((element) => element.elementId !== e.target.id),
    );
  };

  const addElement = (e) => {
    e.preventDefault();

    if (elementType?.length && elementContent?.length) {
      const element = {
        elementId: Date.now().toString(),
        elementType,
        elementContent,
      };

      if (elementType === "link") {
        element.elementLinkInterface = elementLinkInterface;
      }

      setElements([...elements, element]);

      //
      hideMenu(e);
      resetFields();
    }
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  const elementTypeHandler = (e) => {
    e.preventDefault();
    setElementType(e.target.id);
    displayMenu(e);
  };

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const elementLinkInterfaceHandler = (e) => {
    e.preventDefault();
    setElementLinkInterface(e.target.value);
  };

  useEffect(() => {}, [files]);

  useEffect(() => {
    if (newsletterBanner && newsletterBanner?.length) {
      localStorage.setItem(
        "newsletterBanner",
        JSON.stringify(newsletterBanner),
      );
    }
  }, [newsletterBanner]);

  useEffect(() => {
    if (elements && elements?.length) {
      localStorage.setItem("newsletterElements", JSON.stringify(elements));
    }
  }, [elements]);

  useEffect(() => {}, [loading, error]);

  useEffect(() => {
    if (!userInfo || userInfo.type !== "admin") {
      history.push("/");
    }
  }, [userInfo, history]);

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listMediaFiles());
  };

  const elementInlineImageUploadHandler = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      setLoading(true);
      const { data } = await axios.post("/api/upload", formData, config);

      if (data) {
        setElementContent(data);
      }

      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      if (data) {
        setNewsletterBanner(data);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const [displayWindow, setDisplayWindow] = useState(false);

  const displayMenu = (e) => {
    e.preventDefault();
    setElementContent(``);
    setDisplayWindow(true);
  };

  const hideMenu = (e) => {
    e.preventDefault();
    setDisplayWindow(false);
    setElementContent(``);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {error && <Message body={error} />}
      {errorFiles && <Message body={errorFiles} />}
      <div className="newsletter-content">
        <div
          className="popup"
          style={
            displayWindow === true
              ? {
                  display: "flex",
                }
              : displayWindow === false
              ? {
                  display: "none",
                }
              : ""
          }
        >
          <div className="overlay"></div>
          <div className="container">
            <div className="popup-content">
              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  type={elementType === "divider" ? "number" : "text"}
                  onChange={elementContentHandler}
                  placeholder={
                    elementType === "divider"
                      ? "enter divider value in pixels"
                      : elementType === "image"
                      ? "enter image url"
                      : elementType === "link"
                      ? "enter link url"
                      : `enter ${elementType} content`
                  }
                  min="0"
                  className="experience-new-name"
                  value={elementContent}
                />
                {elementType === "link" && (
                  <input
                    type="text"
                    placeholder="text to show"
                    className="experience-new-name"
                    onChange={elementLinkInterfaceHandler}
                    value={elementLinkInterface}
                  />
                )}
                {elementType === "image" && (
                  <>
                    <input
                      type="file"
                      className="experience-new-name"
                      onChange={elementInlineImageUploadHandler}
                      id="upload-inline-image"
                      style={{ display: "none" }}
                    />
                    <label htmlFor="upload-inline-image" className="btn">
                      browse file
                    </label>
                    <button className="btn" onClick={getMediaFiles}>
                      refresh list
                    </button>
                  </>
                )}
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <button
                  className="btn"
                  onClick={addElement}
                  style={{ marginTop: "20px" }}
                >
                  insert
                </button>
                <button
                  className="btn"
                  onClick={hideMenu}
                  style={{ marginTop: "20px" }}
                >
                  cancel
                </button>
              </div>
              {files && files?.length && (
                <div className="media-lib">
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="media-file"
                      style={{ backgroundImage: `url(${file}` }}
                      id={file}
                      onClick={mediaFileUrlHandler}
                    ></div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="toolbar">
          <div className="elements">
            <h5 style={{ width: "100%", marginBottom: "10px" }}>
              insert an element
            </h5>
            {elementList &&
              elementList.map((element, index) => (
                <button
                  key={index}
                  id={element}
                  className="btn"
                  onClick={elementTypeHandler}
                >
                  {element}
                </button>
              ))}
          </div>
          <div style={{ width: "100%" }}>
            <button
              style={{
                fontWeight: "400",
                width: "100%",
              }}
              className="btn"
              onClick={submitHandler}
            >
              submit newsletter
            </button>
          </div>
        </div>
        <div className="view-port">
          <div
            className="banner"
            style={
              newsletterBanner && newsletterBanner?.length
                ? {
                    backgroundImage: `url(${newsletterBanner}`,
                  }
                : {
                    backgroundColor: "var(--var-color-vrc-beige)",
                  }
            }
          >
            <input id="banner" type="file" onChange={uploadFileHandler} />
            <label htmlFor="banner" className="edit-banner">
              <img alt="" src="/images/vrc-icons-beige-edit.png" />
            </label>
            {loading === true && <Loader />}
            {loadingFiles && <Loader />}
          </div>
          <div className="newsletter-container">
            {elements && elements?.length && (
              <>
                {elements.map((element, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    {element.elementType === "heading" ? (
                      <h1 id={element.elementId}>{element.elementContent}</h1>
                    ) : element.elementType === "subheading" ? (
                      <h4 id={element.elementId}>{element.elementContent}</h4>
                    ) : element.elementType === "paragraph" ? (
                      <p id={element.elementId}>{element.elementContent}</p>
                    ) : element.elementType === "bullet point" ? (
                      <li id={element.elementId}>{element.elementContent}</li>
                    ) : element.elementType === "image" ? (
                      <div
                        id={element.elementId}
                        style={{
                          backgroundImage: `url(${element.elementContent})`,
                        }}
                        className="img"
                      ></div>
                    ) : element.elementType === "link" ? (
                      <a
                        href={element.elementContent}
                        id={element.elementId}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {element.elementLinkInterface || "click here"}
                      </a>
                    ) : element.elementType === "divider" ? (
                      <div
                        id={element.elementId}
                        style={{ height: `${element.elementContent}px` }}
                      ></div>
                    ) : (
                      ""
                    )}
                    <button
                      id={element.elementId}
                      onClick={removeElement}
                      className="remove-element"
                    >
                      <img
                        id={element.elementId}
                        src="/images/vrc-icons-beige-false.png"
                        alt=""
                      />
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewsletterScreen;
