import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tokenLogin } from "../actions/userActions";

import Loader from "../components/Loader";
import Message from "../components/Message";

const TokenLoginScreen = ({ location, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const [token, setToken] = useState("");
  const [type, setType] = useState("");

  const handleProceed = () => {
    history.push("/account");
  };

  useEffect(() => {
    setType(
      location.pathname.split("/")[location.pathname.split("/").length - 2],
    );
    setToken(
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    );

    if (type?.length && token?.length) {
      dispatch(tokenLogin(token, type));
    }
  }, [location, type, token, dispatch]);

  return (
    <div
      className="activation landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        <div className="activation-content">
          {loading && <Loader />}
          {error && <Message body={error} />}
          {userInfo && (
            <>
              <p>{`Hi ${userInfo.name}, welcome to vrvclassroom`}</p>
              <button
                className="btn"
                onClick={handleProceed}
                style={{ marginTop: "20px" }}
              >
                proceed
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenLoginScreen;
