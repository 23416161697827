import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteBooking, listAllBookings } from "../actions/bookingActions";
import { BOOKING_DELETE_RESET } from "../constants/bookingConstants";

import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AllBookingsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const bookingListAllBookings = useSelector(
    (state) => state.bookingListAllBookings,
  );
  const { loading, error, bookings } = bookingListAllBookings;

  const bookingDelete = useSelector((state) => state.bookingDelete);
  const { loading: loadingDelete, error: errorDelete, success } = bookingDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || (userInfo && userInfo.type !== "admin")) {
      history.push("/");
    } else {
      dispatch(listAllBookings());
    }
  }, [dispatch, history, userInfo]);

  const deleteBookingHandler = (e) => {
    e.preventDefault();
    dispatch(deleteBooking(e.target.id));
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: BOOKING_DELETE_RESET });
        dispatch(listAllBookings());
      }, 3000);
    }
  }, [success, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        {loadingDelete && <Loader />}
        {errorDelete && <Message body={errorDelete} />}
        {success && (
          <Message body={"Booking deleted successfully!"} type="info" />
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : bookings && bookings?.length ? (
          <>
            <h5 style={{ marginBottom: "20px" }}>
              all mails ({bookings.length})
            </h5>
            <div className="user-list">
              {bookings.map((booking, index) => (
                <div style={{ display: "flex", gap: "10px" }} key={index}>
                  <Link to={`/booking/${booking._id}`} className="btn">
                    <span style={{ width: "20%" }}>{booking._id}</span>
                    <span style={{ width: "10%" }}>
                      {booking.bookingItems[0].date}
                    </span>
                    <span style={{ width: "5%" }}>${booking.totalPrice}</span>
                    <span style={{ width: "50%" }}>
                      {booking.bookingItems[0].name.substring(0, 50)}
                      {booking.bookingItems[0].name.length > 50 && "..."}
                    </span>
                    <span
                      style={{
                        color: booking.isDeclined
                          ? "var(--var-color-vrc-red)"
                          : booking.isPaid
                          ? "var(--var-color-vrc-green)"
                          : booking.isConfirmed
                          ? "var(--var-color-vrc-yellow)"
                          : !booking.isConfirmed
                          ? "var(--var-color-vrc-beige)"
                          : "var(--var-color-vrc-beige)",
                        width: "20%",
                      }}
                    >
                      {booking.isDeclined
                        ? "declined"
                        : booking.isPaid
                        ? "set"
                        : booking.isConfirmed
                        ? "confirmed"
                        : !booking.isConfirmed
                        ? "pending confirmation"
                        : ""}
                    </span>
                  </Link>
                  {userInfo.type === "admin" && (
                    <span
                      className="btn"
                      style={{ width: "fit-content" }}
                      onClick={deleteBookingHandler}
                      id={booking._id}
                    >
                      x
                    </span>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="no-messages">No bookings available</p>
        )}
      </div>
    </div>
  );
};

export default AllBookingsScreen;
