import React, { useState, useEffect } from "react";

import Landing2 from "../components/Landing2";

const ForTeachersScreen = () => {
  const [exp, setExp] = useState("virtual");

  const [virtualActivitiy, setVirtualActivity] = useState("virtual");
  const [personActivitiy, setPersonActivity] = useState("");

  const virtualSwitch = () => {
    if (exp !== "virtual") {
      setExp("virtual");
      setVirtualActivity("virtual");
      setPersonActivity(null);
    }
  };

  const personSwitch = () => {
    if (exp !== "in-person") {
      setExp("in-person");
      setPersonActivity("in-person");
      setVirtualActivity(null);
    }
  };

  useEffect(() => {}, [exp]);

  return (
    <>
      <Landing2
        bg="innovative"
        height="1080px"
        position="40%"
        horizontal="flex-start"
        vertical="center"
        heading="Innovative Solutions for BC Teachers"
        copy="We are here to support your class. Explore the features that we have developed to make your work easier and to make your students enjoy learning even more!"
        btn="Register Today"
        link="account"
        color="beige"
        cColor="beige"
      />
      <div
        style={{
          backgroundImage: "url(./images/landing-snapshot.png)",
          backgroundRepeat: "norepeat",
          backgroundSize: "cover",
          height: "fit-content",
        }}
      >
        <Landing2
          height="77.77vh"
          bg="search"
          position="35%"
          horizontal="flex-end"
          vertical="center"
          heading="Advanced Search Tools"
          hWidth="550px"
          copy="We heard over and over from our teacher community that finding relevant content was the hardest part. We heard those concerns and put out development team to work creating a custom Curriculum Search Tool that integrates a full suite of search topics including the provincial curriculum. Now you can search by subject, location, theme, Big Ideas, Core Competencies, Curriculum Competencies, and Prescribed Learning Outcomes (PLOs). Classroom Voyager’s dedicated curriculum team works with our partners every step of the way to ensure every experience or learning resource is directly aligned with the provincial curriculum."
          cWidth="750px"
          color="yellow"
          cColor="beige"
        />
        <Landing2
          height="77.77vh"
          horizontal="flex-start"
          vertical="flex-start"
          heading="One Source For Field Trips and Presentations"
          hWidth="700px"
          copy="Our Experience Catalogue is a centralized hub of all the exciting field trips and classroom presentations that are offered by museums, heritage sites, Indigenous cultural centres, science centres, and community groups across BC. Our partnership team is making sure that the most engaging of experiences are listed on our system, ready for your class to enjoy. No more endless searching online and scrolling through hundreds of individual sites, we are bringing the field trips to you in one easy-to-use platform to make your life easier."
          cWidth="800px"
          color="red"
          cColor="beige"
          btn="Virtual Experiences"
          link="experiences"
          btn2="In-Person Experiences"
          link2="experiences"
        />
      </div>
      <div
        style={{
          padding: "60px 0",
          backgroundImage: "url(./images/landing-generic.png)",
          backgroundRepeat: "norepeat",
          backgroundSize: "cover",
          height: "fit-content",
        }}
      >
        <div className="container">
          <h1
            style={{
              color: `var(--var-color-vrc-yellow)`,
              // width: `700px`,
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            Our Booking System, How Does It Work?
          </h1>
          <div className="for-teacher-controller">
            <div>
              <button
                onClick={virtualSwitch}
                className={virtualActivitiy && "active"}
              >
                Virtual Experiences
              </button>
              <button
                onClick={personSwitch}
                className={personActivitiy && "active"}
              >
                In-Person Experiences
              </button>
            </div>
            <div
              className="content"
              style={{
                backgroundColor: "var(--var-color-vrc-beige)",
              }}
            >
              <p>
                {exp === "virtual"
                  ? `No more downloading new apps or software. Everything you need to book, organize, and view your virtual field trip is managed directly in the Classroom Voyager platform. After you have found the right experience to showcase your specific curriculum point, you will make your booking and pay for the experience. Our partner will receive a message about the booking with your classes details and confirm the date with you. When the time comes to take your class on their virtual field trip, simply sign into your account and activate the link for the video call. The leader of your field trip will do the same and off you go. Since these experiences are live-streamed, your class will be able to ask questions and engage with the content in real time.`
                  : exp === "in-person"
                  ? `Just like virtual experiences, everything you need to book, organize, and view your in-person field trip is managed directly in the Classroom Voyager platform. After you search for, book, and confirm your booking, you will be connected with the leader of your field trip in order to discuss any specifics for the day. If there are special needs for your class or you need to make travel arrangements, this is the time to do so. Our partners are happy to connect with you and answer any questions you may have in preparation for your field trip. Whether there are parking requirements, meal considerations, or other details to work out, you will be able to all from within your account.`
                  : ""}
              </p>
            </div>
          </div>
          <Landing2
            height="520px"
            bg="database"
            position="15%"
            horizontal="flex-start"
            vertical="center"
            heading="A Database of Digital Teaching Resources"
            copy={`A resource unlike any other. We are working with our partners across the province to adapt and develop educational content into digital forms. Each of these digital resources is directly connected to BC curriculum by Curriculum Coordinators that live and breath Provicial curriculum. The database compliments the bookings system by offering prerecorded content that doesn’t need to have a partner present for your class to utilize the resource. Content published to the database can take the form of engaging virtual tours, scavenger hunts, interactive quizzes, video presentations, activity books, and digital workbooks. Each is meant to bring the curriculum to life and make learning fun.

            Access to this database is by student license and each of your students will have their own account to access their activities, explore on their own, and track their progress. Assign homework, track progress of your students, and share fun activities as a class, all created by experts in their fields.
            `}
            cWidth="700px"
            btn="contact"
            link="contact"
            color="yellow"
            cColor="beige"
          />
        </div>
      </div>
    </>
  );
};

export default ForTeachersScreen;
