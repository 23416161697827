import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import AccountForm from "../components/AccountForm";
import Dashboard from "../components/Dashboard";

const AccountScreen = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      if (userInfo.type === "teacher") {
        history.push("/catalogue");
      } else if (userInfo.type === "admin" || userInfo.type === "reviewer") {
        history.push("/admin");
      }
    }
  }, [userInfo, history]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        {userInfo ? <Dashboard /> : <AccountForm />}
      </div>
    </div>
  );
};

export default AccountScreen;
