const cities = [
  ["100 MILE HOUSE", "BC"],
  ["108 MILE RANCH", "BC"],
  ["150 MILE HOUSE", "BC"],
  ["70 MILE HOUSE", "BC"],
  ["ABBEY", "SK"],
  ["ABBOTSFORD", "BC"],
  ["ABEE", "AB"],
  ["ABERCORN", "QC"],
  ["ABERDEEN", "SK"],
  ["ABERNETHY", "SK"],
  ["ACADIA VALLEY", "AB"],
  ["ACADIE SIDING", "NB"],
  ["ACADIEVILLE", "NB"],
  ["ACHESON", "AB"],
  ["ACME", "AB"],
  ["ACTON VALE", "QC"],
  ["ACTON", "ON"],
  ["ADAMS GULCH", "NB"],
  ["ADAMSVILLE", "NB"],
  ["ADDISON", "ON"],
  ["ADEN", "AB"],
  ["ADMIRAL ROCK", "NS"],
  ["ADMIRAL", "SK"],
  ["ADSTOCK", "QC"],
  ["ADVOCATE HARBOUR", "NS"],
  ["AETNA", "AB"],
  ["AFTON STATION", "NS"],
  ["AGASSIZ", "BC"],
  ["AGUANISH", "QC"],
  ["AGUATHUNA", "NL"],
  ["AHMIC HARBOUR", "ON"],
  ["AHOUSAT", "BC"],
  ["AILSA CRAIG", "ON"],
  ["AINSWORTH HOT SPRINGS", "BC"],
  ["AIR RONGE", "SK"],
  ["AIRDRIE", "AB"],
  ["AIYANSH", "BC"],
  ["AJAX", "ON"],
  ["AKLAVIK", "NT"],
  ["AKULIVIK", "QC"],
  ["AKWESASNE", "ON"],
  ["AKWESASNE", "QC"],
  ["ALAMEDA", "SK"],
  ["ALBAN", "ON"],
  ["ALBANEL", "QC"],
  ["ALBANY", "PE"],
  ["ALBERT BRIDGE", "NS"],
  ["ALBERT MINES", "NB"],
  ["ALBERTA BEACH", "AB"],
  ["ALBERTON", "ON"],
  ["ALBERTON", "PE"],
  ["ALBERTVILLE", "QC"],
  ["ALBERTVILLE", "SK"],
  ["ALBRIGHTS CORNER", "NB"],
  ["ALCIDA", "NB"],
  ["ALCOMDALE", "AB"],
  ["ALCOVE", "QC"],
  ["ALDER FLATS", "AB"],
  ["ALDER POINT", "NS"],
  ["ALDERGROVE", "BC"],
  ["ALDERSYDE", "AB"],
  ["ALDERWOOD", "NB"],
  ["ALDOUANE", "NB"],
  ["ALERT BAY", "BC"],
  ["ALEXANDER", "MB"],
  ["ALEXANDRIA", "ON"],
  ["ALEXIS CREEK", "BC"],
  ["ALFRED", "ON"],
  ["ALGOMA MILLS", "ON"],
  ["ALHAMBRA", "AB"],
  ["ALIDA", "SK"],
  ["ALIX", "AB"],
  ["ALKALI LAKE", "BC"],
  ["ALLAINVILLE", "NB"],
  ["ALLAN", "SK"],
  ["ALLANBURG", "ON"],
  ["ALLARDVILLE", "NB"],
  ["ALLENFORD", "ON"],
  ["ALLIANCE", "AB"],
  ["ALLISON", "NB"],
  ["ALLISTON", "ON"],
  ["ALMA", "NB"],
  ["ALMA", "ON"],
  ["ALMA", "QC"],
  ["ALMONTE", "ON"],
  ["ALONSA", "MB"],
  ["ALOUETTE", "QC"],
  ["ALSASK", "SK"],
  ["ALSIKE", "AB"],
  ["ALTAMONT", "MB"],
  ["ALTARIO", "AB"],
  ["ALTICANE", "SK"],
  ["ALTON", "NS"],
  ["ALTON", "ON"],
  ["ALTONA", "BC"],
  ["ALTONA", "MB"],
  ["ALVENA", "SK"],
  ["ALVINSTON", "ON"],
  ["AMARANTH", "MB"],
  ["AMELIASBURG", "ON"],
  ["AMHERST", "NS"],
  ["AMHERST", "QC"],
  ["AMHERSTBURG", "ON"],
  ["AMHERSTVIEW", "ON"],
  ["AMISK", "AB"],
  ["AMMON", "NB"],
  ["AMOS", "QC"],
  ["AMQUI", "QC"],
  ["ANAGANCE", "NB"],
  ["ANAHIM LAKE", "BC"],
  ["ANCASTER", "ON"],
  ["ANCHOR POINT", "NL"],
  ["ANDERSON ROAD", "NB"],
  ["ANDERSON SETTLEMENT", "NB"],
  ["ANDERSONVILLE", "NB"],
  ["ANDREW", "AB"],
  ["ANEROID", "SK"],
  ["ANFIELD", "NB"],
  ["ANGE-GARDIEN", "QC"],
  ["ANGLEMONT", "BC"],
  ["ANGLIERS", "QC"],
  ["ANGLING LAKE", "ON"],
  ["ANGUS", "ON"],
  ["ANGUSVILLE", "MB"],
  ["ANJOU", "QC"],
  ["ANMORE", "BC"],
  ["ANNAHEIM", "SK"],
  ["ANNAN", "ON"],
  ["ANNAPOLIS ROYAL", "NS"],
  ["ANOLA", "MB"],
  ["ANSE-BLEUE", "NB"],
  ["ANTIGONISH", "NS"],
  ["ANTLER", "SK"],
  ["ANTRIM", "NS"],
  ["ANZAC", "AB"],
  ["APOHAQUI", "NB"],
  ["APPIN", "ON"],
  ["APPLE HILL", "ON"],
  ["APSLEY", "ON"],
  ["AQUAFORTE", "NL"],
  ["ARBEAU SETTLEMENT", "NB"],
  ["ARBORFIELD", "SK"],
  ["ARBORG", "MB"],
  ["ARCADIA", "NS"],
  ["ARCHERWILL", "SK"],
  ["ARCOLA", "SK"],
  ["ARCTIC BAY", "NU"],
  ["ARDATH", "SK"],
  ["ARDEN", "MB"],
  ["ARDEN", "ON"],
  ["ARDILL", "SK"],
  ["ARDMORE", "AB"],
  ["ARDOCH", "ON"],
  ["ARDROSSAN", "AB"],
  ["ARELEE", "SK"],
  ["ARGENTA", "BC"],
  ["ARGYLE", "MB"],
  ["ARICHAT", "NS"],
  ["ARISS", "ON"],
  ["ARKELL", "ON"],
  ["ARKONA", "ON"],
  ["ARMAGH", "QC"],
  ["ARMENA", "AB"],
  ["ARMOND", "NB"],
  ["ARMSTRONG STATION", "ON"],
  ["ARMSTRONG", "BC"],
  ["ARNAUD", "MB"],
  ["ARNES", "MB"],
  ["ARNOLDS COVE", "NL"],
  ["ARNPRIOR", "ON"],
  ["ARNSTEIN", "ON"],
  ["ARNTFIELD", "QC"],
  ["AROLAND", "ON"],
  ["AROOSTOOK JUNCTION", "NB"],
  ["AROOSTOOK", "NB"],
  ["ARRAN", "SK"],
  ["ARRAS", "BC"],
  ["ARROW RIVER", "MB"],
  ["ARROWWOOD", "AB"],
  ["ARTHUR", "ON"],
  ["ARTHURETTE", "NB"],
  ["ARUNDEL", "QC"],
  ["ARVA", "ON"],
  ["ARVIAT", "NU"],
  ["ASBESTOS", "QC"],
  ["ASCOT CORNER", "QC"],
  ["ASHBURN", "ON"],
  ["ASHCROFT", "BC"],
  ["ASHERN", "MB"],
  ["ASHLAND", "NB"],
  ["ASHMONT", "AB"],
  ["ASHTON", "ON"],
  ["ASHVILLE", "MB"],
  ["ASKILTON", "NS"],
  ["ASPEN COVE", "NL"],
  ["ASPEN", "NS"],
  ["ASQUITH", "SK"],
  ["ASSINIBOIA", "SK"],
  ["ASTLE", "NB"],
  ["ASTON-JONCTION", "QC"],
  ["ASTORVILLE", "ON"],
  ["ASTRA", "ON"],
  ["ATHABASCA", "AB"],
  ["ATHALMER", "BC"],
  ["ATHELSTAN", "QC"],
  ["ATHENS", "ON"],
  ["ATHOLVILLE", "NB"],
  ["ATIKAMEG", "AB"],
  ["ATIKOKAN", "ON"],
  ["ATLIN", "BC"],
  ["ATMORE", "AB"],
  ["ATTAWAPISKAT", "ON"],
  ["ATWATER", "SK"],
  ["ATWOOD", "ON"],
  ["AUBIGNY", "MB"],
  ["AUBURN", "NS"],
  ["AUBURN", "ON"],
  ["AUBURNDALE", "NS"],
  ["AUCLAIR", "QC"],
  ["AUDET", "QC"],
  ["AULAC", "NB"],
  ["AUMOND", "QC"],
  ["AUPALUK", "QC"],
  ["AURORA", "ON"],
  ["AUSTIN", "MB"],
  ["AUSTIN", "QC"],
  ["AUTHIER", "QC"],
  ["AUTHIER-NORD", "QC"],
  ["AVOLA", "BC"],
  ["AVONDALE", "NB"],
  ["AVONDALE", "NL"],
  ["AVONHURST", "SK"],
  ["AVONLEA", "SK"],
  ["AVONMORE", "ON"],
  ["AVONPORT", "NS"],
  ["AYER'S CLIFF", "QC"],
  ["AYLESBURY", "SK"],
  ["AYLESFORD", "NS"],
  ["AYLMER", "ON"],
  ["AYLSHAM", "SK"],
  ["AYR", "ON"],
  ["AYTON", "ON"],
  ["AZILDA", "ON"],
  ["BACCARO", "NS"],
  ["BACK BAY", "NB"],
  ["BADDECK", "NS"],
  ["BADEN", "ON"],
  ["BADGER", "NL"],
  ["BADGERS QUAY", "NL"],
  ["BADJEROS", "ON"],
  ["BAGOT", "MB"],
  ["BAIE DE BOUCTOUCHE", "NB"],
  ["BAIE DE PETIT-POKEMOUCHE", "NB"],
  ["BAIE VERTE", "NB"],
  ["BAIE VERTE", "NL"],
  ["BAIE-COMEAU", "QC"],
  ["BAIE-D'URFE", "QC"],
  ["BAIE-DES-SABLES", "QC"],
  ["BAIE-DU-FEBVRE", "QC"],
  ["BAIE-JOHAN-BEETZ", "QC"],
  ["BAIE-SAINT-PAUL", "QC"],
  ["BAIE-SAINTE-ANNE", "NB"],
  ["BAIE-SAINTE-CATHERINE", "QC"],
  ["BAIE-TRINITE", "QC"],
  ["BAILIEBORO", "ON"],
  ["BAILLIE", "NB"],
  ["BAINE HARBOUR", "NL"],
  ["BAINS CORNER", "NB"],
  ["BAINSVILLE", "ON"],
  ["BAIRDSVILLE", "NB"],
  ["BAKER BROOK", "NB"],
  ["BAKER LAKE", "NU"],
  ["BAKER SETTLEMENT", "NS"],
  ["BALA", "ON"],
  ["BALCARRES", "SK"],
  ["BALD ROCK", "NS"],
  ["BALDERSON", "ON"],
  ["BALDONNEL", "BC"],
  ["BALDUR", "MB"],
  ["BALDWIN", "ON"],
  ["BALDWINTON", "SK"],
  ["BALFOUR", "BC"],
  ["BALGONIE", "SK"],
  ["BALLA PHILIP", "NB"],
  ["BALLINAFAD", "ON"],
  ["BALLS CREEK", "NS"],
  ["BALMERTOWN", "ON"],
  ["BALMORAL EST", "NB"],
  ["BALMORAL SUD", "NB"],
  ["BALMORAL", "MB"],
  ["BALMORAL", "NB"],
  ["BALTIMORE", "NB"],
  ["BALTIMORE", "ON"],
  ["BALZAC", "AB"],
  ["BAMFIELD", "BC"],
  ["BANCROFT", "ON"],
  ["BANFF", "AB"],
  ["BANGOR", "SK"],
  ["BANKEND", "SK"],
  ["BANNON", "NB"],
  ["BARACHOIS BROOK", "NL"],
  ["BARACHOIS", "QC"],
  ["BARKERVILLE", "BC"],
  ["BARKMERE", "QC"],
  ["BARNABY", "NB"],
  ["BARNESVILLE", "NB"],
  ["BARNETTVILLE", "NB"],
  ["BARNEYS RIVER STATION", "NS"],
  ["BARNWELL", "AB"],
  ["BARONS", "AB"],
  ["BARONY", "NB"],
  ["BARR SETTLEMENT", "NS"],
  ["BARRA GLEN", "NS"],
  ["BARRACHOIS", "NS"],
  ["BARRAUTE", "QC"],
  ["BARRHEAD", "AB"],
  ["BARRIE", "ON"],
  ["BARRIERE", "BC"],
  ["BARRINGTON PASSAGE", "NS"],
  ["BARRINGTON", "NS"],
  ["BARROWS", "MB"],
  ["BARRYS BAY", "ON"],
  ["BARRYVILLE", "NB"],
  ["BARSS CORNER", "NS"],
  ["BARTER SETTLEMENT", "NB"],
  ["BARTHEL", "SK"],
  ["BARTHOLOMEW", "NB"],
  ["BARTIBOG BRIDGE", "NB"],
  ["BARTIBOG", "NB"],
  ["BARTLETTS HARBOUR", "NL"],
  ["BARTLETTS MILLS", "NB"],
  ["BARTON", "NS"],
  ["BARWICK", "ON"],
  ["BAS-CAP-PELE", "NB"],
  ["BAS-CARAQUET", "NB"],
  ["BAS-PAQUETVILLE", "NB"],
  ["BASHAW", "AB"],
  ["BASS RIVER", "NB"],
  ["BASS RIVER", "NS"],
  ["BASSANO", "AB"],
  ["BASSIN", "QC"],
  ["BASSWOOD RIDGE", "NB"],
  ["BATAWA", "ON"],
  ["BATCHAWANA BAY", "ON"],
  ["BATEMAN", "SK"],
  ["BATESTON", "NS"],
  ["BATH", "NB"],
  ["BATH", "ON"],
  ["BATHURST", "NB"],
  ["BATISCAN", "QC"],
  ["BATTERSEA", "ON"],
  ["BATTLEFORD", "SK"],
  ["BAULINE", "NL"],
  ["BAWLF", "AB"],
  ["BAXTERS CORNER", "NB"],
  ["BAY BULLS", "NL"],
  ["BAY CHIMO", "NU"],
  ["BAY DE VERDE", "NL"],
  ["BAY DU VIN", "NB"],
  ["BAY L'ARGENT", "NL"],
  ["BAY ROBERTS", "NL"],
  ["BAY TREE", "AB"],
  ["BAY VIEW", "NB"],
  ["BAYFIELD", "NB"],
  ["BAYFIELD", "ON"],
  ["BAYSIDE", "NB"],
  ["BAYSIDE", "NS"],
  ["BAYSVILLE", "ON"],
  ["BAYSWATER", "NB"],
  ["BAYTONA", "NL"],
  ["BEACHBURG", "ON"],
  ["BEACHSIDE", "NL"],
  ["BEACHVILLE", "ON"],
  ["BEACONIA", "MB"],
  ["BEACONSFIELD", "NB"],
  ["BEACONSFIELD", "QC"],
  ["BEAMSVILLE", "ON"],
  ["BEAR CANYON", "AB"],
  ["BEAR COVE", "NS"],
  ["BEAR ISLAND", "NB"],
  ["BEAR ISLAND", "ON"],
  ["BEAR LAKE", "BC"],
  ["BEAR RIVER", "NS"],
  ["BEARDMORE", "ON"],
  ["BEARDSLEY", "NB"],
  ["BEARN", "QC"],
  ["BEARSKIN LAKE", "ON"],
  ["BEATTY", "SK"],
  ["BEAUBIER", "SK"],
  ["BEAUCANTON", "QC"],
  ["BEAUCEVILLE", "QC"],
  ["BEAUHARNOIS", "QC"],
  ["BEAULAC-GARTHBY", "QC"],
  ["BEAUMONT", "AB"],
  ["BEAUMONT", "NL"],
  ["BEAUMONT", "QC"],
  ["BEAUPRE", "QC"],
  ["BEAUSEJOUR", "MB"],
  ["BEAUVAL", "SK"],
  ["BEAUVALLON", "AB"],
  ["BEAVER BANK", "NS"],
  ["BEAVER BROOK", "NS"],
  ["BEAVER COVE", "NS"],
  ["BEAVER CREEK", "SK"],
  ["BEAVER CREEK", "YT"],
  ["BEAVER DAM", "NB"],
  ["BEAVER HARBOUR", "NB"],
  ["BEAVERBROOK ALBERT CO", "NB"],
  ["BEAVERBROOK", "NB"],
  ["BEAVERDELL", "BC"],
  ["BEAVERLODGE", "AB"],
  ["BEAVERTON", "ON"],
  ["BECANCOUR", "QC"],
  ["BECKIM SETTLEMENT", "NB"],
  ["BEDELL", "NB"],
  ["BEDEQUE", "PE"],
  ["BEDFORD", "NS"],
  ["BEDFORD", "QC"],
  ["BEECHMONT", "NS"],
  ["BEECHVILLE", "NS"],
  ["BEECHWOOD", "NB"],
  ["BEECHY", "SK"],
  ["BEERSVILLE", "NB"],
  ["BEETON", "ON"],
  ["BEGIN", "QC"],
  ["BEHCHOKO", "NT"],
  ["BEISEKER", "AB"],
  ["BELAIR", "MB"],
  ["BELCARRA", "BC"],
  ["BELCOURT", "QC"],
  ["BELFAST", "PE"],
  ["BELFOUNTAIN", "ON"],
  ["BELGRAVE", "ON"],
  ["BELL ISLAND FRONT", "NL"],
  ["BELL ISLAND", "NL"],
  ["BELLA BELLA", "BC"],
  ["BELLA COOLA", "BC"],
  ["BELLBURNS", "NL"],
  ["BELLE COTE", "NS"],
  ["BELLE EWART", "ON"],
  ["BELLE PLAINE", "SK"],
  ["BELLE RIVER", "ON"],
  ["BELLE RIVER", "PE"],
  ["BELLE VALLEE", "ON"],
  ["BELLECOMBE", "QC"],
  ["BELLEDUNE", "NB"],
  ["BELLEFOND", "NB"],
  ["BELLEGARDE", "SK"],
  ["BELLEISLE CREEK", "NB"],
  ["BELLEORAM", "NL"],
  ["BELLETERRE", "QC"],
  ["BELLEVIEW", "MB"],
  ["BELLEVILLE", "NB"],
  ["BELLEVILLE", "ON"],
  ["BELLEVUE", "AB"],
  ["BELLEVUE", "NL"],
  ["BELLIS", "AB"],
  ["BELLIVEAU COVE", "NS"],
  ["BELMONT", "MB"],
  ["BELMONT", "NS"],
  ["BELMONT", "ON"],
  ["BELNAN", "NS"],
  ["BELOEIL", "QC"],
  ["BELWOOD", "ON"],
  ["BEN EOIN", "NS"],
  ["BENACADIE", "NS"],
  ["BENALTO", "AB"],
  ["BENGOUGH", "SK"],
  ["BENITO", "MB"],
  ["BENJAMIN RIVER", "NB"],
  ["BENOIT", "NB"],
  ["BENOITS COVE", "NL"],
  ["BENSON", "SK"],
  ["BENTLEY", "AB"],
  ["BENTON", "NB"],
  ["BENTON", "NL"],
  ["BERENS RIVER", "MB"],
  ["BERESFORD", "NB"],
  ["BERKELEY", "ON"],
  ["BERRY MILLS", "NB"],
  ["BERRY", "QC"],
  ["BERRYTON", "NB"],
  ["BERTHIER-SUR-MER", "QC"],
  ["BERTHIERVILLE", "QC"],
  ["BERTRAND", "NB"],
  ["BERWICK", "NB"],
  ["BERWICK", "NS"],
  ["BERWICK", "ON"],
  ["BERWYN", "AB"],
  ["BETHANIE", "QC"],
  ["BETHANY", "MB"],
  ["BETHANY", "ON"],
  ["BETHEL", "NB"],
  ["BETHUNE", "SK"],
  ["BETSIAMITES", "QC"],
  ["BETTSBURG", "NB"],
  ["BEULAH", "MB"],
  ["BEWDLEY", "ON"],
  ["BEZANSON", "AB"],
  ["BIBLE HILL", "NS"],
  ["BICKERTON WEST", "NS"],
  ["BIDE ARM", "NL"],
  ["BIENCOURT", "QC"],
  ["BIENFAIT", "SK"],
  ["BIG BEACH", "NS"],
  ["BIG BEAVER", "SK"],
  ["BIG BRAS D'OR", "NS"],
  ["BIG COVE QUEENS CO", "NB"],
  ["BIG HOLE", "NB"],
  ["BIG LAKE RANCH", "BC"],
  ["BIG LAKE", "NS"],
  ["BIG POND CENTRE", "NS"],
  ["BIG POND", "NS"],
  ["BIG RIDGE", "NS"],
  ["BIG RIVER", "NB"],
  ["BIG RIVER", "SK"],
  ["BIG STONE", "AB"],
  ["BIG TROUT LAKE", "ON"],
  ["BIG VALLEY", "AB"],
  ["BIGGAR", "SK"],
  ["BINBROOK", "ON"],
  ["BINDLOSS", "AB"],
  ["BINSCARTH", "MB"],
  ["BIRCH GROVE", "NS"],
  ["BIRCH HILL", "NS"],
  ["BIRCH HILLS", "SK"],
  ["BIRCH ISLAND", "ON"],
  ["BIRCH RIDGE", "NB"],
  ["BIRCH RIVER", "MB"],
  ["BIRCHCLIFF", "AB"],
  ["BIRCHY BAY", "NL"],
  ["BIRCHY HEAD", "NL"],
  ["BIRD COVE", "NL"],
  ["BIRDTON", "NB"],
  ["BIRNIE", "MB"],
  ["BIRSAY", "SK"],
  ["BIRTLE", "MB"],
  ["BISCOTASING", "ON"],
  ["BISHOPS FALLS", "NL"],
  ["BISHOPTON", "QC"],
  ["BISSETT CREEK", "ON"],
  ["BISSETT", "MB"],
  ["BITTERN LAKE", "AB"],
  ["BJORKDALE", "SK"],
  ["BLACK BROOK", "NS"],
  ["BLACK CREEK", "BC"],
  ["BLACK DIAMOND", "AB"],
  ["BLACK DUCK COVE", "NL"],
  ["BLACK DUCK SIDING", "NL"],
  ["BLACK LAKE", "SK"],
  ["BLACK POINT", "NB"],
  ["BLACK POINT", "NS"],
  ["BLACK RIVER BRIDGE", "NB"],
  ["BLACK RIVER", "NB"],
  ["BLACK ROCK", "NB"],
  ["BLACK ROCK", "NS"],
  ["BLACK TICKLE", "NL"],
  ["BLACKETTS LAKE", "NS"],
  ["BLACKFALDS", "AB"],
  ["BLACKFOOT", "AB"],
  ["BLACKIE", "AB"],
  ["BLACKLAND RESTIGOUCHE CO", "NB"],
  ["BLACKS HARBOUR", "NB"],
  ["BLACKSTOCK", "ON"],
  ["BLACKVILLE", "NB"],
  ["BLADWORTH", "SK"],
  ["BLAINE LAKE", "SK"],
  ["BLAINVILLE", "QC"],
  ["BLAIR ATHOL", "NB"],
  ["BLAIRMORE", "AB"],
  ["BLAKETOWN", "NL"],
  ["BLANC-SABLON", "QC"],
  ["BLANDFORD", "NS"],
  ["BLENHEIM", "ON"],
  ["BLEZARD VALLEY", "ON"],
  ["BLIND BAY", "BC"],
  ["BLIND BAY", "NS"],
  ["BLIND CHANNEL", "BC"],
  ["BLIND RIVER", "ON"],
  ["BLISSFIELD", "NB"],
  ["BLOCKHOUSE", "NS"],
  ["BLOODVEIN", "MB"],
  ["BLOOMFIELD CARLETON CO", "NB"],
  ["BLOOMFIELD KINGS CO", "NB"],
  ["BLOOMFIELD RIDGE", "NB"],
  ["BLOOMFIELD STATION", "PE"],
  ["BLOOMFIELD", "NL"],
  ["BLOOMFIELD", "ON"],
  ["BLOOMINGDALE", "ON"],
  ["BLOOMSBURY", "AB"],
  ["BLUBBER BAY", "BC"],
  ["BLUE BELL", "NB"],
  ["BLUE MOUNTAIN BEND", "NB"],
  ["BLUE MOUNTAINS", "ON"],
  ["BLUE RIDGE", "AB"],
  ["BLUE RIVER", "BC"],
  ["BLUE SEA", "QC"],
  ["BLUEBERRY MOUNTAIN", "AB"],
  ["BLUESKY", "AB"],
  ["BLUEVALE", "ON"],
  ["BLUFFTON", "AB"],
  ["BLUMENHOF", "SK"],
  ["BLUMENORT", "MB"],
  ["BLYTH", "ON"],
  ["BLYTHESWOOD", "ON"],
  ["BOAT HARBOUR WEST", "NL"],
  ["BOBCAYGEON", "ON"],
  ["BOCABEC", "NB"],
  ["BODO", "AB"],
  ["BOGGY CREEK", "MB"],
  ["BOGNOR", "ON"],
  ["BOIESTOWN", "NB"],
  ["BOILEAU", "QC"],
  ["BOIS-BLANC", "NB"],
  ["BOIS-DES-FILION", "QC"],
  ["BOIS-FRANC", "QC"],
  ["BOIS-GAGNON", "NB"],
  ["BOISBRIAND", "QC"],
  ["BOISCHATEL", "QC"],
  ["BOISDALE", "NS"],
  ["BOISSEVAIN", "MB"],
  ["BOLSOVER", "ON"],
  ["BOLTON", "ON"],
  ["BOLTON-EST", "QC"],
  ["BOLTON-OUEST", "QC"],
  ["BON ACCORD", "AB"],
  ["BON ACCORD", "NB"],
  ["BON-CONSEIL", "QC"],
  ["BONANZA", "AB"],
  ["BONAVENTURE", "QC"],
  ["BONAVISTA", "NL"],
  ["BOND HEAD", "ON"],
  ["BONFIELD", "ON"],
  ["BONNE BAY POND", "NL"],
  ["BONNE BAY", "NL"],
  ["BONNY RIVER", "NB"],
  ["BONNYVILLE", "AB"],
  ["BONSECOURS", "QC"],
  ["BONSHAW", "PE"],
  ["BOOM ROAD", "NB"],
  ["BORDEN", "ON"],
  ["BORDEN", "SK"],
  ["BORDEN-CARLETON", "PE"],
  ["BORNHOLM", "ON"],
  ["BOSTON BAR", "BC"],
  ["BOSWELL", "BC"],
  ["BOTHA", "AB"],
  ["BOTHWELL", "ON"],
  ["BOTWOOD", "NL"],
  ["BOUCHERVILLE", "QC"],
  ["BOUCHETTE", "QC"],
  ["BOUCTOUCHE COVE", "NB"],
  ["BOUCTOUCHE RESERVE", "NB"],
  ["BOUCTOUCHE SUD", "NB"],
  ["BOUCTOUCHE", "NB"],
  ["BOUDREAU-OUEST", "NB"],
  ["BOULANGER", "QC"],
  ["BOULARDERIE CENTER", "NS"],
  ["BOULARDERIE EAST", "NS"],
  ["BOULTER", "ON"],
  ["BOUNDARY CREEK", "NB"],
  ["BOURGET", "ON"],
  ["BOUTILIERS POINT", "NS"],
  ["BOW ISLAND", "AB"],
  ["BOWDEN", "AB"],
  ["BOWEN ISLAND", "BC"],
  ["BOWMAN", "QC"],
  ["BOWMANVILLE", "ON"],
  ["BOWSER", "BC"],
  ["BOWSMAN", "MB"],
  ["BOYDS COVE", "NL"],
  ["BOYLE", "AB"],
  ["BOYLSTON", "NS"],
  ["BOYNE LAKE", "AB"],
  ["BRACEBRIDGE", "ON"],
  ["BRACKEN", "SK"],
  ["BRACKENDALE", "BC"],
  ["BRADFORD", "ON"],
  ["BRADOR", "QC"],
  ["BRADWARDINE", "MB"],
  ["BRADWELL", "SK"],
  ["BRAESIDE", "ON"],
  ["BRAGG CREEK", "AB"],
  ["BRAMPTON", "ON"],
  ["BRANCH LAHAVE", "NS"],
  ["BRANCH", "NL"],
  ["BRANCHTON", "ON"],
  ["BRANDON", "MB"],
  ["BRANT", "AB"],
  ["BRANTFORD", "ON"],
  ["BRANTVILLE", "NB"],
  ["BRAS D'OR", "NS"],
  ["BREADALBANE", "NB"],
  ["BREADALBANE", "PE"],
  ["BREBEUF", "QC"],
  ["BRECHIN", "ON"],
  ["BREDENBURY", "SK"],
  ["BRENTS COVE", "NL"],
  ["BRENTWOOD BAY", "BC"],
  ["BRENTWOOD", "NS"],
  ["BRESLAU", "ON"],
  ["BRETON", "AB"],
  ["BREWERS MILL", "NB"],
  ["BREYNAT", "AB"],
  ["BRIDESVILLE", "BC"],
  ["BRIDGE LAKE", "BC"],
  ["BRIDGENORTH", "ON"],
  ["BRIDGEPORT", "NL"],
  ["BRIDGETOWN", "NS"],
  ["BRIDGEWATER", "NS"],
  ["BRIERCREST", "SK"],
  ["BRIGDEN", "ON"],
  ["BRIGGS CORNER QUEENS CO", "NB"],
  ["BRIGHAM", "QC"],
  ["BRIGHT SAND", "SK"],
  ["BRIGHT", "ON"],
  ["BRIGHT'S GROVE", "ON"],
  ["BRIGHTON", "NL"],
  ["BRIGHTON", "ON"],
  ["BRIGUS JUNCTION", "NL"],
  ["BRIGUS", "NL"],
  ["BRINSTON", "ON"],
  ["BRISCO", "BC"],
  ["BRISTOL JUNCTION", "NB"],
  ["BRISTOL", "QC"],
  ["BRITANNIA BEACH", "BC"],
  ["BRITISH SETTLEMENT", "NB"],
  ["BRITT", "ON"],
  ["BROAD COVE BDV", "NL"],
  ["BROAD VALLEY", "MB"],
  ["BROADVIEW", "SK"],
  ["BROCHET", "MB"],
  ["BROCK", "SK"],
  ["BROCKET", "AB"],
  ["BROCKINGTON", "SK"],
  ["BROCKVILLE", "ON"],
  ["BROCKWAY", "NB"],
  ["BRODERICK", "SK"],
  ["BRODHAGEN", "ON"],
  ["BROME", "QC"],
  ["BROMHEAD", "SK"],
  ["BROMONT", "QC"],
  ["BRONSON SETTLEMENT", "NB"],
  ["BROOKDALE", "MB"],
  ["BROOKFIELD", "NL"],
  ["BROOKFIELD", "NS"],
  ["BROOKLYN", "NS"],
  ["BROOKS", "AB"],
  ["BROOKSBY", "SK"],
  ["BROOKSIDE", "NS"],
  ["BROOKSIDE.", "NS"],
  ["BROOKVILLE", "NB"],
  ["BROSSARD", "QC"],
  ["BROSSEAU", "AB"],
  ["BROUGHAM", "ON"],
  ["BROUGHTON", "NS"],
  ["BROWNFIELD", "AB"],
  ["BROWNLEE", "SK"],
  ["BROWNS FLAT", "NB"],
  ["BROWNS YARD", "NB"],
  ["BROWNSBURG-CHATHAM", "QC"],
  ["BROWNSDALE", "NL"],
  ["BROWNSVILLE", "ON"],
  ["BROWNVALE", "AB"],
  ["BRUCE MINES", "ON"],
  ["BRUCE", "AB"],
  ["BRUCEFIELD", "ON"],
  ["BRUDERHEIM", "AB"],
  ["BRULE", "AB"],
  ["BRUNKILD", "MB"],
  ["BRUNNER", "ON"],
  ["BRUNO", "SK"],
  ["BRUNSWICK MINES", "NB"],
  ["BRUSSELS", "ON"],
  ["BRUXELLES", "MB"],
  ["BRYENTON", "NB"],
  ["BRYSON", "QC"],
  ["BUBARTOWN", "NB"],
  ["BUCHANAN", "SK"],
  ["BUCHANS JUNCTION", "NL"],
  ["BUCHANS", "NL"],
  ["BUCK CREEK", "AB"],
  ["BUCK LAKE", "AB"],
  ["BUCKFIELD", "NS"],
  ["BUCKHORN", "ON"],
  ["BUCKLAND", "QC"],
  ["BUENA VISTA", "SK"],
  ["BUFFALO CREEK", "BC"],
  ["BUFFALO HEAD PRAIRIE", "AB"],
  ["BUFFALO NARROWS", "SK"],
  ["BUFFALO POINT", "MB"],
  ["BUFFALO", "AB"],
  ["BUICK", "BC"],
  ["BULL LAKE", "NB"],
  ["BULLS CREEK", "NB"],
  ["BULYEA", "SK"],
  ["BUNYANS COVE", "NL"],
  ["BURDETT", "AB"],
  ["BURFORD", "ON"],
  ["BURGEO", "NL"],
  ["BURGESSVILLE", "ON"],
  ["BURGOYNES COVE", "NL"],
  ["BURIN BAY ARM", "NL"],
  ["BURIN", "NL"],
  ["BURKS FALLS", "ON"],
  ["BURLINGTON", "NL"],
  ["BURLINGTON", "ON"],
  ["BURNABY", "BC"],
  ["BURNS LAKE", "BC"],
  ["BURNSIDE", "NL"],
  ["BURNSTOWN", "ON"],
  ["BURNSVILLE", "NB"],
  ["BURNT CHURCH FIRST NATION", "NB"],
  ["BURNT CHURCH", "NB"],
  ["BURNT HILL", "NB"],
  ["BURNT ISLANDS BLP", "NL"],
  ["BURNT POINT BDV", "NL"],
  ["BURNT RIVER", "ON"],
  ["BURNTLAND BROOK", "NB"],
  ["BURPEE", "NB"],
  ["BURR", "SK"],
  ["BURRITTS RAPIDS", "ON"],
  ["BURSTALL", "SK"],
  ["BURTON", "BC"],
  ["BURTON", "NB"],
  ["BURTTS CORNER", "NB"],
  ["BURWASH LANDING", "YT"],
  ["BURY", "QC"],
  ["BUSBY", "AB"],
  ["BUSHELL PARK", "SK"],
  ["BYEMOOR", "AB"],
  ["BYNG INLET", "ON"],
  ["CABANO", "QC"],
  ["CABRI", "SK"],
  ["CACHE BAY", "ON"],
  ["CACHE CREEK", "BC"],
  ["CACOUNA", "QC"],
  ["CACTUS LAKE", "SK"],
  ["CADILLAC", "QC"],
  ["CADILLAC", "SK"],
  ["CADOGAN", "AB"],
  ["CADOMIN", "AB"],
  ["CADOTTE LAKE", "AB"],
  ["CAESAREA", "ON"],
  ["CAILS MILLS", "NB"],
  ["CAINS RIVER", "NB"],
  ["CAISSIE ROAD", "NB"],
  ["CAISTOR CENTRE", "ON"],
  ["CAITHNESS", "NB"],
  ["CALABOGIE", "ON"],
  ["CALAHOO", "AB"],
  ["CALAIS", "AB"],
  ["CALDER", "SK"],
  ["CALDERS HEAD", "NB"],
  ["CALEDON EAST", "ON"],
  ["CALEDON VILLAGE", "ON"],
  ["CALEDON", "ON"],
  ["CALEDONIA MOUNTAIN", "NB"],
  ["CALEDONIA", "NS"],
  ["CALEDONIA", "ON"],
  ["CALGARY", "AB"],
  ["CALHOUN", "NB"],
  ["CALIFORNIA SETTLEMENT", "NB"],
  ["CALIXA-LAVALLEE", "QC"],
  ["CALLANDER", "ON"],
  ["CALLING LAKE", "AB"],
  ["CALMAR", "AB"],
  ["CALVERT", "NL"],
  ["CAMBRAY", "ON"],
  ["CAMBRIDGE BAY", "NU"],
  ["CAMBRIDGE", "NS"],
  ["CAMBRIDGE", "ON"],
  ["CAMBRIDGE-NARROWS", "NB"],
  ["CAMDEN EAST", "ON"],
  ["CAMDEN", "NS"],
  ["CAMERON", "ON"],
  ["CAMLACHIE", "ON"],
  ["CAMP CREEK", "AB"],
  ["CAMP MORTON", "MB"],
  ["CAMPBELL RIVER", "BC"],
  ["CAMPBELL SETTLEMENT YORK CO", "NB"],
  ["CAMPBELL SETTLEMENT", "NB"],
  ["CAMPBELL'S BAY", "QC"],
  ["CAMPBELLCROFT", "ON"],
  ["CAMPBELLFORD", "ON"],
  ["CAMPBELLTON", "NB"],
  ["CAMPBELLTON", "NL"],
  ["CAMPBELLVILLE", "ON"],
  ["CAMPDEN", "ON"],
  ["CAMPERDOWN", "NS"],
  ["CAMPERVILLE", "MB"],
  ["CAMROSE", "AB"],
  ["CANAAN FORKS", "NB"],
  ["CANAAN STATION", "NB"],
  ["CANAL FLATS", "BC"],
  ["CANAL", "NB"],
  ["CANDIAC", "QC"],
  ["CANDIAC", "SK"],
  ["CANDLE LAKE", "SK"],
  ["CANDO", "SK"],
  ["CANFIELD", "ON"],
  ["CANIM LAKE", "BC"],
  ["CANISTO", "NB"],
  ["CANMORE", "AB"],
  ["CANNIFTON", "ON"],
  ["CANNING", "NS"],
  ["CANNINGS COVE", "NL"],
  ["CANNINGTON", "ON"],
  ["CANOBIE", "NB"],
  ["CANOE NARROWS", "SK"],
  ["CANOE", "BC"],
  ["CANOOSE", "NB"],
  ["CANORA", "SK"],
  ["CANSO", "NS"],
  ["CANTERBURY", "NB"],
  ["CANTLEY", "QC"],
  ["CANTON BEDFORD", "QC"],
  ["CANTON DES BASQUES", "NB"],
  ["CANTON STANSTEAD", "QC"],
  ["CANTON TREMBLAY", "QC"],
  ["CANTON-DE-HATLEY", "QC"],
  ["CANWOOD", "SK"],
  ["CANYON CREEK", "AB"],
  ["CANYON", "BC"],
  ["CAP-AU-RENARD", "QC"],
  ["CAP-AUX-MEULES", "QC"],
  ["CAP-BATEAU", "NB"],
  ["CAP-CHAT", "QC"],
  ["CAP-CHAT-EST", "QC"],
  ["CAP-D'ESPOIR", "QC"],
  ["CAP-PELE", "NB"],
  ["CAP-SAINT-IGNACE", "QC"],
  ["CAP-SANTE", "QC"],
  ["CAPE BROYLE", "NL"],
  ["CAPE DAUPHIN", "NS"],
  ["CAPE DORSET", "NU"],
  ["CAPE ENRAGE", "NB"],
  ["CAPE FREELS NORTH", "NL"],
  ["CAPE NEGRO", "NS"],
  ["CAPE NORTH", "NS"],
  ["CAPE RAY", "NL"],
  ["CAPE SPEAR", "NB"],
  ["CAPE ST GEORGE", "NL"],
  ["CAPE STATION", "NB"],
  ["CAPE TORMENTINE", "NB"],
  ["CAPLAN", "QC"],
  ["CAPLIN COVE BDV", "NL"],
  ["CAPPAHAYDEN", "NL"],
  ["CAPREOL", "ON"],
  ["CAPSTICK", "NS"],
  ["CAPUCINS", "QC"],
  ["CARAMAT", "ON"],
  ["CARAQUET", "NB"],
  ["CARBERRY", "MB"],
  ["CARBON", "AB"],
  ["CARBONEAR", "NL"],
  ["CARCAJOU", "AB"],
  ["CARCROSS", "YT"],
  ["CARDALE", "MB"],
  ["CARDIFF", "ON"],
  ["CARDIGAN", "NB"],
  ["CARDIGAN", "PE"],
  ["CARDINAL", "ON"],
  ["CARDROSS", "SK"],
  ["CARDSTON", "AB"],
  ["CARGILL", "ON"],
  ["CARIBOU MARSH", "NS"],
  ["CARIEVALE", "SK"],
  ["CARIGNAN", "QC"],
  ["CARLETON PLACE", "ON"],
  ["CARLETON", "NS"],
  ["CARLETON", "QC"],
  ["CARLINGFORD", "NB"],
  ["CARLISLE", "NB"],
  ["CARLISLE", "ON"],
  ["CARLOW", "NB"],
  ["CARLOWRIE", "MB"],
  ["CARLSBAD SPRINGS", "ON"],
  ["CARLTON", "SK"],
  ["CARLYLE", "SK"],
  ["CARMACKS", "YT"],
  ["CARMAN", "MB"],
  ["CARMANGAY", "AB"],
  ["CARMANVILLE", "NL"],
  ["CARMEL", "SK"],
  ["CARNARVON", "ON"],
  ["CARNDUFF", "SK"],
  ["CARNWOOD", "AB"],
  ["CAROLINE", "AB"],
  ["CARON", "SK"],
  ["CARONPORT", "SK"],
  ["CARP", "ON"],
  ["CARRAGANA", "SK"],
  ["CARROL RIDGE", "NB"],
  ["CARROLL", "MB"],
  ["CARROLLS CORNER", "NS"],
  ["CARROLLS CROSSING", "NB"],
  ["CARROT CREEK", "AB"],
  ["CARROT RIVER", "SK"],
  ["CARRYING PLACE", "ON"],
  ["CARSELAND", "AB"],
  ["CARSONVILLE", "NB"],
  ["CARSTAIRS", "AB"],
  ["CARTERS COVE", "NL"],
  ["CARTERS POINT", "NB"],
  ["CARTIER", "MB"],
  ["CARTIER", "ON"],
  ["CARTWRIGHT", "MB"],
  ["CARTWRIGHT", "NL"],
  ["CARTYVILLE", "NL"],
  ["CARVEL", "AB"],
  ["CASA RIO", "SK"],
  ["CASCAPEDIA-SAINT-JULES", "QC"],
  ["CASLAN", "AB"],
  ["CASSELMAN", "ON"],
  ["CASSIDY LAKE", "NB"],
  ["CASSIDY", "BC"],
  ["CASSILIS", "NB"],
  ["CASTLE BAY", "NS"],
  ["CASTLEGAR", "BC"],
  ["CASTLETON", "ON"],
  ["CASTOR", "AB"],
  ["CASTORS RIVER", "NL"],
  ["CAT LAKE", "ON"],
  ["CATALINA", "NL"],
  ["CATALONE GUT", "NS"],
  ["CATALONE", "NS"],
  ["CATHCART", "ON"],
  ["CAUSAPSCAL", "QC"],
  ["CAVAN", "ON"],
  ["CAVENDISH", "NL"],
  ["CAWSTON", "BC"],
  ["CAYER", "MB"],
  ["CAYLEY", "AB"],
  ["CAYUGA", "ON"],
  ["CAZAVILLE", "QC"],
  ["CECIL LAKE", "BC"],
  ["CEDAR CAMP", "NB"],
  ["CEDAR POINT", "ON"],
  ["CEDAR SPRINGS", "ON"],
  ["CEDAR VALLEY", "ON"],
  ["CEDARVALE", "BC"],
  ["CELISTA", "BC"],
  ["CENTRAL BEDEQUE", "PE"],
  ["CENTRAL BLISSVILLE", "NB"],
  ["CENTRAL BUTTE", "SK"],
  ["CENTRAL GREENWICH", "NB"],
  ["CENTRAL HAINESVILLE", "NB"],
  ["CENTRAL HAMPSTEAD", "NB"],
  ["CENTRAL NORTH RIVER", "NS"],
  ["CENTRAL ONSLOW", "NS"],
  ["CENTRAL WATERVILLE", "NB"],
  ["CENTRALIA", "ON"],
  ["CENTRE BURLINGTON", "NS"],
  ["CENTRE VILLAGE", "NB"],
  ["CENTREVILLE", "NB"],
  ["CENTREVILLE", "NS"],
  ["CENTREVILLE", "ON"],
  ["CEREAL", "AB"],
  ["CESSFORD", "AB"],
  ["CEYLON", "SK"],
  ["CHALK RIVER", "ON"],
  ["CHAMBERLAIN SETTLEMENT", "NB"],
  ["CHAMBERLAIN", "SK"],
  ["CHAMBERS SETTLEMENT", "NB"],
  ["CHAMBLY", "QC"],
  ["CHAMBORD", "QC"],
  ["CHAMCOOK", "NB"],
  ["CHAMPION", "AB"],
  ["CHAMPLAIN", "QC"],
  ["CHAMPNEUF", "QC"],
  ["CHANCE COVE", "NL"],
  ["CHANCE HARBOUR", "NB"],
  ["CHANDLER", "QC"],
  ["CHANGE ISLANDS", "NL"],
  ["CHANNEL-PORT-AUX-BASQUES", "NL"],
  ["CHANNING", "MB"],
  ["CHAPAIS", "QC"],
  ["CHAPEAU", "QC"],
  ["CHAPEL ARM", "NL"],
  ["CHAPEL COVE", "NL"],
  ["CHAPLEAU", "ON"],
  ["CHAPLIN ISLAND ROAD", "NB"],
  ["CHAPLIN", "SK"],
  ["CHAPUT HUGHES", "ON"],
  ["CHARD", "AB"],
  ["CHARETTE", "QC"],
  ["CHARING CROSS", "ON"],
  ["CHARLEMAGNE", "QC"],
  ["CHARLESTON", "NB"],
  ["CHARLESTON", "NL"],
  ["CHARLIE LAKE", "BC"],
  ["CHARLIE LAKE", "NB"],
  ["CHARLO SOUTH", "NB"],
  ["CHARLO", "NB"],
  ["CHARLOTTETOWN LAB", "NL"],
  ["CHARLOTTETOWN", "NL"],
  ["CHARLOTTETOWN", "PE"],
  ["CHARLTON", "ON"],
  ["CHARNY", "QC"],
  ["CHARTERS SETTLEMENT", "NB"],
  ["CHARTIERVILLE", "QC"],
  ["CHASE", "BC"],
  ["CHASWOOD", "NS"],
  ["CHATEAU-RICHER", "QC"],
  ["CHATEAUGUAY", "QC"],
  ["CHATEH", "AB"],
  ["CHATFIELD", "MB"],
  ["CHATHAM", "ON"],
  ["CHATSWORTH", "ON"],
  ["CHAUVIN", "AB"],
  ["CHAZEL", "QC"],
  ["CHELAN", "SK"],
  ["CHELMSFORD", "NB"],
  ["CHELMSFORD", "ON"],
  ["CHELSEA", "NS"],
  ["CHELSEA", "QC"],
  ["CHELTENHAM", "ON"],
  ["CHEMAINUS", "BC"],
  ["CHENEVILLE", "QC"],
  ["CHEPSTOW", "ON"],
  ["CHERHILL", "AB"],
  ["CHERRY BROOK", "NS"],
  ["CHERRY BURTON", "NB"],
  ["CHERRY GROVE", "AB"],
  ["CHERRY POINT", "AB"],
  ["CHERRY VALLEY", "ON"],
  ["CHERRYVILLE", "BC"],
  ["CHERTSEY", "QC"],
  ["CHESLEY", "ON"],
  ["CHESTER BASIN", "NS"],
  ["CHESTER", "NS"],
  ["CHESTERFIELD INLET", "NU"],
  ["CHESTERMERE", "AB"],
  ["CHESTERVILLE", "ON"],
  ["CHESTERVILLE", "QC"],
  ["CHETICAMP", "NS"],
  ["CHETWYND", "BC"],
  ["CHEVERIE", "NS"],
  ["CHEVERY", "QC"],
  ["CHIASSON OFFICE", "NB"],
  ["CHIBOUGAMAU", "QC"],
  ["CHICOUTIMI", "QC"],
  ["CHILANKO FORKS", "BC"],
  ["CHILDS CREEK", "NB"],
  ["CHILLIWACK", "BC"],
  ["CHINOOK", "AB"],
  ["CHIPMAN", "AB"],
  ["CHIPMAN", "NB"],
  ["CHISASIBI", "QC"],
  ["CHISHOLM MILLS", "AB"],
  ["CHITEK LAKE", "SK"],
  ["CHOCOLATE COVE", "NB"],
  ["CHOICELAND", "SK"],
  ["CHOMEDEY", "QC"],
  ["CHRISTINA LAKE", "BC"],
  ["CHRISTMAS ISLAND", "NS"],
  ["CHRISTOPHER LAKE", "SK"],
  ["CHURCH POINT", "NS"],
  ["CHURCHBRIDGE", "SK"],
  ["CHURCHILL FALLS", "NL"],
  ["CHURCHILL", "MB"],
  ["CHURCHILL", "ON"],
  ["CHUTE A BLONDEAU", "ON"],
  ["CHUTE-AUX-OUTARDES", "QC"],
  ["CHUTE-SAINT-PHILIPPE", "QC"],
  ["CLAIR", "NB"],
  ["CLAIR", "SK"],
  ["CLAIRMONT", "AB"],
  ["CLAIRVILLE", "NB"],
  ["CLAM POINT", "NS"],
  ["CLANDEBOYE", "MB"],
  ["CLANDONALD", "AB"],
  ["CLANWILLIAM", "MB"],
  ["CLAREMONT", "ON"],
  ["CLARENCE CREEK", "ON"],
  ["CLARENCEVILLE", "QC"],
  ["CLARENDON STATION", "ON"],
  ["CLARENDON", "NB"],
  ["CLARENVILLE", "NL"],
  ["CLARESHOLM", "AB"],
  ["CLARKE CITY", "QC"],
  ["CLARKES BEACH", "NL"],
  ["CLARKS CORNER", "NB"],
  ["CLARKS HARBOUR", "NS"],
  ["CLARKSBURG", "ON"],
  ["CLARKVILLE", "NB"],
  ["CLAVET", "SK"],
  ["CLAYBANK", "SK"],
  ["CLAYDON", "SK"],
  ["CLAYHURST", "BC"],
  ["CLAYTON", "ON"],
  ["CLEAR CREEK", "ON"],
  ["CLEARDALE", "AB"],
  ["CLEARVIEW", "NB"],
  ["CLEARWATER BAY", "ON"],
  ["CLEARWATER RIVER", "SK"],
  ["CLEARWATER", "BC"],
  ["CLEARWATER", "MB"],
  ["CLEMENTSPORT", "NS"],
  ["CLEMENTSVALE", "NS"],
  ["CLERICY", "QC"],
  ["CLERMONT", "QC"],
  ["CLERVAL", "QC"],
  ["CLEVELAND", "NS"],
  ["CLEVELAND", "QC"],
  ["CLIFFORD", "ON"],
  ["CLIFTON ROYAL", "NB"],
  ["CLIFTON", "NB"],
  ["CLIFTON", "NS"],
  ["CLIMAX", "SK"],
  ["CLINTON", "BC"],
  ["CLINTON", "ON"],
  ["CLIVE", "AB"],
  ["CLORIDORME", "QC"],
  ["CLOUTIER", "QC"],
  ["CLOVA", "QC"],
  ["CLOVER HILL", "NB"],
  ["CLOVER VALLEY", "NB"],
  ["CLOVERDALE", "NB"],
  ["CLOVERDALE", "NS"],
  ["CLOYNE", "ON"],
  ["CLUNY", "AB"],
  ["CLYDE RIVER", "NS"],
  ["CLYDE RIVER", "NU"],
  ["CLYDE", "AB"],
  ["COACHMANS COVE", "NL"],
  ["COAL BRANCH", "NB"],
  ["COAL CREEK", "NB"],
  ["COAL HARBOUR", "BC"],
  ["COALDALE", "AB"],
  ["COALHURST", "AB"],
  ["COALMONT", "BC"],
  ["COATICOOK", "QC"],
  ["COATSWORTH STATION", "ON"],
  ["COBALT", "ON"],
  ["COBBLE HILL", "BC"],
  ["COBDEN", "ON"],
  ["COBOCONK", "ON"],
  ["COBOURG", "ON"],
  ["COBURG", "NB"],
  ["COCAGNE", "NB"],
  ["COCHENOUR", "ON"],
  ["COCHIN", "SK"],
  ["COCHRANE", "AB"],
  ["COCHRANE", "ON"],
  ["CODERRE", "SK"],
  ["CODETTE", "SK"],
  ["CODRINGTON", "ON"],
  ["CODROY", "NL"],
  ["CODYS", "NB"],
  ["COE HILL", "ON"],
  ["COLBORNE", "ON"],
  ["COLD LAKE", "AB"],
  ["COLDBROOK", "NS"],
  ["COLDSTREAM", "BC"],
  ["COLDSTREAM", "NB"],
  ["COLDSTREAM", "NS"],
  ["COLDWATER", "ON"],
  ["COLE BAY", "SK"],
  ["COLE HARBOUR", "NS"],
  ["COLEMAN", "AB"],
  ["COLEMAN", "PE"],
  ["COLES ISLAND QUEENS CO", "NB"],
  ["COLEVILLE", "SK"],
  ["COLEYS POINT SOUTH", "NL"],
  ["COLFAX", "SK"],
  ["COLGATE", "SK"],
  ["COLINET", "NL"],
  ["COLINTON", "AB"],
  ["COLLEGE HEIGHTS", "AB"],
  ["COLLETTE", "NB"],
  ["COLLIERS RIVERHEAD", "NL"],
  ["COLLINA", "NB"],
  ["COLLINGWOOD CORNER", "NS"],
  ["COLLINGWOOD", "ON"],
  ["COLLINS", "ON"],
  ["COLOMBIER", "QC"],
  ["COLONSAY", "SK"],
  ["COLPITTS SETTLEMENT", "NB"],
  ["COLVILLE LAKE", "NT"],
  ["COMBER", "ON"],
  ["COMBERMERE", "ON"],
  ["COME BY CHANCE", "NL"],
  ["COMFORT COVE-NEWSTEAD", "NL"],
  ["COMMANDA", "ON"],
  ["COMOX", "BC"],
  ["COMPEER", "AB"],
  ["COMPTON", "QC"],
  ["CONCEPTION BAY SOUTH", "NL"],
  ["CONCEPTION HARBOUR", "NL"],
  ["CONCHE", "NL"],
  ["CONCORD", "ON"],
  ["CONDOR", "AB"],
  ["CONESTOGO", "ON"],
  ["CONGRESS", "SK"],
  ["CONISTON", "ON"],
  ["CONKLIN", "AB"],
  ["CONN", "ON"],
  ["CONNAUGHT", "ON"],
  ["CONNE RIVER", "NL"],
  ["CONNELL", "NB"],
  ["CONQUERALL BANK", "NS"],
  ["CONQUERALL MILLS", "NS"],
  ["CONQUEST", "SK"],
  ["CONSECON", "ON"],
  ["CONSORT", "AB"],
  ["CONSTANCE LAKE", "ON"],
  ["CONSUL", "SK"],
  ["CONTRECOEUR", "QC"],
  ["COOKING LAKE", "AB"],
  ["COOKS BROOK", "NS"],
  ["COOKS HARBOUR", "NL"],
  ["COOKSHIRE-EATON", "QC"],
  ["COOKSTOWN", "ON"],
  ["COOKVILLE", "NB"],
  ["COOKVILLE", "NS"],
  ["COOMBS", "BC"],
  ["COPETOWN", "ON"],
  ["COPPER CLIFF", "ON"],
  ["COPPERSANDS", "SK"],
  ["COQUITLAM", "BC"],
  ["CORAL HARBOUR", "NU"],
  ["CORBEIL", "ON"],
  ["CORBYVILLE", "ON"],
  ["CORMAC", "ON"],
  ["CORMACK", "NL"],
  ["CORMAN PARK", "SK"],
  ["CORMIER-VILLAGE", "NB"],
  ["CORMORANT", "MB"],
  ["CORNER BROOK", "NL"],
  ["CORNHILL", "NB"],
  ["CORNING", "SK"],
  ["CORNWALL", "ON"],
  ["CORNWALL", "PE"],
  ["CORNWALLIS", "NS"],
  ["CORONACH", "SK"],
  ["CORONATION", "AB"],
  ["CORUNNA", "ON"],
  ["COTE SAINT-LUC", "QC"],
  ["COTEAU ROAD", "NB"],
  ["COTEAU-DU-LAC", "QC"],
  ["COTTAM", "ON"],
  ["COTTLESVILLE", "NL"],
  ["COTTRELLS COVE", "NL"],
  ["COULTER", "MB"],
  ["COUNTY OF GRANDE PRAIRIE NO. 1", "AB"],
  ["COURCELETTE", "QC"],
  ["COURCELLES", "QC"],
  ["COURTENAY", "BC"],
  ["COURTICE", "ON"],
  ["COURTLAND", "ON"],
  ["COURTRIGHT", "ON"],
  ["COURVAL", "SK"],
  ["COUTTS", "AB"],
  ["COW BAY", "NS"],
  ["COW HEAD", "NL"],
  ["COWAN", "MB"],
  ["COWANSVILLE", "QC"],
  ["COWESSESS", "SK"],
  ["COWICHAN BAY", "BC"],
  ["COWLEY", "AB"],
  ["COXHEATH", "NS"],
  ["COXS COVE", "NL"],
  ["CRABTREE", "QC"],
  ["CRAIG FLATS", "NB"],
  ["CRAIGMORE", "NS"],
  ["CRAIGMYLE", "AB"],
  ["CRAIK", "SK"],
  ["CRANBERRY PORTAGE", "MB"],
  ["CRANBROOK", "BC"],
  ["CRANDALL ROAD", "NS"],
  ["CRANDALL", "MB"],
  ["CRANE RIVER", "MB"],
  ["CRANE VALLEY", "SK"],
  ["CRANFORD", "AB"],
  ["CRAPAUD", "PE"],
  ["CRAVEN", "SK"],
  ["CRAWFORD BAY", "BC"],
  ["CREDITON", "ON"],
  ["CREELMAN", "SK"],
  ["CREEMORE", "ON"],
  ["CREIGHTON", "SK"],
  ["CREIGNISH", "NS"],
  ["CREMONA", "AB"],
  ["CRESCENT SPUR", "BC"],
  ["CRESCENT VALLEY", "BC"],
  ["CRESTON NORTH", "NL"],
  ["CRESTON", "BC"],
  ["CRESTON", "NL"],
  ["CROCKER HILL", "NB"],
  ["CROFTON", "BC"],
  ["CROMBIE SETTLEMENT", "NB"],
  ["CROMER", "MB"],
  ["CROOKED CREEK", "AB"],
  ["CROOKED RIVER", "SK"],
  ["CROQUE", "NL"],
  ["CROSS CREEK", "NB"],
  ["CROSS LAKE", "MB"],
  ["CROSS ROADS COUNTRY HARBOUR", "NS"],
  ["CROSSFIELD", "AB"],
  ["CROTON", "ON"],
  ["CROUSES SETTLEMENT", "NS"],
  ["CROUSETOWN", "NS"],
  ["CROWELL", "NS"],
  ["CROWES MILLS", "NS"],
  ["CRYSLER", "ON"],
  ["CRYSTAL BEACH", "ON"],
  ["CRYSTAL CITY", "MB"],
  ["CRYSTAL FALLS", "ON"],
  ["CRYSTAL SPRINGS", "SK"],
  ["CUDWORTH", "SK"],
  ["CULTUS LAKE", "BC"],
  ["CUMBERLAND BAY", "NB"],
  ["CUMBERLAND BEACH", "ON"],
  ["CUMBERLAND HOUSE", "SK"],
  ["CUMBERLAND", "BC"],
  ["CUMBERLAND", "ON"],
  ["CUMMINGS COVE", "NB"],
  ["CUPAR", "SK"],
  ["CUPIDS", "NL"],
  ["CURRAN", "ON"],
  ["CURRIE SIDING", "NB"],
  ["CURRIEBURG", "NB"],
  ["CURRYS CORNER", "NS"],
  ["CURRYVILLE", "NB"],
  ["CURVE LAKE", "ON"],
  ["CURVENTON", "NB"],
  ["CUT KNIFE", "SK"],
  ["CUTLER", "ON"],
  ["CYMRIC", "SK"],
  ["CYNTHIA", "AB"],
  ["CYPRESS COUNTY", "AB"],
  ["CYPRESS RIVER", "MB"],
  ["CZAR", "AB"],
  ["D'ALEMBERT", "QC"],
  ["D'ARCY STATION", "SK"],
  ["D'ARCY", "BC"],
  ["D'ESCOUSSE", "NS"],
  ["DACOTAH", "MB"],
  ["DACRE", "ON"],
  ["DAFOE", "SK"],
  ["DAKOTA TIPI", "MB"],
  ["DALEM LAKE", "NS"],
  ["DALEMEAD", "AB"],
  ["DALHOUSIE JUNCTION", "NB"],
  ["DALHOUSIE", "NB"],
  ["DALHOUSIE", "QC"],
  ["DALKEITH", "ON"],
  ["DALLAS", "MB"],
  ["DALMAS", "QC"],
  ["DALMENY", "SK"],
  ["DAMASCUS", "NB"],
  ["DANBURY", "SK"],
  ["DANESVILLE", "NS"],
  ["DANFORD LAKE", "QC"],
  ["DANIELS HARBOUR", "NL"],
  ["DANVILLE", "QC"],
  ["DAPP", "AB"],
  ["DARFIELD", "BC"],
  ["DARLINGFORD", "MB"],
  ["DARLINGS ISLAND", "NB"],
  ["DARTMOUTH", "NS"],
  ["DARWELL", "AB"],
  ["DASHWOOD", "ON"],
  ["DAUPHIN", "MB"],
  ["DAUVERSIERE", "NB"],
  ["DAVELUYVILLE", "QC"],
  ["DAVIDSON", "QC"],
  ["DAVIDSON", "SK"],
  ["DAVIN", "SK"],
  ["DAWSON CREEK", "BC"],
  ["DAWSON SETTLEMENT", "NB"],
  ["DAWSON", "YT"],
  ["DAWSONS LANDING", "BC"],
  ["DAWSONVILLE", "NB"],
  ["DAYSLAND", "AB"],
  ["DAYSPRING", "NS"],
  ["DE WINTON", "AB"],
  ["DEAD CREEK", "NB"],
  ["DEAD MAN'S FLATS", "AB"],
  ["DEADMANS BAY", "NL"],
  ["DEADWOOD", "AB"],
  ["DEASE LAKE", "BC"],
  ["DEBDEN", "SK"],
  ["DEBEC", "NB"],
  ["DEBERT", "NS"],
  ["DEBOLT", "AB"],
  ["DECKER", "MB"],
  ["DEEP BAY", "NL"],
  ["DEEP BROOK", "NS"],
  ["DEEP RIVER", "ON"],
  ["DEER LAKE", "NL"],
  ["DEER LAKE", "ON"],
  ["DEER VALLEY", "SK"],
  ["DEERSDALE", "NB"],
  ["DEERVILLE", "NB"],
  ["DEGELIS", "QC"],
  ["DEL BONITA", "AB"],
  ["DELACOUR", "AB"],
  ["DELAWARE", "ON"],
  ["DELBURNE", "AB"],
  ["DELEAGE", "QC"],
  ["DELEAU", "MB"],
  ["DELHI", "ON"],
  ["DELIA", "AB"],
  ["DELINE", "NT"],
  ["DELISLE", "SK"],
  ["DELMAS", "SK"],
  ["DELORAINE", "MB"],
  ["DELSON", "QC"],
  ["DELTA", "BC"],
  ["DELTA", "ON"],
  ["DEMAINE", "SK"],
  ["DEMMITT", "AB"],
  ["DEMORESTVILLE", "ON"],
  ["DENARE BEACH", "SK"],
  ["DENBIGH", "ON"],
  ["DENFIELD", "ON"],
  ["DENHOLM", "QC"],
  ["DENHOLM", "SK"],
  ["DENMAN ISLAND", "BC"],
  ["DENNIS BEACH", "NB"],
  ["DENNY ISLAND", "BC"],
  ["DENSMORES MILLS", "NS"],
  ["DENWOOD", "AB"],
  ["DENZIL", "SK"],
  ["DERBY JUNCTION", "NB"],
  ["DERBY", "NB"],
  ["DEROCHE", "BC"],
  ["DERWENT", "AB"],
  ["DESBARATS", "ON"],
  ["DESBIENS", "QC"],
  ["DESBORO", "ON"],
  ["DESCHAILLONS-SUR-SAINT-LAURENT", "QC"],
  ["DESCHAMBAULT LAKE", "SK"],
  ["DESCHAMBAULT", "QC"],
  ["DESERONTO", "ON"],
  ["DESERT BLUME", "AB"],
  ["DESMARAISVILLE", "QC"],
  ["DESMELOIZES", "QC"],
  ["DESTOR", "QC"],
  ["DESTRUCTION BAY", "YT"],
  ["DEUX RIVIERES", "ON"],
  ["DEUX-MONTAGNES", "QC"],
  ["DEVLIN", "ON"],
  ["DEVON", "AB"],
  ["DEWBERRY", "AB"],
  ["DEWDNEY", "BC"],
  ["DEWITTVILLE", "QC"],
  ["DEWOLFE", "NB"],
  ["DIAMOND CITY", "AB"],
  ["DIDSBURY", "AB"],
  ["DIEPPE", "NB"],
  ["DIGBY", "NS"],
  ["DIGDEGUASH", "NB"],
  ["DILDO", "NL"],
  ["DILIGENT RIVER", "NS"],
  ["DILKE", "SK"],
  ["DILLON", "SK"],
  ["DINGWALL", "NS"],
  ["DINORWIC", "ON"],
  ["DINSMORE", "SK"],
  ["DIPPER HARBOUR", "NB"],
  ["DISRAELI", "QC"],
  ["DIVIDE", "NB"],
  ["DIXONVILLE", "AB"],
  ["DIXVILLE", "QC"],
  ["DOAKTOWN", "NB"],
  ["DOBBINTON", "ON"],
  ["DOBIE", "ON"],
  ["DOBSON CORNER", "NB"],
  ["DODSLAND", "SK"],
  ["DOG CREEK", "BC"],
  ["DOLBEAU-MISTASSINI", "QC"],
  ["DOLLARD", "SK"],
  ["DOLLARD-DES-ORMEAUX", "QC"],
  ["DOMAIN", "MB"],
  ["DOME CREEK", "BC"],
  ["DOMINION CITY", "MB"],
  ["DOMINION", "NS"],
  ["DOMREMY", "SK"],
  ["DONALDA", "AB"],
  ["DONEGAL", "NB"],
  ["DONKIN", "NS"],
  ["DONNACONA", "QC"],
  ["DONNELLY", "AB"],
  ["DORCHESTER CAPE", "NB"],
  ["DORCHESTER", "NB"],
  ["DORCHESTER", "ON"],
  ["DORINTOSH", "SK"],
  ["DORION", "ON"],
  ["DOROTHY", "AB"],
  ["DORRINGTON HILL", "NB"],
  ["DORSET", "ON"],
  ["DORVAL", "QC"],
  ["DOSQUET", "QC"],
  ["DOUGLAS HARBOUR", "NB"],
  ["DOUGLAS LAKE", "BC"],
  ["DOUGLAS", "MB"],
  ["DOUGLAS", "NB"],
  ["DOUGLAS", "ON"],
  ["DOURO", "ON"],
  ["DOVER CENTRE", "ON"],
  ["DOVER", "NL"],
  ["DOW SETTLEMENT", "NB"],
  ["DOWLING", "ON"],
  ["DOYLES BROOK", "NB"],
  ["DOYLES", "NL"],
  ["DRAKE", "SK"],
  ["DRAYTON VALLEY", "AB"],
  ["DRAYTON", "ON"],
  ["DRESDEN", "ON"],
  ["DRIFTPILE", "AB"],
  ["DRIFTWOOD", "ON"],
  ["DRINKWATER", "SK"],
  ["DROPMORE", "MB"],
  ["DRUMBO", "ON"],
  ["DRUMHELLER", "AB"],
  ["DRUMMOND", "NB"],
  ["DRUMMONDVILLE", "QC"],
  ["DRURYS COVE", "NB"],
  ["DRYDEN", "ON"],
  ["DSL DE DRUMMOND", "NB"],
  ["DSL DE GRAND-SAULT/FALLS", "NB"],
  ["DUART", "ON"],
  ["DUBEE SETTLEMENT", "NB"],
  ["DUBLIN", "ON"],
  ["DUBREUILVILLE", "ON"],
  ["DUBUC", "SK"],
  ["DUCHESS", "AB"],
  ["DUCK BAY", "MB"],
  ["DUCK LAKE", "SK"],
  ["DUCLOS", "QC"],
  ["DUFF", "SK"],
  ["DUFFERIN CHARLOTTE CO", "NB"],
  ["DUFFERIN QUEENS CO", "NB"],
  ["DUFFIELD", "AB"],
  ["DUFRESNE", "MB"],
  ["DUFROST", "MB"],
  ["DUGALD", "MB"],
  ["DUGAS", "NB"],
  ["DUGUAYVILLE", "NB"],
  ["DUHAMEL", "QC"],
  ["DUHAMEL-OUEST", "QC"],
  ["DUMFRIES", "NB"],
  ["DUMMER", "SK"],
  ["DUNCAN", "BC"],
  ["DUNCANS COVE", "NS"],
  ["DUNCHURCH", "ON"],
  ["DUNDALK", "ON"],
  ["DUNDAS", "NB"],
  ["DUNDAS", "ON"],
  ["DUNDEE", "NB"],
  ["DUNDURN", "SK"],
  ["DUNGANNON", "ON"],
  ["DUNHAM", "QC"],
  ["DUNLOP", "NB"],
  ["DUNMORE", "AB"],
  ["DUNNVILLE", "ON"],
  ["DUNREA", "MB"],
  ["DUNROBIN", "ON"],
  ["DUNSFORD", "ON"],
  ["DUNSINANE", "NB"],
  ["DUNSTER", "BC"],
  ["DUNTARA", "NL"],
  ["DUNTROON", "ON"],
  ["DUNVEGAN", "ON"],
  ["DUNVILLE", "NL"],
  ["DUPARQUET", "QC"],
  ["DUPEROW", "SK"],
  ["DUPUY", "QC"],
  ["DURBAN", "MB"],
  ["DURHAM BRIDGE", "NB"],
  ["DURHAM", "ON"],
  ["DURHAM-SUD", "QC"],
  ["DURRELL", "NL"],
  ["DUTCH BROOK", "NS"],
  ["DUTCH SETTLEMENT", "NS"],
  ["DUTCH VALLEY", "NB"],
  ["DUTTON", "ON"],
  ["DUVAL", "SK"],
  ["DWIGHT", "ON"],
  ["DYSART", "SK"],
  ["EABAMET LAKE", "ON"],
  ["EAGLE BAY", "BC"],
  ["EAGLE CREEK", "BC"],
  ["EAGLE LAKE", "ON"],
  ["EAGLE RIDGE", "SK"],
  ["EAGLE RIVER", "ON"],
  ["EAGLESHAM", "AB"],
  ["EAR FALLS", "ON"],
  ["EARL GREY", "SK"],
  ["EARLTON", "ON"],
  ["EAST ANGUS", "QC"],
  ["EAST BACCARO", "NS"],
  ["EAST BAY", "NS"],
  ["EAST BRAINTREE", "MB"],
  ["EAST BRANCH", "NB"],
  ["EAST BRIGHTON", "NB"],
  ["EAST BROUGHTON STATION", "QC"],
  ["EAST BROUGHTON", "QC"],
  ["EAST CENTREVILLE", "NB"],
  ["EAST CLIFFORD", "NS"],
  ["EAST COLDSTREAM", "NB"],
  ["EAST COULEE", "AB"],
  ["EAST DOVER", "NS"],
  ["EAST FARNHAM", "QC"],
  ["EAST GORE", "NS"],
  ["EAST GWILLIMBURY", "ON"],
  ["EAST HEREFORD", "QC"],
  ["EAST LAHAVE", "NS"],
  ["EAST LAWRENCETOWN", "NS"],
  ["EAST MOUNTAIN", "NS"],
  ["EAST NEWBRIDGE", "NB"],
  ["EAST PENNANT", "NS"],
  ["EAST PRESTON", "NS"],
  ["EAST SELKIRK", "MB"],
  ["EAST ST PAUL", "MB"],
  ["EAST STEWIACKE", "NS"],
  ["EAST YORK", "ON"],
  ["EASTEND", "SK"],
  ["EASTERN PASSAGE", "NS"],
  ["EASTERVILLE", "MB"],
  ["EASTMAIN", "QC"],
  ["EASTMAN", "QC"],
  ["EASTPORT", "NL"],
  ["EATONIA", "SK"],
  ["EBB AND FLOW", "MB"],
  ["EBENEZER", "SK"],
  ["ECHO BAY", "ON"],
  ["ECKVILLE", "AB"],
  ["ECONOMY", "NS"],
  ["EDAM", "SK"],
  ["EDBERG", "AB"],
  ["EDDIES COVE WEST", "NL"],
  ["EDDIES COVE", "NL"],
  ["EDDYSTONE", "MB"],
  ["EDEN MILLS", "ON"],
  ["EDEN", "MB"],
  ["EDEN", "ON"],
  ["EDENWOLD", "SK"],
  ["EDGELEY", "SK"],
  ["EDGERTON", "AB"],
  ["EDGETTS LANDING", "NB"],
  ["EDGEWATER", "BC"],
  ["EDGEWOOD", "BC"],
  ["EDMONTON", "AB"],
  ["EDMUNDSTON", "NB"],
  ["EDSON", "AB"],
  ["EDWARDS", "ON"],
  ["EDWARDSVILLE", "NS"],
  ["EDWIN", "MB"],
  ["EEL GROUND", "NB"],
  ["EEL RIVER BAR FIRST NATION", "NB"],
  ["EEL RIVER COVE", "NB"],
  ["EEL RIVER CROSSING", "NB"],
  ["EEL RIVER LAKE", "NB"],
  ["EGAN", "QC"],
  ["EGANVILLE", "ON"],
  ["EGBERT", "ON"],
  ["EGMONDVILLE", "ON"],
  ["EGMONT", "BC"],
  ["EGREMONT", "AB"],
  ["ELBOW", "SK"],
  ["ELDERBANK", "NS"],
  ["ELDORADO", "ON"],
  ["ELFROS", "SK"],
  ["ELGIN", "MB"],
  ["ELGIN", "NB"],
  ["ELGIN", "ON"],
  ["ELGIN", "QC"],
  ["ELGINBURG", "ON"],
  ["ELIE", "MB"],
  ["ELIZABETHTOWN", "ON"],
  ["ELK LAKE", "ON"],
  ["ELK POINT", "AB"],
  ["ELKFORD", "BC"],
  ["ELKHORN", "MB"],
  ["ELKO", "BC"],
  ["ELKWATER", "AB"],
  ["ELLERSHOUSE", "NS"],
  ["ELLERSLIE", "PE"],
  ["ELLIOT LAKE", "ON"],
  ["ELLISTON", "NL"],
  ["ELLSCOTT", "AB"],
  ["ELM CREEK", "MB"],
  ["ELM HILL", "NB"],
  ["ELMA", "MB"],
  ["ELMIRA", "ON"],
  ["ELMIRA", "PE"],
  ["ELMSDALE", "NS"],
  ["ELMSDALE", "PE"],
  ["ELMSVILLE", "NB"],
  ["ELMVALE", "ON"],
  ["ELMWOOD", "NB"],
  ["ELMWOOD", "ON"],
  ["ELMWORTH", "AB"],
  ["ELNORA", "AB"],
  ["ELORA", "ON"],
  ["ELPHINSTONE", "MB"],
  ["ELROSE", "SK"],
  ["ELSA", "YT"],
  ["ELSIPOGTOG FIRST NATION", "NB"],
  ["ELSTOW", "SK"],
  ["EM-1-A-SARCELLE-RUPERT", "QC"],
  ["EMBREE", "NL"],
  ["EMBRO", "ON"],
  ["EMBRUN", "ON"],
  ["EMERALD PARK", "SK"],
  ["EMERSON", "MB"],
  ["EMERYVILLE", "ON"],
  ["EMO", "ON"],
  ["EMPRESS", "AB"],
  ["EMSDALE", "ON"],
  ["ENCHANT", "AB"],
  ["ENDAKO", "BC"],
  ["ENDEAVOUR", "SK"],
  ["ENDERBY", "BC"],
  ["ENDIANG", "AB"],
  ["ENFIELD", "NS"],
  ["ENGLEE", "NL"],
  ["ENGLEFELD", "SK"],
  ["ENGLEHART", "ON"],
  ["ENGLISH HARBOUR EAST", "NL"],
  ["ENGLISH HARBOUR WEST", "NL"],
  ["ENGLISH SETTLEMENT", "NB"],
  ["ENGLISHTOWN", "NS"],
  ["ENILDA", "AB"],
  ["ENNISMORE", "ON"],
  ["ENOCH", "AB"],
  ["ENON", "NS"],
  ["ENTERPRISE", "NB"],
  ["ENTERPRISE", "NT"],
  ["ENTERPRISE", "ON"],
  ["ENTRELACS", "QC"],
  ["ENTWISTLE", "AB"],
  ["EPWORTH", "NL"],
  ["ERB SETTLEMENT", "NB"],
  ["ERBS COVE", "NB"],
  ["ERICKSON", "BC"],
  ["ERICKSON", "MB"],
  ["ERIEAU", "ON"],
  ["ERIKSDALE", "MB"],
  ["ERIN", "ON"],
  ["ERINSVILLE", "ON"],
  ["ERNFOLD", "SK"],
  ["ERRINGTON", "BC"],
  ["ERSKINE", "AB"],
  ["ESCUMINAC", "NB"],
  ["ESCUMINAC", "QC"],
  ["ESKASONI", "NS"],
  ["ESPANOLA", "ON"],
  ["ESPRIT-SAINT", "QC"],
  ["ESQUIMALT", "BC"],
  ["ESSEX", "ON"],
  ["ESTEREL", "QC"],
  ["ESTERHAZY", "SK"],
  ["ESTEVAN", "SK"],
  ["ESTEY'S BRIDGE", "NB"],
  ["ESTHER", "AB"],
  ["ESTON", "SK"],
  ["ETHEL", "ON"],
  ["ETHELBERT", "MB"],
  ["ETOBICOKE", "ON"],
  ["ETZIKOM", "AB"],
  ["EUREKA RIVER", "AB"],
  ["EUREKA", "NS"],
  ["EUREKA", "NU"],
  ["EVAIN", "QC"],
  ["EVANDALE", "NB"],
  ["EVANGELINE", "NB"],
  ["EVANS ROAD", "NB"],
  ["EVANSBURG", "AB"],
  ["EVANSVILLE", "ON"],
  ["EVERETT", "NB"],
  ["EVERETT", "ON"],
  ["EVESHAM", "SK"],
  ["EXETER", "ON"],
  ["EXMOOR", "NB"],
  ["EXSHAW", "AB"],
  ["EYEBROW", "SK"],
  ["FABRE", "QC"],
  ["FABREVILLE", "QC"],
  ["FAIR HAVEN", "NL"],
  ["FAIRFAX", "MB"],
  ["FAIRFIELD WESTMORLAND CO", "NB"],
  ["FAIRFIELD", "NB"],
  ["FAIRFORD", "MB"],
  ["FAIRHAVEN", "NB"],
  ["FAIRISLE", "NB"],
  ["FAIRLIGHT", "SK"],
  ["FAIRMONT HOT SPRINGS", "BC"],
  ["FAIRVIEW", "AB"],
  ["FAIRY GLEN", "SK"],
  ["FALARDEAU", "QC"],
  ["FALCON BEACH", "MB"],
  ["FALCONBRIDGE", "ON"],
  ["FALHER", "AB"],
  ["FALKLAND", "BC"],
  ["FALL RIVER", "NS"],
  ["FALLIS", "AB"],
  ["FALMOUTH", "NS"],
  ["FALUN", "AB"],
  ["FANNY BAY", "BC"],
  ["FANNYSTELLE", "MB"],
  ["FARMINGTON", "BC"],
  ["FARNHAM", "QC"],
  ["FARO", "YT"],
  ["FARRELLTON", "QC"],
  ["FASSETT", "QC"],
  ["FATIMA", "QC"],
  ["FAULKNER", "MB"],
  ["FAUQUIER", "BC"],
  ["FAUQUIER", "ON"],
  ["FAUST", "AB"],
  ["FAWCETT HILL", "NB"],
  ["FAWCETT", "AB"],
  ["FENELON FALLS", "ON"],
  ["FENN", "AB"],
  ["FENWICK", "ON"],
  ["FENWOOD", "SK"],
  ["FERGUS", "ON"],
  ["FERGUSONS COVE", "NS"],
  ["FERINTOSH", "AB"],
  ["FERLAND", "SK"],
  ["FERLAND-ET-BOILLEAU", "QC"],
  ["FERME-NEUVE", "QC"],
  ["FERMEUSE", "NL"],
  ["FERMONT", "QC"],
  ["FERNIE", "BC"],
  ["FERRYLAND", "NL"],
  ["FERTILE", "SK"],
  ["FEVERSHAM", "ON"],
  ["FIELD", "BC"],
  ["FIELD", "ON"],
  ["FIELDING", "NB"],
  ["FIFE LAKE", "SK"],
  ["FILLMORE", "SK"],
  ["FINCH", "ON"],
  ["FINDLATER", "SK"],
  ["FINGAL", "ON"],
  ["FINNEGAN", "AB"],
  ["FIR MOUNTAIN", "SK"],
  ["FISHER BRANCH", "MB"],
  ["FISHERMANS HARBOUR", "NS"],
  ["FISHERVILLE", "ON"],
  ["FISKE", "SK"],
  ["FITZGERALD", "AB"],
  ["FITZROY HARBOUR", "ON"],
  ["FIVE ISLANDS", "NS"],
  ["FLATBUSH", "AB"],
  ["FLATLANDS", "NB"],
  ["FLATROCK", "NL"],
  ["FLAXCOMBE", "SK"],
  ["FLEMING", "SK"],
  ["FLEMINGTON", "NB"],
  ["FLESHERTON", "ON"],
  ["FLETCHERS LAKE", "NS"],
  ["FLEUR DE LYS", "NL"],
  ["FLIN FLON", "MB"],
  ["FLINTOFT", "SK"],
  ["FLINTON", "ON"],
  ["FLORADALE", "ON"],
  ["FLORENCE", "NS"],
  ["FLORENCE", "ON"],
  ["FLORENCEVILLE-BRISTOL", "NB"],
  ["FLOWERS COVE", "NB"],
  ["FLOWERS COVE", "NL"],
  ["FLUME RIDGE", "NB"],
  ["FOAM LAKE", "SK"],
  ["FOGO", "NL"],
  ["FOISY", "AB"],
  ["FOLEYET", "ON"],
  ["FOND DU LAC", "SK"],
  ["FONTHILL", "ON"],
  ["FORD BANK", "NB"],
  ["FORDS MILLS", "NB"],
  ["FORDWICH", "ON"],
  ["FOREMOST", "AB"],
  ["FOREST CITY", "NB"],
  ["FOREST GLEN", "NB"],
  ["FOREST GROVE", "BC"],
  ["FOREST", "ON"],
  ["FORESTBURG", "AB"],
  ["FORESTERS FALLS", "ON"],
  ["FORESTVILLE", "QC"],
  ["FORGET", "SK"],
  ["FORK RIVER", "MB"],
  ["FORMOSA", "ON"],
  ["FORREST STATION", "MB"],
  ["FORT ALBANY", "ON"],
  ["FORT ALEXANDER", "MB"],
  ["FORT ASSINIBOINE", "AB"],
  ["FORT CHIPEWYAN", "AB"],
  ["FORT ELLIS", "NS"],
  ["FORT ERIE", "ON"],
  ["FORT FRANCES", "ON"],
  ["FORT FRASER", "BC"],
  ["FORT GOOD HOPE", "NT"],
  ["FORT KENT", "AB"],
  ["FORT LIARD", "NT"],
  ["FORT MACKAY", "AB"],
  ["FORT MACLEOD", "AB"],
  ["FORT MCMURRAY", "AB"],
  ["FORT MCPHERSON", "NT"],
  ["FORT NELSON", "BC"],
  ["FORT PROVIDENCE", "NT"],
  ["FORT QU'APPELLE", "SK"],
  ["FORT RESOLUTION", "NT"],
  ["FORT SASKATCHEWAN", "AB"],
  ["FORT SEVERN", "ON"],
  ["FORT SIMPSON", "NT"],
  ["FORT SMITH", "NT"],
  ["FORT ST. JAMES", "BC"],
  ["FORT ST. JOHN", "BC"],
  ["FORT STEELE", "BC"],
  ["FORT VERMILION", "AB"],
  ["FORT-COULONGE", "QC"],
  ["FORTEAU", "NL"],
  ["FORTIERVILLE", "QC"],
  ["FORTRESS OF LOUISBOURG", "NS"],
  ["FORTUNE", "NL"],
  ["FOSSAMBAULT-SUR-LE-LAC", "QC"],
  ["FOSSTON", "SK"],
  ["FOSTER", "QC"],
  ["FOSTERVILLE", "NB"],
  ["FOUR CORNERS", "NB"],
  ["FOUR FALLS", "NB"],
  ["FOUR ROADS", "NB"],
  ["FOURCHU", "NS"],
  ["FOURNIER", "ON"],
  ["FOX CREEK", "AB"],
  ["FOX HARBOUR PB", "NL"],
  ["FOX LAKE", "AB"],
  ["FOX VALLEY", "SK"],
  ["FOXBORO", "ON"],
  ["FOXFORD", "SK"],
  ["FOXWARREN", "MB"],
  ["FOYMOUNT", "ON"],
  ["FRAMBOISE INTERVALE", "NS"],
  ["FRAMBOISE", "NS"],
  ["FRAMPTON", "QC"],
  ["FRANCIS", "SK"],
  ["FRANCOIS LAKE", "BC"],
  ["FRANCOIS", "NL"],
  ["FRANKFORD", "ON"],
  ["FRANKLIN", "MB"],
  ["FRANKLIN", "QC"],
  ["FRANKVILLE", "NS"],
  ["FRANKVILLE", "ON"],
  ["FRANQUELIN", "QC"],
  ["FRASER LAKE", "BC"],
  ["FRASERVILLE", "ON"],
  ["FRASERWOOD", "MB"],
  ["FREDERICKHOUSE", "ON"],
  ["FREDERICKSBURG", "NB"],
  ["FREDERICKTON", "NL"],
  ["FREDERICTON JUNCTION", "NB"],
  ["FREDERICTON", "NB"],
  ["FREE GRANT", "NB"],
  ["FREELTON", "ON"],
  ["FREEPORT", "NS"],
  ["FREETOWN", "PE"],
  ["FRELIGHSBURG", "QC"],
  ["FRENCH LAKE", "NB"],
  ["FRENCH ROAD", "NS"],
  ["FRENCH VILLAGE KINGS CO", "NB"],
  ["FRENCH VILLAGE", "NS"],
  ["FRENCH VILLAGE-YORK", "NB"],
  ["FRENCHMAN BUTTE", "SK"],
  ["FRENCHMANS COVE BOI", "NL"],
  ["FRENCHMANS COVE FB", "NL"],
  ["FRENCHVALE", "NS"],
  ["FRESHWATER PB", "NL"],
  ["FROBISHER", "SK"],
  ["FROG LAKE", "AB"],
  ["FRONTENAC", "QC"],
  ["FRONTIER", "SK"],
  ["FROSTY HOLLOW", "NB"],
  ["FRUITVALE", "BC"],
  ["FUGEREVILLE", "QC"],
  ["FULDA", "SK"],
  ["FULFORD", "QC"],
  ["FULLARTON", "ON"],
  ["FUNDY NATIONAL PARK", "NB"],
  ["FURDALE", "SK"],
  ["FURRY CREEK", "BC"],
  ["GABARUS LAKE", "NS"],
  ["GABARUS", "NS"],
  ["GABRIOLA", "BC"],
  ["GADS HILL STATION", "ON"],
  ["GADSBY", "AB"],
  ["GAGETOWN", "NB"],
  ["GAINFORD", "AB"],
  ["GAINSBOROUGH", "SK"],
  ["GALAHAD", "AB"],
  ["GALIANO", "BC"],
  ["GALLAGHER RIDGE", "NB"],
  ["GALLANTS", "NL"],
  ["GALLICHAN", "QC"],
  ["GALLIVAN", "SK"],
  ["GALLIX", "QC"],
  ["GALLOWAY", "BC"],
  ["GALLOWAY", "NB"],
  ["GAMBO SOUTH", "NL"],
  ["GAMBO", "NL"],
  ["GAMETI", "NT"],
  ["GANANOQUE", "ON"],
  ["GANDER BAY SOUTH", "NL"],
  ["GANDER BAY", "NL"],
  ["GANDER", "NL"],
  ["GANG RANCH", "BC"],
  ["GARDEN BAY", "BC"],
  ["GARDEN COVE PB", "NL"],
  ["GARDEN RIVER", "AB"],
  ["GARDEN RIVER", "ON"],
  ["GARDEN VILLAGE", "ON"],
  ["GARDENTON", "MB"],
  ["GARDINER MINES", "NS"],
  ["GARDINER POINT", "NB"],
  ["GARDNER CREEK", "NB"],
  ["GARIBALDI HIGHLANDS", "BC"],
  ["GARLAND", "MB"],
  ["GARNETT SETTLEMENT", "NB"],
  ["GARNISH", "NL"],
  ["GARRICK", "SK"],
  ["GARSON", "MB"],
  ["GARSON", "ON"],
  ["GASCONS", "QC"],
  ["GASPE", "QC"],
  ["GASPEREAU FORKS", "NB"],
  ["GATINEAU", "ON"],
  ["GATINEAU", "QC"],
  ["GAULTOIS", "NL"],
  ["GAUVREAU", "NB"],
  ["GAYS RIVER", "NS"],
  ["GEARY", "NB"],
  ["GEM", "AB"],
  ["GENELLE", "BC"],
  ["GEORGES RIVER", "NS"],
  ["GEORGETOWN", "ON"],
  ["GEORGETOWN", "PE"],
  ["GEORGEVILLE", "QC"],
  ["GERALD", "SK"],
  ["GERALDTON", "ON"],
  ["GERMANSEN LANDING", "BC"],
  ["GERMANTOWN", "NB"],
  ["GETHSEMANI", "QC"],
  ["GIANTS GLEN", "NB"],
  ["GIBBONS", "AB"],
  ["GIBSONS", "BC"],
  ["GIFT LAKE", "AB"],
  ["GILBERT PLAINS", "MB"],
  ["GILFORD", "ON"],
  ["GILLAM", "MB"],
  ["GILLIES BAY", "BC"],
  ["GILLIS LAKE", "NS"],
  ["GILLIS POINT", "NS"],
  ["GILMOUR", "ON"],
  ["GIMLI", "MB"],
  ["GINEW", "MB"],
  ["GIRARDVILLE", "QC"],
  ["GIROUX", "MB"],
  ["GIROUXVILLE", "AB"],
  ["GITWINKSIHLKW", "BC"],
  ["GJOA HAVEN", "NU"],
  ["GLACE BAY", "NS"],
  ["GLADESIDE", "NB"],
  ["GLADMAR", "SK"],
  ["GLADSTONE", "MB"],
  ["GLADWYN", "NB"],
  ["GLASLYN", "SK"],
  ["GLASNEVIN", "SK"],
  ["GLASSVILLE", "NB"],
  ["GLEICHEN", "AB"],
  ["GLEN EWEN", "SK"],
  ["GLEN HAVEN", "NS"],
  ["GLEN HURON", "ON"],
  ["GLEN MARGARET", "NS"],
  ["GLEN MORRIS", "ON"],
  ["GLEN ROBERTSON", "ON"],
  ["GLENAVON", "SK"],
  ["GLENBAIN", "SK"],
  ["GLENBORO", "MB"],
  ["GLENBURNIE", "ON"],
  ["GLENBUSH", "SK"],
  ["GLENCAIRN", "ON"],
  ["GLENCOE", "NB"],
  ["GLENCOE", "ON"],
  ["GLENDON", "AB"],
  ["GLENELLA", "MB"],
  ["GLENEVIS", "AB"],
  ["GLENLEA", "MB"],
  ["GLENLEVIT", "NB"],
  ["GLENORA", "MB"],
  ["GLENSIDE", "SK"],
  ["GLENTWORTH", "SK"],
  ["GLENVALE", "NB"],
  ["GLENWOOD KINGS CO", "NB"],
  ["GLENWOOD", "AB"],
  ["GLENWOOD", "NB"],
  ["GLENWOOD", "NL"],
  ["GLENWOOD", "NS"],
  ["GLENWOOD.", "NB"],
  ["GLIDDEN", "SK"],
  ["GLOUCESTER JUNCTION", "NB"],
  ["GLOUCESTER", "ON"],
  ["GLOVERTOWN SOUTH", "NL"],
  ["GLOVERTOWN", "NL"],
  ["GODBOUT", "QC"],
  ["GODERICH", "ON"],
  ["GODFREY", "ON"],
  ["GODMANCHESTER", "QC"],
  ["GODS LAKE NARROWS", "MB"],
  ["GODS RIVER", "MB"],
  ["GOFFS", "NS"],
  ["GOGAMA", "ON"],
  ["GOLD BRIDGE", "BC"],
  ["GOLD RIVER", "BC"],
  ["GOLDBORO", "NS"],
  ["GOLDEN LAKE", "ON"],
  ["GOLDEN PRAIRIE", "SK"],
  ["GOLDEN VALLEY", "ON"],
  ["GOLDEN", "BC"],
  ["GOOD CORNER", "NB"],
  ["GOOD HOPE LAKE", "BC"],
  ["GOODERHAM", "ON"],
  ["GOODEVE", "SK"],
  ["GOODFARE", "AB"],
  ["GOODFISH LAKE", "AB"],
  ["GOODLANDS", "MB"],
  ["GOODLOW", "BC"],
  ["GOODRIDGE", "AB"],
  ["GOODSOIL", "SK"],
  ["GOODWATER", "SK"],
  ["GOODWIN MILL", "NB"],
  ["GOODWOOD", "NS"],
  ["GOODWOOD", "ON"],
  ["GOOSEBERRY COVE", "NL"],
  ["GORDONDALE", "AB"],
  ["GORDONSVILLE", "NB"],
  ["GORE BAY", "ON"],
  ["GORE", "QC"],
  ["GORES LANDING", "ON"],
  ["GORMLEY", "ON"],
  ["GORRIE", "ON"],
  ["GOSHEN", "NS"],
  ["GOULAIS RIVER", "ON"],
  ["GOULDS", "NL"],
  ["GOULDTOWN", "SK"],
  ["GOVAN", "SK"],
  ["GOWANSTOWN", "ON"],
  ["GOWGANDA", "ON"],
  ["GRACEFIELD", "QC"],
  ["GRAFTON", "NB"],
  ["GRAFTON", "ON"],
  ["GRAHAM", "ON"],
  ["GRAHAMDALE", "MB"],
  ["GRANBY", "QC"],
  ["GRAND BANK", "NL"],
  ["GRAND BAY EAST", "NL"],
  ["GRAND BAY-WESTFIELD", "NB"],
  ["GRAND BEACH", "NL"],
  ["GRAND BEND", "ON"],
  ["GRAND BRUIT", "NL"],
  ["GRAND ETANG", "NS"],
  ["GRAND FALLS", "NB"],
  ["GRAND FALLS-WINDSOR", "NL"],
  ["GRAND FORKS", "BC"],
  ["GRAND LAKE ROAD", "NB"],
  ["GRAND LAKE ROAD", "NS"],
  ["GRAND LAKE", "NS"],
  ["GRAND LE PIERRE", "NL"],
  ["GRAND MANAN", "NB"],
  ["GRAND MARAIS", "MB"],
  ["GRAND MIRA NORTH", "NS"],
  ["GRAND MIRA SOUTH", "NS"],
  ["GRAND NARROWS", "NS"],
  ["GRAND PRE", "NS"],
  ["GRAND RAPIDS", "MB"],
  ["GRAND RIVER", "NS"],
  ["GRAND VALLEY", "ON"],
  ["GRAND-BARACHOIS", "NB"],
  ["GRAND-MERE", "QC"],
  ["GRAND-REMOUS", "QC"],
  ["GRAND-SAINT-ESPRIT", "QC"],
  ["GRAND-SAULT/GRAND FALLS", "NB"],
  ["GRANDE CACHE", "AB"],
  ["GRANDE POINTE", "MB"],
  ["GRANDE POINTE", "ON"],
  ["GRANDE PRAIRIE", "AB"],
  ["GRANDE-ANSE", "NB"],
  ["GRANDE-DIGUE", "NB"],
  ["GRANDE-ENTREE", "QC"],
  ["GRANDE-RIVIERE", "QC"],
  ["GRANDE-RIVIERE-OUEST", "QC"],
  ["GRANDE-VALLEE", "QC"],
  ["GRANDES-BERGERONNES", "QC"],
  ["GRANDES-PILES", "QC"],
  ["GRANDORA", "SK"],
  ["GRANDVIEW", "MB"],
  ["GRANISLE", "BC"],
  ["GRANTON", "ON"],
  ["GRANUM", "AB"],
  ["GRANVILLE FERRY", "NS"],
  ["GRASMERE", "BC"],
  ["GRASS COVE", "NS"],
  ["GRASSIE", "ON"],
  ["GRASSLAND", "AB"],
  ["GRASSWOOD", "SK"],
  ["GRASSY LAKE", "AB"],
  ["GRASSY NARROWS", "ON"],
  ["GRATES COVE", "NL"],
  ["GRAVEL HILL", "NB"],
  ["GRAVELBOURG", "SK"],
  ["GRAVENHURST", "ON"],
  ["GRAY CREEK", "BC"],
  ["GRAY RAPIDS", "NB"],
  ["GRAY", "SK"],
  ["GRAYSON", "SK"],
  ["GRAYSVILLE", "MB"],
  ["GREAT FALLS", "MB"],
  ["GREAT VILLAGE", "NS"],
  ["GREATER LAKEBURN", "NB"],
  ["GREELY", "ON"],
  ["GREEN BAY", "NS"],
  ["GREEN CREEK", "NS"],
  ["GREEN GABLES", "PE"],
  ["GREEN HILL", "NB"],
  ["GREEN ISLAND BROOK", "NL"],
  ["GREEN ISLAND COVE", "NL"],
  ["GREEN LAKE", "SK"],
  ["GREEN MOUNTAIN", "NB"],
  ["GREEN OAKS", "NS"],
  ["GREEN RIDGE", "MB"],
  ["GREEN ROAD", "NB"],
  ["GREEN VALLEY", "ON"],
  ["GREENBANK", "ON"],
  ["GREENFIELD PARK", "QC"],
  ["GREENFIELD", "NB"],
  ["GREENFIELD", "NS"],
  ["GREENFIELD.", "NS"],
  ["GREENHILL LAKE", "NB"],
  ["GREENS HARBOUR", "NL"],
  ["GREENSPOND", "NL"],
  ["GREENVILLE", "BC"],
  ["GREENWOOD", "BC"],
  ["GREENWOOD", "NS"],
  ["GREENWOOD", "ON"],
  ["GREGG SETTLEMENT", "NB"],
  ["GRENFELL", "SK"],
  ["GRENVILLE", "QC"],
  ["GRENVILLE-SUR-LA-ROUGE", "QC"],
  ["GRETNA", "MB"],
  ["GREY RIVER", "NL"],
  ["GRIFFIN", "SK"],
  ["GRIFFITH", "ON"],
  ["GRIMMS SETTLEMENT", "NS"],
  ["GRIMSBY", "ON"],
  ["GRIMSHAW", "AB"],
  ["GRINDROD", "BC"],
  ["GRISE FIORD", "NU"],
  ["GRISWOLD", "MB"],
  ["GRONDINES", "QC"],
  ["GRONLID", "SK"],
  ["GROS-MORNE", "QC"],
  ["GROSSE ISLE", "MB"],
  ["GROSSE-ILE", "QC"],
  ["GROSSES-ROCHES", "QC"],
  ["GROUARD", "AB"],
  ["GROUNDBIRCH", "BC"],
  ["GROVE HILL", "NB"],
  ["GROVEDALE", "AB"],
  ["GROVES POINT", "NS"],
  ["GRUNTHAL", "MB"],
  ["GUELPH", "ON"],
  ["GUERIN", "QC"],
  ["GUERNSEY", "SK"],
  ["GULL BAY", "ON"],
  ["GULL LAKE", "SK"],
  ["GUNDY", "AB"],
  ["GUNN", "AB"],
  ["GUNTON", "MB"],
  ["GUY", "AB"],
  ["GUYENNE", "QC"],
  ["GUYSBOROUGH", "NS"],
  ["GWYNNE", "AB"],
  ["GYPSUMVILLE", "MB"],
  ["HACHEYVILLE", "NB"],
  ["HACKETTS COVE", "NS"],
  ["HADASHVILLE", "MB"],
  ["HAFFORD", "SK"],
  ["HAGAR", "ON"],
  ["HAGEN", "SK"],
  ["HAGENSBORG", "BC"],
  ["HAGERSVILLE", "ON"],
  ["HAGUE", "SK"],
  ["HAILEYBURY", "ON"],
  ["HAINES JUNCTION", "YT"],
  ["HAIRY HILL", "AB"],
  ["HAISLA", "BC"],
  ["HALBRITE", "SK"],
  ["HALBSTADT", "MB"],
  ["HALCOMB", "NB"],
  ["HALEY STATION", "ON"],
  ["HALFMOON BAY", "AB"],
  ["HALFMOON BAY", "BC"],
  ["HALIBURTON", "ON"],
  ["HALIBUT BAY", "NS"],
  ["HALIFAX", "NS"],
  ["HALKIRK", "AB"],
  ["HALL BEACH", "NU"],
  ["HALLEBOURG", "ON"],
  ["HAM-NORD", "QC"],
  ["HAMILTON", "ON"],
  ["HAMIOTA", "MB"],
  ["HAMMOND", "ON"],
  ["HAMMONDS PLAINS", "NS"],
  ["HAMMONDVALE", "NB"],
  ["HAMPDEN", "NL"],
  ["HAMPSTEAD", "NB"],
  ["HAMPSTEAD", "QC"],
  ["HAMPTON", "NB"],
  ["HAMPTON", "NS"],
  ["HAMPTON", "ON"],
  ["HAMTOWN CORNER", "NB"],
  ["HANCEVILLE", "BC"],
  ["HANDEL", "SK"],
  ["HANEYTOWN", "NB"],
  ["HANFORD BROOK", "NB"],
  ["HANLEY", "SK"],
  ["HANMER", "ON"],
  ["HANNA", "AB"],
  ["HANNON", "ON"],
  ["HANOVER", "ON"],
  ["HANTS HARBOUR", "NL"],
  ["HANTSPORT", "NS"],
  ["HANWELL", "NB"],
  ["HAPPY VALLEY-GOOSE BAY", "NL"],
  ["HARBOUR BRETON", "NL"],
  ["HARBOUR GRACE SOUTH", "NL"],
  ["HARBOUR GRACE", "NL"],
  ["HARBOUR MAIN", "NL"],
  ["HARBOUR MILLE", "NL"],
  ["HARBOUR ROUND", "NL"],
  ["HARCOURT", "NB"],
  ["HARCOURT", "ON"],
  ["HARDING", "MB"],
  ["HARDISTY", "AB"],
  ["HARDWICKE", "NB"],
  ["HARDWOOD LANDS", "NS"],
  ["HARDWOOD RIDGE", "NB"],
  ["HARE BAY BB", "NL"],
  ["HAREWOOD", "NB"],
  ["HARGRAVE", "MB"],
  ["HARLEY", "ON"],
  ["HARMONY", "NS"],
  ["HARRIETSFIELD", "NS"],
  ["HARRIETSVILLE", "ON"],
  ["HARRINGTON HARBOUR", "QC"],
  ["HARRINGTON", "QC"],
  ["HARRIS", "SK"],
  ["HARRISON HOT SPRINGS", "BC"],
  ["HARRISON MILLS", "BC"],
  ["HARRISTON", "ON"],
  ["HARROGATE", "BC"],
  ["HARROW", "ON"],
  ["HARROWSMITH", "ON"],
  ["HARRYS HARBOUR", "NL"],
  ["HARTFIELD", "NB"],
  ["HARTFORD", "NB"],
  ["HARTIN SETTLEMENT", "NB"],
  ["HARTINGTON", "ON"],
  ["HARTLAND", "NB"],
  ["HARTLEY BAY", "BC"],
  ["HARTLEY SETTLEMENT", "NB"],
  ["HARTNEY", "MB"],
  ["HARTY", "ON"],
  ["HARVEY ALBERT CO", "NB"],
  ["HARVEY STATION", "NB"],
  ["HARVEY YORK CO", "NB"],
  ["HARVIE HEIGHTS", "AB"],
  ["HARWOOD", "ON"],
  ["HASTINGS", "ON"],
  ["HATCHET LAKE", "NS"],
  ["HATFIELD POINT", "NB"],
  ["HATLEY", "QC"],
  ["HAUT-LAMEQUE", "NB"],
  ["HAUT-PAQUETVILLE", "NB"],
  ["HAUT-RIVIERE-DU-PORTAGE", "NB"],
  ["HAUT-SAINT-ANTOINE", "NB"],
  ["HAUT-SHEILA", "NB"],
  ["HAUT-SHIPPAGAN", "NB"],
  ["HAUTE-ABOUJAGANE", "NB"],
  ["HAVELOCK", "NB"],
  ["HAVELOCK", "ON"],
  ["HAVELOCK", "QC"],
  ["HAVRE BOUCHER", "NS"],
  ["HAVRE-AUBERT", "QC"],
  ["HAVRE-AUX-MAISONS", "QC"],
  ["HAVRE-SAINT-PIERRE", "QC"],
  ["HAWARDEN", "SK"],
  ["HAWK JUNCTION", "ON"],
  ["HAWKES BAY", "NL"],
  ["HAWKESBURY", "ON"],
  ["HAWKESTONE", "ON"],
  ["HAWKESVILLE", "ON"],
  ["HAWKINS CORNER", "NB"],
  ["HAWKSHAW", "NB"],
  ["HAY LAKES", "AB"],
  ["HAY RIVER", "NT"],
  ["HAY SETTLEMENT", "NB"],
  ["HAY", "ON"],
  ["HAYDON", "ON"],
  ["HAYESVILLE", "NB"],
  ["HAYMAN HILL", "NB"],
  ["HAYS", "AB"],
  ["HAYTER", "AB"],
  ["HAYWOOD", "MB"],
  ["HAZEL DELL", "SK"],
  ["HAZELDEAN", "NB"],
  ["HAZELRIDGE", "MB"],
  ["HAZELTON", "BC"],
  ["HAZELTON", "NB"],
  ["HAZENMORE", "SK"],
  ["HAZLET", "SK"],
  ["HEAD BAY D'ESPOIR", "NL"],
  ["HEAD OF CHEZZETCOOK", "NS"],
  ["HEAD OF JEDDORE", "NS"],
  ["HEAD OF MILLSTREAM", "NB"],
  ["HEAD OF ST MARGARETS BAY", "NS"],
  ["HEADINGLEY", "MB"],
  ["HEARNE", "SK"],
  ["HEARST", "ON"],
  ["HEART'S CONTENT", "NL"],
  ["HEART'S DELIGHT", "NL"],
  ["HEART'S DESIRE", "NL"],
  ["HEATHCOTE", "ON"],
  ["HEATHERTON", "NL"],
  ["HEATHERTON", "NS"],
  ["HEATHLAND", "NB"],
  ["HEBBS CROSS", "NS"],
  ["HEBBVILLE", "NS"],
  ["HEBERT", "NB"],
  ["HEBERTVILLE", "QC"],
  ["HEBERTVILLE-STATION", "QC"],
  ["HEBRON", "NB"],
  ["HEBRON", "NS"],
  ["HEDLEY", "BC"],
  ["HEFFLEY CREEK", "BC"],
  ["HEIDELBERG", "ON"],
  ["HEINSBURG", "AB"],
  ["HEISLER", "AB"],
  ["HEMMINGFORD", "QC"],
  ["HENDERSON SETTLEMENT", "NB"],
  ["HENDON", "SK"],
  ["HENRIBOURG", "SK"],
  ["HENRYVILLE", "QC"],
  ["HENSALL", "ON"],
  ["HEPBURN", "SK"],
  ["HEPWORTH", "ON"],
  ["HERBERT", "SK"],
  ["HERIOT BAY", "BC"],
  ["HERMITAGE", "NL"],
  ["HERON BAY", "ON"],
  ["HEROUXVILLE", "QC"],
  ["HERRING COVE", "NS"],
  ["HERRING NECK", "NL"],
  ["HERSCHEL", "SK"],
  ["HERSONVILLE", "NB"],
  ["HEWARD", "SK"],
  ["HIBERNIA COVE", "NB"],
  ["HICKMANS HARBOUR", "NL"],
  ["HICKSON", "ON"],
  ["HICKSVILLE", "NB"],
  ["HIGH BLUFF", "MB"],
  ["HIGH LEVEL", "AB"],
  ["HIGH PRAIRIE", "AB"],
  ["HIGH RIVER", "AB"],
  ["HIGHFIELD", "NB"],
  ["HIGHGATE", "ON"],
  ["HIGHLAND GROVE", "ON"],
  ["HIGHLAND HILL", "NS"],
  ["HIGHLANDS", "NL"],
  ["HILBRE", "MB"],
  ["HILDA", "AB"],
  ["HILL SPRING", "AB"],
  ["HILLANDALE", "NB"],
  ["HILLCREST MINES", "AB"],
  ["HILLGRADE", "NL"],
  ["HILLGROVE", "NB"],
  ["HILLIARD", "AB"],
  ["HILLIARDTON", "ON"],
  ["HILLIER", "ON"],
  ["HILLSBOROUGH WEST", "NB"],
  ["HILLSBOROUGH", "NB"],
  ["HILLSBURGH", "ON"],
  ["HILLSDALE", "NB"],
  ["HILLSDALE", "ON"],
  ["HILLSIDE BOULARDERIE", "NS"],
  ["HILLTOP", "NB"],
  ["HILLVIEW", "NL"],
  ["HILTON BEACH", "ON"],
  ["HINCHINBROOKE", "QC"],
  ["HINES CREEK", "AB"],
  ["HINTON", "AB"],
  ["HIXON", "BC"],
  ["HOBBEMA", "AB"],
  ["HODGES COVE", "NL"],
  ["HODGEVILLE", "SK"],
  ["HODGSON", "MB"],
  ["HOEY", "SK"],
  ["HOLBEIN", "SK"],
  ["HOLBERG", "BC"],
  ["HOLDEN", "AB"],
  ["HOLDFAST", "SK"],
  ["HOLLAND CENTRE", "ON"],
  ["HOLLAND LANDING", "ON"],
  ["HOLLAND", "MB"],
  ["HOLMESVILLE", "NB"],
  ["HOLMFIELD", "MB"],
  ["HOLSTEIN", "ON"],
  ["HOLTVILLE", "NB"],
  ["HOLTYRE", "ON"],
  ["HOLYROOD", "NL"],
  ["HOLYROOD", "ON"],
  ["HOMEVILLE", "NS"],
  ["HOMEWOOD", "MB"],
  ["HONDO", "AB"],
  ["HONEY HARBOUR", "ON"],
  ["HONEYDALE", "NB"],
  ["HONEYMOON BAY", "BC"],
  ["HONEYWOOD", "ON"],
  ["HONFLEUR", "QC"],
  ["HOOSIER", "SK"],
  ["HOPE", "BC"],
  ["HOPE", "QC"],
  ["HOPEALL", "NL"],
  ["HOPEDALE", "NL"],
  ["HOPEWELL CAPE", "NB"],
  ["HOPEWELL HILL", "NB"],
  ["HOPEWELL", "NS"],
  ["HORNBY ISLAND", "BC"],
  ["HORNBY", "ON"],
  ["HORNDEAN", "MB"],
  ["HORNELL HEIGHTS", "ON"],
  ["HORNEPAYNE", "ON"],
  ["HORNINGS MILLS", "ON"],
  ["HORSEFLY", "BC"],
  ["HORWOOD", "NL"],
  ["HOTCHKISS", "AB"],
  ["HOUSTON", "BC"],
  ["HOWARD BROOK", "NB"],
  ["HOWARD", "NB"],
  ["HOWDEN", "MB"],
  ["HOWICK", "QC"],
  ["HOWIE CENTER", "NS"],
  ["HOWLAND RIDGE", "NB"],
  ["HOWLEY", "NL"],
  ["HOYT", "NB"],
  ["HUBBARD", "SK"],
  ["HUBBARDS", "NS"],
  ["HUBERDEAU", "QC"],
  ["HUBLEY", "NS"],
  ["HUDSON BAY", "SK"],
  ["HUDSON HEIGHTS", "QC"],
  ["HUDSON", "ON"],
  ["HUDSON", "QC"],
  ["HUDSON'S HOPE", "BC"],
  ["HUGHENDEN", "AB"],
  ["HUMBOLDT", "SK"],
  ["HUNTA", "ON"],
  ["HUNTERS HOME", "NB"],
  ["HUNTINGDON", "QC"],
  ["HUNTINGTON", "NS"],
  ["HUNTS POINT", "NS"],
  ["HUNTSVILLE", "ON"],
  ["HURON PARK", "ON"],
  ["HUSSAR", "AB"],
  ["HUXLEY", "AB"],
  ["HYAS", "SK"],
  ["HYLO", "AB"],
  ["HYTHE", "AB"],
  ["IDDESLEIGH", "AB"],
  ["IGLOOLIK", "NU"],
  ["IGNACE", "ON"],
  ["ILDERTON", "ON"],
  ["ILE DES CHENES", "MB"],
  ["ILE DU GRAND-CALUMET", "QC"],
  ["ILE-A-LA-CROSSE", "SK"],
  ["ILE-AUX-NOIX", "QC"],
  ["ILFORD", "MB"],
  ["IMMIGRANT ROAD", "NB"],
  ["IMPERIAL", "SK"],
  ["INDIAN BAY BB", "NL"],
  ["INDIAN BROOK 14", "NS"],
  ["INDIAN HARBOUR", "NS"],
  ["INDIAN HEAD", "SK"],
  ["INDIAN ISLAND", "NB"],
  ["INDIAN MOUNTAIN", "NB"],
  ["INDIAN PATH", "NS"],
  ["INDIAN RIVER", "ON"],
  ["INDUS", "AB"],
  ["INGERSOLL", "ON"],
  ["INGLESIDE", "ON"],
  ["INGLEWOOD", "ON"],
  ["INGLIS", "MB"],
  ["INGOLF", "ON"],
  ["INGOMAR", "NS"],
  ["INGONISH BEACH", "NS"],
  ["INGONISH", "NS"],
  ["INGRAMPORT", "NS"],
  ["INKERMAN FERRY", "NB"],
  ["INKERMAN", "NB"],
  ["INKERMAN", "ON"],
  ["INNERKIP", "ON"],
  ["INNISFAIL", "AB"],
  ["INNISFIL", "ON"],
  ["INNISFREE", "AB"],
  ["INSINGER", "SK"],
  ["INTERVALE", "NB"],
  ["INUKJUAK", "QC"],
  ["INUVIK", "NT"],
  ["INVERARY", "ON"],
  ["INVERMAY", "SK"],
  ["INVERMERE", "BC"],
  ["INVERNESS", "NS"],
  ["INVERNESS", "QC"],
  ["INWOOD", "MB"],
  ["INWOOD", "ON"],
  ["IONA STATION", "ON"],
  ["IONA", "NS"],
  ["IQALUIT", "NU"],
  ["IRISH COVE", "NS"],
  ["IRISH SETTLEMENT", "NB"],
  ["IRISHTOWN", "NB"],
  ["IRISHVALE", "NS"],
  ["IRLANDE", "QC"],
  ["IRMA", "AB"],
  ["IRON BOUND COVE", "NB"],
  ["IRON BRIDGE", "ON"],
  ["IRON RIVER", "AB"],
  ["IRON SPRINGS", "AB"],
  ["IRONDALE", "ON"],
  ["IRONVILLE", "NS"],
  ["IROQUOIS FALLS A", "ON"],
  ["IROQUOIS FALLS", "ON"],
  ["IROQUOIS", "ON"],
  ["IRRICANA", "AB"],
  ["IRVINE", "AB"],
  ["ISAACS HARBOUR", "NS"],
  ["ISABELLA", "MB"],
  ["ISKUT", "BC"],
  ["ISLAND HARBOUR", "NL"],
  ["ISLAND LAKE SOUTH", "AB"],
  ["ISLAND LAKE", "AB"],
  ["ISLAND LAKE", "MB"],
  ["ISLAND LAKE", "SK"],
  ["ISLAND VIEW", "NB"],
  ["ISLANDVIEW", "NS"],
  ["ISLAY", "AB"],
  ["ISLE-AUX-COUDRES", "QC"],
  ["ISLE-AUX-MORTS", "NL"],
  ["ISLINGTON", "NL"],
  ["ISSOUDUN", "QC"],
  ["ITALY CROSS", "NS"],
  ["ITUNA", "SK"],
  ["IVRY-SUR-LE-LAC", "QC"],
  ["IVUJIVIK", "QC"],
  ["JACKFISH LAKE", "SK"],
  ["JACKSON FALLS", "NB"],
  ["JACKSONS ARM", "NL"],
  ["JACKSONS COVE", "NL"],
  ["JACKSONS POINT", "ON"],
  ["JACKSONTOWN", "NB"],
  ["JACKSONVILLE", "NB"],
  ["JACQUES-CARTIER", "QC"],
  ["JADE CITY", "BC"],
  ["JAFFRAY", "BC"],
  ["JAMES RIVER BRIDGE", "AB"],
  ["JAMESVILLE", "NS"],
  ["JANETVILLE", "ON"],
  ["JANEVILLE", "NB"],
  ["JANSEN", "SK"],
  ["JARDINEVILLE", "NB"],
  ["JARVIE", "AB"],
  ["JARVIS BAY", "AB"],
  ["JARVIS", "ON"],
  ["JASPER", "AB"],
  ["JASPER", "ON"],
  ["JEAN COTE", "AB"],
  ["JEDBURGH", "SK"],
  ["JEDDORE OYSTER PONDS", "NS"],
  ["JEFFREYS", "NL"],
  ["JEFFRIES CORNER", "NB"],
  ["JELLICOE", "ON"],
  ["JEMSEG", "NB"],
  ["JENNER", "AB"],
  ["JERSEYSIDE", "NL"],
  ["JERSEYVILLE", "ON"],
  ["JEWETTS MILLS", "NB"],
  ["JOBS COVE", "NL"],
  ["JOE BATTS ARM", "NL"],
  ["JOGGINS", "NS"],
  ["JOGUES", "ON"],
  ["JOHN D'OR PRAIRIE", "AB"],
  ["JOHNSON SETTLEMENT CHARLOTTE C", "NB"],
  ["JOHNSON SETTLEMENT YORK CO", "NB"],
  ["JOHNSON'S MILLS", "NB"],
  ["JOHNSTON POINT", "NB"],
  ["JOHNVILLE", "NB"],
  ["JOLICURE", "NB"],
  ["JOLIETTE", "QC"],
  ["JOLY", "QC"],
  ["JONQUIERE", "QC"],
  ["JORDAN FALLS", "NS"],
  ["JORDAN MOUNTAIN", "NB"],
  ["JORDAN RIVER", "BC"],
  ["JORDAN STATION", "ON"],
  ["JOUSSARD", "AB"],
  ["JOYCEVILLE", "ON"],
  ["JUDIQUE", "NS"],
  ["JUNIPER ISLAND", "ON"],
  ["JUNIPER MOUNTAIN", "NS"],
  ["JUNIPER", "NB"],
  ["JUSKATLA", "BC"],
  ["JUSTICE", "MB"],
  ["KAGAWONG", "ON"],
  ["KAHNAWAKE", "QC"],
  ["KAKABEKA FALLS", "ON"],
  ["KALADAR", "ON"],
  ["KALEDEN", "BC"],
  ["KAMINISTIQUIA", "ON"],
  ["KAMLOOPS", "BC"],
  ["KAMOURASKA", "QC"],
  ["KAMSACK", "SK"],
  ["KANANASKIS", "AB"],
  ["KANATA", "ON"],
  ["KANGIQSUALUJJUAQ", "QC"],
  ["KANGIQSUJUAQ", "QC"],
  ["KANGIRSUK", "QC"],
  ["KAPASIWIN", "AB"],
  ["KAPUSKASING", "ON"],
  ["KARS", "NB"],
  ["KARS", "ON"],
  ["KASABONIKA", "ON"],
  ["KASHABOWIE", "ON"],
  ["KASHECHEWAN", "ON"],
  ["KASLO", "BC"],
  ["KATHYRN", "AB"],
  ["KATRINE", "ON"],
  ["KAWAWACHIKAMACH", "QC"],
  ["KAYVILLE", "SK"],
  ["KAZABAZUA", "QC"],
  ["KEARNEY", "ON"],
  ["KEARNS", "ON"],
  ["KEDGWICK NORD", "NB"],
  ["KEDGWICK OUEST", "NB"],
  ["KEDGWICK RIVER", "NB"],
  ["KEDGWICK SUD", "NB"],
  ["KEDGWICK", "NB"],
  ["KEELER", "SK"],
  ["KEELS", "NL"],
  ["KEENANS", "NB"],
  ["KEENE", "ON"],
  ["KEEWATIN", "ON"],
  ["KEEWAYWIN", "ON"],
  ["KEG RIVER", "AB"],
  ["KEGASKA", "QC"],
  ["KEHEWIN", "AB"],
  ["KEJICK", "ON"],
  ["KELFIELD", "SK"],
  ["KELLIHER", "SK"],
  ["KELOWNA", "BC"],
  ["KELSEY", "AB"],
  ["KELVINGTON", "SK"],
  ["KELWOOD", "MB"],
  ["KEMBLE", "ON"],
  ["KEMPT HEAD", "NS"],
  ["KEMPTOWN", "NS"],
  ["KEMPTVILLE", "NS"],
  ["KEMPTVILLE", "ON"],
  ["KENABEEK", "ON"],
  ["KENASTON", "SK"],
  ["KENDAL", "ON"],
  ["KENDAL", "SK"],
  ["KENILWORTH", "ON"],
  ["KENMORE", "ON"],
  ["KENNEDY", "SK"],
  ["KENNETCOOK", "NS"],
  ["KENORA", "ON"],
  ["KENOSEE LAKE", "SK"],
  ["KENSINGTON", "PE"],
  ["KENT BRIDGE", "ON"],
  ["KENT JUNCTION", "NB"],
  ["KENTON", "MB"],
  ["KENTVILLE", "NS"],
  ["KENVILLE", "MB"],
  ["KEOMA", "AB"],
  ["KEREMEOS", "BC"],
  ["KERROBERT", "SK"],
  ["KERWOOD", "ON"],
  ["KESWICK RIDGE", "NB"],
  ["KESWICK", "NB"],
  ["KESWICK", "ON"],
  ["KETCH HARBOUR", "NS"],
  ["KETTLEBY", "ON"],
  ["KHEDIVE", "SK"],
  ["KIAMIKA", "QC"],
  ["KIERSTEAD MOUNTAIN", "NB"],
  ["KIERSTEADVILLE", "NB"],
  ["KIKINO", "AB"],
  ["KILBRIDE", "NL"],
  ["KILBURN", "NB"],
  ["KILDONAN", "BC"],
  ["KILLALOE", "ON"],
  ["KILLALY", "SK"],
  ["KILLAM", "AB"],
  ["KILLAMS MILLS", "NB"],
  ["KILLARNEY ROAD", "NB"],
  ["KILLARNEY", "MB"],
  ["KILLARNEY", "ON"],
  ["KILLOWEEN", "NB"],
  ["KILWORTHY", "ON"],
  ["KIMBERLEY", "BC"],
  ["KIMBERLEY", "ON"],
  ["KIMMIRUT", "NU"],
  ["KINBURN", "ON"],
  ["KINCAID", "SK"],
  ["KINCARDINE", "NB"],
  ["KINCARDINE", "ON"],
  ["KINCOLITH", "BC"],
  ["KINDERSLEY", "SK"],
  ["KING CITY", "ON"],
  ["KING KIRKLAND", "ON"],
  ["KINGCOME INLET", "BC"],
  ["KINGFISHER LAKE", "ON"],
  ["KINGMAN", "AB"],
  ["KINGS COVE", "NL"],
  ["KINGS LANDING HISTORICAL SETTL", "NB"],
  ["KINGS POINT", "NL"],
  ["KINGSBURY", "QC"],
  ["KINGSCLEAR FIRST NATION", "NB"],
  ["KINGSEY FALLS", "QC"],
  ["KINGSGATE", "BC"],
  ["KINGSLEY", "NB"],
  ["KINGSTON", "NB"],
  ["KINGSTON", "NS"],
  ["KINGSTON", "ON"],
  ["KINGSVILLE", "ON"],
  ["KINISTINO", "SK"],
  ["KINKORA", "PE"],
  ["KINLEY", "SK"],
  ["KINMOUNT", "ON"],
  ["KINNEAR SETTLEMENT", "NB"],
  ["KINNEAR'S MILLS", "QC"],
  ["KINOOSAO", "SK"],
  ["KINOSOTA", "MB"],
  ["KINSAC", "NS"],
  ["KINSELLA", "AB"],
  ["KINTORE", "ON"],
  ["KINUSO", "AB"],
  ["KIPAWA", "QC"],
  ["KIPLING", "SK"],
  ["KIPPEN", "ON"],
  ["KIPPENS", "NL"],
  ["KIRKELLA", "MB"],
  ["KIRKFIELD", "ON"],
  ["KIRKLAND LAKE", "ON"],
  ["KIRKLAND", "NB"],
  ["KIRKLAND", "QC"],
  ["KIRKTON", "ON"],
  ["KIRRIEMUIR", "AB"],
  ["KISBEY", "SK"],
  ["KITCHENER", "BC"],
  ["KITCHENER", "ON"],
  ["KITIMAT", "BC"],
  ["KITKATLA", "BC"],
  ["KITSCOTY", "AB"],
  ["KITWANGA", "BC"],
  ["KLEEFELD", "MB"],
  ["KLEENA KLEENE", "BC"],
  ["KLEINBURG", "ON"],
  ["KLEMTU", "BC"],
  ["KNIGHTS COVE", "NL"],
  ["KNIGHTVILLE", "NB"],
  ["KNOWLESVILLE", "NB"],
  ["KNOWLTON", "QC"],
  ["KNUTSFORD", "BC"],
  ["KOKSILAH", "BC"],
  ["KOLA", "MB"],
  ["KOMARNO", "MB"],
  ["KOMOKA", "ON"],
  ["KOOSTATAK", "MB"],
  ["KOOTENAY BAY", "BC"],
  ["KOUCHIBOUGUAC NATIONAL PARK", "NB"],
  ["KOUCHIBOUGUAC", "NB"],
  ["KRONAU", "SK"],
  ["KRYDOR", "SK"],
  ["KUGAARUK", "NU"],
  ["KUGLUKTUK", "NU"],
  ["KUROKI", "SK"],
  ["KUUJJUAQ", "QC"],
  ["KUUJJUARAPIK", "QC"],
  ["KYLE", "SK"],
  ["KYLEMORE", "SK"],
  ["KYUQUOT", "BC"],
  ["L'ALVERNE", "QC"],
  ["L'AMABLE", "ON"],
  ["L'ANCIENNE-LORETTE", "QC"],
  ["L'ANGE GARDIEN", "QC"],
  ["L'ANGE-GARDIEN", "QC"],
  ["L'ANSE AU CLAIR", "NL"],
  ["L'ANSE AU LOUP", "NL"],
  ["L'ANSE-PLEUREUSE", "QC"],
  ["L'ANSE-SAINT-JEAN", "QC"],
  ["L'ARDOISE", "NS"],
  ["L'ASCENSION", "QC"],
  ["L'ASCENSION-DE-NOTRE-SEIGNEUR", "QC"],
  ["L'ASCENSION-DE-PATAPEDIA", "QC"],
  ["L'ASSOMPTION", "QC"],
  ["L'AVENIR", "QC"],
  ["L'EPIPHANIE", "QC"],
  ["L'ETANG-DU-NORD", "QC"],
  ["L'ETETE", "NB"],
  ["L'ILE-BIZARD", "QC"],
  ["L'ILE-CADIEUX", "QC"],
  ["L'ILE-D'ENTREE", "QC"],
  ["L'ILE-MICHON", "QC"],
  ["L'ILE-PERROT", "QC"],
  ["L'ISLE-AUX-GRUES", "QC"],
  ["L'ISLE-VERTE", "QC"],
  ["L'ISLET", "QC"],
  ["L'ISLETVILLE", "QC"],
  ["L'ORIGNAL", "ON"],
  ["LA BAIE", "QC"],
  ["LA BALEINE", "QC"],
  ["LA BARRIERE", "MB"],
  ["LA BOSTONNAIS", "QC"],
  ["LA BROQUERIE", "MB"],
  ["LA CONCEPTION", "QC"],
  ["LA COREY", "AB"],
  ["LA CORNE", "QC"],
  ["LA CRETE", "AB"],
  ["LA CROCHE", "QC"],
  ["LA DORE", "QC"],
  ["LA DURANTAYE", "QC"],
  ["LA GLACE", "AB"],
  ["LA GUADELOUPE", "QC"],
  ["LA HAVE", "NS"],
  ["LA LOCHE", "SK"],
  ["LA MACAZA", "QC"],
  ["LA MALBAIE", "QC"],
  ["LA MARTRE", "QC"],
  ["LA MINERVE", "QC"],
  ["LA MORANDIERE", "QC"],
  ["LA MOTTE", "QC"],
  ["LA PATRIE", "QC"],
  ["LA POCATIERE", "QC"],
  ["LA POILE", "NL"],
  ["LA PRAIRIE", "QC"],
  ["LA PRESENTATION", "QC"],
  ["LA REDEMPTION", "QC"],
  ["LA REINE", "QC"],
  ["LA RIVIERE", "MB"],
  ["LA RONGE", "SK"],
  ["LA SALETTE", "ON"],
  ["LA SALLE", "MB"],
  ["LA SARRE", "QC"],
  ["LA SCIE", "NL"],
  ["LA TABATIERE", "QC"],
  ["LA TRINITE-DES-MONTS", "QC"],
  ["LA TUQUE", "QC"],
  ["LA VISITATION-DE-L'ILE-DUPAS", "QC"],
  ["LA VISITATION-DE-YAMASKA", "QC"],
  ["LABELLE", "NS"],
  ["LABELLE", "QC"],
  ["LABRADOR CITY", "NL"],
  ["LABRECQUE", "QC"],
  ["LAC BAKER", "NB"],
  ["LAC BROCHET", "MB"],
  ["LAC DES ARCS", "AB"],
  ["LAC DES LOUPS", "QC"],
  ["LAC DU BONNET", "MB"],
  ["LAC LA BICHE", "AB"],
  ["LAC LA HACHE", "BC"],
  ["LAC SEUL", "ON"],
  ["LAC VERT", "SK"],
  ["LAC-A-LA-TORTUE", "QC"],
  ["LAC-AU-SAUMON", "QC"],
  ["LAC-AUX-SABLES", "QC"],
  ["LAC-BEAUPORT", "QC"],
  ["LAC-BOUCHETTE", "QC"],
  ["LAC-CAYAMANT", "QC"],
  ["LAC-DELAGE", "QC"],
  ["LAC-DES-AIGLES", "QC"],
  ["LAC-DES-ECORCES", "QC"],
  ["LAC-DES-ILES", "QC"],
  ["LAC-DES-PLAGES", "QC"],
  ["LAC-DES-SEIZE-ILES", "QC"],
  ["LAC-DROLET", "QC"],
  ["LAC-DU-CERF", "QC"],
  ["LAC-EDOUARD", "QC"],
  ["LAC-ETCHEMIN", "QC"],
  ["LAC-FRONTIERE", "QC"],
  ["LAC-HUMQUI", "QC"],
  ["LAC-KENOGAMI", "QC"],
  ["LAC-MEGANTIC", "QC"],
  ["LAC-SAGUAY", "QC"],
  ["LAC-SAINT-JOSEPH", "QC"],
  ["LAC-SAINT-PAUL", "QC"],
  ["LAC-SAINTE-MARIE", "QC"],
  ["LAC-SERGENT", "QC"],
  ["LAC-SIMON", "QC"],
  ["LAC-SUPERIEUR", "QC"],
  ["LACADENA", "SK"],
  ["LACHINE", "QC"],
  ["LACHUTE", "QC"],
  ["LACOLLE", "QC"],
  ["LACOMBE COUNTY", "AB"],
  ["LACOMBE", "AB"],
  ["LACONIA", "NS"],
  ["LADLE COVE", "NL"],
  ["LADRIERE", "QC"],
  ["LADYSMITH", "BC"],
  ["LADYSMITH", "QC"],
  ["LAFLECHE", "SK"],
  ["LAFOND", "AB"],
  ["LAFORCE", "QC"],
  ["LAGACEVILLE", "NB"],
  ["LAIRD", "SK"],
  ["LAJORD", "SK"],
  ["LAKE ALMA", "SK"],
  ["LAKE AUDY", "MB"],
  ["LAKE CHARLOTTE", "NS"],
  ["LAKE COWICHAN", "BC"],
  ["LAKE ECHO", "NS"],
  ["LAKE EDWARD", "NB"],
  ["LAKE EGMONT", "NS"],
  ["LAKE ERROCK", "BC"],
  ["LAKE FRANCIS", "MB"],
  ["LAKE GEORGE", "NB"],
  ["LAKE ISLE", "AB"],
  ["LAKE LENORE", "SK"],
  ["LAKE LOUISE", "AB"],
  ["LAKE MANITOBA FIRST NATION", "MB"],
  ["LAKE OF BAYS", "ON"],
  ["LAKE ST PETER", "ON"],
  ["LAKE UIST", "NS"],
  ["LAKEFIELD", "ON"],
  ["LAKEHURST", "ON"],
  ["LAKELAND", "MB"],
  ["LAKESIDE", "NB"],
  ["LAKESIDE", "NS"],
  ["LAKESIDE", "ON"],
  ["LAKETON", "NB"],
  ["LAKEVIEW", "NS"],
  ["LAKEVILLE CARLETON CO", "NB"],
  ["LAKEVILLE CORNER", "NB"],
  ["LAKEVILLE-WESTMORLAND", "NB"],
  ["LAMALINE", "NL"],
  ["LAMARCHE", "QC"],
  ["LAMARTINE", "QC"],
  ["LAMBERT'S COVE", "NB"],
  ["LAMBERTVILLE", "NB"],
  ["LAMBTON", "QC"],
  ["LAMEQUE", "NB"],
  ["LAMONT", "AB"],
  ["LAMPMAN", "SK"],
  ["LANARK", "ON"],
  ["LANCASTER PARK", "AB"],
  ["LANCASTER", "ON"],
  ["LANCE COVE", "NL"],
  ["LANCER", "SK"],
  ["LANDIS", "SK"],
  ["LANDMARK", "MB"],
  ["LANDRIENNE", "QC"],
  ["LANDRY OFFICE", "NB"],
  ["LANESVILLE", "NS"],
  ["LANG", "SK"],
  ["LANGBANK", "SK"],
  ["LANGDON", "AB"],
  ["LANGENBURG", "SK"],
  ["LANGHAM", "SK"],
  ["LANGLEY", "BC"],
  ["LANGRUTH", "MB"],
  ["LANGTON", "ON"],
  ["LANIEL", "QC"],
  ["LANIGAN", "SK"],
  ["LANORAIE", "QC"],
  ["LANSDOWNE HOUSE", "ON"],
  ["LANSDOWNE", "NB"],
  ["LANSDOWNE", "ON"],
  ["LANTIER", "QC"],
  ["LANTZ", "NS"],
  ["LANTZVILLE", "BC"],
  ["LAPLAND", "NS"],
  ["LAPLANTE", "NB"],
  ["LAPORTE", "SK"],
  ["LARDER LAKE", "ON"],
  ["LARK HARBOUR", "NL"],
  ["LAROUCHE", "QC"],
  ["LARRYS RIVER", "NS"],
  ["LASALLE", "ON"],
  ["LASALLE", "QC"],
  ["LASHBURN", "SK"],
  ["LASQUETI", "BC"],
  ["LATCHFORD", "ON"],
  ["LATERRIERE", "QC"],
  ["LATULIPE", "QC"],
  ["LAUDER", "MB"],
  ["LAUNAY", "QC"],
  ["LAUREL", "ON"],
  ["LAURENCETON", "NL"],
  ["LAURIER", "MB"],
  ["LAURIER-STATION", "QC"],
  ["LAURIERVILLE", "QC"],
  ["LAVAL", "QC"],
  ["LAVAL-OUEST", "QC"],
  ["LAVALTRIE", "QC"],
  ["LAVENHAM", "MB"],
  ["LAVERLOCHERE", "QC"],
  ["LAVIGNE", "ON"],
  ["LAVILLETTE", "NB"],
  ["LAVINGTON", "BC"],
  ["LAVOY", "AB"],
  ["LAWN", "NL"],
  ["LAWRENCE STATION", "NB"],
  ["LAWRENCETOWN", "NS"],
  ["LAWRENCETOWN.", "NS"],
  ["LAWRENCEVILLE", "QC"],
  ["LAX KW'ALAAMS", "BC"],
  ["LAZO", "BC"],
  ["LE BIC", "QC"],
  ["LE GARDEUR", "QC"],
  ["LE GOULET", "NB"],
  ["LEADER", "SK"],
  ["LEADING TICKLES", "NL"],
  ["LEAF RAPIDS", "MB"],
  ["LEAMINGTON", "ON"],
  ["LEASK", "SK"],
  ["LEASKDALE", "ON"],
  ["LEBEL-SUR-QUEVILLON", "QC"],
  ["LEBRET", "SK"],
  ["LECLERCVILLE", "QC"],
  ["LEDUC COUNTY", "AB"],
  ["LEDUC", "AB"],
  ["LEE SETTLEMENT", "NB"],
  ["LEECH", "NB"],
  ["LEFAIVRE", "ON"],
  ["LEFEBVRE", "QC"],
  ["LEFROY", "ON"],
  ["LEGAL", "AB"],
  ["LEITCHES CREEK", "NS"],
  ["LEITH", "ON"],
  ["LEJEUNE", "QC"],
  ["LEMBERG", "SK"],
  ["LEMIEUX", "QC"],
  ["LEMOYNE", "QC"],
  ["LENNOX ISLAND", "PE"],
  ["LENORE", "MB"],
  ["LEONARD COLONY", "NB"],
  ["LEONARDVILLE", "NB"],
  ["LEOVILLE", "SK"],
  ["LEPREAU", "NB"],
  ["LEROSS", "SK"],
  ["LEROY", "SK"],
  ["LERY", "QC"],
  ["LES BUISSONS", "QC"],
  ["LES CEDRES", "QC"],
  ["LES COTEAUX", "QC"],
  ["LES EBOULEMENTS", "QC"],
  ["LES ESCOUMINS", "QC"],
  ["LES HAUTEURS", "QC"],
  ["LES MECHINS", "QC"],
  ["LESLIE", "SK"],
  ["LESLIEVILLE", "AB"],
  ["LESTOCK", "SK"],
  ["LETANG", "NB"],
  ["LETELLIER", "MB"],
  ["LETHBRIDGE", "AB"],
  ["LETHBRIDGE", "NL"],
  ["LEVACK", "ON"],
  ["LEVERVILLE", "NB"],
  ["LEVIS", "QC"],
  ["LEWINS COVE", "NL"],
  ["LEWIS LAKE", "NS"],
  ["LEWIS MOUNTAIN", "NB"],
  ["LEWISPORTE", "NL"],
  ["LEWVAN", "SK"],
  ["LEXINGTON", "NS"],
  ["LIBAU", "MB"],
  ["LIBERTY", "SK"],
  ["LIEBENTHAL", "SK"],
  ["LIKELY", "BC"],
  ["LILLOOET", "BC"],
  ["LIMEHOUSE", "ON"],
  ["LIMEKILN", "NB"],
  ["LIMERICK", "SK"],
  ["LIMESTONE", "NB"],
  ["LIMOGES", "ON"],
  ["LINCOLN", "NB"],
  ["LINDALE", "AB"],
  ["LINDBERGH", "AB"],
  ["LINDELL BEACH", "BC"],
  ["LINDEN", "AB"],
  ["LINDSAY", "NB"],
  ["LINDSAY", "ON"],
  ["LINGAN ROAD", "NS"],
  ["LINGAN", "NS"],
  ["LINGWICK", "QC"],
  ["LINTLAW", "SK"],
  ["LINTON CORNER", "NB"],
  ["LINWOOD", "ON"],
  ["LIONS BAY", "BC"],
  ["LIONS HEAD", "ON"],
  ["LIPTON", "SK"],
  ["LISCOMB", "NS"],
  ["LISIEUX", "SK"],
  ["LISLE", "ON"],
  ["LISTER", "BC"],
  ["LISTOWEL", "ON"],
  ["LISTUGUJ", "QC"],
  ["LITTLE BARTIBOG", "NB"],
  ["LITTLE BAY EAST", "NL"],
  ["LITTLE BAY ISLANDS", "NL"],
  ["LITTLE BAY NDB", "NL"],
  ["LITTLE BAY PB", "NL"],
  ["LITTLE BRAS D'OR", "NS"],
  ["LITTLE BRITAIN", "ON"],
  ["LITTLE BROOK", "NS"],
  ["LITTLE BULLHEAD", "MB"],
  ["LITTLE BURNT BAY", "NL"],
  ["LITTLE CATALINA", "NL"],
  ["LITTLE CURRENT", "ON"],
  ["LITTLE DOVER", "NS"],
  ["LITTLE FORT", "BC"],
  ["LITTLE GRAND RAPIDS", "MB"],
  ["LITTLE HARBOUR EAST PB", "NL"],
  ["LITTLE HEARTS EASE", "NL"],
  ["LITTLE LEPREAU", "NB"],
  ["LITTLE LORRAINE", "NS"],
  ["LITTLE NARROWS", "NS"],
  ["LITTLE POND", "NS"],
  ["LITTLE RIDGE", "NB"],
  ["LITTLE RIVER ALBERT CO", "NB"],
  ["LITTLE RIVER GLOUCESTER CO", "NB"],
  ["LITTLE RIVER HILL", "NB"],
  ["LITTLE RIVER", "NS"],
  ["LITTLE SALMON RIVER WEST", "NB"],
  ["LITTLE SHEMOGUE", "NB"],
  ["LITTLE SMOKY", "AB"],
  ["LITTLE ST LAWRENCE", "NL"],
  ["LITTLE TANCOOK", "NS"],
  ["LIVELONG", "SK"],
  ["LIVELY", "ON"],
  ["LIVERPOOL", "NS"],
  ["LLOYDMINSTER", "AB"],
  ["LLOYDMINSTER", "SK"],
  ["LOCH LOMOND", "NS"],
  ["LOCHLIN", "ON"],
  ["LOCKEPORT", "NS"],
  ["LOCKPORT", "MB"],
  ["LOCKSTEAD", "NB"],
  ["LOCKWOOD", "SK"],
  ["LOCUST HILL", "ON"],
  ["LODGE BAY", "NL"],
  ["LODGEPOLE", "AB"],
  ["LOGAN LAKE", "BC"],
  ["LOGY BAY", "NL"],
  ["LOMBARDY", "ON"],
  ["LOMOND", "AB"],
  ["LONDESBOROUGH", "ON"],
  ["LONDON", "ON"],
  ["LONDONDERRY", "NB"],
  ["LONDONDERRY", "NS"],
  ["LONE BUTTE", "BC"],
  ["LONE PINE", "AB"],
  ["LONE ROCK", "SK"],
  ["LONG CREEK", "NB"],
  ["LONG HARBOUR", "NL"],
  ["LONG ISLAND", "NS"],
  ["LONG POINT", "NB"],
  ["LONG POINT", "NS"],
  ["LONG REACH", "NB"],
  ["LONG SAULT", "ON"],
  ["LONG SETTLEMENT KINGS CO", "NB"],
  ["LONG SETTLEMENT", "NB"],
  ["LONGBOW LAKE", "ON"],
  ["LONGFORD MILLS", "ON"],
  ["LONGLAC", "ON"],
  ["LONGUE-POINTE-DE-MINGAN", "QC"],
  ["LONGUE-RIVE", "QC"],
  ["LONGUEUIL", "QC"],
  ["LONGVIEW", "AB"],
  ["LONGWORTH", "BC"],
  ["LOON BAY", "NL"],
  ["LOON LAKE", "SK"],
  ["LORD'S COVE", "NB"],
  ["LOREBURN", "SK"],
  ["LORETTE", "MB"],
  ["LORETTO", "ON"],
  ["LORING", "ON"],
  ["LORNE", "NB"],
  ["LORRAINE", "QC"],
  ["LORRAINVILLE", "QC"],
  ["LOSIER SETTLEMENT", "NB"],
  ["LOTBINIERE", "QC"],
  ["LOTS-RENVERSES", "QC"],
  ["LOUGHEED", "AB"],
  ["LOUIS CREEK", "BC"],
  ["LOUISBOURG", "NS"],
  ["LOUISDALE", "NS"],
  ["LOUISEVILLE", "QC"],
  ["LOURDES", "NL"],
  ["LOURDES", "QC"],
  ["LOURDES-DE-BLANC-SABLON", "QC"],
  ["LOURDES-DE-JOLIETTE", "QC"],
  ["LOUSANA", "AB"],
  ["LOVE", "SK"],
  ["LOVERNA", "SK"],
  ["LOW", "QC"],
  ["LOWBANKS", "ON"],
  ["LOWE FARM", "MB"],
  ["LOWER BRANCH", "NS"],
  ["LOWER BRIGHTON", "NB"],
  ["LOWER CAMBRIDGE", "NB"],
  ["LOWER CAPE", "NB"],
  ["LOWER COVE", "NB"],
  ["LOWER COVERDALE", "NB"],
  ["LOWER DERBY", "NB"],
  ["LOWER EAST PUBNICO", "NS"],
  ["LOWER FIVE ISLANDS", "NS"],
  ["LOWER GREENWICH", "NB"],
  ["LOWER HAINESVILLE", "NB"],
  ["LOWER ISLAND COVE", "NL"],
  ["LOWER KINGSCLEAR", "NB"],
  ["LOWER KINTORE", "NB"],
  ["LOWER KNOXFORD", "NB"],
  ["LOWER L'ARDOISE", "NS"],
  ["LOWER MILLSTREAM", "NB"],
  ["LOWER NEWCASTLE", "NB"],
  ["LOWER NICOLA", "BC"],
  ["LOWER NORTHFIELD", "NS"],
  ["LOWER NORTON", "NB"],
  ["LOWER ONSLOW", "NS"],
  ["LOWER POST", "BC"],
  ["LOWER PROSPECT", "NS"],
  ["LOWER QUEENSBURY", "NB"],
  ["LOWER SACKVILLE", "NS"],
  ["LOWER ST MARYS", "NB"],
  ["LOWER TRURO", "NS"],
  ["LOWER WASHABUCK", "NS"],
  ["LOWER WEDGEPORT", "NS"],
  ["LOWER WEST PUBNICO", "NS"],
  ["LOWER WOODS HARBOUR", "NS"],
  ["LOWER WOODSTOCK", "NB"],
  ["LUCAN", "ON"],
  ["LUCASVILLE", "NS"],
  ["LUCKNOW", "ON"],
  ["LUCKY LAKE", "SK"],
  ["LUDLOW", "NB"],
  ["LUGAR", "NB"],
  ["LUMBY", "BC"],
  ["LUMSDEN", "NL"],
  ["LUMSDEN", "SK"],
  ["LUND", "BC"],
  ["LUNDAR", "MB"],
  ["LUNDBRECK", "AB"],
  ["LUNENBURG", "NS"],
  ["LUNENBURG", "ON"],
  ["LUSELAND", "SK"],
  ["LUSKVILLE", "QC"],
  ["LUTES MOUNTAIN", "NB"],
  ["LUTSELK'E", "NT"],
  ["LYALTA", "AB"],
  ["LYDGATE", "NS"],
  ["LYLETON", "MB"],
  ["LYN", "ON"],
  ["LYNDEN", "ON"],
  ["LYNDHURST", "ON"],
  ["LYNN LAKE", "MB"],
  ["LYNNFIELD", "NB"],
  ["LYSTER", "QC"],
  ["LYTTLETON", "NB"],
  ["LYTTON", "BC"],
  ["M'CHIGEENG", "ON"],
  ["MA-ME-O BEACH", "AB"],
  ["MABERLY", "ON"],
  ["MABOU", "NS"],
  ["MACADAMS LAKE", "NS"],
  ["MACAMIC", "QC"],
  ["MACCAN", "NS"],
  ["MACDIARMID", "ON"],
  ["MACDONALD", "MB"],
  ["MACDOUGALL SETTLEMENT", "NB"],
  ["MACDOWALL", "SK"],
  ["MACES BAY", "NB"],
  ["MACGREGOR", "MB"],
  ["MACKAY SIDING", "NS"],
  ["MACKDALE", "NS"],
  ["MACKENZIE", "BC"],
  ["MACKEY", "ON"],
  ["MACKINNONS HARBOUR", "NS"],
  ["MACKLIN", "SK"],
  ["MACLAGGAN BRIDGE", "NB"],
  ["MACNUTT", "SK"],
  ["MACOUN", "SK"],
  ["MACPHEES CORNER", "NS"],
  ["MACRORIE", "SK"],
  ["MACTAQUAC", "NB"],
  ["MACTIER", "ON"],
  ["MADAWASKA MALISEET FRST NATION", "NB"],
  ["MADAWASKA", "ON"],
  ["MADDEN", "AB"],
  ["MADDINGTON", "QC"],
  ["MADEIRA PARK", "BC"],
  ["MADELEINE-CENTRE", "QC"],
  ["MADISON", "SK"],
  ["MADOC", "ON"],
  ["MADRAN", "NB"],
  ["MADSEN", "ON"],
  ["MAFEKING", "MB"],
  ["MAGNETAWAN", "ON"],
  ["MAGOG", "QC"],
  ["MAGPIE", "QC"],
  ["MAGRATH", "AB"],
  ["MAHONE BAY", "NS"],
  ["MAIDSTONE", "ON"],
  ["MAIDSTONE", "SK"],
  ["MAIN BROOK", "NL"],
  ["MAIN CENTRE", "SK"],
  ["MAIN POINT", "NL"],
  ["MAIN RIVER", "NB"],
  ["MAIN-A-DIEU", "NS"],
  ["MAINSTREAM", "NB"],
  ["MAISONNETTE", "NB"],
  ["MAITLAND BRIDGE", "NS"],
  ["MAITLAND", "NS"],
  ["MAITLAND", "ON"],
  ["MAJOR", "SK"],
  ["MAKINAK", "MB"],
  ["MAKINSONS", "NL"],
  ["MAKKOVIK", "NL"],
  ["MAKWA", "SK"],
  ["MALAGASH", "NS"],
  ["MALAHAT", "BC"],
  ["MALAKWA", "BC"],
  ["MALARTIC", "QC"],
  ["MALDEN", "NB"],
  ["MALEB", "AB"],
  ["MALIOTENAM", "QC"],
  ["MALLAIG", "AB"],
  ["MALLORYTOWN", "ON"],
  ["MALONTON", "MB"],
  ["MALTEMPEC", "NB"],
  ["MANCEBOURG", "QC"],
  ["MANCHE-D'EPEE", "QC"],
  ["MANDEVILLE", "QC"],
  ["MANGANESE MINES", "NS"],
  ["MANIGOTAGAN", "MB"],
  ["MANILLA", "ON"],
  ["MANITOU", "MB"],
  ["MANITOUWADGE", "ON"],
  ["MANITOWANING", "ON"],
  ["MANIWAKI", "QC"],
  ["MANKOTA", "SK"],
  ["MANN'S MOUNTAIN", "NB"],
  ["MANNHURST", "NB"],
  ["MANNING PARK", "BC"],
  ["MANNING", "AB"],
  ["MANNVILLE", "AB"],
  ["MANOR", "SK"],
  ["MANOTICK", "ON"],
  ["MANOUANE", "QC"],
  ["MANSEAU", "QC"],
  ["MANSFIELD", "ON"],
  ["MANSFIELD", "QC"],
  ["MANSON CREEK", "BC"],
  ["MANSON", "MB"],
  ["MANSONS LANDING", "BC"],
  ["MANSONVILLE", "QC"],
  ["MANTARIO", "SK"],
  ["MANYBERRIES", "AB"],
  ["MAPLE CREEK", "SK"],
  ["MAPLE GLEN", "NB"],
  ["MAPLE GROVE", "NB"],
  ["MAPLE GROVE", "NS"],
  ["MAPLE GROVE", "QC"],
  ["MAPLE LEAF", "ON"],
  ["MAPLE RIDGE", "BC"],
  ["MAPLE RIDGE", "NB"],
  ["MAPLE VIEW", "NB"],
  ["MAPLE", "ON"],
  ["MAPLEDALE", "NB"],
  ["MAPLEHURST", "NB"],
  ["MAPLEWOOD", "NB"],
  ["MAQUAPIT LAKE", "NB"],
  ["MAR", "ON"],
  ["MARA", "BC"],
  ["MARATHON", "ON"],
  ["MARBLETON", "QC"],
  ["MARCELIN", "SK"],
  ["MARCHAND", "MB"],
  ["MARENGO", "SK"],
  ["MARGAREE CENTRE", "NS"],
  ["MARGAREE FORKS", "NS"],
  ["MARGAREE HARBOUR", "NS"],
  ["MARGAREE VALLEY", "NS"],
  ["MARGAREE", "NS"],
  ["MARGARET", "MB"],
  ["MARGARETSVILLE", "NS"],
  ["MARGO", "SK"],
  ["MARIA", "QC"],
  ["MARIAPOLIS", "MB"],
  ["MARICOURT", "QC"],
  ["MARIE JOSEPH", "NS"],
  ["MARIE REINE", "AB"],
  ["MARIEVILLE", "QC"],
  ["MARION BRIDGE", "NS"],
  ["MARIUS", "MB"],
  ["MARKDALE", "ON"],
  ["MARKERVILLE", "AB"],
  ["MARKHAM", "ON"],
  ["MARKHAMVILLE", "NB"],
  ["MARKINCH", "SK"],
  ["MARKSTAY", "ON"],
  ["MARLBANK", "ON"],
  ["MARMORA", "ON"],
  ["MARNE", "NB"],
  ["MARQUETTE", "MB"],
  ["MARQUIS", "SK"],
  ["MARRTOWN", "NB"],
  ["MARSDEN", "SK"],
  ["MARSH LAKE", "YT"],
  ["MARSHALL", "SK"],
  ["MARSOUI", "QC"],
  ["MARSTON", "QC"],
  ["MARTEN RIVER", "ON"],
  ["MARTENSVILLE", "SK"],
  ["MARTINTOWN", "ON"],
  ["MARTINVILLE", "QC"],
  ["MARWAYNE", "AB"],
  ["MARYFIELD", "SK"],
  ["MARYHILL", "ON"],
  ["MARYS HARBOUR", "NL"],
  ["MARYSTOWN", "NL"],
  ["MARYSVALE", "NL"],
  ["MARYSVILLE", "ON"],
  ["MASCARENE", "NB"],
  ["MASCOUCHE", "QC"],
  ["MASHTEUIATSH", "QC"],
  ["MASKINONGE", "QC"],
  ["MASSET", "BC"],
  ["MASSEY DRIVE", "NL"],
  ["MASSEY", "ON"],
  ["MASSUEVILLE", "QC"],
  ["MATACHEWAN", "ON"],
  ["MATAGAMI", "QC"],
  ["MATANE", "QC"],
  ["MATAPEDIA", "QC"],
  ["MATES CORNER", "NB"],
  ["MATHER", "MB"],
  ["MATHESON ISLAND", "MB"],
  ["MATHESON", "ON"],
  ["MATLOCK", "MB"],
  ["MATTAWA", "ON"],
  ["MATTHEWS SETTLEMENT", "NB"],
  ["MATTICE", "ON"],
  ["MAUGERVILLE", "NB"],
  ["MAVILLETTE", "NS"],
  ["MAXVILLE", "ON"],
  ["MAXWELL", "NB"],
  ["MAXWELL", "ON"],
  ["MAYERTHORPE", "AB"],
  ["MAYFAIR", "SK"],
  ["MAYFIELD", "NB"],
  ["MAYMONT", "SK"],
  ["MAYNE", "BC"],
  ["MAYNOOTH", "ON"],
  ["MAYO", "QC"],
  ["MAYO", "YT"],
  ["MAYVIEW", "SK"],
  ["MAZENOD", "SK"],
  ["MAZEROLLE SETTLEMENT", "NB"],
  ["MCADAM", "NB"],
  ["MCARTHURS MILLS", "ON"],
  ["MCAULEY", "MB"],
  ["MCBRIDE", "BC"],
  ["MCCALLUM SETTLEMENT", "NS"],
  ["MCCALLUM", "NL"],
  ["MCCORD", "SK"],
  ["MCCREARY", "MB"],
  ["MCDONALDS CORNERS", "ON"],
  ["MCGIVNEY", "NB"],
  ["MCGRATHS COVE", "NS"],
  ["MCGRAY", "NS"],
  ["MCGREGOR", "ON"],
  ["MCINTOSH HILL", "NB"],
  ["MCKEES MILLS", "NB"],
  ["MCKELLAR", "ON"],
  ["MCKENNA", "NB"],
  ["MCKENZIE CORNER", "NB"],
  ["MCKENZIE ISLAND", "ON"],
  ["MCKERROW", "ON"],
  ["MCKINLEYVILLE", "NB"],
  ["MCLAUGHLIN", "AB"],
  ["MCLAUGHLIN", "NB"],
  ["MCLEAN", "SK"],
  ["MCLEESE LAKE", "BC"],
  ["MCLENNAN", "AB"],
  ["MCLEOD HILL", "NB"],
  ["MCLEOD LAKE", "BC"],
  ["MCLEODS", "NB"],
  ["MCLURE", "BC"],
  ["MCMAHON", "SK"],
  ["MCMASTERVILLE", "QC"],
  ["MCNAMEE", "NB"],
  ["MCQUADE", "NB"],
  ["MCRAE", "AB"],
  ["MCTAGGART", "SK"],
  ["MCWATTERS", "QC"],
  ["MEACHAM", "SK"],
  ["MEADOW BROOK", "NB"],
  ["MEADOW CREEK", "BC"],
  ["MEADOW LAKE", "SK"],
  ["MEADOW PORTAGE", "MB"],
  ["MEAFORD", "ON"],
  ["MEAGHERS GRANT", "NS"],
  ["MEANDER RIVER", "AB"],
  ["MEATH PARK", "SK"],
  ["MECHANIC SETTLEMENT", "NB"],
  ["MEDFORD", "NB"],
  ["MEDICINE HAT", "AB"],
  ["MEDORA", "MB"],
  ["MEDSTEAD", "SK"],
  ["MEDUCTIC", "NB"],
  ["MEETING CREEK", "AB"],
  ["MELBOURNE", "ON"],
  ["MELBOURNE", "QC"],
  ["MELDRUM BAY", "ON"],
  ["MELEB", "MB"],
  ["MELFORT", "SK"],
  ["MELITA", "MB"],
  ["MELOCHEVILLE", "QC"],
  ["MELROSE", "NB"],
  ["MELROSE", "NL"],
  ["MELVILLE", "NS"],
  ["MELVILLE", "SK"],
  ["MEMBERTOU", "NS"],
  ["MEMRAMCOOK EAST", "NB"],
  ["MEMRAMCOOK", "NB"],
  ["MENDHAM", "SK"],
  ["MENISINO", "MB"],
  ["MENNEVAL", "NB"],
  ["MENZIE", "MB"],
  ["MEOTA", "SK"],
  ["MERCIER", "QC"],
  ["MERIGOMISH", "NS"],
  ["MERLIN", "ON"],
  ["MERRICKVILLE", "ON"],
  ["MERRITT", "BC"],
  ["MERVILLE", "BC"],
  ["MERVIN", "SK"],
  ["MESACHIE LAKE", "BC"],
  ["MESKANAW", "SK"],
  ["MESSINES", "QC"],
  ["METABETCHOUAN-LAC-A-LA-CROIX", "QC"],
  ["METCALFE", "ON"],
  ["METEGHAN CENTRE", "NS"],
  ["METEGHAN RIVER", "NS"],
  ["METEGHAN", "NS"],
  ["METIS-SUR-MER", "QC"],
  ["METISKOW", "AB"],
  ["MEYRONNE", "SK"],
  ["MEZIADIN LAKE", "BC"],
  ["MIAMI", "MB"],
  ["MICA CREEK", "BC"],
  ["MICMAC", "NS"],
  ["MIDALE", "SK"],
  ["MIDDLE ARM GB", "NL"],
  ["MIDDLE BAY", "QC"],
  ["MIDDLE CAPE", "NS"],
  ["MIDDLE COVE", "NL"],
  ["MIDDLE HAINESVILLE", "NB"],
  ["MIDDLE LAHAVE", "NS"],
  ["MIDDLE LAKE", "SK"],
  ["MIDDLE MUSQUODOBOIT", "NS"],
  ["MIDDLE PORTERS LAKE", "NS"],
  ["MIDDLE RIVER", "NB"],
  ["MIDDLE SACKVILLE", "NB"],
  ["MIDDLE SACKVILLE", "NS"],
  ["MIDDLE STEWIACKE", "NS"],
  ["MIDDLE WEST PUBNICO", "NS"],
  ["MIDDLEBRO", "MB"],
  ["MIDDLETON", "NB"],
  ["MIDDLETON", "NS"],
  ["MIDDLEWOOD", "NS"],
  ["MIDGIC", "NB"],
  ["MIDHURST", "ON"],
  ["MIDLAND KINGS CO", "NB"],
  ["MIDLAND QUEENS CO", "NB"],
  ["MIDLAND", "ON"],
  ["MIDVILLE BRANCH", "NS"],
  ["MIDWAY", "BC"],
  ["MIDWAY", "NB"],
  ["MIGISI SAHGAIGAN", "ON"],
  ["MIKADO", "SK"],
  ["MILAN", "QC"],
  ["MILDEN", "SK"],
  ["MILDMAY", "ON"],
  ["MILDRED", "SK"],
  ["MILES COVE", "NL"],
  ["MILESTONE", "SK"],
  ["MILFORD BAY", "ON"],
  ["MILFORD STATION", "NS"],
  ["MILFORD", "NS"],
  ["MILFORD", "ON"],
  ["MILK RIVER", "AB"],
  ["MILL BAY", "BC"],
  ["MILL BROOK", "NB"],
  ["MILL COVE", "NB"],
  ["MILL CREEK", "NS"],
  ["MILL VILLAGE", "NS"],
  ["MILL VILLAGE.", "NS"],
  ["MILLARVILLE", "AB"],
  ["MILLBANK", "ON"],
  ["MILLBROOK", "NS"],
  ["MILLBROOK", "ON"],
  ["MILLE-ISLES", "QC"],
  ["MILLER LAKE", "ON"],
  ["MILLERTON", "NB"],
  ["MILLERTOWN", "NL"],
  ["MILLET", "AB"],
  ["MILLGROVE", "ON"],
  ["MILLICENT", "AB"],
  ["MILLTOWN", "NL"],
  ["MILLVILLE", "NB"],
  ["MILLVILLE", "NS"],
  ["MILNER", "BC"],
  ["MILO", "AB"],
  ["MILTON", "NS"],
  ["MILTON", "ON"],
  ["MILVERTON", "ON"],
  ["MIMINEGASH", "PE"],
  ["MINAKI", "ON"],
  ["MINBURN", "AB"],
  ["MINDEMOYA", "ON"],
  ["MINDEN", "ON"],
  ["MINE CENTRE", "ON"],
  ["MINESING", "ON"],
  ["MINETT", "ON"],
  ["MINEVILLE", "NS"],
  ["MINGS BIGHT", "NL"],
  ["MINIOTA", "MB"],
  ["MINISTERS ISLAND", "NB"],
  ["MINITONAS", "MB"],
  ["MINNEDOSA", "MB"],
  ["MINNITAKI", "ON"],
  ["MINSTREL ISLAND", "BC"],
  ["MINTO", "MB"],
  ["MINTO", "NB"],
  ["MINTON", "SK"],
  ["MIQUELON", "QC"],
  ["MIRA GUT", "NS"],
  ["MIRA ROAD", "NS"],
  ["MIRABEL", "QC"],
  ["MIRAMICHI BAY", "NB"],
  ["MIRAMICHI ROAD", "NB"],
  ["MIRAMICHI", "NB"],
  ["MIRROR", "AB"],
  ["MISCOU", "NB"],
  ["MISCOUCHE", "PE"],
  ["MISPEC", "NB"],
  ["MISSANABIE", "ON"],
  ["MISSION", "BC"],
  ["MISSISSAUGA", "ON"],
  ["MISSISSIPPI STATION", "ON"],
  ["MISTATIM", "SK"],
  ["MISTISSINI", "QC"],
  ["MITCHELL", "MB"],
  ["MITCHELL", "ON"],
  ["MOBERLY LAKE", "BC"],
  ["MOBERT", "ON"],
  ["MOBILE", "NL"],
  ["MOFFAT", "ON"],
  ["MOFFET", "QC"],
  ["MOHANNES", "NB"],
  ["MOISIE", "QC"],
  ["MOLEGA LAKE", "NS"],
  ["MONARCH", "AB"],
  ["MONASTERY", "NS"],
  ["MONCTON", "NB"],
  ["MONETVILLE", "ON"],
  ["MONITOR", "AB"],
  ["MONKLAND", "ON"],
  ["MONKSTOWN", "NL"],
  ["MONKTON", "ON"],
  ["MONQUART", "NB"],
  ["MONT NEBO", "SK"],
  ["MONT-BRUN", "QC"],
  ["MONT-CARMEL", "QC"],
  ["MONT-JOLI", "QC"],
  ["MONT-LAURIER", "QC"],
  ["MONT-LOUIS", "QC"],
  ["MONT-ROYAL", "QC"],
  ["MONT-SAINT-GREGOIRE", "QC"],
  ["MONT-SAINT-HILAIRE", "QC"],
  ["MONT-SAINT-MICHEL", "QC"],
  ["MONT-SAINT-PIERRE", "QC"],
  ["MONT-TREMBLANT", "QC"],
  ["MONTAGUE GOLD MINES", "NS"],
  ["MONTAGUE", "PE"],
  ["MONTBEILLARD", "QC"],
  ["MONTCALM", "QC"],
  ["MONTCERF-LYTTON", "QC"],
  ["MONTE CREEK", "BC"],
  ["MONTE LAKE", "BC"],
  ["MONTEAGLE", "NB"],
  ["MONTEBELLO", "QC"],
  ["MONTEITH", "ON"],
  ["MONTMAGNY", "QC"],
  ["MONTMARTRE", "SK"],
  ["MONTNEY", "BC"],
  ["MONTPELLIER", "QC"],
  ["MONTREAL LAKE", "SK"],
  ["MONTREAL RIVER HARBOUR", "ON"],
  ["MONTREAL", "QC"],
  ["MONTREAL-EST", "QC"],
  ["MONTREAL-NORD", "QC"],
  ["MONTREAL-OUEST", "QC"],
  ["MONTROSE", "BC"],
  ["MONUMENT", "NB"],
  ["MOONBEAM", "ON"],
  ["MOONSTONE", "ON"],
  ["MOOREFIELD", "ON"],
  ["MOORES MILLS", "NB"],
  ["MOORETOWN", "ON"],
  ["MOOSE CREEK", "ON"],
  ["MOOSE FACTORY", "ON"],
  ["MOOSE JAW", "SK"],
  ["MOOSE LAKE", "MB"],
  ["MOOSE MOUNTAIN", "NB"],
  ["MOOSEHORN CREEK", "NB"],
  ["MOOSEHORN", "MB"],
  ["MOOSELAND", "NS"],
  ["MOOSOMIN", "SK"],
  ["MOOSONEE", "ON"],
  ["MORDEN", "MB"],
  ["MORELL", "PE"],
  ["MORETONS HARBOUR", "NL"],
  ["MOREWOOD", "ON"],
  ["MORIN-HEIGHTS", "QC"],
  ["MORINVILLE", "AB"],
  ["MORLEY", "AB"],
  ["MORPETH", "ON"],
  ["MORRELL SIDING", "NB"],
  ["MORRIN", "AB"],
  ["MORRIS", "MB"],
  ["MORRISBURG", "ON"],
  ["MORRISDALE", "NB"],
  ["MORRISTON", "ON"],
  ["MORSE", "SK"],
  ["MORSON", "ON"],
  ["MORTLACH", "SK"],
  ["MOSER RIVER", "NS"],
  ["MOSSBANK", "SK"],
  ["MOSSLEIGH", "AB"],
  ["MOSSLEY", "ON"],
  ["MOUNT ALBERT", "ON"],
  ["MOUNT ARLINGTON HEIGHTS", "NL"],
  ["MOUNT BRYDGES", "ON"],
  ["MOUNT CARMEL", "NL"],
  ["MOUNT CURRIE", "BC"],
  ["MOUNT DELIGHT", "NB"],
  ["MOUNT ELGIN", "ON"],
  ["MOUNT FOREST", "ON"],
  ["MOUNT HEBRON", "NB"],
  ["MOUNT HOPE", "NB"],
  ["MOUNT HOPE", "ON"],
  ["MOUNT MORIAH", "NL"],
  ["MOUNT PEARL", "NL"],
  ["MOUNT PISGAH", "NB"],
  ["MOUNT PLEASANT", "NB"],
  ["MOUNT PLEASANT", "ON"],
  ["MOUNT STEWART", "PE"],
  ["MOUNT UNIACKE", "NS"],
  ["MOUNTAIN GROVE", "ON"],
  ["MOUNTAIN ROAD", "MB"],
  ["MOUNTAIN VIEW", "AB"],
  ["MOUNTAIN", "ON"],
  ["MOYIE", "BC"],
  ["MOZART", "SK"],
  ["MT MIDDLETON", "NB"],
  ["MUD LAKE", "NL"],
  ["MUENSTER", "SK"],
  ["MUIRKIRK", "ON"],
  ["MULGRAVE", "NS"],
  ["MULGRAVE-ET-DERRY", "QC"],
  ["MULHURST BAY", "AB"],
  ["MULLINGAR", "SK"],
  ["MULVIHILL", "MB"],
  ["MUNCEY", "ON"],
  ["MUNCHO LAKE", "BC"],
  ["MUNDARE", "AB"],
  ["MUNDLEVILLE", "NB"],
  ["MUNIAC", "NB"],
  ["MUNSON", "AB"],
  ["MUNSTER", "ON"],
  ["MURDOCHVILLE", "QC"],
  ["MURILLO", "ON"],
  ["MURRAY CORNER", "NB"],
  ["MURRAY HARBOUR", "PE"],
  ["MURRAY RIVER", "PE"],
  ["MURRAY SETTLEMENT", "NB"],
  ["MURRAY SIDING", "NS"],
  ["MUSGRAVE HARBOUR", "NL"],
  ["MUSGRAVETOWN", "NL"],
  ["MUSIDORA", "AB"],
  ["MUSKODAY", "SK"],
  ["MUSKRAT DAM", "ON"],
  ["MUSQUASH", "NB"],
  ["MUSQUODOBOIT HARBOUR", "NS"],
  ["MUTTON BAY", "QC"],
  ["MYRNAM", "AB"],
  ["NACKAWIC", "NB"],
  ["NACMINE", "AB"],
  ["NAICAM", "SK"],
  ["NAIN", "NL"],
  ["NAIRN CENTRE", "ON"],
  ["NAKINA", "ON"],
  ["NAKUSP", "BC"],
  ["NAMAO", "AB"],
  ["NAMPA", "AB"],
  ["NAMUR", "QC"],
  ["NANAIMO", "BC"],
  ["NANOOSE BAY", "BC"],
  ["NANTES", "QC"],
  ["NANTICOKE", "ON"],
  ["NANTON", "AB"],
  ["NAPADOGAN", "NB"],
  ["NAPAN", "NB"],
  ["NAPANEE", "ON"],
  ["NAPIERVILLE", "QC"],
  ["NAPINKA", "MB"],
  ["NAPPAN", "NS"],
  ["NARAMATA", "BC"],
  ["NARCISSE", "MB"],
  ["NASH CREEK", "NB"],
  ["NASHWAAK BRIDGE", "NB"],
  ["NASHWAAK VILLAGE", "NB"],
  ["NASONWORTH", "NB"],
  ["NASS CAMP", "BC"],
  ["NATASHQUAN", "QC"],
  ["NATUASHISH", "NL"],
  ["NAUGHTON", "ON"],
  ["NAUWIGEWAUK", "NB"],
  ["NAVAN", "ON"],
  ["NEDELEC", "QC"],
  ["NEEBING", "ON"],
  ["NEEPAWA", "MB"],
  ["NEERLANDIA", "AB"],
  ["NEGGINAN", "MB"],
  ["NEGUAC", "NB"],
  ["NEIDPATH", "SK"],
  ["NEILBURG", "SK"],
  ["NEILS HARBOUR", "NS"],
  ["NELSON HOLLOW", "NB"],
  ["NELSON HOUSE", "MB"],
  ["NELSON", "BC"],
  ["NEMAIAH VALLEY", "BC"],
  ["NEMISCAU", "QC"],
  ["NEPEAN", "ON"],
  ["NEPISIGUIT FALLS", "NB"],
  ["NEREPIS", "NB"],
  ["NESBITT", "MB"],
  ["NESTLETON STATION", "ON"],
  ["NESTOR FALLS", "ON"],
  ["NESTOW", "AB"],
  ["NETHERHILL", "SK"],
  ["NEUDORF", "SK"],
  ["NEUSTADT", "ON"],
  ["NEUVILLE", "QC"],
  ["NEVILLE", "SK"],
  ["NEVIS", "AB"],
  ["NEW AVON", "NB"],
  ["NEW BANDON GLOUCESTER CO", "NB"],
  ["NEW BANDON NORTHUMB CO", "NB"],
  ["NEW BOTHWELL", "MB"],
  ["NEW BRIGDEN", "AB"],
  ["NEW CAMPBELLTON", "NS"],
  ["NEW CANAAN", "NB"],
  ["NEW CANADA", "NS"],
  ["NEW CARLISLE", "QC"],
  ["NEW CHELSEA", "NL"],
  ["NEW DAYTON", "AB"],
  ["NEW DENMARK", "NB"],
  ["NEW DENVER", "BC"],
  ["NEW DUNDEE", "ON"],
  ["NEW ENGLAND SETTLEMENT", "NB"],
  ["NEW GERMANY", "NS"],
  ["NEW GLASGOW", "NS"],
  ["NEW GLASGOW", "PE"],
  ["NEW HAMBURG", "ON"],
  ["NEW HARBOUR TB", "NL"],
  ["NEW HARRIS", "NS"],
  ["NEW HAVEN", "NS"],
  ["NEW HAZELTON", "BC"],
  ["NEW HORTON", "NB"],
  ["NEW JERSEY", "NB"],
  ["NEW LINE", "NB"],
  ["NEW LISKEARD", "ON"],
  ["NEW LOWELL", "ON"],
  ["NEW MARKET", "NB"],
  ["NEW MARYLAND", "NB"],
  ["NEW MELBOURNE", "NL"],
  ["NEW MILLS", "NB"],
  ["NEW MINAS", "NS"],
  ["NEW NORWAY", "AB"],
  ["NEW PERLICAN", "NL"],
  ["NEW RICHMOND", "QC"],
  ["NEW RIVER BEACH", "NB"],
  ["NEW ROSS", "NS"],
  ["NEW SCOTLAND", "NB"],
  ["NEW SONGHEES 1A", "BC"],
  ["NEW VICTORIA", "NS"],
  ["NEW WATERFORD", "NS"],
  ["NEW WESTMINSTER", "BC"],
  ["NEW ZION", "NB"],
  ["NEWBORO", "ON"],
  ["NEWBRIDGE", "NB"],
  ["NEWBROOK", "AB"],
  ["NEWBURG", "NB"],
  ["NEWBURGH", "ON"],
  ["NEWBURY", "ON"],
  ["NEWCASTLE CENTRE", "NB"],
  ["NEWCASTLE CREEK", "NB"],
  ["NEWCASTLE", "ON"],
  ["NEWCOMBVILLE", "NS"],
  ["NEWDALE", "MB"],
  ["NEWINGTON", "ON"],
  ["NEWMANS COVE", "NL"],
  ["NEWMARKET", "ON"],
  ["NEWPORT STATION", "NS"],
  ["NEWPORT", "NS"],
  ["NEWPORT", "QC"],
  ["NEWTON SIDING", "MB"],
  ["NEWTON", "ON"],
  ["NEWTONVILLE", "ON"],
  ["NEWTOWN", "NB"],
  ["NEWTOWN", "NL"],
  ["NEWTOWN", "NS"],
  ["NIAGARA FALLS", "ON"],
  ["NIAGARA ON THE LAKE", "ON"],
  ["NICHOLAS DENYS", "NB"],
  ["NICOLET", "QC"],
  ["NICTAU", "NB"],
  ["NIGADOO", "NB"],
  ["NIMPO LAKE", "BC"],
  ["NINE MILE RIVER", "NS"],
  ["NINETTE", "MB"],
  ["NINGA", "MB"],
  ["NIPAWIN", "SK"],
  ["NIPIGON", "ON"],
  ["NIPISSING", "ON"],
  ["NIPPERS HARBOUR", "NL"],
  ["NISKU", "AB"],
  ["NITON JUNCTION", "AB"],
  ["NIVERVILLE", "MB"],
  ["NOBEL", "ON"],
  ["NOBLEFORD", "AB"],
  ["NOBLETON", "ON"],
  ["NOEL", "NS"],
  ["NOELS POND", "NL"],
  ["NOELVILLE", "ON"],
  ["NOINVILLE", "NB"],
  ["NOKOMIS", "SK"],
  ["NOLALU", "ON"],
  ["NOMININGUE", "QC"],
  ["NOONAN", "NB"],
  ["NORDEGG", "AB"],
  ["NORGLENWOLD", "AB"],
  ["NORLAND", "ON"],
  ["NORMAN WELLS", "NT"],
  ["NORMANDIN", "QC"],
  ["NORMANS COVE", "NL"],
  ["NORMETAL", "QC"],
  ["NORQUAY", "SK"],
  ["NORRIS ARM NORTHSIDE", "NL"],
  ["NORRIS ARM", "NL"],
  ["NORRIS POINT", "NL"],
  ["NORTH AUGUSTA", "ON"],
  ["NORTH BATTLEFORD", "SK"],
  ["NORTH BAY", "ON"],
  ["NORTH BUXTON", "ON"],
  ["NORTH COBALT", "ON"],
  ["NORTH EAST MARGAREE", "NS"],
  ["NORTH EAST POINT", "NS"],
  ["NORTH FORKS", "NB"],
  ["NORTH GOWER", "ON"],
  ["NORTH HARBOUR PB", "NL"],
  ["NORTH HARBOUR SMB", "NL"],
  ["NORTH HATLEY", "QC"],
  ["NORTH LAKE", "NB"],
  ["NORTH LANCASTER", "ON"],
  ["NORTH PINE", "BC"],
  ["NORTH PORTAL", "SK"],
  ["NORTH PRESTON", "NS"],
  ["NORTH RIVER", "NS"],
  ["NORTH RUSTICO", "PE"],
  ["NORTH SAANICH", "BC"],
  ["NORTH SALEM", "NS"],
  ["NORTH SHANNONVALE", "NB"],
  ["NORTH SPIRIT LAKE", "ON"],
  ["NORTH STAR", "AB"],
  ["NORTH SYDNEY", "NS"],
  ["NORTH TAY", "NB"],
  ["NORTH TETAGOUCHE", "NB"],
  ["NORTH VALLEY", "NL"],
  ["NORTH VANCOUVER", "BC"],
  ["NORTH VIEW", "NB"],
  ["NORTH WEST ARM", "NS"],
  ["NORTH WEST BROOK", "NL"],
  ["NORTH WEST RIVER", "NL"],
  ["NORTH WEYBURN", "SK"],
  ["NORTH WILTSHIRE", "PE"],
  ["NORTH YORK", "ON"],
  ["NORTHAMPTON", "NB"],
  ["NORTHBROOK", "ON"],
  ["NORTHERN BAY", "NL"],
  ["NORTHERN HARBOUR", "NB"],
  ["NORTHGATE", "SK"],
  ["NORTHPORT", "NS"],
  ["NORTHSIDE EAST BAY", "NS"],
  ["NORTON", "NB"],
  ["NORTONDALE", "NB"],
  ["NORVAL", "ON"],
  ["NORWAY HOUSE", "MB"],
  ["NORWICH", "ON"],
  ["NORWOOD", "ON"],
  ["NOTIKEWIN", "AB"],
  ["NOTRE DAME DE LOURDES", "MB"],
  ["NOTRE DAME DE LOURDES", "NB"],
  ["NOTRE-DAME", "NB"],
  ["NOTRE-DAME-DE-HAM", "QC"],
  ["NOTRE-DAME-DE-L'ILE-PERROT", "QC"],
  ["NOTRE-DAME-DE-LA-MERCI", "QC"],
  ["NOTRE-DAME-DE-LA-PAIX", "QC"],
  ["NOTRE-DAME-DE-LA-SALETTE", "QC"],
  ["NOTRE-DAME-DE-LORETTE", "QC"],
  ["NOTRE-DAME-DE-MONTAUBAN", "QC"],
  ["NOTRE-DAME-DE-PONTMAIN", "QC"],
  ["NOTRE-DAME-DE-STANBRIDGE", "QC"],
  ["NOTRE-DAME-DES-BOIS", "QC"],
  ["NOTRE-DAME-DES-ERABLES", "NB"],
  ["NOTRE-DAME-DES-MONTS", "QC"],
  ["NOTRE-DAME-DES-PINS", "QC"],
  ["NOTRE-DAME-DES-PRAIRIES", "QC"],
  ["NOTRE-DAME-DES-SEPT-DOULEURS", "QC"],
  ["NOTRE-DAME-DU-LAC", "QC"],
  ["NOTRE-DAME-DU-LAUS", "QC"],
  ["NOTRE-DAME-DU-MONT-CARMEL", "QC"],
  ["NOTRE-DAME-DU-NORD", "QC"],
  ["NOTRE-DAME-DU-PORTAGE", "QC"],
  ["NOTRE-DAME-DU-ROSAIRE", "QC"],
  ["NOTTAWA", "ON"],
  ["NOUVELLE", "QC"],
  ["NOUVELLE-OUEST", "QC"],
  ["NOVAR", "ON"],
  ["NOYAN", "QC"],
  ["NUT MOUNTAIN", "SK"],
  ["NUTTBY", "NS"],
  ["O'HANLY", "MB"],
  ["O'LEARY", "PE"],
  ["OAK BAY", "NB"],
  ["OAK BLUFF", "MB"],
  ["OAK HAVEN", "NB"],
  ["OAK HILL", "NB"],
  ["OAK LAKE", "MB"],
  ["OAK MOUNTAIN", "NB"],
  ["OAK POINT KINGS CO", "NB"],
  ["OAK POINT", "MB"],
  ["OAK POINT", "NB"],
  ["OAK RIVER", "MB"],
  ["OAKBANK", "MB"],
  ["OAKBURN", "MB"],
  ["OAKFIELD", "NS"],
  ["OAKHILL", "NS"],
  ["OAKLAND", "NB"],
  ["OAKLAND", "ON"],
  ["OAKVIEW", "MB"],
  ["OAKVILLE", "MB"],
  ["OAKVILLE", "NB"],
  ["OAKVILLE", "ON"],
  ["OAKWOOD", "ON"],
  ["OBA", "ON"],
  ["OBEDJIWAN", "QC"],
  ["OCEAN FALLS", "BC"],
  ["OCHRE PIT COVE", "NL"],
  ["OCHRE RIVER", "MB"],
  ["ODANAK", "QC"],
  ["ODELL", "NB"],
  ["ODESSA", "ON"],
  ["ODESSA", "SK"],
  ["OGDEN", "QC"],
  ["OGEMA", "SK"],
  ["OGOKI", "ON"],
  ["OHATON", "AB"],
  ["OHSWEKEN", "ON"],
  ["OIL CITY", "ON"],
  ["OIL SPRINGS", "ON"],
  ["OKA", "QC"],
  ["OKANAGAN CENTRE", "BC"],
  ["OKANAGAN FALLS", "BC"],
  ["OKLA", "SK"],
  ["OKOTOKS", "AB"],
  ["OLD AVON", "NB"],
  ["OLD BARNS", "NS"],
  ["OLD CROW", "YT"],
  ["OLD FORT BAY", "QC"],
  ["OLD PERLICAN", "NL"],
  ["OLD RIDGE", "NB"],
  ["OLD SHOP", "NL"],
  ["OLDCASTLE", "ON"],
  ["OLDHAM", "NS"],
  ["OLDS", "AB"],
  ["OLHA", "MB"],
  ["OLIVER", "BC"],
  ["OMEMEE", "ON"],
  ["OMPAH", "ON"],
  ["ONANOLE", "MB"],
  ["ONAPING", "ON"],
  ["ONEFOUR", "AB"],
  ["ONION LAKE", "SK"],
  ["ONOWAY", "AB"],
  ["ONSLOW MOUNTAIN", "NS"],
  ["OONA RIVER", "BC"],
  ["OPAL", "AB"],
  ["OPASATIKA", "ON"],
  ["OPASKWAYAK", "MB"],
  ["OPEN HALL", "NL"],
  ["ORANGE HILL", "NB"],
  ["ORANGEDALE", "NS"],
  ["ORANGEVILLE", "ON"],
  ["ORFORD", "QC"],
  ["ORILLIA", "ON"],
  ["ORION", "AB"],
  ["ORKNEY", "SK"],
  ["ORLEANS", "ON"],
  ["ORMISTON", "SK"],
  ["ORMSTOWN", "QC"],
  ["ORO STATION", "ON"],
  ["ORO", "ON"],
  ["OROMOCTO", "NB"],
  ["ORONO", "ON"],
  ["ORTON", "ON"],
  ["OSAGE", "SK"],
  ["OSBORNE CORNER", "NB"],
  ["OSBORNE HARBOUR", "NS"],
  ["OSGOODE", "ON"],
  ["OSHAWA", "ON"],
  ["OSLER", "SK"],
  ["OSNABURGH HOUSE", "ON"],
  ["OSOYOOS", "BC"],
  ["OTTAWA BROOK", "NS"],
  ["OTTAWA", "ON"],
  ["OTTER CREEK", "NB"],
  ["OTTER LAKE", "QC"],
  ["OTTERBURN PARK", "QC"],
  ["OTTERBURNE", "MB"],
  ["OTTERVILLE", "ON"],
  ["OUJE-BOUGOUMOU", "QC"],
  ["OUNGRE", "SK"],
  ["OUTER COVE", "NL"],
  ["OUTLOOK", "SK"],
  ["OUTREMONT", "QC"],
  ["OVERSTONEVILLE", "MB"],
  ["OWEN SOUND", "ON"],
  ["OXBOW", "NB"],
  ["OXBOW", "SK"],
  ["OXDRIFT", "ON"],
  ["OXFORD HOUSE", "MB"],
  ["OXFORD JUNCTION", "NS"],
  ["OXFORD MILLS", "ON"],
  ["OXFORD STATION", "ON"],
  ["OXFORD", "NS"],
  ["OYAMA", "BC"],
  ["OYEN", "AB"],
  ["PABINEAU FALLS", "NB"],
  ["PABINEAU FIRST NATION", "NB"],
  ["PABOS MILLS", "QC"],
  ["PABOS", "QC"],
  ["PACKINGTON", "QC"],
  ["PACQUET", "NL"],
  ["PADDLE PRAIRIE", "AB"],
  ["PADDOCKWOOD", "SK"],
  ["PADOUE", "QC"],
  ["PAIN COURT", "ON"],
  ["PAISLEY", "ON"],
  ["PAKENHAM", "ON"],
  ["PAKWAW LAKE", "SK"],
  ["PALGRAVE", "ON"],
  ["PALMAROLLE", "QC"],
  ["PALMER RAPIDS", "ON"],
  ["PALMER", "SK"],
  ["PALMERSTON", "ON"],
  ["PAMBRUN", "SK"],
  ["PANGMAN", "SK"],
  ["PANGNIRTUNG", "NU"],
  ["PANORAMA", "BC"],
  ["PANSY", "MB"],
  ["PAPINEAUVILLE", "QC"],
  ["PAQUETVILLE", "NB"],
  ["PARADISE HILL", "SK"],
  ["PARADISE RIVER", "NL"],
  ["PARADISE VALLEY", "AB"],
  ["PARADISE", "NL"],
  ["PARADISE", "NS"],
  ["PARENT", "QC"],
  ["PARHAM", "ON"],
  ["PARIS", "ON"],
  ["PARISVILLE", "QC"],
  ["PARKBEG", "SK"],
  ["PARKER RIDGE", "NB"],
  ["PARKER ROAD", "NB"],
  ["PARKERS COVE", "NL"],
  ["PARKERVIEW", "SK"],
  ["PARKHILL", "ON"],
  ["PARKINDALE", "NB"],
  ["PARKLAND", "AB"],
  ["PARKMAN", "SK"],
  ["PARKSIDE", "SK"],
  ["PARKSVILLE", "BC"],
  ["PARLEE BROOK", "NB"],
  ["PARLEEVILLE", "NB"],
  ["PARRSBORO", "NS"],
  ["PARRY SOUND", "ON"],
  ["PARRY", "SK"],
  ["PARSON", "BC"],
  ["PARSONS POND", "NL"],
  ["PASADENA", "NL"],
  ["PASPEBIAC", "QC"],
  ["PASQUA", "SK"],
  ["PASS LAKE", "ON"],
  ["PASSEKEAG", "NB"],
  ["PATHLOW", "SK"],
  ["PATRICIA", "AB"],
  ["PATUANAK", "SK"],
  ["PAUINGASSI", "MB"],
  ["PAULATUK", "NT"],
  ["PAVILION", "BC"],
  ["PAWITIK", "ON"],
  ["PAYNTON", "SK"],
  ["PAYS PLAT", "ON"],
  ["PEACE RIVER RD", "BC"],
  ["PEACE RIVER", "AB"],
  ["PEACHLAND", "BC"],
  ["PEARSONVILLE", "NB"],
  ["PEAWANUCK", "ON"],
  ["PEEBLES", "SK"],
  ["PEEL", "NB"],
  ["PEERLESS LAKE", "AB"],
  ["PEERS", "AB"],
  ["PEFFERLAW", "ON"],
  ["PEGGYS COVE", "NS"],
  ["PEGUIS", "MB"],
  ["PELEE ISLAND", "ON"],
  ["PELERIN", "NB"],
  ["PELICAN NARROWS", "SK"],
  ["PELICAN RAPIDS", "MB"],
  ["PELLY CROSSING", "YT"],
  ["PELLY", "SK"],
  ["PELTOMA SETTLEMENT", "NB"],
  ["PEMBERTON RIDGE", "NB"],
  ["PEMBERTON", "BC"],
  ["PEMBROKE", "NB"],
  ["PEMBROKE", "ON"],
  ["PENDER ISLAND", "BC"],
  ["PENETANGUISHENE", "ON"],
  ["PENHOLD", "AB"],
  ["PENNANT STATION", "SK"],
  ["PENNFIELD", "NB"],
  ["PENNIAC", "NB"],
  ["PENNY", "BC"],
  ["PENOBSQUIS", "NB"],
  ["PENSE", "SK"],
  ["PENTICTON", "BC"],
  ["PENZANCE", "SK"],
  ["PERCE", "QC"],
  ["PERDUE", "SK"],
  ["PERIBONKA", "QC"],
  ["PERKINSFIELD", "ON"],
  ["PERRAULT FALLS", "ON"],
  ["PERRY SETTLEMENT", "NB"],
  ["PERRYVALE", "AB"],
  ["PERTH ROAD", "ON"],
  ["PERTH", "ON"],
  ["PERTH-ANDOVER", "NB"],
  ["PETAWAWA", "ON"],
  ["PETERBOROUGH", "ON"],
  ["PETERSBURG", "ON"],
  ["PETERSFIELD", "MB"],
  ["PETERVIEW", "NL"],
  ["PETIT DE GRAT", "NS"],
  ["PETIT ETANG", "NS"],
  ["PETIT FORTE", "NL"],
  ["PETIT TRACADIE", "NB"],
  ["PETIT-CAP", "NB"],
  ["PETIT-PAQUETVILLE", "NB"],
  ["PETIT-ROCHER", "NB"],
  ["PETIT-ROCHER-NORD", "NB"],
  ["PETIT-ROCHER-OUEST", "NB"],
  ["PETIT-ROCHER-SUD", "NB"],
  ["PETIT-SAGUENAY", "QC"],
  ["PETIT-SHIPPAGAN", "NB"],
  ["PETITCODIAC EAST", "NB"],
  ["PETITCODIAC", "NB"],
  ["PETITE RIVIERE", "NS"],
  ["PETITE-LAMEQUE", "NB"],
  ["PETITE-RIVIERE-DE-L'ILE", "NB"],
  ["PETITE-RIVIERE-SAINT-FRANCOIS", "QC"],
  ["PETITE-VALLEE", "QC"],
  ["PETROLIA", "ON"],
  ["PETTY HARBOUR", "NL"],
  ["PHELPSTON", "ON"],
  ["PHILIPSBURG", "QC"],
  ["PHIPPEN", "SK"],
  ["PIAPOT", "SK"],
  ["PICADILLY", "NB"],
  ["PICKARDVILLE", "AB"],
  ["PICKEREL", "ON"],
  ["PICKERING", "ON"],
  ["PICKETTS COVE", "NB"],
  ["PICKLE LAKE", "ON"],
  ["PICTON", "ON"],
  ["PICTOU ISLAND", "NS"],
  ["PICTOU", "NS"],
  ["PICTURE BUTTE", "AB"],
  ["PIEDMONT", "QC"],
  ["PIERCELAND", "SK"],
  ["PIERCEMONT", "NB"],
  ["PIERREFONDS", "QC"],
  ["PIERREVILLE", "QC"],
  ["PIERSON", "MB"],
  ["PIGEON HILL", "NB"],
  ["PIKANGIKUM", "ON"],
  ["PIKE RIVER", "QC"],
  ["PIKWITONEI", "MB"],
  ["PILGER", "SK"],
  ["PILLEYS ISLAND", "NL"],
  ["PILOT BUTTE", "SK"],
  ["PILOT MOUND", "MB"],
  ["PINANTAN LAKE", "BC"],
  ["PINAWA", "MB"],
  ["PINCHER CREEK", "AB"],
  ["PINCOURT", "QC"],
  ["PINE FALLS", "MB"],
  ["PINE GLEN", "NB"],
  ["PINE GROVE", "NS"],
  ["PINE GROVE.", "NS"],
  ["PINE LAKE", "AB"],
  ["PINE RIDGE", "NB"],
  ["PINE RIVER", "MB"],
  ["PINEHOUSE LAKE", "SK"],
  ["PINEHURST", "NS"],
  ["PINEVILLE", "NB"],
  ["PINEWOOD", "ON"],
  ["PINEY", "MB"],
  ["PINK MOUNTAIN", "BC"],
  ["PINTENDRE", "QC"],
  ["PIOPOLIS", "QC"],
  ["PIPERS COVE", "NS"],
  ["PIPESTONE", "MB"],
  ["PITT MEADOWS", "BC"],
  ["PLACENTIA", "NL"],
  ["PLAINFIELD", "ON"],
  ["PLAISANCE", "QC"],
  ["PLAMONDON", "AB"],
  ["PLANTAGENET", "ON"],
  ["PLASTER ROCK", "NB"],
  ["PLATE COVE EAST", "NL"],
  ["PLATE COVE WEST", "NL"],
  ["PLATO", "SK"],
  ["PLATTSVILLE", "ON"],
  ["PLEASANT BAY", "NS"],
  ["PLEASANT HILL", "NS"],
  ["PLEASANT RIDGE CHAR COUNTY", "NB"],
  ["PLEASANT RIDGE KINGS CO", "NB"],
  ["PLEASANT VILLA", "NB"],
  ["PLEASANTDALE", "SK"],
  ["PLEASANTVILLE", "NS"],
  ["PLENTY", "SK"],
  ["PLESSISVILLE", "QC"],
  ["PLEVNA", "ON"],
  ["PLUM COULEE", "MB"],
  ["PLUM POINT", "NL"],
  ["PLUMAS", "MB"],
  ["PLUMWESEEP", "NB"],
  ["PLUNKETT", "SK"],
  ["PLYMOUTH", "NB"],
  ["PLYMPTON", "NS"],
  ["POCOLOGAN", "NB"],
  ["POHENEGAMOOK", "QC"],
  ["POINT ACONI", "NS"],
  ["POINT DE BUTE", "NB"],
  ["POINT EDWARD", "NS"],
  ["POINT EDWARD", "ON"],
  ["POINT LA NIM", "NB"],
  ["POINT LEAMINGTON", "NL"],
  ["POINT OF BAY", "NL"],
  ["POINT TUPPER", "NS"],
  ["POINTE A BOULEAU", "NB"],
  ["POINTE A TOM", "NB"],
  ["POINTE AUX ROCHES", "ON"],
  ["POINTE DES ROBICHAUD", "NB"],
  ["POINTE DIXON POINT", "NB"],
  ["POINTE DU BOIS", "MB"],
  ["POINTE-A-LA-CROIX", "QC"],
  ["POINTE-A-LA-GARDE", "QC"],
  ["POINTE-ALEXANDRE", "NB"],
  ["POINTE-AU-BARIL-STATION", "ON"],
  ["POINTE-AUX-LOUPS", "QC"],
  ["POINTE-AUX-OUTARDES", "QC"],
  ["POINTE-AUX-TREMBLES", "QC"],
  ["POINTE-BRULE", "NB"],
  ["POINTE-CALUMET", "QC"],
  ["POINTE-CANOT", "NB"],
  ["POINTE-CLAIRE", "QC"],
  ["POINTE-DES-CASCADES", "QC"],
  ["POINTE-DU-CHENE", "NB"],
  ["POINTE-FORTUNE", "QC"],
  ["POINTE-LEBEL", "QC"],
  ["POINTE-SAPIN", "NB"],
  ["POINTE-SAUVAGE", "NB"],
  ["POINTE-VERTE", "NB"],
  ["POIRIER SUBDIVISION", "NB"],
  ["POKEMOUCHE", "NB"],
  ["POKESHAW", "NB"],
  ["POKESUDIE", "NB"],
  ["POKIOK", "NB"],
  ["POLE HILL", "NB"],
  ["POLLARDS POINT", "NL"],
  ["POLLETT RIVER", "NB"],
  ["POLLOCKVILLE", "AB"],
  ["POLONIA", "MB"],
  ["POMEROY RIDGE", "NB"],
  ["POND INLET", "NU"],
  ["PONDSTREAM", "NB"],
  ["PONOKA", "AB"],
  ["PONT LAFRANCE", "NB"],
  ["PONT LANDRY", "NB"],
  ["PONT-ROUGE", "QC"],
  ["PONTEIX", "SK"],
  ["PONTYPOOL", "ON"],
  ["POODIAC", "NB"],
  ["POOLE", "ON"],
  ["POOLS COVE", "NL"],
  ["POOLS ISLAND", "NL"],
  ["POPLAR HILL", "ON"],
  ["POPLAR POINT", "MB"],
  ["POPLARFIELD", "MB"],
  ["PORCUPINE PLAIN", "SK"],
  ["PORCUPINE", "ON"],
  ["PORQUIS JUNCTION", "ON"],
  ["PORT ALBERNI", "BC"],
  ["PORT ALBERT", "NL"],
  ["PORT ALICE", "BC"],
  ["PORT ALMA", "ON"],
  ["PORT ANSON", "NL"],
  ["PORT AU CHOIX", "NL"],
  ["PORT AU PORT", "NL"],
  ["PORT BLANDFORD", "NL"],
  ["PORT BURWELL", "ON"],
  ["PORT CALEDONIA", "NS"],
  ["PORT CARLING", "ON"],
  ["PORT CLEMENTS", "BC"],
  ["PORT CLYDE", "NS"],
  ["PORT COLBORNE", "ON"],
  ["PORT COQUITLAM", "BC"],
  ["PORT DE GRAVE", "NL"],
  ["PORT DOVER", "ON"],
  ["PORT DUFFERIN", "NS"],
  ["PORT EDWARD", "BC"],
  ["PORT ELGIN", "NB"],
  ["PORT ELGIN", "ON"],
  ["PORT FRANKS", "ON"],
  ["PORT GREVILLE", "NS"],
  ["PORT HARDY", "BC"],
  ["PORT HASTINGS", "NS"],
  ["PORT HAWKESBURY", "NS"],
  ["PORT HOOD", "NS"],
  ["PORT HOPE SIMPSON", "NL"],
  ["PORT HOPE", "ON"],
  ["PORT HOWE", "NS"],
  ["PORT JOLI", "NS"],
  ["PORT LA TOUR", "NS"],
  ["PORT LAMBTON", "ON"],
  ["PORT LORING", "ON"],
  ["PORT MAITLAND", "NS"],
  ["PORT MALCOLM", "NS"],
  ["PORT MCNEILL", "BC"],
  ["PORT MCNICOLL", "ON"],
  ["PORT MEDWAY", "NS"],
  ["PORT MELLON", "BC"],
  ["PORT MOODY", "BC"],
  ["PORT MORIEN", "NS"],
  ["PORT MOUTON", "NS"],
  ["PORT NEVILLE", "BC"],
  ["PORT PERRY", "ON"],
  ["PORT RENFREW", "BC"],
  ["PORT REXTON", "NL"],
  ["PORT ROBINSON", "ON"],
  ["PORT ROWAN", "ON"],
  ["PORT SANDFIELD", "ON"],
  ["PORT SAUNDERS", "NL"],
  ["PORT SEVERN", "ON"],
  ["PORT STANLEY", "ON"],
  ["PORT SYDNEY", "ON"],
  ["PORT UNION", "NL"],
  ["PORT WILLIAMS", "NS"],
  ["PORT-CARTIER", "QC"],
  ["PORT-DANIEL", "QC"],
  ["PORT-MENIER", "QC"],
  ["PORTAGE LA PRAIRIE", "MB"],
  ["PORTAGE ST-LOUIS", "NB"],
  ["PORTAGE VALE", "NB"],
  ["PORTAGE", "NB"],
  ["PORTAGE", "NS"],
  ["PORTAGE-DU-FORT", "QC"],
  ["PORTER COVE", "NB"],
  ["PORTERS LAKE", "NS"],
  ["PORTLAND CREEK", "NL"],
  ["PORTLAND", "ON"],
  ["PORTNEUF", "QC"],
  ["PORTNEUF-SUR-MER", "QC"],
  ["PORTREEVE", "SK"],
  ["PORTUGAL COVE-ST PHILIPS", "NL"],
  ["PORTUGUESE COVE", "NS"],
  ["POSTVILLE", "NL"],
  ["POUCE COUPE", "BC"],
  ["POUCH COVE", "NL"],
  ["POULARIES", "QC"],
  ["POUND COVE", "NL"],
  ["POWASSAN", "ON"],
  ["POWELL RIVER", "BC"],
  ["POWERVIEW", "MB"],
  ["POWNAL", "PE"],
  ["PRAIRIE RIVER", "SK"],
  ["PRAIRIE VIEW", "SK"],
  ["PRATT", "MB"],
  ["PREECEVILLE", "SK"],
  ["PREISSAC", "QC"],
  ["PRELATE", "SK"],
  ["PRESCOTT", "ON"],
  ["PRESPATOU", "BC"],
  ["PREVOST", "QC"],
  ["PRICE", "QC"],
  ["PRICEVILLE", "NB"],
  ["PRICEVILLE", "ON"],
  ["PRIDDIS", "AB"],
  ["PRIMATE", "SK"],
  ["PRIME BROOK", "NS"],
  ["PRINCE ALBERT", "ON"],
  ["PRINCE ALBERT", "SK"],
  ["PRINCE GEORGE", "BC"],
  ["PRINCE OF WALES", "NB"],
  ["PRINCE RUPERT", "BC"],
  ["PRINCE WILLIAM", "NB"],
  ["PRINCEPORT", "NS"],
  ["PRINCESS HARBOUR", "MB"],
  ["PRINCESS PARK", "NB"],
  ["PRINCETON", "BC"],
  ["PRINCETON", "NL"],
  ["PRINCETON", "ON"],
  ["PRINCEVILLE", "QC"],
  ["PRINTZ COVE", "NB"],
  ["PRITCHARD", "BC"],
  ["PROCTER", "BC"],
  ["PROGRESS", "BC"],
  ["PROPHET RIVER", "BC"],
  ["PROSPECT BAY", "NS"],
  ["PROSPECT VILLAGE", "NS"],
  ["PROSPECT", "NS"],
  ["PROSSER BROOK", "NB"],
  ["PROTON STATION", "ON"],
  ["PROULXVILLE", "QC"],
  ["PROVIDENCE BAY", "ON"],
  ["PROVOST", "AB"],
  ["PRUD'HOMME", "SK"],
  ["PUBLIC LANDING", "NB"],
  ["PUBNICO", "NS"],
  ["PUGWASH JUNCTION", "NS"],
  ["PUGWASH", "NS"],
  ["PUKATAWAGAN", "MB"],
  ["PUNNICHY", "SK"],
  ["PURPLE SPRINGS", "AB"],
  ["PUSLINCH", "ON"],
  ["PUTNAM", "ON"],
  ["PUVIRNITUQ", "QC"],
  ["PYNN'S BROOK", "NL"],
  ["QIKIQTARJUAQ", "NU"],
  ["QU'APPELLE", "SK"],
  ["QUADEVILLE", "ON"],
  ["QUAKER BROOK", "NB"],
  ["QUALICUM BEACH", "BC"],
  ["QUAQTAQ", "QC"],
  ["QUARRYVILLE", "NB"],
  ["QUATHIASKI COVE", "BC"],
  ["QUATSINO", "BC"],
  ["QUEBEC", "QC"],
  ["QUEEN CHARLOTTE", "BC"],
  ["QUEENSTON", "ON"],
  ["QUEENSTOWN", "NB"],
  ["QUEENSVILLE", "NS"],
  ["QUEENSVILLE", "ON"],
  ["QUESNEL", "BC"],
  ["QUILCHENA", "BC"],
  ["QUILL LAKE", "SK"],
  ["QUINTON", "SK"],
  ["QUISPAMSIS", "NB"],
  ["QUYON", "QC"],
  ["RABBIT LAKE", "SK"],
  ["RACINE", "QC"],
  ["RADISSON", "QC"],
  ["RADISSON", "SK"],
  ["RADIUM HOT SPRINGS", "BC"],
  ["RADVILLE", "SK"],
  ["RADWAY", "AB"],
  ["RAGUENEAU", "QC"],
  ["RAINBOW LAKE", "AB"],
  ["RAINIER", "AB"],
  ["RAINY RIVER", "ON"],
  ["RAITH", "ON"],
  ["RALEIGH", "NL"],
  ["RALSTON", "AB"],
  ["RAMA", "ON"],
  ["RAMA", "SK"],
  ["RAMEA", "NL"],
  ["RAMORE", "ON"],
  ["RAMSAYVILLE", "ON"],
  ["RAMSEY", "ON"],
  ["RANDOLPH", "MB"],
  ["RANFURLY", "AB"],
  ["RANG-SAINT-GEORGES", "NB"],
  ["RANKIN INLET", "NU"],
  ["RAPID CITY", "MB"],
  ["RAPID LAKE", "QC"],
  ["RAPID VIEW", "SK"],
  ["RAPIDE-DANSEUR", "QC"],
  ["RAPIDES-DES-JOACHIMS", "QC"],
  ["RATHWELL", "MB"],
  ["RATTER CORNER", "NB"],
  ["RATTLING BROOK", "NL"],
  ["RAVENNA", "ON"],
  ["RAVIGNAN", "QC"],
  ["RAWDON", "QC"],
  ["RAYMOND", "AB"],
  ["RAYMORE", "SK"],
  ["REABORO", "ON"],
  ["RED BANK QUEENS CO", "NB"],
  ["RED BANK RESERVE", "NB"],
  ["RED BANK", "NB"],
  ["RED BAY", "NL"],
  ["RED BRIDGE", "NB"],
  ["RED DEER COUNTY", "AB"],
  ["RED DEER", "AB"],
  ["RED EARTH CREEK", "AB"],
  ["RED EARTH", "SK"],
  ["RED HARBOUR PB", "NL"],
  ["RED HEAD COVE", "NL"],
  ["RED LAKE", "ON"],
  ["RED POINT", "NS"],
  ["RED RAPIDS", "NB"],
  ["RED ROCK", "NB"],
  ["RED ROCK", "ON"],
  ["RED SUCKER LAKE", "MB"],
  ["RED WILLOW", "AB"],
  ["REDBRIDGE", "ON"],
  ["REDCLIFF", "AB"],
  ["REDDITT", "ON"],
  ["REDMONDVILLE", "NB"],
  ["REDSTONE", "BC"],
  ["REDVERS", "SK"],
  ["REDWATER", "AB"],
  ["REDWOOD MEADOWS", "AB"],
  ["REEFS HARBOUR", "NL"],
  ["REFUGE COVE", "BC"],
  ["REGINA BEACH", "SK"],
  ["REGINA", "SK"],
  ["REIDVILLE", "NL"],
  ["REINFELD", "MB"],
  ["REMIGNY", "QC"],
  ["RENAUDS MILLS", "NB"],
  ["RENCONTRE EAST", "NL"],
  ["RENEWS", "NL"],
  ["RENFREW", "ON"],
  ["RENNIE", "MB"],
  ["RENOUS", "NB"],
  ["RENWER", "MB"],
  ["REPENTIGNY", "QC"],
  ["REPULSE BAY", "NU"],
  ["RESERVE MINES", "NS"],
  ["RESOLUTE", "NU"],
  ["RESTON", "MB"],
  ["RESTOULE", "ON"],
  ["REVELSTOKE", "BC"],
  ["REWARD", "SK"],
  ["REXTON", "NB"],
  ["RHEIN", "SK"],
  ["RHODES CORNER", "NS"],
  ["RICETON", "SK"],
  ["RICEVILLE", "NB"],
  ["RICHARD", "SK"],
  ["RICHARDS LANDING", "ON"],
  ["RICHARDSON", "NB"],
  ["RICHARDSON", "SK"],
  ["RICHELIEU", "QC"],
  ["RICHER", "MB"],
  ["RICHIBOUCTOU-VILLAGE", "NB"],
  ["RICHIBUCTO ROAD", "NB"],
  ["RICHIBUCTO", "NB"],
  ["RICHLEA", "SK"],
  ["RICHMOND CORNER", "NB"],
  ["RICHMOND HILL", "ON"],
  ["RICHMOND SETTLEMENT", "NB"],
  ["RICHMOND", "BC"],
  ["RICHMOND", "ON"],
  ["RICHMOND", "PE"],
  ["RICHMOND", "QC"],
  ["RICHMOUND", "SK"],
  ["RIDEAU FERRY", "ON"],
  ["RIDGEDALE", "SK"],
  ["RIDGETOWN", "ON"],
  ["RIDGEVILLE", "MB"],
  ["RIDGEVILLE", "ON"],
  ["RIDGEWAY", "ON"],
  ["RIDING MOUNTAIN", "MB"],
  ["RIGAUD", "QC"],
  ["RIGOLET", "NL"],
  ["RILEY BROOK", "NB"],
  ["RIMBEY", "AB"],
  ["RIMOUSKI", "QC"],
  ["RINES CREEK", "NS"],
  ["RIO GRANDE", "NB"],
  ["RIONDEL", "BC"],
  ["RIPLEY", "ON"],
  ["RIPON", "QC"],
  ["RIPPLES", "NB"],
  ["RISKE CREEK", "BC"],
  ["RITCHIE", "NB"],
  ["RIVER BOURGEOIS", "NS"],
  ["RIVER DE CHUTE", "NB"],
  ["RIVER DENYS", "NS"],
  ["RIVER DRIVE PARK", "ON"],
  ["RIVER GLADE", "NB"],
  ["RIVER HEBERT EAST", "NS"],
  ["RIVER HEBERT", "NS"],
  ["RIVER HILLS", "MB"],
  ["RIVER JOHN", "NS"],
  ["RIVER OF PONDS", "NL"],
  ["RIVER PHILIP", "NS"],
  ["RIVER RYAN", "NS"],
  ["RIVER VALLEY", "ON"],
  ["RIVERBANK CARLETON CO", "NB"],
  ["RIVERBANK KINGS CO", "NB"],
  ["RIVERBANK SOUTH", "NB"],
  ["RIVERCOURSE", "AB"],
  ["RIVERHEAD HARBOUR GRACE", "NL"],
  ["RIVERHURST", "SK"],
  ["RIVERPORT", "NS"],
  ["RIVERS", "MB"],
  ["RIVERSDALE", "NS"],
  ["RIVERSIDE ESTATES", "SK"],
  ["RIVERSIDE", "NS"],
  ["RIVERSIDE-ALBERT", "NB"],
  ["RIVERTON", "MB"],
  ["RIVERVIEW", "NB"],
  ["RIVIERE A LA TRUITE", "NB"],
  ["RIVIERE DU NORD", "NB"],
  ["RIVIERE QUI BARRE", "AB"],
  ["RIVIERE-A-CLAUDE", "QC"],
  ["RIVIERE-A-PIERRE", "QC"],
  ["RIVIERE-AU-TONNERRE", "QC"],
  ["RIVIERE-BEAUDETTE", "QC"],
  ["RIVIERE-BLEUE", "QC"],
  ["RIVIERE-DU-LOUP", "QC"],
  ["RIVIERE-DU-PORTAGE", "NB"],
  ["RIVIERE-ETERNITE", "QC"],
  ["RIVIERE-HEVA", "QC"],
  ["RIVIERE-MADELEINE", "QC"],
  ["RIVIERE-OUELLE", "QC"],
  ["RIVIERE-PASPEBIAC", "QC"],
  ["RIVIERE-PENTECOTE", "QC"],
  ["RIVIERE-ROUGE", "QC"],
  ["RIVIERE-SAINT-JEAN", "QC"],
  ["RIVIERE-SAINT-PAUL", "QC"],
  ["RIVIERE-TROIS-PISTOLES", "QC"],
  ["RIVIERE-VERTE", "NB"],
  ["ROACHVILLE", "NB"],
  ["ROBB", "AB"],
  ["ROBERTS ARM", "NL"],
  ["ROBERTS CREEK", "BC"],
  ["ROBERTVILLE", "NB"],
  ["ROBERVAL", "QC"],
  ["ROBICHAUD SETTLEMENT", "NB"],
  ["ROBINSONS", "NL"],
  ["ROBINSONVILLE", "NB"],
  ["ROBLIN", "MB"],
  ["ROBLIN", "ON"],
  ["ROBSART", "SK"],
  ["ROBSON", "BC"],
  ["ROCANVILLE", "SK"],
  ["ROCHEBAUCOURT", "QC"],
  ["ROCHES POINT", "ON"],
  ["ROCHESTER", "AB"],
  ["ROCHEVILLE", "NB"],
  ["ROCHFORT BRIDGE", "AB"],
  ["ROCHON SANDS", "AB"],
  ["ROCK CREEK", "BC"],
  ["ROCK ELM", "NS"],
  ["ROCK RIDGE", "MB"],
  ["ROCKCLIFFE", "ON"],
  ["ROCKGLEN", "SK"],
  ["ROCKHAVEN", "SK"],
  ["ROCKLAND", "NB"],
  ["ROCKLAND", "ON"],
  ["ROCKPORT", "NB"],
  ["ROCKPORT", "ON"],
  ["ROCKTON", "ON"],
  ["ROCKWOOD", "ON"],
  ["ROCKY HARBOUR", "NL"],
  ["ROCKY MOUNTAIN HOUSE", "AB"],
  ["ROCKY RAPIDS", "AB"],
  ["ROCKY VIEW", "AB"],
  ["ROCKYFORD", "AB"],
  ["RODDICKTON", "NL"],
  ["RODGERS COVE", "NL"],
  ["RODNEY", "ON"],
  ["ROGERSVILLE", "NB"],
  ["ROGERSVILLE-EST", "NB"],
  ["ROGERSVILLE-OUEST", "NB"],
  ["ROKEBY", "SK"],
  ["ROLAND", "MB"],
  ["ROLLA", "BC"],
  ["ROLLET", "QC"],
  ["ROLLING HILLS", "AB"],
  ["ROLLINGDAM", "NB"],
  ["ROLLY VIEW", "AB"],
  ["ROLPHTON", "ON"],
  ["ROQUEMAURE", "QC"],
  ["RORKETON", "MB"],
  ["ROSA", "MB"],
  ["ROSAIREVILLE", "NB"],
  ["ROSALIND", "AB"],
  ["ROSE BAY", "NS"],
  ["ROSE BLANCHE", "NL"],
  ["ROSE PRAIRIE", "BC"],
  ["ROSE VALLEY", "SK"],
  ["ROSEAU RIVER", "MB"],
  ["ROSEBUD", "AB"],
  ["ROSEDALE STATION", "AB"],
  ["ROSEDALE VALLEY", "AB"],
  ["ROSEDALE", "BC"],
  ["ROSEDALE", "NB"],
  ["ROSEHILL", "NB"],
  ["ROSEISLE", "MB"],
  ["ROSEMARY", "AB"],
  ["ROSEMERE", "QC"],
  ["ROSEMONT", "ON"],
  ["ROSENEATH", "ON"],
  ["ROSENFELD", "MB"],
  ["ROSENORT", "MB"],
  ["ROSETOWN", "SK"],
  ["ROSEVALE", "NB"],
  ["ROSLIN", "ON"],
  ["ROSS FERRY", "NS"],
  ["ROSS RIVER", "YT"],
  ["ROSSBURN", "MB"],
  ["ROSSEAU", "ON"],
  ["ROSSENDALE", "MB"],
  ["ROSSER", "MB"],
  ["ROSSLAND", "BC"],
  ["ROSSPORT", "ON"],
  ["ROSSVILLE", "NB"],
  ["ROSTHERN", "SK"],
  ["ROSTOCK", "ON"],
  ["ROTHESAY", "NB"],
  ["ROUGEMONT", "QC"],
  ["ROUGH WATERS", "NB"],
  ["ROULEAU", "SK"],
  ["ROUND HARBOUR GB", "NL"],
  ["ROUND HILL", "AB"],
  ["ROUND ISLAND", "NS"],
  ["ROUND LAKE CENTRE", "ON"],
  ["ROUTHIERVILLE", "QC"],
  ["ROUYN-NORANDA", "QC"],
  ["ROWENA", "NB"],
  ["ROWLEY", "AB"],
  ["ROWLEY", "NB"],
  ["ROXBORO", "QC"],
  ["ROXTON FALLS", "QC"],
  ["ROXTON POND", "QC"],
  ["ROYAL ROAD", "NB"],
  ["ROYALTON", "NB"],
  ["ROYSTON", "BC"],
  ["RUDDELL", "SK"],
  ["RUISSEAU-A-REBOURS", "QC"],
  ["RUMSEY", "AB"],
  ["RUNNYMEDE", "SK"],
  ["RUSAGONIS", "NB"],
  ["RUSCOM STATION", "ON"],
  ["RUSH LAKE", "SK"],
  ["RUSHOON", "NL"],
  ["RUSSELL", "MB"],
  ["RUSSELL", "ON"],
  ["RUSSELLVILLE", "NB"],
  ["RUTHERGLEN", "ON"],
  ["RUTHILDA", "SK"],
  ["RUTHVEN", "ON"],
  ["RYCROFT", "AB"],
  ["RYLEY", "AB"],
  ["SAANICH", "BC"],
  ["SAANICHTON", "BC"],
  ["SABLE RIVER", "NS"],
  ["SABREVOIS", "QC"],
  ["SACHIGO LAKE", "ON"],
  ["SACHS HARBOUR", "NT"],
  ["SACKVILLE ROAD", "NB"],
  ["SACKVILLE", "NB"],
  ["SACRE-COEUR-SAGUENAY", "QC"],
  ["SADDLE LAKE", "AB"],
  ["SAINT JOHN", "NB"],
  ["SAINT PONS", "NB"],
  ["SAINT-ADALBERT", "QC"],
  ["SAINT-ADELME", "QC"],
  ["SAINT-ADELPHE-DE-CHAMPLAIN", "QC"],
  ["SAINT-ADOLPHE-D'HOWARD", "QC"],
  ["SAINT-ADRIEN", "QC"],
  ["SAINT-ADRIEN-D'IRLANDE", "QC"],
  ["SAINT-AGAPIT", "QC"],
  ["SAINT-AIME", "QC"],
  ["SAINT-AIME-DES-LACS", "QC"],
  ["SAINT-ALBAN", "QC"],
  ["SAINT-ALBERT", "QC"],
  ["SAINT-ALEXANDRE-D'IBERVILLE", "QC"],
  ["SAINT-ALEXANDRE-DE-KAMOURASKA", "QC"],
  ["SAINT-ALEXANDRE-DES-LACS", "QC"],
  ["SAINT-ALEXIS-DE-MATAPEDIA", "QC"],
  ["SAINT-ALEXIS-DE-MONTCALM", "QC"],
  ["SAINT-ALEXIS-DES-MONTS", "QC"],
  ["SAINT-ALFRED", "QC"],
  ["SAINT-ALPHONSE-DE-CAPLAN", "QC"],
  ["SAINT-ALPHONSE-DE-GRANBY", "QC"],
  ["SAINT-ALPHONSE-RODRIGUEZ", "QC"],
  ["SAINT-AMABLE", "QC"],
  ["SAINT-AMATEUR", "NB"],
  ["SAINT-AMBROISE", "QC"],
  ["SAINT-AMBROISE-DE-KILDARE", "QC"],
  ["SAINT-ANACLET", "QC"],
  ["SAINT-ANDRE", "NB"],
  ["SAINT-ANDRE-AVELLIN", "QC"],
  ["SAINT-ANDRE-D'ARGENTEUIL", "QC"],
  ["SAINT-ANDRE-DE-KAMOURASKA", "QC"],
  ["SAINT-ANDRE-DE-RESTIGOUCHE", "QC"],
  ["SAINT-ANDRE-DU-LAC-SAINT-JEAN", "QC"],
  ["SAINT-ANDRE-LEBLANC", "NB"],
  ["SAINT-ANICET", "QC"],
  ["SAINT-ANSELME", "QC"],
  ["SAINT-ANTOINE SUD", "NB"],
  ["SAINT-ANTOINE", "NB"],
  ["SAINT-ANTOINE-ABBE", "QC"],
  ["SAINT-ANTOINE-DE-KENT", "NB"],
  ["SAINT-ANTOINE-DE-TILLY", "QC"],
  ["SAINT-ANTOINE-SUR-RICHELIEU", "QC"],
  ["SAINT-ANTONIN", "QC"],
  ["SAINT-APOLLINAIRE", "QC"],
  ["SAINT-ARMAND", "QC"],
  ["SAINT-ARSENE", "QC"],
  ["SAINT-ARTHUR", "NB"],
  ["SAINT-AUBERT", "QC"],
  ["SAINT-AUGUSTIN-DE-DESMAURES", "QC"],
  ["SAINT-AUGUSTIN-SAGUENAY", "QC"],
  ["SAINT-BARNABE-SUD", "QC"],
  ["SAINT-BARTHELEMY", "QC"],
  ["SAINT-BASILE", "NB"],
  ["SAINT-BASILE", "QC"],
  ["SAINT-BASILE-LE-GRAND", "QC"],
  ["SAINT-BENJAMIN", "QC"],
  ["SAINT-BENOIT-DU-LAC", "QC"],
  ["SAINT-BENOIT-LABRE", "QC"],
  ["SAINT-BERNARD", "QC"],
  ["SAINT-BERNARD-DE-LACOLLE", "QC"],
  ["SAINT-BERNARD-DE-MICHAUDVILLE", "QC"],
  ["SAINT-BERNARD-SUR-MER", "QC"],
  ["SAINT-BLAISE-SUR-RICHELIEU", "QC"],
  ["SAINT-BONAVENTURE", "QC"],
  ["SAINT-BONIFACE-DE-SHAWINIGAN", "QC"],
  ["SAINT-BRUNO", "QC"],
  ["SAINT-BRUNO-DE-GUIGUES", "QC"],
  ["SAINT-BRUNO-DE-KAMOURASKA", "QC"],
  ["SAINT-BRUNO-LAC-SAINT-JEAN", "QC"],
  ["SAINT-CALIXTE", "QC"],
  ["SAINT-CAMILLE", "QC"],
  ["SAINT-CAMILLE-DE-BELLECHASSE", "QC"],
  ["SAINT-CASIMIR", "QC"],
  ["SAINT-CELESTIN", "QC"],
  ["SAINT-CESAIRE", "QC"],
  ["SAINT-CHARLES", "NB"],
  ["SAINT-CHARLES-BORROMEE", "QC"],
  ["SAINT-CHARLES-DE-BELLECHASSE", "QC"],
  ["SAINT-CHARLES-DE-BOURGET", "QC"],
  ["SAINT-CHARLES-DE-DRUMMOND", "QC"],
  ["SAINT-CHARLES-GARNIER", "QC"],
  ["SAINT-CHARLES-SUR-RICHELIEU", "QC"],
  ["SAINT-CHRISTOPHE-D'ARTHABASKA", "QC"],
  ["SAINT-CHRYSOSTOME", "QC"],
  ["SAINT-CLAUDE", "QC"],
  ["SAINT-CLEMENT", "QC"],
  ["SAINT-CLEOPHAS", "QC"],
  ["SAINT-CLEOPHAS-DE-BRANDON", "QC"],
  ["SAINT-CLET", "QC"],
  ["SAINT-COLOMBAN", "QC"],
  ["SAINT-COME", "QC"],
  ["SAINT-COME-LINIERE", "QC"],
  ["SAINT-CONSTANT", "QC"],
  ["SAINT-CUTHBERT", "QC"],
  ["SAINT-CYPRIEN", "QC"],
  ["SAINT-CYPRIEN-DES-ETCHEMINS", "QC"],
  ["SAINT-CYRILLE-DE-L'ISLET", "QC"],
  ["SAINT-CYRILLE-DE-WENDOVER", "QC"],
  ["SAINT-DAMASE", "QC"],
  ["SAINT-DAMASE-DE-MATAPEDIA", "QC"],
  ["SAINT-DAMASE-DES-AULNAIES", "QC"],
  ["SAINT-DAMIEN", "NB"],
  ["SAINT-DAMIEN", "QC"],
  ["SAINT-DAMIEN-DE-BUCKLAND", "QC"],
  ["SAINT-DAVID", "QC"],
  ["SAINT-DENIS-DE-BROMPTON", "QC"],
  ["SAINT-DENIS-DE-LA-BOUTEILLERIE", "QC"],
  ["SAINT-DENIS-SUR-RICHELIEU", "QC"],
  ["SAINT-DIDACE", "QC"],
  ["SAINT-DOMINIQUE", "QC"],
  ["SAINT-DOMINIQUE-DU-ROSAIRE", "QC"],
  ["SAINT-DONAT", "QC"],
  ["SAINT-DONAT-DE-MONTCALM", "QC"],
  ["SAINT-EDMOND-DE-GRANTHAM", "QC"],
  ["SAINT-EDMOND-LES-PLAINES", "QC"],
  ["SAINT-EDOUARD-DE-KENT", "NB"],
  ["SAINT-EDOUARD-DE-LOTBINIERE", "QC"],
  ["SAINT-EDOUARD-DE-MASKINONGE", "QC"],
  ["SAINT-EDOUARD-DE-NAPIERVILLE", "QC"],
  ["SAINT-ELIE-DE-CAXTON", "QC"],
  ["SAINT-ELOI", "QC"],
  ["SAINT-ELPHEGE", "QC"],
  ["SAINT-ELZEAR", "QC"],
  ["SAINT-ELZEAR-DE-BONAVENTURE", "QC"],
  ["SAINT-ELZEAR-DE-TEMISCOUATA", "QC"],
  ["SAINT-EMILE-DE-SUFFOLK", "QC"],
  ["SAINT-EPHREM-DE-BEAUCE", "QC"],
  ["SAINT-EPIPHANE", "QC"],
  ["SAINT-ESPRIT", "QC"],
  ["SAINT-ETIENNE-DE-BEAUHARNOIS", "QC"],
  ["SAINT-ETIENNE-DE-BOLTON", "QC"],
  ["SAINT-ETIENNE-DE-LAUZON", "QC"],
  ["SAINT-ETIENNE-DES-GRES", "QC"],
  ["SAINT-EUGENE-D'ARGENTENAY", "QC"],
  ["SAINT-EUGENE-DE-GRANTHAM", "QC"],
  ["SAINT-EUGENE-DE-GUIGUES", "QC"],
  ["SAINT-EUSEBE", "QC"],
  ["SAINT-EUSTACHE", "QC"],
  ["SAINT-EVARISTE-DE-FORSYTH", "QC"],
  ["SAINT-FABIEN", "QC"],
  ["SAINT-FABIEN-DE-PANET", "QC"],
  ["SAINT-FAUSTIN-LAC-CARRE", "QC"],
  ["SAINT-FELICIEN", "QC"],
  ["SAINT-FELIX-D'OTIS", "QC"],
  ["SAINT-FELIX-DE-DALQUIER", "QC"],
  ["SAINT-FELIX-DE-KINGSEY", "QC"],
  ["SAINT-FELIX-DE-VALOIS", "QC"],
  ["SAINT-FERDINAND", "QC"],
  ["SAINT-FERREOL-LES-NEIGES", "QC"],
  ["SAINT-FLAVIEN", "QC"],
  ["SAINT-FORTUNAT", "QC"],
  ["SAINT-FRANCOIS-D'ASSISE", "QC"],
  ["SAINT-FRANCOIS-D'ORLEANS", "QC"],
  ["SAINT-FRANCOIS-DE-MADAWASKA", "NB"],
  ["SAINT-FRANCOIS-DE-SALES", "QC"],
  ["SAINT-FRANCOIS-DU-LAC", "QC"],
  ["SAINT-FRANCOIS-XAVIER-DE-VIGER", "QC"],
  ["SAINT-FREDERIC", "QC"],
  ["SAINT-FULGENCE", "QC"],
  ["SAINT-GABRIEL-DE-BRANDON", "QC"],
  ["SAINT-GABRIEL-DE-RIMOUSKI", "QC"],
  ["SAINT-GABRIEL-DE-VALCARTIER", "QC"],
  ["SAINT-GEDEON", "QC"],
  ["SAINT-GEDEON-DE-BEAUCE", "QC"],
  ["SAINT-GEORGES", "QC"],
  ["SAINT-GEORGES-DE-CHAMPLAIN", "QC"],
  ["SAINT-GEORGES-DE-MALBAIE", "QC"],
  ["SAINT-GEORGES-DE-WINDSOR", "QC"],
  ["SAINT-GERARD-DES-LAURENTIDES", "QC"],
  ["SAINT-GERMAIN", "QC"],
  ["SAINT-GERMAIN-DE-GRANTHAM", "QC"],
  ["SAINT-GERVAIS", "QC"],
  ["SAINT-GILBERT", "QC"],
  ["SAINT-GILLES", "QC"],
  ["SAINT-GODEFROI", "QC"],
  ["SAINT-GREGOIRE", "NB"],
  ["SAINT-GUILLAUME", "QC"],
  ["SAINT-GUY", "QC"],
  ["SAINT-HENRI-DE-LEVIS", "QC"],
  ["SAINT-HERMENEGILDE", "QC"],
  ["SAINT-HILARION", "QC"],
  ["SAINT-HIPPOLYTE", "QC"],
  ["SAINT-HONORE-DE-CHICOUTIMI", "QC"],
  ["SAINT-HONORE-DE-SHENLEY", "QC"],
  ["SAINT-HONORE-DE-TEMISCOUATA", "QC"],
  ["SAINT-HUBERT", "QC"],
  ["SAINT-HUBERT-RIVIERE-DU-LOUP", "QC"],
  ["SAINT-HUGUES", "QC"],
  ["SAINT-HYACINTHE", "QC"],
  ["SAINT-IGNACE", "NB"],
  ["SAINT-IGNACE-DE-LOYOLA", "QC"],
  ["SAINT-IGNACE-DE-STANBRIDGE", "QC"],
  ["SAINT-IRENEE", "NB"],
  ["SAINT-IRENEE", "QC"],
  ["SAINT-ISIDORE", "NB"],
  ["SAINT-ISIDORE", "QC"],
  ["SAINT-ISIDORE-DE-CLIFTON", "QC"],
  ["SAINT-ISIDORE-DE-LAPRAIRIE", "QC"],
  ["SAINT-JACQUES", "NB"],
  ["SAINT-JACQUES", "QC"],
  ["SAINT-JACQUES-DE-LEEDS", "QC"],
  ["SAINT-JACQUES-LE-MINEUR", "QC"],
  ["SAINT-JEAN-BAPTISTE", "QC"],
  ["SAINT-JEAN-CHRYSOSTOME", "QC"],
  ["SAINT-JEAN-D'ORLEANS", "QC"],
  ["SAINT-JEAN-DE-BREBEUF", "QC"],
  ["SAINT-JEAN-DE-CHERBOURG", "QC"],
  ["SAINT-JEAN-DE-DIEU", "QC"],
  ["SAINT-JEAN-DE-LA-LANDE", "QC"],
  ["SAINT-JEAN-DE-MATAPEDIA", "QC"],
  ["SAINT-JEAN-DE-MATHA", "QC"],
  ["SAINT-JEAN-DES-PILES", "QC"],
  ["SAINT-JEAN-PORT-JOLI", "QC"],
  ["SAINT-JEAN-SUR-RICHELIEU", "QC"],
  ["SAINT-JEROME", "QC"],
  ["SAINT-JOACHIM-DE-COURVAL", "QC"],
  ["SAINT-JOACHIM-DE-SHEFFORD", "QC"],
  ["SAINT-JOGUES", "QC"],
  ["SAINT-JOSEPH-DE-BEAUCE", "QC"],
  ["SAINT-JOSEPH-DE-COLERAINE", "QC"],
  ["SAINT-JOSEPH-DE-HAM-SUD", "QC"],
  ["SAINT-JOSEPH-DE-KAMOURASKA", "QC"],
  ["SAINT-JOSEPH-DE-KENT", "NB"],
  ["SAINT-JOSEPH-DE-LA-RIVE", "QC"],
  ["SAINT-JOSEPH-DE-LEPAGE", "QC"],
  ["SAINT-JOSEPH-DE-SOREL", "QC"],
  ["SAINT-JOSEPH-DU-LAC", "QC"],
  ["SAINT-JUDE", "QC"],
  ["SAINT-JULES", "QC"],
  ["SAINT-JULIEN", "QC"],
  ["SAINT-JUST-DE-BRETENIERES", "QC"],
  ["SAINT-JUSTE-DU-LAC", "QC"],
  ["SAINT-JUSTIN", "QC"],
  ["SAINT-LAMBERT", "QC"],
  ["SAINT-LAMBERT-DE-LAUZON", "QC"],
  ["SAINT-LAURENT NORD", "NB"],
  ["SAINT-LAURENT", "NB"],
  ["SAINT-LAURENT", "QC"],
  ["SAINT-LAURENT-ILE-D'ORLEANS", "QC"],
  ["SAINT-LAZARE", "QC"],
  ["SAINT-LAZARE-DE-BELLECHASSE", "QC"],
  ["SAINT-LEANDRE", "QC"],
  ["SAINT-LEOLIN", "NB"],
  ["SAINT-LEON", "QC"],
  ["SAINT-LEON-LE-GRAND", "QC"],
  ["SAINT-LEONARD", "NB"],
  ["SAINT-LEONARD", "QC"],
  ["SAINT-LEONARD-D'ASTON", "QC"],
  ["SAINT-LEONARD-DE-PORTNEUF", "QC"],
  ["SAINT-LEONARD-PARENT", "NB"],
  ["SAINT-LIBOIRE", "QC"],
  ["SAINT-LIGUORI", "QC"],
  ["SAINT-LIN-LAURENTIDES", "QC"],
  ["SAINT-LOUIS", "NB"],
  ["SAINT-LOUIS", "QC"],
  ["SAINT-LOUIS-DE-BLANDFORD", "QC"],
  ["SAINT-LOUIS-DE-GONZAGUE", "QC"],
  ["SAINT-LOUIS-DE-KENT", "NB"],
  ["SAINT-LOUIS-DU-HA-HA", "QC"],
  ["SAINT-LUC-DE-BELLECHASSE", "QC"],
  ["SAINT-LUC-DE-VINCENNES", "QC"],
  ["SAINT-LUCIEN", "QC"],
  ["SAINT-LUDGER", "QC"],
  ["SAINT-LUDGER-DE-MILOT", "QC"],
  ["SAINT-MAGLOIRE", "QC"],
  ["SAINT-MAJORIQUE", "QC"],
  ["SAINT-MALACHIE", "QC"],
  ["SAINT-MALO", "QC"],
  ["SAINT-MARC-DE-FIGUERY", "QC"],
  ["SAINT-MARC-DES-CARRIERES", "QC"],
  ["SAINT-MARC-DU-LAC-LONG", "QC"],
  ["SAINT-MARC-SUR-RICHELIEU", "QC"],
  ["SAINT-MARCEL-DE-RICHELIEU", "QC"],
  ["SAINT-MARCELLIN", "QC"],
  ["SAINT-MARTIN", "QC"],
  ["SAINT-MARTIN-DE-RESTIGOUCHE", "NB"],
  ["SAINT-MATHIAS-SUR-RICHELIEU", "QC"],
  ["SAINT-MATHIEU-D'HARRICANA", "QC"],
  ["SAINT-MATHIEU-DE-BELOEIL", "QC"],
  ["SAINT-MATHIEU-DE-LAPRAIRIE", "QC"],
  ["SAINT-MATHIEU-DE-RIOUX", "QC"],
  ["SAINT-MATHIEU-DU-PARC", "QC"],
  ["SAINT-MAURE", "NB"],
  ["SAINT-MAURICE", "NB"],
  ["SAINT-MAURICE", "QC"],
  ["SAINT-MEDARD", "QC"],
  ["SAINT-MICHEL", "QC"],
  ["SAINT-MICHEL-DE-BELLECHASSE", "QC"],
  ["SAINT-MICHEL-DES-SAINTS", "QC"],
  ["SAINT-MODESTE", "QC"],
  ["SAINT-MOISE", "QC"],
  ["SAINT-NARCISSE", "QC"],
  ["SAINT-NARCISSE-DE-BEAURIVAGE", "QC"],
  ["SAINT-NARCISSE-DE-RIMOUSKI", "QC"],
  ["SAINT-NAZAIRE-D'ACTON", "QC"],
  ["SAINT-NAZAIRE-DE-DORCHESTER", "QC"],
  ["SAINT-NEREE", "QC"],
  ["SAINT-NICEPHORE", "QC"],
  ["SAINT-NICOLAS", "QC"],
  ["SAINT-NOEL", "QC"],
  ["SAINT-NORBERT", "NB"],
  ["SAINT-NORBERT", "QC"],
  ["SAINT-NORBERT-D'ARTHABASKA", "QC"],
  ["SAINT-ODILON", "QC"],
  ["SAINT-OMER", "QC"],
  ["SAINT-OMER-L'ISLET", "QC"],
  ["SAINT-OURS", "QC"],
  ["SAINT-PACOME", "QC"],
  ["SAINT-PAMPHILE", "QC"],
  ["SAINT-PASCAL", "QC"],
  ["SAINT-PASCAL-BAYLON", "ON"],
  ["SAINT-PATRICE-DE-BEAURIVAGE", "QC"],
  ["SAINT-PAUL", "NB"],
  ["SAINT-PAUL", "QC"],
  ["SAINT-PAUL-D'ABBOTSFORD", "QC"],
  ["SAINT-PAUL-DE-LA-CROIX", "QC"],
  ["SAINT-PAUL-DE-MONTMINY", "QC"],
  ["SAINT-PAULIN", "QC"],
  ["SAINT-PHILEMON", "QC"],
  ["SAINT-PHILIBERT", "QC"],
  ["SAINT-PHILIPPE", "NB"],
  ["SAINT-PHILIPPE", "QC"],
  ["SAINT-PHILIPPE-DE-NERI", "QC"],
  ["SAINT-PIE", "QC"],
  ["SAINT-PIE-DE-GUIRE", "QC"],
  ["SAINT-PIERRE-BAPTISTE", "QC"],
  ["SAINT-PIERRE-DE-BROUGHTON", "QC"],
  ["SAINT-PIERRE-DE-LAMY", "QC"],
  ["SAINT-PIERRE-ILE-D'ORLEANS", "QC"],
  ["SAINT-PIERRE-LES-BECQUETS", "QC"],
  ["SAINT-PLACIDE", "QC"],
  ["SAINT-POLYCARPE", "QC"],
  ["SAINT-PRIME", "QC"],
  ["SAINT-PROSPER", "QC"],
  ["SAINT-PROSPER-DE-DORCHESTER", "QC"],
  ["SAINT-QUENTIN", "NB"],
  ["SAINT-RAPHAEL", "QC"],
  ["SAINT-RAYMOND", "QC"],
  ["SAINT-REDEMPTEUR", "QC"],
  ["SAINT-REMI", "QC"],
  ["SAINT-REMI-D'AMHERST", "QC"],
  ["SAINT-REMI-DE-TINGWICK", "QC"],
  ["SAINT-RENE", "QC"],
  ["SAINT-RENE-DE-MATANE", "QC"],
  ["SAINT-ROBERT", "QC"],
  ["SAINT-ROBERT-BELLARMIN", "QC"],
  ["SAINT-ROCH-DE-L'ACHIGAN", "QC"],
  ["SAINT-ROCH-DE-MEKINAC", "QC"],
  ["SAINT-ROCH-DE-RICHELIEU", "QC"],
  ["SAINT-ROCH-DES-AULNAIES", "QC"],
  ["SAINT-ROCH-OUEST", "QC"],
  ["SAINT-ROMAIN", "QC"],
  ["SAINT-ROMUALD", "QC"],
  ["SAINT-ROSAIRE", "QC"],
  ["SAINT-SAMUEL", "QC"],
  ["SAINT-SAUVEUR", "NB"],
  ["SAINT-SAUVEUR", "QC"],
  ["SAINT-SEBASTIEN", "QC"],
  ["SAINT-SEBASTIEN-DE-FRONTENAC", "QC"],
  ["SAINT-SEVERE", "QC"],
  ["SAINT-SEVERIN-DE-BEAUCE", "QC"],
  ["SAINT-SIMEON", "QC"],
  ["SAINT-SIMEON-DE-BONAVENTURE", "QC"],
  ["SAINT-SIMON", "NB"],
  ["SAINT-SIMON-DE-BAGOT", "QC"],
  ["SAINT-SIMON-DE-RIMOUSKI", "QC"],
  ["SAINT-SIMON-LES-MINES", "QC"],
  ["SAINT-STANISLAS", "QC"],
  ["SAINT-STANISLAS-DE-CHAMPLAIN", "QC"],
  ["SAINT-STANISLAS-DE-KOSTKA", "QC"],
  ["SAINT-SULPICE", "QC"],
  ["SAINT-SYLVERE", "QC"],
  ["SAINT-SYLVESTRE", "QC"],
  ["SAINT-TELESPHORE", "QC"],
  ["SAINT-THARCISIUS", "QC"],
  ["SAINT-THEODORE-D'ACTON", "QC"],
  ["SAINT-THEOPHILE", "QC"],
  ["SAINT-THOMAS", "QC"],
  ["SAINT-THOMAS-DE-KENT", "NB"],
  ["SAINT-THOMAS-DIDYME", "QC"],
  ["SAINT-THURIBE", "QC"],
  ["SAINT-TITE", "QC"],
  ["SAINT-TITE-DES-CAPS", "QC"],
  ["SAINT-UBALDE", "QC"],
  ["SAINT-ULRIC", "QC"],
  ["SAINT-URBAIN-DE-CHARLEVOIX", "QC"],
  ["SAINT-URBAIN-PREMIER", "QC"],
  ["SAINT-VALENTIN", "QC"],
  ["SAINT-VALERE", "QC"],
  ["SAINT-VALERIEN-DE-RIMOUSKI", "QC"],
  ["SAINT-VALLIER", "QC"],
  ["SAINT-VIANNEY", "QC"],
  ["SAINT-VICTOR", "QC"],
  ["SAINT-VITAL-DE-CLERMONT", "QC"],
  ["SAINT-WENCESLAS", "QC"],
  ["SAINT-WILFRED", "NB"],
  ["SAINT-ZACHARIE", "QC"],
  ["SAINT-ZENON", "QC"],
  ["SAINT-ZEPHIRIN-DE-COURVAL", "QC"],
  ["SAINT-ZOTIQUE", "QC"],
  ["SAINTE ROSE", "NB"],
  ["SAINTE-ADELE", "QC"],
  ["SAINTE-AGATHE-DE-LOTBINIERE", "QC"],
  ["SAINTE-AGATHE-DES-MONTS", "QC"],
  ["SAINTE-AGATHE-NORD", "QC"],
  ["SAINTE-AGNES-DE-DUNDEE", "QC"],
  ["SAINTE-ANGELE-DE-MERICI", "QC"],
  ["SAINTE-ANGELE-DE-MONNOIR", "QC"],
  ["SAINTE-ANGELE-DE-PREMONT", "QC"],
  ["SAINTE-ANNE GLOUCESTER CO", "NB"],
  ["SAINTE-ANNE-DE-BEAUPRE", "QC"],
  ["SAINTE-ANNE-DE-BELLEVUE", "QC"],
  ["SAINTE-ANNE-DE-KENT", "NB"],
  ["SAINTE-ANNE-DE-LA-PERADE", "QC"],
  ["SAINTE-ANNE-DE-LA-ROCHELLE", "QC"],
  ["SAINTE-ANNE-DE-MADAWASKA", "NB"],
  ["SAINTE-ANNE-DE-SOREL", "QC"],
  ["SAINTE-ANNE-DES-LACS", "QC"],
  ["SAINTE-ANNE-DES-MONTS", "QC"],
  ["SAINTE-ANNE-DES-PLAINES", "QC"],
  ["SAINTE-ANNE-DU-LAC", "QC"],
  ["SAINTE-ANNE-DU-SAULT", "QC"],
  ["SAINTE-APOLLINE-DE-PATTON", "QC"],
  ["SAINTE-AURELIE", "QC"],
  ["SAINTE-BARBE", "QC"],
  ["SAINTE-BEATRIX", "QC"],
  ["SAINTE-BRIGIDE-D'IBERVILLE", "QC"],
  ["SAINTE-BRIGITTE-DE-LAVAL", "QC"],
  ["SAINTE-BRIGITTE-DES-SAULTS", "QC"],
  ["SAINTE-CATHERINE", "QC"],
  ["SAINTE-CATHERINE-DE-HATLEY", "QC"],
  ["SAINTE-CECILE", "NB"],
  ["SAINTE-CECILE-DE-LEVRARD", "QC"],
  ["SAINTE-CECILE-DE-MASHAM", "QC"],
  ["SAINTE-CECILE-DE-MILTON", "QC"],
  ["SAINTE-CECILE-DE-WHITTON", "QC"],
  ["SAINTE-CHRISTINE", "QC"],
  ["SAINTE-CHRISTINE-D'AUVERGNE", "QC"],
  ["SAINTE-CLAIRE", "QC"],
  ["SAINTE-CLOTILDE-DE-BEAUCE", "QC"],
  ["SAINTE-CLOTILDE-DE-CHATEAUGUAY", "QC"],
  ["SAINTE-CLOTILDE-DE-HORTON", "QC"],
  ["SAINTE-CROIX", "QC"],
  ["SAINTE-DOROTHEE", "QC"],
  ["SAINTE-EDWIDGE", "QC"],
  ["SAINTE-ELISABETH", "QC"],
  ["SAINTE-ELISABETH-DE-PROULX", "QC"],
  ["SAINTE-ELIZABETH-DE-WARWICK", "QC"],
  ["SAINTE-EMELIE-DE-L'ENERGIE", "QC"],
  ["SAINTE-EULALIE", "QC"],
  ["SAINTE-EUPHEMIE", "QC"],
  ["SAINTE-FAMILLE", "QC"],
  ["SAINTE-FELICITE", "QC"],
  ["SAINTE-FELICITE-DE-L'ISLET", "QC"],
  ["SAINTE-FLAVIE", "QC"],
  ["SAINTE-FLORENCE", "QC"],
  ["SAINTE-FRANCOISE", "QC"],
  ["SAINTE-FRANCOISE-DE-LOTBINIERE", "QC"],
  ["SAINTE-GENEVIEVE", "QC"],
  ["SAINTE-GENEVIEVE-DE-BATISCAN", "QC"],
  ["SAINTE-GERMAINE-BOULE", "QC"],
  ["SAINTE-GERTRUDE-MANNEVILLE", "QC"],
  ["SAINTE-HEDWIDGE-DE-ROBERVAL", "QC"],
  ["SAINTE-HELENE-DE-BAGOT", "QC"],
  ["SAINTE-HELENE-DE-BREAKEYVILLE", "QC"],
  ["SAINTE-HELENE-DE-CHESTER", "QC"],
  ["SAINTE-HELENE-DE-KAMOURASKA", "QC"],
  ["SAINTE-HENEDINE", "QC"],
  ["SAINTE-JULIE", "QC"],
  ["SAINTE-JULIENNE", "QC"],
  ["SAINTE-JUSTINE", "QC"],
  ["SAINTE-JUSTINE-DE-NEWTON", "QC"],
  ["SAINTE-LOUISE", "NB"],
  ["SAINTE-LOUISE", "QC"],
  ["SAINTE-LUCE", "QC"],
  ["SAINTE-LUCIE-DE-BEAUREGARD", "QC"],
  ["SAINTE-LUCIE-DES-LAURENTIDES", "QC"],
  ["SAINTE-MADELEINE", "QC"],
  ["SAINTE-MARCELLINE-DE-KILDARE", "QC"],
  ["SAINTE-MARGUERITE-MARIE", "QC"],
  ["SAINTE-MARIE", "QC"],
  ["SAINTE-MARIE-DE-BLANDFORD", "QC"],
  ["SAINTE-MARIE-DE-KENT", "NB"],
  ["SAINTE-MARIE-SAINT-RAPHAEL", "NB"],
  ["SAINTE-MARIE-SALOME", "QC"],
  ["SAINTE-MARTHE", "QC"],
  ["SAINTE-MARTHE-SUR-LE-LAC", "QC"],
  ["SAINTE-MARTINE", "QC"],
  ["SAINTE-MELANIE", "QC"],
  ["SAINTE-MONIQUE", "QC"],
  ["SAINTE-MONIQUE-LAC-SAINT-JEAN", "QC"],
  ["SAINTE-PAULE", "QC"],
  ["SAINTE-PERPETUE", "QC"],
  ["SAINTE-PERPETUE-DE-L'ISLET", "QC"],
  ["SAINTE-PETRONILLE", "QC"],
  ["SAINTE-RITA", "QC"],
  ["SAINTE-ROSE", "QC"],
  ["SAINTE-ROSE-DE-WATFORD", "QC"],
  ["SAINTE-ROSE-DU-NORD", "QC"],
  ["SAINTE-ROSETTE", "NB"],
  ["SAINTE-SABINE", "QC"],
  ["SAINTE-SABINE-DE-BELLECHASSE", "QC"],
  ["SAINTE-SERAPHINE", "QC"],
  ["SAINTE-SOPHIE", "QC"],
  ["SAINTE-SOPHIE-D'HALIFAX", "QC"],
  ["SAINTE-SOPHIE-DE-LEVRARD", "QC"],
  ["SAINTE-THECLE", "QC"],
  ["SAINTE-THERESE SUD", "NB"],
  ["SAINTE-THERESE", "QC"],
  ["SAINTE-THERESE-DE-GASPE", "QC"],
  ["SAINTE-THERESE-DE-LA-GATINEAU", "QC"],
  ["SAINTE-URSULE", "QC"],
  ["SAINTE-VICTOIRE-DE-SOREL", "QC"],
  ["SAINTS-ANGES", "QC"],
  ["SAINTS-MARTYRS-CANADIENS", "QC"],
  ["SALABERRY-DE-VALLEYFIELD", "QC"],
  ["SALEM", "NB"],
  ["SALFORD", "ON"],
  ["SALISBURY WEST", "NB"],
  ["SALISBURY", "NB"],
  ["SALLUIT", "QC"],
  ["SALLYS COVE", "NL"],
  ["SALMO", "BC"],
  ["SALMON ARM", "BC"],
  ["SALMON BEACH", "NB"],
  ["SALMON COVE BDV", "NL"],
  ["SALMON CREEK", "NB"],
  ["SALMON RIVER ROAD", "NB"],
  ["SALMON RIVER", "NB"],
  ["SALMON RIVER", "NS"],
  ["SALMON RIVER.", "NS"],
  ["SALT SPRING ISLAND", "BC"],
  ["SALT SPRINGS", "NB"],
  ["SALT SPRINGS", "NS"],
  ["SALTCOATS", "SK"],
  ["SALVAGE", "NL"],
  ["SAMBRO CREEK", "NS"],
  ["SAMBRO HEAD", "NS"],
  ["SAMBRO", "NS"],
  ["SAMPSON COVE", "NS"],
  ["SAN CLARA", "MB"],
  ["SANDFIELD", "NS"],
  ["SANDFORD", "ON"],
  ["SANDILANDS", "MB"],
  ["SANDRINGHAM", "NL"],
  ["SANDSPIT", "BC"],
  ["SANDY BAY", "SK"],
  ["SANDY COVE", "NL"],
  ["SANDY COVE", "NS"],
  ["SANDY HOOK", "MB"],
  ["SANDY LAKE", "MB"],
  ["SANDY LAKE", "ON"],
  ["SANFORD", "MB"],
  ["SANGUDO", "AB"],
  ["SANIKILUAQ", "NU"],
  ["SARNIA", "ON"],
  ["SARSFIELD", "ON"],
  ["SARTO", "MB"],
  ["SASKATOON", "SK"],
  ["SATURNA", "BC"],
  ["SAUBLE BEACH", "ON"],
  ["SAULNIERVILLE", "NS"],
  ["SAULT STE. MARIE", "ON"],
  ["SAUMAREZ", "NB"],
  ["SAVANT LAKE", "ON"],
  ["SAVOIE LANDING", "NB"],
  ["SAVONA", "BC"],
  ["SAWYERVILLE", "QC"],
  ["SAYABEC", "QC"],
  ["SAYWARD", "BC"],
  ["SCANDIA", "AB"],
  ["SCANTERBURY", "MB"],
  ["SCARBOROUGH", "ON"],
  ["SCEPTRE", "SK"],
  ["SCHANZENFELD", "MB"],
  ["SCHEFFERVILLE", "QC"],
  ["SCHOMBERG", "ON"],
  ["SCHREIBER", "ON"],
  ["SCHULER", "AB"],
  ["SCHUMACHER", "ON"],
  ["SCOTCH CREEK", "BC"],
  ["SCOTCH LAKE", "NB"],
  ["SCOTCH LAKE", "NS"],
  ["SCOTCH RIDGE", "NB"],
  ["SCOTCH SETTLEMENT YORK CO", "NB"],
  ["SCOTCH SETTLEMENT", "NB"],
  ["SCOTCH VILLAGE", "NS"],
  ["SCOTCHTOWN", "NB"],
  ["SCOTCHTOWN", "NS"],
  ["SCOTLAND", "ON"],
  ["SCOTSBURN", "NS"],
  ["SCOTSTOWN", "QC"],
  ["SCOTSVILLE", "NS"],
  ["SCOTT SIDING", "NB"],
  ["SCOTT", "QC"],
  ["SCOTT", "SK"],
  ["SCOUDOUC ROAD", "NB"],
  ["SCOUDOUC", "NB"],
  ["SCOUT LAKE", "SK"],
  ["SEA SIDE", "NB"],
  ["SEABRIGHT", "NS"],
  ["SEAFORTH", "ON"],
  ["SEAGRAVE", "ON"],
  ["SEAL COVE FB", "NL"],
  ["SEAL COVE WB", "NL"],
  ["SEARCHMONT", "ON"],
  ["SEARSVILLE", "NB"],
  ["SEBA BEACH", "AB"],
  ["SEBRIGHT", "ON"],
  ["SEBRINGVILLE", "ON"],
  ["SECHELT", "BC"],
  ["SECOND FALLS", "NB"],
  ["SECOND NORTH RIVER", "NB"],
  ["SEDALIA", "AB"],
  ["SEDDONS CORNER", "MB"],
  ["SEDGEWICK", "AB"],
  ["SEDLEY", "SK"],
  ["SEELEYS BAY", "ON"],
  ["SEELEYS COVE", "NB"],
  ["SELBY", "ON"],
  ["SELDOM", "NL"],
  ["SELKIRK", "MB"],
  ["SELKIRK", "ON"],
  ["SEMANS", "SK"],
  ["SENLAC", "SK"],
  ["SENNETERRE", "QC"],
  ["SENNEVILLE", "QC"],
  ["SEPT-ILES", "QC"],
  ["SERPENT RIVER", "ON"],
  ["SESEKINIKA", "ON"],
  ["SETON PORTAGE", "BC"],
  ["SEVEN PERSONS", "AB"],
  ["SEVEN SISTERS FALLS", "MB"],
  ["SEVERN BRIDGE", "ON"],
  ["SEVOGLE", "NB"],
  ["SEXSMITH", "AB"],
  ["SHACKLETON", "SK"],
  ["SHAD BAY", "NS"],
  ["SHAG HARBOUR", "NS"],
  ["SHAKESPEARE", "ON"],
  ["SHALALTH", "BC"],
  ["SHALLOW LAKE", "ON"],
  ["SHALLOWAY COVE", "NL"],
  ["SHAMATTAWA", "MB"],
  ["SHAMROCK", "SK"],
  ["SHANKLIN", "NB"],
  ["SHANNON", "NB"],
  ["SHANNON", "QC"],
  ["SHANNONVILLE", "ON"],
  ["SHANTY BAY", "ON"],
  ["SHARBOT LAKE", "ON"],
  ["SHARON", "ON"],
  ["SHAUGHNESSY", "AB"],
  ["SHAUNAVON", "SK"],
  ["SHAWINIGAN", "QC"],
  ["SHAWINIGAN-SUD", "QC"],
  ["SHAWNIGAN LAKE", "BC"],
  ["SHAWVILLE", "QC"],
  ["SHEA HEIGHTS", "NL"],
  ["SHEARSTOWN", "NL"],
  ["SHEARWATER", "NS"],
  ["SHEBANDOWAN", "ON"],
  ["SHEDDEN", "ON"],
  ["SHEDIAC BRIDGE", "NB"],
  ["SHEDIAC BRIDGE-SHEDIAC RIVER", "NB"],
  ["SHEDIAC CAPE", "NB"],
  ["SHEDIAC RIVER", "NB"],
  ["SHEDIAC", "NB"],
  ["SHEENBORO", "QC"],
  ["SHEET HARBOUR", "NS"],
  ["SHEFFIELD", "NB"],
  ["SHEFFIELD", "ON"],
  ["SHEFFORD", "QC"],
  ["SHEGUIANDAH", "ON"],
  ["SHEHO", "SK"],
  ["SHELBURNE", "NS"],
  ["SHELBURNE", "ON"],
  ["SHELDRAKE", "QC"],
  ["SHELL LAKE", "SK"],
  ["SHELLBROOK", "SK"],
  ["SHELLMOUTH", "MB"],
  ["SHEMOGUE", "NB"],
  ["SHENACADIE", "NS"],
  ["SHENSTONE", "NB"],
  ["SHEPODY ALBERT CO", "NB"],
  ["SHEPODY KINGS CO", "NB"],
  ["SHERBROOKE", "NS"],
  ["SHERBROOKE", "QC"],
  ["SHERKSTON", "ON"],
  ["SHERRIDON", "MB"],
  ["SHERRINGTON", "QC"],
  ["SHERWOOD PARK", "AB"],
  ["SHESHEGWANING", "ON"],
  ["SHIGAWAKE", "QC"],
  ["SHILO", "MB"],
  ["SHINING TREE", "ON"],
  ["SHIP HARBOUR PB", "NL"],
  ["SHIPMAN", "SK"],
  ["SHIPPAGAN", "NB"],
  ["SHIPSHAW", "QC"],
  ["SHIRLEY", "BC"],
  ["SHOAL LAKE", "MB"],
  ["SHOE COVE", "NL"],
  ["SHORTDALE", "MB"],
  ["SHORTTS LAKE", "NS"],
  ["SHUBENACADIE EAST", "NS"],
  ["SHUBENACADIE", "NS"],
  ["SIBBALD", "AB"],
  ["SICAMOUS", "BC"],
  ["SIDNEY", "BC"],
  ["SIDNEY", "MB"],
  ["SIEGAS", "NB"],
  ["SIFTON", "MB"],
  ["SIKSIKA", "AB"],
  ["SILLIKERS", "NB"],
  ["SILTON", "SK"],
  ["SILVER RIDGE", "MB"],
  ["SILVER VALLEY", "AB"],
  ["SILVER WATER", "ON"],
  ["SILVER", "MB"],
  ["SILVERTON", "BC"],
  ["SIMCOE", "ON"],
  ["SIMMIE", "SK"],
  ["SIMONDS", "NB"],
  ["SIMOOM SOUND", "BC"],
  ["SIMPSON", "SK"],
  ["SINCLAIR MILLS", "BC"],
  ["SINCLAIR", "MB"],
  ["SINGHAMPTON", "ON"],
  ["SINTALUTA", "SK"],
  ["SIOUX LOOKOUT", "ON"],
  ["SIOUX NARROWS", "ON"],
  ["SIRDAR", "BC"],
  ["SISSON BROOK", "NB"],
  ["SISSON RIDGE", "NB"],
  ["SIX ROADS", "NB"],
  ["SKEAD", "ON"],
  ["SKIFF LAKE", "NB"],
  ["SKIFF", "AB"],
  ["SKOOKUMCHUCK", "BC"],
  ["SKOWNAN", "MB"],
  ["SLATE FALLS", "ON"],
  ["SLAVE LAKE", "AB"],
  ["SLEEMAN", "ON"],
  ["SLEMON PARK", "PE"],
  ["SLOCAN PARK", "BC"],
  ["SLOCAN", "BC"],
  ["SLOPE", "NB"],
  ["SMEATON", "SK"],
  ["SMILEY", "SK"],
  ["SMITH CROSSING", "NB"],
  ["SMITH", "AB"],
  ["SMITH'S CORNER", "NB"],
  ["SMITHERS", "BC"],
  ["SMITHFIELD", "NB"],
  ["SMITHS COVE", "NS"],
  ["SMITHS CREEK", "NB"],
  ["SMITHS FALLS", "ON"],
  ["SMITHSVILLE", "NS"],
  ["SMITHTOWN", "NB"],
  ["SMITHVILLE", "ON"],
  ["SMOKY LAKE", "AB"],
  ["SMOOTH ROCK FALLS", "ON"],
  ["SNIDER MOUNTAIN", "NB"],
  ["SNOOKS ARM", "NL"],
  ["SNOW LAKE", "MB"],
  ["SNOW ROAD STATION", "ON"],
  ["SNOWDEN", "SK"],
  ["SNOWFLAKE", "MB"],
  ["SOINTULA", "BC"],
  ["SOLSGIRTH", "MB"],
  ["SOMBRA", "ON"],
  ["SOMERSET", "MB"],
  ["SOMERVILLE", "NB"],
  ["SONNINGDALE", "SK"],
  ["SOOKE", "BC"],
  ["SOPS ARM", "NL"],
  ["SOREL-TRACY", "QC"],
  ["SORMANY", "NB"],
  ["SORRENTO", "BC"],
  ["SOURIS", "MB"],
  ["SOURIS", "PE"],
  ["SOUTH BAPTISTE", "AB"],
  ["SOUTH BAR", "NS"],
  ["SOUTH BAYMOUTH", "ON"],
  ["SOUTH BRANCH KENT CO", "NB"],
  ["SOUTH BRANCH KINGS CO", "NB"],
  ["SOUTH BRANCH", "NL"],
  ["SOUTH BROOK GB", "NL"],
  ["SOUTH BROOKFIELD", "NS"],
  ["SOUTH CANAAN", "NB"],
  ["SOUTH DILDO", "NL"],
  ["SOUTH EAST BIGHT", "NL"],
  ["SOUTH ESK", "NB"],
  ["SOUTH GILLIES", "ON"],
  ["SOUTH HAZELTON", "BC"],
  ["SOUTH HEAD", "NS"],
  ["SOUTH INDIAN LAKE", "MB"],
  ["SOUTH JUNCTION", "MB"],
  ["SOUTH LANCASTER", "ON"],
  ["SOUTH MAITLAND", "NS"],
  ["SOUTH MOUNTAIN", "ON"],
  ["SOUTH NELSON", "NB"],
  ["SOUTH OHIO", "NS"],
  ["SOUTH OROMOCTO LAKE", "NB"],
  ["SOUTH PORCUPINE", "ON"],
  ["SOUTH RIVER", "NL"],
  ["SOUTH RIVER", "ON"],
  ["SOUTH SLOCAN", "BC"],
  ["SOUTH TETAGOUCHE", "NB"],
  ["SOUTH WEST MARGAREE", "NS"],
  ["SOUTH WOODSLEE", "ON"],
  ["SOUTHAMPTON", "NB"],
  ["SOUTHAMPTON", "NS"],
  ["SOUTHAMPTON", "ON"],
  ["SOUTHBANK", "BC"],
  ["SOUTHEND", "SK"],
  ["SOUTHERN BAY", "NL"],
  ["SOUTHERN HARBOUR PB", "NL"],
  ["SOUTHEY", "SK"],
  ["SOUTHFIELD", "NB"],
  ["SOUTHPORT", "MB"],
  ["SOUTHSIDE BOULARDERIE", "NS"],
  ["SOUTHWOLD", "ON"],
  ["SOVEREIGN", "SK"],
  ["SPALDING", "SK"],
  ["SPANIARDS BAY", "NL"],
  ["SPANISH", "ON"],
  ["SPARTA", "ON"],
  ["SPARWOOD", "BC"],
  ["SPECTACLE LAKES", "NS"],
  ["SPEDDEN", "AB"],
  ["SPEERS", "SK"],
  ["SPEERVILLE", "NB"],
  ["SPENCERVILLE", "ON"],
  ["SPENCES BRIDGE", "BC"],
  ["SPERLING", "MB"],
  ["SPILLIMACHEEN", "BC"],
  ["SPIRIT RIVER", "AB"],
  ["SPIRITWOOD", "SK"],
  ["SPLIT LAKE", "MB"],
  ["SPRAGGE", "ON"],
  ["SPRAGUE", "MB"],
  ["SPRING BAY", "ON"],
  ["SPRING COULEE", "AB"],
  ["SPRING LAKE", "AB"],
  ["SPRING VALLEY", "SK"],
  ["SPRINGBROOK", "AB"],
  ["SPRINGBROOK", "ON"],
  ["SPRINGDALE", "NB"],
  ["SPRINGDALE", "NL"],
  ["SPRINGFIELD KINGS CO", "NB"],
  ["SPRINGFIELD YORK CO", "NB"],
  ["SPRINGFIELD", "MB"],
  ["SPRINGFIELD", "NS"],
  ["SPRINGFIELD", "ON"],
  ["SPRINGFORD", "ON"],
  ["SPRINGHILL", "NS"],
  ["SPRINGSIDE", "SK"],
  ["SPRINGSTEIN", "MB"],
  ["SPRINGWATER", "SK"],
  ["SPRUCE GROVE", "AB"],
  ["SPRUCE HOME", "SK"],
  ["SPRUCE LAKE", "SK"],
  ["SPRUCE VIEW", "AB"],
  ["SPRUCEDALE", "ON"],
  ["SPUTINOW", "AB"],
  ["SPY HILL", "SK"],
  ["SQUAMISH", "BC"],
  ["SQUATEC", "QC"],
  ["SQUAW CAP", "NB"],
  ["SQUIRREL COVE", "BC"],
  ["ST ADOLPHE", "MB"],
  ["ST AGATHA", "ON"],
  ["ST ALBANS", "NL"],
  ["ST ALBERT", "ON"],
  ["ST ALMO", "NB"],
  ["ST ALPHONSE", "MB"],
  ["ST AMBROISE", "MB"],
  ["ST ANDREWS CHANNEL", "NS"],
  ["ST ANDREWS WEST", "ON"],
  ["ST ANDREWS", "MB"],
  ["ST ANDREWS", "NB"],
  ["ST ANDREWS", "NL"],
  ["ST ANDREWS", "NS"],
  ["ST ANNS", "ON"],
  ["ST ANTHONY EAST", "NL"],
  ["ST BENEDICT", "SK"],
  ["ST BERNARDIN", "ON"],
  ["ST BERNARDS-JACQUES FONTAINE", "NL"],
  ["ST BRENDANS", "NL"],
  ["ST BRIDES", "AB"],
  ["ST BRIDES", "NL"],
  ["ST BRIEUX", "SK"],
  ["ST CATHARINES", "ON"],
  ["ST CHADS", "NL"],
  ["ST CHARLES", "ON"],
  ["ST CLAUDE", "MB"],
  ["ST CLEMENTS", "MB"],
  ["ST CLEMENTS", "ON"],
  ["ST COLUMBA", "NS"],
  ["ST CROIX", "NB"],
  ["ST DAVID RIDGE", "NB"],
  ["ST DAVIDS", "NL"],
  ["ST DAVIDS", "ON"],
  ["ST DENIS", "SK"],
  ["ST EUGENE", "ON"],
  ["ST EUSTACHE", "MB"],
  ["ST FINTANS", "NL"],
  ["ST FRANCOIS XAVIER", "MB"],
  ["ST GEORGE BRANT", "ON"],
  ["ST GEORGE", "NB"],
  ["ST GEORGES", "NL"],
  ["ST GERMAIN SOUTH", "MB"],
  ["ST GREGOR", "SK"],
  ["ST ISIDORE DE BELLEVUE", "SK"],
  ["ST ISIDORE", "AB"],
  ["ST ISIDORE", "ON"],
  ["ST JACOBS", "ON"],
  ["ST JEAN BAPTISTE", "MB"],
  ["ST JOACHIM", "ON"],
  ["ST JOSEPH", "MB"],
  ["ST JOSEPHS SAL", "NL"],
  ["ST JUDES", "NL"],
  ["ST JULIENS", "NL"],
  ["ST LAURENT", "MB"],
  ["ST LAWRENCE", "NL"],
  ["ST LAZARE", "MB"],
  ["ST LEON", "MB"],
  ["ST LEWIS", "NL"],
  ["ST LINA", "AB"],
  ["ST LOUIS", "SK"],
  ["ST LUNAIRE-GRIQUET", "NL"],
  ["ST MALO", "MB"],
  ["ST MARGARET VILLAGE", "NS"],
  ["ST MARGARETS", "NB"],
  ["ST MARKS", "MB"],
  ["ST MARTIN", "MB"],
  ["ST MARTINS NORTH", "NB"],
  ["ST MARTINS", "NB"],
  ["ST MARYS", "NL"],
  ["ST MARYS", "ON"],
  ["ST MICHAEL", "AB"],
  ["ST PAUL", "AB"],
  ["ST PAULS STATION", "ON"],
  ["ST PAULS", "NL"],
  ["ST PETERS", "NS"],
  ["ST PIERRE JOLYS", "MB"],
  ["ST SHOTTS", "NL"],
  ["ST STEPHEN", "NB"],
  ["ST THERESA POINT", "MB"],
  ["ST THOMAS", "NB"],
  ["ST THOMAS", "ON"],
  ["ST VICTOR", "SK"],
  ["ST VINCENT", "AB"],
  ["ST VINCENTS", "NL"],
  ["ST WALBURG", "SK"],
  ["ST WILLIAMS", "ON"],
  ["ST-ANTOINE NORD", "NB"],
  ["ST-ATHANASE", "QC"],
  ["ST-BARNABE-NORD", "QC"],
  ["ST-FRANCOIS-DE-LA-RIVIERE-DU-S", "QC"],
  ["ST-FRANCOIS-XAVIER-DE-BROMPTON", "QC"],
  ["ST-GABRIEL-DE-KAMOURASKA", "QC"],
  ["ST-HILAIRE", "NB"],
  ["ST-JEAN-BAPTISTE", "NB"],
  ["ST-JOACHIM-DE-MONTMORENCY", "QC"],
  ["ST-JOSEPH-DE-LA-POINTE-DE-LEVY", "QC"],
  ["ST-JOSEPH-DE-MADAWASKA", "NB"],
  ["ST-JOSEPH-DU-MOINE", "NS"],
  ["ST-LOUIS", "PE"],
  ["ST-MARCEL-DE-L'ISLET", "QC"],
  ["ST-NAZAIRE-DU-LAC-ST-JEAN", "QC"],
  ["ST-OCTAVE", "QC"],
  ["ST-ONESIME", "QC"],
  ["ST-PETERS BAY", "PE"],
  ["ST-PIERRE-DE-LA-RIVIERE-DU-SUD", "QC"],
  ["ST-VALERIEN", "QC"],
  ["ST. ALBERT", "AB"],
  ["ST. ANTHONY", "NL"],
  ["ST. GEORGES", "MB"],
  ["ST. JOHN'S", "NL"],
  ["STAFFA", "ON"],
  ["STAG HARBOUR", "NL"],
  ["STALWART", "SK"],
  ["STANBRIDGE EAST", "QC"],
  ["STANBRIDGE STATION", "QC"],
  ["STAND OFF", "AB"],
  ["STANDARD", "AB"],
  ["STANDON", "QC"],
  ["STANLEY MISSION", "SK"],
  ["STANLEY", "NB"],
  ["STANSTEAD", "QC"],
  ["STAPLES SETTLEMENT", "NB"],
  ["STAPLES", "ON"],
  ["STAR CITY", "SK"],
  ["STAR", "AB"],
  ["STARBUCK", "MB"],
  ["STAUFFER", "AB"],
  ["STAVELY", "AB"],
  ["STAYNER", "ON"],
  ["STE AGATHE", "MB"],
  ["STE ANNE DE PRESCOTT", "ON"],
  ["STE ANNE", "MB"],
  ["STE CROIX", "NS"],
  ["STE ROSE DU LAC", "MB"],
  ["STE-ANNE-DU-RUISSEAU", "NS"],
  ["STE-CATHERINE-DE-LA-J-CARTIER", "QC"],
  ["STE-IRENE-DE-MATAPEDIA", "QC"],
  ["STE-JEANNE-D'ARC-DE-MATANE", "QC"],
  ["STE-MARGUERITE-DE-DORCHESTER", "QC"],
  ["STE-MARGUERITE-DU-LAC-MASSON", "QC"],
  ["STEAD", "MB"],
  ["STEELMAN", "SK"],
  ["STEEP ROCK", "MB"],
  ["STEEVES MOUNTAIN", "NB"],
  ["STEEVES SETTLEMENT", "NB"],
  ["STEINBACH", "MB"],
  ["STELLA", "ON"],
  ["STELLARTON", "NS"],
  ["STENEN", "SK"],
  ["STEPHENFIELD", "MB"],
  ["STEPHENVILLE CROSSING", "NL"],
  ["STEPHENVILLE", "NL"],
  ["STETTLER", "AB"],
  ["STEVENSON ISLAND", "MB"],
  ["STEVENSVILLE", "ON"],
  ["STEWART VALLEY", "SK"],
  ["STEWART", "BC"],
  ["STEWIACKE", "NS"],
  ["STICKNEY", "NB"],
  ["STILESVILLE", "NB"],
  ["STILLWATER LAKE", "NS"],
  ["STIRLING", "AB"],
  ["STIRLING", "NS"],
  ["STIRLING", "ON"],
  ["STITTSVILLE", "ON"],
  ["STOCKHOLM", "SK"],
  ["STOCKTON", "MB"],
  ["STOKE", "QC"],
  ["STOKES BAY", "ON"],
  ["STONECLIFFE", "ON"],
  ["STONEHAM-ET-TEWKESBURY", "QC"],
  ["STONEHAVEN", "NB"],
  ["STONEVILLE", "NL"],
  ["STONEWALL", "MB"],
  ["STONEY CREEK", "NB"],
  ["STONEY CREEK", "ON"],
  ["STONEY ISLAND", "NS"],
  ["STONY BEACH", "SK"],
  ["STONY MOUNTAIN", "MB"],
  ["STONY PLAIN", "AB"],
  ["STONY RAPIDS", "SK"],
  ["STOREYTOWN", "NB"],
  ["STORNOWAY", "QC"],
  ["STORNOWAY", "SK"],
  ["STORTHOAKS", "SK"],
  ["STOUFFVILLE", "ON"],
  ["STOUGHTON", "SK"],
  ["STRAFFORDVILLE", "ON"],
  ["STRANRAER", "SK"],
  ["STRASBOURG", "SK"],
  ["STRATFORD", "ON"],
  ["STRATFORD", "PE"],
  ["STRATFORD", "QC"],
  ["STRATHADAM", "NB"],
  ["STRATHCLAIR", "MB"],
  ["STRATHMORE", "AB"],
  ["STRATHROY", "ON"],
  ["STRATTON", "ON"],
  ["STREAMSTOWN", "AB"],
  ["STRICKLAND", "ON"],
  ["STROME", "AB"],
  ["STRONGFIELD", "SK"],
  ["STUART ISLAND", "BC"],
  ["STUART TOWN", "NB"],
  ["STUARTBURN", "MB"],
  ["STUKELY-SUD", "QC"],
  ["STUMP LAKE", "SK"],
  ["STURGEON COUNTY", "AB"],
  ["STURGEON FALLS", "ON"],
  ["STURGEON LANDING", "SK"],
  ["STURGIS", "SK"],
  ["STYMIEST", "NB"],
  ["SUCCESS", "SK"],
  ["SUDBURY", "ON"],
  ["SUGAR CAMP", "NS"],
  ["SULTAN", "ON"],
  ["SUMMER BEAVER", "ON"],
  ["SUMMERBERRY", "SK"],
  ["SUMMERFIELD CARLETON CO", "NB"],
  ["SUMMERFIELD KINGS CO", "NB"],
  ["SUMMERFORD", "NL"],
  ["SUMMERLAND", "BC"],
  ["SUMMERSIDE", "PE"],
  ["SUMMERSTOWN", "ON"],
  ["SUMMERVILLE", "NB"],
  ["SUMMERVILLE", "NL"],
  ["SUMMERVILLE", "NS"],
  ["SUMMIT LAKE", "BC"],
  ["SUN PEAKS", "BC"],
  ["SUNDERLAND", "ON"],
  ["SUNDOWN", "MB"],
  ["SUNDRE", "AB"],
  ["SUNDRIDGE", "ON"],
  ["SUNNY CORNER", "NB"],
  ["SUNNYBRAE", "NS"],
  ["SUNNYBROOK", "AB"],
  ["SUNNYNOOK", "AB"],
  ["SUNNYSIDE BEACH", "NB"],
  ["SUNNYSIDE", "NL"],
  ["SUNSET BEACH", "AB"],
  ["SUNSET HOUSE", "AB"],
  ["SUNSET PRAIRIE", "BC"],
  ["SURGE NARROWS", "BC"],
  ["SURREY", "BC"],
  ["SUSSEX CORNER", "NB"],
  ["SUSSEX EAST", "NB"],
  ["SUSSEX SOUTH", "NB"],
  ["SUSSEX", "NB"],
  ["SUTTON WEST", "ON"],
  ["SUTTON", "QC"],
  ["SWALWELL", "AB"],
  ["SWAN CREEK", "NB"],
  ["SWAN HILLS", "AB"],
  ["SWAN LAKE", "MB"],
  ["SWAN RIVER", "MB"],
  ["SWANSEA POINT", "BC"],
  ["SWASTIKA", "ON"],
  ["SWEET BAY", "NL"],
  ["SWIFT CURRENT", "NL"],
  ["SWIFT CURRENT", "SK"],
  ["SYDENHAM", "ON"],
  ["SYDNEY FORKS", "NS"],
  ["SYDNEY MINES", "NS"],
  ["SYDNEY RIVER", "NS"],
  ["SYDNEY", "NS"],
  ["SYLVAN LAKE", "AB"],
  ["SYLVANIA", "SK"],
  ["SYPHER COVE", "NB"],
  ["TA TA CREEK", "BC"],
  ["TABER", "AB"],
  ["TABUSINTAC", "NB"],
  ["TADOULE LAKE", "MB"],
  ["TADOUSSAC", "QC"],
  ["TAGISH", "YT"],
  ["TAHSIS", "BC"],
  ["TAILLON", "QC"],
  ["TAKLA LANDING", "BC"],
  ["TALBOTVILLE ROYAL", "ON"],
  ["TALOYOAK", "NU"],
  ["TAMWORTH", "ON"],
  ["TANCOOK ISLAND", "NS"],
  ["TANGENT", "AB"],
  ["TANGIER", "NS"],
  ["TANTALLON", "NS"],
  ["TANTALLON", "SK"],
  ["TAPPEN", "BC"],
  ["TARA", "ON"],
  ["TARGETTVILLE", "NB"],
  ["TARZWELL", "ON"],
  ["TASCHEREAU", "QC"],
  ["TASIUJAQ", "QC"],
  ["TATAMAGOUCHE", "NS"],
  ["TATLA LAKE", "BC"],
  ["TATLAYOKO LAKE", "BC"],
  ["TAVISTOCK", "ON"],
  ["TAWATINAW", "AB"],
  ["TAXIS RIVER", "NB"],
  ["TAY CREEK", "NB"],
  ["TAY FALLS", "NB"],
  ["TAYLOR VILLAGE", "NB"],
  ["TAYLOR", "BC"],
  ["TAYMOUTH", "NB"],
  ["TECUMSEH", "ON"],
  ["TEEDS MILLS", "NB"],
  ["TEES", "AB"],
  ["TEESWATER", "ON"],
  ["TEETERVILLE", "ON"],
  ["TEHKUMMAH", "ON"],
  ["TELEGRAPH COVE", "BC"],
  ["TELEGRAPH CREEK", "BC"],
  ["TELKWA", "BC"],
  ["TEMAGAMI", "ON"],
  ["TEMISCAMING", "QC"],
  ["TEMPERANCE VALE", "NB"],
  ["TEMPLE", "NB"],
  ["TEMPLEMAN", "NL"],
  ["TERENCE BAY", "NS"],
  ["TERRA COTTA", "ON"],
  ["TERRACE BAY", "ON"],
  ["TERRACE", "BC"],
  ["TERRASSE-VAUDREUIL", "QC"],
  ["TERREBONNE", "QC"],
  ["TERRENCEVILLE", "NL"],
  ["TESLIN", "YT"],
  ["TESSIER", "SK"],
  ["TETAGOUCHE FALLS", "NB"],
  ["TETE-A-LA-BALEINE", "QC"],
  ["TEULON", "MB"],
  ["THAMESFORD", "ON"],
  ["THAMESVILLE", "ON"],
  ["THE GLADES", "NB"],
  ["THE PAS", "MB"],
  ["THEDFORD", "ON"],
  ["THEODORE", "SK"],
  ["THESSALON", "ON"],
  ["THETFORD MINES", "QC"],
  ["THETIS ISLAND", "BC"],
  ["THICKET PORTAGE", "MB"],
  ["THOMASBURG", "ON"],
  ["THOMASVILLE", "NS"],
  ["THOMPSON", "MB"],
  ["THORBURN", "NS"],
  ["THORHILD", "AB"],
  ["THORNBURY", "ON"],
  ["THORNDALE", "ON"],
  ["THORNE", "ON"],
  ["THORNHILL", "BC"],
  ["THORNHILL", "MB"],
  ["THORNHILL", "ON"],
  ["THORNLOE", "ON"],
  ["THORNTON", "ON"],
  ["THOROLD", "ON"],
  ["THORSBY", "AB"],
  ["THREE BROOKS", "NB"],
  ["THREE HILLS", "AB"],
  ["THREE TREE CREEK", "NB"],
  ["THUNDER BAY", "ON"],
  ["THURSO", "QC"],
  ["TICHBORNE", "ON"],
  ["TICKLE COVE", "NL"],
  ["TIDE HEAD", "NB"],
  ["TIGER LILY", "AB"],
  ["TIGNISH", "PE"],
  ["TILBURY", "ON"],
  ["TILDEN LAKE", "ON"],
  ["TILLEY ROAD", "NB"],
  ["TILLEY", "AB"],
  ["TILLEY", "NB"],
  ["TILLSONBURG", "ON"],
  ["TILSTON", "MB"],
  ["TILTING", "NL"],
  ["TIMBER BAY", "SK"],
  ["TIMBER RIVER", "NB"],
  ["TIMBERLEA", "NS"],
  ["TIMMINS", "ON"],
  ["TINGWICK", "QC"],
  ["TINKER", "NB"],
  ["TISDALE", "SK"],
  ["TITUSVILLE", "NB"],
  ["TIVERTON", "NS"],
  ["TIVERTON", "ON"],
  ["TIZZARDS HARBOUR", "NL"],
  ["TLELL", "BC"],
  ["TOAD RIVER", "BC"],
  ["TOBERMORY", "ON"],
  ["TOBIQUE FIRST NATION", "NB"],
  ["TOBIQUE NARROWS", "NB"],
  ["TOFIELD", "AB"],
  ["TOFINO", "BC"],
  ["TOGO", "SK"],
  ["TOLEDO", "ON"],
  ["TOLSTOI", "MB"],
  ["TOMAHAWK", "AB"],
  ["TOMPKINS", "SK"],
  ["TOMSLAKE", "BC"],
  ["TOPLEY", "BC"],
  ["TORBAY", "NL"],
  ["TORONTO", "ON"],
  ["TORQUAY", "SK"],
  ["TORRANCE", "ON"],
  ["TORRINGTON", "AB"],
  ["TORS COVE", "NL"],
  ["TORY HILL", "ON"],
  ["TOTTENHAM", "ON"],
  ["TOUROND", "MB"],
  ["TOURVILLE", "QC"],
  ["TOUTES AIDES", "MB"],
  ["TOWER HILL", "NB"],
  ["TOWER ROAD", "NS"],
  ["TOWNSEND", "ON"],
  ["TRACADIE BEACH", "NB"],
  ["TRACADIE-SHEILA", "NB"],
  ["TRACEY MILLS", "NB"],
  ["TRACY", "NB"],
  ["TRACYVILLE", "NB"],
  ["TRAIL", "BC"],
  ["TRAMPING LAKE", "SK"],
  ["TRAVERSE BAY", "MB"],
  ["TRAYTOWN", "NL"],
  ["TRECESSON", "QC"],
  ["TREHERNE", "MB"],
  ["TREMBLAY", "NB"],
  ["TRENT RIVER", "ON"],
  ["TRENTON", "NS"],
  ["TRENTON", "ON"],
  ["TREPASSEY", "NL"],
  ["TRES-SAINT-REDEMPTEUR", "QC"],
  ["TRIBUNE", "SK"],
  ["TRING-JONCTION", "QC"],
  ["TRINITY BB", "NL"],
  ["TRINITY TB", "NL"],
  ["TRITON", "NL"],
  ["TROCHU", "AB"],
  ["TROIS-PISTOLES", "QC"],
  ["TROIS-RIVES", "QC"],
  ["TROIS-RIVIERES", "QC"],
  ["TROIS-RUISSEAUX", "NB"],
  ["TROSSACHS", "SK"],
  ["TROUT BROOK", "NB"],
  ["TROUT CREEK", "ON"],
  ["TROUT LAKE", "AB"],
  ["TROUT LAKE", "NT"],
  ["TROUT RIVER", "NL"],
  ["TROY", "NS"],
  ["TROY", "ON"],
  ["TRUAX", "SK"],
  ["TRUDEL", "NB"],
  ["TRURO HEIGHTS", "NS"],
  ["TRURO", "NS"],
  ["TSAWWASSEN", "BC"],
  ["TSAY KEH DENE", "BC"],
  ["TSIIGEHTCHIC", "NT"],
  ["TSUU T'INA", "AB"],
  ["TUFFNELL", "SK"],
  ["TUGASKE", "SK"],
  ["TUKTOYAKTUK", "NT"],
  ["TULAMEEN", "BC"],
  ["TULITA", "NT"],
  ["TULLIBY LAKE", "AB"],
  ["TUMBLER RIDGE", "BC"],
  ["TUNIS", "ON"],
  ["TUPPERVILLE", "ON"],
  ["TURIN", "AB"],
  ["TURKEY POINT", "ON"],
  ["TURKS COVE", "NL"],
  ["TURNER VALLEY", "AB"],
  ["TURNOR LAKE", "SK"],
  ["TURTLE CREEK", "NB"],
  ["TURTLEFORD", "SK"],
  ["TUSKET", "NS"],
  ["TUXFORD", "SK"],
  ["TWAY", "SK"],
  ["TWEED", "ON"],
  ["TWILLINGATE", "NL"],
  ["TWIN BUTTE", "AB"],
  ["TWO BROOKS", "NB"],
  ["TWO HILLS", "AB"],
  ["TYNDALL", "MB"],
  ["TYNE VALLEY", "PE"],
  ["TYNEMOUTH CREEK", "NB"],
  ["TYNER", "SK"],
  ["TYVAN", "SK"],
  ["UCLUELET", "BC"],
  ["UDORA", "ON"],
  ["ULUKHAKTOK", "NT"],
  ["ULVERTON", "QC"],
  ["UMIUJAQ", "QC"],
  ["UNION BAY", "BC"],
  ["UNION CORNER", "NB"],
  ["UNION", "ON"],
  ["UNIONVILLE", "ON"],
  ["UNITY", "SK"],
  ["UPHAM", "NB"],
  ["UPPER BLACKVILLE", "NB"],
  ["UPPER BRANCH", "NS"],
  ["UPPER BRIGHTON", "NB"],
  ["UPPER BROOKSIDE", "NS"],
  ["UPPER CANADA VILLAGE", "ON"],
  ["UPPER CAPE", "NB"],
  ["UPPER CAVERHILL", "NB"],
  ["UPPER CHELSEA", "NS"],
  ["UPPER COVERDALE", "NB"],
  ["UPPER DERBY", "NB"],
  ["UPPER DORCHESTER", "NB"],
  ["UPPER FRASER", "BC"],
  ["UPPER GAGETOWN", "NB"],
  ["UPPER GOLDEN GROVE", "NB"],
  ["UPPER GRAND MIRA", "NS"],
  ["UPPER HAINESVILLE", "NB"],
  ["UPPER HAMMONDS PLAINS", "NS"],
  ["UPPER HAMPSTEAD", "NB"],
  ["UPPER ISLAND COVE", "NL"],
  ["UPPER KENNETCOOK", "NS"],
  ["UPPER KENT", "NB"],
  ["UPPER KESWICK", "NB"],
  ["UPPER KINGSCLEAR", "NB"],
  ["UPPER KINTORE", "NB"],
  ["UPPER KNOXFORD", "NB"],
  ["UPPER LAHAVE", "NS"],
  ["UPPER LEITCHES CREEK", "NS"],
  ["UPPER LETANG", "NB"],
  ["UPPER LOCH LOMOND", "NB"],
  ["UPPER MILLS", "NB"],
  ["UPPER MUSQUODOBOIT", "NS"],
  ["UPPER NINE MILE RIVER", "NS"],
  ["UPPER NORTH RIVER", "NS"],
  ["UPPER NORTH SYDNEY", "NS"],
  ["UPPER NORTHFIELD", "NS"],
  ["UPPER ONSLOW", "NS"],
  ["UPPER PORT LA TOUR", "NS"],
  ["UPPER QUEENSBURY", "NB"],
  ["UPPER RAWDON", "NS"],
  ["UPPER REXTON", "NB"],
  ["UPPER ROCKPORT", "NB"],
  ["UPPER SACKVILLE", "NB"],
  ["UPPER SACKVILLE", "NS"],
  ["UPPER SALMON CREEK", "NB"],
  ["UPPER STEWIACKE", "NS"],
  ["UPPER TANTALLON", "NS"],
  ["UPPER TRACY", "NB"],
  ["UPPER WASHABUCK", "NS"],
  ["UPPER WOODSTOCK", "NB"],
  ["UPPERTON", "NB"],
  ["UPSALA", "ON"],
  ["UPSALQUITCH", "NB"],
  ["UPTON", "QC"],
  ["URANIUM CITY", "SK"],
  ["URBANIA", "NS"],
  ["UTOPIA", "NB"],
  ["UTOPIA", "ON"],
  ["UTTERSON", "ON"],
  ["UXBRIDGE", "ON"],
  ["VAL CARON", "ON"],
  ["VAL COMEAU", "NB"],
  ["VAL COTE", "ON"],
  ["VAL GAGNE", "ON"],
  ["VAL MARIE", "SK"],
  ["VAL RITA", "ON"],
  ["VAL THERESE", "ON"],
  ["VAL-ALAIN", "QC"],
  ["VAL-BRILLANT", "QC"],
  ["VAL-D'AMOUR", "NB"],
  ["VAL-D'ESPOIR", "QC"],
  ["VAL-D'OR", "QC"],
  ["VAL-DAVID", "QC"],
  ["VAL-DES-BOIS", "QC"],
  ["VAL-DES-LACS", "QC"],
  ["VAL-DES-MONTS", "QC"],
  ["VAL-DOUCET", "NB"],
  ["VAL-JOLI", "QC"],
  ["VAL-MORIN", "QC"],
  ["VAL-PARADIS", "QC"],
  ["VAL-RACINE", "QC"],
  ["VAL-SAINT-GILLES", "QC"],
  ["VALCOURT", "QC"],
  ["VALEMOUNT", "BC"],
  ["VALHALLA CENTRE", "AB"],
  ["VALLEE-JONCTION", "QC"],
  ["VALLEY POND", "NL"],
  ["VALLEY RIVER", "MB"],
  ["VALLEY ROAD", "NB"],
  ["VALLEY", "NS"],
  ["VALLEYVIEW", "AB"],
  ["VAN ANDA", "BC"],
  ["VANCOUVER", "BC"],
  ["VANDERHOOF", "BC"],
  ["VANESSA", "ON"],
  ["VANGUARD", "SK"],
  ["VANIER", "ON"],
  ["VANKLEEK HILL", "ON"],
  ["VANSCOY", "SK"],
  ["VARENNES", "QC"],
  ["VARNA", "ON"],
  ["VARS", "ON"],
  ["VASSAR", "MB"],
  ["VAUDREUIL-DORION", "QC"],
  ["VAUDREUIL-SUR-LE-LAC", "QC"],
  ["VAUGHAN", "ON"],
  ["VAUXHALL", "AB"],
  ["VAVENBY", "BC"],
  ["VAWN", "SK"],
  ["VEGA", "AB"],
  ["VEGREVILLE", "AB"],
  ["VENISE-EN-QUEBEC", "QC"],
  ["VENOSTA", "QC"],
  ["VERCHERES", "QC"],
  ["VERDUN", "QC"],
  ["VEREGIN", "SK"],
  ["VERMETTE", "MB"],
  ["VERMILION BAY", "ON"],
  ["VERMILION", "AB"],
  ["VERNER", "ON"],
  ["VERNON BRIDGE", "PE"],
  ["VERNON", "BC"],
  ["VERNON", "ON"],
  ["VERONA", "ON"],
  ["VERRET", "NB"],
  ["VERWOOD", "SK"],
  ["VESPRA", "NB"],
  ["VETERAN", "AB"],
  ["VIBANK", "SK"],
  ["VICEROY", "SK"],
  ["VICTOIRE", "SK"],
  ["VICTORIA BEACH", "MB"],
  ["VICTORIA CB", "NL"],
  ["VICTORIA CORNER", "NB"],
  ["VICTORIA COVE", "NL"],
  ["VICTORIA HARBOUR", "ON"],
  ["VICTORIA MINES", "NS"],
  ["VICTORIA", "BC"],
  ["VICTORIA", "PE"],
  ["VICTORIAVILLE", "QC"],
  ["VIDORA", "SK"],
  ["VIENNA", "ON"],
  ["VIKING", "AB"],
  ["VILLAGE BLANCHARD", "NB"],
  ["VILLAGE SAINT-PIERRE", "QC"],
  ["VILLAGE-DES-POIRIER", "NB"],
  ["VILLAGE-SAINT-LAURENT", "NB"],
  ["VILLE-MARIE", "QC"],
  ["VILLEBOIS", "QC"],
  ["VILLEROY", "QC"],
  ["VILNA", "AB"],
  ["VIMONT", "QC"],
  ["VIMY", "AB"],
  ["VINEGAR HILL", "NB"],
  ["VINELAND STATION", "ON"],
  ["VINELAND", "ON"],
  ["VIRDEN", "MB"],
  ["VIRGIL", "ON"],
  ["VIRGINIATOWN", "ON"],
  ["VISCOUNT", "SK"],
  ["VISTA", "MB"],
  ["VITA", "MB"],
  ["VITTORIA", "ON"],
  ["VOGAR", "MB"],
  ["VONDA", "SK"],
  ["VULCAN", "AB"],
  ["WAASIS", "NB"],
  ["WABAMUN", "AB"],
  ["WABASCA", "AB"],
  ["WABIGOON", "ON"],
  ["WABOWDEN", "MB"],
  ["WABUSH", "NL"],
  ["WADENA", "SK"],
  ["WAGMATCOOK", "NS"],
  ["WAHNAPITAE", "ON"],
  ["WAINFLEET", "ON"],
  ["WAINWRIGHT", "AB"],
  ["WAKAW", "SK"],
  ["WAKEFIELD", "NB"],
  ["WAKEFIELD", "QC"],
  ["WALDECK", "SK"],
  ["WALDERSEE", "MB"],
  ["WALDHEIM", "SK"],
  ["WALDHOF", "ON"],
  ["WALDRON", "SK"],
  ["WALFORD STATION", "ON"],
  ["WALHACHIN", "BC"],
  ["WALKER SETTLEMENT", "NB"],
  ["WALKERTON", "ON"],
  ["WALLACE", "NS"],
  ["WALLACEBURG", "ON"],
  ["WALLACETOWN", "ON"],
  ["WALLENSTEIN", "ON"],
  ["WALSH", "AB"],
  ["WALSINGHAM", "ON"],
  ["WALTERS FALLS", "ON"],
  ["WALTHAM", "QC"],
  ["WALTON", "NS"],
  ["WALTON", "ON"],
  ["WANDERING RIVER", "AB"],
  ["WANHAM", "AB"],
  ["WANIPIGOW", "MB"],
  ["WANLESS", "MB"],
  ["WAPELLA", "SK"],
  ["WAPSKE", "NB"],
  ["WARBURG", "AB"],
  ["WARD SETTLEMENT", "NB"],
  ["WARDEN", "QC"],
  ["WARDLOW", "AB"],
  ["WARDNER", "BC"],
  ["WARDS CREEK", "NB"],
  ["WARDSVILLE", "ON"],
  ["WARE", "BC"],
  ["WAREHAM-CENTREVILLE", "NL"],
  ["WARKWORTH", "ON"],
  ["WARMAN", "SK"],
  ["WARMINSTER", "ON"],
  ["WARNER", "AB"],
  ["WARREN", "MB"],
  ["WARREN", "ON"],
  ["WARSAW", "ON"],
  ["WARSPITE", "AB"],
  ["WARWICK SETTLEMENT", "NB"],
  ["WARWICK", "QC"],
  ["WASA", "BC"],
  ["WASAGA BEACH", "ON"],
  ["WASAGAMACK", "MB"],
  ["WASAGAMING", "MB"],
  ["WASECA", "SK"],
  ["WASHABUCK CENTRE", "NS"],
  ["WASHAGO", "ON"],
  ["WASKADA", "MB"],
  ["WASKAGANISH", "QC"],
  ["WASKATENAU", "AB"],
  ["WASKESIU LAKE", "SK"],
  ["WASWANIPI", "QC"],
  ["WATER VALLEY", "AB"],
  ["WATERBOROUGH", "NB"],
  ["WATERDOWN", "ON"],
  ["WATERFORD", "NB"],
  ["WATERFORD", "ON"],
  ["WATERHEN LAKE", "SK"],
  ["WATERHEN", "MB"],
  ["WATERLOO", "NS"],
  ["WATERLOO", "ON"],
  ["WATERLOO", "QC"],
  ["WATERSIDE", "NB"],
  ["WATERTON PARK", "AB"],
  ["WATERVILLE CARLETON CO", "NB"],
  ["WATERVILLE", "NS"],
  ["WATERVILLE", "QC"],
  ["WATERVILLE-SUNBURY", "NB"],
  ["WATFORD", "ON"],
  ["WATINO", "AB"],
  ["WATROUS", "SK"],
  ["WATSON LAKE", "YT"],
  ["WATSON", "SK"],
  ["WAUBAUSHENE", "ON"],
  ["WAUCHOPE", "SK"],
  ["WAVERLEY", "NS"],
  ["WAWA", "ON"],
  ["WAWANESA", "MB"],
  ["WAWEIG", "NB"],
  ["WAWOTA", "SK"],
  ["WAYERTON", "NB"],
  ["WAYWAYSEECAPPO", "MB"],
  ["WEAGAMOW LAKE", "ON"],
  ["WEAVER SIDING", "NB"],
  ["WEAVER", "NB"],
  ["WEBB", "SK"],
  ["WEBBWOOD", "ON"],
  ["WEBEQUIE", "ON"],
  ["WEDGEPORT", "NS"],
  ["WEEDON", "QC"],
  ["WEEKES", "SK"],
  ["WEIRDALE", "SK"],
  ["WEKWETI", "NT"],
  ["WELDON", "NB"],
  ["WELDON", "SK"],
  ["WELLAND", "ON"],
  ["WELLANDPORT", "ON"],
  ["WELLESLEY", "ON"],
  ["WELLING", "AB"],
  ["WELLINGTON STATION", "PE"],
  ["WELLINGTON", "NS"],
  ["WELLINGTON", "ON"],
  ["WELLS", "BC"],
  ["WELLWOOD", "MB"],
  ["WELSFORD", "NB"],
  ["WELSHPOOL", "NB"],
  ["WELWYN", "SK"],
  ["WEMBLEY", "AB"],
  ["WEMINDJI", "QC"],
  ["WEMOTACI", "QC"],
  ["WENDAKE", "QC"],
  ["WENDOVER", "ON"],
  ["WENTWORTH", "NS"],
  ["WENTWORTH", "QC"],
  ["WENTWORTH-NORD", "QC"],
  ["WENTZELLS LAKE", "NS"],
  ["WESLEYVILLE", "NL"],
  ["WEST ARICHAT", "NS"],
  ["WEST BAPTISTE", "AB"],
  ["WEST BAY CENTRE", "NL"],
  ["WEST BAY ROAD", "NS"],
  ["WEST BAY", "NS"],
  ["WEST BEND", "SK"],
  ["WEST BRANCH", "NB"],
  ["WEST BROME", "QC"],
  ["WEST CLIFFORD", "NS"],
  ["WEST DOVER", "NS"],
  ["WEST FLORENCEVILLE", "NB"],
  ["WEST GUILFORD", "ON"],
  ["WEST INDIAN ROAD", "NS"],
  ["WEST KELOWNA", "BC"],
  ["WEST LORNE", "ON"],
  ["WEST MONTROSE", "ON"],
  ["WEST NORTHFIELD", "NS"],
  ["WEST PENNANT", "NS"],
  ["WEST PORTERS LAKE", "NS"],
  ["WEST PUBNICO", "NS"],
  ["WEST QUACO", "NB"],
  ["WEST RIVER STATION", "NS"],
  ["WEST RIVER", "NB"],
  ["WEST ST ANDREWS", "NS"],
  ["WEST ST MODESTE", "NL"],
  ["WEST ST PAUL", "MB"],
  ["WEST VANCOUVER", "BC"],
  ["WESTBANK", "BC"],
  ["WESTBOURNE", "MB"],
  ["WESTBRIDGE", "BC"],
  ["WESTBROOK", "ON"],
  ["WESTBURY", "QC"],
  ["WESTCHESTER STATION", "NS"],
  ["WESTCOCK", "NB"],
  ["WESTERN BAY", "NL"],
  ["WESTERN SHORE", "NS"],
  ["WESTEROSE", "AB"],
  ["WESTHOLME", "BC"],
  ["WESTLOCK", "AB"],
  ["WESTMEATH", "ON"],
  ["WESTMOUNT", "NS"],
  ["WESTMOUNT", "QC"],
  ["WESTON", "NB"],
  ["WESTPHAL", "NS"],
  ["WESTPORT", "NL"],
  ["WESTPORT", "NS"],
  ["WESTPORT", "ON"],
  ["WESTVILLE", "NS"],
  ["WESTWOLD", "BC"],
  ["WESTWOOD", "ON"],
  ["WETASKIWIN", "AB"],
  ["WEYAKWIN", "SK"],
  ["WEYBURN", "SK"],
  ["WEYMOUTH", "NS"],
  ["WHALE COVE", "NU"],
  ["WHALETOWN", "BC"],
  ["WHATI", "NT"],
  ["WHEATLEY", "ON"],
  ["WHEATON SETTLEMENT", "NB"],
  ["WHELAN", "SK"],
  ["WHISPERING HILLS", "AB"],
  ["WHISTLER", "BC"],
  ["WHITBOURNE", "NL"],
  ["WHITBY", "ON"],
  ["WHITE BEAR", "SK"],
  ["WHITE CITY", "SK"],
  ["WHITE FOX", "SK"],
  ["WHITE GULL", "AB"],
  ["WHITE HEAD ISLAND", "NB"],
  ["WHITE HEAD", "NB"],
  ["WHITE LAKE", "ON"],
  ["WHITE RAPIDS", "NB"],
  ["WHITE RIVER", "ON"],
  ["WHITE ROCK", "BC"],
  ["WHITECAP", "SK"],
  ["WHITECOURT", "AB"],
  ["WHITEDOG", "ON"],
  ["WHITEFISH FALLS", "ON"],
  ["WHITEFISH", "ON"],
  ["WHITEHORSE", "YT"],
  ["WHITELAW", "AB"],
  ["WHITEMOUTH", "MB"],
  ["WHITES BROOK", "NB"],
  ["WHITES COVE", "NB"],
  ["WHITES LAKE", "NS"],
  ["WHITES MOUNTAIN", "NB"],
  ["WHITESHELL", "MB"],
  ["WHITEVALE", "ON"],
  ["WHITEWAY", "NL"],
  ["WHITEWOOD", "SK"],
  ["WHITNEY", "NB"],
  ["WHITNEY", "ON"],
  ["WHYCOCOMAGH", "NS"],
  ["WHYNOTTS SETTLEMENT", "NS"],
  ["WIARTON", "ON"],
  ["WICKHAM", "NB"],
  ["WICKHAM", "QC"],
  ["WICKLOW", "NB"],
  ["WIDEWATER", "AB"],
  ["WIGGINS MILL", "NB"],
  ["WIKWEMIKONG", "ON"],
  ["WILBERFORCE", "ON"],
  ["WILCOX", "SK"],
  ["WILD COVE WB", "NL"],
  ["WILDWOOD", "AB"],
  ["WILEVILLE", "NS"],
  ["WILKESPORT", "ON"],
  ["WILKIE", "SK"],
  ["WILLIAMS HARBOUR", "NL"],
  ["WILLIAMS LAKE", "BC"],
  ["WILLIAMSBURG", "NB"],
  ["WILLIAMSBURG", "ON"],
  ["WILLIAMSFORD", "ON"],
  ["WILLIAMSTOWN CARLETON CO", "NB"],
  ["WILLIAMSTOWN", "NB"],
  ["WILLIAMSTOWN", "ON"],
  ["WILLIAMSWOOD", "NS"],
  ["WILLINGDON", "AB"],
  ["WILLOW BEACH", "ON"],
  ["WILLOW BUNCH", "SK"],
  ["WILLOW GROVE", "NB"],
  ["WILLOW RIVER", "BC"],
  ["WILLOWBROOK", "SK"],
  ["WILMOT STATION", "NS"],
  ["WILMOT", "NB"],
  ["WILMOT", "ON"],
  ["WILNO", "ON"],
  ["WILSONS BEACH", "NB"],
  ["WILSONVILLE", "ON"],
  ["WILTONDALE", "NL"],
  ["WIMBORNE", "AB"],
  ["WINCHESTER SPRINGS", "ON"],
  ["WINCHESTER", "ON"],
  ["WINDERMERE", "BC"],
  ["WINDERMERE", "ON"],
  ["WINDHAM CENTRE", "ON"],
  ["WINDSOR JUNCTION", "NS"],
  ["WINDSOR", "NB"],
  ["WINDSOR", "NS"],
  ["WINDSOR", "ON"],
  ["WINDSOR", "QC"],
  ["WINDTHORST", "SK"],
  ["WINFIELD", "AB"],
  ["WINFIELD", "BC"],
  ["WINGHAM", "ON"],
  ["WINGS POINT", "NL"],
  ["WINKLER", "MB"],
  ["WINLAW", "BC"],
  ["WINNIPEG BEACH", "MB"],
  ["WINNIPEG", "MB"],
  ["WINNIPEGOSIS", "MB"],
  ["WINSLOE", "PE"],
  ["WINTER HARBOUR", "BC"],
  ["WINTERLAND", "NL"],
  ["WINTERTON", "NL"],
  ["WIRRAL", "NB"],
  ["WISETON", "SK"],
  ["WISHART", "SK"],
  ["WITLESS BAY", "NL"],
  ["WITTENBURG", "NS"],
  ["WOBURN", "QC"],
  ["WOKING", "AB"],
  ["WOLFE ISLAND", "ON"],
  ["WOLFVILLE", "NS"],
  ["WOLINAK", "QC"],
  ["WOLLASTON LAKE", "SK"],
  ["WOLSELEY", "SK"],
  ["WONOWON", "BC"],
  ["WOOD MOUNTAIN", "SK"],
  ["WOOD POINT", "NB"],
  ["WOODBRIDGE", "ON"],
  ["WOODFORDS", "NL"],
  ["WOODHAM", "ON"],
  ["WOODLANDS", "MB"],
  ["WOODLANDS", "NB"],
  ["WOODLAWN", "ON"],
  ["WOODMANS POINT", "NB"],
  ["WOODMORE", "MB"],
  ["WOODRIDGE", "MB"],
  ["WOODROW", "SK"],
  ["WOODSIDE", "MB"],
  ["WOODSIDE", "NB"],
  ["WOODSTOCK FIRST NATION", "NB"],
  ["WOODSTOCK", "NB"],
  ["WOODSTOCK", "NL"],
  ["WOODSTOCK", "ON"],
  ["WOODVIEW", "ON"],
  ["WOODVILLE", "ON"],
  ["WOOLER", "ON"],
  ["WORSLEY", "AB"],
  ["WORTHINGTON", "ON"],
  ["WOSS", "BC"],
  ["WOSTOK", "AB"],
  ["WOTTON", "QC"],
  ["WRENTHAM", "AB"],
  ["WRIGLEY", "NT"],
  ["WROXETER", "ON"],
  ["WROXTON", "SK"],
  ["WUHRS BEACH", "NB"],
  ["WUNNUMIN LAKE", "ON"],
  ["WYEBRIDGE", "ON"],
  ["WYERS BROOK", "NB"],
  ["WYEVALE", "ON"],
  ["WYMARK", "SK"],
  ["WYNNDEL", "BC"],
  ["WYNYARD", "SK"],
  ["WYOMING", "ON"],
  ["WYSES CORNER", "NS"],
  ["YAHK", "BC"],
  ["YALE", "BC"],
  ["YAMACHICHE", "QC"],
  ["YAMASKA", "QC"],
  ["YAMASKA-EST", "QC"],
  ["YARBO", "SK"],
  ["YARKER", "ON"],
  ["YARMOUTH", "NS"],
  ["YELLOW CREEK", "SK"],
  ["YELLOW GRASS", "SK"],
  ["YELLOW QUILL", "SK"],
  ["YELLOWKNIFE", "NT"],
  ["YMIR", "BC"],
  ["YOHO", "NB"],
  ["YORK HARBOUR", "NL"],
  ["YORK LANDING", "MB"],
  ["YORK", "ON"],
  ["YORK", "PE"],
  ["YORK.", "ON"],
  ["YORKTON", "SK"],
  ["YOUBOU", "BC"],
  ["YOUNG", "SK"],
  ["YOUNGS COVE", "NB"],
  ["YOUNGS POINT", "ON"],
  ["YOUNGSTOWN", "AB"],
  ["ZAMA CITY", "AB"],
  ["ZEALAND", "NB"],
  ["ZEALANDIA", "SK"],
  ["ZEBALLOS", "BC"],
  ["ZEHNER", "SK"],
  ["ZENON PARK", "SK"],
  ["ZEPHYR", "ON"],
  ["ZHODA", "MB"],
  ["ZIONVILLE", "NB"],
  ["ZURICH", "ON"],
];

export default cities;
