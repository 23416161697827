import {
  ADMIN_ADD_USER_REQUEST,
  ADMIN_ADD_USER_SUCCESS,
  ADMIN_ADD_USER_FAIL,
  ADMIN_ADD_USER_RESET,
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_DELETE_USER_SUCCESS,
  ADMIN_DELETE_USER_FAIL,
  ADMIN_DELETE_USER_RESET,
  ADMIN_ADD_EXPERIENCE_REQUEST,
  ADMIN_ADD_EXPERIENCE_SUCCESS,
  ADMIN_ADD_EXPERIENCE_FAIL,
  ADMIN_EDIT_EXPERIENCE_REQUEST,
  ADMIN_EDIT_EXPERIENCE_SUCCESS,
  ADMIN_EDIT_EXPERIENCE_FAIL,
  ADMIN_EXPERIENCE_VALIDATE_REQUEST,
  ADMIN_EXPERIENCE_VALIDATE_SUCCESS,
  ADMIN_EXPERIENCE_VALIDATE_FAIL,
  ADMIN_ADD_EXPERIENCE_RESET,
  ADMIN_EXPERIENCE_VALIDATE_RESET,
} from "../constants/adminConstants";

// admin add user
export const adminUserAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_ADD_USER_REQUEST:
      return {
        loading: true,
        user: null,
      };
    case ADMIN_ADD_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };
    case ADMIN_ADD_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADMIN_ADD_USER_RESET:
      return {};
    default:
      return state;
  }
};

// admin delete user
export const adminUserDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_USER_REQUEST:
      return {
        loading: true,
      };
    case ADMIN_DELETE_USER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADMIN_DELETE_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADMIN_DELETE_USER_RESET:
      return {};
    default:
      return state;
  }
};

// admin add experience
export const adminExperienceAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_ADD_EXPERIENCE_REQUEST:
      return {
        loading: true,
        experience: null,
      };
    case ADMIN_ADD_EXPERIENCE_SUCCESS:
      return {
        loading: false,
        experience: action.payload,
      };
    case ADMIN_ADD_EXPERIENCE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADMIN_ADD_EXPERIENCE_RESET:
      return {};
    default:
      return state;
  }
};

// admin edit experience
export const adminExperienceEditReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EDIT_EXPERIENCE_REQUEST:
      return {
        loading: true,
        experience: null,
      };
    case ADMIN_EDIT_EXPERIENCE_SUCCESS:
      return {
        loading: false,
        experience: action.payload,
      };
    case ADMIN_EDIT_EXPERIENCE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// admin validate experience
export const adminExperienceValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_EXPERIENCE_VALIDATE_REQUEST:
      return {
        loading: true,
      };
    case ADMIN_EXPERIENCE_VALIDATE_SUCCESS:
      return {
        loading: false,
        validated: action.payload,
      };
    case ADMIN_EXPERIENCE_VALIDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADMIN_EXPERIENCE_VALIDATE_RESET:
      return {};
    default:
      return state;
  }
};
