export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_CREATE_RESET = "USER_CREATE_RESET";

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL";
export const USER_ACTIVATE_RESET = "USER_ACTIVATE_RESET";

export const USER_LIST_ALL_REQUEST = "USER_LIST_ALL_REQUEST";
export const USER_LIST_ALL_SUCCESS = "USER_LIST_ALL_SUCCESS";
export const USER_LIST_ALL_FAIL = "USER_LIST_ALL_FAIL";
export const USER_LIST_ALL_RESET = "USER_LIST_ALL_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_LIST_RESET";

export const USER_SET_AVATAR_REQUEST = "USER_SET_AVATAR_REQUEST";
export const USER_SET_AVATAR_SUCCESS = "USER_SET_AVATAR_SUCCESS";
export const USER_SET_AVATAR_FAIL = "USER_SET_AVATAR_FAIL";

export const USER_SET_BANNER_REQUEST = "USER_SET_BANNER_REQUEST";
export const USER_SET_BANNER_SUCCESS = "USER_SET_BANNER_SUCCESS";
export const USER_SET_BANNER_FAIL = "USER_SET_BANNER_FAIL";

export const USER_SET_THUMBNAIL_REQUEST = "USER_SET_THUMBNAIL_REQUEST";
export const USER_SET_THUMBNAIL_SUCCESS = "USER_SET_THUMBNAIL_SUCCESS";
export const USER_SET_THUMBNAIL_FAIL = "USER_SET_THUMBNAIL_FAIL";

export const USER_PUSH_VALUE_REQUEST = "USER_PUSH_VALUE_REQUEST";
export const USER_PUSH_VALUE_SUCCESS = "USER_PUSH_VALUE_SUCCESS";
export const USER_PUSH_VALUE_FAIL = "USER_PUSH_VALUE_FAIL";

export const USER_PUSH_ABOUT_REQUEST = "USER_PUSH_ABOUT_REQUEST";
export const USER_PUSH_ABOUT_SUCCESS = "USER_PUSH_ABOUT_SUCCESS";
export const USER_PUSH_ABOUT_FAIL = "USER_PUSH_ABOUT_FAIL";

export const USER_SET_LA_REQUEST = "USER_SET_LA_REQUEST";
export const USER_SET_LA_SUCCESS = "USER_SET_LA_SUCCESS";
export const USER_SET_LA_FAIL = "USER_SET_LA_FAIL";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";

export const USER_PASSWORD_RESET_REQUEST_REQUEST =
  "USER_PASSWORD_RESET_REQUEST_REQUEST";
export const USER_PASSWORD_RESET_REQUEST_SUCCESS =
  "USER_PASSWORD_RESET_REQUEST_SUCCESS";
export const USER_PASSWORD_RESET_REQUEST_FAIL =
  "USER_PASSWORD_RESET_REQUEST_FAIL";
export const USER_PASSWORD_RESET_REQUEST_RESET =
  "USER_PASSWORD_RESET_REQUEST_RESET";
