import axios from "axios";

import {
  WS_GET_URL_REQUEST,
  WS_GET_URL_SUCCESS,
  WS_GET_URL_FAIL,
  CONV_GET_CONVERSATIONS_REQUEST,
  CONV_GET_CONVERSATIONS_SUCCESS,
  CONV_GET_CONVERSATIONS_FAIL,
  CONV_CREATE_NEW_REQUEST,
  CONV_CREATE_NEW_SUCCESS,
  CONV_CREATE_NEW_FAIL,
  MSG_GET_MESSAGES_REQUEST,
  MSG_GET_MESSAGES_SUCCESS,
  MSG_GET_MESSAGES_FAIL,
  MSG_ADD_NEW_REQUEST,
  MSG_ADD_NEW_SUCCESS,
  MSG_ADD_NEW_FAIL,
} from "../constants/connectConstants";

// get socket connected
export const getSocketConnected = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: WS_GET_URL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/conversations/ws`, config);

    dispatch({
      type: WS_GET_URL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WS_GET_URL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get user conversations
export const getConversations = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONV_GET_CONVERSATIONS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/conversations/${userId}`, config);

    dispatch({
      type: CONV_GET_CONVERSATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONV_GET_CONVERSATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// create conversation
export const createNewConversation =
  (senderId, receiverId, senderType, receiverType) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONV_CREATE_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/conversations`,
        { senderId, receiverId, senderType, receiverType },
        config,
      );

      dispatch({
        type: CONV_CREATE_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONV_CREATE_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// get messages by converstation id
export const getMessages = (converstationId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MSG_GET_MESSAGES_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/messages/${converstationId}`,
      config,
    );

    dispatch({
      type: MSG_GET_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MSG_GET_MESSAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// add new message
export const addNewMessage =
  (conversationId, sender, text) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MSG_ADD_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/messages`,
        { conversationId, sender, text },
        config,
      );

      dispatch({
        type: MSG_ADD_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MSG_ADD_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
