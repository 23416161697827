// all experiences
export const EXPERIENCE_LIST_REQUEST = "EXPERIENCE_LIST_REQUEST";
export const EXPERIENCE_LIST_SUCCESS = "EXPERIENCE_LIST_SUCCESS";
export const EXPERIENCE_LIST_FAIL = "EXPERIENCE_LIST_FAIL";
export const EXPERIENCE_LIST_RESET = "EXPERIENCE_LIST_RESET";

// single experience details
export const EXPERIENCE_DETAILS_REQUEST = "EXPERIENCE_DETAILS_REQUEST";
export const EXPERIENCE_DETAILS_SUCCESS = "EXPERIENCE_DETAILS_SUCCESS";
export const EXPERIENCE_DETAILS_FAIL = "EXPERIENCE_DETAILS_FAIL";

// delete experience
export const EXPERIENCE_DELETE_REQUEST = "EXPERIENCE_DELETE_REQUEST";
export const EXPERIENCE_DELETE_SUCCESS = "EXPERIENCE_DELETE_SUCCESS";
export const EXPERIENCE_DELETE_FAIL = "EXPERIENCE_DELETE_FAIL";
export const EXPERIENCE_DELETE_RESET = "EXPERIENCE_DELETE_RESET";

// request experience validation from admin
export const EXPERIENCE_VALIDATE_REQUEST = "EXPERIENCE_VALIDATE_REQUEST";
export const EXPERIENCE_VALIDATE_SUCCESS = "EXPERIENCE_VALIDATE_SUCCESS";
export const EXPERIENCE_VALIDATE_FAIL = "EXPERIENCE_VALIDATE_FAIL";
export const EXPERIENCE_VALIDATE_RESET = "EXPERIENCE_VALIDATE_RESET";

// set experience hero visual
export const EXPERIENCE_SET_HERO_REQUEST = "EXPERIENCE_SET_HERO_REQUEST";
export const EXPERIENCE_SET_HERO_SUCCESS = "EXPERIENCE_SET_HERO_SUCCESS";
export const EXPERIENCE_SET_HERO_FAIL = "EXPERIENCE_SET_HERO_FAIL";

// set experience name
export const EXPERIENCE_SET_NAME_REQUEST = "EXPERIENCE_SET_NAME_REQUEST";
export const EXPERIENCE_SET_NAME_SUCCESS = "EXPERIENCE_SET_NAME_SUCCESS";
export const EXPERIENCE_SET_NAME_FAIL = "EXPERIENCE_SET_NAME_FAIL";

// set experience banner
export const EXPERIENCE_SET_BANNER_REQUEST = "EXPERIENCE_SET_BANNER_REQUEST";
export const EXPERIENCE_SET_BANNER_SUCCESS = "EXPERIENCE_SET_BANNER_SUCCESS";
export const EXPERIENCE_SET_BANNER_FAIL = "EXPERIENCE_SET_BANNER_FAIL";

// set experience num purchased
export const EXPERIENCE_SET_NUM_PURCHASED_REQUEST = "EXPERIENCE_SET_NUM_PURCHASED_REQUEST";
export const EXPERIENCE_SET_NUM_PURCHASED_SUCCESS = "EXPERIENCE_SET_NUM_PURCHASED_SUCCESS";
export const EXPERIENCE_SET_NUM_PURCHASED_FAIL = "EXPERIENCE_SET_NUM_PURCHASED_FAIL";

// add experience review
export const EXPERIENCE_ADD_REVIEW_REQUEST = "EXPERIENCE_ADD_REVIEW_REQUEST";
export const EXPERIENCE_ADD_REVIEW_SUCCESS = "EXPERIENCE_ADD_REVIEW_SUCCESS";
export const EXPERIENCE_ADD_REVIEW_FAIL = "EXPERIENCE_ADD_REVIEW_FAIL";

// set experience gallery thumbnail
export const EXPERIENCE_SET_GALLERY_THUMBNAIL_REQUEST =
  "EXPERIENCE_SET_GALLERY_THUMBNAIL_REQUEST";
export const EXPERIENCE_SET_GALLERY_THUMBNAIL_SUCCESS =
  "EXPERIENCE_SET_GALLERY_THUMBNAIL_SUCCESS";
export const EXPERIENCE_SET_GALLERY_THUMBNAIL_FAIL =
  "EXPERIENCE_SET_GALLERY_THUMBNAIL_FAIL";
