import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { techSupportEmail } from "../data/data";
import { createNewHost } from "../actions/hostActions";

import Loader from "../components/Loader";
import Message from "../components/Message";

const HostForm = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const hostCreate = useSelector((state) => state.hostCreate);
  const { loading, error } = hostCreate;

  const [accountId, setAccountId] = useState("");
  const [stripeKey, setStripeKey] = useState("");

  const accountIdHandler = (e) => {
    setAccountId(e.target.value);
  };
  const stripeKeyHandler = (e) => {
    setStripeKey(e.target.value);
  };

  const resetFields = () => {
    setAccountId("");
    setStripeKey("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (accountId?.length && stripeKey?.length) {
      const host = {
        partner: userInfo._id,
        name: userInfo.name,
        email: userInfo.email,
        stripeAccountId: accountId,
        stripeApiKey: stripeKey,
      };

      dispatch(createNewHost(host));

      resetFields();
    }
  };

  return (
    <>
      {loading && <Loader />}
      {error && <Message body={error} />}
      <div
        className="account-content"
        style={{
          height: "fit-content",
          paddingBottom: "100px",
        }}
      >
        <form onSubmit={submitHandler}>
          <div
            style={{
              padding: "10px 30px",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h3>enter your financial information</h3>
            <p>
              This information is used to connect to your Stripe account. By
              connecting these accounts, any bookings you receive will be
              automatically split and the payment minus the booking fee will be
              deposited into your account.
            </p>
          </div>
          <input
            type="text"
            placeholder="your stripe account id *"
            onChange={accountIdHandler}
            value={accountId}
            required
          />
          <input
            type="text"
            placeholder="your stripe api key *"
            onChange={stripeKeyHandler}
            value={stripeKey}
            required
          />
          <button className="btn" type="submit" onSubmit={submitHandler}>
            submit
          </button>
          <p style={{ paddingLeft: "30px" }}>
            Something wrong?{" "}
            <a className="lost-password" href={`mailto:${techSupportEmail}`}>
              Contact technical support!
            </a>
          </p>
        </form>
      </div>
    </>
  );
};

export default HostForm;
