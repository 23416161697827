export const ADMIN_ADD_USER_REQUEST = "ADMIN_ADD_USER_REQUEST";
export const ADMIN_ADD_USER_SUCCESS = "ADMIN_ADD_USER_SUCCESS";
export const ADMIN_ADD_USER_FAIL = "ADMIN_ADD_USER_FAIL";
export const ADMIN_ADD_USER_RESET = "ADMIN_ADD_USER_RESET";

export const ADMIN_DELETE_USER_REQUEST = "ADMIN_DELETE_USER_REQUEST";
export const ADMIN_DELETE_USER_SUCCESS = "ADMIN_DELETE_USER_SUCCESS";
export const ADMIN_DELETE_USER_FAIL = "ADMIN_DELETE_USER_FAIL";
export const ADMIN_DELETE_USER_RESET = "ADMIN_DELETE_USER_RESET";

export const ADMIN_ADD_EXPERIENCE_REQUEST = "ADMIN_ADD_EXPERIENCE_REQUEST";
export const ADMIN_ADD_EXPERIENCE_SUCCESS = "ADMIN_ADD_EXPERIENCE_SUCCESS";
export const ADMIN_ADD_EXPERIENCE_FAIL = "ADMIN_ADD_EXPERIENCE_FAIL";
export const ADMIN_ADD_EXPERIENCE_RESET = "ADMIN_ADD_EXPERIENCE_RESET";

export const ADMIN_EDIT_EXPERIENCE_REQUEST = "ADMIN_EDIT_EXPERIENCE_REQUEST";
export const ADMIN_EDIT_EXPERIENCE_SUCCESS = "ADMIN_EDIT_EXPERIENCE_SUCCESS";
export const ADMIN_EDIT_EXPERIENCE_FAIL = "ADMIN_EDIT_EXPERIENCE_FAIL";

export const ADMIN_EXPERIENCE_VALIDATE_REQUEST =
  "ADMIN_EXPERIENCE_VALIDATE_REQUEST";
export const ADMIN_EXPERIENCE_VALIDATE_SUCCESS =
  "ADMIN_EXPERIENCE_VALIDATE_SUCCESS";
export const ADMIN_EXPERIENCE_VALIDATE_FAIL =
  "ADMIN_EXPERIENCE_VALIDATE_FAIL";
export const ADMIN_EXPERIENCE_VALIDATE_RESET =
  "ADMIN_EXPERIENCE_VALIDATE_RESET";
