import React from "react";

import Search from "../components/Search";

const SearchScreen = () => {
  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        <Search />
      </div>
    </div>
  );
};

export default SearchScreen;
