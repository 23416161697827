import React from "react";
import { useDispatch } from "react-redux";

import { payBooking } from "../actions/bookingActions";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { BOOKING_PAY_FAIL } from "../constants/bookingConstants";

export const CheckoutForm = ({ bookingId }) => {
  const dispatch = useDispatch();

  const cardElementOptions = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "var(--var-color-vrc-beige)",
        color: "var(--var-color-vrc-beige)",
        fontFamily: "var(--var-oswald)",
        padding: "10px",
        border: "2px solid var(--var-color-vrc-beige)",
        borderRadius: "50px",
        filter: "invert(100%)",
      },
      invalid: {
        iconColor: "var(--var-color-vrc-red)",
        color: "var(--var-color-vrc-red)",
        filter: "invert(100%)",
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (paymentMethod) {
      const { id } = paymentMethod;

      dispatch(payBooking(bookingId, id));
    } else if (error) {
      dispatch({ type: BOOKING_PAY_FAIL, payload: error.message });
    }
  };

  return (
    <div className="action-card">
      <img className="stripe-icon" src="/images/payment-stripe.png" alt="" />
      <form onSubmit={handleSubmit}>
        <div className="stripe-input-container">
          <CardElement options={cardElementOptions} />
        </div>
        <button className="btn" type="submit" disabled={!stripe || !elements}>
          Pay Now
        </button>
      </form>
    </div>
  );
};
