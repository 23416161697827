import React, { useEffect, useState } from "react";

const Message = ({ body, type, delay = 5 }) => {
  const [display, setDisplay] = useState("block");

  useEffect(() => {
    setDisplay("block");
    setTimeout(() => setDisplay("none"), delay * 1000);
    // eslint-disable-next-line
  }, [body]);

  return (
    <div
      className="popup message"
      style={{
        display: `${display}`,
      }}
    >
      <div className="popup-overlay"></div>
      <div className="container">
        <div
          className="message-body"
          style={{
            color: "var(--var-color-vrc-beige)",
            backgroundColor:
              type === "info"
                ? "var(--var-color-vrc-green)"
                : "var(--var-color-vrc-red)",
          }}
        >
          <div>
            <h3>{type === "info" ? "success" : "error"}</h3>
            <h6>
              {body?.toLowerCase().includes("code 500") ||
              body?.toLowerCase().includes("<!doctype")
                ? "Something went wrong! Please contact the platform administrator!"
                : body}
            </h6>
          </div>
          <img
            src={
              type === "info"
                ? "/images/vrc-icons-beige-true.png"
                : "/images/vrc-icons-beige-false.png"
            }
            alt="discount-applied"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Message;
