import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { listMyBookings } from "../actions/bookingActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const MyBookingsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bookingListMyBookings = useSelector(
    (state) => state.bookingListMyBookings,
  );
  const { loading, error, bookings } = bookingListMyBookings;

  useEffect(() => {
    if (userInfo) {
      dispatch(listMyBookings());
    } else {
      history.push("/");
    }
  }, [history, userInfo, dispatch]);
  return (
    <div
      className="landing-generic my-bookings"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
        paddingBottom: "60px",
      }}
    >
      <div
        className="container"
        style={{ color: "var(--var-color-vrc-beige)" }}
      >
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : (
          <>
            {bookings && bookings?.length ? (
              <>
                <h3 style={{ margin: "40px 0" }}>My bookings</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  {bookings.map((booking, index) => (
                    <Link
                      to={`/booking/${booking._id}`}
                      key={index}
                      className="btn"
                    >
                      {window.innerWidth > 835 ? (
                        <>
                          <span >{booking._id}</span>
                          <span style={{ width: "80px" }}>
                            {booking.bookingItems[0].date}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      <span
                        style={{
                          width: "40px",
                          color: !booking.totalPrice
                            ? "var(--var-color-vrc-green)"
                            : "",
                        }}
                      >
                        ${booking.totalPrice ? booking.totalPrice : "Free"}
                      </span>
                      <span style={{ flexGrow: "1" }}>
                        {booking.bookingItems[0].name.substring(0, 50)}
                        {booking.bookingItems[0].name.length > 50 ? "..." : ""}
                      </span>
                      <span
                        style={{
                          width: "140px",
                          color: booking.isDeclined
                            ? "var(--var-color-vrc-red)"
                            : booking.isPaid
                            ? "var(--var-color-vrc-green)"
                            : booking.isConfirmed
                            ? "var(--var-color-vrc-yellow)"
                            : !booking.isConfirmed
                            ? "var(--var-color-vrc-beige)"
                            : "var(--var-color-vrc-beige)",
                        }}
                      >
                        {booking.isDeclined
                          ? "declined"
                          : booking.isPaid
                          ? "set"
                          : booking.isConfirmed
                          ? "confirmed"
                          : !booking.isConfirmed
                          ? "pending confirmation"
                          : ""}
                      </span>
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <p className="no-messages">You have no bookings yet</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyBookingsScreen;
