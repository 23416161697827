import React from "react";

const Rating = ({ value, color, numReviews }) => {
  return (
    <div className="rating">
      <div className="stars">
        <img
          className="rating-star"
          alt=""
          src={
            value >= 1
              ? `/images/vrc-icons-${color}-star-full.png`
              : value >= 0.5
              ? `/images/vrc-icons-${color}-star-half.png`
              : `/images/vrc-icons-${color}-star-empty.png`
          }
        />
        <img
          className="rating-star"
          alt=""
          src={
            value >= 2
              ? `/images/vrc-icons-${color}-star-full.png`
              : value >= 1.5
              ? `/images/vrc-icons-${color}-star-half.png`
              : `/images/vrc-icons-${color}-star-empty.png`
          }
        />
        <img
          className="rating-star"
          alt=""
          src={
            value >= 3
              ? `/images/vrc-icons-${color}-star-full.png`
              : value >= 2.5
              ? `/images/vrc-icons-${color}-star-half.png`
              : `/images/vrc-icons-${color}-star-empty.png`
          }
        />
        <img
          className="rating-star"
          alt=""
          src={
            value >= 4
              ? `/images/vrc-icons-${color}-star-full.png`
              : value >= 3.5
              ? `/images/vrc-icons-${color}-star-half.png`
              : `/images/vrc-icons-${color}-star-empty.png`
          }
        />
        <img
          className="rating-star"
          alt=""
          src={
            value >= 5
              ? `/images/vrc-icons-${color}-star-full.png`
              : value >= 4.5
              ? `/images/vrc-icons-${color}-star-half.png`
              : `/images/vrc-icons-${color}-star-empty.png`
          }
        />
      </div>
      {numReviews && (
        <span>
          ({numReviews} teacher review{numReviews.length > 1 && "s"})
        </span>
      )}
    </div>
  );
};

export default Rating;
