import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteExperience,
  listExperiences,
} from "../actions/experienceActions";
import { EXPERIENCE_DELETE_RESET } from "../constants/experienceConstants";

import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AllExperiencesScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const experienceList = useSelector((state) => state.experienceList);
  const { loading, error, experiences } = experienceList;

  const experienceDelete = useSelector((state) => state.experienceDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success,
  } = experienceDelete;

  useEffect(() => {
    if (
      !userInfo ||
      (userInfo.type !== "admin" && userInfo.type !== "reviewer")
    ) {
      history.push("/");
    } else {
      dispatch(listExperiences());
    }
  }, [dispatch, history, userInfo]);

  const deleteExperienceHandler = (e) => {
    e.preventDefault();
    dispatch(deleteExperience(e.target.id));
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: EXPERIENCE_DELETE_RESET });
        dispatch(listExperiences());
      }, 3000);
    }
  }, [success, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        {loadingDelete && <Loader />}
        {errorDelete && <Message body={errorDelete} />}
        {success && (
          <Message body="Experience deleted successfully!" type="info" />
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : experiences && experiences?.length ? (
          <>
            <h5 style={{ marginBottom: "20px" }}>
              all experiences ({experiences.length})
            </h5>
            <div className="user-list">
              {experiences
                .sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((experience, index) => (
                  <div style={{ display: "flex", gap: "10px" }} key={index}>
                    <Link
                      to={`/experience/${experience._id}`}
                      className="btn"
                      style={{ flexGrow: "1" }}
                    >
                      <span>{experience._id}</span>
                      <span>{experience.name}</span>
                      <span>{experience.subject}</span>
                    </Link>
                    {userInfo.type === "admin" && (
                      <span
                        className="btn"
                        style={{ width: "fit-content" }}
                        onClick={deleteExperienceHandler}
                        id={experience._id}
                      >
                        x
                      </span>
                    )}
                  </div>
                ))}
            </div>
          </>
        ) : (
          <p className="no-messages">No experiences available</p>
        )}
      </div>
    </div>
  );
};

export default AllExperiencesScreen;
