export const HOST_CREATE_REQUEST = "HOST_CREATE_REQUEST";
export const HOST_CREATE_SUCCESS = "HOST_CREATE_SUCCESS";
export const HOST_CREATE_FAIL = "HOST_CREATE_FAIL";
export const HOST_CREATE_RESET = "HOST_CREATE_RESET";

export const HOST_DETAILS_REQUEST = "HOST_DETAILS_REQUEST";
export const HOST_DETAILS_SUCCESS = "HOST_DETAILS_SUCCESS";
export const HOST_DETAILS_FAIL = "HOST_DETAILS_FAIL";
export const HOST_DETAILS_RESET = "HOST_DETAILS_RESET";

export const HOST_LIST_REQUEST = "HOST_LIST_REQUEST";
export const HOST_LIST_SUCCESS = "HOST_LIST_SUCCESS";
export const HOST_LIST_FAIL = "HOST_LIST_FAIL";
