import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../actions/userActions";

const AdminAccountForm = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [type, setType] = useState("hide");
  const [emailLog, setEmailLog] = useState("");
  const [passwordLog, setPasswordLog] = useState("");

  const typeHandler = (e) => {
    setType(e.target.value);
  };

  const emailLogHandler = (e) => {
    setEmailLog(e.target.value);
  };
  const passwordLogHandler = (e) => {
    setPasswordLog(e.target.value);
  };

  const resetFields = () => {
    setType("hide");
    setEmailLog("");
    setPasswordLog("");
  };

  // login handler
  const submitLogHandler = (e) => {
    e.preventDefault();
    if (
      type?.length &&
      type !== "hide" &&
      emailLog?.length &&
      passwordLog?.length
    ) {
      dispatch(login(emailLog, passwordLog, type));
    }
  };

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        resetFields();
      }, 5000);
    }
  }, [userInfo, dispatch]);

  return (
    <>
      <div className="account-content">
        <img
          src="/images/logo.png"
          alt="vrc-logo"
          style={{ width: "400px", marginBottom: "50px" }}
        />
        <form onSubmit={submitLogHandler}>
          <div className="select">
            <select onChange={typeHandler}>
              <option value="hide">choose acccount type</option>
              <option value="admin">admin</option>
              <option value="reviewer">educator</option>
            </select>
          </div>
          <input
            type="email"
            placeholder="your email address *"
            onChange={emailLogHandler}
            value={emailLog}
            required
          />
          <input
            type="password"
            placeholder="your password *"
            onChange={passwordLogHandler}
            value={passwordLog}
            required
          />
          <button className="btn" type="submit" onSubmit={submitLogHandler}>
            login
          </button>
        </form>
      </div>
    </>
  );
};

export default AdminAccountForm;
