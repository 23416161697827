import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { EXPERIENCE_DELETE_RESET } from "../constants/experienceConstants";
import { USER_PASSWORD_RESET_REQUEST_RESET } from "../constants/userConstants";
import {
  CONV_CREATE_NEW_RESET,
  MSG_ADD_NEW_RESET,
} from "../constants/connectConstants";

import {
  listUserDetails,
  setUserAvatar,
  setUserBanner,
  setThumbnail,
  addPartnerValue,
  addPartnerAbout,
  setPartnerLA,
  resetUserPassword,
} from "../actions/userActions";
import { listExperiences } from "../actions/experienceActions";
import {
  addNewMessage,
  createNewConversation,
} from "../actions/connectActions";
import { listRelatedMedia, uploadMedia } from "../actions/mediaActions";

import Collection from "../components/Collection";
import Loader from "../components/Loader";
import Message from "../components/Message";

const PartnerScreen = ({ match }) => {
  const dispatch = useDispatch();

  const galleryThumbnails = new Array(3).fill(false);

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const {
    loading: loadingPwd,
    error: errorPwd,
    updatedUser,
  } = userResetPassword;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const experienceDelete = useSelector((state) => state.experienceDelete);
  const { loading: loadingDel, error: errorDel, success } = experienceDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const experienceList = useSelector((state) => state.experienceList);
  const { loading: loadingExp, error: errorExp, experiences } = experienceList;

  const createConversation = useSelector((state) => state.createConversation);
  const { conversation } = createConversation;

  const newMessage = useSelector((state) => state.newMessage);
  const { message: nMessage } = newMessage;

  const [valueDisplayWindow, setValuesDisplayWindow] = useState(false);
  const [value, setValue] = useState(``);

  // admin only
  const [passwordDisplayWindow, setPasswordDisplayWindow] = useState(false);

  const [password, setPassword] = useState(``);
  const [confirmedPassword, setConfirmedPassword] = useState(``);
  const [passwordStatus, setPasswordStatus] = useState(`password`);
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] =
    useState(`password`);
  const [passwordIconStatus, setPasswordIconStatus] = useState(`show`);
  const [confirmedPasswordIconStatus, setConfirmedPasswordIconStatus] =
    useState(`show`);

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const passwordStatusHandler = (e) => {
    e.preventDefault();
    if (passwordStatus === "text") {
      setPasswordStatus("password");
    } else {
      setPasswordStatus("text");
    }

    if (passwordIconStatus === "show") {
      setPasswordIconStatus("hide");
    } else {
      setPasswordIconStatus("show");
    }
  };

  const confirmedPasswordStatusHandler = (e) => {
    e.preventDefault();
    if (confirmedPasswordStatus === "text") {
      setConfirmedPasswordStatus("password");
    } else {
      setConfirmedPasswordStatus("text");
    }

    if (confirmedPasswordIconStatus === "show") {
      setConfirmedPasswordIconStatus("hide");
    } else {
      setConfirmedPasswordIconStatus("show");
    }
  };

  const resetPasswordFields = () => {
    setPassword(``);
    setConfirmedPassword(``);
    setPasswordStatus("password");
    setConfirmedPasswordStatus("password");
    setPasswordIconStatus("show");
    setConfirmedPasswordIconStatus("show");
  };

  const resetPassword = () => {
    if (
      password?.length &&
      confirmedPassword?.length &&
      password === confirmedPassword
    ) {
      dispatch(resetUserPassword(user.type, password, user._id));
      resetPasswordFields();
      setPasswordDisplayWindow(false);
    }
  };

  const displayPasswordMenu = (e) => {
    e.preventDefault();
    resetPasswordFields();
    setPasswordDisplayWindow(true);
  };

  const hidePasswordMenu = (e) => {
    e.preventDefault();
    resetPasswordFields();
    setPasswordDisplayWindow(false);
  };

  useEffect(() => {
    if (updatedUser) {
      setTimeout(() => {
        dispatch({ type: USER_PASSWORD_RESET_REQUEST_RESET });
        window.location.reload();
      }, 5000);
    }
  }, [updatedUser, dispatch]);

  //

  const valueHandler = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const pushValue = () => {
    if (value?.length) {
      dispatch(addPartnerValue(user._id, value));
      setValue(``);
      setValuesDisplayWindow(false);
      window.location.reload();
    }
  };

  const displayValuesMenu = (e) => {
    e.preventDefault();
    setValue(``);
    setValuesDisplayWindow(true);
  };

  const hideValuesMenu = (e) => {
    e.preventDefault();
    setValuesDisplayWindow(false);
    setValue(``);
  };

  const [aboutDisplayWindow, setAboutDisplayWindow] = useState(false);
  const [about, setAbout] = useState(``);

  const aboutHandler = (e) => {
    e.preventDefault();
    setAbout(e.target.value);
  };

  const pushAbout = () => {
    if (about?.length) {
      dispatch(addPartnerAbout(user._id, about));
      setAbout(``);
      setAboutDisplayWindow(false);
      window.location.reload();
    }
  };

  const displayAboutMenu = (e) => {
    e.preventDefault();
    setAbout(``);
    setAboutDisplayWindow(true);
  };

  const hideAboutMenu = (e) => {
    e.preventDefault();
    setAboutDisplayWindow(false);
    setAbout(``);
  };

  const [LADisplayWindow, setLADisplayWindow] = useState(false);
  const [LA, setLA] = useState(``);

  const LAHandler = (e) => {
    e.preventDefault();
    setLA(e.target.value);
  };

  const addLA = () => {
    if (LA?.length) {
      dispatch(setPartnerLA(user._id, LA));
      setLA(``);
      setLADisplayWindow(false);
      window.location.reload();
    }
  };

  const displayLAMenu = (e) => {
    e.preventDefault();
    setLA(``);
    setLADisplayWindow(true);
  };

  const hideLAMenu = (e) => {
    e.preventDefault();
    setLADisplayWindow(false);
    setLA(``);
  };

  const [messageDisplayWindow, setMessageDisplayWindow] = useState(false);
  const [message, setMessage] = useState(``);

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const addMessage = (e) => {
    e.preventDefault();
    if (message?.length) {
      dispatch(
        createNewConversation(userInfo._id, user._id, userInfo.type, user.type),
      );
    }
  };

  useEffect(() => {
    if (conversation && message?.length) {
      dispatch(addNewMessage(conversation._id, userInfo._id, message));
      setMessage(``);
      setMessageDisplayWindow(false);
    }
    // eslint-disable-next-line
  }, [dispatch, conversation]);

  useEffect(() => {}, [message]);

  useEffect(() => {
    if (nMessage) {
      setTimeout(() => {
        dispatch({ type: CONV_CREATE_NEW_RESET });
        dispatch({ type: MSG_ADD_NEW_RESET });
      }, 3000);
    }
    // eslint-disable-next-line
  }, [dispatch, nMessage]);

  const displayMessageMenu = (e) => {
    e.preventDefault();
    setMessage(``);
    setMessageDisplayWindow(true);
  };

  const hideMessageMenu = (e) => {
    e.preventDefault();
    setMessageDisplayWindow(false);
    setMessage(``);
  };

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  const userSetAvatar = useSelector((state) => state.userSetAvatar);
  const {
    loading: loadingAvatar,
    error: errorAvatar,
    success: successAvatar,
  } = userSetAvatar;

  const userSetBanner = useSelector((state) => state.userSetBanner);
  const {
    loading: loadingBanner,
    error: errorBanner,
    success: successBanner,
  } = userSetBanner;

  const userSetThumbnail = useSelector((state) => state.userSetThumbnail);
  const {
    loading: loadingThumbnail,
    error: errorThumbnail,
    success: successThumbnail,
  } = userSetThumbnail;

  useEffect(() => {
    if (successAvatar || successBanner || successThumbnail) {
      window.location.reload();
    }
  }, [successAvatar, successBanner, successThumbnail]);

  useEffect(() => {
    dispatch(listUserDetails(match.params.id, match.url.split("/")[1]));
  }, [dispatch, match]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: EXPERIENCE_DELETE_RESET });
      }, 3000);
    }
    if (userInfo && !experiences) {
      dispatch(listExperiences());
    }
  }, [userInfo, dispatch, success, experiences]);

  ////////////////////////////////////
  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  const [elementType, setElementType] = useState(``);
  const [elementContent, setElementContent] = useState(``);

  const [displayWindow, setDisplayWindow] = useState(false);

  const displayMenu = (e) => {
    e.preventDefault();
    if (!files) {
      getMediaFiles(e);
    }
    setElementType(e.target.id);
    setElementContent(``);
    setDisplayWindow(true);
  };

  const hideMenu = (e) => {
    e.preventDefault();
    setDisplayWindow(false);
    setElementContent(``);
    setElementType(``);
  };

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia(userInfo._id, userInfo.type));
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  const setVisual = () => {
    if (elementContent && elementType?.length) {
      switch (elementType) {
        case "profile-picture":
          dispatch(
            setUserAvatar(
              userInfo._id,
              userInfo.type,
              elementContent.replace("\\", "/"),
            ),
          );
          break;
        case "banner":
          dispatch(
            setUserBanner(
              userInfo._id,
              userInfo.type,
              elementContent.replace("\\", "/"),
            ),
          );
          break;
        case "gallery-thumbnail-0":
        case "gallery-thumbnail-1":
        case "gallery-thumbnail-2":
          dispatch(
            setThumbnail(
              userInfo._id,
              userInfo.type,
              elementContent.replace("\\", "/"),
              elementType.split("-")[2],
            ),
          );
          break;
        default:
          return;
      }
      window.location.reload();
    }
  };

  useEffect(() => {
    if (file && file?.length) {
      setElementContent(file);
    }
  }, [file]);

  ////////////////////////////////////

  return (
    <>
      {loadingExp && <Loader />}
      {loadingDel && <Loader />}
      {loadingPwd && <Loader />}
      {loadingBanner && <Loader />}
      {loadingAvatar && <Loader />}
      {loadingThumbnail && <Loader />}
      {errorExp && <Message body={errorExp} />}
      {errorDel && <Message body={errorDel} />}
      {errorPwd && <Message body={errorPwd} />}
      {errorBanner && <Message body={errorBanner} />}
      {errorAvatar && <Message body={errorAvatar} />}
      {errorThumbnail && <Message body={errorThumbnail} />}
      {nMessage && <Message body="Message sent successfully!" type="info" />}
      {success && (
        <Message body="Experience deleted successfully!" type="info" />
      )}
      {updatedUser && (
        <Message body="Password reset successfully!" type="info" />
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        user && (
          <>
            {userInfo && userInfo._id === user._id && displayWindow && (
              <>
                <div className="popup" style={{ display: "flex" }}>
                  <div className="overlay"></div>
                  <div className="container">
                    {loadingFiles && <Loader />}
                    {loadingFile && <Loader />}
                    {errorFiles && <Message body={errorFiles} />}
                    {errorFile && <Message body={errorFile} />}
                    <div className="popup-content">
                      <div style={{ display: "flex", gap: "10px" }}>
                        <input
                          type="text"
                          onChange={elementContentHandler}
                          placeholder="enter image url"
                          min="0"
                          className="experience-new-name"
                          value={elementContent}
                          style={{ flexGrow: "1" }}
                        />

                        <input
                          type="file"
                          className="experience-new-name"
                          onChange={uploadFileHandler}
                          id="upload-inline-image"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="upload-inline-image" className="btn">
                          upload file
                        </label>
                        <button className="btn" onClick={getMediaFiles}>
                          refresh list
                        </button>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <button
                          className="btn"
                          onClick={setVisual}
                          style={{ marginTop: "20px" }}
                        >
                          set
                        </button>
                        <button
                          className="btn"
                          onClick={hideMenu}
                          style={{ marginTop: "20px" }}
                        >
                          cancel
                        </button>
                      </div>
                      {files && files?.length && (
                        <div className="media-lib">
                          {files.map((file, index) => (
                            <div
                              key={index}
                              className="media-file"
                              style={{ backgroundImage: `url(${file}` }}
                              id={file}
                              onClick={mediaFileUrlHandler}
                            ></div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="user">
              <div
                className="banner"
                style={{
                  backgroundImage: user.head.banner?.length
                    ? `url(${user.head.banner}`
                    : "url(/images/user-hero.png)",
                }}
              >
                {userInfo && userInfo._id === user._id && (
                  <label
                    id="banner"
                    className="edit-banner"
                    onClick={displayMenu}
                  >
                    <img alt="" src="/images/vrc-icons-beige-edit.png" />
                  </label>
                )}
              </div>
              <div className="container">
                <div className="user-profile">
                  <div
                    style={
                      window.innerWidth < 835
                        ? { left: "50%", transform: "translateX(-50%)" }
                        : {}
                    }
                    className="user-logo"
                  >
                    <span
                      className="logo"
                      style={{
                        backgroundImage: `url(${
                          user.head.avatar?.length
                            ? user.head.avatar
                            : "/images/avatar-partner.png"
                        })`,
                      }}
                    ></span>
                    {userInfo && userInfo._id === user._id && (
                      <label
                        id="profile-picture"
                        className="edit-logo"
                        onClick={displayMenu}
                      >
                        <img alt="" src="/images/vrc-icons-beige-edit.png" />
                      </label>
                    )}
                  </div>
                  <div className="user-title">
                    <h2>{user.head.name}</h2>
                    <h3>
                      {user.category} | {user.city}, {user.country}
                    </h3>
                    {/* {user.tags && (
                      <div className="user-tags-showcase">
                        {user.tags.map((ele, index) => (
                          <span key={index} className="small-btn">
                            {ele}
                          </span>
                        ))}
                      </div>
                    )} */}
                  </div>
                  {userInfo && userInfo._id === user._id && (
                    <Link
                      className="btn send-message"
                      to={`/${userInfo.type}/${userInfo._id}/edit`}
                      style={{
                        position: window.innerWidth < 835 ? "relative" : "",
                      }}
                    >
                      edit profile
                    </Link>
                  )}
                  {userInfo && userInfo._id !== user._id && (
                    <>
                      <div
                        className="popup"
                        style={
                          messageDisplayWindow === true
                            ? {
                                display: "flex",
                              }
                            : messageDisplayWindow === false
                            ? {
                                display: "none",
                              }
                            : ""
                        }
                      >
                        <div className="overlay"></div>
                        <div className="container">
                          <div className="popup-content">
                            <textarea
                              placeholder="write your message here"
                              onChange={messageHandler}
                              value={message}
                            ></textarea>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "50px",
                                gap: "10px",
                              }}
                            >
                              <button
                                className="btn message-btn"
                                onClick={addMessage}
                              >
                                send
                              </button>
                              <button
                                className="btn message-btn"
                                onClick={hideMessageMenu}
                              >
                                cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn send-message"
                        onClick={displayMessageMenu}
                        style={
                          window.innerWidth < 835
                            ? {
                                position: "relative",
                              }
                            : {}
                        }
                      >
                        send message
                      </button>
                    </>
                  )}
                </div>
              </div>
              {userInfo && user._id === userInfo._id ? (
                <>
                  <div className="land-acknowledgment">
                    <img src="/images/section-generic.png" alt="" />
                    <div className="container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          position: "relative",
                          gap: "20px",
                          padding: "40px 0",
                        }}
                      >
                        {user.landAcknowledgment && (
                          <p style={{ textAlign: "center" }}>
                            {user.landAcknowledgment}
                          </p>
                        )}
                        <button className="btn" onClick={displayLAMenu}>
                          set land acknowledgment
                        </button>

                        <div
                          className="popup"
                          style={
                            LADisplayWindow === true
                              ? {
                                  display: "flex",
                                }
                              : LADisplayWindow === false
                              ? {
                                  display: "none",
                                }
                              : ""
                          }
                        >
                          <div className="overlay"></div>
                          <div className="container">
                            <div className="popup-content">
                              <textarea
                                onChange={LAHandler}
                                placeholder="write single discription"
                                value={LA}
                              />
                              <div style={{ display: "flex", gap: "20px" }}>
                                <button
                                  className="btn"
                                  onClick={addLA}
                                  style={{ marginTop: "20px" }}
                                >
                                  add
                                </button>
                                <button
                                  className="btn"
                                  onClick={hideLAMenu}
                                  style={{ marginTop: "20px" }}
                                >
                                  cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {user.landAcknowledgment && (
                    <div className="land-acknowledgment">
                      <img src="/images/section-generic.png" alt="" />
                      <div className="container">
                        <p style={{ padding: "60px 0", textAlign: "center" }}>
                          {user.landAcknowledgment}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="container">
                <div
                  className="user-details"
                  style={{
                    paddingBottom:
                      userInfo && user._id === userInfo._id ? "100px" : "",
                  }}
                >
                  <div className="user-about">
                    <h3>About {user.head.name}</h3>
                    {user &&
                    user.about &&
                    user.about?.length &&
                    user.about[0]?.length ? (
                      user.about.map((p, i) => <p key={i}>{p}</p>)
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No description available
                      </p>
                    )}
                    {user &&
                      userInfo &&
                      user.type === "partner" &&
                      user._id === userInfo._id && (
                        <div style={{ position: "relative", margin: "60px 0" }}>
                          <button className="btn" onClick={displayAboutMenu}>
                            add about
                          </button>
                          <div
                            className="popup"
                            style={
                              aboutDisplayWindow === true
                                ? {
                                    display: "flex",
                                  }
                                : aboutDisplayWindow === false
                                ? {
                                    display: "none",
                                  }
                                : ""
                            }
                          >
                            <div className="overlay"></div>
                            <div className="container">
                              <div className="popup-content">
                                <textarea
                                  onChange={aboutHandler}
                                  placeholder="write single discription"
                                  value={about}
                                />
                                <div style={{ display: "flex", gap: "20px" }}>
                                  <button
                                    className="btn"
                                    onClick={pushAbout}
                                    style={{ marginTop: "20px" }}
                                  >
                                    add
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={hideAboutMenu}
                                    style={{ marginTop: "20px" }}
                                  >
                                    cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  {user && user.type === "partner" && (
                    <>
                      <span className="user-divider"></span>
                      {user && (
                        <div className="partner-gallery">
                          <div
                            className="user-hero"
                            style={{
                              backgroundColor: "var(--var-color-vrc-grey)",
                              backgroundImage: user.head.banner?.length
                                ? `url(${user.head.banner}`
                                : "url(/images/user-hero.png)",
                            }}
                          ></div>
                          <div className="partner-thumbnails">
                            {galleryThumbnails.map((thumb, index) => (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: "var(--var-color-vrc-grey)",
                                  backgroundImage: user.gallery[index]
                                    ? `url(${user.gallery[index]}`
                                    : "url(/images/user-hero.png)",
                                }}
                                className="partner-thumbnail"
                              >
                                {userInfo && userInfo._id === user._id && (
                                  <label
                                    id={`gallery-thumbnail-${index}`}
                                    className="edit-thumbnail"
                                    onClick={displayMenu}
                                  >
                                    <img
                                      alt=""
                                      src="/images/vrc-icons-beige-edit.png"
                                    />
                                  </label>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <span className="user-divider"></span>
                      <div className="user-values">
                        <h3>About {user.head.name}'s values</h3>
                        {user &&
                        user.values &&
                        user.values?.length &&
                        user.values[0]?.length ? (
                          user.values.map((p, i) => <p key={i}>{p}</p>)
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            No values available
                          </p>
                        )}
                        <div style={{ position: "relative", margin: "60px 0" }}>
                          {user &&
                            userInfo &&
                            user.type === "partner" &&
                            user._id === userInfo._id && (
                              <>
                                <button
                                  className="btn"
                                  onClick={displayValuesMenu}
                                  style={{ top: "60px" }}
                                >
                                  add value
                                </button>
                                <div
                                  className="popup"
                                  style={
                                    valueDisplayWindow === true
                                      ? {
                                          display: "flex",
                                        }
                                      : valueDisplayWindow === false
                                      ? {
                                          display: "none",
                                        }
                                      : ""
                                  }
                                >
                                  <div className="overlay"></div>
                                  <div className="container">
                                    <div className="popup-content">
                                      <textarea
                                        onChange={valueHandler}
                                        placeholder="write single note"
                                        value={value}
                                      />
                                      <div
                                        style={{ display: "flex", gap: "20px" }}
                                      >
                                        <button
                                          className="btn"
                                          onClick={pushValue}
                                          style={{ marginTop: "20px" }}
                                        >
                                          add
                                        </button>
                                        <button
                                          className="btn"
                                          onClick={hideValuesMenu}
                                          style={{ marginTop: "20px" }}
                                        >
                                          cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                        {user && user.website && (
                          <p>
                            To learn more about {user.head.name}, visit their
                            website:{" "}
                            <a
                              href={`${
                                !user.website.includes("http") ? "https://" : ""
                              }${user.website}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {user.head.name}
                            </a>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {userInfo && (
                <div
                  className="experiences"
                  style={{
                    backgroundImage: "url(/images/section-generic.png)",
                  }}
                >
                  {loadingExp ? (
                    <Loader />
                  ) : errorExp ? (
                    <Message body={errorExp} />
                  ) : (
                    experiences && (
                      <Collection
                        collectionType="experiences"
                        items={experiences.filter(
                          (exp) => exp.partner === user._id,
                        )}
                        heading="Browse Current Experiences"
                        users={[user]}
                      />
                    )
                  )}
                </div>
              )}
              {userInfo &&
                (userInfo.type === "admin" || userInfo.type === "partner") && (
                  <>
                    <div
                      className="popup"
                      style={
                        passwordDisplayWindow === true
                          ? {
                              display: "flex",
                            }
                          : passwordDisplayWindow === false
                          ? {
                              display: "none",
                            }
                          : ""
                      }
                    >
                      <div className="overlay"></div>
                      <div className="container">
                        <div className="popup-content">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <input
                                type={passwordStatus}
                                placeholder="password *"
                                onChange={passwordHandler}
                                value={password}
                                required
                                style={{ width: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={passwordStatusHandler}
                              >
                                <img
                                  alt=""
                                  src={`/images/vrc-icons-beige-passowrd-${passwordIconStatus}.png`}
                                  style={{ width: "30px" }}
                                />
                              </button>
                            </div>
                            <div style={{ position: "relative" }}>
                              <input
                                type={confirmedPasswordStatus}
                                placeholder="confirm password *"
                                onChange={confirmedPasswordHandler}
                                value={confirmedPassword}
                                required
                                style={{ width: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={confirmedPasswordStatusHandler}
                              >
                                <img
                                  alt=""
                                  src={`/images/vrc-icons-beige-passowrd-${confirmedPasswordIconStatus}.png`}
                                  style={{ width: "30px" }}
                                />
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "50px",
                              gap: "10px",
                            }}
                          >
                            <button
                              className="btn message-btn"
                              onClick={resetPassword}
                            >
                              send
                            </button>
                            <button
                              className="btn message-btn"
                              onClick={hidePasswordMenu}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div
                        className="user-profile"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn send-message"
                          onClick={displayPasswordMenu}
                          style={{
                            position: "relative",
                            top: "auto",
                            right: "auto",
                            transform: "translateY(0)",
                          }}
                        >
                          reset password
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </>
        )
      )}
    </>
  );
};

export default PartnerScreen;
