import {
  STRIPE_PUBLISHABLE_KEY_REQUEST,
  STRIPE_PUBLISHABLE_KEY_SUCCESS,
  STRIPE_PUBLISHABLE_KEY_FAIL,
  STRIPE_PUBLISHABLE_KEY_RESET,
} from "../constants/stripeConstants";

// get stripe publishable key
export const stripeKeyReducer = (state = {}, action) => {
  switch (action.type) {
    case STRIPE_PUBLISHABLE_KEY_REQUEST:
      return {
        loading: true,
      };
    case STRIPE_PUBLISHABLE_KEY_SUCCESS:
      return {
        loading: false,
        key: action.payload,
      };
    case STRIPE_PUBLISHABLE_KEY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case STRIPE_PUBLISHABLE_KEY_RESET:
      return {};
    default:
      return state;
  }
};
