import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContactMail } from "../actions/contactMailActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const verifyEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const ContactScreen = () => {
  const dispatch = useDispatch();

  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { loading, error, contactMail } = contactMailCreate;

  const [errorMessage, setErrorMessage] = useState(null);

  const [subject, setSubject] = useState("hide");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [message, setMessage] = useState(``);

  const subjectHandler = (e) => {
    setSubject(e.target.value);
  };

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const provinceHandler = (e) => {
    setProvince(e.target.value);
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      subject !== "hide" &&
      firstName?.length &&
      lastName?.length &&
      String(email).toLowerCase().match(verifyEmail) &&
      city?.length &&
      province?.length
    ) {
      const mail = {
        subject,
        firstName,
        lastName,
        email,
        phone,
        city,
        province,
        message,
      };

      dispatch(createContactMail(mail));

      setSubject("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setCity("");
      setProvince("");
      setMessage(``);
    } else {
      if (subject === "hide") {
        setErrorMessage("Please select a subject");
      } else if (!String(email).toLowerCase().match(verifyEmail)) {
        setErrorMessage("Invalid email address");
      } else {
        setErrorMessage("*= required feilds");
      }

      setTimeout(() => {
        setErrorMessage(null);
      }, 8000);
    }
  };

  return (
    <div
      className="contact landing-generic"
      style={{
        backgroundImage: "url(./images/landing-generic.png)",
      }}
    >
      {errorMessage && <Message body={errorMessage} />}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : contactMail ? (
        <Message body={contactMail.message} type="info" />
      ) : (
        ""
      )}
      <div className="container">
        <div className="contact-content" style={{ paddingTop: "30px" }}>
          <div className="cta">
            <div className="text">
              <h1>Get in touch with the Classroom Voyager team</h1>
              <p>
                Do you have a question or comment about the platform, or want to
                learn more about how it works? We are always available to help!
                Send us a message and one of our team will get back to you
                shortly.
              </p>
            </div>
            <div className="visual">
              <img id="mail" alt="" src="/images/mail.png" />
              <img id="mail-shadow" alt="" src="/images/mail-shadow.png" />
            </div>
          </div>
          <form onSubmit={submitHandler}>
            <div className="select">
              <select onChange={subjectHandler} value={subject}>
                <option value="hide">How can we help you? *</option>
                <option value="hide" disabled>
                  -- please choose an option --
                </option>
                {[
                  "Becoming a Partner",
                  "Register as a Teacher",
                  "Help Using the Platform",
                  "Technical Support",
                  "Billing Issues",
                  "Provide Feedback",
                  "Other",
                ].map((ele, index) => (
                  <option key={index} value={ele}>
                    {ele}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              placeholder="first name *"
              onChange={firstNameHandler}
              value={firstName}
              required
            />
            <input
              type="text"
              placeholder="last name *"
              onChange={lastNameHandler}
              value={lastName}
              required
            />
            <input
              type="text"
              placeholder="email address *"
              onChange={emailHandler}
              value={email}
              required
            />
            <input
              type="text"
              placeholder="phone number (optional)"
              onChange={phoneHandler}
              value={phone}
            />
            <input
              type="text"
              placeholder="city *"
              onChange={cityHandler}
              value={city}
              required
            />
            <input
              type="text"
              placeholder="province *"
              onChange={provinceHandler}
              value={province}
              required
            />
            <textarea
              id="contact-textarea"
              name="contact-textarea"
              rows="8"
              cols="50"
              placeholder="write your message here *"
              onChange={messageHandler}
              value={message}
              required
            ></textarea>
            <div>
              <button className="btn" onClick={submitHandler}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
