import {
  WS_GET_URL_REQUEST,
  WS_GET_URL_SUCCESS,
  WS_GET_URL_FAIL,
  WS_GET_URL_RESET,
  CONV_GET_CONVERSATIONS_REQUEST,
  CONV_GET_CONVERSATIONS_SUCCESS,
  CONV_GET_CONVERSATIONS_FAIL,
  MSG_GET_MESSAGES_REQUEST,
  MSG_GET_MESSAGES_SUCCESS,
  MSG_GET_MESSAGES_FAIL,
  MSG_ADD_NEW_REQUEST,
  MSG_ADD_NEW_SUCCESS,
  MSG_ADD_NEW_FAIL,
  CONV_CREATE_NEW_REQUEST,
  CONV_CREATE_NEW_SUCCESS,
  CONV_CREATE_NEW_FAIL,
  CONV_CREATE_NEW_RESET,
  MSG_ADD_NEW_RESET,
  CONV_GET_CONVERSATIONS_RESET,
  MSG_GET_MESSAGES_RESET,
} from "../constants/connectConstants";

// get socket connected
export const connectSocketReducer = (state = {}, action) => {
  switch (action.type) {
    case WS_GET_URL_REQUEST:
      return {
        loading: true,
      };
    case WS_GET_URL_SUCCESS:
      return {
        loading: false,
        url: action.payload,
      };
    case WS_GET_URL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WS_GET_URL_RESET:
      return {};
    default:
      return state;
  }
};

// all conversations
export const conversationListReducer = (
  state = { loading: false, conversations: null },
  action,
) => {
  switch (action.type) {
    case CONV_GET_CONVERSATIONS_REQUEST:
      return {
        loading: true,
        conversations: [],
      };
    case CONV_GET_CONVERSATIONS_SUCCESS:
      return {
        loading: false,
        conversations: action.payload,
      };
    case CONV_GET_CONVERSATIONS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONV_GET_CONVERSATIONS_RESET:
      return {
        loading: false,
        conversations: null,
      };
    default:
      return state;
  }
};

// create new conversation
export const createConversationReducer = (
  state = { loading: false, conversation: null },
  action,
) => {
  switch (action.type) {
    case CONV_CREATE_NEW_REQUEST:
      return {
        loading: true,
      };
    case CONV_CREATE_NEW_SUCCESS:
      return {
        loading: false,
        conversation: action.payload,
      };
    case CONV_CREATE_NEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CONV_CREATE_NEW_RESET:
      return {
        loading: false,
        conversation: null,
      };
    default:
      return state;
  }
};

// all messages by conversation id
export const messageListReducer = (
  state = { loading: false, messages: null },
  action,
) => {
  switch (action.type) {
    case MSG_GET_MESSAGES_REQUEST:
      return {
        loading: true,
        messages: [],
      };
    case MSG_GET_MESSAGES_SUCCESS:
      return {
        loading: false,
        messages: action.payload,
      };
    case MSG_GET_MESSAGES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MSG_GET_MESSAGES_RESET:
      return {
        loading: false,
        messages: null,
      };
    default:
      return state;
  }
};

// add new message
export const newMessageReducer = (
  state = { loading: false, message: null },
  action,
) => {
  switch (action.type) {
    case MSG_ADD_NEW_REQUEST:
      return {
        loading: true,
      };
    case MSG_ADD_NEW_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };
    case MSG_ADD_NEW_RESET:
      return {
        loading: false,
        message: null,
      };
    case MSG_ADD_NEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
