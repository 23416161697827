import React from "react";
import Landing2 from "../components/Landing2";

const ThePlatformScreen = () => {
  return (
    <>
      <Landing2
        bg="connecting"
        height="1080px"
        position="40%"
        horizontal="flex-start"
        vertical="center"
        heading="Connecting the Classroom to the World"
        hWidth="650px"
        copy="We bring the cultural and natural diversity of the world directly into the classroom."
        cWidth="800px"
        color="beige"
        cColor="beige"
        shadowColor="grey"
      />
      <div
        style={{
          backgroundImage: "url(./images/landing-generic.png)",
          backgroundRepeat: "norepeat",
          backgroundSize: "cover",
          height: "fit-content",
        }}
      >
        <Landing2
          bg="new-way2"
          position="25%"
          height="500px"
          horizontal="flex-end"
          vertical="center"
          heading="A New Way of Teaching Has Arrived"
          hWidth="550px"
          copy="Classroom Voyager works with cultural institutions, environmental organizations, Indigenous communities, and experts in science and the humanities, to create a hub for teachers to bring their curriculum to life. No teacher can be an expert in every curriculum theme. Our network of experts helps classes learn directly from the source."
          cWidth="550px"
          color="red"
          cColor="grey"
        />
        <Landing2
          bg="direct"
          position="30%"
          height="500px"
          horizontal="flex-start"
          vertical="center"
          heading="A Direct Link to The Curriculum"
          hWidth="500px"
          copy="Our education team connects every experience to the specific Prescribed Learning Outcomes outlined in the Provincial/Territorial curriculum guides. No more need for teachers to spend hours looking for the right resource, our team does the work and with a simple click of the curriculum the relevant information pops up."
          cWidth="700px"
          color="beige"
          cColor="beige"
          shadowColor="grey"
        />
        <Landing2
          bg="live-streamed"
          position="25%"
          height="500px"
          horizontal="flex-end"
          vertical="center"
          heading="Live-streamed or In-person"
          hWidth="350px"
          copy="No matter where the classroom is, the world is open to them. Our platform lists both in-person and online experiences to ensure students experience a well-rounded curriculum by being able to visit museums, aquariums, science centres, and heritage sites regardless of how close they are to their community."
          cWidth="550px"
          color="red"
          cColor="beige"
          shadowColor="grey"
        />
      </div>
    </>
  );
};

export default ThePlatformScreen;
