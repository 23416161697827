import grades from "./grades";
import experienceSubjects from "./experienceSubjects";

const curriculumArtsHandles = [
  "Exploring & Creating",
  "Reasoning & Reflecting",
  "Communicating & Documenting",
  "Connecting & Expanding",
];

const curriculumBiologyHandles = [
  "Questioning & Predicting",
  "Planning & Conducting",
  "Processing & Analyzing Information",
  "Evaluating",
  "Applying & Innovating",
  "Innovating",
];

const curriculumEarthAndSpaceHandles = [
  "Questioning & Predicting",
  "Planning & Conducting",
  "Processing & Analyzing Information",
  "Evaluating",
  "Applying & Innovating",
  "Innovating",
];

const curriculumSocialHandles = [
  "Inquiry",
  "Significance",
  "Evidence",
  "Continuity & Change",
  "Cause & Consequences",
  "Perspective",
  "Ethical Judgement",
];

const curriculumCompetencies = [
  {
    subject: experienceSubjects[0],
    data: [
      {
        grade: grades[0],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Learn about art: its different parts, the way it is done, and the tools,  technology, techniques and movements used to do it; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; find creative new ways to express yourself and your community through art.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              " Watch and tell about the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools and techniques; learn ways to do a mix of different kinds of art in ways that help you be creative, develop and stay motivated; think about creative processes and how they are connected to other experiences.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Look into ways that the arts use symbols; use creative work to tell others stories and about things you feel, think, see and experience; tell others about works of art and your reaction to them; use a mix of ways to experience creative works and show and tell others about them; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[1],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Learn about art: its different parts, the way it is done, and the tools,  technology, techniques and movements used to do it; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; find creative new ways to express yourself and your community through art.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Watch and tell about the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools and techniques; learn ways to do a mix of different kinds of art in ways that help you be creative, develop and stay motivated; think about creative processes and how they are connected to other experiences.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Look into ways that the arts use symbols and what they mean; use creative work to tell others stories and about things you feel, think, see and experience; tell others about works of art and your reaction to them; use a mix of ways to experience creative works and show and tell others about them; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[2],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Learn about art: its different parts, the way it is done, and the tools,  technology, techniques and movements used to do it; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; find creative new ways to express yourself, your community and culture through art.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Watch and tell about the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools and techniques; learn ways to do a mix of different kinds of art and improve your artistic skills; think about creative processes and how they are connected to other experiences.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Decide what symbols in artistic work might mean, and how they are used; use creative work to tell others stories and about things you feel, think, see and experience; tell others about works of art and your reaction to them; use a mix of ways to experience creative works and show and tell others about them; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[3],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Select a mix of tool and materials, technologies and techniques, processes and movements to make art; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; use the arts to look into ideas about belonging, culture, identity and place; learn about the ways different cultures and societies are related through the arts.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Watch, listen, ask, guess and tell about the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools and techniques; build your skills (ideas, processes and techniques) in a mix of different types of art; think about creative processes and how they are connected to your own experiences; use the tools you have from other subjects to plan, execute and look for meaning in works of art.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Use the skills, processes and ideas you’ve learned in new contexts; decide and explain what symbols in artistic work might mean, and how they are used; use creativity to share your experiences and the things you’re feeling and thinking about; tell about some (visual or performing) art you experienced—what you thought about it, and some  constructive feedback; use a mix of ways to experience creative works and show and tell others about them; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[4],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Intentionally select a mix of tool and materials, technologies and techniques, processes and movements, elements and environments that make sense for the art you are creating; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; use the arts to look into ideas about belonging, culture, identity and place; learn about the ways different cultures and societies are related through the arts.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Watch, listen, ask, guess and tell about the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools, techniques and environments to make art and share ideas; create and fine-tune your ideas, abilities and processes in a mix of different kinds of art to make it better; think about creative processes and how they are connected to other experiences; use the tools you have from other subjects to plan, execute and look for meaning in works of art.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Find ways to use the things you’ve learned/tools you’ve gained in new ways, for different reasons and different people; decide and explain what symbols in artistic work might mean, and use them in your own work; be creative in finding new ways to show the things you are thinking, feeling and experiencing; tell about some art you experienced—what you thought about it, and what the artist might have meant; use a mix of ways to experience creative works, document them and present them to others; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[5],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Intentionally select a mix of tool and materials, technologies and techniques, processes and movements, elements and environments that work to express your intended meaning through art; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; use creative expression to look into ideas about belonging, culture, identity and place; learn about the ways different cultures and societies are related through the arts.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Watch, listen, ask, guess and tell about the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools, techniques and environments to make art and share ideas; create and fine-tune your ideas, abilities and processes in a mix of different kinds of art to make it better; think about creative processes on your own and with others, and how they are connected to other experiences; use the tools you have from other subjects to plan, execute and look for meaning in works of art; look at the ways arts and the rest of the world are related.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Find ways to use the things you’ve learned/tools you’ve gained in new ways, for different reasons and different people; decide and explain what symbols and elements in artistic work might mean, and use them in your own work; use art to share the things you are thinking, feeling and experiencing; tell about some art you experienced—what you thought about it, and what the artist might have meant; use a mix of ways to experience creative works, document them and present them to others; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[6],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Choose, use, mix and match different artistic tools and techniques, processes and movements, materials and technologies and environments; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; use the arts to look into ways that the concepts of belonging, culture, identity, place, relationships and society are related; show that you understand and grasp the importance of how context (culture, history, society, environment and personality) affects art.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Explore, analyze and explain the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools, techniques and environments to make art and share ideas; create and fine-tune methods, ideas and technical skills in a mix of kinds of art to make your creations better; think about specific works of art and the methods for creating them to gain understanding about what the artist wanted to achieve; use your knowledge and skills from other areas to help you understand creative works; look at the ways arts and the rest of the world are related.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Find ways to use the things you’ve learned/tools you’ve gained in new ways, for different reasons and different people; decide and explain what symbols and elements in artistic work might mean, and use them in your own work; share your thoughts, feelings and experiences, taking creative risks to do so; use art to share the things you are thinking, feeling and experiencing; tell about some art you experienced—what you thought about it, and what the artist might have meant; use a mix of different approaches to document, experience and share creative works; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[7],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Choose and use different artistic tools and techniques, movements and environments, materials and technologies, mixing and matching elements, processes and principles to make art; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; use the arts to look into ways that the concepts of belonging, culture, identity, place, relationships and society are related; show that you understand how the arts fit into culture, environment, history, society and your own  life.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Explore, analyze and explain the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools, techniques and environments to make art and share ideas; create and fine-tune methods, ideas and technical skills in a mix of kinds of art to make your creations better; think about specific works of art and the methods for creating them to gain understanding about what the artist wanted to achieve; use your knowledge and skills from other areas to help you understand creative works; look at the ways arts and the rest of the world are related.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Find ways to use the things you’ve learned/tools you’ve gained in new ways, for different reasons and different people; decide and explain what symbols and elements in artistic work might mean, and use them in your own work; share your thoughts, feelings and experiences, taking creative risks to do so; use art to share the things you are thinking, feeling and experiencing; tell about some art you experienced—what you thought about it, and what the artist might have meant; use a mix of different approaches to choreograph, document, experience and share creative works; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[8],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Choose and use different artistic tools and techniques, movements and environments, materials and technologies, mixing and matching elements, processes and principles to make art; make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; look into ways that people’s cultures, identities, locations and societies are related to how they create and experience art; show that you understand how the arts fit into culture, environment, history, society and your own life.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Explain and analyze the way artists (actors, dancers, musicians and visual artists) use processes, materials, movements, technologies, tools, techniques and environments to make art and share ideas; create, fine-tune and evaluate methods, ideas and technical skills in a mix of kinds of art to make your creations better; think about specific works of art and the methods for creating them to gain understanding about what the artist wanted to achieve and why; use your knowledge and skills from other areas to help you understand creative works; use what you know about the world to react to art.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Find ways to use the things you’ve learned/tools you’ve gained in new ways, for different reasons and different people; decide and explain what symbols and elements in artistic work might mean, and use them in your own work; share your thoughts, feelings and experiences, taking creative risks to do so; tell about some art you experienced—what you thought about it, and what the artist might have meant; use a mix of different approaches to choreograph, document, experience and share creative works; gain understanding of and respond to current issues (including environmental ones) around the world through the arts; show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
          {
            handle: curriculumArtsHandles[3],
            description: "N/A",
          },
        ],
      },
      {
        grade: grades[9],
        points: [
          {
            handle: curriculumArtsHandles[0],
            description:
              "Make art together and by yourself, getting ideas from asking, testing, intentional play and your own imagination; mix and match the parts, processes and rules of art, using them to play with tools, techniques and materials in different environments; show that you understand how the arts fit into culture, environment, history, society and your own  life; look into ways that people’s cultures, identities, locations and societies are related to how they experience and find a sense of belonging through art; choose and mix different parts and rules of the arts to elicit a specific feel, effect or meaning.",
          },
          {
            handle: curriculumArtsHandles[1],
            description:
              "Explain and analyze the way artists use processes, materials, movements, technologies, tools, and environments to make art and share ideas; create, fine-tune, evaluate and record methods, ideas and technical skills in a mix of kinds of art to make your creations better.",
          },
          {
            handle: curriculumArtsHandles[2],
            description:
              "Use and tweak the things you’ve learned about art and how it is made depending on the context,  your audience and the purpose of the piece; create, analyze and build on ideas by using different elements, symbols and images; rework, fine-tune, interpret and catalog creative works and experiences to improve their presentation/ performance.",
          },
          {
            handle: curriculumArtsHandles[3],
            description:
              "Think about art and the process of making it, and how it is connected to your own learning and life experiences; experience and share your thoughts, feelings and ideas, taking creative risks to do so; show respect for yourself, the people around you and your audience; work with and support others through the creative process; make art that means something to you and show your understanding of how it fits into other contexts (culture, environment, history, society); show how your art is improving and/or how you’re using the things you’re learning about art.",
          },
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[1],
    data: [
      {
        grade: grades[0],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious and excited about the world; make observations about things and happenings in contexts you are familiar with; make basic inquiries about things and happenings you are familiar with.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Use your senses to observe and explore the world; use materials safely; measure things (not in standard units).",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; see how spoken and written stories from First Peoples are a way of sharing knowledge with others; tell about what you see; show your thoughts and discoveries by making basic charts and pictographs.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description: "N/A",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "Find personal ways to help look after yourself and your family as well as your school and classroom; use the things you’ve learned in new contexts; invent or fine-tune ideas when solving a problem.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Tell others about your thoughts and the things you notice; think and share about how you experience a place.",
          },
        ],
      },
      {
        grade: grades[1],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious and excited about the world; make observations about things and happenings in contexts you are familiar with; make basic inquiries about things and happenings you are familiar with; guess what might happen (with things and happenings you are familiar with).",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Observe the world and write down the things you discover; use materials safely to check your ideas and thoughts about what might happen; measure things (in informal or nonstandard units) and write down the results.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; see how spoken and written stories from First Peoples are a way of sharing knowledge with others; put data and information into categories using visual representations and provided tables; discuss similarities and differences between what you thought would happen and what did; spot basic patterns and ways things are connected.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Look at your observations and how they are similar to and different from others; think about some effects that the things you do have on the environment.",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "Find personal ways to help look after yourself and your family as well as your school and classroom; use the things you’ve learned in new contexts; invent or fine-tune ideas when solving a problem.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Share your thoughts and the things you notice with others, either by talking, writing, making art or acting it out; think and share about how you experience a place.",
          },
        ],
      },
      {
        grade: grades[2],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious and excited about the world; make observations about things and happenings in contexts you are familiar with; make basic inquiries about things and happenings you are familiar with; guess what might happen (with things and happenings you are familiar with).",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Observe the world and write down the things you discover; use materials safely to check your ideas and thoughts about what might happen; measure things (in informal or nonstandard units) and write down the results.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; see how spoken and written stories from First Peoples are a way of sharing knowledge with others; put data and information into categories using visual representations and provided tables; discuss similarities and differences between what you thought would happen and what did; spot basic patterns and ways things are connected.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Look at your observations and how they are similar to and different from others; think about some effects that the things you do have on the environment.",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "Find personal ways to help look after yourself and your family as well as your school and classroom; use the things you’ve learned in new contexts; invent or fine-tune ideas when solving a problem.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Share your thoughts and the things you notice with others, either by talking, writing, making art or acting it out; think and share about how you experience a place.",
          },
        ],
      },
      {
        grade: grades[3],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious about the natural world; make observations about things and happenings in contexts you are familiar with; pick some questions about things/happenings you know about that you can use science to investigate; guess what might happen based on what you already know.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Think of methods for finding answers to the questions you have, and how you would implement them; think about ethics and doing the right thing when deciding on the way to do an experiment; use the right tools and technology to safely observe and measure, using formal measurements; notice things about your surroundings and the things around you, both living and nonliving; gather basic data.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; put data and information into categories using visual representations and provided tables; illustrate data and display basic patterns and trends using bar graphs, tables or other methods; look for differences between what you predicted and what happened, and suggest why they might be.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Make guesses using what you already know and have learned; think about whether an investigation was an effective/accurate test; show that you understand and are interested in evidence; think about some effects that the things you and others do have on the environment.",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "By yourself or with others, help look after yourself and others as well as your school and neighbourhood; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Show and tell about your ideas and the things you find in a mix of ways (e.g. visual representations or basic reports), using digital technology if it makes sense to do so; think about and share how you or you and others experience a place.",
          },
        ],
      },
      {
        grade: grades[4],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious about the natural world; make observations about things and happenings in contexts you are familiar with; pick some questions about things/happenings you know about that you can use science to investigate; guess what might happen based on what you already know.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Think of methods for finding answers to the questions you have, and how you would implement them; think about ethics and doing the right thing when deciding on the way to do an experiment; use the right tools and technology to safely observe and measure, using formal measurements; notice things about your surroundings and the things around you, both living and nonliving; gather basic data.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; put data and information into categories using visual representations and provided tables; illustrate data and display basic patterns and trends using bar graphs, tables or other methods; look for differences between what you predicted and what happened, and suggest why they might be.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Make guesses using what you already know and have learned; think about whether an investigation was an effective/accurate test; show that you understand and are interested in evidence; think about some effects that the things you and others do have on the environment.",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "By yourself or with others, help look after yourself and others as well as your school and neighbourhood; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Show and tell about your ideas and the things you find in a mix of ways (e.g. visual representations or basic reports), using digital technology if it makes sense to do so; think about and share how you or you and others experience a place.",
          },
        ],
      },
      {
        grade: grades[5],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things in contexts you are and aren’t familiar with; pick questions and issues to investigate using science; guess what might happen.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "With help, outline ways to get answers to questions you have or fix problems you’ve spotted; pick a variable to change and measure that means you’re doing a fair test; pick what data makes sense to find answers to your questions; use the right tools and digital technologies to observe, measure and record data; safely use tools and materials, pointing out possible risks.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; show patterns and ways data is related by making and using a mix of tables, graphs and digital technologies; spot patterns in data and ways things are connected; look for differences between what you predicted and what happened, and suggest why they might be; show that you’re willing to think about new ideas and other possibilities.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Think about whether an investigation was an effective/accurate test; point out ways that errors might have been introduced; think of ways you could make your investigation methods better; look at secondary sources and spot some assumptions they are making; show that you know what evidence is and grasp its importance; outline possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "By yourself or with others, help look after yourself, others and your community; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Share your thoughts, methods and reasoning in a mix of different ways; think about and share how you, you and others, or others only experience a place.",
          },
        ],
      },
      {
        grade: grades[6],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things in contexts you are and aren’t familiar with; pick questions and issues to investigate using science; guess what might happen.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "With help, outline ways to get answers to questions you have or fix problems you’ve spotted; pick a variable to change and measure that means you’re doing a fair test; pick what data makes sense to find answers to your questions; use the right tools and digital technologies to observe, measure and record data; safely use tools and materials, pointing out possible risks.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; show patterns and ways data is related by making and using a mix of tables, graphs and digital technologies; spot patterns in data and ways things are connected; look for differences between what you predicted and what happened, and suggest why they might be; show that you’re willing to think about new ideas and other possibilities.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Think about whether an investigation was an effective/accurate test; point out ways that errors might have been introduced; think of ways you could make your investigation methods better; look at secondary sources and spot some assumptions they are making; show that you know what evidence is and grasp its importance; outline possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "By yourself or with others, help look after yourself, others and your community; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Share your thoughts, methods and reasoning in a mix of different ways; think about and share how you, you and others, or others only experience a place.",
          },
        ],
      },
      {
        grade: grades[7],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things that help you find questions you have about the world around you; pick questions and issues to investigate using science; use your questions to generate different hypotheses; guess what your inquiry might find out.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Work with others to plan different kinds of investigations (e.g. field work, experiments) to answer queries or find solutions to problems; use fair tests to measure and control dependent and independent variables; use the right tools and digital technologies to precisely and accurately observe, measure and record qualitative and quantitative data; use the right SI units and do basic unit conversions; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; show patterns and ways data is related by making and using a mix of tables, graphs, keys, models and digital technologies; look for patterns in your data and data from other sources, and ways they are connected; spot relationships and make inferences based on your scientific understanding.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Look back on the ways you conducted your investigation, including whether your control of dependent and independent variables was effective, and how good the data you got was; point out ways errors might have crept in and suggest ways you can make your methods better next time; show you can pick up on assumptions and bias, both in yourself and in other sources; show that you know what qualitative and quantitative evidence are and grasp their importance; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; think about possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Share the things you think and solutions find, using scientific language and digital technology when it makes sense to do so; share and look back on a mix of different ways people experience and think about place.",
          },
        ],
      },
      {
        grade: grades[8],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things that help you find questions you have about the world around you; pick questions and issues to investigate using science; use your questions to generate different hypotheses; guess what your inquiry might find out.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Work with others to plan different kinds of investigations (e.g. field work, experiments) to answer queries or find solutions to problems; use fair tests to measure and control dependent and independent variables; use the right tools and digital technologies to precisely and accurately observe, measure and record qualitative and quantitative data; use the right SI units and do basic unit conversions; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; show patterns and ways data is related by making and using a mix of tables, graphs, keys, models and digital technologies; look for patterns in your data and data from other sources, and ways they are connected; spot relationships and make inferences based on your scientific understanding.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Look back on the ways you conducted your investigation, including whether your control of dependent and independent variables was effective, and how good the data you got was; point out ways errors might have crept in and suggest ways you can make your methods better next time; show you can pick up on assumptions and bias, both in yourself and in other sources; show that you know what qualitative and quantitative evidence are and grasp their importance; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; think about possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Share the things you think and solutions find, using scientific language and digital technology when it makes sense to do so; share and look back on a mix of different ways people experience and think about place.",
          },
        ],
      },
      {
        grade: grades[9],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you have a lasting intellectual curiosity about a specific issue in science or your own interests; observe things that help you find (sometimes more complex) questions you have about the world around you; make a few guesses about different things that might happen.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Work alone or with others to plan, choose and carry out different kinds of investigations (e.g. field work, experiments) to gather reliable qualitative and quantitative data; consider the possible risks of your and others’ suggested methods (cultural, ethical, environmental); choose and use the right equipment and digital technology to gather and document data; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; look for and evaluate the ways data is connected, including patterns and trends; explain how the dependent and independent variables are related; spot any inconsistencies; make, evaluate and interpret diagrams, models and graphs; interpolate and extrapolate; come to conclusions that make sense given the evidence and your knowledge of scientific concepts; evaluate cause-and-effect relationships.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Look back on the way you conducted your investigation, including spotting places you aren’t sure about or where mistakes could have been introduced, and other ways of explaining the data you got; detail ways you can make your investigation methods and the quality of your data better; decide how useful a model is in the context it is used (whether it is valid, any limitations); show you can spot assumptions, question whether the information you’re given is accurate and spot bias in your own and others’ work; think about how knowledge has evolved as new tools and technologies were created; see the connections between exploring science and working in the sciences; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; outline possible implications of your and others’ findings (for ethics, the environment and society); consider secondary sources of information. How accurate is their information? Were the tactics they used to solve problems effective?.",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems; make inquiries that help find ways to solve local and/or global problems; think about how scientists are involved in innovation.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Create theoretical models (either physical or mental) that describe something that happens; craft arguments based on evidence, using the correct scientific language/conventions/ representations, that have a specific purpose and audience; share scientific claims, ideas and information as well as possibly what should be done next; share and look back on a mix of different ways people experience and think about place, and how that affects their worldview.",
          },
        ],
      },
      {
        grade: grades[10],
        points: [
          {
            handle: curriculumBiologyHandles[0],
            description:
              "Show that you have a lasting intellectual curiosity about a specific issue in science or your own interests; observe things that help you find (sometimes more complex) questions you have about the world around you; make a few guesses about different things that might happen.",
          },
          {
            handle: curriculumBiologyHandles[1],
            description:
              "Work alone or with others to plan, choose and carry out different kinds of investigations (e.g. field work, experiments) to gather reliable qualitative and quantitative data; consider the possible risks of your and others’ suggested methods (cultural, ethical, environmental); choose and use the right equipment and digital technology to gather and document data; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumBiologyHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; look for and evaluate the ways data is connected, including patterns and trends; explain how the dependent and independent variables are related; spot any inconsistencies; make, evaluate and interpret diagrams, models and graphs; interpolate and extrapolate; come to conclusions that make sense given the evidence and your knowledge of scientific concepts; evaluate cause-and-effect relationships.",
          },
          {
            handle: curriculumBiologyHandles[3],
            description:
              "Look back on the way you conducted your investigation, including spotting places you aren’t sure about or where mistakes could have been introduced, and other ways of explaining the data you got; detail ways you can make your investigation methods and the quality of your data better; decide how useful a model is in the context it is used (whether it is valid, any limitations); show you can spot assumptions, question whether the information you’re given is accurate and spot bias in your own and others’ work; think about how knowledge has evolved as new tools and technologies were created; see the connections between exploring science and working in the sciences; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; outline possible implications of your and others’ findings (for ethics, the environment and society); consider secondary sources of information. How accurate is their information? Were the tactics they used to solve problems effective?.",
          },
          {
            handle: curriculumBiologyHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems; make inquiries that help find ways to solve local and/or global problems; think about how scientists are involved in innovation.",
          },
          {
            handle: curriculumBiologyHandles[5],
            description:
              "Create theoretical models (either physical or mental) that describe something that happens; craft arguments based on evidence, using the correct scientific language/conventions/ representations, that have a specific purpose and audience; share scientific claims, ideas and information as well as possibly what should be done next; share and look back on a mix of different ways people experience and think about place, and how that affects their worldview.",
          },
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[2],
    data: [
      {
        grade: grades[0],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious and excited about the world; make observations about things and happenings in contexts you are familiar with; make basic inquiries about things and happenings you are familiar with.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Use your senses to observe and explore the world; use materials safely; measure things (not in standard units).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; see how spoken and written stories from First Peoples are a way of sharing knowledge with others; tell about what you see; show your thoughts and discoveries by making basic charts and pictographs.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description: "N/A",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "Find personal ways to help look after yourself and your family as well as your school and classroom; use the things you’ve learned in new contexts; invent or fine-tune ideas when solving a problem.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Tell others about your thoughts and the things you notice; think and share about how you experience a place.",
          },
        ],
      },
      {
        grade: grades[1],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious and excited about the world; make observations about things and happenings in contexts you are familiar with; make basic inquiries about things and happenings you are familiar with; guess what might happen (with things and happenings you are familiar with).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Observe the world and write down the things you discover; use materials safely to check your ideas and thoughts about what might happen; measure things (in informal or nonstandard units) and write down the results.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; see how spoken and written stories from First Peoples are a way of sharing knowledge with others; put data and information into categories using visual representations and provided tables; discuss similarities and differences between what you thought would happen and what did; spot basic patterns and ways things are connected.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Look at your observations and how they are similar to and different from others; think about some effects that the things you do have on the environment.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "Find personal ways to help look after yourself and your family as well as your school and classroom; use the things you’ve learned in new contexts; invent or fine-tune ideas when solving a problem.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Share your thoughts and the things you notice with others, either by talking, writing, making art or acting it out; think and share about how you experience a place.",
          },
        ],
      },
      {
        grade: grades[2],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious and excited about the world; make observations about things and happenings in contexts you are familiar with; make basic inquiries about things and happenings you are familiar with; guess what might happen (with things and happenings you are familiar with).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Observe the world and write down the things you discover; use materials safely to check your ideas and thoughts about what might happen; measure things (in informal or nonstandard units) and write down the results.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; see how spoken and written stories from First Peoples are a way of sharing knowledge with others; put data and information into categories using visual representations and provided tables; discuss similarities and differences between what you thought would happen and what did; spot basic patterns and ways things are connected.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Look at your observations and how they are similar to and different from others; think about some effects that the things you do have on the environment.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "Find personal ways to help look after yourself and your family as well as your school and classroom; use the things you’ve learned in new contexts; invent or fine-tune ideas when solving a problem.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Share your thoughts and the things you notice with others, either by talking, writing, making art or acting it out; think and share about how you experience a place.",
          },
        ],
      },
      {
        grade: grades[3],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious about the natural world; make observations about things and happenings in contexts you are familiar with; pick some questions about things/happenings you know about that you can use science to investigate; guess what might happen based on what you already know.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Think of methods for finding answers to the questions you have, and how you would implement them; think about ethics and doing the right thing when deciding on the way to do an experiment; use the right tools and technology to safely observe and measure, using formal measurements; notice things about your surroundings and the things around you, both living and nonliving; gather basic data.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; put data and information into categories using visual representations and provided tables; illustrate data and display basic patterns and trends using bar graphs, tables or other methods; look for differences between what you predicted and what happened, and suggest why they might be.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Make guesses using what you already know and have learned; think about whether an investigation was an effective/accurate test; show that you understand and are interested in evidence; think about some effects that the things you and others do have on the environment.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "By yourself or with others, help look after yourself and others as well as your school and neighbourhood; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Show and tell about your ideas and the things you find in a mix of ways (e.g. visual representations or basic reports), using digital technology if it makes sense to do so; think about and share how you or you and others experience a place.",
          },
        ],
      },
      {
        grade: grades[4],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious about the natural world; make observations about things and happenings in contexts you are familiar with; pick some questions about things/happenings you know about that you can use science to investigate; guess what might happen based on what you already know.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Think of methods for finding answers to the questions you have, and how you would implement them; think about ethics and doing the right thing when deciding on the way to do an experiment; use the right tools and technology to safely observe and measure, using formal measurements; notice things about your surroundings and the things around you, both living and nonliving; gather basic data.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; put data and information into categories using visual representations and provided tables; illustrate data and display basic patterns and trends using bar graphs, tables or other methods; look for differences between what you predicted and what happened, and suggest why they might be.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Make guesses using what you already know and have learned; think about whether an investigation was an effective/accurate test; show that you understand and are interested in evidence; think about some effects that the things you and others do have on the environment.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "By yourself or with others, help look after yourself and others as well as your school and neighbourhood; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Show and tell about your ideas and the things you find in a mix of ways (e.g. visual representations or basic reports), using digital technology if it makes sense to do so; think about and share how you or you and others experience a place.",
          },
        ],
      },
      {
        grade: grades[5],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things in contexts you are and aren’t familiar with; pick questions and issues to investigate using science; guess what might happen.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "With help, outline ways to get answers to questions you have or fix problems you’ve spotted; pick a variable to change and measure that means you’re doing a fair test; pick what data makes sense to find answers to your questions; use the right tools and digital technologies to observe, measure and record data; safely use tools and materials, pointing out possible risks.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; show patterns and ways data is related by making and using a mix of tables, graphs and digital technologies; spot patterns in data and ways things are connected; look for differences between what you predicted and what happened, and suggest why they might be; show that you’re willing to think about new ideas and other possibilities.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Think about whether an investigation was an effective/accurate test; point out ways that errors might have been introduced; think of ways you could make your investigation methods better; look at secondary sources and spot some assumptions they are making; show that you know what evidence is and grasp its importance; outline possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "By yourself or with others, help look after yourself, others and your community; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Share your thoughts, methods and reasoning in a mix of different ways; think about and share how you, you and others, or others only experience a place.",
          },
        ],
      },
      {
        grade: grades[6],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things in contexts you are and aren’t familiar with; pick questions and issues to investigate using science; guess what might happen.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "With help, outline ways to get answers to questions you have or fix problems you’ve spotted; pick a variable to change and measure that means you’re doing a fair test; pick what data makes sense to find answers to your questions; use the right tools and digital technologies to observe, measure and record data; safely use tools and materials, pointing out possible risks.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; consider the perspectives and knowledge of First People as a source of information; show patterns and ways data is related by making and using a mix of tables, graphs and digital technologies; spot patterns in data and ways things are connected; look for differences between what you predicted and what happened, and suggest why they might be; show that you’re willing to think about new ideas and other possibilities.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Think about whether an investigation was an effective/accurate test; point out ways that errors might have been introduced; think of ways you could make your investigation methods better; look at secondary sources and spot some assumptions they are making; show that you know what evidence is and grasp its importance; outline possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "By yourself or with others, help look after yourself, others and your community; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Share your thoughts, methods and reasoning in a mix of different ways; think about and share how you, you and others, or others only experience a place.",
          },
        ],
      },
      {
        grade: grades[7],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things that help you find questions you have about the world around you; pick questions and issues to investigate using science; use your questions to generate different hypotheses; guess what your inquiry might find out.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Work with others to plan different kinds of investigations (e.g. field work, experiments) to answer queries or find solutions to problems; use fair tests to measure and control dependent and independent variables; use the right tools and digital technologies to precisely and accurately observe, measure and record qualitative and quantitative data; use the right SI units and do basic unit conversions; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; show patterns and ways data is related by making and using a mix of tables, graphs, keys, models and digital technologies; look for patterns in your data and data from other sources, and ways they are connected; spot relationships and make inferences based on your scientific understanding.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Look back on the ways you conducted your investigation, including whether your control of dependent and independent variables was effective, and how good the data you got was; point out ways errors might have crept in and suggest ways you can make your methods better next time; show you can pick up on assumptions and bias, both in yourself and in other sources; show that you know what qualitative and quantitative evidence are and grasp their importance; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; think about possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Share the things you think and solutions find, using scientific language and digital technology when it makes sense to do so; share and look back on a mix of different ways people experience and think about place.",
          },
        ],
      },
      {
        grade: grades[8],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you’re curious about a specific issue in science or your own interests; observe things that help you find questions you have about the world around you; pick questions and issues to investigate using science; use your questions to generate different hypotheses; guess what your inquiry might find out.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Work with others to plan different kinds of investigations (e.g. field work, experiments) to answer queries or find solutions to problems; use fair tests to measure and control dependent and independent variables; use the right tools and digital technologies to precisely and accurately observe, measure and record qualitative and quantitative data; use the right SI units and do basic unit conversions; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; show patterns and ways data is related by making and using a mix of tables, graphs, keys, models and digital technologies; look for patterns in your data and data from other sources, and ways they are connected; spot relationships and make inferences based on your scientific understanding.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Look back on the ways you conducted your investigation, including whether your control of dependent and independent variables was effective, and how good the data you got was; point out ways errors might have crept in and suggest ways you can make your methods better next time; show you can pick up on assumptions and bias, both in yourself and in other sources; show that you know what qualitative and quantitative evidence are and grasp their importance; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; think about possible implications of your and others’ findings (for ethics, the environment and society).",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; work with others to design projects; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Share the things you think and solutions find, using scientific language and digital technology when it makes sense to do so; share and look back on a mix of different ways people experience and think about place.",
          },
        ],
      },
      {
        grade: grades[9],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you have a lasting intellectual curiosity about a specific issue in science or your own interests; observe things that help you find (sometimes more complex) questions you have about the world around you; make a few guesses about different things that might happen.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Work alone or with others to plan, choose and carry out different kinds of investigations (e.g. field work, experiments) to gather reliable qualitative and quantitative data; consider the possible risks of your and others’ suggested methods (cultural, ethical, environmental); choose and use the right equipment and digital technology to gather and document data; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; look for and evaluate the ways data is connected, including patterns and trends; explain how the dependent and independent variables are related; spot any inconsistencies; make, evaluate and interpret diagrams, models and graphs; interpolate and extrapolate; come to conclusions that make sense given the evidence and your knowledge of scientific concepts; evaluate cause-and-effect relationships.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Look back on the way you conducted your investigation, including spotting places you aren’t sure about or where mistakes could have been introduced, and other ways of explaining the data you got; detail ways you can make your investigation methods and the quality of your data better; decide how useful a model is in the context it is used (whether it is valid, any limitations); show you can spot assumptions, question whether the information you’re given is accurate and spot bias in your own and others’ work; think about how knowledge has evolved as new tools and technologies were created; see the connections between exploring science and working in the sciences; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; outline possible implications of your and others’ findings (for ethics, the environment and society); consider secondary sources of information. How accurate is their information? Were the tactics they used to solve problems effective?.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems; make inquiries that help find ways to solve local and/or global problems; think about how scientists are involved in innovation.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Create theoretical models (either physical or mental) that describe something that happens; craft arguments based on evidence, using the correct scientific language/conventions/ representations, that have a specific purpose and audience; share scientific claims, ideas and information as well as possibly what should be done next; share and look back on a mix of different ways people experience and think about place, and how that affects their worldview.",
          },
        ],
      },
      {
        grade: grades[10],
        points: [
          {
            handle: curriculumEarthAndSpaceHandles[0],
            description:
              "Show that you have a lasting intellectual curiosity about a specific issue in science or your own interests; observe things that help you find (sometimes more complex) questions you have about the world around you; make a few guesses about different things that might happen.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[1],
            description:
              "Work alone or with others to plan, choose and carry out different kinds of investigations (e.g. field work, experiments) to gather reliable qualitative and quantitative data; consider the possible risks of your and others’ suggested methods (cultural, ethical, environmental); choose and use the right equipment and digital technology to gather and document data; follow guidelines around safety and ethics when investigating.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[2],
            description:
              "Immerse yourself in and make observations about your local environment; use a mix of information sources, including the knowledge and perspective of First People, local knowledge and other ways of knowing; look for and evaluate the ways data is connected, including patterns and trends; explain how the dependent and independent variables are related; spot any inconsistencies; make, evaluate and interpret diagrams, models and graphs; interpolate and extrapolate; come to conclusions that make sense given the evidence and your knowledge of scientific concepts; evaluate cause-and-effect relationships.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[3],
            description:
              "Look back on the way you conducted your investigation, including spotting places you aren’t sure about or where mistakes could have been introduced, and other ways of explaining the data you got; detail ways you can make your investigation methods and the quality of your data better; decide how useful a model is in the context it is used (whether it is valid, any limitations); show you can spot assumptions, question whether the information you’re given is accurate and spot bias in your own and others’ work; think about how knowledge has evolved as new tools and technologies were created; see the connections between exploring science and working in the sciences; use the things you’ve learned through your own investigations as well as your knowledge of science to decide whether claims in other sources are reliable; be informed and skeptical; outline possible implications of your and others’ findings (for ethics, the environment and society); consider secondary sources of information. How accurate is their information? Were the tactics they used to solve problems effective?.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[4],
            description:
              "By yourself or with others, help look after yourself, others, your community and the world; use the things you’ve learned in new contexts; make and bring up new or fine-tuned ideas while solving problems; make inquiries that help find ways to solve local and/or global problems; think about how scientists are involved in innovation.",
          },
          {
            handle: curriculumEarthAndSpaceHandles[5],
            description:
              "Create theoretical models (either physical or mental) that describe something that happens; craft arguments based on evidence, using the correct scientific language/conventions/ representations, that have a specific purpose and audience; share scientific claims, ideas and information as well as possibly what should be done next; share and look back on a mix of different ways people experience and think about place, and how that affects their worldview.",
          },
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[3],
    data: [
      {
        grade: grades[0],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Tell why things, people, places, or events in your life or your area are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, infer and build opinions about specific types of sources: what they contain and what features they have.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and spot what changes and what doesn’t.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Spot the causes and effects of actions, decisions and developments in your life.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Realize that different people have different views about the people, places, issues or events in your life.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Spot parts of actions, events or decisions in your life that are and aren’t fair, and decide what to do about it.",
          },
        ],
      },
      {
        grade: grades[1],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Tell why things, people, places, or events in your life or your area are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, infer and build opinions about specific types of sources: what they contain and what features they have.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and spot what changes and what doesn’t.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Spot the causes and effects of actions, decisions and developments in your life.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Realize that different people have different views about the people, places, issues or events in your life.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Spot parts of actions, events or decisions in your life that are and aren’t fair, and decide what to do about it.",
          },
        ],
      },
      {
        grade: grades[2],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Tell why people, places, or events are important to different people and groups of people.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, infer and build opinions about specific types of sources: what they contain and what features they have.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and explain why some things change and some don’t.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Spot the causes and effects of actions, decisions and developments.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Describe why people have different views about people, places, issues or events, based on their life experiences, view of the world and role in life.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Decide whether actions, events or decisions are good or bad, and what people can learn from them.",
          },
        ],
      },
      {
        grade: grades[3],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Tell why people, places, or events are important to different people and groups of people.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, infer and build opinions about specific types of sources: what they contain and what features they have.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and explain why some things change and some don’t.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Spot the causes and effects of actions, decisions and developments.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Describe why people have different views about people, places, issues or events, based on their life experiences, view of the world and role in life.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Decide whether actions, events or decisions are good or bad, and what people can learn from them.",
          },
        ],
      },
      {
        grade: grades[4],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Be able to argue why people or groups of people, places, events or developments are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, confirm your guesses and develop opinions about where different sources come from and what content they contain.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and spot what changes over time and space and what doesn’t.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Be able to tell whether the effects of decisions, developments or events are intentional or not, and think about other things that could have happened.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Tell stories that show people who have different values, attitudes and worldviews than you (but are still common in different places and times).",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Decide what is right (actions, decisions, events), taking the specific time, places and conditions into consideration.",
          },
        ],
      },
      {
        grade: grades[5],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry; make plans that outline a way to fix a specific problem or issue.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Be able to argue why people or groups of people, places, events or developments are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, confirm your guesses and develop opinions about where different sources come from and what content they contain, including mass media.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and spot the good and bad things about what has and hasn’t changed.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Be able to tell whether the effects of decisions, developments or events are intentional or not, and think about other things that could have happened.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Make informed guesses about what stakeholders might believe, value and be motivated by, and use those ideas to infer what they might think about specific developments, events or issues.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Decide what is right (actions, decisions, events), taking the specific time, places and conditions into consideration, and suggest a good way to respond.",
          },
        ],
      },
      {
        grade: grades[6],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry; make plans that outline a way to fix a specific problem or issue.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Be able to argue why people or groups of people, places, events or developments are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Question, confirm your guesses and develop opinions about where a mix of different sources come from and what content they contain, including mass media.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Put things in order (things, pictures, events) and spot the good and bad things about what has and hasn’t changed.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "For events, decisions or developments, understand the differences between intentional and unintentional effects, and short- and long-term consequences.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Make informed guesses about what stakeholders might believe, value and be motivated by, and use those ideas to infer what they might think about specific developments, events or issues.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Decide what is right (actions, decisions, events), taking the specific time, places and conditions into consideration, and suggest a good way to respond.",
          },
        ],
      },
      {
        grade: grades[7],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Determine whether people, places, events or developments are important and specific times and places; spot the things that people who created documents (accounts, stories, maps, other writings) think are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Decide whether different sources are trustworthy/accurate, and whether they have good enough evidence to draw the conclusions they do.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Grasp the characteristics of a variety of historical time periods and how they rose and fell, and spot the pivotal moments where changes occurred.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Decide what causes had the greatest effect on specific actions, decisions and events, and look at the immediate and longer term consequences.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Explain why people in the past and present might have certain opinions about specific events, issues, people or places, and examine differences in what was/is commonly believed, valued and considered normal in that time and place.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Looking at things that happened in the past (actions, decisions, events), decide what was right, and identify some challenges we encounter when trying to learn directly from the past.",
          },
        ],
      },
      {
        grade: grades[8],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Determine whether people, places, events or developments are important and specific times and places; spot the things that people who created documents (accounts, stories, maps, other writings) think are important.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Decide whether different sources are trustworthy/accurate, and whether they have good enough evidence to draw the conclusions they do.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Grasp the characteristics of a variety of historical time periods and how they rose and fell, and spot the pivotal moments where changes occurred.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Decide what causes had the greatest effect on specific actions, decisions and events, and look at the immediate and longer term consequences.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Explain why people in the past and present might have certain opinions about specific events, issues, people or places, and examine differences in what was/is commonly believed, valued and considered normal in that time and place.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Looking at things that happened in the past (actions, decisions, events), decide what was right, and identify some challenges we encounter when trying to learn directly from the past.",
          },
        ],
      },
      {
        grade: grades[9],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Decide how important people, places, events or developments are to history, looking at different opinions and how they vary depending on place, source, time, etc.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Look into differing accounts of history, and decide which one is justified. Consider how reliable the sources are, whether their evidence is good enough and which of their points are contentious.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Look for similarities and differences between different groups of people in specific places at the same time.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Explain how the things people do (alone or with others), and the conditions at the time, affect what happens next (events, decisions or developments).",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Make informed guesses about what people in the past and present might think about specific events, issues, people or places based on what was/is commonly believed, valued and considered normal in that time and place.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Spot ethical judgements (both stated and implied) in a mix of sources; decide what is/was right when it comes to actions both past and present, and what the right way is to recall and respond.",
          },
        ],
      },
      {
        grade: grades[10],
        points: [
          {
            handle: curriculumSocialHandles[0],
            description:
              "Ask questions; compile, comprehend, and draw conclusions from ideas; and share what you’ve found and what you decide about it; all using the skills and processes learned for Social Studies inquiry.",
          },
          {
            handle: curriculumSocialHandles[1],
            description:
              "Decide how important people, places, events or developments are to history, looking at different opinions and how they vary depending on place, source, time, etc.",
          },
          {
            handle: curriculumSocialHandles[2],
            description:
              "Look into differing accounts of history, and decide which one is justified. Consider how reliable the sources are, whether their evidence and data is good enough and which of their points are contentious.",
          },
          {
            handle: curriculumSocialHandles[3],
            description:
              "Look for similarities and differences between different groups of people in specific times and places, and how that changes over time.",
          },
          {
            handle: curriculumSocialHandles[4],
            description:
              "Explain how the things people do (alone or with others), and the conditions at the time, affect what happens next (events, decisions or developments), and any other consequences they might have.",
          },
          {
            handle: curriculumSocialHandles[5],
            description:
              "Make informed guesses about what people in the past and present might think about specific events, issues, people or places based on what was/is commonly believed, valued and considered normal in that time and place.",
          },
          {
            handle: curriculumSocialHandles[6],
            description:
              "Decide what is/was right when it comes to actions both past and present, and what the right way is to recall and respond.",
          },
        ],
      },
    ],
  },
];

export default curriculumCompetencies;
