import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SET_DATE,
  CART_ADD_NOTE,
  CART_SET_COUNT,
  CART_RESET,
  CART_INCLUDE_ADDON,
  CART_SET_TIME,
} from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item1 = action.payload;
      return {
        ...state,
        cartItems: [...state.cartItems, item1],
      };
    case CART_INCLUDE_ADDON:
      const item2 = state.cartItems.find(
        (e) => e.experience === action.payload.experience,
      );

      const temp2 = item2;

      item2.addOns.push(action.payload);

      const items2 = state.cartItems;

      items2.splice(items2.indexOf(temp2), 1, item2);

      return {
        ...state,
        cartItems: items2,
      };
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (x) => x.experience !== action.payload,
        ),
      };
    case CART_SET_DATE:
      const item3 = state.cartItems.find(
        (e) => e.experience === action.payload.id,
      );

      const temp3 = item3;

      item3.date = action.payload.date;

      const items3 = state.cartItems;

      items3.splice(items3.indexOf(temp3), 1, item3);

      return {
        ...state,
        cartItems: items3,
      };
    case CART_SET_TIME:
      const item6 = state.cartItems.find(
        (e) => e.experience === action.payload.id,
      );

      const temp6 = item6;

      item6.time = action.payload.time;

      const items6 = state.cartItems;

      items6.splice(items6.indexOf(temp6), 1, item6);

      return {
        ...state,
        cartItems: items6,
      };
    case CART_ADD_NOTE:
      const item4 = state.cartItems.find(
        (e) => e.experience === action.payload.id,
      );

      const temp4 = item4;

      item4.note = action.payload.note;

      const items4 = state.cartItems;

      items4.splice(items4.indexOf(temp4), 1, item4);

      return {
        ...state,
        cartItems: items4,
      };
    case CART_SET_COUNT:
      const item5 = state.cartItems.find(
        (e) => e.experience === action.payload.id,
      );

      const temp5 = item5;

      item5.count = action.payload.count;

      const items5 = state.cartItems;

      items5.splice(items5.indexOf(temp5), 1, item5);

      return {
        ...state,
        cartItems: items5,
      };
    case CART_RESET:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};
