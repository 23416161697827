import axios from "axios";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_LIST_ALL_REQUEST,
  USER_LIST_ALL_SUCCESS,
  USER_LIST_ALL_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_SET_AVATAR_REQUEST,
  USER_SET_AVATAR_SUCCESS,
  USER_SET_AVATAR_FAIL,
  USER_SET_BANNER_REQUEST,
  USER_SET_BANNER_SUCCESS,
  USER_SET_BANNER_FAIL,
  USER_SET_THUMBNAIL_REQUEST,
  USER_SET_THUMBNAIL_SUCCESS,
  USER_SET_THUMBNAIL_FAIL,
  USER_PUSH_VALUE_REQUEST,
  USER_PUSH_VALUE_SUCCESS,
  USER_PUSH_VALUE_FAIL,
  USER_PUSH_ABOUT_REQUEST,
  USER_PUSH_ABOUT_SUCCESS,
  USER_PUSH_ABOUT_FAIL,
  USER_SET_LA_REQUEST,
  USER_SET_LA_SUCCESS,
  USER_SET_LA_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_LOGIN_RESET,
  USER_PASSWORD_RESET_REQUEST_REQUEST,
  USER_PASSWORD_RESET_REQUEST_SUCCESS,
  USER_PASSWORD_RESET_REQUEST_FAIL,
} from "../constants/userConstants";

// login
export const login = (email, password, type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/${type}s/login`,
      { email, password },
      config,
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// login from token
export const tokenLogin = (token, type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`/api/${type}s/login/${token}`, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// request register
export const register = (user, type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`/api/${type}s/join`, { user }, config);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// request register
export const createNewUser = (user, type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/${type}s${type === "partner" ? "/new" : ""}`,
      { user },
      config,
    );

    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// activate user account
export const activateAccount =
  (type, userToken) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_ACTIVATE_REQUEST,
      });

      const { data } = await axios.post(`/api/${type}s/activate/${userToken}`);

      dispatch({
        type: USER_ACTIVATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_ACTIVATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// logout
export const logout = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });

  localStorage.removeItem("userInfo");

  dispatch({
    type: USER_LOGIN_RESET,
  });
};

// get all users
export const listAllUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_ALL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users`, config);

    dispatch({
      type: USER_LIST_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get all users by type
export const listUsers = (type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/${type}s`);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get single user details by type
export const listUserDetails = (id, type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/${type}s/${id}`);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// update user details
export const updateUser =
  (fields, userId, type) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/${type}s/${userId}`,
        fields,
        config,
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// set user avatar
export const setUserAvatar =
  (id, type, avatar) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_SET_AVATAR_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/${type}s/avatar`,
        { id, type, avatar },
        config,
      );

      dispatch({
        type: USER_SET_AVATAR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_SET_AVATAR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// set user banner
export const setUserBanner =
  (id, type, banner) => async (dispatch, getState) => {
    console.log(id, type, banner);
    try {
      dispatch({
        type: USER_SET_BANNER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/${type}s/banner`,
        { id, type, banner },
        config,
      );

      dispatch({
        type: USER_SET_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_SET_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// set user banner
export const setThumbnail =
  (id, type, thumbnail, index) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_SET_THUMBNAIL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/${type}s/thumbnail`,
        { id, thumbnail, index },
        config,
      );

      dispatch({
        type: USER_SET_THUMBNAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_SET_THUMBNAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// add partner value
export const addPartnerValue = (id, value) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PUSH_VALUE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/partners/values`,
      { id, value },
      config,
    );

    dispatch({
      type: USER_PUSH_VALUE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PUSH_VALUE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// add partner value
export const addPartnerAbout = (id, about) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PUSH_ABOUT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/partners/about`,
      { id, about },
      config,
    );

    dispatch({
      type: USER_PUSH_ABOUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PUSH_ABOUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// set partner LA
export const setPartnerLA = (id, LA) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_SET_LA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(`/api/partners/LA`, { id, LA }, config);

    dispatch({
      type: USER_SET_LA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SET_LA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// reset user password
export const resetUserPassword =
  (type, password, userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/${type}s/${userId}`,
        { password, userId },
        config,
      );

      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_RESET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// request password reset
export const requestUserPasswordReset = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PASSWORD_RESET_REQUEST_REQUEST,
    });

    const { data } = await axios.post(`/api/users/${email}`);

    dispatch({
      type: USER_PASSWORD_RESET_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RESET_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
