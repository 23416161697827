export const WS_GET_URL_REQUEST = "WS_GET_URL_REQUEST";
export const WS_GET_URL_SUCCESS = "WS_GET_URL_SUCCESS";
export const WS_GET_URL_FAIL = "WS_GET_URL_FAIL";
export const WS_GET_URL_RESET = "WS_GET_URL_RESET";

export const CONV_GET_CONVERSATIONS_REQUEST = "CONV_GET_CONVERSATIONS_REQUEST";
export const CONV_GET_CONVERSATIONS_SUCCESS = "CONV_GET_CONVERSATIONS_SUCCESS";
export const CONV_GET_CONVERSATIONS_FAIL = "CONV_GET_CONVERSATIONS_FAIL";
export const CONV_GET_CONVERSATIONS_RESET = "CONV_GET_CONVERSATIONS_RESET";

export const CONV_CREATE_NEW_REQUEST = "CONV_CREATE_NEW_REQUEST";
export const CONV_CREATE_NEW_SUCCESS = "CONV_CREATE_NEW_SUCCESS";
export const CONV_CREATE_NEW_FAIL = "CONV_CREATE_NEW_FAIL";
export const CONV_CREATE_NEW_RESET = "CONV_CREATE_NEW_RESET";

export const MSG_GET_MESSAGES_REQUEST = "MSG_GET_MESSAGES_REQUEST";
export const MSG_GET_MESSAGES_SUCCESS = "MSG_GET_MESSAGES_SUCCESS";
export const MSG_GET_MESSAGES_FAIL = "MSG_GET_MESSAGES_FAIL";
export const MSG_GET_MESSAGES_RESET = "MSG_GET_MESSAGES_RESET";

export const MSG_ADD_NEW_REQUEST = "MSG_ADD_NEW_REQUEST";
export const MSG_ADD_NEW_SUCCESS = "MSG_ADD_NEW_SUCCESS";
export const MSG_ADD_NEW_FAIL = "MSG_ADD_NEW_FAIL";
export const MSG_ADD_NEW_RESET = "MSG_ADD_NEW_RESET";
