import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGIN_RESET } from "../constants/userConstants";
import Loader from "./Loader";

const AdminDashboard = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
      }, 5000);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (!userInfo) {
      history.push("/");
    } else {
      if (userInfo.type === "partner" || userInfo.type === "teacher") {
        history.push("/account");
      }
    }
  }, [userInfo, history]);

  return loading ? (
    <Loader />
  ) : (
    userInfo && (
      <div className="admin-dashboard">
        {userInfo.type === "admin" && (
          <>
            <div>
              <Link to="/add-partner" className="btn">
                <img
                  src="./images/vrc-icons-beige-add.png"
                  alt="+"
                  className="dashboard-icon"
                />
                <span>add partner</span>
              </Link>
              <Link to="/add-teacher" className="btn">
                <img
                  src="./images/vrc-icons-beige-add.png"
                  alt="+"
                  className="dashboard-icon"
                />
                <span>add teacher</span>
              </Link>
              <Link to="/add-experience" className="btn">
                <img
                  src="./images/vrc-icons-beige-add.png"
                  alt="+"
                  className="dashboard-icon"
                />
                <span>add experience</span>
              </Link>
            </div>
            <div style={{ justifyContent: "center" }}>
              <Link to="/add-reviewer" className="btn">
                <img
                  src="./images/vrc-icons-beige-add.png"
                  alt="+"
                  className="dashboard-icon"
                />
                <span>add reviewer</span>
              </Link>
            </div>
          </>
        )}
        <div style={{ height: "50px" }}></div>
        <div>
          <Link to="/admin/all-partners" className="btn">
            <img
              src="./images/vrc-icons-beige-partnership.png"
              alt="+"
              className="dashboard-icon"
            />
            <span>view all partners</span>
          </Link>
          {userInfo.type === "admin" && (
            <Link to="/admin/all-teachers" className="btn">
              <img
                src="./images/vrc-icons-beige-teacher.png"
                alt="+"
                className="dashboard-icon"
              />
              <span>view all teachers</span>
            </Link>
          )}
          <Link to="/admin/all-experiences" className="btn">
            <img
              src="./images/vrc-icons-beige-passowrd-show.png"
              alt="+"
              className="dashboard-icon"
            />
            <span>view all experiences</span>
          </Link>
        </div>
        <div>
          {userInfo.type === "admin" && (
            <>
              <Link to="/admin/all-bookings" className="btn">
                <img
                  src="./images/vrc-icons-beige-booking.png"
                  alt="+"
                  className="dashboard-icon"
                />
                <span>view all bookings</span>
              </Link>
              <Link to="/admin/all-mails" className="btn">
                <img
                  src="./images/vrc-icons-beige-mail.png"
                  alt="+"
                  className="dashboard-icon"
                />
                <span>view all mails</span>
              </Link>
            </>
          )}
          <Link to="/media-library" className="btn">
            <img
              src="./images/vrc-icons-beige-media.png"
              alt="+"
              className="dashboard-icon"
            />
            <span>view all media</span>
          </Link>
        </div>
        <div style={{ justifyContent: "center" }}>
          <Link to="/admin/all-hosts" className="btn">
            <img
              src="./images/vrc-icons-beige-host.png"
              alt="+"
              className="dashboard-icon"
            />
            <span>view all hosts</span>
          </Link>
          {userInfo.type === "admin" && (
            <Link to="/admin/all-reviewers" className="btn">
              <img
                src="./images/vrc-icons-beige-review.png"
                alt="+"
                className="dashboard-icon"
              />
              <span>view all reviewers</span>
            </Link>
          )}
        </div>
        <div style={{ height: "50px" }}></div>
        <div style={{ justifyContent: "center" }}>
          {userInfo.type === "admin" && (
            <Link to={`/admin/profile/${userInfo._id}`} className="btn">
              <img
                src="./images/vrc-icons-beige-edit.png"
                alt="+"
                className="dashboard-icon"
              />
              <span>Go to profile</span>
            </Link>
          )}
        </div>
      </div>
    )
  );
};

export default AdminDashboard;
