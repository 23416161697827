import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

import { getStripePublishableKey } from "../actions/stripeActions";
import { STRIPE_PUBLISHABLE_KEY_RESET } from "../constants/stripeConstants";
import {
  BOOKING_CONFIRM_RESET,
  BOOKING_DECLINE_RESET,
  BOOKING_DETAILS_RESET,
  BOOKING_MARK_PAID_RESET,
  BOOKING_PAY_LATER_RESET,
  BOOKING_PAY_RESET,
} from "../constants/bookingConstants";
import {
  confirmBooking,
  declineBooking,
  getBookingDetails,
  markBookingPaid,
  payLater,
} from "../actions/bookingActions";
import { setExperienceNumPurchased } from "../actions/experienceActions";

import {
  daysOfWeek,
  inPersonPaymentTypes,
  months,
  timezones,
} from "../data/data";

import Loader from "../components/Loader";
import Message from "../components/Message";

const BookingScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    }

    dispatch(getBookingDetails(match.params.id));
  }, [dispatch, userInfo, history, match]);

  const [paymentCard, setPaymentCard] = useState(false);
  const [paymentLater, setPaymentLater] = useState(false);

  const bookingDetails = useSelector((state) => state.bookingDetails);
  const { loading, error, booking } = bookingDetails;

  useEffect(() => {
    if (!booking) {
      dispatch(getBookingDetails(match.params.id));
    }
  }, [booking, dispatch, match]);

  useEffect(() => {
    if (
      userInfo?.type === "teacher" &&
      booking?.isConfirmed &&
      !booking?.isPaid
    ) {
      dispatch(getStripePublishableKey());
    }
  }, [userInfo, booking, dispatch]);

  const stripeKey = useSelector((state) => state.stripeKey);
  const { loading: loadingStripeKey, error: errorStripeKey, key } = stripeKey;

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (key) {
      setStripePromise(loadStripe(key));
    }
  }, [key]);

  useEffect(() => {
    if (errorStripeKey) {
      setTimeout(() => {
        dispatch({ type: STRIPE_PUBLISHABLE_KEY_RESET });
      }, 5000);
    }
  }, [errorStripeKey, dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: BOOKING_DETAILS_RESET });
      }, 5000);
    }
  }, [error, dispatch]);

  const bookingConfirm = useSelector((state) => state.bookingConfirm);
  const {
    loading: loadingConfirm,
    error: errorConfirm,
    success: successConfirm,
  } = bookingConfirm;

  useEffect(() => {
    if (booking && successConfirm) {
      setTimeout(() => {
        if (booking.totalPrice) {
          window.location.reload();
        } else {
          dispatch(markBookingPaid(booking._id));
        }
      }, 5000);
    }
  }, [booking, successConfirm, dispatch]);

  useEffect(() => {
    if (errorConfirm) {
      setTimeout(() => {
        dispatch({ type: BOOKING_CONFIRM_RESET });
      }, 5000);
    }
  }, [errorConfirm, dispatch]);

  const bookingDecline = useSelector((state) => state.bookingDecline);
  const {
    loading: loadingDecline,
    error: errorDecline,
    success: successDecline,
  } = bookingDecline;

  useEffect(() => {
    if (errorDecline) {
      setTimeout(() => {
        dispatch({ type: BOOKING_DECLINE_RESET });
      }, 5000);
    }
  }, [errorDecline, dispatch]);

  const bookingPay = useSelector((state) => state.bookingPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = bookingPay;

  useEffect(() => {
    if (errorPay) {
      setTimeout(() => {
        dispatch({ type: BOOKING_PAY_RESET });
      }, 5000);
    }
  }, [errorPay, dispatch]);

  const bookingPayLater = useSelector((state) => state.bookingPayLater);
  const {
    loading: loadingPayLater,
    error: errorPayLater,
    success: successPayLater,
  } = bookingPayLater;

  useEffect(() => {
    if (errorPayLater) {
      setTimeout(() => {
        dispatch({ type: BOOKING_PAY_LATER_RESET });
      }, 5000);
    }
  }, [errorPayLater, dispatch]);

  const bookingMarkPaid = useSelector((state) => state.bookingMarkPaid);
  const {
    loading: loadingMarkPaid,
    error: errorMarkPaid,
    success: successMarkPaid,
  } = bookingMarkPaid;

  useEffect(() => {
    if (errorMarkPaid) {
      setTimeout(() => {
        dispatch({ type: BOOKING_MARK_PAID_RESET });
      }, 5000);
    }
  }, [errorMarkPaid, dispatch]);

  const togglePaymentCard = () => {
    setPaymentCard(!paymentCard);
  };

  const togglePaymentLater = () => {
    setPaymentLater(!paymentLater);
  };

  const paymentHandler = (e) => {
    e.preventDefault();
    togglePaymentCard();
  };

  const payLaterHandler = (e) => {
    e.preventDefault();
    togglePaymentLater();
  };

  const postPaymentHandler = (e) => {
    e.preventDefault();
    if (e.target.id?.length) {
      dispatch(payLater(booking._id, e.target.id));
    }
  };

  useEffect(() => {
    if (successDecline) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [successDecline, history]);

  useEffect(() => {}, [successConfirm, successDecline]);

  useEffect(() => {
    if (successPay) {
      dispatch(setExperienceNumPurchased(booking.bookingItems[0].experience));
      setTimeout(() => {
        dispatch({ type: BOOKING_PAY_RESET });
        window.location.reload();
      }, 5000);
    }
    // eslint-disable-next-line
  }, [dispatch, successPay]);

  useEffect(() => {
    if (successPayLater) {
      setTimeout(() => {
        dispatch({ type: BOOKING_PAY_LATER_RESET });
        window.location.reload();
      }, 5000);
    }
    // eslint-disable-next-line
  }, [dispatch, successPayLater]);

  useEffect(() => {
    if (successMarkPaid) {
      setTimeout(() => {
        dispatch({ type: BOOKING_MARK_PAID_RESET });
        window.location.reload();
      }, 5000);
    }
  }, [dispatch, successMarkPaid]);

  // step 3 - availability
  const [note, setNote] = useState("");
  const [startMonth, setStartMonth] = useState(`hide`);
  const [endMonth, setEndMonth] = useState(`hide`);
  const [startTime, setStartTime] = useState(``);
  const [endTime, setEndTime] = useState(``);
  const [timezone, setTimezone] = useState(`hide`);
  const [checkedDays, setCheckedDays] = useState(
    new Array(daysOfWeek.length).fill(false),
  );
  const [selectedDays, setSelectedDays] = useState([]);

  const startMonthHandler = (e) => {
    e.preventDefault();
    setStartMonth(e.target.value);
  };

  const endMonthHandler = (e) => {
    e.preventDefault();
    setEndMonth(e.target.value);
  };

  const startTimeHandler = (e) => {
    e.preventDefault();
    setStartTime(e.target.value);
  };

  const endTimeHandler = (e) => {
    e.preventDefault();
    setEndTime(e.target.value);
  };

  const timezoneHandler = (e) => {
    e.preventDefault();
    setTimezone(e.target.value);
  };

  const handleOnChangeDays = (positionDays) => {
    const updatedCheckedDays = checkedDays.map((itemDays, indexDays) =>
      indexDays === positionDays ? !itemDays : itemDays,
    );

    setCheckedDays(updatedCheckedDays);
  };

  useEffect(() => {
    setSelectedDays(
      daysOfWeek
        .filter((ele) => checkedDays[daysOfWeek.indexOf(ele)] === true)
        .map((day) => day.substring(0, 3)),
    );
  }, [checkedDays]);

  const noteHandler = (e) => {
    setNote(e.target.value);
  };

  const confirmHandler = (e) => {
    e.preventDefault();
    if (!booking.isConfirmed) {
      if (userInfo.type === "partner") {
        if (booking.partner.id === userInfo._id) {
          dispatch(confirmBooking(booking._id));
        }
      }
    }
  };

  const declineHandler = (e) => {
    e.preventDefault();
    if (!booking.isDeclined) {
      if (userInfo.type === "partner") {
        if (booking.partner.id === userInfo._id) {
          if (
            note?.length &&
            startMonth?.length &&
            startMonth !== "hide" &&
            endMonth?.length &&
            endMonth !== "hide" &&
            timezone?.length &&
            timezone !== "hide" &&
            startTime?.length &&
            endTime?.length &&
            selectedDays?.length
          ) {
            dispatch(
              declineBooking(
                booking._id,
                JSON.stringify({
                  reason: note,
                  startMonth,
                  endMonth,
                  selectedDays,
                  startTime,
                  endTime,
                  timezone,
                }),
              ),
            );

            setNote("");
            startMonth("hide");
            endMonth("hide");
            startTime("");
            endTime("");
            timezone("hide");
            setCheckedDays(new Array(daysOfWeek.length).fill(false));
            setSelectedDays([]);
          }
        }
      }
    }
  };

  const markPaidHandler = (e) => {
    e.preventDefault();
    dispatch(markBookingPaid(booking._id));
  };

  return (
    <div
      className="landing-generic booking-details"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
        paddingBottom: "60px",
      }}
    >
      <div
        className="container"
        style={{ color: "var(--var-color-vrc-beige)" }}
      >
        {successConfirm && (
          <Message
            body={`You've confirmed the booking successfully!${
              !booking?.totalPrice
                ? " Booking is free. Please wait while marking it paid by the platform!"
                : ""
            }`}
            type="info"
          />
        )}
        {successDecline && (
          <Message body={`Booking has been declined!`} type="info" />
        )}
        {successPay && (
          <Message
            body={`Congratulations! Booking has been paid successfully!`}
            type="info"
          />
        )}
        {successMarkPaid && (
          <Message
            body={`Booking has been set to paid successfully.`}
            type="info"
          />
        )}
        {successPayLater && (
          <Message
            body={`Booking request has been sent to your host. They will respond within 48 hours. You can enjoy browsing more listings!`}
            type="info"
          />
        )}
        {loading ? (
          <Loader />
        ) : loadingConfirm ? (
          <Loader />
        ) : loadingDecline ? (
          <Loader />
        ) : loadingPay ? (
          <Loader />
        ) : loadingPayLater ? (
          <Loader />
        ) : loadingMarkPaid ? (
          <Loader />
        ) : loadingStripeKey ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : errorConfirm ? (
          <Message body={errorConfirm} />
        ) : errorDecline ? (
          <Message body={errorDecline} />
        ) : errorPay ? (
          <Message body={errorPay} />
        ) : errorPayLater ? (
          <Message body={errorPayLater} />
        ) : errorMarkPaid ? (
          <Message body={errorMarkPaid} />
        ) : errorStripeKey ? (
          <Message body={errorStripeKey} />
        ) : userInfo && booking ? (
          <div className="booking-table">
            <h3 style={{ margin: "40px 0" }}>Booking Details</h3>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                style={{
                  width:
                    booking.isConfirmed &&
                    !booking.isPaid &&
                    !booking.isDeclined &&
                    userInfo._id === booking.teacher
                      ? "65%"
                      : "100%",
                }}
                className="booking-table-outline"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Booking ID</th>
                      <th>{booking._id}</th>
                    </tr>
                    <tr>
                      <th>Teacher name</th>
                      <th>{booking.teacher.name}</th>
                    </tr>
                    <tr>
                      <th>Partner name</th>
                      <th>{booking.partner.name}</th>
                    </tr>
                    <tr>
                      <th>Experience name</th>
                      <th>{booking.bookingItems[0].name}</th>
                    </tr>
                    <tr>
                      <th>Experience Type</th>
                      <th>{booking.bookingItems[0].type}</th>
                    </tr>
                    <tr>
                      <th>Start Date</th>
                      <th>
                        {booking.bookingItems[0].date
                          .split("-")
                          .reverse()
                          .join("-")}
                      </th>
                    </tr>
                    <tr>
                      <th>Start Time</th>
                      <th>{booking.bookingItems[0].time}</th>
                    </tr>
                    {booking.bookingItems[0].per === "student" && (
                      <tr>
                        <th>Number of Students</th>
                        <th>
                          {booking.bookingItems[0].count} Students (
                          {booking.bookingItems[0].maxStudents > 0
                            ? `max: ${booking.bookingItems[0].maxStudents}`
                            : `no max`}
                          )
                        </th>
                      </tr>
                    )}
                    <tr>
                      <th>Experience price</th>
                      <th>
                        {booking.bookingItems[0].price === 0 ? (
                          "free"
                        ) : (
                          <>
                            ${booking.bookingItems[0].price}/
                            {booking.bookingItems[0].per} (
                            {booking.bookingItems[0].per === "student"
                              ? `$${
                                  booking.bookingItems[0].price *
                                  booking.bookingItems[0].count
                                }`
                              : booking.bookingItems[0].per === "class"
                              ? `$${booking.bookingItems[0].price}`
                              : "Unspecified"}
                            )
                          </>
                        )}
                      </th>
                    </tr>
                    {booking.bookingItems[0].addOns?.length && (
                      <>
                        <tr>
                          <th>
                            Add-Ons ({booking.bookingItems[0].addOns.length})
                          </th>
                          <th>
                            {booking.bookingItems[0].addOns.map((ao, index) => (
                              <p key={index} style={{ textAlign: "left" }}>
                                - {ao.name} (
                                {ao.price === 0 ? "free" : `$${ao.price}`})
                              </p>
                            ))}
                          </th>
                        </tr>
                        <tr>
                          <th>Add-Ons Price</th>
                          <th>
                            {booking.bookingItems[0].addOns.reduce(
                              (acc, ele) => ele.price + acc,
                              0,
                            ) === 0
                              ? "free"
                              : `$${booking.bookingItems[0].addOns.reduce(
                                  (acc, ele) => ele.price + acc,
                                  0,
                                )}`}
                          </th>
                        </tr>
                      </>
                    )}
                    {booking.discount ? (
                      <tr>
                        <th>Discount</th>
                        <th>($50 Discount applied)</th>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th className="total-price-cell">Total price</th>
                      <th className="total-price-cell">
                        {booking.totalPrice === 0
                          ? "free"
                          : `$${booking.totalPrice}`}
                      </th>
                    </tr>
                    {booking.bookingItems[0].note ? (
                      <tr>
                        <th>Teacher note</th>
                        <th>{booking.bookingItems[0].note}</th>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th>Status</th>
                      <th
                        style={
                          booking.isPaid
                            ? {
                                color: "var(--var-color-vrc-green)",
                              }
                            : booking.isConfirmed
                            ? {
                                color: "var(--var-color-vrc-yellow)",
                              }
                            : booking.isDeclined
                            ? {
                                color: "var(--var-color-vrc-red)",
                              }
                            : {
                                color: "var(--var-color-vrc-beige)",
                              }
                        }
                      >
                        {booking.isPaid ? (
                          <>
                            {booking.totalPrice !== 0
                              ? "Paid"
                              : booking.isConfirmed
                              ? "Confirmed"
                              : "Error"}
                          </>
                        ) : booking.isConfirmed ? (
                          "Confirmed"
                        ) : booking.isDeclined ? (
                          "Booking declined"
                        ) : (
                          "Pending Confirmation"
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th>Confirmed?</th>
                      <th>
                        <img
                          src={
                            booking.isConfirmed
                              ? "/images/vrc-icons-beige-true.png"
                              : "/images/vrc-icons-beige-false.png"
                          }
                          alt=""
                        />
                      </th>
                    </tr>

                    {booking.isConfirmed && booking.confirmedAt && (
                      <tr>
                        <th>Confirmed At</th>
                        <th>
                          {booking.confirmedAt
                            .substring(0, 10)
                            .split("-")
                            .reverse()
                            .join("-")}
                        </th>
                      </tr>
                    )}

                    {booking?.totalPrice ? (
                      <tr>
                        <th>Paid?</th>
                        <th>
                          <img
                            src={
                              booking.isPaid
                                ? "/images/vrc-icons-beige-true.png"
                                : "/images/vrc-icons-beige-false.png"
                            }
                            alt=""
                          />
                        </th>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {booking.requestedToBePaidInPerson && (
                      <tr>
                        <th>Requested payment type</th>
                        <th>In-person ({booking.inPersonPaymentType})</th>
                      </tr>
                    )}
                    {booking.totalPrice !== 0 && (
                      <>
                        {booking.isPaid && booking.paidAt && (
                          <tr>
                            <th>Paid At</th>
                            <th>
                              {booking.paidAt
                                .substring(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </th>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {booking.isConfirmed &&
                !booking.isPaid &&
                booking.requestedToBePaidInPerson &&
                userInfo._id === booking.partner.id && (
                  <div className="action-card">
                    <button
                      className="btn confirm-btn"
                      onClick={markPaidHandler}
                    >
                      mark as paid
                    </button>
                  </div>
                )}
            </div>
            {/*  */}
            {!booking.isDeclined && (
              <>
                {!booking.isConfirmed &&
                  userInfo._id === booking.partner.id && (
                    <div className="action-card" style={{ marginTop: "20px" }}>
                      <button
                        className="btn confirm-btn"
                        onClick={confirmHandler}
                        style={{ height: "60px" }}
                      >
                        confirm booking
                      </button>
                      <span className="user-divider"></span>
                      <p>
                        Or, if you'll decline the booking, please mention the
                        reason below:
                      </p>
                      <textarea
                        type="text"
                        value={note}
                        onChange={noteHandler}
                        placeholder="Please specify the reason for declining the booking. If you suggest a new date and/or time, please fill the fields below with the available timings."
                      ></textarea>
                      <form
                        className="decline-form"
                        onSubmit={declineHandler}
                        noValidate
                      >
                        <div className="select">
                          <select
                            onChange={startMonthHandler}
                            defaultValue={startMonth}
                          >
                            <option value="hide">start month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month.substring(0, 3)}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="select">
                          <select
                            onChange={endMonthHandler}
                            defaultValue={endMonth}
                          >
                            <option value="hide">end month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month.substring(0, 3)}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          className="toggle-time"
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <label style={{ width: "50%", paddingLeft: "30px" }}>
                            start time
                          </label>
                          <input
                            type="time"
                            onChange={startTimeHandler}
                            value={startTime}
                          />
                        </div>
                        <div
                          className="toggle-time"
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <label style={{ width: "50%", paddingLeft: "30px" }}>
                            end time
                          </label>
                          <input
                            type="time"
                            onChange={endTimeHandler}
                            value={endTime}
                          />
                        </div>
                        <div className="select">
                          <select
                            onChange={timezoneHandler}
                            defaultValue={timezone}
                          >
                            <option value="hide">timezone</option>
                            {timezones.sort().map((zone, index) => (
                              <option key={index} value={zone}>
                                {zone}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div
                          className="days-of-week"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "flex-start",
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "200",
                              fontSize: "var(--a-font-size)",
                            }}
                          >
                            days of the week
                          </label>
                          <div
                            className="days"
                            style={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            {daysOfWeek.map((ele, indexDays) => (
                              <div
                                key={indexDays}
                                style={{ width: "calc((100% - 10px)/2)" }}
                              >
                                <input
                                  type="checkbox"
                                  id={indexDays + 500}
                                  name={indexDays}
                                  checked={checkedDays[indexDays]}
                                  onChange={() => handleOnChangeDays(indexDays)}
                                  style={{ width: "15px" }}
                                />
                                <label htmlFor={indexDays + 500}>
                                  <p style={{ fontWeight: "400" }}>{ele}</p>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <button
                          className="btn decline-btn"
                          onClick={declineHandler}
                        >
                          decline booking
                        </button>
                      </form>
                    </div>
                  )}
              </>
            )}
            {booking.isConfirmed &&
              !booking.isPaid &&
              !booking.requestedToBePaidInPerson &&
              userInfo._id === booking.teacher.id && (
                <div
                  className="action-card"
                  style={{ height: "fit-content", marginTop: "20px" }}
                >
                  <div>
                    <h5>Experience</h5>
                    <h5>
                      {booking.bookingItems[0].count} x $
                      {booking.bookingItems[0].price}
                    </h5>
                  </div>
                  {booking.bookingItems[0].addOns &&
                    booking.bookingItems[0].addOns?.length && (
                      <div>
                        <h5>Add-Ons</h5>
                        <h5>
                          $
                          {booking.bookingItems[0].addOns.reduce(
                            (acc, ele) => ele.price + acc,
                            0,
                          )}
                        </h5>
                      </div>
                    )}
                  <span className="user-divider"></span>
                  <div>
                    <h5>TOTAL</h5>
                    <h5>${booking.totalPrice}</h5>
                  </div>
                  <span className="user-divider"></span>

                  {!paymentCard ? (
                    <>
                      {!paymentLater && (
                        <button className="btn" onClick={paymentHandler}>
                          pay now
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {key && stripePromise && (
                        <Elements stripe={stripePromise}>
                          <CheckoutForm bookingId={booking._id} />
                        </Elements>
                      )}
                    </>
                  )}

                  {inPersonPaymentTypes.find(
                    (paymentType) =>
                      paymentType === booking.bookingItems[0].type,
                  ) && (
                    <>
                      {!paymentLater ? (
                        <>
                          {!paymentCard && (
                            <button className="btn" onClick={payLaterHandler}>
                              pay later
                            </button>
                          )}
                        </>
                      ) : (
                        <div className="action-card">
                          <p>
                            By selecting one of the following payment methods,
                            the experience host will be notified you are
                            arriving with payment. If there is an issue, you
                            will be notified within 48 hours of the experience
                            date:
                          </p>
                          {["Cash", "Cheque"].map((ele, index) => (
                            <button
                              key={index}
                              className="btn"
                              id={ele}
                              onClick={postPaymentHandler}
                            >
                              {ele} on arrival
                            </button>
                          ))}
                        </div>
                      )}
                      {(paymentLater || paymentCard) && (
                        <button
                          className="btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setPaymentCard(false);
                            setPaymentLater(false);
                          }}
                        >
                          reset payment method
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            {/*  */}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BookingScreen;
