import React from "react";

const Loader = () => {
  return (
    <div className="loading" style={{ zIndex: "5000" }}>
      <div className="container">
        <img className="loader" src="/images/loading.gif" alt="" />
      </div>
    </div>
  );
};

export default Loader;
