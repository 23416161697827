import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkFinancialProfile } from "../actions/hostActions";

import HostForm from "../components/HostForm";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  HOST_CREATE_RESET,
  HOST_DETAILS_RESET,
} from "../constants/hostConstants";

const HostScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const hostDetails = useSelector((state) => state.hostDetails);
  const { loading, error, success } = hostDetails;

  const hostCreate = useSelector((state) => state.hostCreate);
  const { success: successCreate } = hostCreate;

  useEffect(() => {
    if (userInfo && userInfo.type === "partner") {
      dispatch(checkFinancialProfile(userInfo._id));
    } else {
      history.push("/");
    }
  }, [userInfo, history, dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: HOST_DETAILS_RESET });
        history.push("/account");
      }, 5000);
    }
  }, [success, dispatch, history]);

  useEffect(() => {
    if (successCreate) {
      setTimeout(() => {
        dispatch({ type: HOST_CREATE_RESET });
        dispatch({ type: HOST_DETAILS_RESET });
        history.push("/account");
      }, 5000);
    }
  }, [successCreate, dispatch, history]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {userInfo && (
        <>
          {loading && <Loader />}
          {success && (
            <Message
              body={
                "Host has already stored their details in the platform. If you want to perform any changes, please contact the admin!"
              }
            />
          )}
          {successCreate && (
            <Message
              body={
                "Partner financial profile has been created successfully! Now, you can add new listings. Thank you!"
              }
              type="info"
            />
          )}
          {error && <div className="container">{userInfo && <HostForm />}</div>}
        </>
      )}
    </div>
  );
};

export default HostScreen;
