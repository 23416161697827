import React from "react";

import { Link } from "react-router-dom";
import Benefit from "./Benefit";

import Rating from "./Rating";

const CardH = ({ content, cardType, user }) => {
  return (
    <>
      {content && (
        <div
          className="card product-card"
          style={{ width: "100%", flexDirection: "row", height: "auto" }}
        >
          <div
            className="visual"
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderTopRightRadius: "0",
              height: "auto",
              backgroundImage: `url(${content.banner})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            {cardType === "partner" ? (
              <img
                src={
                  content.head.avatar && content.head.avatar?.length
                    ? content.head.avatar
                    : "/images/avatar-partner-card.png"
                }
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.src = "/images/avatar-partner-card.png";
                }}
                style={{ width: "160px", padding: "20px" }}
              />
            ) : cardType === "experience" ? (
              <>
                <img
                  src={
                    !content.banner ? "/images/avatar-experience-card.png" : ""
                  }
                  alt=""
                  style={{
                    height: "160px",
                    width: "160px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: "0",
                  }}
                />
                <div
                  className="bg-overlay"
                  style={
                    content.banner ? { opacity: "75%" } : { opacity: "50%" }
                  }
                ></div>
              </>
            ) : (
              ""
            )}
          </div>
          <div
            className="text"
            style={
              cardType === "partner"
                ? {
                    flexDirection: "column",
                    width: "100%",
                    borderBottomLeftRadius: "0",
                    borderBottomRightRadius: "5px",
                    borderTopRightRadius: "5px",
                  }
                : cardType === "experience"
                ? {
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                    padding: "20px",
                  }
                : ""
            }
          >
            {cardType === "partner" ? (
              <>
                <Link
                  to={`/${cardType}/${content._id}`}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                ></Link>
                <h6 style={{ fontSize: "26px" }}>{content.head.name}</h6>
                <span style={{ fontSize: "22px" }}>{content.category}</span>
                {content.about && content.about?.length && (
                  <p
                    style={{ textTransform: "capitalize", paddingTop: "10px" }}
                  >
                    {content.about[0].substring(0, 200)}... Learn more
                  </p>
                )}
              </>
            ) : cardType === "experience" ? (
              <>
                <Link
                  to={`/${cardType}/${content._id}`}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                ></Link>
                <div>
                  <div
                    className="text-overlay"
                    style={{
                      alignItems: "flex-start",
                      position: "relative",
                      height: "100%",
                      justifyContent: "flex-start",
                      padding: "0",
                      gap: "10px",
                    }}
                  >
                    <h3>
                      {content.name.substring(0, 28)}
                      {content.name.length > 28 && "..."}
                    </h3>
                    <p>
                      {content.location.city},{" "}
                      {content.location.province
                        .split(" ")
                        .map((e) => (e = e[0]))}
                    </p>
                    <h5>{content.category}</h5>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  {user && <Benefit visual={user.head.avatar} />}
                  <Rating
                    value={
                      content.reviews.reduce(
                        (acc, item) => item.rating + acc,
                        0,
                      ) / content.reviews.length
                    }
                    color="beige"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CardH;
