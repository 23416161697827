import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { addExperience } from "../actions/adminActions";
import { listUserDetails, listUsers } from "../actions/userActions";
import { listRelatedMedia, uploadMedia } from "../actions/mediaActions";
// import { checkFinancialProfile } from "../actions/hostActions";
import { requestExperienceValidate } from "../actions/experienceActions";
import { ADMIN_ADD_EXPERIENCE_RESET } from "../constants/adminConstants";
import {
  USER_DETAILS_RESET,
  USER_LIST_RESET,
} from "../constants/userConstants";
import {
  MEDIA_LIST_RESET,
  MEDIA_UPLOAD_RESET,
} from "../constants/mediaConstants";
// import { HOST_DETAILS_RESET } from "../constants/hostConstants";
import { EXPERIENCE_VALIDATE_RESET } from "../constants/experienceConstants";

import grades from "../data/grades";
import bigIdeas from "../data/bigIdeas";
import experienceSubjects from "../data/experienceSubjects";
import contents from "../data/contents";
import curriculumCompetencies from "../data/curriculumCompetencies";
import {
  coreCompetencies,
  coreCompetenciesDescriptions,
} from "../data/coreCompetencies";
import {
  timezones,
  daysOfWeek,
  months,
  provincesObj,
  provinces,
  regions,
  wizardSteps,
  experienceTypes,
} from "../data/data";
import countries from "../data/countries";
import cities from "../data/cities";

import Loader from "../components/Loader";
import Message from "../components/Message";

const AddExperienceScreen = ({ history }) => {
  const dispatch = useDispatch();
  // dispatch({type: HOST_DETAILS_RESET})

  // states
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorUsers, users } = userList;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingUser, error: errorUser, user } = userDetails;

  // const hostDetails = useSelector((state) => state.hostDetails);
  // const { loading: loadingHost, error: errorHost, success: host } = hostDetails;

  const adminExperienceAdd = useSelector((state) => state.adminExperienceAdd);
  const { loading, error, experience } = adminExperienceAdd;

  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const experienceRequestValidate = useSelector(
    (state) => state.experienceRequestValidate,
  );
  const {
    loading: loadingValidate,
    error: errorValidate,
    success: successValidate,
  } = experienceRequestValidate;

  const mediaUpload = useSelector((state) => state.mediaUpload);

  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  // auth
  useEffect(() => {
    if (userInfo?.type !== "teacher") {
      if (userInfo?.type === "admin") {
        dispatch(listUsers("partner"));
      }

      if (userInfo?.type === "partner") {
        if (!user || user._id !== userInfo._id) {
          dispatch(listUserDetails(userInfo._id, userInfo.type));
        }
      }
    } else {
      history.push("/account");
    }
  }, [dispatch, userInfo, user, history]);

  useEffect(() => {
    if (experience && userInfo) {
      setTimeout(() => {
        if (userInfo.type === "partner") {
          history.push(`/${userInfo.type}/${userInfo._id}`);
        } else {
          window.location.reload();
        }
      }, 5000);
    }
  }, [experience, history, userInfo]);

  // useEffect(() => {
  //   if (errorHost) {
  //     setTimeout(() => {
  //       dispatch({ type: HOST_DETAILS_RESET });
  //       switch (userInfo.type) {
  //         case "partner":
  //           history.push(`/host`);
  //           break;
  //         case "admin":
  //           history.push("/");
  //           break;
  //         default:
  //           break;
  //       }
  //     }, 5000);
  //   }
  // }, [errorHost, userInfo, dispatch, history]);

  // wizard
  const [wizardHandle, SetWizardHandle] = useState(0);

  const resetWizardStepHandler = (e) => {
    e.preventDefault();
    SetWizardHandle(0);
  };

  const wizardStepHandler = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case "+":
        if (wizardHandle < wizardSteps.length - 1) {
          SetWizardHandle(wizardHandle + 1);
        }
        break;
      case "-":
        if (wizardHandle > 0) {
          SetWizardHandle(wizardHandle - 1);
        }
        break;
      default:
        break;
    }
  };

  // step 1 - general info
  const [partner, setPartner] = useState(``);
  const [about, setAbout] = useState(``);
  const [category, setCategory] = useState(``);
  const [name, setName] = useState(``);
  const [type, setType] = useState(`hide`);
  const [description, setDescription] = useState(``);
  const [duration, setDuration] = useState(``);
  const [features, setFeatures] = useState(``);
  const [priorKnowledge, setPriorKnowledge] = useState(``);
  const [checkedCores, setCheckedCores] = useState(
    new Array(coreCompetencies.length).fill(false),
  );
  // eslint-disable-next-line
  const [selectedCores, setSelectedCores] = useState([]);

  useEffect(() => {
    if (userInfo) {
      switch (userInfo.type) {
        case "admin":
          if (users?.length && partner?.length && partner !== "hide") {
            if (users.find((user) => user._id === partner)) {
              setAbout(users.find((user) => user._id === partner).bio);
              setCategory(users.find((user) => user._id === partner).category);

              // dispatch(
              //   checkFinancialProfile(
              //     users.find((user) => user._id.toString() === partner)._id,
              //   ),
              // );
            }
          }
          break;
        case "partner":
          setPartner(userInfo._id);
          if (user) {
            // dispatch(checkFinancialProfile(user._id.toString()));

            setAbout(user.bio);
            setCategory(user.category);
          }
          break;
        default:
          break;
      }
    }
  }, [user, userInfo, users, partner]);

  // useEffect(() => {
  //   if (userInfo) {
  //     switch (userInfo.type) {
  //       case "admin":
  //         if (partner) {
  //           dispatch(
  //             checkFinancialProfile(
  //               users.find((user) => user._id.toString() === partner)._id,
  //             ),
  //           );
  //         }
  //         break;
  //       case "partner":
  //         if (user) {
  //           dispatch(checkFinancialProfile(user._id.toString()));
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [userInfo, partner, user, users, dispatch]);

  const partnerHandler = (e) => {
    e.preventDefault();
    setPartner(e.target.value);
  };

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const typeHandler = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  const descriptionHandler = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const durationHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setDuration(Number(e.target.value));
    }
  };

  const featuresHandler = (e) => {
    e.preventDefault();
    setFeatures(e.target.value);
  };

  const priorKnowledgeHandler = (e) => {
    e.preventDefault();
    setPriorKnowledge(e.target.value);
  };

  const handleOnChangeCores = (positionCores) => {
    const updatedCheckedCores = checkedCores.map((itemCores, indexCores) =>
      indexCores === positionCores ? !itemCores : itemCores,
    );

    setCheckedCores(updatedCheckedCores);
  };

  useEffect(() => {
    setSelectedCores(
      coreCompetencies.filter(
        (ele) => checkedCores[coreCompetencies.indexOf(ele)] === true,
      ),
    );
  }, [checkedCores]);

  // step 2 - location
  const [address, setAddress] = useState(``);
  const [city, setCity] = useState(``);
  const [region, setRegion] = useState(`hide`);
  const [province, setProvince] = useState(`hide`);
  const [country, setCountry] = useState(``);

  const addressHandler = (e) => {
    e.preventDefault();
    setAddress(e.target.value);
  };

  const cityHandler = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const regionHandler = (e) => {
    e.preventDefault();
    setRegion(e.target.value);
  };

  const provinceHandler = (e) => {
    e.preventDefault();
    setProvince(e.target.value);
  };

  const countryHandler = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  // step 3 - availability
  const [startMonth, setStartMonth] = useState(`hide`);
  const [endMonth, setEndMonth] = useState(`hide`);
  const [startTime, setStartTime] = useState(``);
  const [endTime, setEndTime] = useState(``);
  const [timezone, setTimezone] = useState(`hide`);
  const [checkedDays, setCheckedDays] = useState(
    new Array(daysOfWeek.length).fill(false),
  );
  const [selectedDays, setSelectedDays] = useState([]);

  const startMonthHandler = (e) => {
    e.preventDefault();
    setStartMonth(e.target.value);
  };

  const endMonthHandler = (e) => {
    e.preventDefault();
    setEndMonth(e.target.value);
  };

  const startTimeHandler = (e) => {
    e.preventDefault();
    setStartTime(e.target.value);
  };

  const endTimeHandler = (e) => {
    e.preventDefault();
    setEndTime(e.target.value);
  };

  const timezoneHandler = (e) => {
    e.preventDefault();
    setTimezone(e.target.value);
  };

  const handleOnChangeDays = (positionDays) => {
    const updatedCheckedDays = checkedDays.map((itemDays, indexDays) =>
      indexDays === positionDays ? !itemDays : itemDays,
    );

    setCheckedDays(updatedCheckedDays);
  };

  useEffect(() => {
    setSelectedDays(
      daysOfWeek
        .filter((ele) => checkedDays[daysOfWeek.indexOf(ele)] === true)
        .map((day) => day.substring(0, 3)),
    );
  }, [checkedDays]);

  // step 4 - add-ons
  const [addOns, setAddOns] = useState([]);

  const [addOnTitle, setAddOnTitle] = useState(``);
  const [addOnDescription, setAddOnDescription] = useState(``);
  const [addOnPrice, setAddOnPrice] = useState(``);

  const removeAddOn = (e) => {
    e.preventDefault();
    setAddOns(addOns.filter((ao) => ao.addOnTitle !== e.target.id));
  };

  const pushAddOn = (e) => {
    e.preventDefault();

    if (addOnTitle?.length && addOnDescription?.length && addOnPrice?.length) {
      const addOnObject = {
        addOnTitle,
        addOnDescription,
        addOnPrice: Number(addOnPrice),
      };

      setAddOns([...addOns, addOnObject]);

      //
      setAddOnTitle("");
      setAddOnDescription("");
      setAddOnPrice("");
    }
  };

  const addOnTitleHandler = (e) => {
    e.preventDefault();
    setAddOnTitle(e.target.value);
  };

  const addOnDescriptionHandler = (e) => {
    e.preventDefault();
    setAddOnDescription(e.target.value);
  };

  const addOnPriceHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setAddOnPrice(e.target.value);
    }
  };

  useEffect(() => {}, [addOns]);

  // step 5 - pricing
  const [amount, setAmount] = useState(null);
  const [pricingType, setPricingType] = useState(`hide`);
  const [maxStudents, setMaxStudents] = useState(``);

  const amountHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setAmount(Number(e.target.value));
    }
  };

  useEffect(() => {
    if (amount === 0) {
      setPricingType("novalue");
    } else {
      setPricingType("hide");
    }
  }, [amount]);

  const pricingTypeHandler = (e) => {
    e.preventDefault();
    if (amount === 0) {
      setPricingType("novalue");
    } else {
      setPricingType(e.target.value);
    }
  };

  const maxStudentsHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setMaxStudents(Number(e.target.value));
    }
  };

  // step 6, 7 - subject, grades
  const [subject, setSubject] = useState(`hide`);
  const [grade, setGrade] = useState(`hide`);
  const [note, setNote] = useState(``);

  const subjectHandler = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const gradeHandler = (e) => {
    e.preventDefault();
    setGrade(e.target.value);
    if (
      subject?.length &&
      subject !== `hide` &&
      grade?.length &&
      grade !== `hide`
    ) {
      setCheckedBigIdeas(
        new Array(
          bigIdeas
            .find((group) => group.subject === subject)
            .data.find((subGroup) => subGroup.grade === grade).ideas.length,
        ).fill(false),
      );

      setCheckedCurriculums(
        new Array(
          curriculumCompetencies
            .find((group) => group.subject === subject)
            .data.find((subGroup) => subGroup.grade === grade).points.length,
        ).fill(false),
      );

      setCheckedContents(
        new Array(
          contents
            .find((group) => group.subject === subject)
            .data.find((subGroup) => subGroup.grade === grade).contents.length,
        ).fill(false),
      );
    }
  };

  const noteHandler = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };

  const [checkedBigIdeas, setCheckedBigIdeas] = useState([]);
  const [checkedCurriculums, setCheckedCurriculums] = useState([]);
  const [checkedContents, setCheckedContents] = useState([]);

  const handleOnChangeBigIdeas = (positionIdeas) => {
    const updatedCheckedBigIdeas = checkedBigIdeas.map(
      (itemIdeas, indexIdeas) =>
        indexIdeas === positionIdeas ? !itemIdeas : itemIdeas,
    );

    setCheckedBigIdeas(updatedCheckedBigIdeas);
  };

  const handleOnChangeContents = (positionContents) => {
    const updatedCheckedContents = checkedContents.map(
      (itemContents, indexContents) =>
        indexContents === positionContents ? !itemContents : itemContents,
    );

    setCheckedContents(updatedCheckedContents);
  };

  const handleOnChangeCurriculums = (positionCurriculums) => {
    const updatedCheckedCurriculums = checkedCurriculums.map(
      (itemCurriculums, indexCurriculums) =>
        indexCurriculums === positionCurriculums
          ? !itemCurriculums
          : itemCurriculums,
    );

    setCheckedCurriculums(updatedCheckedCurriculums);
  };

  useEffect(() => {
    if (
      subject?.length &&
      subject !== `hide` &&
      grade?.length &&
      grade !== `hide`
    ) {
      if (
        grades.indexOf(grade) <
        bigIdeas.find((group) => group.subject === subject).data.length
      ) {
        setCheckedBigIdeas(
          new Array(
            bigIdeas
              .find((group) => group.subject === subject)
              .data.find((subGroup) => subGroup.grade === grade).ideas.length,
          ).fill(false),
        );
      }

      if (
        grades.indexOf(grade) <
        curriculumCompetencies.find((group) => group.subject === subject).data
          .length
      ) {
        setCheckedCurriculums(
          new Array(
            curriculumCompetencies
              .find((group) => group.subject === subject)
              .data.find((subGroup) => subGroup.grade === grade).points.length,
          ).fill(false),
        );
      }

      if (
        grades.indexOf(grade) <
        contents.find((group) => group.subject === subject).data.length
      ) {
        setCheckedContents(
          new Array(
            contents
              .find((group) => group.subject === subject)
              .data.find(
                (subGroup) => subGroup.grade === grade,
              ).contents.length,
          ).fill(false),
        );
      }
    } else {
      setCheckedBigIdeas([]);
      setCheckedContents([]);
      setCheckedCurriculums([]);
    }
  }, [subject, grade]);

  const [finalGrades, setFinalGrades] = useState([]);

  const removeGrade = (e) => {
    e.preventDefault();
    setFinalGrades(finalGrades.filter((obj) => obj.grade !== e.target.id));
  };

  const pushGrade = (e) => {
    e.preventDefault();

    if (
      subject?.length &&
      subject !== `hide` &&
      grade?.length &&
      grade !== `hide` &&
      checkedBigIdeas?.length &&
      checkedCurriculums?.length &&
      checkedContents?.length &&
      !finalGrades.find((obj) => obj.grade === grade)
    ) {
      const gradeObject = {
        grade,
        checkedBigIdeas: checkedBigIdeas
          .map((ele, index) => (ele ? index : ""))
          .join(""),
        checkedCurriculums: checkedCurriculums
          .map((ele, index) => (ele ? index : ""))
          .join(""),
        checkedContents: checkedContents
          .map((ele, index) => (ele ? index : ""))
          .join(""),
      };

      setFinalGrades([...finalGrades, gradeObject]);
      //
      setCheckedBigIdeas([]);
      setCheckedContents([]);
      setCheckedCurriculums([]);
    }
  };

  // step 8 - media
  const [elementContent, setElementContent] = useState(``);
  const [elementType, setElementType] = useState(`hide`);
  const visualTypes = ["banner", "hero"];

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  useEffect(() => {
    if (file) {
      dispatch(listRelatedMedia(userInfo._id, userInfo.type));
    }
  }, [file, dispatch, userInfo]);

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const elementTypeHandler = (e) => {
    e.preventDefault();
    setElementType(e.target.value);
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia(userInfo._id, userInfo.type));
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  useEffect(() => {
    if (wizardHandle === 7) {
      if (!files) {
        dispatch(listRelatedMedia(userInfo._id, userInfo.type));
      }
    }
    // eslint-disable-next-line
  }, [wizardHandle, dispatch]);

  const [finalMedia, setFinalMedia] = useState([]);

  const removeMedia = (e) => {
    e.preventDefault();
    setFinalMedia(finalMedia.filter((obj) => obj.elementType !== e.target.id));
  };

  const pushMedia = (e) => {
    e.preventDefault();

    if (
      elementContent?.length &&
      elementType?.length &&
      elementType !== "hide" &&
      !finalMedia.find((obj) => obj.elementType === elementType)
    ) {
      const mediaObject = {
        elementContent,
        elementType,
      };

      setFinalMedia([...finalMedia, mediaObject]);
      //
      setElementContent("");
      setElementType(`hide`);
    }
  };

  const [errorInsert, setErrorInsert] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      partner?.length &&
      partner !== "hide" &&
      about?.length &&
      category?.length &&
      name?.length &&
      type !== "hide" &&
      type?.length &&
      description.length > 100 &&
      duration > 0 &&
      features?.length &&
      priorKnowledge?.length &&
      checkedCores?.length &&
      checkedCores.map((ele, index) => (ele ? index : "")).join("").length >
        0 &&
      address?.length &&
      city?.length &&
      region?.length &&
      region !== "hide" &&
      province?.length &&
      province !== "hide" &&
      country?.length &&
      startMonth !== "hide" &&
      startMonth?.length &&
      endMonth !== "hide" &&
      endMonth?.length &&
      startTime?.length &&
      endTime?.length &&
      timezone !== "hide" &&
      timezone?.length &&
      selectedDays?.length &&
      amount !== null &&
      pricingType !== "hide" &&
      pricingType?.length &&
      maxStudents.toString()?.length &&
      subject?.length &&
      subject !== "hide" &&
      finalGrades?.length
    ) {
      const listing = {
        partner,
        about,
        category,
        name,
        type,
        description,
        duration,
        features: features
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length),
        priorKnowledge: priorKnowledge
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length),
        coreCompetencies: checkedCores
          .map((ele, index) => (ele ? index : ""))
          .join(""),
        location: { address, city, region, province, country },
        availability: JSON.stringify({
          startMonth,
          endMonth,
          selectedDays,
          startTime,
          endTime,
          timezone,
        }),
        note,
        addOns,
        price: { amount, pricingType },
        maxStudents,
        subject,
        grade: finalGrades.map((obj) => obj.grade).join(","),
        bigIdeas: finalGrades.map((obj) => obj.checkedBigIdeas),
        curriculumCompetencies: finalGrades.map(
          (obj) => obj.checkedCurriculums,
        ),
        content: finalGrades.map((obj) => obj.checkedContents),
      };

      if (finalMedia?.length) {
        listing.banner =
          finalMedia.find((ele) => ele.elementType === "banner")
            ?.elementContent || "";
        listing.hero = {
          type: "image",
          url:
            finalMedia.find((ele) => ele.elementType === "hero")
              ?.elementContent || "",
        };
      } else {
        listing.banner = "";
        listing.hero = { type: "image", url: "" };
      }

      if (userInfo.type === "admin") {
        // if admin, then add the experience.
        dispatch(addExperience(listing, userInfo.type));
      } else if (userInfo.type === "partner") {
        // if partner, then request the validation.
        dispatch(requestExperienceValidate(listing, Date.now().toString()));
      }
    } else {
      if (description?.length && description.length < 100) {
        setErrorInsert(
          "description field has to be more than 100 characters length",
        );
      } else {
        setErrorInsert("* = requried fields");
      }
    }
  };

  useEffect(() => {
    if (successValidate) {
      setTimeout(() => {
        dispatch({ type: EXPERIENCE_VALIDATE_RESET });
        history.push("/");
      }, 5000);
    }
  }, [history, successValidate, dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: ADMIN_ADD_EXPERIENCE_RESET });
      }, 5000);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (errorUsers) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorUsers, dispatch]);

  useEffect(() => {
    if (errorUser) {
      setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET });
      }, 5000);
    }
  }, [errorUser, dispatch]);

  useEffect(() => {
    if (errorFile) {
      setTimeout(() => {
        dispatch({ type: MEDIA_UPLOAD_RESET });
      }, 5000);
    }
  }, [errorFile, dispatch]);

  useEffect(() => {
    if (errorFiles) {
      setTimeout(() => {
        dispatch({ type: MEDIA_LIST_RESET });
      }, 5000);
    }
  }, [errorFiles, dispatch]);

  useEffect(() => {
    if (errorInsert) {
      setTimeout(() => {
        setErrorInsert(null);
      }, 5000);
    }
  }, [errorInsert]);

  // useEffect(() => {
  //   if (errorHost) {
  //     setTimeout(() => {
  //       dispatch({ type: HOST_DETAILS_RESET });
  //     }, 5000);
  //   }
  // }, [errorHost, dispatch]);

  useEffect(() => {
    if (errorValidate) {
      setTimeout(() => {
        dispatch({ type: EXPERIENCE_VALIDATE_RESET });
      }, 5000);
    }
  }, [errorValidate, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {loadingUsers && <Loader />}
      {loadingUser && <Loader />}
      {loadingFiles && <Loader />}
      {loadingFile && <Loader />}
      {/* {loadingHost && <Loader />} */}
      {loadingValidate && <Loader />}
      {error && <Message body={error} />}
      {errorUsers && <Message body={errorUsers} />}
      {errorUser && <Message body={errorUser} />}
      {errorFiles && <Message body={errorFiles} />}
      {errorFile && <Message body={errorFile} />}
      {errorInsert && <Message body={errorInsert} />}
      {/* {errorHost && <Message body={errorHost} />} */}
      {errorValidate && <Message body={errorValidate} />}
      {experience && (
        <Message
          body={`Experience is created successfully. Please wait for the reload`}
          type="info"
        />
      )}
      {successValidate && (
        <Message
          body={`Experience validation request has been send to admin. Our team will review your request and will notify you soon. Thank you!`}
          type="info"
        />
      )}
      {userInfo && (
        <div className="container">
          <form
            className="add-experience-form"
            onSubmit={submitHandler}
            noValidate
          >
            <div className="wizard-head">
              <div>
                <h3>Add Experience</h3>
                <p>Fields marked with an * are required</p>
              </div>
              {partner?.length && partner !== "hide" ? (
                <div className="wizard-toggle">
                  <Link className="btn cancel-btn" to="/">
                    cancel
                  </Link>
                  {wizardHandle > 0 && (
                    <button className="btn" onClick={resetWizardStepHandler}>
                      home
                    </button>
                  )}
                  {wizardHandle > 0 && (
                    <button className="btn" id="-" onClick={wizardStepHandler}>
                      back
                    </button>
                  )}
                  {wizardHandle < wizardSteps.length - 1 && (
                    <button className="btn" id="+" onClick={wizardStepHandler}>
                      next
                    </button>
                  )}
                  {wizardHandle === wizardSteps.length - 1 && (
                    <button
                      className="btn submit-btn"
                      type="submit"
                      onSubmit={submitHandler}
                    >
                      submit
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="wizard-step"
              style={{ display: wizardHandle === 0 ? "flex" : "none" }}
            >
              <h5>step 1 - general info *</h5>
              {userInfo.type === "admin" && (
                <div className="select">
                  <select onChange={partnerHandler}>
                    <option value="hide">select partner *</option>
                    {users && users?.length ? (
                      users
                        .sort(function (a, b) {
                          if (
                            a.head.name.toString().toLowerCase() <
                            b.head.name.toString().toLowerCase()
                          ) {
                            return -1;
                          }
                          if (
                            a.head.name.toString().toLowerCase() >
                            b.head.name.toString().toLowerCase()
                          ) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.head.name}
                          </option>
                        ))
                    ) : (
                      <option disabled>
                        No partners available. Please refresh the page.
                      </option>
                    )}
                  </select>
                </div>
              )}
              {partner?.length && partner !== "hide" ? (
                <>
                  <input
                    type="text"
                    placeholder="title *"
                    onChange={nameHandler}
                    value={name}
                    required
                  />
                  <div className="multiple-input-2">
                    <div className="select">
                      <select onChange={typeHandler}>
                        <option value="hide">select type *</option>
                        {experienceTypes.map((ele, index) => (
                          <option value={ele} key={index}>
                            {ele}
                          </option>
                        ))}
                      </select>
                    </div>
                    <input
                      className="duration"
                      type="number"
                      placeholder="duration (in minutes) *"
                      onChange={durationHandler}
                      value={duration}
                    />
                  </div>
                  <textarea
                    onChange={descriptionHandler}
                    placeholder="description (min. 100 words) *"
                    value={description}
                    required
                  />
                  <textarea
                    onChange={featuresHandler}
                    placeholder="list what students will experience during this offering. Tell the different components that make up the full experience. *"
                    value={features}
                  />
                  <textarea
                    onChange={priorKnowledgeHandler}
                    placeholder="list what students need to review in order to understand this lesson. *"
                    value={priorKnowledge}
                  />
                  <div className="core-competencies">
                    <h5>core competencies *</h5>
                    <div>
                      {coreCompetencies.map((ele, indexCores) => (
                        <div key={indexCores}>
                          <input
                            type="checkbox"
                            id={indexCores + 100}
                            name={indexCores}
                            checked={checkedCores[indexCores]}
                            onChange={() => handleOnChangeCores(indexCores)}
                          />
                          <label
                            htmlFor={indexCores + 100}
                            title={coreCompetenciesDescriptions[
                              indexCores
                            ].copy.join("\n\n")}
                          >
                            <p style={{ fontWeight: "400" }}>{ele}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 1 ? "flex" : "none" }}
            >
              <h5>step 2 - location *</h5>
              <input
                type="text"
                placeholder="address *"
                onChange={addressHandler}
                value={address}
                required
              />
              <div className="multiple-input-2">
                <div className="select">
                  <select onChange={provinceHandler}>
                    <option value="hide">select province/ territory *</option>
                    {provinces.sort().map((prov, index) => (
                      <option key={index} value={prov}>
                        {prov}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="select">
                  <select onChange={regionHandler}>
                    <option value="hide">select region *</option>
                    {province?.length && province !== "hide" ? (
                      regions
                        .find((ele) => ele.province === province)
                        .list.sort()
                        .map((reg, index) => (
                          <option key={index} value={reg}>
                            {reg}
                          </option>
                        ))
                    ) : (
                      <option disabled>
                        No region available. Please select province.
                      </option>
                    )}
                  </select>
                </div>
              </div>
              <div className="multiple-input-2">
                <div className="select">
                  <select onChange={cityHandler}>
                    <option value="hide">select city *</option>
                    {province?.length && province !== "hide" ? (
                      cities
                        .filter(
                          (ele) =>
                            ele[1] ===
                            Object.keys(provincesObj)[
                              provinces.indexOf(province)
                            ],
                        )
                        .sort()
                        .map((city, index) => (
                          <option
                            key={index}
                            value={city[0]}
                            style={{ textTransform: "capitalize" }}
                          >
                            {city[0]}
                          </option>
                        ))
                    ) : (
                      <option disabled>
                        No city available. Please select province.
                      </option>
                    )}
                  </select>
                </div>
                <div className="select">
                  <select onChange={countryHandler} defaultValue={country}>
                    <option value="hide">select country *</option>
                    {countries.map((ele, index) => (
                      <option key={index} value={ele.name}>
                        {ele.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 2 ? "flex" : "none" }}
            >
              <h5>step 3 - availability *</h5>
              <div className="date-time multiple-input-5">
                <div className="select">
                  <select
                    onChange={startMonthHandler}
                    defaultValue={startMonth}
                  >
                    <option value="hide">start month *</option>
                    {months.map((month, index) => (
                      <option key={index} value={month.substring(0, 3)}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="select">
                  <select onChange={endMonthHandler} defaultValue={endMonth}>
                    <option value="hide">end month *</option>
                    {months.map((month, index) => (
                      <option key={index} value={month.substring(0, 3)}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="toggle-time">
                  <label>start time *</label>
                  <input
                    type="time"
                    onChange={startTimeHandler}
                    value={startTime}
                  />
                </div>
                <div className="toggle-time">
                  <label>end time *</label>
                  <input
                    type="time"
                    onChange={endTimeHandler}
                    value={endTime}
                  />
                </div>
                <div className="select">
                  <select onChange={timezoneHandler} defaultValue={timezone}>
                    <option value="hide">timezone *</option>
                    {timezones.sort().map((zone, index) => (
                      <option key={index} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="days-of-week">
                <label
                  style={{
                    fontWeight: "200",
                    fontSize: "var(--a-font-size)",
                    opacity: "50%",
                  }}
                >
                  days of the week *
                </label>
                <div className="days">
                  {daysOfWeek.map((ele, indexDays) => (
                    <div key={indexDays}>
                      <input
                        type="checkbox"
                        id={indexDays + 500}
                        name={indexDays}
                        checked={checkedDays[indexDays]}
                        onChange={() => handleOnChangeDays(indexDays)}
                      />
                      <label htmlFor={indexDays + 500}>
                        <p style={{ fontWeight: "400" }}>{ele}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <textarea
                onChange={noteHandler}
                placeholder="note (optional)"
                value={note}
              />
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 3 ? "flex" : "none" }}
            >
              <h5>step 4 - add-ons (optional)</h5>
              <div className="addons-inputs">
                <input
                  type="text"
                  placeholder="title"
                  onChange={addOnTitleHandler}
                  value={addOnTitle}
                />
                <input
                  type="text"
                  placeholder="description"
                  onChange={addOnDescriptionHandler}
                  value={addOnDescription}
                />
                <input
                  type="number"
                  placeholder="price"
                  onChange={addOnPriceHandler}
                  value={addOnPrice}
                />
                <button
                  className="btn"
                  style={{ height: "60px", padding: "30px", fontSize: "24px" }}
                  onClick={pushAddOn}
                >
                  +
                </button>
              </div>

              {addOns?.length ? (
                <div className="addons">
                  {addOns.map((ele, indexAddOns) => (
                    <div key={indexAddOns}>
                      <span key={indexAddOns}>
                        <p>
                          {ele.addOnTitle.substring(0, 20)}
                          {ele.addOnTitle.length > 20 && "..."}
                        </p>
                        <p>
                          {ele.addOnDescription.substring(0, 100)}
                          {ele.addOnDescription.length > 100 && "..."}
                        </p>
                        <p>${ele.addOnPrice}</p>
                      </span>
                      <button
                        className="btn"
                        style={{
                          height: "60px",
                          padding: "30px",
                          fontSize: "24px",
                        }}
                        id={ele.addOnTitle}
                        onClick={removeAddOn}
                      >
                        <div style={{ transform: "rotateZ(45deg)" }}>+</div>
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 4 ? "flex" : "none" }}
            >
              <h5>step 5 - pricing *</h5>
              <div className="pricing">
                <input
                  type="number"
                  placeholder="price * (0 = free)"
                  onChange={amountHandler}
                  value={amount === null ? "" : `${amount}`}
                  required
                />
                <div className="select">
                  <select onChange={pricingTypeHandler} value={pricingType}>
                    <option value="hide" disabled={amount === 0}>
                      select pricing type *
                    </option>
                    <option value="class" disabled={amount === 0}>
                      per class
                    </option>
                    <option value="student" disabled={amount === 0}>
                      per student
                    </option>
                    <option value="novalue">not specified</option>
                  </select>
                </div>
                <input
                  type="number"
                  placeholder="max students (0 = no maximum) *"
                  onChange={maxStudentsHandler}
                  value={maxStudents}
                />
              </div>
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 5 ? "flex" : "none" }}
            >
              <h5>step 6 - subject *</h5>
              <div className="select">
                <select onChange={subjectHandler}>
                  <option value="hide">select subject *</option>
                  {experienceSubjects.sort().map((ele, index) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 6 ? "flex" : "none" }}
            >
              <h5>step 7 - grades *</h5>
              {finalGrades?.length ? (
                <div className="cells">
                  {finalGrades.map((ele, index) => (
                    <div
                      key={index}
                      className="cell"
                      style={{ width: "fit-content" }}
                    >
                      <p>{ele.grade}</p>
                      <button
                        id={ele.grade}
                        className="btn"
                        style={{ padding: "0 20px" }}
                        onClick={removeGrade}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}

              <div className="select">
                <select onChange={gradeHandler}>
                  <option value="hide">select grade *</option>
                  {subject?.length && subject !== "hide" ? (
                    grades.map(
                      (ele, indexGrades) =>
                        indexGrades <
                          bigIdeas.find((group) => group.subject === subject)
                            .data.length &&
                        indexGrades <
                          curriculumCompetencies.find(
                            (group) => group.subject === subject,
                          ).data.length &&
                        indexGrades <
                          contents.find((group) => group.subject === subject)
                            .data.length && (
                          <option
                            key={indexGrades}
                            value={ele}
                            disabled={finalGrades
                              .map((fg) => fg.grade)
                              .includes(ele)}
                          >
                            {ele}
                          </option>
                        ),
                    )
                  ) : (
                    <option disabled>
                      No grades available. Please select a subject.
                    </option>
                  )}
                </select>
              </div>
              {checkedBigIdeas?.length && grade !== "hide" ? (
                <div className="big-ideas">
                  <h5 style={{ marginTop: "30px" }}>big ideas *</h5>
                  {checkedBigIdeas.map((idea, indexIdeas) => (
                    <div key={indexIdeas}>
                      <input
                        type="checkbox"
                        id={indexIdeas}
                        name={indexIdeas}
                        value={checkedBigIdeas}
                        checked={checkedBigIdeas[indexIdeas]}
                        onChange={() => handleOnChangeBigIdeas(indexIdeas)}
                      />
                      <label htmlFor={indexIdeas}>
                        <p>
                          {
                            bigIdeas
                              .find((group) => group.subject === subject)
                              .data.find((subGroup) => subGroup.grade === grade)
                              .ideas[indexIdeas]
                          }
                        </p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {checkedCurriculums?.length && grade !== "hide" ? (
                <div className="curriculum-competencies">
                  <h5 style={{ marginTop: "30px" }}>
                    curriculum competencies *
                  </h5>
                  <p style={{ opacity: "50%" }}>
                    By participating in this program, students will interact
                    with the following Curriculum Competencies:
                  </p>
                  {checkedCurriculums.map((ele, indexCurriculums) => (
                    <div key={indexCurriculums}>
                      <input
                        type="checkbox"
                        id={indexCurriculums + 200}
                        name={indexCurriculums}
                        value={checkedCurriculums}
                        checked={checkedCurriculums[indexCurriculums]}
                        onChange={() =>
                          handleOnChangeCurriculums(indexCurriculums)
                        }
                      />
                      <label
                        htmlFor={indexCurriculums + 200}
                        title={
                          curriculumCompetencies
                            .find((group) => group.subject === subject)
                            .data.find((subGroup) => subGroup.grade === grade)
                            .points[indexCurriculums].description.length > 170
                            ? curriculumCompetencies
                                .find((group) => group.subject === subject)
                                .data.find(
                                  (subGroup) => subGroup.grade === grade,
                                ).points[indexCurriculums].description
                            : ""
                        }
                        style={{
                          display: "flex",
                          flexDirection:
                            window.innerWidth < 835 ? "column" : "",
                        }}
                      >
                        <p style={{ fontWeight: "400" }}>
                          {
                            curriculumCompetencies
                              .find((group) => group.subject === subject)
                              .data.find((subGroup) => subGroup.grade === grade)
                              .points[indexCurriculums].handle
                          }
                          :
                        </p>
                        <p
                          style={{
                            paddingLeft: window.innerWidth < 835 ? "" : "10px",
                          }}
                        >
                          {curriculumCompetencies
                            .find((group) => group.subject === subject)
                            .data.find((subGroup) => subGroup.grade === grade)
                            .points[indexCurriculums].description.substring(
                              0,
                              180 -
                                curriculumCompetencies
                                  .find((group) => group.subject === subject)
                                  .data.find(
                                    (subGroup) => subGroup.grade === grade,
                                  ).points[indexCurriculums].handle.length,
                            )}
                          {curriculumCompetencies
                            .find((group) => group.subject === subject)
                            .data.find((subGroup) => subGroup.grade === grade)
                            .points[indexCurriculums].description.length >
                            140 && `...`}
                        </p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {checkedContents?.length && grade !== "hide" ? (
                <div
                  style={{
                    paddingLeft: "30px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    margin: "10px 0",
                  }}
                >
                  <h5 style={{ marginTop: "30px" }}>content *</h5>
                  <p style={{ opacity: "50%" }}>
                    The content of these sessions can incorporate any of these
                    content items identified by the Province:
                  </p>
                  {checkedContents.map((ele, indexContents) => (
                    <div
                      key={indexContents}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={indexContents + 300}
                        name={indexContents}
                        value={checkedContents}
                        checked={checkedContents[indexContents]}
                        onChange={() => handleOnChangeContents(indexContents)}
                      />
                      <label htmlFor={indexContents + 300}>
                        <p>
                          {
                            contents
                              .find((group) => group.subject === subject)
                              .data.find((subGroup) => subGroup.grade === grade)
                              .contents[indexContents]
                          }
                        </p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {subject?.length &&
              subject !== "hide" &&
              grade?.length &&
              grade !== "hide" ? (
                <button
                  className="btn"
                  style={{ alignSelf: "flex-end" }}
                  onClick={pushGrade}
                >
                  add
                </button>
              ) : (
                <></>
              )}
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 7 ? "flex" : "none" }}
            >
              <h5>step 8 - add media (optional)</h5>
              {finalMedia?.length ? (
                <div className="cells">
                  {finalMedia.map((ele, index) => (
                    <div key={index} className="cell">
                      <p>{ele.elementType}</p>
                      <p>{ele.elementContent}</p>
                      <button
                        id={ele.elementType}
                        className="btn"
                        style={{ padding: "0 30px" }}
                        onClick={removeMedia}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}

              <div className="add-media">
                {finalMedia.length < 2 && (
                  <div className="select">
                    <select onChange={elementTypeHandler}>
                      <option value="hide">select visual type</option>
                      {visualTypes.map((visual) => (
                        <option
                          key={visual}
                          value={visual}
                          disabled={finalMedia.find(
                            (ele) => ele.elementType === visual,
                          )}
                        >
                          {visual}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {elementType?.length && elementType !== "hide" ? (
                  <div className="inputs">
                    <input
                      type="text"
                      onChange={elementContentHandler}
                      placeholder={`insert ${elementType} url`}
                      min="0"
                      className="experience-new-name"
                      value={elementContent}
                      style={{ flexGrow: "1" }}
                    />
                    <button className="btn" onClick={pushMedia}>
                      set
                    </button>

                    <input
                      type="file"
                      className="experience-new-name"
                      onChange={uploadFileHandler}
                      id="upload-inline-image"
                      style={{ display: "none" }}
                    />
                    <label htmlFor="upload-inline-image" className="btn">
                      upload
                    </label>
                    <button className="btn" onClick={getMediaFiles}>
                      refresh
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {elementType?.length && elementType !== "hide" ? (
                <>
                  {files && files?.length ? (
                    <div className="media-lib">
                      {files.map((file, index) => (
                        <button
                          key={index}
                          className="media-file"
                          style={{
                            backgroundImage: `url(${
                              userInfo.type === "admin" ? file[0] : file
                            }`,
                          }}
                          id={userInfo.type === "admin" ? file[0] : file}
                          onClick={mediaFileUrlHandler}
                        ></button>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            <div
              className="wizard-step"
              style={{ display: wizardHandle === 8 ? "flex" : "none" }}
            >
              <h5 style={{ marginBotton: "0" }}>step 9 - review</h5>
              <p style={{ paddingLeft: "30px" }}>
                Any value in red colour must be filled
              </p>
              {partner?.length &&
              subject?.length &&
              subject !== "hide" &&
              grade?.length &&
              grade !== "hide" ? (
                <></>
              ) : (
                <></>
              )}
              <div className="revision">
                <div className="cells">
                  <div className="cell">
                    <p>title:</p>
                    <p>
                      {name?.length ? name : <span className="n-a">N/A</span>}
                    </p>
                  </div>
                  <div className="cell">
                    <p>type:</p>
                    <p>
                      {type?.length && type !== "hide" ? (
                        type
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>description:</p>
                    <p>
                      {description?.length ? (
                        description
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>duration:</p>
                    <p>
                      {duration.toString()?.length ? (
                        `${duration} mins`
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>max. students:</p>
                    <p>
                      {maxStudents.toString()?.length ? (
                        <>
                          {maxStudents > 0 ? (
                            `${maxStudents} ${
                              maxStudents > 0 ? "students" : ""
                            }`
                          ) : (
                            <span>No maximum</span>
                          )}
                        </>
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>What to expect:</p>
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {features?.length ? (
                        features
                          .split("\n")
                          .map((sentence, index) => (
                            <span key={index}>
                              {sentence?.length ? `- ${sentence}` : ""}
                            </span>
                          ))
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>previous knowledge:</p>
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {priorKnowledge?.length ? (
                        priorKnowledge
                          .split("\n")
                          .map((sentence, index) => (
                            <span key={index}>
                              {sentence?.length ? `- ${sentence}` : ""}
                            </span>
                          ))
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>core competencies:</p>
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {checkedCores
                        .map((ele, index) => (ele ? index : ""))
                        .join("")?.length ? (
                        checkedCores.map(
                          (ele, index) =>
                            checkedCores[index] && (
                              <span key={index}>
                                - {coreCompetencies[index]}
                              </span>
                            ),
                        )
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>address:</p>
                    <p>
                      {address?.length ? (
                        address
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>city:</p>
                    <p>
                      {city?.length ? city : <span className="n-a">N/A</span>}
                    </p>
                  </div>
                  <div className="cell">
                    <p>region:</p>
                    <p>
                      {region !== "hide" ? (
                        region
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>province:</p>
                    <p>
                      {province !== "hide" ? (
                        province
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>country:</p>
                    <p>
                      {country?.length ? (
                        country
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>availability:</p>
                    <p>
                      {startMonth !== "hide" &&
                      startMonth?.length &&
                      endMonth !== "hide" &&
                      endMonth?.length &&
                      startTime?.length &&
                      endTime?.length &&
                      timezone !== "hide" &&
                      timezone?.length ? (
                        <>
                          {`${startMonth}-${endMonth} | ${
                            selectedDays.length === 5
                              ? "Daily"
                              : selectedDays.join(", ")
                          } | ${Number(startTime.split(":")[0]) % 12}:${
                            startTime.split(":")[1]
                          }${
                            Number(startTime.split(":")[0]) >= 12 ? "pm" : "am"
                          }-${Number(endTime.split(":")[0]) % 12}:${
                            endTime.split(":")[1]
                          }${
                            Number(endTime.split(":")[0]) >= 12 ? "pm" : "am"
                          } ${timezone}`}
                        </>
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>note:</p>
                    <p>
                      {note?.length ? (
                        note
                      ) : (
                        <span className="n-a n-r">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>add-ons:</p>
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {addOns?.length ? (
                        addOns.map((ele, index) => (
                          <span
                            key={index}
                            style={{
                              border: "none",
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            <span style={{ width: "200px" }}>
                              - {ele.addOnTitle}:
                            </span>
                            <span style={{ width: "calc(100% - 300px)" }}>
                              {ele.addOnDescription}
                            </span>
                            <span style={{ width: "100px" }}>
                              ${ele.addOnPrice}
                            </span>
                          </span>
                        ))
                      ) : (
                        <span className="n-a n-r">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>price:</p>
                    {amount > 0 &&
                    pricingType?.length &&
                    pricingType !== "hide" ? (
                      <p>
                        ${amount}/{pricingType}
                      </p>
                    ) : (
                      <p className="n-a">N/A</p>
                    )}
                  </div>
                  <div className="cell">
                    <p>subject:</p>
                    <p>
                      {subject?.length && subject !== "hide" ? (
                        subject
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  <div className="cell">
                    <p>grades:</p>
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {subject?.length &&
                      subject !== "hide" &&
                      grade?.length &&
                      grade !== "hide" &&
                      finalGrades?.length ? (
                        finalGrades.map((obj, index) => (
                          <span
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {index > 0 && (
                              <span>
                                ------------------------------------------------------------------------------------
                              </span>
                            )}
                            <span className="n-a">{obj.grade}</span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  color: "var(--var-color-vrc-yellow)",
                                }}
                              >
                                big ideas
                              </span>
                              {obj.checkedBigIdeas
                                .split("")
                                .map((ele, index) => (
                                  <span key={index}>
                                    {
                                      bigIdeas
                                        .find(
                                          (group) => group.subject === subject,
                                        )
                                        .data.find(
                                          (subGroup) =>
                                            subGroup.grade === obj.grade,
                                        ).ideas[ele]
                                    }
                                  </span>
                                ))}
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  color: "var(--var-color-vrc-yellow)",
                                }}
                              >
                                curriculum competencies
                              </span>
                              {obj.checkedCurriculums
                                .split("")
                                .map((ele, index) => (
                                  <span key={index}>
                                    {
                                      curriculumCompetencies
                                        .find(
                                          (group) => group.subject === subject,
                                        )
                                        .data.find(
                                          (subGroup) =>
                                            subGroup.grade === obj.grade,
                                        ).points[ele].handle
                                    }
                                    :{" "}
                                    {curriculumCompetencies
                                      .find(
                                        (group) => group.subject === subject,
                                      )
                                      .data.find(
                                        (subGroup) =>
                                          subGroup.grade === obj.grade,
                                      )
                                      .points[ele].description.substring(
                                        0,
                                        100,
                                      )}
                                    ...
                                  </span>
                                ))}
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  color: "var(--var-color-vrc-yellow)",
                                }}
                              >
                                content
                              </span>
                              {obj.checkedContents
                                .split("")
                                .map((ele, index) => (
                                  <span key={index}>
                                    {contents
                                      .find(
                                        (group) => group.subject === subject,
                                      )
                                      .data.find(
                                        (subGroup) =>
                                          subGroup.grade === obj.grade,
                                      )
                                      .contents[ele].substring(0, 100)}
                                    ...
                                  </span>
                                ))}
                            </span>
                          </span>
                        ))
                      ) : (
                        <span className="n-a">N/A</span>
                      )}
                    </p>
                  </div>
                  {note?.length ? (
                    <div className="cell">
                      <p>note:</p>
                      <p>{note}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="cell">
                    <p>media:</p>
                    <p
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {finalMedia?.length ? (
                        finalMedia.map((obj, index) => (
                          <img src={obj.elementContent} alt="" key={index} />
                        ))
                      ) : (
                        <span className="n-a n-r">N/A</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddExperienceScreen;
