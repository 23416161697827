import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAddUser } from "../actions/adminActions";
import { provinces } from "../components/data";
import Loader from "../components/Loader";
import Message from "../components/Message";
import cities from "../data/cities";
import countries from "../data/countries";
import grades from "../data/grades";
import {
  ADMIN_ADD_USER_FAIL,
  ADMIN_ADD_USER_RESET,
} from "../constants/adminConstants";

const AddTeacherScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || userInfo.type !== "admin") {
      history.push("/account");
    }
  }, [userInfo, history]);

  const adminUserAdd = useSelector((state) => state.adminUserAdd);
  const { loading, error, user } = adminUserAdd;

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [password, setPassword] = useState(``);
  const [confirmedPassword, setConfirmedPassword] = useState(``);
  const [phone, setPhone] = useState(``);
  const [grade, setGrade] = useState("");
  const [school, setSchool] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [schoolDistrict, setSchoolDistrict] = useState("");
  const [city, setCity] = useState(``);
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState(``);
  const [website, setWebsite] = useState(``);
  const [passwordStatus, setPasswordStatus] = useState(`password`);
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] =
    useState(`password`);
  const [passwordIconStatus, setPasswordIconStatus] = useState(`show`);
  const [confirmedPasswordIconStatus, setConfirmedPasswordIconStatus] =
    useState(`show`);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    setConfirmedPassword(e.target.value);
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const countryHandler = (e) => {
    setCountry(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const schoolHandler = (e) => {
    setSchool(e.target.value);
  };

  const schoolDistrictHandler = (e) => {
    setSchoolDistrict(e.target.value);
  };

  const gradeHandler = (e) => {
    setGrade(e.target.value);
  };

  const streetAddressHandler = (e) => {
    setStreetAddress(e.target.value);
  };

  const provinceHandler = (e) => {
    setProvince(e.target.value);
  };

  const passwordStatusHandler = (e) => {
    e.preventDefault();
    if (passwordStatus === "text") {
      setPasswordStatus("password");
    } else {
      setPasswordStatus("text");
    }

    if (passwordIconStatus === "show") {
      setPasswordIconStatus("hide");
    } else {
      setPasswordIconStatus("show");
    }
  };

  const confirmedPasswordStatusHandler = (e) => {
    e.preventDefault();
    if (confirmedPasswordStatus === "text") {
      setConfirmedPasswordStatus("password");
    } else {
      setConfirmedPasswordStatus("text");
    }

    if (confirmedPasswordIconStatus === "show") {
      setConfirmedPasswordIconStatus("hide");
    } else {
      setConfirmedPasswordIconStatus("show");
    }
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmedPassword("");
    setPhone("");
    setWebsite("");
    setGrade("");
    setSchool("");
    setSchoolDistrict("");
    setCity("");
    setProvince("");
    setCountry("");
    setPasswordStatus(`password`);
    setConfirmedPasswordStatus(`password`);
    setPasswordIconStatus(`show`);
    setConfirmedPasswordIconStatus(`show`);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (password?.length && password === confirmedPassword) {
      if (
        name?.length &&
        email?.length &&
        phone?.length &&
        website?.length &&
        grade?.length &&
        school?.length &&
        schoolDistrict?.length &&
        streetAddress?.length &&
        city?.length &&
        province?.length &&
        country?.length
      ) {
        const teacher = {
          name,
          email,
          password,
          phone,
          website,
          grade,
          school,
          schoolDistrict,
          streetAddress,
          city,
          province,
          country,
        };

        dispatch(adminAddUser(teacher, "teacher"));
      } else {
        dispatch({
          type: ADMIN_ADD_USER_FAIL,
          payload: "All fields marked with an (*) are required!",
        });
      }
    } else {
      dispatch({
        type: ADMIN_ADD_USER_FAIL,
        payload: "Invalid passwords!",
      });
    }
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        resetFields();
        window.location.reload();
      }, 5000);
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: ADMIN_ADD_USER_RESET });
      }, 5000);
    }
  }, [error, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {error && <Message body={error} />}
      {user && (
        <Message
          body={`${user.type}: ${user.head.name} is created successfully. Please wait until page reloads`}
          type="info"
        />
      )}

      {userInfo ? (
        <div className="container" style={{ paddingTop: "40px" }}>
          <h3>Add A Teacher</h3>
          <form style={{ paddingTop: "20px" }} onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="name *"
              onChange={nameHandler}
              value={name}
              required
            />
            <input
              type="text"
              placeholder="email *"
              onChange={emailHandler}
              value={email}
              required
            />
            <div style={{ position: "relative" }}>
              <input
                type={passwordStatus}
                placeholder="password *"
                onChange={passwordHandler}
                value={password}
                required
              />
              <button
                className="password-toggle"
                onClick={passwordStatusHandler}
              >
                <img
                  alt=""
                  src={`/images/vrc-icons-beige-passowrd-${passwordIconStatus}.png`}
                  style={{ width: "30px" }}
                />
              </button>
            </div>
            <div style={{ position: "relative" }}>
              <input
                type={confirmedPasswordStatus}
                placeholder="confirm password *"
                onChange={confirmedPasswordHandler}
                value={confirmedPassword}
                required
              />
              <button
                className="password-toggle"
                onClick={confirmedPasswordStatusHandler}
              >
                <img
                  alt=""
                  src={`/images/vrc-icons-beige-passowrd-${confirmedPasswordIconStatus}.png`}
                  style={{ width: "30px" }}
                />
              </button>
            </div>
            <input
              type="number"
              placeholder="phone *"
              onChange={phoneHandler}
              value={phone}
              required
            />
            <input
              type="text"
              placeholder="website *"
              onChange={websiteHandler}
              value={website}
              required
            />
            <div className="select">
              <select onChange={countryHandler} defaultValue={country}>
                <option value="hide">select country *</option>
                {countries.map((ele, index) => (
                  <option key={index} value={ele.name}>
                    {ele.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="select">
              <select onChange={provinceHandler} value={province}>
                <option value="hide">select province/ territory *</option>
                {provinces.sort().map((prov, index) => (
                  <option key={index} value={prov}>
                    {prov}
                  </option>
                ))}
              </select>
            </div>
            {country === "Canada" ? (
              <div className="select">
                <select onChange={cityHandler} defaultValue={city}>
                  <option value="hide">select city *</option>
                  {cities
                    .map((cityArray) => cityArray[0])
                    .map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <input
                type="text"
                placeholder="city *"
                onChange={cityHandler}
                value={city}
                required
              />
            )}
            <input
              type="text"
              placeholder="school District *"
              onChange={schoolDistrictHandler}
              value={schoolDistrict}
              required
            />
            <input
              type="text"
              placeholder="street address *"
              onChange={streetAddressHandler}
              value={streetAddress}
              required
            />
            <div className="select">
              <select onChange={gradeHandler} value={grade}>
                <option value="hide">select grade *</option>
                {grades.map((grade, index) => (
                  <option key={index} value={grade}>
                    {grade}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              placeholder="school *"
              onChange={schoolHandler}
              value={school}
              required
            />

            <button className="btn" type="submit" onSubmit={submitHandler}>
              add teacher
            </button>
          </form>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddTeacherScreen;
