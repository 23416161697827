// import axios from "axios";

import {
  CART_ADD_ITEM,
  CART_INCLUDE_ADDON,
  CART_REMOVE_ITEM,
  CART_SET_DATE,
  CART_SET_TIME,
  CART_ADD_NOTE,
  CART_SET_COUNT,
  CART_RESET,
} from "../constants/cartConstants";

//
export const addToCart = (id) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find((x) => x.experience === id);

    if (existItem) {
      return;
    }
  }

  const {
    experienceDetails: { experience },
  } = getState();

  ///////

  // const { data } = await axios.get(`/api/experiences/${id}`);

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      name: experience.name,
      type: experience.type,
      maxStudents: experience.maxStudents,
      price: experience.price.amount,
      per: experience.price.pricingType,
      count: 1,
      experience: experience._id,
      addOns: [],
      date: "",
      time: "",
      note: "",
      // totalPrice: experience.price.amount,
      // totalPrice: experience.price.amount + experience.addOns.reduce((acc, ele) => ele.price + acc, 0),
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));

  // if (
  //   window.confirm(`"${data.name}" added to cart successfully! Go to Cart?`)
  // ) {
  //   window.location.href = `${window.location.origin}/cart`;
  // }
};

//
export const includeToCartItem = (addOn) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find(
      (x) => x.experience === addOn.experience,
    );

    if (existItem) {
      const existAddOn = existItem.addOns.find((a) => a.name === addOn.name);

      if (existAddOn) {
        alert(`Add-on is included already!`);
        return;
      }

      dispatch({
        type: CART_INCLUDE_ADDON,
        payload: addOn,
      });

      //
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
      );

      return;
    }
  }

  alert(`Add-ons can't be included wihtout the listing`);
  return;
};

//
export const excludeFromCartItem = (addOn) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find(
      (x) => x.experience === addOn.experience,
    );

    if (existItem) {
      const existAddOn = existItem.addOns.find((a) => a.name === addOn.name);

      if (existAddOn) {
        alert(`Add-on is included already!`);
        return;
      }

      dispatch({
        type: CART_INCLUDE_ADDON,
        payload: addOn,
      });

      //
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
      );

      return;
    }
  }

  alert(`Add-ons can't be included wihtout the listing`);
  return;
};

//
export const removeFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

//
export const resetCart = () => async (dispatch) => {
  dispatch({
    type: CART_RESET,
  });

  localStorage.removeItem("cartItems");
};

//
export const addDate = (id, date) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find((x) => x.experience === id);

    if (existItem) {
      dispatch({
        type: CART_SET_DATE,
        payload: {
          id,
          date,
        },
      });

      //
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
      );

      return;
    }
  }
};

//
export const addTime = (id, time) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find((x) => x.experience === id);

    if (existItem) {
      dispatch({
        type: CART_SET_TIME,
        payload: {
          id,
          time,
        },
      });

      //
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
      );

      return;
    }
  }
};

//
export const addNote = (id, note) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find((x) => x.experience === id);

    if (existItem) {
      dispatch({
        type: CART_ADD_NOTE,
        payload: {
          id,
          note,
        },
      });

      //
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
      );

      return;
    }
  }
};

//
export const addNumber = (id, count) => async (dispatch, getState) => {
  const cartItemsFromStorage = JSON.parse(localStorage.getItem("cartItems"));

  if (cartItemsFromStorage) {
    const existItem = cartItemsFromStorage.find((x) => x.experience === id);

    if (existItem) {
      dispatch({
        type: CART_SET_COUNT,
        payload: {
          id,
          count,
        },
      });

      //
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
      );

      return;
    }
  }
};
