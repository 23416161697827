import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import Loader from "../components/Loader";
import Message from "../components/Message";
import SetRating from "../components/SetRating";
import Benefit from "../components/Benefit";
// import Carousel from "../components/Carousel";
import ExperienceInfo from "../components/ExperienceInfo";
import Rating from "../components/Rating";
import Review from "../components/Review";
import Sidebar from "../components/Sidebar";

import { listUserDetails } from "../actions/userActions";

import {
  listExperienceDetails,
  listExperiences,
  setExperienceBanner,
  setExperienceHero,
  setExperienceName,
  // setGalleryThumbnail,
  addExperienceReview,
} from "../actions/experienceActions";

import {
  getDiscountCode,
  listMyBookings,
  setBooking,
} from "../actions/bookingActions";
import { listRelatedMedia, uploadMedia } from "../actions/mediaActions";

import {
  addNumber,
  addDate,
  addTime,
  addNote,
  addToCart,
  // eslint-disable-next-line
  includeToCartItem,
  resetCart,
} from "../actions/cartActions";

import { coreCompetenciesDescriptions } from "../data/coreCompetencies";
import bigIdeas from "../data/bigIdeas";
import curriculumCompetencies from "../data/curriculumCompetencies";
import contents from "../data/contents";
import {
  BOOKING_SET_FAIL,
  BOOKING_SET_RESET,
  GET_DISCOUNT_CODE_RESET,
} from "../constants/bookingConstants";

const ExperienceScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    }

    dispatch(listExperienceDetails(match.params.id));
  }, [userInfo, dispatch, match, history]);

  useEffect(() => {
    dispatch(resetCart());

    // eslint-disable-next-line
  }, []);

  const experienceList = useSelector((state) => state.experienceList);
  const {
    loading: loadingExps,
    error: errorExps,
    experiences,
  } = experienceList;

  const experienceDetails = useSelector((state) => state.experienceDetails);
  const { loading, error, experience } = experienceDetails;

  useEffect(() => {
    if (experience && experiences && experiences?.length) {
      setListings(
        experiences
          .filter((exp) => exp.grade === experience.grade)
          .filter((exp) => exp.subject === experience.subject),
      );
    }
  }, [experience, experiences]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo.type === "teacher" &&
      experience &&
      experience.grade &&
      !experience.grade?.includes(userInfo.grade)
    ) {
      history.push("/account");
    }
  }, [userInfo, experience, history]);

  const [displayCartWindow, setCartDisplayWindow] = useState(false);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [note, setNote] = useState("");
  const [number, setNumber] = useState(1);
  const [addons, setAddons] = useState([]);

  const displayCartMenu = () => {
    setCartDisplayWindow(true);
  };

  const hideCartMenu = () => {
    setCartDisplayWindow(false);
    setDate("");
    setNumber("");
    setNote("");
  };

  const bookingListMyBookings = useSelector(
    (state) => state.bookingListMyBookings,
  );
  const {
    loading: loadingBkgs,
    error: errorBkgs,
    bookings: myBookings,
  } = bookingListMyBookings;

  const [listings, setListings] = useState([]);

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingPtr, error: errorPtr, user } = userDetails;

  const experienceAddReview = useSelector((state) => state.experienceAddReview);
  const {
    loading: loadingReview,
    error: errorReview,
    success: successReview,
  } = experienceAddReview;

  const cart = useSelector((state) => state.cart);
  // eslint-disable-next-line
  const { cartItems } = cart;

  const [tab, setTab] = useState("description");

  const [descriptionActivitiy, setDescriptionActivity] =
    useState("description");
  const [featuresActivitiy, setFeaturesActivity] = useState("");
  const [addOnsActivitiy, setAddOnsActivity] = useState("");
  const [reviewsActivitiy, setReviewsActivity] = useState("");

  const descriptionSwitch = () => {
    if (tab !== "description") {
      setTab("description");
      setDescriptionActivity("description");
      setFeaturesActivity(null);
      setAddOnsActivity(null);
      setReviewsActivity(null);
    }
  };

  const featuresSwitch = () => {
    if (tab !== "features") {
      setTab("features");
      setFeaturesActivity("features");
      setDescriptionActivity(null);
      setAddOnsActivity(null);
      setReviewsActivity(null);
    }
  };

  const addOnsSwitch = () => {
    if (tab !== "add-ons") {
      setTab("add-ons");
      setAddOnsActivity("add-ons");
      setDescriptionActivity(null);
      setFeaturesActivity(null);
      setReviewsActivity(null);
    }
  };

  const reviewsSwitch = () => {
    if (tab !== "reviews") {
      setTab("reviews");
      setReviewsActivity("reviews");
      setDescriptionActivity(null);
      setFeaturesActivity(null);
      setAddOnsActivity(null);

      // dispatch if => auth teacher bookings
      if (userInfo.type === "teacher") {
        dispatch(listMyBookings());
      }
    }
  };

  // booking popup
  const addToCartHandler = (e) => {
    e.preventDefault();
    if (experience) {
      dispatch(addToCart(experience._id));
      displayCartMenu();
    }
  };

  const addOnsHandle = (e) => {
    e.preventDefault();

    const addOnContent = e.target.id.split("=");
    if (!addons.find((ele) => ele.name === addOnContent[0])) {
      setAddons([
        ...addons,
        {
          experience: experience._id,
          name: addOnContent[0],
          price: addOnContent[1],
        },
      ]);
    }
  };

  const removeAddOnsHandle = (e) => {
    const addOnContent = e.target.id.split("=");
    setAddons(addons.filter((ele) => ele.name !== addOnContent[0]));
  };

  const dateHandler = (e) => {
    e.preventDefault();
    setDate(e.target.value);
  };

  const timeHandler = (e) => {
    e.preventDefault();
    setTime(e.target.value);
  };

  const noteHandler = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };

  const numberHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setNumber(e.target.value);
    }
  };

  const cancelHandler = () => {
    hideCartMenu();
    dispatch(resetCart());
    setAddons([]);
    setTotalPrice(0);
  };

  const bookingSet = useSelector((state) => state.bookingSet);
  const {
    loading: loadingBooking,
    error: errorBooking,
    success,
    booking,
  } = bookingSet;

  const bookingHandler = () => {
    if (userInfo) {
      if (
        !date?.length ||
        !time?.length ||
        (experience.price.type === "student" && number < 1)
      ) {
        dispatch({
          type: BOOKING_SET_FAIL,
          payload: "Fields marked with an (*) are required!",
        });
      }

      // dispatching
      const teacher = {
        _id: userInfo._id,
        name: userInfo.name,
        email: userInfo.email,
        schoolDistrict: userInfo.schoolDistrict,
        grade: userInfo.grade,
        type: userInfo.type,
      };

      const partner = {
        _id: user._id,
        name: user.head.name,
        email: user.head.email,
        type: user.type,
      };

      const booking = {
        teacher,
        partner,
        bookingItems: [
          {
            experience: experience._id,
            name: experience.name,
            price: experience.price.amount,
            per: experience.price.pricingType,
            maxStudents: experience.maxStudents,
            type: experience.type,
            date,
            time,
            addOns: addons,
            count: number,
            note,
          },
        ],
        totalPrice,
        discount,
      };

      dispatch(setBooking(booking));
      dispatch(resetCart());
      hideCartMenu();
    }
  };

  const [totalPrice, setTotalPrice] = useState(0);

  // discount
  // discountCode
  const discountCode = useSelector((state) => state.discountCode);
  const {
    loading: loadingDiscountCode,
    error: errorDiscountCode,
    code,
  } = discountCode;

  useEffect(() => {
    if (!code) {
      dispatch(getDiscountCode());
    }
  }, [code, dispatch]);

  useEffect(() => {
    if (errorDiscountCode) {
      setTimeout(() => {
        dispatch({ type: GET_DISCOUNT_CODE_RESET });
      }, 5000);
    }
  }, [errorDiscountCode, dispatch]);

  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(false);

  const couponHandler = (e) => {
    setCoupon(e.target.value);
  };

  const applyCouponHandler = (e) => {
    e.preventDefault();

    if (code) {
      // get discount code from backend
      if (coupon.toLocaleLowerCase() === code) {
        setDiscount(true);
        setCoupon("");
      }
    }
  };

  useEffect(() => {
    if (experience) {
      let experiencePrice = 0;
      if (experience.price?.pricingType === "student") {
        experiencePrice = experience.price?.amount * number;
      } else {
        experiencePrice = experience.price?.amount;
      }
      setTotalPrice(
        experiencePrice +
          addons.reduce((acc, ele) => parseInt(ele.price) + parseInt(acc), 0),
      );

      setDiscount(false);
    }
  }, [experience, addons, number]);

  useEffect(() => {
    if (experience && discount) {
      if (experience.price?.pricingType === "student") {
        setTotalPrice(totalPrice - experience.price?.amount * parseInt(number));
      } else if (experience.price?.pricingType === "class") {
        setTotalPrice(totalPrice - experience.price?.amount);
      }
    }

    // eslint-disable-next-line
  }, [experience, discount]);

  const [finalGrade, setFinalGrade] = useState(null);

  useEffect(() => {
    if (experience && experience.partner) {
      setFinalGrade(experience.grade);
      dispatch(listExperiences());
      dispatch(listUserDetails(experience.partner, "partner"));
    }
  }, [dispatch, experience]);

  useEffect(() => {
    descriptionSwitch();
    // eslint-disable-next-line
  }, [experience]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(resetCart());
        dispatch({ type: BOOKING_SET_RESET });
        history.push(`/booking/${booking._id}`);
      }, 5000);
    }
  }, [dispatch, success, history, booking]);

  useEffect(() => {
    if (errorBooking) {
      setTimeout(() => {
        dispatch({ type: BOOKING_SET_RESET });
      }, 5000);
    }
  }, [errorBooking, dispatch]);

  useEffect(() => {
    if (experience && date?.length) {
      dispatch(addDate(experience._id, date.toString()));
    }
    // eslint-disable-next-line
  }, [experience, date, dispatch]);

  useEffect(() => {
    dispatch(addTime(experience._id, time.toString()));
    // eslint-disable-next-line
  }, [dispatch, time]);

  useEffect(() => {
    dispatch(addNote(experience._id, note));
    // eslint-disable-next-line
  }, [dispatch, note]);

  useEffect(() => {
    dispatch(addNumber(experience._id, number));
    // eslint-disable-next-line
  }, [dispatch, number]);

  // set experience name
  // eslint-disable-next-line
  const [nameDisplayWindow, setNameDisplayWindow] = useState(false);
  const [newName, setNewName] = useState(``);

  // eslint-disable-next-line
  const nameHandler = (e) => {
    e.preventDefault();
    setNewName(e.target.value);
  };

  // eslint-disable-next-line
  const addNewName = () => {
    if (newName?.length) {
      dispatch(setExperienceName(experience._id, newName));
      setNewName(``);
      setNameDisplayWindow(false);
      window.location.reload();
    }
  };

  // eslint-disable-next-line
  const displayNameMenu = (e) => {
    e.preventDefault();
    setNewName(``);
    setNameDisplayWindow(true);
  };

  // eslint-disable-next-line
  const hideNameMenu = (e) => {
    e.preventDefault();
    setNameDisplayWindow(false);
    setNewName(``);
  };
  //

  // add review
  const [reviewTitle, setReviewTitle] = useState(``);
  const [reviewBody, setReviewBody] = useState(``);
  const [reviewRating, setReviewRating] = useState(1);

  const resetReviewFields = () => {
    setReviewTitle(``);
    setReviewBody(``);
    setReviewRating(1);
  };

  const reviewTitleHandler = (e) => {
    e.preventDefault();
    setReviewTitle(e.target.value);
  };

  const reviewBodyHandler = (e) => {
    e.preventDefault();
    setReviewBody(e.target.value);
  };

  const submitReviewHandler = (e) => {
    e.preventDefault();

    if (reviewTitle?.length && reviewBody?.length) {
      const review = {
        teacher: userInfo._id,
        name: userInfo.name,
        title: reviewTitle,
        rating: reviewRating,
        body: reviewBody,
      };

      dispatch(addExperienceReview(experience._id, review));

      resetReviewFields();
    }
  };

  useEffect(() => {
    if (successReview) {
      dispatch(listExperienceDetails(experience._id));
    }
    // eslint-disable-next-line
  }, [successReview]);

  //

  ////////////////////////////////////
  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  const [elementType, setElementType] = useState(``);
  const [elementContent, setElementContent] = useState(``);

  const [displayWindow, setDisplayWindow] = useState(false);

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  const displayMenu = (e) => {
    e.preventDefault();

    setElementType(e.target.id);
    setElementContent(``);
    setDisplayWindow(true);

    if (!files) {
      getMediaFiles(e);
    }
  };

  const hideMenu = (e) => {
    e.preventDefault();
    setDisplayWindow(false);
    setElementContent(``);
    setElementType(``);
  };

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia(userInfo._id, userInfo.type));
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  const setVisual = (e) => {
    if (
      elementContent &&
      elementContent?.length &&
      elementType &&
      elementType?.length
    ) {
      switch (elementType) {
        case "hero-visual":
          dispatch(
            setExperienceHero(
              experience._id,
              elementContent.replace("\\", "/"),
            ),
          );
          break;
        case "experience-banner":
          dispatch(
            setExperienceBanner(
              experience._id,
              elementContent.replace("\\", "/"),
            ),
          );
          break;
        // future TODO: galleryThumbnails [1,2,3]
        // case "gallery-thumbnail-0":
        // case "gallery-thumbnail-1":
        // case "gallery-thumbnail-2":
        // dispatch(
        //   setThumbnail(
        //     user._id,
        //     "partner",
        //     elementContent.replace("\\", "/"),
        //     elementType.split("-")[2]
        //   )
        // );
        // break;
        default:
          return;
      }
      hideMenu(e);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (file && file?.length) {
      setElementContent(file);
    }
  }, [file]);

  ////////////////////////////////////

  // view as teacher
  const viewAsTeacher = (e) => {
    setFinalGrade(e.target.id);
  };

  const resetFinalGrade = () => {
    setFinalGrade(experience.grade);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        userInfo &&
        experience &&
        user && (
          <>
            {loadingBkgs && <Loader />}
            {loadingBooking && <Loader />}
            {loadingReview && <Loader />}
            {loadingDiscountCode && <Loader />}
            {errorBooking && <Message body={errorBooking} />}
            {errorBkgs && <Message body={errorBkgs} />}
            {errorReview && <Message body={errorReview} />}
            {success && (
              <Message
                body="Congratulations! Your booking request has been sent to the Host for confirmation. You will hear back from the Host shortly."
                type="info"
              />
            )}
            {successReview && (
              <Message body="Review has been set successfully" type="info" />
            )}

            {userInfo._id === user._id && displayWindow && (
              <>
                <div className="popup" style={{ display: "flex" }}>
                  <div className="overlay"></div>
                  <div className="container">
                    {loadingFiles && <Loader />}
                    {loadingFile && <Loader />}
                    {errorFiles && <Message body={errorFiles} />}
                    {errorFile && <Message body={errorFile} />}
                    <div className="popup-content">
                      <div style={{ display: "flex", gap: "10px" }}>
                        <input
                          type="text"
                          onChange={elementContentHandler}
                          placeholder="enter image url"
                          min="0"
                          className="experience-new-name"
                          value={elementContent}
                          style={{ flexGrow: "1" }}
                        />

                        <input
                          type="file"
                          className="experience-new-name"
                          onChange={uploadFileHandler}
                          id="upload-inline-image"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="upload-inline-image" className="btn">
                          upload file
                        </label>
                        <button className="btn" onClick={getMediaFiles}>
                          refresh list
                        </button>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <button
                          className="btn"
                          onClick={setVisual}
                          style={{ marginTop: "20px" }}
                        >
                          set
                        </button>
                        <button
                          className="btn"
                          onClick={hideMenu}
                          style={{ marginTop: "20px" }}
                        >
                          cancel
                        </button>
                      </div>
                      {files && files?.length && (
                        <div className="media-lib">
                          {files.map((file, index) => (
                            <div
                              key={index}
                              className="media-file"
                              style={{ backgroundImage: `url(${file}` }}
                              id={file}
                              onClick={mediaFileUrlHandler}
                            ></div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {displayCartWindow && (
              <div
                className="popup"
                style={{
                  display: "flex",
                }}
              >
                <div className="overlay"></div>
                <div className="container" style={{ margin: "14px" }}>
                  <div
                    className="popup-content"
                    style={{ padding: "40px 15px 40px 0" }}
                  >
                    <div className="input-wrapper">
                      <label htmlFor="date" className="label">
                        Pick date: *
                      </label>
                      <input
                        type="date"
                        id="date"
                        className="input"
                        onChange={dateHandler}
                        value={date}
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="time" className="label">
                        Pick time: *
                      </label>
                      <input
                        id="time"
                        type="time"
                        className="input"
                        onChange={timeHandler}
                        value={time}
                      />
                    </div>
                    {experience.price &&
                      experience.price.pricingType === "student" && (
                        <div className="input-wrapper">
                          <label htmlFor="number-of-students" className="label">
                            Number of students: *
                          </label>
                          <input
                            id="number-of-students"
                            className="input"
                            type="number"
                            onChange={numberHandler}
                            value={number}
                            placeholder="0 = maximum"
                          />
                        </div>
                      )}
                    {experience.addOns?.length ? (
                      <>
                        <div
                          className="input-wrapper"
                          style={{
                            borderTop: "2px solid var(--var-color-vrc-beige)",
                            paddingTop: "20px",
                          }}
                        >
                          <p className="label">Select add-ons: (optional)</p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "20px",
                              flexGrow: "1",
                            }}
                          >
                            {experience.addOns.map((ao, index) => (
                              <div
                                className="add-ons"
                                key={index}
                                style={{
                                  borderColor:
                                    addons?.length &&
                                    addons.filter(
                                      (a) => a.name === ao.addOnTitle,
                                    )?.length
                                      ? "var(--var-color-vrc-beige)"
                                      : "",
                                }}
                              >
                                {addons?.length &&
                                addons.filter((a) => a.name === ao.addOnTitle)
                                  ?.length ? (
                                  <span
                                    className="remove"
                                    id={ao.addOnTitle}
                                    onClick={removeAddOnsHandle}
                                  >
                                    <img
                                      id={ao.addOnTitle}
                                      onClick={removeAddOnsHandle}
                                      src="/images/vrc-icons-beige-false.png"
                                      alt="remove-addon"
                                      style={{ width: "20px", height: "20px" }}
                                    />
                                  </span>
                                ) : (
                                  <></>
                                )}

                                <div>
                                  <h4>{ao.addOnTitle}</h4>
                                  <span
                                    style={{
                                      color: "var(--var-color-vrc-green)",
                                    }}
                                  >
                                    Price:{" "}
                                  </span>
                                  ${ao.addOnPrice}
                                </div>

                                <button
                                  className="btn"
                                  id={`${ao.addOnTitle}=${ao.addOnPrice}`}
                                  onClick={addOnsHandle}
                                  style={{ width: "100%" }}
                                >
                                  include in experience
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className="input-wrapper"
                      style={{
                        display: "flex",
                        borderTop: "2px solid var(--var-color-vrc-beige)",
                        paddingTop: "20px",
                      }}
                    >
                      <label
                        htmlFor="note"
                        className="label"
                        style={{ paddingTop: "20px" }}
                      >
                        Add note: (optional)
                      </label>
                      <textarea
                        id="note"
                        className="textarea"
                        onChange={noteHandler}
                        placeholder="In this field, please describe any customization you need for your class to make the most of this experience. Also, use this field for your preferred time."
                        value={note}
                      />
                    </div>
                    <div
                      className="input-wrapper"
                      style={{
                        display: "flex",
                        borderTop: "2px solid var(--var-color-vrc-beige)",
                        paddingTop: "20px",
                      }}
                    >
                      <label htmlFor="coupon" className="label">
                        Coupon Code:
                      </label>
                      <div
                        style={{ display: "flex", gap: "20px", flexGrow: "1" }}
                      >
                        <input
                          id="coupon"
                          className="input"
                          type="text"
                          onChange={couponHandler}
                          value={coupon}
                          placeholder="insert coupon code"
                          style={{ flexGrow: "1" }}
                        />
                        <button className="btn" onClick={applyCouponHandler}>
                          apply
                        </button>
                        {discount && (
                          <img
                            src="/images/vrc-icons-beige-true.png"
                            alt="discount-applied"
                            style={{ width: "60px", height: "60px" }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "20px",
                        gap: "10px",
                        borderTop: "2px solid var(--var-color-vrc-beige)",
                      }}
                    >
                      <button
                        className="btn message-btn"
                        onClick={bookingHandler}
                      >
                        set booking
                      </button>
                      <button
                        className="btn cancel-btn"
                        onClick={cancelHandler}
                      >
                        cancel
                      </button>
                    </div>
                    <div
                      className="input-wrapper"
                      style={{
                        display: "flex",
                        borderTop: "2px solid var(--var-color-vrc-beige)",
                        paddingTop: "20px",
                      }}
                    >
                      <h3 style={{ marginRight: "30px" }}>
                        Total Price: ${totalPrice}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="experience">
              <div
                className="experience-banner"
                style={{
                  backgroundImage: experience.banner?.length
                    ? `url(${experience.banner})`
                    : "url(/images/generic-banner.png)",
                  position: "relative",
                }}
              >
                <span className="bg-overlay"></span>
                {userInfo && user && userInfo._id === user._id && (
                  <label
                    id="experience-banner"
                    className="edit-banner"
                    onClick={displayMenu}
                  >
                    <img alt="" src="/images/vrc-icons-beige-edit.png" />
                  </label>
                )}
                <div className="container">
                  <div className="experience-banner-content">
                    {loadingPtr ? (
                      <Loader />
                    ) : errorPtr ? (
                      <Message body={errorPtr} />
                    ) : (
                      <>
                        {user && (
                          <div className="identity">
                            <div className="text">
                              <h3 className="partner-name">{user.head.name}</h3>
                              <span>
                                {user.category} | {user.city}, {user.country}
                              </span>
                            </div>
                            <Benefit visual={user.head.avatar} />
                            <Link to={`/partner/${user._id}`} />
                          </div>
                        )}
                      </>
                    )}
                    <div className="experience-title" style={{ zIndex: "100" }}>
                      {experience.numReviews?.length && (
                        <Rating
                          value={
                            experience.reviews.reduce(
                              (acc, item) => item.rating + acc,
                              0,
                            ) / experience.reviews.length
                          }
                          color="beige"
                        />
                      )}
                      <div style={{ display: "flex", gap: "10px" }}>
                        {userInfo && userInfo.type !== "teacher" && (
                          <Link
                            className="btn"
                            style={{ width: "fit-content" }}
                            to={`/experience/${experience._id}/edit`}
                          >
                            edit experience
                          </Link>
                        )}
                        {userInfo.type === "admin" && (
                          <div
                            className="btn header-dashboard-btn"
                            style={{ width: "fit-content", padding: "0 40px" }}
                          >
                            view as teacher
                            <ul>
                              {experience.grade.split(",").map((ele, index) => (
                                <li
                                  key={index}
                                  id={ele}
                                  onClick={viewAsTeacher}
                                >
                                  {ele}
                                </li>
                              ))}
                              <li onClick={resetFinalGrade}>reset</li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <h1 className="experience-name">{experience.name}</h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <h6>
                          {experience.price.amount === 0
                            ? "FREE"
                            : `$${experience.price.amount}/${experience.price.pricingType}`}
                        </h6>

                        {userInfo.type === "teacher" && (
                          <button
                            className="btn"
                            style={{ width: "fit-content" }}
                            onClick={addToCartHandler}
                          >
                            book now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="experience-gallery">
                <div className="container">
                  <div className="experience-carousel">
                    {experience.gallery?.length ? (
                      <Carousel gallery={experience.gallery} />
                    ) : (
                      <Carousel
                        gallery={new Array(3).fill("/images/user-hero.png")}
                      />
                    )}
                  </div>
                </div>
              </div> */}
              <div className="big-idea">
                <div className="container">
                  <img src="/images/section-generic.png" alt="" />
                  <div className="big-idea-content">
                    <div>
                      <h3>core competencies</h3>
                      <div className="cores" style={{ display: "flex" }}>
                        {experience.coreCompetencies
                          .toString()
                          .split("")
                          .map((coreIndex, index) => (
                            <span key={index}>
                              {
                                coreCompetenciesDescriptions[Number(coreIndex)]
                                  .heading
                              }
                            </span>
                          ))}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h3>big idea</h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          flexGrow: "1",
                        }}
                      >
                        {userInfo.type === "teacher" ? (
                          <>
                            {bigIdeas
                              .find(
                                (group) => group.subject === experience.subject,
                              )
                              .data.find(
                                (subGroup) => subGroup.grade === userInfo.grade,
                              )
                              .ideas.filter((idea, indexIdea) =>
                                experience.bigIdeas[
                                  experience.grade
                                    .split(",")
                                    .map((grade, indexGrade) =>
                                      grade === userInfo.grade
                                        ? indexGrade
                                        : "",
                                    )
                                    .join("")
                                ]?.includes(indexIdea),
                              )
                              .map((finalIdea, indexFinalIdea) => (
                                <p key={indexFinalIdea}>• {finalIdea}</p>
                              ))}
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {finalGrade &&
                              finalGrade.split(",").map((grade, indexGrade) => (
                                <div key={indexGrade}>
                                  <span style={{ textTransform: "uppercase" }}>
                                    {finalGrade.split(",").length > 1
                                      ? grade
                                      : ""}
                                  </span>
                                  {bigIdeas
                                    .find(
                                      (group) =>
                                        group.subject === experience.subject,
                                    )
                                    .data.find(
                                      (subGroup) => subGroup.grade === grade,
                                    )
                                    .ideas.filter((idea, indexIdea) =>
                                      experience.bigIdeas[indexGrade]?.includes(
                                        indexIdea,
                                      ),
                                    )
                                    .map((finalIdea, indexFinalIdea) => (
                                      <p key={indexFinalIdea}>• {finalIdea}</p>
                                    ))}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="experience-details">
                <div className="container">
                  <div className="experience-details-content">
                    <div className="sidebar">
                      <div className="sidebar-section">
                        <h3>experience info</h3>
                        {user && finalGrade && (
                          <ExperienceInfo
                            experience={experience}
                            grade={finalGrade}
                            user={user}
                            userInfo={userInfo}
                          />
                        )}
                        {userInfo.type === "teacher" && (
                          <button className="btn" onClick={addToCartHandler}>
                            book now
                          </button>
                        )}
                      </div>
                      {loadingExps ? (
                        <Loader />
                      ) : errorExps ? (
                        <Message body={errorExps} />
                      ) : (
                        listings &&
                        listings?.length && (
                          <div className="sidebar-section">
                            <h3>related experiences</h3>
                            <Sidebar
                              collection={listings
                                .filter(
                                  (exp) => exp.category === experience.category,
                                )
                                .slice(-5)}
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div className="details-body">
                      {experience.hero && experience.hero.type?.length ? (
                        experience.hero.type === "video" ? (
                          <video
                            style={{ position: "relative" }}
                            className="hero"
                            width="100%"
                            height="auto"
                            autoPlay={false}
                            controls={true}
                            muted={false}
                            loop={false}
                          >
                            <source
                              src={experience.hero.url}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            {userInfo && user && userInfo._id === user._id && (
                              <>
                                <input
                                  id="hero-visual"
                                  type="file"
                                  onChange={uploadFileHandler}
                                />
                                <label
                                  htmlFor="hero-visual"
                                  className="edit-hero"
                                >
                                  <img
                                    alt=""
                                    src="/images/vrc-icons-beige-edit.png"
                                  />
                                </label>
                              </>
                            )}
                          </video>
                        ) : experience.hero.type === "image" ? (
                          <div
                            className="hero"
                            style={{
                              backgroundImage: `url(${
                                experience.hero.url?.length
                                  ? experience.hero.url
                                  : "/images/user-hero.png"
                              })`,
                            }}
                          >
                            {userInfo && user && userInfo._id === user._id && (
                              <label
                                id="hero-visual"
                                className="edit-hero"
                                onClick={displayMenu}
                              >
                                <img
                                  alt=""
                                  src="/images/vrc-icons-beige-edit.png"
                                />
                              </label>
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div
                          className="hero"
                          style={{
                            backgroundImage: "url(/images/user-hero.png)",
                          }}
                        >
                          {userInfo && user && userInfo._id === user._id && (
                            <>
                              <input
                                id="hero-visual"
                                type="file"
                                onChange={uploadFileHandler}
                              />
                              <label
                                htmlFor="hero-visual"
                                className="edit-hero"
                              >
                                <img
                                  alt=""
                                  src="/images/vrc-icons-beige-edit.png"
                                />
                              </label>
                            </>
                          )}
                        </div>
                      )}
                      <div className="experience-tabs">
                        <div className="experience-controller">
                          <button
                            onClick={descriptionSwitch}
                            className={descriptionActivitiy && "active"}
                            style={{ zIndex: "100" }}
                          >
                            description
                          </button>
                          <button
                            onClick={featuresSwitch}
                            className={featuresActivitiy && "active"}
                            style={{ zIndex: "101" }}
                          >
                            content
                          </button>
                          <button
                            onClick={addOnsSwitch}
                            className={addOnsActivitiy && "active"}
                            style={{ zIndex: "102" }}
                          >
                            add-ons
                          </button>
                          <button
                            onClick={reviewsSwitch}
                            className={reviewsActivitiy && "active"}
                            style={{ zIndex: "103" }}
                          >
                            reviews
                          </button>
                        </div>
                        <div className="experience-tab-body">
                          {descriptionActivitiy ? (
                            <>
                              <p>{experience.description}</p>
                              {/* hhh */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  flexGrow: "1",
                                }}
                              ></div>
                              <div>
                                <h3>curriculum competencies</h3>
                                <p>
                                  By participating in this program, students
                                  will interact with the following Curriculum
                                  Competencies:
                                </p>
                                <ul>
                                  {userInfo.type === "teacher" ? (
                                    <>
                                      {curriculumCompetencies
                                        .find(
                                          (group) =>
                                            group.subject ===
                                            experience.subject,
                                        )
                                        .data.find(
                                          (subGroup) =>
                                            subGroup.grade === userInfo.grade,
                                        )
                                        .points.filter((point, indexPoint) =>
                                          experience.curriculumCompetencies[
                                            experience.grade
                                              .split(",")
                                              .map((grade, indexGrade) =>
                                                grade === userInfo.grade
                                                  ? indexGrade
                                                  : "",
                                              )
                                              .join("")
                                          ]?.includes(indexPoint),
                                        )
                                        .map((finalCC, indexFinalCC) => (
                                          <li key={indexFinalCC}>
                                            <span className="cch">
                                              {finalCC.handle}:
                                            </span>
                                            <span className="ccd">
                                              {finalCC.description}
                                            </span>
                                          </li>
                                        ))}
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      {finalGrade &&
                                        finalGrade
                                          .split(",")
                                          .map((grade, indexGrade) => (
                                            <div key={indexGrade}>
                                              <span
                                                style={{
                                                  textTransform: "uppercase",
                                                  color:
                                                    "var(--var-color-vrc-grey)",
                                                }}
                                              >
                                                {finalGrade.split(",").length >
                                                1
                                                  ? grade
                                                  : ""}
                                              </span>
                                              {curriculumCompetencies
                                                .find(
                                                  (group) =>
                                                    group.subject ===
                                                    experience.subject,
                                                )
                                                .data.find(
                                                  (subGroup) =>
                                                    subGroup.grade === grade,
                                                )
                                                .points.filter(
                                                  (point, indexPoint) =>
                                                    experience.curriculumCompetencies[
                                                      indexGrade
                                                    ]?.includes(indexPoint),
                                                )
                                                .map(
                                                  (finalCC, indexFinalCC) => (
                                                    <li key={indexFinalCC}>
                                                      <span className="cch">
                                                        {finalCC.handle}:
                                                      </span>
                                                      <span className="ccd">
                                                        {finalCC.description}
                                                      </span>
                                                    </li>
                                                  ),
                                                )}
                                            </div>
                                          ))}
                                    </div>
                                  )}
                                </ul>
                              </div>
                              {loadingPtr ? (
                                <Loader />
                              ) : errorPtr ? (
                                <Message body={errorPtr} />
                              ) : (
                                <>
                                  {user && (
                                    <div className="experience-about">
                                      <h3>about {user.head.name}</h3>
                                      <p>{experience.about}</p>
                                      <p>
                                        To learn more about the cultural centre,
                                        visit their partner page:{" "}
                                        <Link to={`/partner/${user._id}`}>
                                          {user.head.name}
                                        </Link>
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : featuresActivitiy ? (
                            <>
                              <div>
                                <h3>Curricular Connections</h3>
                                {/* <p>
                                  Learn the diverse characteristics of the{" "}
                                  {user.head.name}:
                                </p> */}
                                <ul>
                                  {userInfo.type === "teacher" ? (
                                    <>
                                      {contents
                                        .find(
                                          (group) =>
                                            group.subject ===
                                            experience.subject,
                                        )
                                        .data.find(
                                          (subGroup) =>
                                            subGroup.grade === userInfo.grade,
                                        )
                                        .contents.filter((point, indexPoint) =>
                                          experience.content[
                                            experience.grade
                                              .split(",")
                                              .map((grade, indexGrade) =>
                                                grade === userInfo.grade
                                                  ? indexGrade
                                                  : "",
                                              )
                                              .join("")
                                          ]?.includes(indexPoint),
                                        )
                                        .map(
                                          (finalContent, indexFinalContent) => (
                                            <li key={indexFinalContent}>
                                              <span>•</span>
                                              <span>{finalContent}</span>
                                            </li>
                                          ),
                                        )}
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      {finalGrade
                                        .split(",")
                                        .map((grade, indexGrade) => (
                                          <div key={indexGrade}>
                                            <span
                                              style={{
                                                textTransform: "uppercase",
                                                color:
                                                  "var(--var-color-vrc-grey)",
                                              }}
                                            >
                                              {finalGrade.split(",").length > 1
                                                ? grade
                                                : ""}
                                            </span>
                                            {contents
                                              .find(
                                                (group) =>
                                                  group.subject ===
                                                  experience.subject,
                                              )
                                              .data.find(
                                                (subGroup) =>
                                                  subGroup.grade === grade,
                                              )
                                              .contents.filter(
                                                (point, indexPoint) =>
                                                  experience.content[
                                                    indexGrade
                                                  ]?.includes(indexPoint),
                                              )
                                              .map(
                                                (
                                                  finalContent,
                                                  indexFinalContent,
                                                ) => (
                                                  <li key={indexFinalContent}>
                                                    <span>•</span>
                                                    <span>{finalContent}</span>
                                                  </li>
                                                ),
                                              )}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </ul>
                              </div>
                              <div>
                                <h3>your experience will include</h3>
                                <ul>
                                  {experience.features.map((cc, index) => (
                                    <li
                                      key={index}
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <span>•</span>
                                      <p>{cc}</p>
                                    </li>
                                  ))}
                                </ul>
                                {experience.note && (
                                  <p>
                                    <span>Note: </span>
                                    {experience.note}
                                  </p>
                                )}
                              </div>
                              <div>
                                <h3>prior knowledge</h3>
                                <p>
                                  In order to understand this lesson students
                                  will need to know, or have prior knowledge of,
                                  or will need to review the following:
                                </p>
                                <ul>
                                  {experience.priorKnowledge.map(
                                    (pk, index) => (
                                      <li
                                        key={index}
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        {experience.priorKnowledge.length >
                                          1 && <span>•</span>}
                                        <p>{pk}</p>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : addOnsActivitiy ? (
                            <>
                              <div>
                                {experience.addOns?.length ? (
                                  <>
                                    <p>
                                      Browse the add-on elements that you can
                                      choose from to create the perfect field
                                      trip for you.
                                    </p>
                                    <ul
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      {experience.addOns.map((ao, index) => (
                                        <div className="add-ons" key={index}>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "10px",
                                            }}
                                          >
                                            <h4>{ao.addOnTitle}</h4>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              <span style={{ width: "100px" }}>
                                                Description:
                                              </span>
                                              <p
                                                style={{
                                                  width: "calc(100% - 100px)",
                                                }}
                                              >
                                                {ao.addOnDescription}
                                              </p>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              <span style={{ width: "100px" }}>
                                                Price:
                                              </span>
                                              <p
                                                style={{
                                                  width: "calc(100% - 100px)",
                                                }}
                                              >
                                                ${ao.addOnPrice}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </ul>
                                  </>
                                ) : (
                                  <p>No add ons for this listing.</p>
                                )}
                              </div>
                            </>
                          ) : reviewsActivitiy ? (
                            <>
                              <p>
                                Browse the reviews that other teachers who have
                                taken this experiences have left.
                              </p>
                              {experience.reviews?.length ? (
                                <>
                                  <div>
                                    <Rating
                                      value={
                                        experience.reviews.reduce(
                                          (acc, item) => item.rating + acc,
                                          0,
                                        ) / experience.reviews.length
                                      }
                                      color="grey"
                                      numReviews={experience.reviews.length}
                                    />
                                  </div>
                                  {experience.reviews &&
                                    experience.reviews?.length && (
                                      <div className="reviews">
                                        {experience.reviews.map((r, index) => (
                                          <Review key={index} review={r} />
                                        ))}
                                      </div>
                                    )}
                                </>
                              ) : (
                                <p>No reviews yet.</p>
                              )}
                              {userInfo.type === "teacher" &&
                                myBookings &&
                                myBookings?.length &&
                                myBookings.find(
                                  (bkg) =>
                                    bkg.bookingItems[0].experience ===
                                    experience._id,
                                ).isPaid &&
                                !experience.reviews.find(
                                  (rvws) => rvws.teacher === userInfo._id,
                                ) && (
                                  <form
                                    onSubmit={submitReviewHandler}
                                    className="review-form"
                                  >
                                    <h4>add review</h4>
                                    <SetRating
                                      rating={reviewRating}
                                      setRating={setReviewRating}
                                    />
                                    <input
                                      type="text"
                                      placeholder="enter review title"
                                      value={reviewTitle}
                                      onChange={reviewTitleHandler}
                                      required
                                    />
                                    <textarea
                                      type="text"
                                      placeholder="enter review body"
                                      onChange={reviewBodyHandler}
                                      value={reviewBody}
                                      required
                                    ></textarea>
                                    <button
                                      className="btn"
                                      onSubmit={submitReviewHandler}
                                      style={{ height: "60px" }}
                                    >
                                      submit review
                                    </button>
                                  </form>
                                )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default ExperienceScreen;
