import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { listExperiences } from "../actions/experienceActions";
import { listUsers } from "../actions/userActions";
import { USER_LIST_RESET } from "../constants/userConstants";
import { EXPERIENCE_LIST_RESET } from "../constants/experienceConstants";

import Loader from "./Loader";
import Message from "./Message";

const Search = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
    dispatch({ type: EXPERIENCE_LIST_RESET });
    setKeyword("");
  }, [dispatch]);

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorPartners, users } = userList;

  const experienceList = useSelector((state) => state.experienceList);
  const {
    loading: loadingExperiences,
    error: errorExperiences,
    experiences,
  } = experienceList;

  useEffect(() => {
    if (!users) {
      dispatch(listUsers("partner"));
    }
  }, [users, dispatch]);

  useEffect(() => {
    if (!experiences) {
      dispatch(listExperiences());
    }
  }, [experiences, dispatch]);

  useEffect(() => {
    if (errorPartners) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET });
      }, 5000);
    }
  }, [errorPartners, dispatch]);

  useEffect(() => {
    if (errorExperiences) {
      setTimeout(() => {
        dispatch({ type: EXPERIENCE_LIST_RESET });
      }, 5000);
    }
  }, [errorExperiences, dispatch]);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);

  const [keyword, setKeyword] = useState("");

  const keywordHandler = (e) => {
    setKeyword(e.target.value);
  };

  useEffect(() => {
    if (users && experiences) {
      if (keyword?.length) {
        setFilteredUsers(
          users.filter((user) =>
            user.head.name.toLowerCase().includes(keyword.toLowerCase()),
          ),
        );
        setFilteredListings(
          experiences.filter((experience) =>
            experience.name.toLowerCase().includes(keyword.toLowerCase()),
          ),
        );
      } else {
        setFilteredUsers(users);
        setFilteredListings(experiences);
      }
    }

    // eslint-disable-next-line
  }, [keyword]);

  return (
    <>
      {loadingUsers && <Loader />}
      {loadingExperiences && <Loader />}
      {errorPartners && <Message body={errorPartners} />}
      {errorExperiences && <Message body={errorExperiences} />}
      <div className="search-content">
        {users && experiences ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              type="search"
              onChange={keywordHandler}
              value={keyword}
              placeholder="Search in partners and experiences"
            />
          </form>
        ) : (
          <></>
        )}
        {keyword?.length ? (
          <div className="search-result">
            {users?.length && filteredUsers?.length ? (
              <div className="in-section">
                <h6>in partners</h6>
                <div className="collection">
                  {filteredUsers.map((partner, index) => (
                    <Link key={index} to={`/partner/${partner._id}`}>
                      {partner.head.name}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
            {userInfo && experiences?.length && filteredListings?.length ? (
              <>
                <div className="in-section">
                  <h6>in listings</h6>
                  <div className="collection">
                    {userInfo.type === "teacher" ? (
                      <>
                        {filteredListings
                          .filter((experience) =>
                            experience.grade.includes(userInfo.grade),
                          )
                          .map((experience, index) => (
                            <Link
                              key={index}
                              to={`/experience/${experience._id}`}
                            >
                              {experience.name}
                            </Link>
                          ))}
                      </>
                    ) : userInfo.type === "partner" ? (
                      <>
                        {filteredListings
                          .filter(
                            (experience) => experience.partner === userInfo._id,
                          )
                          .map((experience, index) => (
                            <Link
                              key={index}
                              to={`/experience/${experience._id}`}
                            >
                              {experience.name}
                            </Link>
                          ))}
                      </>
                    ) : (
                      <>
                        {filteredListings.map((experience, index) => (
                          <Link
                            key={index}
                            to={`/experience/${experience._id}`}
                          >
                            {experience.name}
                          </Link>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Search;
