import React, { useEffect, useState } from "react";
import Card from "./Card";
import CardH from "./CardH";
import { profileTags } from "../data/data";

const UserShowcase = ({ users }) => {
  const [filteredUsers, setFilteredUsers] = useState(users);

  const [view, setView] = useState("grid");

  const viewHandler = (e) => {
    if (view !== e.target.id) {
      setView(e.target.id);
    }
  };

  const resetHandler = () => {
    setFilteredUsers(users);
  };

  const valueHandler = (e) => {
    if (["hide", "all", "new"].includes(e.target.value)) {
      resetHandler();
    } else {
      setFilteredUsers(users.filter((u) => u.tags.includes(e.target.value)));
    }
    /*
        setFilteredListings(
      listings.filter((item) => item.subject === e.target.id)
    );

    */
  };

  useEffect(() => {}, [view, users, filteredUsers]);

  return (
    <div className="partner-showcase">
      <div className="container">
        <div
          className="controls"
          style={
            window.innerWidth < 835
              ? {
                  flexDirection: "column",
                  gap: "20px",
                }
              : {}
          }
        >
          <div className="navigation">
            <form onSubmit={() => {}}>
              <div className="select">
                <select onChange={valueHandler}>
                  <option value="hide">Filter by tag</option>
                  <option value="all">All</option>
                  <option value="new">New</option>
                  {profileTags.map((ele, index) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
          {/* <div className="view">
            <button className="grid-view btn" id="grid" onClick={viewHandler}>
              <span className="grid-row">
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
              </span>
              <span className="grid-row">
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
              </span>
              <span className="grid-row">
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
              </span>
            </button>
            <button className="tile-view btn" id="tile" onClick={viewHandler}>
              <span className="tile-row">
                <span className="grid-pixel"></span>
                <span className="grid-w-pixel"></span>
              </span>
              <span className="tile-row">
                <span className="grid-pixel"></span>
                <span className="grid-w-pixel"></span>
              </span>
              <span className="tile-row">
                <span className="grid-pixel"></span>
                <span className="grid-w-pixel"></span>
              </span>
            </button>
          </div> */}
        </div>
        <div
          className="partners network"
          style={
            window.innerWidth < 835
              ? { justifyContent: "space-between" }
              : { justifyContent: "flex-start" }
          }
        >
          {view === "grid" ? (
            <>
              {filteredUsers?.length ? (
                filteredUsers.map((partner, index) => (
                  <Card key={index} content={partner} cardType="partner" />
                ))
              ) : (
                <p className="empty">Please try different criteria</p>
              )}
            </>
          ) : view === "tile" ? (
            <>
              {filteredUsers?.length ? (
                filteredUsers.map((partner, index) => (
                  <CardH key={index} content={partner} cardType="partner" />
                ))
              ) : (
                <p className="empty">Please try different criteria</p>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default UserShowcase;
