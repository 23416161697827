import React from "react";

import { Link } from "react-router-dom";

const Landing2 = ({
  height,
  shadowColor,
  bg,
  position,
  vertical,
  horizontal,
  sWidth,
  sColor,
  subheading,
  heading,
  hWidth,
  copy,
  cWidth,
  fullWidth,
  btn,
  link,
  color,
  cColor,
  btn2,
  link2,
  customHeight,
}) => {
  return (
    <div
      className="landing2"
      style={{
        height:
          window.innerWidth < 835
            ? customHeight
              ? customHeight
              : "100vh"
            : height
            ? height
            : "fit-content",
        alignItems: window.innerWidth < 835 ? `center` : `${vertical}`,
        backgroundImage: bg ? `url(/images/landing-${bg}.png)` : "",
      }}
    >
      <div
        className="container"
        style={{
          justifyContent: window.innerWidth < 835 ? `center` : `${horizontal}`,
        }}
      >
        <div
          className="landing-content2"
          style={
            window.innerWidth < 835
              ? {
                  margin: "0 auto",
                  top: `50%`,
                  transform: "translateY(-50%)",
                }
              : {
                  top: `${position}`,
                }
          }
        >
          {window.innerWidth < 835 && (
            <div
              className="landing-shadow"
              style={{
                backgroundColor: `var(--var-color-vrc-${shadowColor})`,
              }}
            ></div>
          )}
          <div>
            {subheading && (
              <h4 className="landing-subheading"
                style={
                  window.innerWidth < 835
                    ? {
                        width: window.innerWidth - 80,
                        textAlign: "center",
                        color: `var(--var-color-vrc-${sColor})`,
                      }
                    : sWidth
                    ? {
                        width: sWidth,
                        color: `var(--var-color-vrc-${sColor})`,
                      }
                    : {
                        color: `var(--var-color-vrc-${sColor})`,
                      }
                }
              >
                {subheading}
              </h4>
            )}
            <h1 className="landing-heading"
              style={
                window.innerWidth < 835
                  ? {
                      textAlign: "center",
                      color: `var(--var-color-vrc-${color})`,
                      width: window.innerWidth - 80,
                    }
                  : {
                      color: `var(--var-color-vrc-${color})`,
                      width: `${hWidth}`,
                    }
              }
            >
              {heading}
            </h1>
          </div>
          {copy && (
            <p className="landing-copy"
              style={
                window.innerWidth < 835
                  ? {
                      width: window.innerWidth - 80,
                      textAlign: "center",
                      color: `var(--var-color-vrc-${cColor})`,
                    }
                  : fullWidth
                  ? { width: "100%", color: `var(--var-color-vrc-${cColor})` }
                  : { width: cWidth, color: `var(--var-color-vrc-${cColor})` }
              }
            >
              {copy}
            </p>
          )}
          <div
            className="btn-container"
            style={window.innerWidth < 835 ? { justifyContent: "center" } : {}}
          >
            {btn && (
              <Link className="btn" to={link}>
                {btn}
              </Link>
            )}
            {btn2 && (
              <Link className="btn" to={link2}>
                {btn2}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing2;
