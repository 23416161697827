import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listUsers } from "../actions/userActions";

import { hompageLandings, homepageLandingSettings } from "../components/data";
import Slider from "react-slick";
import Landing2 from "../components/Landing2";
import Collection from "../components/Collection";
import Loader from "../components/Loader";
import Message from "../components/Message";

const HomeScreen = () => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  useEffect(() => {
    dispatch(listUsers("partner"));
  }, [dispatch]);

  return (
    <div className="homepage" style={{ position: "relative" }}>
      <div>
        <Slider {...homepageLandingSettings}>
          {hompageLandings.map((item, index) => (
            <Landing2
              key={index}
              height="1080px"
              bg={item.bg}
              position="30%"
              horizontal="flex-start"
              vertical="center"
              subheading={item.subheading}
              sWidth="600px"
              heading={item.heading}
              hWidth="530px"
              copy={item.copy}
              cWidth="400px"
              btn={item.btn}
              link={item.link}
              shadowColor="grey"
              color="beige"
              sColor="beige"
              cColor="beige"
            />
          ))}
        </Slider>
      </div>
      <Landing2
        height="1080px"
        bg="students"
        position="20%"
        horizontal="flex-start"
        vertical="center"
        heading="A New World Is Opening Up"
        copy={`Now more than ever, people know the power of virtual reality and video conferencing. Find a local experience to take your class on or let your students explore beyond their immediate surroundings by booking live-streamed field trips to interesting sites around British Columbia.

        Create direct relationships with leading experts in a variety of fields and let your class learn directly from the source.
        `}
        shadowColor="grey"
        color="red"
        cColor="beige"
      />
      <Landing2
        height="1080px"
        bg="discover"
        position="10%"
        horizontal="flex-start"
        vertical="flex-start"
        subheading="for teachers"
        heading="What will you discover?"
        copy="Explore the resources that are available to your class and how to bring the curriculum to life. Field trips, presentations, activity books, virtual tours, and interactive scavenger hunts are just some of the resources waiting for you when you join the Classroom Voyager community."
        btn="learn more"
        link="for-teachers"
        color="yellow"
        cColor="beige"
        sColor="beige"
        shadowColor="grey"
      />
      <Landing2
        height="640px"
        bg="story"
        position="-10%"
        horizontal="flex-start"
        vertical="flex-start"
        subheading="for partners"
        heading="How to share your story?"
        hWidth="800px"
        copy="Discover the ways you can promote your education programs through listings and our database. Plan, produce, source funding, and connect your programming to the BC curriculum with the full support of the Classroom Voyager’s Curriculum and Partnership Coordinators, and production partners."
        btn="learn more"
        link="for-partners"
        color="beige"
        cColor="beige"
        sColor="beige"
        shadowColor="grey"
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        users && (
          <div
            className="partners"
            style={{
              height: "fit-content",
              paddingTop: "100px",
              backgroundImage: "url(/images/landing-generic.png)",
            }}
          >
            <Collection
              collectionType="partners"
              heading="new partnering organizations"
              items={users}
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            />
          </div>
        )
      )}
    </div>
  );
};

export default HomeScreen;
