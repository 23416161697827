export const GET_DISCOUNT_CODE_REQUEST = "GET_DISCOUNT_CODE_REQUEST";
export const GET_DISCOUNT_CODE_SUCCESS = "GET_DISCOUNT_CODE_SUCCESS";
export const GET_DISCOUNT_CODE_FAIL = "GET_DISCOUNT_CODE_FAIL";
export const GET_DISCOUNT_CODE_RESET = "GET_DISCOUNT_CODE_RESET";

export const BOOKING_SET_REQUEST = "BOOKING_SET_REQUEST";
export const BOOKING_SET_SUCCESS = "BOOKING_SET_SUCCESS";
export const BOOKING_SET_FAIL = "BOOKING_SET_FAIL";
export const BOOKING_SET_RESET = "BOOKING_SET_RESET";

export const BOOKING_DELETE_REQUEST = "BOOKING_DELETE_REQUEST";
export const BOOKING_DELETE_SUCCESS = "BOOKING_DELETE_SUCCESS";
export const BOOKING_DELETE_FAIL = "BOOKING_DELETE_FAIL";
export const BOOKING_DELETE_RESET = "BOOKING_DELETE_RESET";

export const BOOKING_DETAILS_REQUEST = "BOOKING_DETAILS_REQUEST";
export const BOOKING_DETAILS_SUCCESS = "BOOKING_DETAILS_SUCCESS";
export const BOOKING_DETAILS_FAIL = "BOOKING_DETAILS_FAIL";
export const BOOKING_DETAILS_RESET = "BOOKING_DETAILS_RESET";

export const BOOKING_CONFIRM_REQUEST = "BOOKING_CONFIRM_REQUEST";
export const BOOKING_CONFIRM_SUCCESS = "BOOKING_CONFIRM_SUCCESS";
export const BOOKING_CONFIRM_FAIL = "BOOKING_CONFIRM_FAIL";
export const BOOKING_CONFIRM_RESET = "BOOKING_CONFIRM_RESET";

export const BOOKING_DECLINE_REQUEST = "BOOKING_DECLINE_REQUEST";
export const BOOKING_DECLINE_SUCCESS = "BOOKING_DECLINE_SUCCESS";
export const BOOKING_DECLINE_FAIL = "BOOKING_DECLINE_FAIL";
export const BOOKING_DECLINE_RESET = "BOOKING_DECLINE_RESET";

export const BOOKING_PAY_REQUEST = "BOOKING_PAY_REQUEST";
export const BOOKING_PAY_SUCCESS = "BOOKING_PAY_SUCCESS";
export const BOOKING_PAY_FAIL = "BOOKING_PAY_FAIL";
export const BOOKING_PAY_RESET = "BOOKING_PAY_RESET";

export const BOOKING_PAY_LATER_REQUEST = "BOOKING_PAY_LATER_REQUEST";
export const BOOKING_PAY_LATER_SUCCESS = "BOOKING_PAY_LATER_SUCCESS";
export const BOOKING_PAY_LATER_FAIL = "BOOKING_PAY_LATER_FAIL";
export const BOOKING_PAY_LATER_RESET = "BOOKING_PAY_LATER_RESET";

export const BOOKING_MARK_PAID_REQUEST = "BOOKING_MARK_PAID_REQUEST";
export const BOOKING_MARK_PAID_SUCCESS = "BOOKING_MARK_PAID_SUCCESS";
export const BOOKING_MARK_PAID_FAIL = "BOOKING_MARK_PAID_FAIL";
export const BOOKING_MARK_PAID_RESET = "BOOKING_MARK_PAID_RESET";

export const BOOKING_LIST_MY_BOOKINGS_REQUEST =
  "BOOKING_LIST_MY_BOOKINGS_REQUEST";
export const BOOKING_LIST_MY_BOOKINGS_SUCCESS =
  "BOOKING_LIST_MY_BOOKINGS_SUCCESS";
export const BOOKING_LIST_MY_BOOKINGS_FAIL = "BOOKING_LIST_MY_BOOKINGS_FAIL";
export const BOOKING_LIST_MY_BOOKINGS_RESET = "BOOKING_LIST_MY_BOOKINGS_RESET";

export const BOOKING_LIST_ALL_BOOKINGS_REQUEST =
  "BOOKING_LIST_ALL_BOOKINGS_REQUEST";
export const BOOKING_LIST_ALL_BOOKINGS_SUCCESS =
  "BOOKING_LIST_ALL_BOOKINGS_SUCCESS";
export const BOOKING_LIST_ALL_BOOKINGS_FAIL = "BOOKING_LIST_ALL_BOOKINGS_FAIL";
