export const CONTACTMAIL_CREATE_REQUEST = "CONTACTMAIL_CREATE_REQUEST";
export const CONTACTMAIL_CREATE_SUCCESS = "CONTACTMAIL_CREATE_SUCCESS";
export const CONTACTMAIL_CREATE_FAIL = "CONTACTMAIL_CREATE_FAIL";

export const CONTACTMAIL_DELETE_REQUEST = "CONTACTMAIL_DELETE_REQUEST";
export const CONTACTMAIL_DELETE_SUCCESS = "CONTACTMAIL_DELETE_SUCCESS";
export const CONTACTMAIL_DELETE_FAIL = "CONTACTMAIL_DELETE_FAIL";
export const CONTACTMAIL_DELETE_RESET = "CONTACTMAIL_DELETE_RESET";

export const CONTACTMAIL_LIST_REQUEST = "CONTACTMAIL_LIST_REQUEST";
export const CONTACTMAIL_LIST_SUCCESS = "CONTACTMAIL_LIST_SUCCESS";
export const CONTACTMAIL_LIST_FAIL = "CONTACTMAIL_LIST_FAIL";

export const CONTACTMAIL_DETAILS_REQUEST = "CONTACTMAIL_DETAILS_REQUEST";
export const CONTACTMAIL_DETAILS_SUCCESS = "CONTACTMAIL_DETAILS_SUCCESS";
export const CONTACTMAIL_DETAILS_FAIL = "CONTACTMAIL_DETAILS_FAIL";
