import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { logout } from "../actions/userActions";
import Benefit from "./Benefit";
import { mobileNavMenu } from "./data";

const Header = ({ history }) => {
  const dispatch = useDispatch();

  //
  const [headerBG, setHeaderBG] = useState(false);

  useEffect(() => {
    const unlisten = history?.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    document.addEventListener(
      "scroll",
      () => {
        if (window.pageYOffset !== 0) {
          setHeaderBG(true);
        } else {
          setHeaderBG(false);
        }
      },
      true,
    );
  }, [headerBG]);
  //

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [mobileNavView, setMobileNavView] = useState("none");

  const displayMobileNav = () => {
    setMobileNavView("flex");
  };

  const hideMobileNav = () => {
    setMobileNavView("none");
  };

  const logoutHandler = () => {
    dispatch(logout());
    setLoggedIn(false);
  };

  useEffect(() => {
    if (userInfo) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user, userInfo, isLoggedIn]);

  return (
    <>
      <div
        id="header"
        style={{
          backgroundColor: headerBG ? "var(--var-color-vrc-grey)" : "",
        }}
      >
        <div className="logo">
          <Link to="/">
            <img className="logo-img" src="/images/logo.png" alt="vrc-logo" />
          </Link>
        </div>

        <ul className="main-menu">
          <li className="main-menu-item">
            <Link to="/the-platform">The platform</Link>
          </li>
          <li className="main-menu-item">
            <Link to="/our-network">Our Network</Link>
          </li>
          <li className="main-menu-item">
            <Link to="/for-teachers">for teachers</Link>
          </li>
          <li className="main-menu-item">
            <Link to="/for-partners">for partners</Link>
          </li>
          {isLoggedIn && userInfo && (
            <li className="main-menu-item">
              <Link to="/catalogue">listings catalogue</Link>
            </li>
          )}
        </ul>
        <div className="auth-menu">
          {isLoggedIn && userInfo && (
            <Link to="/messages" style={{ display: "flex" }}>
              <img
                className="message-img"
                src="/images/vrc-icons-message-beige.png"
                alt="vrc-message"
              />
            </Link>
          )}
          <Link to="/search">
            <img
              className="search-img"
              src="/images/vrc-icons-search-beige.png"
              alt="vrc-search"
            />
          </Link>
          {isLoggedIn && userInfo ? (
            <div className="btn header-dashboard-btn">
              {userInfo && userInfo.name?.split(" ")[0]}
              {userInfo && isLoggedIn && (
                <>
                  <Benefit
                    visual={
                      userInfo.type === "admin"
                        ? "/images/avatar-admin.png"
                        : userInfo.avatar
                    }
                  />
                  <ul>
                    <li>
                      <Link
                        to={userInfo.type === "admin" ? "/admin" : "/account"}
                      >
                        dashboard
                      </Link>
                    </li>
                    {userInfo.type !== "admin" && (
                      <li>
                        <Link to={`/mybookings`}>my bookings</Link>
                      </li>
                    )}
                    {userInfo.type !== "admin" && (
                      <li>
                        <Link to={`/${userInfo.type}/${userInfo._id}`}>
                          my profile
                        </Link>
                      </li>
                    )}
                    <li onClick={logoutHandler}>logout</li>
                  </ul>
                </>
              )}
            </div>
          ) : (
            <Link to="/account" className="btn">
              login/ register
            </Link>
          )}
          <Link className="contact-menu-item" to="/contact">
            contact
          </Link>
        </div>
        <div className="hamburger-menu btn" onClick={displayMobileNav}>
          <div className="buns">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul
          className="main-menu-2"
          style={{
            display: mobileNavView,
          }}
          onClick={hideMobileNav}
        >
          <li className="main-menu-item" onClick={hideMobileNav}>
            <Link to="/">the Classroom Voyager</Link>
          </li>
          {mobileNavMenu.map((ele, index) => (
            <li key={index} className="main-menu-item" onClick={hideMobileNav}>
              <Link to={`/${ele}`}>{ele.split("-").join(" ")}</Link>
            </li>
          ))}
          {isLoggedIn && userInfo ? (
            <>
              <span className="nav-divider"></span>
              <li className="main-menu-item" onClick={hideMobileNav}>
                <Link to="/catalogue">listings catalogue</Link>
              </li>
              <li className="main-menu-item" onClick={hideMobileNav}>
                <Link to={userInfo.type === "admin" ? "/admin" : "/account"}>
                  dashboard
                </Link>
              </li>
              <li className="main-menu-item" onClick={hideMobileNav}>
                <Link to="/messages">chat</Link>
              </li>
              {userInfo.type === "teacher" && (
                <li className="main-menu-item" onClick={hideMobileNav}>
                  <Link to={`/mybookings`}>my bookings</Link>
                </li>
              )}
              {userInfo.type !== "admin" && (
                <li className="main-menu-item" onClick={hideMobileNav}>
                  <Link to={`/${userInfo.type}/${userInfo._id}`}>
                    my profile
                  </Link>
                </li>
              )}

              <li
                onClick={() => {
                  logoutHandler();
                  hideMobileNav();
                }}
                className="main-menu-item"
              >
                <Link to={`/account`}>logout</Link>
              </li>
            </>
          ) : (
            <li className="main-menu-item" onClick={hideMobileNav}>
              <Link to="/account">login/ register</Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Header;
