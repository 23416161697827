import grades from "../data/grades";

// export const provinces = [
//   "British Columbia",
//   "Alberta",
//   "Saskatchewan",
//   "Manitoba",
//   "Ontario",
//   "Quebec",
//   "New Brunswick",
//   "Nova Scotia",
//   "Prince Edward Island",
//   "Newfoundland & Labrador",
// ];

export const provinces = ["British Columbia", "Yukon", "Northwest Territories"];

// export const regions = [
//   {
//     province: provinces[0],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[1],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[2],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[3],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[4],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[5],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[6],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[7],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[8],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
//   {
//     province: provinces[9],
//     list: [
//       "Vancouver Island & Coast",
//       "North Coast & Nechako",
//       "Northeast",
//       "Cariboo",
//       "Mainland & Southwest",
//       "Thompson Okanagan",
//       "Kootenay",
//     ],
//   },
// ];

export const regions = [
  {
    province: provinces[0],
    list: [
      "Vancouver Island & Coast",
      "North Coast & Nechako",
      "Northeast",
      "Cariboo",
      "Mainland & Southwest",
      "Thompson Okanagan",
      "Kootenay",
    ],
  },
  {
    province: provinces[1],
    list: [
      "Vancouver Island & Coast",
      "North Coast & Nechako",
      "Northeast",
      "Cariboo",
      "Mainland & Southwest",
      "Thompson Okanagan",
      "Kootenay",
    ],
  },
  {
    province: provinces[2],
    list: [
      "Vancouver Island & Coast",
      "North Coast & Nechako",
      "Northeast",
      "Cariboo",
      "Mainland & Southwest",
      "Thompson Okanagan",
      "Kootenay",
    ],
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  // "Saturday",
  // "Sunday",
];

export const timezones = [
  // "UTC -8 (Vancouver)",
  // "UTC -7 (Edmonton)",
  // "UTC -6 (Winnipeg)",
  // "UTC -5 (Toronto)",
  // "UTC -4 (Halifax)",
  // "UTC -3:30 (St. John's)",
  "PST",
  "MST",
  "CST",
  "EST",
  "AST",
  "NST",
];

export const mobileNavMenu = [
  "the-platform",
  "our-network",
  "for-teachers",
  "for-partners",
  "contact",
];

export const benefits = [
  {
    heading: "Training Sessions",
    copy: "Get tips on how to host the best virtual experience. Learn about the equipment you need and how to film yourself in the best way. We offer one-on-one support through dedicated Partnership Coordinators, but with larger themes, we organize training sessions for multiple partners to attend at the same time.",
  },
  {
    heading: "Funding Opportunities",
    copy: "We are working with our key partners to identify grants and subsidies to alleviate the financial burden of creating digital educational materials. Wherever possible, we will work with the BC Museums Association to submit grants to provide funding for individual partners as part of larger projects.",
  },
  {
    heading: "Curriculum Consultation",
    copy: "Let our provincial curriculum experts guide you through the planning process on new experiences and materials. One-on-one sessions are available, as well as opportunities for group training sessions through the Classroom Voyager or the BC Museums Association.",
  },
  {
    heading: "Sales & Marketing",
    copy: "Our team and provincial sales partners manage the complete process of promoting your offerings so that you can focus on programming. We are working with school districts, the BC Offshore School System, and private schools to create a community of teachers that are eager to utilize your expertise.",
  },
  {
    heading: "Integrated Bookings",
    copy: "We set up your field trip and presentation options in a way that allows quick bookings by teachers and minimizes the amount of back and forth you have to do with teachers. Integrations with in-house bookings systems are possible to ensure you aren’t creating more work for your team.",
  },
  {
    heading: "Analytics",
    copy: "Understand the demographics and engagement level of your audience. Learn about the classes that enjoy your content the most and help grow your programming in an informed way. Through your account dashboard, you can see which content is popular and where it is most popular.",
  },
];

export const hompageLandings = [
  {
    subheading: "Classroom Voyager",
    heading: "The platform that brings the world to BC students",
    copy: "Don’t just read about it, experience it! Register today for free and start exploring the world with your class right away.",
    bg: "homepage",
    btn: "Register Today",
    link: "account",
  },
  {
    subheading:
      "Specializing in Support for Cultural Organization Education Programming",
    heading: "Over 20% of BC Institutions Have Joined",
    copy: "Our team provides curriculum support, training opportunities, and access to classrooms in British Columbia and beyond. Don’t try to be an expert in everything, let the Classroom Voyager help promote and diversify your outreach so you can focus on what you do best, sharing your institution’s story.",
    bg: "connecting",
    btn: "learn more",
    link: "for-partners",
  },
  {
    subheading: "Make Learning Fun",
    heading: "Engaging Teaching Resources Direct from the Source",
    copy: "the Classroom Voyager is creating direct connections between BC teachers and the institutions that hold the keys to the variety of stories that have shaped the province. Working with our curriculum team, each connection is engaging and aligns perfectly with the provincial curriculum.",
    bg: "innovative",
    btn: "learn more",
    link: "for-teachers",
  },
];

export const homepageLandingSettings = {
  dots: false,
  navs: false,
  swipeToSlide: false,
  autoplay: true,
  infinite: true,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const profileCategories = [
  "Museum",
  "Cultural Centre",
  "Science Centre",
  "Environmental Organization",
  "Religious Site",
  "Heritage Site",
  "Archaeological Site",
  "Indigenous Community",
  "Indigenous Organization",
  "Indigenous Elder",
  "Art Gallery",
  "Charity",
  "Other",
];

export const experienceSubjects = [
  "Arts Education",
  "Biology",
  "Earth & Space",
  "Social Studies",
  // "Applied Design, Skills, and Technologies",
  // "Career Education",
  // "English Language Arts",
  // "Français Langue première",
  // "French Immersion Language Arts",
  // "Mathematics",
  // "Physical and Health Education",
  // "Languages",
];

export const coreCompetencies = [
  "Communicating",
  "Collaborating",
  "Creative Thinking",
  "Critical & Reflective Thinking",
  "Personal Awareness & Responsibility",
  "Positive Personal & Cultural Identity",
  "Social Awareness & Responsibility",
];

export const coreCompetenciesDescriptions = [
  {
    heading: coreCompetencies[0],
    copy: [
      "Communicating encompasses the set of abilities that people use to impart and exchange information, experiences, and ideas; to explore the world around them; and to understand and effectively use communication forms, strategies, and technologies. Communicating provides a bridge between peoples’ learning, their personal and social identity, and the world in which they interact.",
      "People who communicate effectively use their skills and strategies intentionally to ensure understanding their audience. They communicate in an increasing variety of contexts, for a variety of purposes, and often with multiple audiences.",
    ],
  },
  {
    heading: coreCompetencies[1],
    copy: [
      "Collaborating involves the skills, strategies, and dispositions that people use to work together to pursue common purposes and accomplish common goals.",
      "People who collaborate effectively recognize how combining others’ perspectives, strategies, and efforts with their own enhances collective understanding, use, and impact. They value the contributions of group members, interact supportively and effectively using inclusive practices, and strive for shared commitment and mutual benefit.",
    ],
  },
  {
    heading: coreCompetencies[2],
    copy: [
      "Creative Thinking involves the generation of ideas and concepts that are novel and innovative in the context in which they are generated, reflection on their value to the individual or others, and the development of chosen ideas and concepts from thought to reality.",
      "People who think creatively are curious and open-minded, have a sense of wonder and joy in learning, demonstrate a willingness to think divergently, and are comfortable with complexity. A creative thinker reflects on existing ideas and concepts; uses imagination, inventiveness, resourcefulness, and flexibility; and is willing to take risks to go beyond existing knowledge.",
    ],
  },
  {
    heading: coreCompetencies[3],
    copy: [
      "Critical and Reflective Thinking encompasses a set of abilities that students use to examine their own thinking and that of others. This involves making judgments based on reasoning, where students consider options, analyze options using specific criteria, and draw conclusions.",
      "People who think critically and reflectively are analytical and investigative, willing to question and challenge their own thoughts, ideas, and assumptions and challenge those of others. They reflect on the information they receive through observation, experience, and other forms of communication to solve problems, design products, understand events, and address issues. A critical thinker uses their ideas, experiences, and reflections to set goals, make judgments, and refine their thinking.",
    ],
  },
  {
    heading: coreCompetencies[4],
    copy: [
      "Personal Awareness and Responsibility involves understanding the connections between personal and social behaviour and well-being; it encourages people to make constructive and ethical decisions and act on them.",
      "People who are personally aware and responsible demonstrate self-respect, persevere in difficult situations, and exercise responsibility. They understand that there are consequences for their decisions and actions. A personally aware and responsible individual takes steps to ensure their well-being, sets goals and monitors progress, regulates emotions and manages stress, and recognizes and advocates for their own rights.",
    ],
  },
  {
    heading: coreCompetencies[5],
    copy: [
      "Positive Personal and Cultural Identity involves the awareness, understanding, and appreciation of the factors that contribute to a healthy sense of oneself; it includes knowledge of one’s family background, heritage(s), language(s), beliefs, and perspectives in a pluralistic society.",
      "People who have a positive personal and cultural identity value their personal and cultural narratives and understand how these shape their identity. They exhibit a sense of self-worth, self-awareness, and positive identity to become confident individuals who take satisfaction in who they are and what they can do. They contribute to their own well-being and to the well-being of their family, community, and society.",
    ],
  },
  {
    heading: coreCompetencies[6],
    copy: [
      "Social Awareness and Responsibility involves the awareness, understanding, and appreciation of connections among people, including between people and the natural environment. Social Awareness and Responsibility focuses on interacting with others and the natural world in respectful and caring ways.",
      "People who are socially aware and responsible contribute to the well-being of their social and physical environments. They support the development of welcoming and inclusive communities, where people feel safe and have a sense of belonging.",
      "A socially aware and responsible individual contributes positively to their family, community, and environment; empathizes with others and appreciates their perspectives; resolves problems peacefully; and develops and sustains healthy relationships.",
    ],
  },
];

const curriculumHandles = [
  "Inquiry",
  "Significance",
  "Evidence",
  "Continuity & Change",
  "Cause & Consequences",
  "Perspective",
  "Ethical Judgement",
];

export const curriculumCompetencies = [
  {
    grade: grades[0],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Explain the significance of personal or local events, objects, people, or places.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, make inferences, and draw conclusions about the content and features of different types of sources.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, or events, and distinguish between what has changed and what has stayed the same.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Recognize causes and consequences of events, decisions, or developments in their lives.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Acknowledge different perspectives on people, places, issues, or events in their lives.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Identify fair and unfair aspects of events, decisions, or actions in their lives and consider appropriate courses of action.",
      },
    ],
  },
  {
    grade: grades[1],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Explain the significance of personal or local events, objects, people, or places.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, make inferences, and draw conclusions about the content and features of different types of sources.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, or events, and distinguish between what has changed and what has stayed the same.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Recognize causes and consequences of events, decisions, or developments in their lives.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explore different perspectives on people, places, issues, or events in their lives.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Identify fair and unfair aspects of events, decisions, or actions in their lives and consider appropriate courses of action.",
      },
    ],
  },
  {
    grade: grades[2],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Explain why people, events, or places are significant to various individuals and groups.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, make inferences, and draw conclusions about the content and features of different types of sources.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, and events, or explain why some aspects change and others stay the same.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Recognize causes and consequences of events, decisions, or developments.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explain why people’s beliefs, values, worldviews, experiences, and roles give them different perspectives on people, places, issues, or events.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make value judgments about events, decisions, or actions, and suggest lessons that can be learned.",
      },
    ],
  },
  {
    grade: grades[3],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Explain why people, events, or places are significant to various individuals and groups.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, make inferences, and draw conclusions about the content and features of different types of sources.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, or events, and explain why some aspects change and others stay the same.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Recognize causes and consequences of events, decisions, or developments.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explain why people’s beliefs, values, worldviews, experiences, and roles give them different perspectives on people, places, issues, or events.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make value judgments about events, decisions, or actions, and suggest lessons that can be learned.",
      },
    ],
  },
  {
    grade: grades[4],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Construct arguments defending the significance of individuals/groups, places, events, or developments.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, corroborate inferences, and draw conclusions about the content and origins of different sources.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, or events, and determine continuities and changes between different time periods or places.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Differentiate between intended and unintended consequences of events, decisions, or developments, and speculate about alternative outcomes.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Construct narratives that capture the attitudes, values, and worldviews commonly held by people at different times or places.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make ethical judgments about events, decisions, or actions that consider the conditions of a particular time and place.",
      },
    ],
  },
  {
    grade: grades[5],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions. Develop a plan of action to address a selected problem or issue.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Construct arguments defending the significance of individuals/groups, places, events, or developments.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, corroborate inferences, and draw conclusions about the content and origins of a variety of sources, including mass media.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, or events, and recognize the positive and negative aspects of continuities and changes in the past and present.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Differentiate between intended and unintended consequences of events, decisions, or developments, and speculate about alternative outcomes.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Take stakeholders’ perspectives on issues, developments, or events by making inferences about their beliefs, values, and motivations.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make ethical judgments about events, decisions, or actions that consider the conditions of a particular time and place, and assess appropriate ways to respond.",
      },
    ],
  },
  {
    grade: grades[6],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions. Develop a plan of action to address a selected problem or issue.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Construct arguments defending the significance of individuals/groups, places, events, or developments.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Ask questions, corroborate inferences, and draw conclusions about the content and origins of a variety of sources, including mass media.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Sequence objects, images, or events, and recognize the positive and negative aspects of continuities and changes in the past and present.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Differentiate between short- and long-term causes, and intended and unintended consequences, of events, decisions, or developments.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Take stakeholders’ perspectives on issues, developments, or events by making inferences about their beliefs, values, and motivations.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make ethical judgments about events, decisions, or actions that consider the conditions of a particular time and place, and assess appropriate ways to respond.",
      },
    ],
  },
  {
    grade: grades[7],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Assess the significance of people, places, events, or developments at particular times and places. Identify what the creators of accounts, narratives, maps, or texts have determined is significant.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Assess the credibility of multiple sources and the adequacy of evidence used to justify conclusions.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Characterize different time periods in history, including periods of progress and decline, and identify key turning points that marked periods of change.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Determine which causes most influenced particular decisions, actions, or events, and assess their short- and longterm consequences.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explain different perspectives on past or present people, places, issues, or events, and compare the values, worldviews, and beliefs of human cultures and societies in different times and places.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make ethical judgments about past events, decisions, or actions, and assess the limitations of drawing direct lessons from the past.",
      },
    ],
  },
  {
    grade: grades[8],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Assess the significance of people, places, events, or developments at particular times and places. Identify what the creators of accounts, narratives, maps, or texts have determined is significant.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Assess the credibility of multiple sources and the adequacy of evidence used to justify conclusions.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Characterize different time periods in history, including periods of progress and decline, and identify key turning points that mark periods of change.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Determine which causes most influenced particular decisions, actions, or events, and assess their short-and longterm consequences.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explain different perspectives on past or present people, places, issues, or events, and compare the values, worldviews, and beliefs of human cultures and societies in different times and places.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make ethical judgments about past events, decisions, or actions, and assess the limitations of drawing direct lessons from the past.",
      },
    ],
  },
  {
    grade: grades[9],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Assess the significance of people, places, events, or developments, and compare varying perspectives on their historical significance at particular times and places, and from group to group.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Assess the justification for competing historical accounts after investigating points of contention, reliability of sources, and adequacy of evidence.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Compare and contrast continuities and changes for different groups at the same time period.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Assess how prevailing conditions and the actions of individuals or groups affect events, decisions, or developments.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explain and infer different perspectives on past or present people, places, issues, or events by considering prevailing norms, values, worldviews, and beliefs.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Recognize implicit and explicit ethical judgments in a variety of sources. Make reasoned ethical judgments about actions in the past and present, and determine appropriate ways to remember and respond.",
      },
    ],
  },
  {
    grade: grades[10],
    points: [
      {
        handle: curriculumHandles[0],
        description:
          "Use Social Studies inquiry processes and skills to ask questions; gather, interpret, and analyze ideas; and communicate findings and decisions.",
      },
      {
        handle: curriculumHandles[1],
        description:
          "Assess the significance of people, places, events, or developments, and compare varying perspectives on their significance at particular times and places, and from group to group.",
      },
      {
        handle: curriculumHandles[2],
        description:
          "Assess the justification for competing accounts after investigating points of contention, reliability of sources, and adequacy of evidence, including data.",
      },
      {
        handle: curriculumHandles[3],
        description:
          "Compare and contrast continuities and changes for different groups at particular times and places.",
      },
      {
        handle: curriculumHandles[4],
        description:
          "Assess how underlying conditions and the actions of individuals or groups influence events, decisions, or developments, and analyze multiple consequences.",
      },
      {
        handle: curriculumHandles[5],
        description:
          "Explain and infer different perspectives on past or present people, places, issues, or events by considering prevailing norms, values, worldviews, and beliefs.",
      },
      {
        handle: curriculumHandles[6],
        description:
          "Make reasoned ethical judgments about actions in the past and present, and assess appropriate ways to remember and respond.",
      },
    ],
  },
];

export const bigIdeas = [
  {
    grade: grades[0],
    ideas: [
      "Our communities are diverse and made up of individuals who have a lot in common.",
      "Stories and traditions about ourselves and our families reflect who we are and where we are from.",
      "Rights, roles, and responsibilities shape our identity and help us build healthy relationships with others.",
    ],
  },
  {
    grade: grades[1],
    ideas: [
      "We shape the local environment, and the local environment shapes who we are and how we live.",
      "Our rights, roles, and responsibilities are important for building strong communities.",
      "Healthy communities recognize and respect the diversity of individuals and care for the local environment.",
    ],
  },
  {
    grade: grades[2],
    ideas: [
      "Local actions have global consequences, and global actions have local consequences.",
      "Canada is made up of many diverse regions and communities.",
      "Individuals have rights and responsibilities as global citizens.",
    ],
  },
  {
    grade: grades[3],
    ideas: [
      "Learning about indigenous peoples nurtures multicultural awareness and respect for diversity.",
      "People from diverse cultures and societies share some common experiences and aspects of life.",
      "Indigenous knowledge is passed down through oral history, traditions, and collective memory.",
      "Indigenous societies throughout the world value the well-being of the self, the land, spirits, and ancestors.",
    ],
  },
  {
    grade: grades[4],
    ideas: [
      "The pursuit of valuable natural resources has played a key role in changing the land, people, and communities of Canada.",
      "Interactions between First Peoples and Europeans led to conflict and co-operation, which continue to shape Canada’s identity.",
      "Demographic changes in North America created shifts in economic and political power.",
      "British Columbia followed a unique path in becoming a part of Canada.",
    ],
  },
  {
    grade: grades[5],
    ideas: [
      "Canada’s policies for and treatment of minority peoples have negative and positive legacies.",
      "Natural resources continue to shape the economy and identity of different regions of Canada.",
      "Immigration and multiculturalism continue to shape Canadian society and identity.",
      "Canadian institutions and government reflect the challenge of our regional diversity.",
    ],
  },
  {
    grade: grades[6],
    ideas: [
      "Economic self-interest can be a significant cause of conflict among peoples and governments.",
      "Complex global problems require international co-operation to make difficult choices for the future.",
      "Systems of government vary in their respect for human rights and freedoms.",
      "Media sources can both positively and negatively affect our understanding of important events and issues.",
    ],
  },
  {
    grade: grades[7],
    ideas: [
      "Geographic conditions shaped the emergence of civilizations.",
      "Religious and cultural practices that emerged during this period have endured and continue to influence people.",
      "Increasingly complex societies required new systems of laws and government.",
      "Economic specialization and trade networks can lead to conflict and co-operation between societies.",
    ],
  },
  {
    grade: grades[8],
    ideas: [
      "Contact and conflict between peoples stimulated significant cultural, social, and political change.",
      "Human and environmental factors shape changes in population and living standards.",
      "Exploration, expansion, and colonization had varying consequences for different groups.",
      "Changing ideas about the world created tension between people wanting to adopt new ideas and those wanting to preserve established traditions.",
    ],
  },
  {
    grade: grades[9],
    ideas: [
      "Emerging ideas and ideologies profoundly influence societies and events.",
      "The physical environment influences the nature of political, social, and economic change.",
      "Disparities in power alter the balance of relationships between individuals and between societies.",
      "Collective identity is constructed and can change over time.",
    ],
  },
  {
    grade: grades[10],
    ideas: [
      "Global and regional conflicts have been a powerful force in shaping our contemporary world and identities.",
      "The development of political institutions is influenced by economic, social, ideological, and geographic factors.",
      "Worldviews lead to different perspectives and ideas about developments in Canadian society.",
      "Historical and contemporary injustices challenge the narrative and identity of Canada as an inclusive, multicultural society.",
    ],
  },
];

export const contents = [
  {
    grade: grades[0],
    contents: [
      "Ways in which individuals and families differ and are the same",
      "Personal and family history and traditions",
      "Needs and wants of individuals and families",
      "Rights, roles, and responsibilities of individuals and groups",
      "People, places, and events in the local community, and in local First Peoples communities",
    ],
  },
  {
    grade: grades[1],
    contents: [
      "Characteristics of the local community that provide organization and meet the needs of the community",
      "Diverse cultures, backgrounds, and perspectives within the local and other communities",
      "Relationships between a community and its environment",
      "Roles, rights, and responsibilities in the local community",
      "Key events and developments in the local community, and in local First Peoples communities",
      "Natural and human-made features of the local environment",
    ],
  },
  {
    grade: grades[2],
    contents: [
      "Diverse characteristics of communities and cultures in Canada and around the world, including at least one Canadian First Peoples community and culture",
      "How people’s needs and wants are met in communities",
      "Relationships between people and the environment in different communities",
      "Diverse features of the environment in other parts of Canada and the world",
      "Rights and responsibilities of individuals regionally and globally",
      "Roles and responsibilities of regional governments",
    ],
  },
  {
    grade: grades[3],
    contents: [
      "Cultural characteristics and ways of life of local First Peoples and global indigenous peoples",
      "Aspects of life shared by and common to peoples and cultures",
      "Interconnections of cultural and technological innovations of global and local indigenous peoples",
      "Governance and social organization in local and global indigenous societies",
      "Oral history, traditional stories, and artifacts as evidence about past First Peoples cultures",
      "Relationship between humans and their environment",
    ],
  },
  {
    grade: grades[4],
    contents: [
      "Early contact, trade, co-operation, and conflict between First Peoples and European peoples",
      "The fur trade in pre-Confederation Canada and British Columbia",
      "Demographic changes in pre-Confederation British Columbia in both First Peoples and non–First Peoples communities",
      "Economic and political factors that influenced the colonization of British Columbia and its entry into Confederation",
      "The impact of colonization on First Peoples societies in British Columbia and Canada",
      "The history of the local community and of local First Peoples communities",
      "Physiographic features and natural resources of Canada",
    ],
  },
  {
    grade: grades[5],
    contents: [
      "The development and evolution of Canadian identity over time",
      "The changing nature of Canadian immigration over time",
      "Past discriminatory government policies and actions, such as the Chinese Head Tax, the Komagata Maru incident, residential schools, and internments",
      "Human rights and responses to discrimination in Canadian society",
      "Levels of government (First Peoples, federal, provincial, and municipal), their main functions, and sources of funding",
      "Participation and representation in Canada’s system of government",
      "Resources and economic development in different regions of Canada",
      "First Peoples land ownership and use",
    ],
  },
  {
    grade: grades[6],
    contents: [
      "The urbanization and migration of people",
      "Global poverty and inequality issues, including class structure and gender",
      "Roles of individuals, governmental organizations, and NGOs, including groups representing indigenous peoples",
      "Different systems of government",
      "Economic policies and resource management, including effects on indigenous peoples",
      "Globalization and economic interdependence",
      "International co-operation and responses to global issues",
      "Regional and international conflict",
      "Media technologies and coverage of current events",
    ],
  },
  {
    grade: grades[7],
    contents: [
      "Anthropological origins of humans",
      "Human responses to particular geographic challenges and opportunities, including climates, landforms, and natural resources",
      "Features and characteristics of civilizations, and factors that led to their rise and fall",
      "Origins, core beliefs, narratives, practices, and influences of religions, including at least one indigenous to the Americas",
      "Scientific, philosophical, and technological developments",
      "Interactions and exchanges between past civilizations and cultures, including conflict, peace, trade, expansion, and migration",
      "Social, political, legal, governmental, and economic systems and structures, including at least one indigenous to the Americas",
    ],
  },
  {
    grade: grades[8],
    contents: [
      "Social, political, and economic systems and structures, including those of at least one indigenous civilization",
      "Scientific and technological innovations",
      "Philosophical and cultural shifts",
      "Interactions and exchanges of resources, ideas, arts, and culture between and among different civilizations",
      "Exploration, expansion, and colonization",
      "Changes in population and living standards",
    ],
  },
  {
    grade: grades[9],
    contents: [
      "Political, social, economic, and technological revolutions",
      "Imperialism and colonialism, and their continuing effects on indigenous peoples in Canada and around the world",
      "Global demographic shifts, including patterns of migration and population growth",
      "Nationalism and the development of modern nation-states, including Canada",
      "Local, regional, and global conflicts",
      "Discriminatory policies and injustices in Canada and the world, such as the Head Tax, the Komagata Maru incident, residential schools, and World War I internment",
      "Physiographic features and natural resources in Canada",
    ],
  },
  {
    grade: grades[10],
    contents: [
      "Government, First Peoples governance, political institutions, and ideologies",
      "Environmental, political, and economic policies",
      "Canadian autonomy",
      "Canadian identities",
      "Discriminatory policies and injustices in Canada and the world, including residential schools, the head tax, the Komagata Maru incident, and internments",
      "Advocacy for human rights, including findings and recommendations of the Truth and Reconciliation Commission",
      "Domestic conflicts and co-operation",
      "International conflicts and co-operation",
    ],
  },
];

export const newsletterElementList = [
  "heading",
  "subheading",
  "paragraph",
  "bullet point",
  "image",
  "link",
  "divider",
];

export const awarenessChannels = [
  "Advertising",
  "Internet",
  "Social Media",
  "Another Teacher",
  "Institution",
];
