import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listExperiences } from "../actions/experienceActions";

import Collection from "../components/Collection";
import Landing from "../components/Landing";
import Loader from "../components/Loader";
import Message from "../components/Message";
import ListingShowcase from "../components/ListingShowcase";
import { listUsers } from "../actions/userActions";
import grades from "../data/grades";

const CatalogueScreen = ({ history }) => {
  const dispatch = useDispatch();

  const experienceList = useSelector((state) => state.experienceList);
  const { loading, error, experiences } = experienceList;

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorUsers, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [finalGrades, setFinalGrades] = useState(null);

  const [listings, setListings] = useState(null);

  useEffect(() => {
    if (experiences) {
      setListings(
        experiences
          .map((exp) => {
            return { ...exp, grade: exp.grade.split(",") };
          })
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }),
      );
    } else {
      dispatch(listExperiences());
      dispatch(listUsers("partner"));
    }
  }, [experiences, dispatch]);

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    }

    dispatch(listExperiences());
    dispatch(listUsers("partner"));
  }, [userInfo, history, dispatch]);

  // view as teacher
  const viewAsTeacher = (e) => {
    setFinalGrades(e.target.id);
  };

  const resetFinalGrades = () => {
    setFinalGrades(null);
  };

  return (
    <>
      <div
        className="landing-generic"
        style={{
          backgroundImage: "url(./images/landing-generic.png)",
        }}
      >
        <Landing
          heading="listings catalogue"
          copy="Use this catalogue to search for the best experiences to take your class on. Our partners have education programmers that work tirelessly to develop new field trips and presentations to offer to classes of every age. If you are looking for something specific, use the filters to sort your results and find the right experience to bring your curriculum to life. Some of the listings here also have special add-on features that offer even more excitement for your students."
          fullWidth={true}
          color="beige"
          cColor="beige"
        />
        {loading || loadingUsers ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : errorUsers ? (
          <Message body={errorUsers} />
        ) : userInfo && experiences && listings && listings?.length && users ? (
          <>
            <div
              className="container"
              style={{ marginBottom: "50px", zIndex: "1000" }}
            >
              {userInfo.type === "admin" && (
                <div
                  className="btn header-dashboard-btn"
                  style={{
                    width: "fit-content",
                    padding: "0 40px",
                    zIndex: "1000",
                  }}
                >
                  view as teacher
                  <ul>
                    {grades.map((ele, index) => (
                      <li key={index} id={ele} onClick={viewAsTeacher}>
                        {ele}
                      </li>
                    ))}
                    <li onClick={resetFinalGrades}>reset</li>
                  </ul>
                </div>
              )}
            </div>

            <Collection
              collectionType="experiences"
              heading="explore our newest listings"
              items={
                userInfo.type === "teacher"
                  ? listings.filter((exp) => exp.grade.includes(userInfo.grade))
                  : userInfo.type === "partner"
                  ? listings.filter((exp) => exp.partner === userInfo._id)
                  : finalGrades === null
                  ? listings
                  : listings.filter((exp) => exp.grade.includes(finalGrades))
              }
              users={users}
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            />
            <ListingShowcase
              listings={
                userInfo.type === "teacher"
                  ? listings.filter((exp) => exp.grade.includes(userInfo.grade))
                  : userInfo.type === "partner"
                  ? listings.filter((exp) => exp.partner === userInfo._id)
                  : finalGrades === null
                  ? listings
                  : listings.filter((exp) => exp.grade.includes(finalGrades))
              }
              users={users}
            />
          </>
        ) : (
          <div className="container">
            <span className="no-messages">No listings available</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CatalogueScreen;
