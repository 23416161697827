import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMailDetails } from "../actions/contactMailActions";

import Loader from "../components/Loader";
import Message from "../components/Message";

const MailScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const contactMailDetails = useSelector((state) => state.contactMailDetails);
  const { loading, error, contactMail } = contactMailDetails;

  useEffect(() => {
    if (!userInfo || (userInfo && userInfo.type !== "admin")) {
      history.push("/");
    } else {
      if (
        match.params.id &&
        match.params.id?.length &&
        match.url.split("/")[1] &&
        match.url.split("/")[1]?.length
      ) {
        dispatch(listMailDetails(match.params.id));
      }
    }
  }, [userInfo, history, match, dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        contactMail && (
          <div
            className="landing-generic"
            style={{ backgroundImage: "url(/images/landing-generic.png)" }}
          >
            <div className="container">
              <div className="mail-content">
                <h5>
                  <strong>subject: </strong>
                  {contactMail.subject}
                </h5>
                <p style={{ marginTop: "30px" }}>
                  <strong>from: </strong>
                  {contactMail.lastName}, {contactMail.firstName} (
                  {contactMail.email})
                </p>
                {contactMail.phone && contactMail.phone?.length && (
                  <p>
                    <strong>phone: </strong>
                    {contactMail.phone}
                  </p>
                )}
                <p>
                  <strong>location: </strong>
                  {contactMail.city}, {contactMail.province}
                </p>
                <p style={{ marginTop: "60px" }}>{contactMail.message}</p>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default MailScreen;
