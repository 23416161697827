import axios from "axios";

import {
  ADMIN_ADD_USER_REQUEST,
  ADMIN_ADD_USER_SUCCESS,
  ADMIN_ADD_USER_FAIL,
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_DELETE_USER_SUCCESS,
  ADMIN_DELETE_USER_FAIL,
  ADMIN_ADD_EXPERIENCE_REQUEST,
  ADMIN_ADD_EXPERIENCE_SUCCESS,
  ADMIN_ADD_EXPERIENCE_FAIL,
  ADMIN_EDIT_EXPERIENCE_REQUEST,
  ADMIN_EDIT_EXPERIENCE_SUCCESS,
  ADMIN_EDIT_EXPERIENCE_FAIL,
  ADMIN_EXPERIENCE_VALIDATE_REQUEST,
  ADMIN_EXPERIENCE_VALIDATE_SUCCESS,
  ADMIN_EXPERIENCE_VALIDATE_FAIL,
} from "../constants/adminConstants";

// admin add user
export const adminAddUser = (user, type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_ADD_USER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/${type}s`, { user }, config);

    dispatch({
      type: ADMIN_ADD_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// admin delete user
export const adminDeleteUser = (type, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DELETE_USER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/${type}s/${id}`, config);

    dispatch({
      type: ADMIN_DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// admin add experience
export const addExperience =
  (experience, userType) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_ADD_EXPERIENCE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      if (userType === "admin") {
        const { data } = await axios.post(
          `/api/experiences/admin`,
          { experience },
          config,
        );

        dispatch({
          type: ADMIN_ADD_EXPERIENCE_SUCCESS,
          payload: data,
        });
      } else if (userType === "partner") {
        const { data } = await axios.post(
          `/api/experiences`,
          { experience },
          config,
        );

        dispatch({
          type: ADMIN_ADD_EXPERIENCE_SUCCESS,
          payload: data,
        });
      } else {
        throw new Error("Not authorized!");
      }
    } catch (error) {
      dispatch({
        type: ADMIN_ADD_EXPERIENCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// admin add experience
export const activateExperience = (experience, stamp) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_EXPERIENCE_REQUEST,
    });

    const { data } = await axios.post(`/api/experiences/activate`, {
      experience,
      stamp,
    });

    dispatch({
      type: ADMIN_ADD_EXPERIENCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_EXPERIENCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// admin edit experience
export const editExperience =
  (experience, experienceId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_EDIT_EXPERIENCE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/experiences/${experienceId}`,
        { experience },
        config,
      );

      dispatch({
        type: ADMIN_EDIT_EXPERIENCE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_EDIT_EXPERIENCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// admin approves/declines experience creation request from partner
export const validateExperience = (validatedExperience) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_EXPERIENCE_VALIDATE_REQUEST,
    });

    const { data } = await axios.post(`/api/experiences/performValidation`, {
      validatedExperience,
    });

    dispatch({
      type: ADMIN_EXPERIENCE_VALIDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_EXPERIENCE_VALIDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
