import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAddUser } from "../actions/adminActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AddReviewerScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const adminUserAdd = useSelector((state) => state.adminUserAdd);
  const { loading, error, user } = adminUserAdd;

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [password, setPassword] = useState(``);
  const [confirmedPassword, setConfirmedPassword] = useState(``);
  const [passwordStatus, setPasswordStatus] = useState(`password`);
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] =
    useState(`password`);
  const [passwordIconStatus, setPasswordIconStatus] = useState(`show`);
  const [confirmedPasswordIconStatus, setConfirmedPasswordIconStatus] =
    useState(`show`);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const passwordStatusHandler = (e) => {
    e.preventDefault();
    if (passwordStatus === "text") {
      setPasswordStatus("password");
    } else {
      setPasswordStatus("text");
    }

    if (passwordIconStatus === "show") {
      setPasswordIconStatus("hide");
    } else {
      setPasswordIconStatus("show");
    }
  };

  const confirmedPasswordStatusHandler = (e) => {
    e.preventDefault();
    if (confirmedPasswordStatus === "text") {
      setConfirmedPasswordStatus("password");
    } else {
      setConfirmedPasswordStatus("text");
    }

    if (confirmedPasswordIconStatus === "show") {
      setConfirmedPasswordIconStatus("hide");
    } else {
      setConfirmedPasswordIconStatus("show");
    }
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmedPassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name?.length &&
      email?.length &&
      password?.length &&
      confirmedPassword?.length &&
      password === confirmedPassword
    ) {
      const partner = {
        name,
        email,
        password,
      };

      dispatch(adminAddUser(partner, "reviewer"));
    }
  };

  useEffect(() => {
    if (!userInfo || userInfo.type !== "admin") {
      history.push("/account");
    }

    if (user) {
      resetFields();
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [userInfo, history, user]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {error && <Message body={error} />}
      {user && (
        <Message
          body={`${user.type}: ${user.head.name} is created successfully. Please wait until page reloads`}
          type="info"
        />
      )}
      <div className="container" style={{ paddingTop: "40px" }}>
        <h3>Add A Reviewer</h3>
        <form style={{ paddingTop: "20px" }} onSubmit={submitHandler}>
          <input
            type="text"
            placeholder="name *"
            onChange={nameHandler}
            value={name}
            required
          />
          <input
            type="text"
            placeholder="email *"
            onChange={emailHandler}
            value={email}
            required
          />
          <div style={{ position: "relative" }}>
            <input
              type={passwordStatus}
              placeholder="password *"
              onChange={passwordHandler}
              value={password}
              required
            />
            <button
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={passwordStatusHandler}
            >
              <img
                alt=""
                src={`/images/vrc-icons-beige-passowrd-${passwordIconStatus}.png`}
                style={{ width: "30px" }}
              />
            </button>
          </div>
          <div style={{ position: "relative" }}>
            <input
              type={confirmedPasswordStatus}
              placeholder="confirm password *"
              onChange={confirmedPasswordHandler}
              value={confirmedPassword}
              required
            />
            <button
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={confirmedPasswordStatusHandler}
            >
              <img
                alt=""
                src={`/images/vrc-icons-beige-passowrd-${confirmedPasswordIconStatus}.png`}
                style={{ width: "30px" }}
              />
            </button>
          </div>
          <button className="btn" type="submit" onSubmit={submitHandler}>
            add reviewer
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddReviewerScreen;
