import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";

import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";

import SearchScreen from "./screens/SearchScreen";
import NotFoundScreen from "./screens/NotFoundScreen";

import HomeScreen from "./screens/HomeScreen";
import ThePlatformScreen from "./screens/ThePlatformScreen";
import MediaLibraryScreen from "./screens/MediaLibraryScreen";
import OurNetworkScreen from "./screens/OurNetworkScreen";
import ForTeachersScreen from "./screens/ForTeachersScreen";
import ForPartnersScreen from "./screens/ForPartnersScreen";
import CatalogueScreen from "./screens/CatalogueScreen";
import ContactScreen from "./screens/ContactScreen";

import AccountScreen from "./screens/AccountScreen";
import LostPasswordScreen from "./screens/LostPasswordScreen";
import MyBookingsScreen from "./screens/MyBookingsScreen";

import TokenLoginScreen from "./screens/TokenLoginScreen";
import ActivationScreen from "./screens/ActivationScreen";

import AdminScreen from "./screens/AdminScreen";
import AdminProfileScreen from "./screens/AdminProfileScreen";
import AddReviewerScreen from "./screens/AddReviewerScreen";
import AddPartnerScreen from "./screens/AddPartnerScreen";
import AddExperienceScreen from "./screens/AddExperienceScreen";
import AddTeacherScreen from "./screens/AddTeacherScreen";
import CreateNewsletterScreen from "./screens/CreateNewsletterScreen";
import AllPartnersScreen from "./screens/AllPartnersScreen";
import AllExperiencesScreen from "./screens/AllExperiencesScreen";
import AllTeachersScreen from "./screens/AllTeachersScreen";
import AllReviewersScreen from "./screens/AllReviewersScreen";
import AllMailsScreen from "./screens/AllMailsScreen";
import AllBookingsScreen from "./screens/AllBookingsScreen";

import ReviewerScreen from "./screens/ReviewerScreen";
import PartnerScreen from "./screens/PartnerScreen";
import PartnerEditScreen from "./screens/PartnerEditScreen";
import TeacherScreen from "./screens/TeacherScreen";
import TeacherEditScreen from "./screens/TeacherEditScreen";

import HostScreen from "./screens/HostScreen";

import ExperienceScreen from "./screens/ExperienceScreen";
import ExperienceEditScreen from "./screens/ExperienceEditScreen";
import ExperienceValidateScreen from "./screens/ExperienceValidateScreen";
import BookingScreen from "./screens/BookingScreen";
import MessagesScreen from "./screens/MessagesScreen";

import PartnerPolicyScreen from "./screens/PartnerPolicyScreen";
import PartnerRevenueShareScreen from "./screens/PartnerRevenueShareScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import MailScreen from "./screens/MailScreen";
import AllHostsScreen from "./screens/AllHostsScreen";
import Offer from "./components/Offer";

const App = () => {
  useEffect(() => {
    if (!window.location.host.includes("localhost")) {
      if (!window.location.protocol.includes("s")) {
        window.location.replace(
          `https://${window.location.host}${
            window.location.pathname.length > 1
              ? `/${window.location.pathname}`
              : ""
          }`,
        );
      }
    }

    TimeAgo.addDefaultLocale(en);
    TimeAgo.addLocale(ru);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Offer />
      <Switch>
        <Route path="/" component={HomeScreen} exact />
        <Route path="/search" component={SearchScreen} exact />
        <Route path="/the-platform" component={ThePlatformScreen} exact />
        <Route path="/media-library" component={MediaLibraryScreen} exact />
        <Route path="/our-network" component={OurNetworkScreen} exact />
        <Route path="/for-teachers" component={ForTeachersScreen} exact />
        <Route path="/for-partners" component={ForPartnersScreen} exact />
        <Route path="/catalogue" component={CatalogueScreen} exact />
        <Route path="/contact" component={ContactScreen} exact />
        <Route path="/partner-policy" component={PartnerPolicyScreen} exact />
        <Route
          path="/revenue-share"
          component={PartnerRevenueShareScreen}
          exact
        />
        <Route path="/privacy-policy" component={PrivacyPolicyScreen} exact />
        <Route path="/admin" component={AdminScreen} exact />
        <Route path="/admin/all-partners" component={AllPartnersScreen} exact />
        <Route
          path="/admin/all-experiences"
          component={AllExperiencesScreen}
          exact
        />
        <Route path="/admin/all-teachers" component={AllTeachersScreen} exact />
        <Route
          path="/admin/all-reviewers"
          component={AllReviewersScreen}
          exact
        />
        <Route path="/admin/all-mails" component={AllMailsScreen} exact />
        <Route path="/admin/all-bookings" component={AllBookingsScreen} exact />
        <Route path="/admin/all-hosts" component={AllHostsScreen} exact />
        <Route path="/account" component={AccountScreen} exact />
        <Route path="/lost-password" component={LostPasswordScreen} exact />
        <Route path="/admin/profile/:id" component={AdminProfileScreen} exact />
        <Route path="/reviewer/:id" component={ReviewerScreen} exact />
        <Route path="/partner/:id" component={PartnerScreen} exact />
        <Route path="/partner/:id/edit" component={PartnerEditScreen} exact />
        <Route path="/host" component={HostScreen} exact />
        <Route path="/teacher/:id" component={TeacherScreen} exact />
        <Route path="/teacher/:id/edit" component={TeacherEditScreen} exact />
        <Route path="/mail/:id" component={MailScreen} exact />
        <Route path="/experience/:id" component={ExperienceScreen} exact />
        <Route
          path="/experience/:id/edit"
          component={ExperienceEditScreen}
          exact
        />
        <Route
          path="/experiencevalidate/:token"
          component={ExperienceValidateScreen}
          exact
        />
        <Route
          path="/account/partner/:token"
          component={TokenLoginScreen}
          exact
        />
        <Route
          path="/account/teacher/:token"
          component={TokenLoginScreen}
          exact
        />
        <Route
          path="/partner/activate/:token"
          component={ActivationScreen}
          exact
        />
        <Route
          path="/teacher/activate/:token"
          component={ActivationScreen}
          exact
        />
        <Route path="/mybookings" component={MyBookingsScreen} exact />
        <Route path="/booking/:id" component={BookingScreen} exact />
        <Route path="/add-reviewer" component={AddReviewerScreen} exact />
        <Route path="/add-partner" component={AddPartnerScreen} exact />
        <Route path="/add-teacher" component={AddTeacherScreen} exact />
        <Route path="/add-experience" component={AddExperienceScreen} exact />
        <Route
          path="/new-newsletter"
          component={CreateNewsletterScreen}
          exact
        />
        <Route path="/messages" component={MessagesScreen} exact />

        <Route component={NotFoundScreen} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
