import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAddUser } from "../actions/adminActions";
import { profileCategories, profileTags } from "../data/data";
import Loader from "../components/Loader";
import Message from "../components/Message";
import cities from "../data/cities";
import countries from "../data/countries";

const AddPartnerScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const adminUserAdd = useSelector((state) => state.adminUserAdd);
  const { loading, error, user } = adminUserAdd;

  const [name, setName] = useState(``);
  const [bio, setBio] = useState(``);
  const [email, setEmail] = useState(``);
  const [avatar, setAvatar] = useState(``);
  const [banner, setBanner] = useState(``);
  const [phone, setPhone] = useState(``);
  const [password, setPassword] = useState(``);
  const [confirmedPassword, setConfirmedPassword] = useState(``);
  const [passwordStatus, setPasswordStatus] = useState(`password`);
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] =
    useState(`password`);
  const [passwordIconStatus, setPasswordIconStatus] = useState(`show`);
  const [confirmedPasswordIconStatus, setConfirmedPasswordIconStatus] =
    useState(`show`);
  const [category, setCategory] = useState(``);
  const [checkedTags, setCheckedTags] = useState(
    new Array(profileTags.length).fill(false),
  );
  const [selectedTags, setSelectedTags] = useState([]);

  const handleOnChangeTags = (positionTags) => {
    const updatedCheckedTags = checkedTags.map((itemTags, indexTags) =>
      indexTags === positionTags ? !itemTags : itemTags,
    );

    setCheckedTags(updatedCheckedTags);
  };

  useEffect(() => {
    setSelectedTags(
      profileTags.filter(
        (ele) => checkedTags[profileTags.indexOf(ele)] === true,
      ),
    );
  }, [checkedTags]);

  const [landAcknowledgment, setLandAcknowledgment] = useState(``);
  const [about, setAbout] = useState(``);
  const [values, setValues] = useState(``);
  const [website, setWebsite] = useState(``);
  const [city, setCity] = useState(``);
  const [country, setCountry] = useState(``);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const bioHandler = (e) => {
    e.preventDefault();
    setBio(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const avatarHandler = (e) => {
    e.preventDefault();
    setAvatar(e.target.value);
  };

  const bannerHandler = (e) => {
    e.preventDefault();
    setBanner(e.target.value);
  };

  const phoneHandler = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const passwordStatusHandler = (e) => {
    e.preventDefault();
    if (passwordStatus === "text") {
      setPasswordStatus("password");
    } else {
      setPasswordStatus("text");
    }

    if (passwordIconStatus === "show") {
      setPasswordIconStatus("hide");
    } else {
      setPasswordIconStatus("show");
    }
  };

  const confirmedPasswordStatusHandler = (e) => {
    e.preventDefault();
    if (confirmedPasswordStatus === "text") {
      setConfirmedPasswordStatus("password");
    } else {
      setConfirmedPasswordStatus("text");
    }

    if (confirmedPasswordIconStatus === "show") {
      setConfirmedPasswordIconStatus("hide");
    } else {
      setConfirmedPasswordIconStatus("show");
    }
  };

  const categoryHandler = (e) => {
    e.preventDefault();
    setCategory(e.target.value);
  };

  const landAcknowledgmentHandler = (e) => {
    e.preventDefault();
    setLandAcknowledgment(e.target.value);
  };

  const websiteHandler = (e) => {
    e.preventDefault();
    setWebsite(e.target.value);
  };

  const aboutHandler = (e) => {
    e.preventDefault();
    setAbout(e.target.value);
  };

  const valuesHandler = (e) => {
    e.preventDefault();
    setValues(e.target.value);
  };

  const cityHandler = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const countryHandler = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  const resetFields = () => {
    setName("");
    setBio("");
    setEmail("");
    setAvatar("");
    setBanner("");
    setPhone("");
    setPassword("");
    setConfirmedPassword("");
    setCategory("");
    setLandAcknowledgment("");
    setAbout("");
    setValues("");
    setWebsite("");
    setCity("");
    setCountry("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name?.length &&
      bio?.length &&
      email?.length &&
      phone?.length &&
      password?.length &&
      confirmedPassword?.length &&
      password === confirmedPassword &&
      category !== "hide" &&
      selectedTags?.length &&
      landAcknowledgment?.length &&
      website?.length &&
      city?.length &&
      country?.length
    ) {
      const partner = {
        name,
        email,
        bio,
        phone,
        password,
        category,
        tags: selectedTags,
        landAcknowledgment,
        website,
        city,
        country,
      };

      if (avatar?.length) {
        partner.avatar = avatar;
      }

      if (banner?.length) {
        partner.banner = banner;
      }

      if (about?.length) {
        partner.about = about
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length);
      }

      if (values?.length) {
        partner.values = values
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length);
      }

      dispatch(adminAddUser(partner, "partner"));
    }
  };

  useEffect(() => {
    if (!userInfo || userInfo.type !== "admin") {
      history.push("/account");
    }

    if (user) {
      resetFields();
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [userInfo, history, user]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {error && <Message body={error} />}
      {user && (
        <Message
          body={`${user.type}: ${user.head.name} is created successfully. Please wait until page reloads`}
          type="info"
        />
      )}
      <div className="container" style={{ paddingTop: "40px" }}>
        <h3>Add A Partner</h3>
        <form style={{ paddingTop: "20px" }} onSubmit={submitHandler}>
          <input
            type="text"
            placeholder="name *"
            onChange={nameHandler}
            value={name}
            required
          />
          <input
            type="text"
            placeholder="email *"
            onChange={emailHandler}
            value={email}
            required
          />
          <textarea onChange={bioHandler} placeholder="bio *" value={bio} />
          <input
            type="text"
            placeholder="avatar url (optional)"
            onChange={avatarHandler}
            value={avatar}
          />
          <input
            type="text"
            placeholder="banner url (optional)"
            onChange={bannerHandler}
            value={banner}
          />
          <input
            type="number"
            placeholder="phone *"
            onChange={phoneHandler}
            value={phone}
            required
          />
          <div style={{ position: "relative" }}>
            <input
              type={passwordStatus}
              placeholder="password *"
              onChange={passwordHandler}
              value={password}
              required
            />
            <button
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={passwordStatusHandler}
            >
              <img
                alt=""
                src={`/images/vrc-icons-beige-passowrd-${passwordIconStatus}.png`}
                style={{ width: "30px" }}
              />
            </button>
          </div>
          <div style={{ position: "relative" }}>
            <input
              type={confirmedPasswordStatus}
              placeholder="confirm password *"
              onChange={confirmedPasswordHandler}
              value={confirmedPassword}
              required
            />
            <button
              style={{
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={confirmedPasswordStatusHandler}
            >
              <img
                alt=""
                src={`/images/vrc-icons-beige-passowrd-${confirmedPasswordIconStatus}.png`}
                style={{ width: "30px" }}
              />
            </button>
          </div>
          <div className="select">
            <select onChange={categoryHandler}>
              <option value="hide">select category *</option>
              {profileCategories.map((ele, index) => (
                <option key={index} value={ele}>
                  {ele}
                </option>
              ))}
            </select>
          </div>

          <div className="profile-tags-container">
            <div>
              <p style={{ fontWeight: "400" }}>select tags *</p>
              <p style={{ fontWeight: "200", opacity: "50%" }}>
                You have to select at least 1 tag from the following list:
              </p>
            </div>
            <div className="profile-tags">
              {profileTags.map((ele, indexCores) => (
                <div key={indexCores}>
                  <input
                    type="checkbox"
                    id={indexCores + 100}
                    name={indexCores}
                    checked={checkedTags[indexCores]}
                    onChange={() => handleOnChangeTags(indexCores)}
                  />
                  <label htmlFor={indexCores + 100}>
                    <p style={{ fontWeight: "400" }}>{ele}</p>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <input
            type="text"
            placeholder="land acknowledgment *"
            onChange={landAcknowledgmentHandler}
            value={landAcknowledgment}
          />
          <input
            type="text"
            placeholder="website *"
            onChange={websiteHandler}
            value={website}
            required
          />
          <textarea
            onChange={aboutHandler}
            placeholder="about *"
            value={about}
          />
          <textarea
            onChange={valuesHandler}
            placeholder="values *"
            value={values}
          />
          <div className="select">
            <select onChange={countryHandler} defaultValue={country}>
              <option value="hide">select country *</option>
              {countries.map((ele, index) => (
                <option key={index} value={ele.name}>
                  {ele.name}
                </option>
              ))}
            </select>
          </div>
          {country === "Canada" ? (
            <div className="select">
              <select onChange={cityHandler} defaultValue={city}>
                <option value="hide">select city *</option>
                {cities
                  .map((cityArray) => cityArray[0])
                  .map((ele, index) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            </div>
          ) : (
            <input
              type="text"
              placeholder="city *"
              onChange={cityHandler}
              value={city}
              required
            />
          )}
          <button className="btn" type="submit" onSubmit={submitHandler}>
            add partner
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddPartnerScreen;
