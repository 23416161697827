import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { adminDeleteUser } from "../actions/adminActions";
import { listUsers } from "../actions/userActions";
import { ADMIN_DELETE_USER_RESET } from "../constants/adminConstants";

import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AllReviewersScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const adminUserDelete = useSelector((state) => state.adminUserDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success,
  } = adminUserDelete;

  useEffect(() => {
    if (!userInfo || (userInfo && userInfo.type !== "admin")) {
      history.push("/");
    } else {
      // implement
      dispatch(listUsers("reviewer"));
    }
  }, [dispatch, history, userInfo]);

  const deleteUserHandler = (e) => {
    e.preventDefault();
    // implement
    dispatch(adminDeleteUser("reviewer", e.target.id));
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: ADMIN_DELETE_USER_RESET });
        dispatch(listUsers("reviewer"));
      }, 3000);
    }
  }, [success, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        {loadingDelete && <Loader />}
        {errorDelete && <Message body={errorDelete} />}
        {success && (
          <Message body="Reviewer deleted successfully!" type="info" />
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : users && users?.length ? (
          <>
            <h5 style={{ marginBottom: "20px" }}>
              all reviewers ({users.length})
            </h5>
            <div className="user-list">
              {users
                .sort(function (a, b) {
                  if (a.head.name.toLowerCase() < b.head.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.head.name.toLowerCase() > b.head.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                .map((user) => (
                  <div style={{ display: "flex", gap: "10px" }} key={user._id}>
                    <Link
                      to={`/${user.type}/${user._id}`}
                      className="btn"
                      style={{ flexGrow: "1" }}
                    >
                      <span>{user._id}</span>
                      <span>{user.head.name}</span>
                      <span>{user.category}</span>
                    </Link>
                    <span
                      className="btn"
                      style={{ width: "fit-content" }}
                      onClick={deleteUserHandler}
                      id={user._id}
                    >
                      x
                    </span>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <p className="no-messages">No reviewers available</p>
        )}
      </div>
    </div>
  );
};

export default AllReviewersScreen;
