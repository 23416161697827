import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { activateAccount } from "../actions/userActions";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_ACTIVATE_RESET } from "../constants/userConstants";

const ActivationScreen = ({ location, history }) => {
  const dispatch = useDispatch();

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const userActivate = useSelector((state) => state.userActivate);
  const { loading, error, userInfo: userActivated } = userActivate;

  const [token, setToken] = useState(``);
  const [type, setType] = useState(``);

  useEffect(() => {
    setType(location.pathname.split("/")[1].toString());
    setToken(location.pathname.split("/")[3].toString());
  }, [location.pathname]);

  useEffect(() => {
    if (type && token) {
      if (type?.length && token?.length) {
        dispatch(activateAccount(type, token));
      }
    }
  }, [type, token, dispatch]);

  useEffect(() => {
    if (error || userActivated) {
      setTimeout(() => {
        dispatch({ type: USER_ACTIVATE_RESET });
        history.push("/");
      }, 5000);
    }
  }, [error, userActivated, dispatch, history]);

  return (
    <div
      className="activation landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        <div className="activation-content">
          {loading && <Loader />}
          {error && <Message body={error} />}
          {userActivated && (
            <Message body={userActivated.message} type="info" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivationScreen;
