import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PartnerRevenueShareScreen = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (
      !userInfo ||
      (userInfo.type !== "partner" && userInfo.type !== "admin")
    ) {
      history.push("/");
    }
  }, [history, userInfo]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        <div className="policies">
          <div>
            <h3>Partner Revenue Share</h3>
            <i>This Policy was last updated 08 April 2022.</i>
          </div>
          <p>
            There is no fee to become a partner (“Partner”) of Classroom Voyager, nor is there a fee to list field trips and presentations
            (“Experiences”). Our team works with our Partners to ensure they
            maximize their offerings to reach as many school groups as possible
            by aligning with provincial and territorial curriculums. We believe
            in creating a sustainable partnership program that benefits
            institutions of all sizes.
          </p>
          <ul>
            <li>
              Partner Revenue Share Overview
              <p>
                A basic breakdown of the Classroom Voyager’s revenue share
                program is provided below. Partner revenue share is calculated
                on Net Revenue, which can be read in our{" "}
                <Link to="/partner-policy">Partner Policy</Link>. Partners will
                also find more detailed information on Forager Education Ltd.’s
                relationship with our Partners there.
              </p>
              <ul>
                <li>
                  Experience Bookings
                  <p>
                    Each time a teacher books a Partner Experience, Forager
                    Education Ltd. takes a 15% booking fee to support Classroom Voyager platform. If a Partner chooses to host free
                    Experiences on the Classroom Voyager, a fee of $0.75 per
                    student (in-person) or $12.75 per class (virtual) will be
                    charged in place of the booking fee.
                  </p>
                </li>
                <li>
                  Virtual Resources License Fees
                  <p>
                    With the launch of the database of teaching resources, 70%
                    of license fee revenue is distributed to Partners depending
                    upon the breakdown of Partner resource views compared to the
                    total resource views from each grade. The 70/30 split is
                    calculated after the payment processing fee and 10% sales
                    commission paid to the BC Museums Association is subtracted.{" "}
                  </p>
                </li>
              </ul>
            </li>
            <li>
              Reporting
              <p>
                Partners will receive a monthly Revenue Report but can view
                their sales and views through their Partner account. Reports
                will show Partners the dates of each purchase, the location of
                customers, and the total price paid.
              </p>
            </li>
            <li>
              Modifications
              <p>
                On occasion, we may update this Policy to further define our
                promotional program. Forager Education Ltd. reserves the right
                to modify or make changes to this policy at any time. When
                changes to the Policy are made, Partners will be notified by the
                email that is associated with their account. Changes will take
                effect on the day the Policy is posted.
              </p>
              <p>
                Continued use of the Classroom Voyager’s marketplace after
                changes are posted shall mean each Partner’s acceptance of the
                modifications. The revised Policy shall supersede all previous
                editions of the Policy.
              </p>
            </li>
            <li>
              How to Contact Us
              <p>
                The best way to contact the Classroom Voyager is through your
                Partner Manager. If you are not sure who your dedicated Partner
                Manager is, use partners.ca@foragereducation.com and an agent
                will be in touch. We are always looking for feedback on our
                services and would love to hear from you.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PartnerRevenueShareScreen;
