import React from "react";

import Rating from "./Rating";

const Review = ({ review }) => {
  return (
    <>
      <div className="review">
        <h4 className="review-username">
          {review.name && review.name?.length
            ? review.name
            : `user id: ${review.teacher}`}
        </h4>
        <span className="review-title">{review.title}</span>
        <Rating value={review.rating} color="grey" />
        <p className="review-body">{review.body}</p>
      </div>
      <span className="testimonail-divider"></span>
    </>
  );
};

export default Review;
