import React from "react";

const SetRating = ({ rating, setRating }) => {
  const starArray = new Array(5).fill(false);

  const ratingHandler = (e) => {
    e.preventDefault();
    setRating(Number(e.target.id));
  };

  return (
    <div className="set-rating">
      {starArray.map((ele, index) => (
        <button
          style={{ background: "none", border: "none", padding: "0" }}
          id={index + 1}
          key={index}
          onClick={ratingHandler}
          className="rating-star"
        >
          <img
            className="rating-star"
            alt=""
            src={
              rating >= index + 1
                ? "/images/vrc-icons-grey-star-full.png"
                : "/images/vrc-icons-grey-star-empty.png"
            }
          />
        </button>
      ))}
    </div>
  );
};

export default SetRating;
