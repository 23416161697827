export const coreCompetencies = [
  "Communicating",
  "Collaborating",
  "Creative Thinking",
  "Critical & Reflective Thinking",
  "Personal Awareness & Responsibility",
  "Positive Personal & Cultural Identity",
  "Social Awareness & Responsibility",
];

export const coreCompetenciesDescriptions = [
  {
    heading: coreCompetencies[0],
    copy: [
      "Communicating encompasses the set of abilities that people use to impart and exchange information, experiences, and ideas; to explore the world around them; and to understand and effectively use communication forms, strategies, and technologies. Communicating provides a bridge between peoples’ learning, their personal and social identity, and the world in which they interact.",
      "People who communicate effectively use their skills and strategies intentionally to ensure understanding their audience. They communicate in an increasing variety of contexts, for a variety of purposes, and often with multiple audiences.",
    ],
  },
  {
    heading: coreCompetencies[1],
    copy: [
      "Collaborating involves the skills, strategies, and dispositions that people use to work together to pursue common purposes and accomplish common goals.",
      "People who collaborate effectively recognize how combining others’ perspectives, strategies, and efforts with their own enhances collective understanding, use, and impact. They value the contributions of group members, interact supportively and effectively using inclusive practices, and strive for shared commitment and mutual benefit.",
    ],
  },
  {
    heading: coreCompetencies[2],
    copy: [
      "Creative Thinking involves the generation of ideas and concepts that are novel and innovative in the context in which they are generated, reflection on their value to the individual or others, and the development of chosen ideas and concepts from thought to reality.",
      "People who think creatively are curious and open-minded, have a sense of wonder and joy in learning, demonstrate a willingness to think divergently, and are comfortable with complexity. A creative thinker reflects on existing ideas and concepts; uses imagination, inventiveness, resourcefulness, and flexibility; and is willing to take risks to go beyond existing knowledge.",
    ],
  },
  {
    heading: coreCompetencies[3],
    copy: [
      "Critical and Reflective Thinking encompasses a set of abilities that students use to examine their own thinking and that of others. This involves making judgments based on reasoning, where students consider options, analyze options using specific criteria, and draw conclusions.",
      "People who think critically and reflectively are analytical and investigative, willing to question and challenge their own thoughts, ideas, and assumptions and challenge those of others. They reflect on the information they receive through observation, experience, and other forms of communication to solve problems, design products, understand events, and address issues. A critical thinker uses their ideas, experiences, and reflections to set goals, make judgments, and refine their thinking.",
    ],
  },
  {
    heading: coreCompetencies[4],
    copy: [
      "Personal Awareness and Responsibility involves understanding the connections between personal and social behaviour and well-being; it encourages people to make constructive and ethical decisions and act on them.",
      "People who are personally aware and responsible demonstrate self-respect, persevere in difficult situations, and exercise responsibility. They understand that there are consequences for their decisions and actions. A personally aware and responsible individual takes steps to ensure their well-being, sets goals and monitors progress, regulates emotions and manages stress, and recognizes and advocates for their own rights.",
    ],
  },
  {
    heading: coreCompetencies[5],
    copy: [
      "Positive Personal and Cultural Identity involves the awareness, understanding, and appreciation of the factors that contribute to a healthy sense of oneself; it includes knowledge of one’s family background, heritage(s), language(s), beliefs, and perspectives in a pluralistic society.",
      "People who have a positive personal and cultural identity value their personal and cultural narratives and understand how these shape their identity. They exhibit a sense of self-worth, self-awareness, and positive identity to become confident individuals who take satisfaction in who they are and what they can do. They contribute to their own well-being and to the well-being of their family, community, and society.",
    ],
  },
  {
    heading: coreCompetencies[6],
    copy: [
      "Social Awareness and Responsibility involves the awareness, understanding, and appreciation of connections among people, including between people and the natural environment. Social Awareness and Responsibility focuses on interacting with others and the natural world in respectful and caring ways.",
      "People who are socially aware and responsible contribute to the well-being of their social and physical environments. They support the development of welcoming and inclusive communities, where people feel safe and have a sense of belonging. A socially aware and responsible individual contributes positively to their family, community, and environment; empathizes with others and appreciates their perspectives; resolves problems peacefully; and develops and sustains healthy relationships.",
    ],
  },
];
