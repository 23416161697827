import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGIN_RESET } from "../constants/userConstants";

import Loader from "../components/Loader";
import Message from "../components/Message";
import AdminDashboard from "../components/AdminDashboard";
import AdminAccountForm from "../components/AdminAccountForm";

const AdminScreen = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
      }, 5000);
    }
  }, [error, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div style={{ paddingTop: "0", position: "absolute", top: "10%" }}>
        {loading && <Loader />}
        {error && <Message body={error} />}
      </div>
      <div className="container">
        {loading && <Loader />}
        {error && <Message body={error} />}
        {userInfo ? (
          <>
            <AdminDashboard history={history} />
          </>
        ) : (
          <AdminAccountForm />
        )}
      </div>
    </div>
  );
};

export default AdminScreen;
