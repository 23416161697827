import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import Message from "../components/Message";

import { listUserDetails, updateUser } from "../actions/userActions";
import { Link } from "react-router-dom";
import { USER_UPDATE_RESET } from "../constants/userConstants";
import cities from "../data/cities";
import countries from "../data/countries";
import { profileTags } from "../data/data";

const PartnerEditScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success } = userUpdate;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [name, setName] = useState(``);
  const [bio, setBio] = useState(``);
  const [phone, setPhone] = useState(``);
  const [tempTags, setTempTags] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleOnChangeTags = (positionTags) => {
    const updatedCheckedTags = checkedTags.map((itemTags, indexTags) =>
      indexTags === positionTags ? !itemTags : itemTags,
    );

    setCheckedTags(updatedCheckedTags);
  };

  useEffect(() => {
    setSelectedTags(
      profileTags.filter(
        (ele) => checkedTags[profileTags.indexOf(ele)] === true,
      ),
    );
  }, [checkedTags]);

  useEffect(() => {
    setCheckedTags(
      new Array(profileTags.length)
        .fill(false)
        .map((ele, index) => tempTags.includes(profileTags[index])),
    );
    // eslint-disable-next-line
  }, [tempTags]);

  const [landAcknowledgment, setLandAcknowledgment] = useState(``);
  const [about, setAbout] = useState(``);
  const [values, setValues] = useState(``);
  const [website, setWebsite] = useState(``);
  const [city, setCity] = useState(``);
  const [country, setCountry] = useState(``);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const bioHandler = (e) => {
    e.preventDefault();
    setBio(e.target.value);
  };

  const phoneHandler = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  const landAcknowledgmentHandler = (e) => {
    e.preventDefault();
    setLandAcknowledgment(e.target.value);
  };

  const websiteHandler = (e) => {
    e.preventDefault();
    setWebsite(e.target.value);
  };

  const aboutHandler = (e) => {
    e.preventDefault();
    setAbout(e.target.value);
  };

  const valuesHandler = (e) => {
    e.preventDefault();
    setValues(e.target.value);
  };

  const cityHandler = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const countryHandler = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name?.length &&
      bio?.length &&
      landAcknowledgment?.length &&
      phone?.length &&
      selectedTags?.length &&
      website?.length &&
      city?.length &&
      country?.length
    ) {
      const fields = {
        type: userInfo.type,
        name,
        bio,
        phone,
        landAcknowledgment,
        tags: selectedTags,
        website,
        about: about
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length),
        values: values
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length),
        city,
        country,
      };

      dispatch(updateUser(fields, user._id, userInfo.type));
    }
  };

  useEffect(() => {
    if (!userInfo || userInfo.type !== "partner") {
      history.push("/");
    }

    if (!user) {
      dispatch(
        listUserDetails(match.url.split("/").reverse()[1], userInfo.type),
      );
    }
  }, [userInfo, history, user, dispatch, match]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
        history.push(`/${userInfo.type}/${userInfo._id}`);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [history, success]);

  useEffect(() => {
    if (user) {
      setName(user.head.name);
      setBio(user.bio);
      setPhone(user.phone);
      setLandAcknowledgment(user.landAcknowledgment);
      setAbout(user.about.join("\n\n"));
      setValues(user.values.join("\n\n"));
      setWebsite(user.website);
      setCity(user.city);
      setCountry(user.country);
      setTempTags(user.tags || []);
    }
  }, [user]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {loadingUpdate && <Loader />}
      {error && <Message body={error} />}
      {errorUpdate && <Message body={errorUpdate} />}
      {success && <Message body={"User updated successfully!"} type="info" />}
      {user && userInfo && (
        <div className="container" style={{ paddingTop: "40px" }}>
          <h3>Edit {userInfo.type} Profile Information</h3>
          <form style={{ paddingTop: "20px" }} onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="name *"
              onChange={nameHandler}
              value={name}
              required
            />
            <textarea onChange={bioHandler} placeholder="bio *" value={bio} />
            <input
              type="number"
              placeholder="phone *"
              onChange={phoneHandler}
              value={phone}
              required
            />

            {checkedTags && checkedTags?.length && (
              <div className="profile-tags-container">
                <div>
                  <p style={{ fontWeight: "400" }}>select tags *</p>
                  <p style={{ fontWeight: "200", opacity: "50%" }}>
                    You have to select at least 1 tag from the following list:
                  </p>
                </div>
                <div className="profile-tags">
                  {profileTags.map((ele, indexCores) => (
                    <div key={indexCores}>
                      <input
                        type="checkbox"
                        id={indexCores + 100}
                        name={indexCores}
                        checked={checkedTags[indexCores]}
                        onChange={() => handleOnChangeTags(indexCores)}
                      />
                      <label htmlFor={indexCores + 100}>
                        <p style={{ fontWeight: "400" }}>{ele}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <input
              type="text"
              placeholder="land acknowledgment"
              onChange={landAcknowledgmentHandler}
              value={landAcknowledgment}
            />
            <input
              type="text"
              placeholder="website *"
              onChange={websiteHandler}
              value={website}
              required
            />
            <textarea
              onChange={aboutHandler}
              placeholder="about"
              value={about}
            />
            <textarea
              onChange={valuesHandler}
              placeholder="values"
              value={values}
            />
            <div className="select">
              <select onChange={cityHandler} defaultValue={user.city}>
                <option value="hide">select city *</option>
                {cities
                  .map((cityArray) => cityArray[0])
                  .map((ele, index) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            </div>
            <div className="select">
              <select onChange={countryHandler} defaultValue={user.country}>
                <option value="hide">select country *</option>
                {countries.map((ele, index) => (
                  <option key={index} value={ele.name}>
                    {ele.name}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn" type="submit" onSubmit={submitHandler}>
              submit changes
            </button>
            <Link
              className="btn send-message"
              to={`/${userInfo.type}/${userInfo._id}`}
              style={{
                position: "relative",
                width: "fit-content",
              }}
            >
              back to profile
            </Link>
          </form>
        </div>
      )}
    </div>
  );
};

export default PartnerEditScreen;
