import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Offer = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [state, setState] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      setTimeout(() => {
        setState(true);
      }, 3000);
    }
  }, [userInfo]);

  const closePopup = (e) => {
    e.preventDefault();

    setState(false);
  };

  return state ? (
    <div className="popup">
      <div className="overlay"></div>
      <div className="container">
        <div
          className="popup-content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div className="offer-body">
            <span className="close" onClick={closePopup}>
              <img src="/images/vrc-icons-grey-false.png" alt="offer" />
            </span>
            <h1>Attention, educators!</h1>
            <div>
              <img src="/images/offer.png" alt="offer" />
              <p>
                Grab hold of a <strong>100% OFF</strong> bursary and set course
                for your class's next unforgettable field trip adventure with
                the Classroom Voyager. The possibilities are boundless!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Offer;
