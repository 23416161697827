export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";

export const CART_INCLUDE_ADDON = "CART_INCLUDE_ADDON";
export const CART_EXCLUDE_ADDON = "CART_EXCLUDE_ADDON";

export const CART_SET_DATE = "CART_SET_DATE";
export const CART_SET_TIME = "CART_SET_TIME";

export const CART_ADD_NOTE = "CART_ADD_NOTE";

export const CART_SET_COUNT = "CART_SET_COUNT";

export const CART_RESET = "CART_RESET";
