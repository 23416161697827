import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CONV_CREATE_NEW_RESET,
  MSG_ADD_NEW_RESET,
} from "../constants/connectConstants";

import { USER_PASSWORD_RESET_REQUEST_RESET } from "../constants/userConstants";

import {
  addNewMessage,
  createNewConversation,
} from "../actions/connectActions";
import { listUserDetails, resetUserPassword } from "../actions/userActions";

import Loader from "../components/Loader";
import Message from "../components/Message";

const AdminProfileScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const {
    loading: loadingPwd,
    error: errorPwd,
    updatedUser,
  } = userResetPassword;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createConversation = useSelector((state) => state.createConversation);
  const { conversation } = createConversation;

  const newMessage = useSelector((state) => state.newMessage);
  const { message: nMessage } = newMessage;

  const [messageDisplayWindow, setMessageDisplayWindow] = useState(false);
  const [message, setMessage] = useState(``);

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const addMessage = (e) => {
    e.preventDefault();
    if (message?.length) {
      dispatch(
        createNewConversation(userInfo._id, user._id, userInfo.type, user.type),
      );
    }
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    } else {
      if (
        match.params.id &&
        match.params.id?.length &&
        match.url.split("/")[1] &&
        match.url.split("/")[1]?.length
      ) {
        dispatch(listUserDetails(match.params.id, match.url.split("/")[1]));
      }
    }
  }, [userInfo, history, match, dispatch]);

  useEffect(() => {
    if (conversation && message?.length) {
      dispatch(addNewMessage(conversation._id, userInfo._id, message));
      setMessage(``);
      setMessageDisplayWindow(false);
    }
    // eslint-disable-next-line
  }, [dispatch, conversation]);

  useEffect(() => {}, [message]);

  useEffect(() => {
    if (nMessage) {
      dispatch({ type: CONV_CREATE_NEW_RESET });
      dispatch({ type: MSG_ADD_NEW_RESET });
    }
    // eslint-disable-next-line
  }, [dispatch, nMessage]);

  const displayMessageMenu = (e) => {
    e.preventDefault();
    setMessage(``);
    setMessageDisplayWindow(true);
  };

  const hideMessageMenu = (e) => {
    e.preventDefault();
    setMessageDisplayWindow(false);
    setMessage(``);
  };

  // admin only
  const [passwordDisplayWindow, setPasswordDisplayWindow] = useState(false);

  const [password, setPassword] = useState(``);
  const [confirmedPassword, setConfirmedPassword] = useState(``);
  const [passwordStatus, setPasswordStatus] = useState(`password`);
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] =
    useState(`password`);
  const [passwordIconStatus, setPasswordIconStatus] = useState(`show`);
  const [confirmedPasswordIconStatus, setConfirmedPasswordIconStatus] =
    useState(`show`);

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const passwordStatusHandler = (e) => {
    e.preventDefault();
    if (passwordStatus === "text") {
      setPasswordStatus("password");
    } else {
      setPasswordStatus("text");
    }

    if (passwordIconStatus === "show") {
      setPasswordIconStatus("hide");
    } else {
      setPasswordIconStatus("show");
    }
  };

  const confirmedPasswordStatusHandler = (e) => {
    e.preventDefault();
    if (confirmedPasswordStatus === "text") {
      setConfirmedPasswordStatus("password");
    } else {
      setConfirmedPasswordStatus("text");
    }

    if (confirmedPasswordIconStatus === "show") {
      setConfirmedPasswordIconStatus("hide");
    } else {
      setConfirmedPasswordIconStatus("show");
    }
  };

  const resetPasswordFields = () => {
    setPassword(``);
    setConfirmedPassword(``);
    setPasswordStatus("password");
    setConfirmedPasswordStatus("password");
    setPasswordIconStatus("show");
    setConfirmedPasswordIconStatus("show");
  };

  const resetPassword = () => {
    if (
      password?.length &&
      confirmedPassword?.length &&
      password === confirmedPassword
    ) {
      dispatch(resetUserPassword(user.type, password, user._id));
      resetPasswordFields();
      setPasswordDisplayWindow(false);
    }
  };

  const displayPasswordMenu = (e) => {
    e.preventDefault();
    resetPasswordFields();
    setPasswordDisplayWindow(true);
  };

  const hidePasswordMenu = (e) => {
    e.preventDefault();
    resetPasswordFields();
    setPasswordDisplayWindow(false);
  };

  useEffect(() => {
    if (updatedUser) {
      setTimeout(() => {
        dispatch({ type: USER_PASSWORD_RESET_REQUEST_RESET });
        window.location.reload();
      }, 5000);
    }
  }, [updatedUser, dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message body={error} />
  ) : (
    user && (
      <>
        {loadingPwd && <Loader />}
        {errorPwd && <Message body={errorPwd} />}
        {updatedUser && (
          <Message body="Password reset successfully!" type="info" />
        )}
        <div className="user">
          <div
            className="banner"
            style={{
              backgroundImage: user.head.banner?.length
                ? `url(${user.head.banner}`
                : "url(/images/user-hero.png)",
            }}
          ></div>
          <div className="container">
            <div className="user-profile">
              <div
                style={
                  window.innerWidth < 835
                    ? { left: "50%", transform: "translateX(-50%)" }
                    : {}
                }
                className="user-logo"
              >
                <span
                  className="logo"
                  style={{
                    backgroundImage: `url(${
                      user.head.avatar?.length
                        ? user.head.avatar
                        : "/images/avatar-admin.png"
                    })`,
                  }}
                ></span>
              </div>
              <div className="user-title">
                <h2>{user.head.name}</h2>
                <h3>Platform Administrator | Vancouver, Canada</h3>
              </div>
              {userInfo && userInfo._id !== user._id && (
                <>
                  <div
                    className="popup"
                    style={
                      messageDisplayWindow === true
                        ? {
                            display: "flex",
                          }
                        : messageDisplayWindow === false
                        ? {
                            display: "none",
                          }
                        : ""
                    }
                  >
                    <div className="overlay"></div>
                    <div className="container">
                      <div className="popup-content">
                        <textarea
                          placeholder="write your message here"
                          onChange={messageHandler}
                          value={message}
                        ></textarea>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "50px",
                            gap: "10px",
                          }}
                        >
                          <button
                            className="btn message-btn"
                            onClick={addMessage}
                          >
                            send
                          </button>
                          <button
                            className="btn message-btn"
                            onClick={hideMessageMenu}
                          >
                            cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn send-message"
                    onClick={displayMessageMenu}
                    style={
                      window.innerWidth < 835
                        ? {
                            position: "relative",
                          }
                        : {}
                    }
                  >
                    send message
                  </button>
                </>
              )}
              {userInfo &&
                (userInfo.type === "admin" || userInfo.type === "partner") && (
                  <>
                    <div
                      className="popup"
                      style={
                        passwordDisplayWindow === true
                          ? {
                              display: "flex",
                            }
                          : passwordDisplayWindow === false
                          ? {
                              display: "none",
                            }
                          : ""
                      }
                    >
                      <div className="overlay"></div>
                      <div className="container">
                        <div className="popup-content">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <input
                                type={passwordStatus}
                                placeholder="password *"
                                onChange={passwordHandler}
                                value={password}
                                required
                                style={{ width: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={passwordStatusHandler}
                              >
                                <img
                                  alt=""
                                  src={`/images/vrc-icons-beige-passowrd-${passwordIconStatus}.png`}
                                  style={{ width: "30px" }}
                                />
                              </button>
                            </div>
                            <div style={{ position: "relative" }}>
                              <input
                                type={confirmedPasswordStatus}
                                placeholder="confirm password *"
                                onChange={confirmedPasswordHandler}
                                value={confirmedPassword}
                                required
                                style={{ width: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={confirmedPasswordStatusHandler}
                              >
                                <img
                                  alt=""
                                  src={`/images/vrc-icons-beige-passowrd-${confirmedPasswordIconStatus}.png`}
                                  style={{ width: "30px" }}
                                />
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "50px",
                              gap: "10px",
                            }}
                          >
                            <button
                              className="btn message-btn"
                              onClick={resetPassword}
                            >
                              send
                            </button>
                            <button
                              className="btn message-btn"
                              onClick={hidePasswordMenu}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div
                        className="user-profile"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn send-message"
                          onClick={displayPasswordMenu}
                          style={{
                            position: "relative",
                            top: "auto",
                            right: "auto",
                            transform: "translateY(0)",
                          }}
                        >
                          reset password
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default AdminProfileScreen;
