import React from "react";
import { Link } from "react-router-dom";

import Benefit from "./Benefit";
import Rating from "./Rating";

const Card = ({ content, cardType, user }) => {
  return (
    <>
      {content && (
        <div className="card product-card">
          <div
            className="visual"
            style={
              content.banner
                ? {
                    backgroundImage: `url(${content.banner})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }
                : {}
            }
          >
            {cardType === "partner" ? (
              <>
                <img
                  src={
                    content.head.avatar && content.head.avatar?.length
                      ? content.head.avatar
                      : "/images/avatar-partner-card.png"
                  }
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.src = "/images/avatar-partner-card.png";
                  }}
                  style={{ width: "75%" }}
                />
              </>
            ) : cardType === "experience" ? (
              <>
                <img
                  src={
                    !content.banner ? "/images/avatar-experience-card.png" : ""
                  }
                  alt=""
                  style={{ width: "75%" }}
                />
                <div
                  className="bg-overlay"
                  style={
                    content.banner ? { opacity: "75%" } : { opacity: "50%" }
                  }
                ></div>
                <div className="text-overlay">
                  <h3>{content.name}</h3>
                  <p>
                    {content.location.city},{" "}
                    {content.location.province
                      .split(" ")
                      .map((e) => (e = e[0]))}
                  </p>
                  <h5>{content.category}</h5>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div
            className="text"
            style={
              cardType === "partner"
                ? {
                    flexDirection: "column",
                  }
                : cardType === "experience"
                ? {
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
                : ""
            }
          >
            {cardType === "partner" ? (
              <>
                <Link
                  to={`/${cardType}/${content._id}`}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                ></Link>
                {content.category && (
                  <>
                    <h6>
                      {content.head.name.substring(0, 18)}
                      {content.head.name.length > 18 && "..."}
                    </h6>
                    <span style={{ fontSize: "14px" }}>
                      {content.category.substring(0, 19)}
                      {content.category.length > 19 && "..."}
                    </span>
                  </>
                )}
              </>
            ) : cardType === "experience" ? (
              <>
                <Link
                  to={`/${cardType}/${content._id}`}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                  title={content.name}
                ></Link>
                <div
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  {user && <Benefit visual={user.head.avatar} />}
                  <h6
                    style={
                      content.price.amount === 0
                        ? { color: "var(--var-color-vrc-green)" }
                        : {}
                    }
                  >
                    {content.price.amount === 0
                      ? "FREE"
                      : `$${content.price.amount}/${content.price.pricingType}`}
                  </h6>
                </div>
                {content.reviews?.length ? (
                  <Rating
                    value={
                      content.reviews.reduce(
                        (acc, item) => item.rating + acc,
                        0,
                      ) / content.reviews.length
                    }
                    color="beige"
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
