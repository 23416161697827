import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listExperienceDetails } from "../actions/experienceActions";

import {
  coreCompetencies,
  coreCompetenciesDescriptions,
} from "../data/coreCompetencies";
import grades from "../data/grades";
import bigIdeas from "../data/bigIdeas";
import curriculumCompetencies from "../data/curriculumCompetencies";
import contents from "../data/contents";
import cities from "../data/cities";
import {
  provinces,
  provincesObj,
  regions,
  daysOfWeek,
  months,
  timezones,
  experienceTypes,
} from "../data/data";

import Message from "../components/Message";
import Loader from "../components/Loader";
import { editExperience } from "../actions/adminActions";
import { listRelatedMedia, uploadMedia } from "../actions/mediaActions";

const ExperienceEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const experienceDetails = useSelector((state) => state.experienceDetails);
  const { loading, error, experience } = experienceDetails;

  const adminExperienceEdit = useSelector((state) => state.adminExperienceEdit);
  const {
    loading: loadingEdit,
    error: errorEdit,
    experience: experienceEdit,
  } = adminExperienceEdit;

  const mediaList = useSelector((state) => state.mediaList);
  // eslint-disable-next-line
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  // eslint-disable-next-line
  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  useEffect(() => {
    if (!userInfo || userInfo.type === "teacher") {
      history.push("/");
    } else {
      dispatch(listExperienceDetails(match.params.id));
    }
  }, [userInfo, dispatch, match, history]);

  useEffect(() => {
    if (experienceEdit) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [experienceEdit]);

  const [checkedCores, setCheckedCores] = useState(
    new Array(coreCompetencies.length).fill(false),
  );

  // eslint-disable-next-line
  const [selectedCores, setSelectedCores] = useState([]);

  const [checkedDays, setCheckedDays] = useState(
    new Array(daysOfWeek.length).fill(false),
  );

  // eslint-disable-next-line
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    setSelectedDays(
      daysOfWeek
        .filter((ele) => checkedDays[daysOfWeek.indexOf(ele)] === true)
        .map((day) => day.substring(0, 3)),
    );
  }, [checkedDays]);

  const [name, setName] = useState(``);

  // eslint-disable-next-line
  const [timezone, setTimezone] = useState(`hide`);
  const [priorKnowledge, setPriorKnowledge] = useState(``);
  const [features, setFeatures] = useState(``);
  const [type, setType] = useState(`hide`);
  const [startMonth, setStartMonth] = useState(`hide`);
  const [endMonth, setEndMonth] = useState(`hide`);
  const [startTime, setStartTime] = useState(``);
  const [endTime, setEndTime] = useState(``);
  const [description, setDescription] = useState(``);
  const [address, setAddress] = useState(``);
  const [city, setCity] = useState(``);
  const [region, setRegion] = useState(``);
  const [province, setProvince] = useState(`British Columbia`);
  const [country, setCountry] = useState(``);
  const [maxStudents, setMaxStudents] = useState(``);
  const [amount, setAmount] = useState(0);
  const [pricingType, setPricingType] = useState(`hide`);
  const [duration, setDuration] = useState(``);

  // step 4 - add-ons
  const [addOns, setAddOns] = useState([]);

  const [addOnTitle, setAddOnTitle] = useState(``);
  const [addOnDescription, setAddOnDescription] = useState(``);
  const [addOnPrice, setAddOnPrice] = useState(``);

  const removeAddOn = (e) => {
    e.preventDefault();
    setAddOns(addOns.filter((ao) => ao.addOnTitle !== e.target.id));
  };

  const pushAddOn = (e) => {
    e.preventDefault();

    if (addOnTitle?.length && addOnDescription?.length && addOnPrice?.length) {
      const addOnObject = {
        addOnTitle,
        addOnDescription,
        addOnPrice: Number(addOnPrice),
      };

      setAddOns([...addOns, addOnObject]);

      //
      setAddOnTitle("");
      setAddOnDescription("");
      setAddOnPrice("");
    }
  };

  const addOnTitleHandler = (e) => {
    e.preventDefault();
    setAddOnTitle(e.target.value);
  };

  const addOnDescriptionHandler = (e) => {
    e.preventDefault();
    setAddOnDescription(e.target.value);
  };

  const addOnPriceHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setAddOnPrice(e.target.value);
    }
  };

  useEffect(() => {}, [addOns]);

  // step 6, 7 - subject, grades
  const [subject, setSubject] = useState(`hide`);
  const [grade, setGrade] = useState(`hide`);
  const [note, setNote] = useState(``);

  const gradeHandler = (e) => {
    e.preventDefault();
    setGrade(e.target.value);
    if (
      subject?.length &&
      subject !== `hide` &&
      grade?.length &&
      grade !== `hide`
    ) {
      setCheckedBigIdeas(
        new Array(
          bigIdeas
            .find((group) => group.subject === subject)
            .data.find((subGroup) => subGroup.grade === grade).ideas.length,
        ).fill(false),
      );

      setCheckedCurriculums(
        new Array(
          curriculumCompetencies
            .find((group) => group.subject === subject)
            .data.find((subGroup) => subGroup.grade === grade).points.length,
        ).fill(false),
      );

      setCheckedContents(
        new Array(
          contents
            .find((group) => group.subject === subject)
            .data.find((subGroup) => subGroup.grade === grade).contents.length,
        ).fill(false),
      );
    }
  };

  const noteHandler = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };

  const [checkedBigIdeas, setCheckedBigIdeas] = useState([]);
  const [checkedCurriculums, setCheckedCurriculums] = useState([]);
  const [checkedContents, setCheckedContents] = useState([]);

  const handleOnChangeBigIdeas = (positionIdeas) => {
    const updatedCheckedBigIdeas = checkedBigIdeas.map(
      (itemIdeas, indexIdeas) =>
        indexIdeas === positionIdeas ? !itemIdeas : itemIdeas,
    );

    setCheckedBigIdeas(updatedCheckedBigIdeas);
  };

  const handleOnChangeContents = (positionContents) => {
    const updatedCheckedContents = checkedContents.map(
      (itemContents, indexContents) =>
        indexContents === positionContents ? !itemContents : itemContents,
    );

    setCheckedContents(updatedCheckedContents);
  };

  const handleOnChangeCurriculums = (positionCurriculums) => {
    const updatedCheckedCurriculums = checkedCurriculums.map(
      (itemCurriculums, indexCurriculums) =>
        indexCurriculums === positionCurriculums
          ? !itemCurriculums
          : itemCurriculums,
    );

    setCheckedCurriculums(updatedCheckedCurriculums);
  };

  useEffect(() => {
    if (
      subject?.length &&
      subject !== `hide` &&
      grade?.length &&
      grade !== `hide`
    ) {
      if (
        grades.indexOf(grade) <
        bigIdeas.find((group) => group.subject === subject).data.length
      ) {
        setCheckedBigIdeas(
          new Array(
            bigIdeas
              .find((group) => group.subject === subject)
              .data.find((subGroup) => subGroup.grade === grade).ideas.length,
          ).fill(false),
        );
      }

      if (
        grades.indexOf(grade) <
        curriculumCompetencies.find((group) => group.subject === subject).data
          .length
      ) {
        setCheckedCurriculums(
          new Array(
            curriculumCompetencies
              .find((group) => group.subject === subject)
              .data.find((subGroup) => subGroup.grade === grade).points.length,
          ).fill(false),
        );
      }

      if (
        grades.indexOf(grade) <
        contents.find((group) => group.subject === subject).data.length
      ) {
        setCheckedContents(
          new Array(
            contents
              .find((group) => group.subject === subject)
              .data.find(
                (subGroup) => subGroup.grade === grade,
              ).contents.length,
          ).fill(false),
        );
      }
    } else {
      setCheckedBigIdeas([]);
      setCheckedContents([]);
      setCheckedCurriculums([]);
    }
  }, [subject, grade]);

  const [finalGrades, setFinalGrades] = useState([]);

  const removeGrade = (e) => {
    e.preventDefault();
    setFinalGrades(finalGrades.filter((obj) => obj.grade !== e.target.id));
  };

  const pushGrade = (e) => {
    e.preventDefault();

    if (
      subject?.length &&
      subject !== `hide` &&
      grade?.length &&
      grade !== `hide` &&
      checkedBigIdeas?.length &&
      checkedCurriculums?.length &&
      checkedContents?.length &&
      !finalGrades.find((obj) => obj.grade === grade)
    ) {
      const gradeObject = {
        grade,
        checkedBigIdeas: checkedBigIdeas
          .map((ele, index) => (ele ? index : ""))
          .join(""),
        checkedCurriculums: checkedCurriculums
          .map((ele, index) => (ele ? index : ""))
          .join(""),
        checkedContents: checkedContents
          .map((ele, index) => (ele ? index : ""))
          .join(""),
      };

      setFinalGrades([...finalGrades, gradeObject]);
      //
      setGrade(`hide`);
      setCheckedBigIdeas([]);
      setCheckedContents([]);
      setCheckedCurriculums([]);
    }
  };

  //
  //
  //
  useEffect(() => {
    if (experience && experience.name) {
      setName(experience.name);
      setType(experience.type);
      setDuration(experience.duration);
      setDescription(experience.description);
      setFeatures(experience.features.join("\n"));
      setPriorKnowledge(experience.priorKnowledge.join("\n"));
      setCheckedCores(
        new Array(coreCompetencies.length).fill(false).map((ccc, indexccc) =>
          experience.coreCompetencies
            .toString()
            .split("")
            .map((cc) => Number(cc))
            .includes(indexccc),
        ),
      );
      setAddress(experience.location.address);
      setProvince(experience.location.province);
      setRegion(experience.location.region);
      setCity(experience.location.city);
      setCountry(experience.location.country);

      //
      const availability = Object.values(JSON.parse(experience.availability));

      setStartMonth(availability[0]);
      setEndMonth(availability[1]);
      setCheckedDays(
        new Array(daysOfWeek.length)
          .fill(false)
          .map((ddd, indexddd) =>
            availability[2].includes(daysOfWeek[indexddd].substring(0, 3)),
          ),
      );
      setStartTime(availability[3]);
      setEndTime(availability[4]);
      setTimezone(availability[5]);

      //

      setNote(experience.note);
      setAddOns(experience.addOns);
      setAmount(experience.price.amount);
      setPricingType(experience.price.pricingType);
      setMaxStudents(experience.maxStudents);
      setSubject(experience.subject);

      //
      const tempGrades = [];

      experience.grade.split(",").forEach((grade, indexGrade) => {
        const gradeObject = {
          grade,
          checkedBigIdeas: experience.bigIdeas[indexGrade],
          checkedCurriculums: experience.curriculumCompetencies[indexGrade],
          checkedContents: experience.content[indexGrade],
        };

        tempGrades.push(gradeObject);
      });

      let mediaArray = [];

      if (experience.banner?.length) {
        mediaArray.push({
          elementContent: experience.banner,
          elementType: "banner",
        });
      }

      if (experience.hero.url?.length) {
        mediaArray.push({
          elementContent: experience.hero.url,
          elementType: "hero",
        });
      }

      setFinalMedia(mediaArray);

      setFinalGrades(tempGrades);
    }

    // eslint-disable-next-line
  }, [experience]);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const timezoneHandler = (e) => {
    e.preventDefault();
    setTimezone(e.target.value);
  };

  const featuresHandler = (e) => {
    e.preventDefault();
    setFeatures(e.target.value);
  };

  const priorKnowledgeHandler = (e) => {
    e.preventDefault();
    setPriorKnowledge(e.target.value);
  };

  const typeHandler = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  const descriptionHandler = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const addressHandler = (e) => {
    e.preventDefault();
    setAddress(e.target.value);
  };

  const cityHandler = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const regionHandler = (e) => {
    e.preventDefault();
    setRegion(e.target.value);
  };

  const provinceHandler = (e) => {
    e.preventDefault();
    setProvince(e.target.value);
  };

  const countryHandler = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  const durationHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setDuration(e.target.value);
    }
  };

  const maxStudentsHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setMaxStudents(e.target.value);
    }
  };

  const amountHandler = (e) => {
    e.preventDefault();
    if (e.target.value >= 0) {
      setAmount(Number(e.target.value));
    }
  };

  const pricingTypeHandler = (e) => {
    e.preventDefault();
    if (amount === 0) {
      setPricingType("novalue");
    } else {
      setPricingType(e.target.value);
    }
  };

  const startMonthHandler = (e) => {
    e.preventDefault();
    setStartMonth(e.target.value);
  };

  const endMonthHandler = (e) => {
    e.preventDefault();
    setEndMonth(e.target.value);
  };

  const startTimeHandler = (e) => {
    e.preventDefault();
    setStartTime(e.target.value);
  };

  const endTimeHandler = (e) => {
    e.preventDefault();
    setEndTime(e.target.value);
  };

  const handleOnChangeCores = (positionCores) => {
    const updatedCheckedCores = checkedCores.map((itemCores, indexCores) =>
      indexCores === positionCores ? !itemCores : itemCores,
    );

    setCheckedCores(updatedCheckedCores);
  };

  const handleOnChangeDays = (positionDays) => {
    const updatedCheckedDays = checkedDays.map((itemDays, indexDays) =>
      indexDays === positionDays ? !itemDays : itemDays,
    );

    setCheckedDays(updatedCheckedDays);
  };

  const [errorInsert, setError] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name?.length &&
      type !== "hide" &&
      type?.length &&
      description.length > 100 &&
      duration > 0 &&
      features?.length &&
      priorKnowledge?.length &&
      checkedCores?.length &&
      checkedCores.map((ele, index) => (ele ? index : "")).join("").length >
        0 &&
      address?.length &&
      city?.length &&
      region?.length &&
      region !== "hide" &&
      province?.length &&
      province !== "hide" &&
      country?.length &&
      startMonth !== "hide" &&
      startMonth?.length &&
      endMonth !== "hide" &&
      endMonth?.length &&
      startTime?.length &&
      endTime?.length &&
      timezone !== "hide" &&
      timezone?.length &&
      selectedDays?.length &&
      amount !== null &&
      pricingType !== "hide" &&
      pricingType?.length &&
      maxStudents.toString()?.length &&
      subject?.length &&
      subject !== "hide" &&
      finalGrades?.length
    ) {
      const listing = {
        partner: experience.partner,
        about: experience.about,
        category: experience.category,
        name,
        type,
        description,
        duration,
        features: features
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length),
        priorKnowledge: priorKnowledge
          .split("\n")
          .map((sentence) =>
            sentence.startsWith(" ")
              ? sentence.replace(/\s+/g, " ").trim()
              : sentence,
          )
          .filter((sentence) => sentence?.length),
        coreCompetencies: checkedCores
          .map((ele, index) => (ele ? index : ""))
          .join(""),
        location: { address, city, region, province, country },
        availability: JSON.stringify({
          startMonth,
          endMonth,
          selectedDays,
          startTime,
          endTime,
          timezone,
        }),
        note,
        addOns,
        price: { amount, pricingType },
        maxStudents,
        subject,
        grade: finalGrades.map((obj) => obj.grade).join(","),
        bigIdeas: finalGrades.map((obj) => obj.checkedBigIdeas),
        curriculumCompetencies: finalGrades.map(
          (obj) => obj.checkedCurriculums,
        ),
        content: finalGrades.map((obj) => obj.checkedContents),
      };

      if (finalMedia.find((ele) => ele.elementType === "banner")) {
        listing.banner = finalMedia.find(
          (ele) => ele.elementType === "banner",
        ).elementContent;
      } else {
        listing.banner = "";
      }

      if (finalMedia.find((ele) => ele.elementType === "hero")) {
        listing.hero = {
          type: "image",
          url: finalMedia.find((ele) => ele.elementType === "hero")
            .elementContent,
        };
      } else {
        listing.hero = {
          type: "",
          url: "",
        };
      }

      dispatch(editExperience(listing, experience._id));
    } else {
      if (description?.length && description.length < 100) {
        setError("description field has to be more than 100 characters length");
      } else {
        setError("* = requried fields");
      }
    }
  };

  const [elementContent, setElementContent] = useState(``);
  const [elementType, setElementType] = useState(`hide`);
  const visualTypes = ["banner", "hero"];

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  useEffect(() => {
    if (file) {
      dispatch(listRelatedMedia(userInfo._id, userInfo.type));
    }
  }, [file, dispatch, userInfo]);

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const elementTypeHandler = (e) => {
    e.preventDefault();
    setElementType(e.target.value);
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia(userInfo._id, userInfo.type));
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  useEffect(() => {
    if (userInfo) {
      if (!files) {
        dispatch(listRelatedMedia(userInfo._id, userInfo.type));
      }
    }
  }, [dispatch, files, userInfo]);

  const [finalMedia, setFinalMedia] = useState([]);

  const removeMedia = (e) => {
    e.preventDefault();
    setFinalMedia(finalMedia.filter((obj) => obj.elementType !== e.target.id));
  };

  const pushMedia = (e) => {
    e.preventDefault();

    if (
      elementContent?.length &&
      elementType?.length &&
      elementType !== "hide" &&
      !finalMedia.find((obj) => obj.elementType === elementType)
    ) {
      const mediaObject = {
        elementContent,
        elementType,
      };

      setFinalMedia([...finalMedia, mediaObject]);
      //
      setElementContent("");
      setElementType(`hide`);
    }
  };

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loadingEdit && <Loader />}
      {errorInsert && <Message body={errorInsert} />}
      {errorEdit && <Message body={errorEdit} />}
      {experienceEdit && (
        <Message
          body={`Experience is updated successfully! Please wait for the reload`}
          type="info"
        />
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        userInfo && (
          <div className="container">
            <h3>Edit Experience</h3>
            <form
              className="add-experience-form edit-experience-form"
              onSubmit={submitHandler}
              noValidate
            >
              <h5>general info *</h5>
              <input
                type="text"
                placeholder="title *"
                onChange={nameHandler}
                value={name.toUpperCase()}
                required
              />
              <div className="multiple-input-2">
                <div className="select">
                  <select onChange={typeHandler} value={type}>
                    <option value="hide">select type *</option>
                    {experienceTypes.map((ele, index) => (
                      <option value={ele} key={index}>
                        {ele}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  className="duration"
                  type="number"
                  placeholder="duration (in minutes) *"
                  onChange={durationHandler}
                  value={duration}
                />
              </div>

              <textarea
                onChange={descriptionHandler}
                placeholder="description (min. 100 words) *"
                value={description}
                required
              />
              <textarea
                onChange={featuresHandler}
                placeholder="list what students will experience during this offering. Tell the different components that make up the full experience. *"
                value={features}
              />
              <textarea
                onChange={priorKnowledgeHandler}
                placeholder="list what students need to review in order to understand this lesson. *"
                value={priorKnowledge}
              />
              <h5>core competencies *</h5>
              <div className="core-competencies">
                <div>
                  {coreCompetencies.map((ele, indexCores) => (
                    <div key={indexCores}>
                      <input
                        type="checkbox"
                        id={indexCores + 100}
                        name={indexCores}
                        checked={checkedCores[indexCores]}
                        onChange={() => handleOnChangeCores(indexCores)}
                      />
                      <label
                        htmlFor={indexCores + 100}
                        title={coreCompetenciesDescriptions[
                          indexCores
                        ].copy.join("\n\n")}
                      >
                        <p style={{ fontWeight: "400" }}>{ele}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <h5>location *</h5>
              <input
                type="text"
                placeholder="address *"
                onChange={addressHandler}
                value={address}
                required
              />
              <div className="multiple-input-2">
                <div className="select">
                  <select onChange={provinceHandler} value={province}>
                    <option value="hide">select province/ territory *</option>
                    {provinces.sort().map((prov, index) => (
                      <option key={index} value={prov}>
                        {prov}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="select">
                  <select onChange={regionHandler} value={region}>
                    <option value="hide">select region *</option>
                    {province?.length && province !== "hide" ? (
                      regions
                        .find((ele) => ele.province === province)
                        .list.sort()
                        .map((reg, index) => (
                          <option key={index} value={reg}>
                            {reg}
                          </option>
                        ))
                    ) : (
                      <option disabled>
                        No region available. Please select province.
                      </option>
                    )}
                  </select>
                </div>
              </div>
              <div className="multiple-input-2">
                <div className="select">
                  <select onChange={cityHandler} value={city}>
                    <option value="hide">select city *</option>
                    {province?.length && province !== "hide" ? (
                      cities
                        .filter(
                          (ele) =>
                            ele[1] ===
                            Object.keys(provincesObj)[
                              provinces.indexOf(province)
                            ],
                        )
                        .sort()
                        .map((cityGroup, index) => (
                          <option
                            key={index}
                            value={cityGroup[0]}
                            style={{ textTransform: "uppercase" }}
                          >
                            {cityGroup[0][0]}
                            {cityGroup[0].slice(1).toLocaleLowerCase()}
                          </option>
                        ))
                    ) : (
                      <option disabled>
                        No city available. Please select province.
                      </option>
                    )}
                  </select>
                </div>
                <input
                  type="text"
                  placeholder="country *"
                  onChange={countryHandler}
                  value={country}
                  required
                />
              </div>
              <h5>availability *</h5>
              <div className="date-time multiple-input-5">
                <div className="select">
                  <select onChange={startMonthHandler} value={startMonth}>
                    <option value="hide">start month *</option>
                    {months.map((month, index) => (
                      <option key={index} value={month.substring(0, 3)}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="select">
                  <select onChange={endMonthHandler} value={endMonth}>
                    <option value="hide">end month *</option>
                    {months.map((month, index) => (
                      <option key={index} value={month.substring(0, 3)}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="toggle-time">
                  <label>start time *</label>
                  <input
                    type="time"
                    onChange={startTimeHandler}
                    value={startTime}
                  />
                </div>
                <div className="toggle-time">
                  <label>end time *</label>
                  <input
                    type="time"
                    onChange={endTimeHandler}
                    value={endTime}
                  />
                </div>
                <div className="select">
                  <select onChange={timezoneHandler} value={timezone}>
                    <option value="hide">timezone *</option>
                    {timezones.sort().map((zone, index) => (
                      <option key={index} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="days-of-week">
                <label
                  style={{
                    fontWeight: "200",
                    fontSize: "var(--a-font-size)",
                    opacity: "50%",
                  }}
                >
                  days of the week *
                </label>
                <div className="days">
                  {daysOfWeek.map((ele, indexDays) => (
                    <div key={indexDays}>
                      <input
                        type="checkbox"
                        id={indexDays + 500}
                        name={indexDays}
                        checked={checkedDays[indexDays]}
                        onChange={() => handleOnChangeDays(indexDays)}
                      />
                      <label htmlFor={indexDays + 500}>
                        <p style={{ fontWeight: "400" }}>{ele}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <textarea
                onChange={noteHandler}
                placeholder="note (optional)"
                value={note}
              />
              <h5>add-ons (optional)</h5>
              <div className="addons-inputs">
                <input
                  type="text"
                  placeholder="title"
                  onChange={addOnTitleHandler}
                  value={addOnTitle}
                />
                <input
                  type="text"
                  placeholder="description"
                  onChange={addOnDescriptionHandler}
                  value={addOnDescription}
                />
                <input
                  type="number"
                  placeholder="price"
                  onChange={addOnPriceHandler}
                  value={addOnPrice}
                />
                <button
                  className="btn"
                  style={{ height: "60px", padding: "30px", fontSize: "24px" }}
                  onClick={pushAddOn}
                >
                  +
                </button>
              </div>

              {addOns?.length && (
                <div className="addons">
                  {addOns.map((ele, indexAddOns) => (
                    <div key={indexAddOns}>
                      <span key={indexAddOns}>
                        <p>
                          {ele.addOnTitle.substring(0, 20)}
                          {ele.addOnTitle.length > 20 && "..."}
                        </p>
                        <p>
                          {ele.addOnDescription.substring(0, 100)}
                          {ele.addOnDescription.length > 100 && "..."}
                        </p>
                        <p>${ele.addOnPrice}</p>
                      </span>
                      <button
                        className="btn"
                        style={{
                          height: "60px",
                          padding: "30px",
                          fontSize: "24px",
                        }}
                        id={ele.addOnTitle}
                        onClick={removeAddOn}
                      >
                        <div style={{ transform: "rotateZ(45deg)" }}>+</div>
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <h5>pricing *</h5>
              <div className="pricing">
                <input
                  type="number"
                  placeholder="price *"
                  onChange={amountHandler}
                  value={amount}
                />
                <div className="select">
                  <select onChange={pricingTypeHandler} value={pricingType}>
                    <option value="hide" disabled={amount === 0}>
                      select pricing type *
                    </option>
                    <option value="class" disabled={amount === 0}>
                      per class
                    </option>
                    <option value="student" disabled={amount === 0}>
                      {/* <option value="student"> */}
                      per student
                    </option>
                    <option value="novalue">not specified</option>
                  </select>
                </div>
                <input
                  type="number"
                  placeholder="max students (0 = no maximum) *"
                  onChange={maxStudentsHandler}
                  value={maxStudents}
                />
              </div>

              <h5>grades *</h5>
              {finalGrades && finalGrades?.length && (
                <div className="cells">
                  {finalGrades.map((ele, index) => (
                    <div
                      key={index}
                      className="cell"
                      style={{ width: "fit-content" }}
                    >
                      <p>{ele.grade}</p>
                      <button
                        id={ele.grade}
                        className="btn"
                        style={{ padding: "0 20px" }}
                        onClick={removeGrade}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="select">
                <select onChange={gradeHandler}>
                  <option value="hide">add more grade *</option>
                  {subject?.length && subject !== "hide" ? (
                    grades.map(
                      (ele, indexGrades) =>
                        indexGrades <
                          bigIdeas.find((group) => group.subject === subject)
                            .data.length &&
                        indexGrades <
                          curriculumCompetencies.find(
                            (group) => group.subject === subject,
                          ).data.length &&
                        indexGrades <
                          contents.find((group) => group.subject === subject)
                            .data.length && (
                          <option
                            key={indexGrades}
                            value={ele}
                            disabled={finalGrades
                              .map((fg) => fg.grade)
                              .includes(ele)}
                          >
                            {ele}
                          </option>
                        ),
                    )
                  ) : (
                    <option disabled>
                      No grades available. Please select a subject.
                    </option>
                  )}
                </select>
              </div>
              {checkedBigIdeas?.length && grade !== "hide" ? (
                <div className="big-ideas">
                  <h5 style={{ marginTop: "30px" }}>big ideas *</h5>
                  {checkedBigIdeas.map((idea, indexIdeas) => (
                    <div key={indexIdeas}>
                      <input
                        type="checkbox"
                        id={indexIdeas}
                        name={indexIdeas}
                        value={checkedBigIdeas}
                        checked={checkedBigIdeas[indexIdeas]}
                        onChange={() => handleOnChangeBigIdeas(indexIdeas)}
                      />
                      <label htmlFor={indexIdeas}>
                        <p>
                          {
                            bigIdeas
                              .find((group) => group.subject === subject)
                              .data.find((subGroup) => subGroup.grade === grade)
                              .ideas[indexIdeas]
                          }
                        </p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {checkedCurriculums?.length && grade !== "hide" ? (
                <div className="curriculum-competencies">
                  <h5 style={{ marginTop: "30px" }}>
                    curriculum competencies *
                  </h5>
                  <p style={{ opacity: "50%" }}>
                    By participating in this program, students will interact
                    with the following Curriculum Competencies:
                  </p>
                  {checkedCurriculums.map((ele, indexCurriculums) => (
                    <div key={indexCurriculums}>
                      <input
                        type="checkbox"
                        id={indexCurriculums + 200}
                        name={indexCurriculums}
                        value={checkedCurriculums}
                        checked={checkedCurriculums[indexCurriculums]}
                        onChange={() =>
                          handleOnChangeCurriculums(indexCurriculums)
                        }
                      />
                      <label
                        htmlFor={indexCurriculums + 200}
                        title={
                          curriculumCompetencies
                            .find((group) => group.subject === subject)
                            .data.find((subGroup) => subGroup.grade === grade)
                            .points[indexCurriculums].description.length > 170
                            ? curriculumCompetencies
                                .find((group) => group.subject === subject)
                                .data.find(
                                  (subGroup) => subGroup.grade === grade,
                                ).points[indexCurriculums].description
                            : ""
                        }
                        style={{
                          display: "flex",
                          flexDirection:
                            window.innerWidth < 835 ? "column" : "",
                        }}
                      >
                        <p style={{ fontWeight: "400" }}>
                          {
                            curriculumCompetencies
                              .find((group) => group.subject === subject)
                              .data.find((subGroup) => subGroup.grade === grade)
                              .points[indexCurriculums].handle
                          }
                          :
                        </p>
                        <p
                          style={{
                            paddingLeft: window.innerWidth < 835 ? "" : "10px",
                          }}
                        >
                          {curriculumCompetencies
                            .find((group) => group.subject === subject)
                            .data.find((subGroup) => subGroup.grade === grade)
                            .points[indexCurriculums].description.substring(
                              0,
                              180 -
                                curriculumCompetencies
                                  .find((group) => group.subject === subject)
                                  .data.find(
                                    (subGroup) => subGroup.grade === grade,
                                  ).points[indexCurriculums].handle.length,
                            )}
                          {curriculumCompetencies
                            .find((group) => group.subject === subject)
                            .data.find((subGroup) => subGroup.grade === grade)
                            .points[indexCurriculums].description.length >
                            140 && `...`}
                        </p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {checkedContents?.length && grade !== "hide" ? (
                <div
                  style={{
                    paddingLeft: "30px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    margin: "10px 0",
                  }}
                >
                  <h5 style={{ marginTop: "30px" }}>content *</h5>
                  <p style={{ opacity: "50%" }}>
                    The content of these sessions can incorporate any of these
                    content items identified by the Province:
                  </p>
                  {checkedContents.map((ele, indexContents) => (
                    <div
                      key={indexContents}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={indexContents + 300}
                        name={indexContents}
                        value={checkedContents}
                        checked={checkedContents[indexContents]}
                        onChange={() => handleOnChangeContents(indexContents)}
                      />
                      <label htmlFor={indexContents + 300}>
                        <p>
                          {
                            contents
                              .find((group) => group.subject === subject)
                              .data.find((subGroup) => subGroup.grade === grade)
                              .contents[indexContents]
                          }
                        </p>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {subject?.length &&
              subject !== "hide" &&
              grade?.length &&
              grade !== "hide" ? (
                <button
                  className="btn"
                  style={{ alignSelf: "flex-end" }}
                  onClick={pushGrade}
                >
                  add
                </button>
              ) : (
                <></>
              )}

              <h5>media (optional)</h5>
              {/*  */}
              {finalMedia?.length && (
                <div className="cells">
                  {finalMedia.map((ele, index) => (
                    <div key={index} className="cell">
                      <p>{ele.elementType}</p>
                      <p>{ele.elementContent}</p>
                      <button
                        id={ele.elementType}
                        className="btn"
                        style={{ padding: "0 30px" }}
                        onClick={removeMedia}
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="add-media">
                {finalMedia.length < 2 && (
                  <div className="select">
                    <select onChange={elementTypeHandler}>
                      <option value="hide">select visual type</option>
                      {visualTypes.map((visual) => (
                        <option
                          key={visual}
                          value={visual}
                          disabled={finalMedia.find(
                            (ele) => ele.elementType === visual,
                          )}
                        >
                          {visual}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {elementType?.length && elementType !== "hide" && (
                  <div className="inputs">
                    <input
                      type="text"
                      onChange={elementContentHandler}
                      placeholder={`insert ${elementType} url`}
                      min="0"
                      className="experience-new-name"
                      value={elementContent}
                      style={{ flexGrow: "1" }}
                    />
                    <button className="btn" onClick={pushMedia}>
                      set
                    </button>

                    <input
                      type="file"
                      className="experience-new-name"
                      onChange={uploadFileHandler}
                      id="upload-inline-image"
                      style={{ display: "none" }}
                    />
                    <label htmlFor="upload-inline-image" className="btn">
                      upload
                    </label>
                    <button className="btn" onClick={getMediaFiles}>
                      refresh
                    </button>
                  </div>
                )}
              </div>

              {elementType?.length && elementType !== "hide" && (
                <>
                  {files && files?.length && (
                    <div className="media-lib">
                      {files.map((file, index) => (
                        <button
                          key={index}
                          className="media-file"
                          style={{ backgroundImage: `url(${file}` }}
                          id={file}
                          onClick={mediaFileUrlHandler}
                        ></button>
                      ))}
                    </div>
                  )}
                </>
              )}

              {/*  */}

              <button className="btn" type="submit" onSubmit={submitHandler}>
                submit
              </button>
            </form>
          </div>
        )
      )}
    </div>
  );
};

export default ExperienceEditScreen;
