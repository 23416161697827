import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_RESET,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_RESET,
  USER_LIST_ALL_REQUEST,
  USER_LIST_ALL_SUCCESS,
  USER_LIST_ALL_FAIL,
  USER_LIST_ALL_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_SET_AVATAR_REQUEST,
  USER_SET_AVATAR_SUCCESS,
  USER_SET_AVATAR_FAIL,
  USER_SET_BANNER_REQUEST,
  USER_SET_BANNER_SUCCESS,
  USER_SET_BANNER_FAIL,
  USER_SET_THUMBNAIL_REQUEST,
  USER_SET_THUMBNAIL_SUCCESS,
  USER_SET_THUMBNAIL_FAIL,
  USER_PUSH_VALUE_REQUEST,
  USER_PUSH_VALUE_SUCCESS,
  USER_PUSH_VALUE_FAIL,
  USER_PUSH_ABOUT_REQUEST,
  USER_PUSH_ABOUT_SUCCESS,
  USER_PUSH_ABOUT_FAIL,
  USER_SET_LA_REQUEST,
  USER_SET_LA_SUCCESS,
  USER_SET_LA_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_LIST_RESET,
  USER_LOGIN_RESET,
  USER_UPDATE_RESET,
  USER_DETAILS_RESET,
  USER_PASSWORD_RESET_REQUEST_RESET,
  USER_PASSWORD_RESET_REQUEST_REQUEST,
  USER_PASSWORD_RESET_REQUEST_SUCCESS,
  USER_PASSWORD_RESET_REQUEST_FAIL,
  USER_REGISTER_RESET,
} from "../constants/userConstants";

// login
export const userLoginReducer = (state = { userInfo: null }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        userInfo: null,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LOGIN_RESET:
      return {};
    case USER_LOGOUT:
      return { userInfo: null };
    default:
      return state;
  }
};

// register
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loading: true,
        userInfo: null,
      };
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

// register
export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// verify
export const userActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_REQUEST:
      return {
        loading: true,
        userInfo: null,
      };
    case USER_ACTIVATE_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_ACTIVATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_ACTIVATE_RESET:
      return {};
    default:
      return state;
  }
};

// all users
export const userListAllReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_ALL_REQUEST:
      return {
        loading: true,
      };
    case USER_LIST_ALL_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };
    case USER_LIST_ALL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LIST_ALL_RESET:
      return {};
    default:
      return state;
  }
};

// all users by type
export const userListReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
        // users: [],
      };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };
    case USER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_LIST_RESET:
      return {
        // users: [],
      };
    default:
      return state;
  }
};

// get user details by type
export const userDetailsReducer = (state = { user: null }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };
    case USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_DETAILS_RESET:
      return {
        user: null,
      };
    default:
      return state;
  }
};

// update user by type
export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_UPDATE_RESET:
      return {};
    case USER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set user avatar by type
export const userSetAvatarReducer = (state = { success: null }, action) => {
  switch (action.type) {
    case USER_SET_AVATAR_REQUEST:
      return {
        loading: true,
      };
    case USER_SET_AVATAR_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_SET_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set user banner by type
export const userSetBannerReducer = (state = { success: null }, action) => {
  switch (action.type) {
    case USER_SET_BANNER_REQUEST:
      return {
        loading: true,
      };
    case USER_SET_BANNER_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_SET_BANNER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set user thumbnail by type & index
export const userSetThumbnailReducer = (state = { success: null }, action) => {
  switch (action.type) {
    case USER_SET_THUMBNAIL_REQUEST:
      return {
        loading: true,
      };
    case USER_SET_THUMBNAIL_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_SET_THUMBNAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// add partner value
export const userPushValueReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PUSH_VALUE_REQUEST:
      return {
        loading: true,
      };
    case USER_PUSH_VALUE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_PUSH_VALUE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// add partner about
export const userPushAboutReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PUSH_ABOUT_REQUEST:
      return {
        loading: true,
      };
    case USER_PUSH_ABOUT_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_PUSH_ABOUT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// add partner about
export const userSetLAReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SET_LA_REQUEST:
      return {
        loading: true,
      };
    case USER_SET_LA_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case USER_SET_LA_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// reset user password
export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        updatedUser: action.payload,
      };
    case USER_RESET_PASSWORD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// request user password reset
export const userResetPasswordRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST_REQUEST:
      return {
        loading: true,
      };
    case USER_PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_PASSWORD_RESET_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_PASSWORD_RESET_REQUEST_RESET:
      return {};
    default:
      return state;
  }
};
