import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// import { listUserDetails } from "../actions/userActions";
import Loader from "./Loader";
import Message from "./Message";

const Dashboard = ({ history, match }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      // dispatch(listUserDetails(userInfo._id, userInfo.type));
      // history.push(`/partner/${match.params.id}`);
      // window.location.href = `${window.location.origin}/${userInfo.type}/${userInfo._id}`;
    }
  }, [userInfo, dispatch, history, match]);
  return (
    <div className="dashboard">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        <div className="admin-dashboard">
        <div>
          <Link to="/mybookings" className="btn">
            view bookings
          </Link>
          <Link to="/add-experience" className="btn">
            add new experience
          </Link>
        </div>
      </div>
        )}
    </div>
  );
};

export default Dashboard;
