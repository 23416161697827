import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//
import {
  adminUserAddReducer,
  adminExperienceAddReducer,
  adminExperienceEditReducer,
  adminUserDeleteReducer,
  adminExperienceValidateReducer,
} from "./reducers/adminReducers";

//
import {
  userLoginReducer,
  userRegisterReducer,
  userCreateReducer,
  userActivateReducer,
  userListReducer,
  userDetailsReducer,
  userUpdateReducer,
  userSetAvatarReducer,
  userSetBannerReducer,
  userSetThumbnailReducer,
  userPushValueReducer,
  userPushAboutReducer,
  userSetLAReducer,
  userListAllReducer,
  userResetPasswordReducer,
  userResetPasswordRequestReducer,
} from "./reducers/userReducers";

//
import {
  hostCreateReducer,
  hostDetailsReducer,
  hostListReducer,
} from "./reducers/hostReducers";

//
import {
  experienceListReducer,
  experienceDetailsReducer,
  experienceRequestValidateReducer,
  experienceDeleteReducer,
  experienceSetNameReducer,
  experienceSetHeroReducer,
  experienceSetBannerReducer,
  experienceSetGalleryThumbnailReducer,
  experienceSetNumPurchasedReducer,
  experienceAddReviewReducer,
} from "./reducers/experienceReducers";

//
import { cartReducer } from "./reducers/cartReducers";

//
import {
  connectSocketReducer,
  conversationListReducer,
  createConversationReducer,
  messageListReducer,
  newMessageReducer,
} from "./reducers/connectReducers";

//
import {
  bookingSetReducer,
  bookingDeleteReducer,
  bookingDetailsReducer,
  bookingConfirmReducer,
  bookingDeclineReducer,
  bookingPayReducer,
  bookingPayLaterReducer,
  bookingMarkPaidReducer,
  bookingListMyBookingsReducer,
  bookingListAllBookingsReducer,
  discountCodeReducer,
} from "./reducers/bookingReducers";

//
import {
  contactMailCreateReducer,
  contactMailDeleteReducer,
  contactMailListReducer,
  contactMailDetailsReducer,
} from "./reducers/contactMailReducers";

import {
  mediaDeleteReducer,
  mediaListReducer,
  mediaUploadReducer,
} from "./reducers/mediaReducers";

import { stripeKeyReducer } from "./reducers/stripeReducers";

//
//
//
const reducer = combineReducers({
  adminUserAdd: adminUserAddReducer,
  adminUserDelete: adminUserDeleteReducer,
  adminExperienceAdd: adminExperienceAddReducer,
  adminExperienceEdit: adminExperienceEditReducer,
  //
  hostCreate: hostCreateReducer,
  hostList: hostListReducer,
  hostDetails: hostDetailsReducer,
  //
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userCreate: userCreateReducer,
  userActivate: userActivateReducer,
  userList: userListReducer,
  userListAll: userListAllReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userSetAvatar: userSetAvatarReducer,
  userSetBanner: userSetBannerReducer,
  userSetThumbnail: userSetThumbnailReducer,
  userPushValue: userPushValueReducer,
  userPushAbout: userPushAboutReducer,
  userSetLA: userSetLAReducer,
  userResetPassword: userResetPasswordReducer,
  userResetPasswordRequest: userResetPasswordRequestReducer,
  //
  experienceList: experienceListReducer,
  experienceDetails: experienceDetailsReducer,
  experienceRequestValidate: experienceRequestValidateReducer,
  experienceDelete: experienceDeleteReducer,
  experienceSetName: experienceSetNameReducer,
  experienceSetHero: experienceSetHeroReducer,
  experienceSetBanner: experienceSetBannerReducer,
  experienceSetGalleryThumbnail: experienceSetGalleryThumbnailReducer,
  experienceSetNumPurchased: experienceSetNumPurchasedReducer,
  experienceAddReview: experienceAddReviewReducer,
  adminExperienceValidate: adminExperienceValidateReducer,
  //
  cart: cartReducer,
  //
  bookingSet: bookingSetReducer,
  bookingDelete: bookingDeleteReducer,
  bookingDetails: bookingDetailsReducer,
  bookingConfirm: bookingConfirmReducer,
  bookingDecline: bookingDeclineReducer,
  bookingPay: bookingPayReducer,
  bookingPayLater: bookingPayLaterReducer,
  bookingMarkPaid: bookingMarkPaidReducer,
  bookingListMyBookings: bookingListMyBookingsReducer,
  bookingListAllBookings: bookingListAllBookingsReducer,
  discountCode: discountCodeReducer,
  //
  contactMailCreate: contactMailCreateReducer,
  contactMailDelete: contactMailDeleteReducer,
  contactMailList: contactMailListReducer,
  contactMailDetails: contactMailDetailsReducer,
  //
  connectSocket: connectSocketReducer,
  conversationList: conversationListReducer,
  createConversation: createConversationReducer,
  messageList: messageListReducer,
  newMessage: newMessageReducer,
  //
  mediaList: mediaListReducer,
  mediaUpload: mediaUploadReducer,
  mediaDelete: mediaDeleteReducer,
  //
  stripeKey: stripeKeyReducer,
});

// const cartItemsFromStorage = localStorage.getItem("cartItems")
//   ? JSON.parse(localStorage.getItem("cartItems"))
//   : [];

// const wishListFromStorage = localStorage.getItem("wishList")
//   ? JSON.parse(localStorage.getItem("wishList"))
//   : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  // cart: {
  //   cartItems: cartItemsFromStorage,
  //   wishList: wishListFromStorage,
  // },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
