import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { requestUserPasswordReset } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_PASSWORD_RESET_REQUEST_RESET } from "../constants/userConstants";

const LostPasswordScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userResetPasswordRequest = useSelector(
    (state) => state.userResetPasswordRequest,
  );
  const { loading, error, success } = userResetPasswordRequest;

  const [email, setEmail] = useState(``);

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(requestUserPasswordReset(email));
  };

  useEffect(() => {
    if (error || success) {
      setTimeout(() => {
        setEmail("");
        dispatch({ type: USER_PASSWORD_RESET_REQUEST_RESET });
        history.push("/");
      }, 5000);
    }
  }, [error, success, dispatch, history]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {error && <Message body={error} />}
      {success && (
        <Message
          body={`An email with instructions on how to reset your password has been sent to ${email}. Check your spam or junk folder if you don’t see the email in your inbox. If you no longer have access to this email account, please contact us`}
          type="info"
        />
      )}
      <div className="container" style={{ paddingTop: "40px" }}>
        <h3 style={{ marginBottom: "20px" }}>reset your password</h3>
        <p style={{ paddingLeft: "30px" }}>
          Enter your user account's verified email address and we will send you
          a password reset link.
        </p>
        <form style={{ paddingTop: "20px" }} onSubmit={submitHandler}>
          <input
            type="email"
            placeholder="enter your email address"
            onChange={emailHandler}
            value={email}
            required
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <button className="btn" type="submit" onSubmit={submitHandler}>
              send password reset email
            </button>
            <Link
              className="btn send-message cancel-btn"
              to={`/account`}
              style={{
                position: "relative",
                width: "fit-content",
              }}
            >
              go back
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LostPasswordScreen;
