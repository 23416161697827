import React from "react";

const PartnerPolicyScreen = () => {
  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        <div className="policies">
          <div>
            <h3>Partner Policy</h3>
            <i>This Policy was last updated on 30 July 2022.</i>
          </div>
          <p>
            Upon signing up to become a partner (“Partner”) with Forager
            Education Ltd. (“Forager Education”) to collaborate on Classroom Voyager platform (the “Platform”), Partners agree to the following
            Partner Policy (the “Policy”). This Policy covers all details of the
            partnership with Forager Education. All policies are concerning
            Canadian law.
          </p>
          <ul>
            <li>
              <strong>Partnership Costs</strong>
              <p>
                There is no cost to partnering with Forager Education.
                Developing field trip and presentation listings (“Experiences”),
                whether live-streamed or in-person, are the sole responsibility
                of each Partner and can be listed on the Phase 1 Bookings System
                at no cost to the Partner.
              </p>
              <p>
                When the Phase 2 Digital Resource Database is launched in 2023,
                Partners can publish their learning resources with no added
                hosting fee. Assistance with the production of learning
                resources can be contracted through Forager Education or for
                virtual and augmented reality resources, Forager Virtual can be
                contracted for production assistance.
              </p>
            </li>
            <li>
              <strong>Rights to Materials</strong>
              <ul>
                <li>
                  Partner Materials
                  <p>
                    Partners retain all rights of ownership to their Experiences
                    and learning resources including all images, text, videos,
                    and multimedia materials (“Submitted Content”) related to
                    each that Partners submit for the Platform. Forager
                    Education retains the right to use Submitted Content to
                    promote Experiences and Classroom Voyager as a whole.
                    Submitted Content remains Partner property outside of Classroom Voyager Experiences and promotional activities.
                    Before promotional materials are published using Partner
                    Submitted Content, final designs will be sent to each
                    Partner for approval.
                  </p>
                </li>
                <li>
                  Classroom Voyager Materials
                  <p>
                    2.2. Classroom Voyager Materials Forager Education
                    retains all rights of ownership on promotional materials,
                    branding, photographs, and video content that are produced
                    for the Platform. Partners may use these materials for
                    promotional purposes to showcase their participation in the
                    Platform with the approval of final designs by Forager
                    Education.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <strong>Review Process</strong>
              <p>
                Forager Education Curriculum Coordinators will review all
                materials and Experiences posted to the Platform and suggest
                changes to optimize audience potential and ensure each Partner’s
                content matches the quality and curriculum connections that
                teachers expect. The suggestions made by Curriculum Coordinators
                must be approved by Partners before the materials or Experiences
                are live on the platform.
              </p>
            </li>
            <li>
              <strong>License to Forager Education Ltd.</strong>
              <p>
                Partners grant Forager Education the rights to offer, market,
                and otherwise exploit their Submitted Content. This includes the
                right to add captions for any additional languages or otherwise
                modify content to ensure accessibility.
              </p>
              <p>
                Partners retain the right to request Experiences be removed from
                the Platform at any time. Requests for Removal must be made in
                writing to assigned Account Managers with 30 days’ notice to
                ensure all bookings are fulfilled prior to the removal of
                Experiences.
              </p>
              <p>
                Partners grant Forager Education permission to use their name,
                logo, voice, likeness, and image in connection with marketing,
                promoting, and selling Experiences. Partners waive any rights of
                privacy, publicity, or other rights of a similar nature, to the
                extent permissible under applicable law.
              </p>
            </li>
            <li>
              <strong>{`Trust & Safety`}</strong>
              <ul>
                <li>
                  {`Trust & Safety Policies`}
                  <p>
                    Forager Education reserves the right to remove Experiences,
                    suspend payouts, and/or ban Partners for any reason at any
                    time, without prior notice, including in cases where:
                  </p>
                  <ol>
                    <li>
                      a Partner or Experience does not comply with our policies;
                    </li>
                    <li>
                      a Partner engages in behaviour that might reflect
                      unfavourably on Forager Education or bring Classroom Voyager brand into public disrepute, contempt, scandal,
                      or ridicule; or
                    </li>
                    <li>
                      as determined by Forager Education at its sole discretion.
                    </li>
                  </ol>
                </li>
                <li>
                  Customer Data
                  <p>
                    Partners will have access to as much customer data as is
                    needed to fulfil each booking. No payment details will be
                    shared with Partners. The only customer data that will be
                    shared with Partners is the location of each school group,
                    the name of the teacher, and any other information that may
                    be determined necessary for specific Experiences.
                  </p>
                </li>
                <li>
                  Student Privacy
                  <p>
                    No information will be shared with Partners regarding
                    specific students. Student privacy is of the utmost concern
                    and no names, images, or sensitive information will be
                    shared without written consent from each student’s legal
                    guardian.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <strong>Pricing</strong>
              <ul>
                <li>
                  Experience Pricing
                  <p>
                    Forager Education will make suggestions on Experience
                    pricing to help Partners maximize their revenue potential,
                    but Partners have sole discretion to price their Experiences
                    how they choose.
                  </p>
                  <p>
                    Partners permit Forager Education to join existing bookings
                    for free with our employees for review purposes and to
                    better understand the programs being offered. Partners also
                    permit Forager Education to share access to learning
                    resources with other Partners and school contacts for
                    promotional purposes and content development inspiration.
                    Partners understand that they will not receive compensation
                    in these cases.
                  </p>
                </li>
                <li>
                  Transaction Taxes
                  <p>
                    Customers purchasing Experiences will have local taxes added
                    to the final purchase price. Forager Education will add
                    these onto relevant purchases and remit those taxes to the
                    appropriate authorities.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <strong>Payments</strong>
              <ul>
                <li>
                  Revenue Share
                  <p>
                    When an Experience is purchased, Forager Education
                    calculates the gross revenue (“Gross Amount”) as the total
                    amount received for the purchase. From this, Forager
                    Education subtracts a 3.5% transaction fee paid directly to
                    the payment gateway software provider, as well as any
                    amounts paid to third parties in connection with promotional
                    activities to calculate the net amount of the sale (“Net
                    Amount”).
                  </p>
                  <p>
                    For sales of Experiences, 15% is subtracted as a booking fee
                    used to maintain the platform. This fee is built into the
                    listed price and not added as a separate item upon checkout.
                  </p>
                  <p>
                    After the launch of Phase 2 Digital Resource Database, the
                    sale of student licenses will be divided between Partners,
                    Forager Education, and the partnering provincial or
                    territorial museum association. These associations, acting
                    as sales agents take a 10% commission from the Gross Amount.
                    From the remaining amount, 30% is retained by Forager
                    Education and 70% is distributed to Partners based on the
                    popularity of their content compared to other content within
                    the same grade.
                  </p>
                  <p>
                    Forager Education makes all Partner payments in Canadian
                    dollars (“CAD”). Monthly Revenue Reports will show sales in
                    CAD.
                  </p>
                </li>
                <li>
                  Receiving Payments
                  <p>
                    Distributing booking revenue, Partners are required to
                    submit account details using the same payment gateway as the
                    Platform. This system is supported through Stripe, Inc. and
                    allows for an immediate division of revenue between Forager
                    Education and Partners without the need for extra
                    administrative coordination. To receive payments promptly,
                    Partners must provide accurate account information linked to
                    their Stripe account and any relevant tax numbers that may
                    be needed for payments. Partners are responsible for any
                    taxes owed on income generated through the Platform.
                  </p>
                </li>
                <li>
                  Refunds
                  <p>
                    Partners acknowledge and agree that customers have the right
                    to receive a refund, as detailed in the Refund Policy. They
                    will not receive any revenue from Experiences that have been
                    granted a refund. If Forager Education is required to refund
                    solely from its accounts, Partners agree to transfer funds
                    to cover their portion of the refunded amount.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <strong>Trademarks</strong>
              <p>
                While in partnership with Forager Education, Partners may use
                our trademarks in promotional materials with the written
                approval of Forager Education.
              </p>
              <p>Partners may:</p>
              <ol>
                <li>
                  only use approved trademarks, with appropriate uses outlined
                  in the available brand guidelines;
                </li>
                <li>
                  only use Classroom Voyager trademarks while promoting
                  Experiences through our marketplace; and
                </li>
                <li>
                  remove any trademark materials upon Forager Education’s
                  request.
                </li>
              </ol>
              <p>Partners may not:</p>
              <ol>
                <li>
                  use our trademarks in any way that may degrade our public
                  reputation.
                </li>
              </ol>
            </li>
            <li>
              <strong>Terminating The Partnership</strong>
              <p>
                Partners retain the right to terminate their partnership at any
                time. This will follow the 30-day notice period, as is the case
                with the removal of specific Experiences outlined above.
              </p>
            </li>
            <li>
              <strong>Miscellaneous Legal Terms</strong>
              <ul>
                <li>
                  Updating These Terms
                  <p>
                    On occasion, we may update our Policy to further define our
                    relationships with our Partners. Forager Education reserves
                    the right to modify or make changes to this policy at any
                    time. When changes to the Policy are made, Partners will be
                    notified by the email that is associated with their account.
                    Changes will take effect on the day the Policy is posted.
                  </p>
                  <p>
                    Continued use of the Platform after changes are posted shall
                    mean each Partner’s acceptance of the modifications. The
                    revised Policy shall supersede all previous editions of the
                    Policy.
                  </p>
                </li>
                <li>
                  Survival
                  <p>
                    The following sections shall survive the expiration or
                    termination of this Policy: Sections 2 (Rights to
                    Materials), 3 (License to Forager Education), and 9
                    (Miscellaneous Legal Terms).
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <strong>How to Contact Us</strong>
              <p>
                The best way to contact Forager Education is through each
                Partner’s Account Manager. If no Account Manager has been
                assigned, use partners.ca@foragereducation.com and an agent will
                be in touch. We are always looking for feedback on our services
                and would love to hear from our Partners.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PartnerPolicyScreen;
