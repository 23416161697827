import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Landing from "../components/Landing";

import Collection from "../components/Collection";
import UserShowcase from "../components/UserShowcase";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { listUsers } from "../actions/userActions";
import {
  USER_LIST_ALL_RESET,
  USER_LIST_RESET,
} from "../constants/userConstants";

const OurNetworkScreen = () => {
  const dispatch = useDispatch();

  const [collection, setCollection] = useState([]);

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  useEffect(() => {
    dispatch({ type: USER_LIST_RESET });
    dispatch({ type: USER_LIST_ALL_RESET });
    setTimeout(() => {
      dispatch(listUsers("partner"));
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    if (
      users &&
      [...new Set(users.map((user) => user.type))][0] === "partner"
    ) {
      setCollection(
        users.sort(function (a, b) {
          if (
            a.head.name.toString().toLowerCase() <
            b.head.name.toString().toLowerCase()
          ) {
            return -1;
          }
          if (
            a.head.name.toString().toLowerCase() >
            b.head.name.toString().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        }),
      );
    }
  }, [users]);

  return (
    <>
      {loading && <Loader />}
      {error && <Message body={error} />}
      <div
        className="landing-generic"
        style={{
          backgroundImage: "url(./images/landing-generic.png)",
          paddingTop: "100px",
        }}
      >
        <Landing
          height="fit-content"
          horizontal="flex-start"
          vertical="center"
          heading="Our Partners our pride"
          hWidth="400px"
          copy="We partner with museums, Indigenous communities, heritage sites, science centres, art galleries, and more to bring a wealth of knowledge and experience to BC students and teachers. Every day we are reaching out to new potential partners to increase the variety of stories that are shared through the Classroom Voyager platform. A community representing the full spectrum of our provice. Explore a few of our partners below."
          fullWidth={true}
          btn="become a partner"
          link="account"
          color="beige"
          cColor="beige"
        />
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : (
          <>
            {collection && collection?.length && (
              <>
                <Collection
                  collectionType="partners"
                  heading="new partnering organizations"
                  items={collection}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                  }}
                />
                <UserShowcase users={collection} />
                {/* <Collection
              collectionType="partners"
              heading="With Special Thanks to Our Development and Funding Partners"
              items={users}
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            /> */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OurNetworkScreen;
