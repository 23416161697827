import axios from "axios";

import {
  GET_DISCOUNT_CODE_REQUEST,
  GET_DISCOUNT_CODE_SUCCESS,
  GET_DISCOUNT_CODE_FAIL,
  BOOKING_SET_REQUEST,
  BOOKING_SET_SUCCESS,
  BOOKING_SET_FAIL,
  BOOKING_DELETE_REQUEST,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_DETAILS_REQUEST,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_DETAILS_FAIL,
  BOOKING_CONFIRM_REQUEST,
  BOOKING_CONFIRM_SUCCESS,
  BOOKING_CONFIRM_FAIL,
  BOOKING_DECLINE_REQUEST,
  BOOKING_DECLINE_SUCCESS,
  BOOKING_DECLINE_FAIL,
  BOOKING_PAY_REQUEST,
  BOOKING_PAY_SUCCESS,
  BOOKING_PAY_FAIL,
  BOOKING_PAY_LATER_REQUEST,
  BOOKING_PAY_LATER_SUCCESS,
  BOOKING_PAY_LATER_FAIL,
  BOOKING_MARK_PAID_REQUEST,
  BOOKING_MARK_PAID_SUCCESS,
  BOOKING_MARK_PAID_FAIL,
  BOOKING_LIST_MY_BOOKINGS_REQUEST,
  BOOKING_LIST_MY_BOOKINGS_SUCCESS,
  BOOKING_LIST_MY_BOOKINGS_FAIL,
  BOOKING_LIST_ALL_BOOKINGS_REQUEST,
  BOOKING_LIST_ALL_BOOKINGS_SUCCESS,
  BOOKING_LIST_ALL_BOOKINGS_FAIL,
} from "../constants/bookingConstants";
// import { CART_RESET } from "../constants/cartConstants";

//
//
//
export const getDiscountCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_DISCOUNT_CODE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/bookings/discountcode`, config);

    dispatch({
      type: GET_DISCOUNT_CODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DISCOUNT_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const setBooking = (booking) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_SET_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/bookings`, { booking }, config);

    dispatch({
      type: BOOKING_SET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_SET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const deleteBooking = (bookingId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/bookings/${bookingId}`, config);

    dispatch({
      type: BOOKING_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const getBookingDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/bookings/${id}`, config);

    dispatch({
      type: BOOKING_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const confirmBooking = (bookingId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_CONFIRM_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/bookings/${bookingId}/confirm`,
      {},
      config,
    );

    dispatch({
      type: BOOKING_CONFIRM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_CONFIRM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const declineBooking =
  (bookingId, note) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BOOKING_DECLINE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/bookings/${bookingId}/decline`,
        { note },
        config,
      );

      dispatch({
        type: BOOKING_DECLINE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOKING_DECLINE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//
//
//
export const payBooking = (bookingId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_PAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/bookings/${bookingId}/pay`,
      { id },
      config,
    );

    dispatch({
      type: BOOKING_PAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_PAY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const payLater = (bookingId, type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_PAY_LATER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/bookings/${bookingId}/postpay/${type}`,
      {},
      config,
    );

    dispatch({
      type: BOOKING_PAY_LATER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_PAY_LATER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
//
//
export const markBookingPaid = (bookingId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_MARK_PAID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/bookings/${bookingId}/markpaid`,
      {},
      config,
    );

    dispatch({
      type: BOOKING_MARK_PAID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_MARK_PAID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listMyBookings = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_LIST_MY_BOOKINGS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

    const { data } = await axios.get(`/api/bookings/mybookings`, config);

    dispatch({
      type: BOOKING_LIST_MY_BOOKINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_LIST_MY_BOOKINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listAllBookings = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_LIST_ALL_BOOKINGS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

    const { data } = await axios.get(`/api/bookings/mybookings`, config);

    dispatch({
      type: BOOKING_LIST_ALL_BOOKINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_LIST_ALL_BOOKINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
