import {
  HOST_CREATE_REQUEST,
  HOST_CREATE_SUCCESS,
  HOST_CREATE_FAIL,
  HOST_LIST_REQUEST,
  HOST_LIST_SUCCESS,
  HOST_LIST_FAIL,
  HOST_DETAILS_REQUEST,
  HOST_DETAILS_SUCCESS,
  HOST_DETAILS_FAIL,
  HOST_DETAILS_RESET,
  HOST_CREATE_RESET,
} from "../constants/hostConstants";

// create a host
export const hostCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case HOST_CREATE_REQUEST:
      return {
        loading: true,
      };
    case HOST_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case HOST_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case HOST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// list all hosts
export const hostListReducer = (state = {}, action) => {
  switch (action.type) {
    case HOST_LIST_REQUEST:
      return {
        loading: true,
      };
    case HOST_LIST_SUCCESS:
      return {
        loading: false,
        hosts: action.payload,
      };
    case HOST_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// find a host
export const hostDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case HOST_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case HOST_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case HOST_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case HOST_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};
