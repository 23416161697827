import axios from "axios";

import {
  MEDIA_LIST_REQUEST,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_FAIL,
  MEDIA_UPLOAD_REQUEST,
  MEDIA_UPLOAD_SUCCESS,
  MEDIA_UPLOAD_FAIL,
  MEDIA_DELETE_REQUEST,
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAIL,
} from "../constants/mediaConstants";

// get all cloudinary images
export const listMediaFiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/upload`, config);

    dispatch({
      type: MEDIA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get all cloudinary images
export const listRelatedMedia =
  (userId, type) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MEDIA_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/${type}s/media/${userId}`, config);

      dispatch({
        type: MEDIA_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEDIA_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// upload image to cloudinary
export const uploadMedia = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_UPLOAD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post("/api/upload", formData, config);

    dispatch({
      type: MEDIA_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// delete and image from library
export const deleteMedia = (public_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEDIA_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/upload/${public_id}`, config);

    dispatch({
      type: MEDIA_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
