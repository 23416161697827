import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteMedia,
  listRelatedMedia,
  uploadMedia,
} from "../actions/mediaActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  MEDIA_DELETE_RESET,
  MEDIA_UPLOAD_RESET,
} from "../constants/mediaConstants";

const MediaLibraryScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const mediaDelete = useSelector((state) => state.mediaDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = mediaDelete;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  // eslint-disable-next-line
  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  useEffect(() => {
    if (
      !userInfo ||
      (userInfo.type !== "admin" && userInfo.type !== "reviewer")
    ) {
      history.push("/");
    } else {
      if (!files) {
        dispatch(listRelatedMedia(userInfo._id, "admin"));
      }
    }
  }, [userInfo, history, files, dispatch]);

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia(userInfo._id, userInfo.type));
  };

  const deleteMediaFile = (e) => {
    e.preventDefault();

    if (e.target.id) {
      dispatch(deleteMedia(e.target.id));
    }
  };

  useEffect(() => {
    if (successDelete || errorDelete) {
      setTimeout(() => {
        dispatch({ type: MEDIA_DELETE_RESET });
        dispatch(listRelatedMedia(userInfo._id, userInfo.type));
      }, 5000);
    }
    //eslint-disable-next-line
  }, [successDelete, errorDelete, dispatch]);

  useEffect(() => {
    if (file || errorFile) {
      dispatch({ type: MEDIA_UPLOAD_RESET });
      dispatch(listRelatedMedia(userInfo._id, userInfo.type));
    }
    //eslint-disable-next-line
  }, [file, errorFile, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loadingFiles && <Loader />}
      {loadingFile && <Loader />}
      {loadingDelete && <Loader />}
      {errorFiles && <Message body={errorFiles} />}
      {errorFile && <Message body={errorFile} />}
      {errorDelete && <Message body={errorDelete} />}
      {successDelete && (
        <Message body={"File deleted successfully!"} type="info" />
      )}
      <div className="container">
        <form onSubmit={() => {}} className="add-media">
          <div className="inputs">
            <input
              type="file"
              className="experience-new-name"
              onChange={uploadFileHandler}
              id="upload-inline-image"
              style={{ display: "none" }}
            />
            <label htmlFor="upload-inline-image" className="btn">
              upload
            </label>
            <button className="btn" onClick={getMediaFiles}>
              refresh
            </button>
          </div>
          {files && files?.length && (
            <div className="media-lib">
              {files.map((file, index) => (
                <a
                  href={file[0]}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className="media-file"
                  style={{ backgroundImage: `url(${file[0]}` }}
                  id={file[1]}
                >
                  {userInfo.type === "admin" && (
                    <button
                      className="delete-media-file"
                      onClick={deleteMediaFile}
                      id={file[1]}
                    >
                      <img
                        src="./images/vrc-icons-red-false.png"
                        alt="remove media file"
                        id={file[1]}
                      />
                    </button>
                  )}
                </a>
              ))}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default MediaLibraryScreen;
