import React, { useEffect, useState } from "react";

const ExperienceInfo = ({ experience, grade, user, userInfo }) => {
  const [availability, setAvailability] = useState([]);

  useEffect(() => {
    if (experience && experience.availability) {
      setAvailability(Object.values(JSON.parse(experience.availability)));
    }
  }, [experience]);

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <h6>name:</h6>
          </td>
          <td>
            <h4 title={user.head.name}>
              {user.head.name.substring(0, 29)}
              {user.head.name.length > 29 && "..."}
            </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>
              grade
              {userInfo.type !== "teacher" &&
                grade.split(",").length > 1 &&
                "s"}
              :
            </h6>
          </td>
          <td>
            <h4>
              {userInfo.type === "teacher"
                ? grade.split(",").find((grade) => grade === userInfo.grade)
                : `${grade
                    .split(",")
                    .map((g) =>
                      g === "Kindergarten" ? "K" : g.slice(5, g.length),
                    )}`}
            </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>type:</h6>
          </td>
          <td>
            <h4>{experience.type}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>price:</h6>
          </td>
          <td>
            <h4
              style={
                experience.price.amount === 0
                  ? { color: "var(--var-color-vrc-green)", fontWeight: "500" }
                  : {}
              }
            >
              {experience.price.amount === 0
                ? "FREE"
                : `$${experience.price.amount}/${experience.price.pricingType}`}
            </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>max. students:</h6>
          </td>
          <td>
            <h4>
              {experience.maxStudents === 0
                ? "no maximum"
                : `${experience.maxStudents} students`}
            </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>duration:</h6>
          </td>
          <td>
            <h4>{experience.duration} minutes</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>subject:</h6>
          </td>
          <td>
            <h4>{experience.subject}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>address:</h6>
          </td>
          <td>
            <h4>
              {experience.location.address.substring(0, 29)}
              {experience.location.address.length > 29 && "..."}
            </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>city:</h6>
          </td>
          <td>
            <h4 style={{ textTransform: "Capitalize" }}>
              {experience.location.city.toLowerCase()}
            </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>region:</h6>
          </td>
          <td>
            <h4>{experience.location.region}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>province:</h6>
          </td>
          <td>
            <h4>{experience.location.province}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>country:</h6>
          </td>
          <td>
            <h4>{experience.location.country}</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h6>category:</h6>
          </td>
          <td>
            <h4>{experience.category}</h4>
          </td>
        </tr>
        <tr style={{ display: "flex", flexDirection: "column" }}>
          <td>
            <h6>availability:</h6>
          </td>
          <td>
            <h4>{`${availability[0]}-${availability[1]} | ${
              availability[2] && availability[2].length === 5
                ? "Daily"
                : availability[2] &&
                  availability[2]
                    .map((day) =>
                      day.toLowerCase().includes("thu") ? "thur" : day,
                    )
                    .join(", ")
            } | ${
              availability[3] ? Number(availability[3].split(":")[0]) % 12 : ""
            }:${availability[3] ? availability[3].split(":")[1] : ""}${
              availability[3]
                ? Number(availability[3].split(":")[0]) >= 12
                  ? "pm"
                  : "am"
                : ""
            }-${
              availability[4] ? Number(availability[4].split(":")[0]) % 12 : ""
            }:${availability[4] ? availability[4].split(":")[1] : ""}${
              availability[4]
                ? Number(availability[4].split(":")[0]) >= 12
                  ? "pm"
                  : "am"
                : ""
            } ${availability[5]}`}</h4>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ExperienceInfo;
