import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import jwt_decode from "jwt-decode";

import {
  activateExperience,
  validateExperience,
} from "../actions/adminActions";
import { listUserDetails } from "../actions/userActions";
import {
  ADMIN_ADD_EXPERIENCE_RESET,
  ADMIN_EXPERIENCE_VALIDATE_FAIL,
  ADMIN_EXPERIENCE_VALIDATE_RESET,
} from "../constants/adminConstants";

import Loader from "../components/Loader";
import Message from "../components/Message";

import ExperienceInfo from "../components/ExperienceInfo";
import Rating from "../components/Rating";
import Review from "../components/Review";
import Benefit from "../components/Benefit";

import { coreCompetenciesDescriptions } from "../data/coreCompetencies";
import bigIdeas from "../data/bigIdeas";
import curriculumCompetencies from "../data/curriculumCompetencies";
import contents from "../data/contents";

const ExperienceValidateScreen = ({ match, history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.type !== "admin") {
      dispatch({
        type: ADMIN_EXPERIENCE_VALIDATE_FAIL,
        payload: "Not authorized! Only admin is authrized to show this screen.",
      });
      setTimeout(() => {
        history.push("/");
      }, 7000);
    }
  }, [userInfo, history, dispatch]);

  const [experienceFromToken, setExperienceFromToken] = useState(null);
  const [experience, setExperience] = useState(null);
  const [stamp, setStamp] = useState(null);

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingPtr, error: errorPtr, user } = userDetails;

  const adminExperienceAdd = useSelector((state) => state.adminExperienceAdd);
  const { loading, error, experience: addedExperience } = adminExperienceAdd;

  const adminExperienceValidate = useSelector(
    (state) => state.adminExperienceValidate,
  );
  const {
    loading: loadingValidated,
    error: errorValidated,
    validated,
  } = adminExperienceValidate;

  useEffect(() => {
    setExperienceFromToken(jwt_decode(match.params.token));
  }, [dispatch, match]);

  useEffect(() => {
    if (experienceFromToken) {
      setExperience(experienceFromToken.experience);
      setStamp(experienceFromToken.stamp);
    }
  }, [experienceFromToken]);

  const [tab, setTab] = useState("description");

  const [descriptionActivitiy, setDescriptionActivity] =
    useState("description");
  const [featuresActivitiy, setFeaturesActivity] = useState("");
  const [addOnsActivitiy, setAddOnsActivity] = useState("");
  const [reviewsActivitiy, setReviewsActivity] = useState("");

  const descriptionSwitch = () => {
    if (tab !== "description") {
      setTab("description");
      setDescriptionActivity("description");
      setFeaturesActivity(null);
      setAddOnsActivity(null);
      setReviewsActivity(null);
    }
  };

  const featuresSwitch = () => {
    if (tab !== "features") {
      setTab("features");
      setFeaturesActivity("features");
      setDescriptionActivity(null);
      setAddOnsActivity(null);
      setReviewsActivity(null);
    }
  };

  const addOnsSwitch = () => {
    if (tab !== "add-ons") {
      setTab("add-ons");
      setAddOnsActivity("add-ons");
      setDescriptionActivity(null);
      setFeaturesActivity(null);
      setReviewsActivity(null);
    }
  };

  const reviewsSwitch = () => {
    if (tab !== "reviews") {
      setTab("reviews");
      setReviewsActivity("reviews");
      setDescriptionActivity(null);
      setFeaturesActivity(null);
      setAddOnsActivity(null);
    }
  };

  const [reason, setReason] = useState(``);
  const [displayReason, setDisplayReason] = useState(false);

  const [finalGrade, setFinalGrade] = useState(null);

  const reasonHandler = (e) => {
    setReason(e.target.value);
  };

  const declineHandler = (e) => {
    e.preventDefault();
    setDisplayReason(false);

    if (reason?.length) {
      const validatedExperience = {
        stamp,
        name: experience.name,
        partnerName: user.head.name,
        partnerEmail: user.head.email,
        status: "decline",
        reason,
      };

      dispatch(validateExperience(validatedExperience));
    }
  };

  const validateHandler = (e) => {
    e.preventDefault();
    dispatch(activateExperience(experience, stamp));
  };

  useEffect(() => {
    if (addedExperience) {
      setTimeout(() => {
        dispatch({ type: ADMIN_ADD_EXPERIENCE_RESET });
        const validatedExperience = {
          id: addedExperience._id,
          name: addedExperience.name,
          partnerName: user.head.name,
          partnerEmail: user.head.email,
          status: "approve",
        };

        dispatch(validateExperience(validatedExperience));
      }, 5000);
    }
  }, [addedExperience, user, dispatch]);

  const displayReasonHandler = (e) => {
    e.preventDefault();
    setDisplayReason(!displayReason);
  };

  useEffect(() => {
    if (experience) {
      descriptionSwitch();
      setFinalGrade(experience.grade);
      dispatch(listUserDetails(experience.partner, "partner"));
    }
    // eslint-disable-next-line
  }, [experience]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: ADMIN_ADD_EXPERIENCE_RESET });
        setReason(``);
        history.push("/");
      }, 5000);
    }
  }, [error, history, dispatch]);

  useEffect(() => {
    if (validated || errorValidated) {
      setTimeout(() => {
        dispatch({ type: ADMIN_EXPERIENCE_VALIDATE_RESET });
        setReason(``);
        history.push("/");
      }, 5000);
    }
  }, [validated, errorValidated, history, dispatch]);

  return (
    userInfo?.type === "admin" && (
      <>
        {loading && <Loader />}
        {loadingPtr && <Loader />}
        {loadingValidated && <Loader />}
        {error && <Message body={error} />}
        {errorValidated && <Message body={errorValidated} />}
        {addedExperience && (
          <Message
            body={`Experience is created successfully! Please wait...`}
            type="info"
          />
        )}
        {validated && (
          <Message
            body={`Experience validation email has been sent to the partner! Please wait...`}
            type="info"
          />
        )}
        {experience && user && (
          <>
            <div className="experience">
              <div
                className="experience-banner"
                style={{
                  backgroundImage: experience.banner?.length
                    ? `url(${experience.banner})`
                    : "url(/images/generic-banner.png)",
                  position: "relative",
                }}
              >
                <span className="bg-overlay"></span>
                <div className="container">
                  <div className="experience-banner-content">
                    {loadingPtr ? (
                      <Loader />
                    ) : errorPtr ? (
                      <Message body={errorPtr} />
                    ) : (
                      <>
                        {user && (
                          <div className="identity">
                            <div className="text">
                              <h3 className="partner-name">{user.head.name}</h3>
                              <span>
                                {user.category} | {user.city}, {user.country}
                              </span>
                            </div>
                            <Benefit visual={user.head.avatar} />
                            <Link to={`/partner/${user._id}`} />
                          </div>
                        )}
                      </>
                    )}
                    <div className="experience-title" style={{ zIndex: "100" }}>
                      {experience.numReviews &&
                        experience.numReviews.length &&
                        experience.numReviews?.length && (
                          <Rating
                            value={
                              experience.reviews.reduce(
                                (acc, item) => item.rating + acc,
                                0,
                              ) / experience.reviews.length
                            }
                            color="beige"
                          />
                        )}
                      <h1 className="experience-name">{experience.name}</h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <h6>
                          {experience.price.amount === 0
                            ? "FREE"
                            : `$${experience.price.amount}/${experience.price.pricingType}`}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="big-idea">
                <div className="container">
                  <img src="/images/section-generic.png" alt="" />
                  <div className="big-idea-content">
                    <div>
                      <h3>core competencies</h3>
                      <div className="cores" style={{ display: "flex" }}>
                        {experience.coreCompetencies
                          .toString()
                          .split("")
                          .map((coreIndex, index) => (
                            <span key={index}>
                              {
                                coreCompetenciesDescriptions[Number(coreIndex)]
                                  .heading
                              }
                            </span>
                          ))}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h3>big idea</h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          flexGrow: "1",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {finalGrade &&
                            finalGrade.split(",").map((grade, indexGrade) => (
                              <div key={indexGrade}>
                                <span style={{ textTransform: "uppercase" }}>
                                  {finalGrade.split(",").length > 1
                                    ? grade
                                    : ""}
                                </span>
                                {bigIdeas
                                  .find(
                                    (group) =>
                                      group.subject === experience.subject,
                                  )
                                  .data.find(
                                    (subGroup) => subGroup.grade === grade,
                                  )
                                  .ideas.filter((idea, indexIdea) =>
                                    experience.bigIdeas[indexGrade].includes(
                                      indexIdea,
                                    ),
                                  )
                                  .map((finalIdea, indexFinalIdea) => (
                                    <p key={indexFinalIdea}>• {finalIdea}</p>
                                  ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="experience-details">
                <div className="container">
                  <div className="experience-details-content">
                    <div className="sidebar">
                      <div className="sidebar-section">
                        <h3>experience info</h3>
                        {user && finalGrade && (
                          <ExperienceInfo
                            experience={experience}
                            grade={finalGrade}
                            user={user}
                            userInfo="partner"
                          />
                        )}
                      </div>
                    </div>
                    <div className="details-body">
                      {experience.hero && experience.hero.type?.length ? (
                        experience.hero.type === "video" ? (
                          <video
                            style={{ position: "relative" }}
                            className="hero"
                            width="100%"
                            height="auto"
                            autoPlay={false}
                            controls={true}
                            muted={false}
                            loop={false}
                          >
                            <source
                              src={experience.hero.url}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : experience.hero.type === "image" ? (
                          <div
                            className="hero"
                            style={{
                              backgroundImage: `url(${
                                experience.hero.url?.length
                                  ? experience.hero.url
                                  : "/images/user-hero.png"
                              })`,
                            }}
                          ></div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div
                          className="hero"
                          style={{
                            backgroundImage: "url(/images/user-hero.png)",
                          }}
                        ></div>
                      )}
                      <div className="experience-tabs">
                        <div className="experience-controller">
                          <button
                            onClick={descriptionSwitch}
                            className={descriptionActivitiy && "active"}
                            style={{ zIndex: "100" }}
                          >
                            description
                          </button>
                          <button
                            onClick={featuresSwitch}
                            className={featuresActivitiy && "active"}
                            style={{ zIndex: "101" }}
                          >
                            content
                          </button>
                          <button
                            onClick={addOnsSwitch}
                            className={addOnsActivitiy && "active"}
                            style={{ zIndex: "102" }}
                          >
                            add-ons
                          </button>
                          <button
                            onClick={reviewsSwitch}
                            className={reviewsActivitiy && "active"}
                            style={{ zIndex: "103" }}
                          >
                            reviews
                          </button>
                        </div>
                        <div className="experience-tab-body">
                          {descriptionActivitiy ? (
                            <>
                              <p>{experience.description}</p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  flexGrow: "1",
                                }}
                              ></div>
                              <div>
                                <h3>curriculum competencies</h3>
                                <p>
                                  By participating in this program, students
                                  will interact with the following Curriculum
                                  Competencies:
                                </p>
                                <ul>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    {finalGrade &&
                                      finalGrade
                                        .split(",")
                                        .map((grade, indexGrade) => (
                                          <div key={indexGrade}>
                                            <span
                                              style={{
                                                textTransform: "uppercase",
                                                color:
                                                  "var(--var-color-vrc-grey)",
                                              }}
                                            >
                                              {finalGrade.split(",").length > 1
                                                ? grade
                                                : ""}
                                            </span>
                                            {curriculumCompetencies
                                              .find(
                                                (group) =>
                                                  group.subject ===
                                                  experience.subject,
                                              )
                                              .data.find(
                                                (subGroup) =>
                                                  subGroup.grade === grade,
                                              )
                                              .points.filter(
                                                (point, indexPoint) =>
                                                  experience.curriculumCompetencies[
                                                    indexGrade
                                                  ].includes(indexPoint),
                                              )
                                              .map((finalCC, indexFinalCC) => (
                                                <li key={indexFinalCC}>
                                                  <span className="cch">
                                                    {finalCC.handle}:
                                                  </span>
                                                  <span className="ccd">
                                                    {finalCC.description}
                                                  </span>
                                                </li>
                                              ))}
                                          </div>
                                        ))}
                                  </div>
                                </ul>
                              </div>
                              {loadingPtr ? (
                                <Loader />
                              ) : errorPtr ? (
                                <Message body={errorPtr} />
                              ) : (
                                <>
                                  {user && (
                                    <div className="experience-about">
                                      <h3>about {user.head.name}</h3>
                                      <p>{experience.about}</p>
                                      <p>
                                        To learn more about the cultural centre,
                                        visit their partner page:{" "}
                                        <Link to={`/partner/${user._id}`}>
                                          {user.head.name}
                                        </Link>
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : featuresActivitiy ? (
                            <>
                              <div>
                                <h3>Curricular Connections</h3>
                                {/* <p>
                                Learn the diverse characteristics of the{" "}
                                {user.head.name}:
                              </p> */}
                                <ul>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    {finalGrade
                                      .split(",")
                                      .map((grade, indexGrade) => (
                                        <div key={indexGrade}>
                                          <span
                                            style={{
                                              textTransform: "uppercase",
                                              color:
                                                "var(--var-color-vrc-grey)",
                                            }}
                                          >
                                            {finalGrade.split(",").length > 1
                                              ? grade
                                              : ""}
                                          </span>
                                          {contents
                                            .find(
                                              (group) =>
                                                group.subject ===
                                                experience.subject,
                                            )
                                            .data.find(
                                              (subGroup) =>
                                                subGroup.grade === grade,
                                            )
                                            .contents.filter(
                                              (point, indexPoint) =>
                                                experience.content[
                                                  indexGrade
                                                ].includes(indexPoint),
                                            )
                                            .map(
                                              (
                                                finalContent,
                                                indexFinalContent,
                                              ) => (
                                                <li key={indexFinalContent}>
                                                  <span>•</span>
                                                  <span>{finalContent}</span>
                                                </li>
                                              ),
                                            )}
                                        </div>
                                      ))}
                                  </div>
                                </ul>
                              </div>
                              <div>
                                <h3>your experience will include</h3>
                                <ul>
                                  {experience.features.map((cc, index) => (
                                    <li
                                      key={index}
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <span>•</span>
                                      <p>{cc}</p>
                                    </li>
                                  ))}
                                </ul>
                                {experience.note && (
                                  <p>
                                    <span>Note: </span>
                                    {experience.note}
                                  </p>
                                )}
                              </div>
                              <div>
                                <h3>prior knowledge</h3>
                                <p>
                                  In order to understand this lesson students
                                  will need to know, or have prior knowledge of,
                                  or will need to review the following:
                                </p>
                                <ul>
                                  {experience.priorKnowledge.map(
                                    (pk, index) => (
                                      <li
                                        key={index}
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        {experience.priorKnowledge.length >
                                          1 && <span>•</span>}
                                        <p>{pk}</p>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : addOnsActivitiy ? (
                            <>
                              <div>
                                {experience.addOns?.length ? (
                                  <>
                                    <p>
                                      Browse the add-on elements that you can
                                      choose from to create the perfect field
                                      trip for you.
                                    </p>
                                    <ul
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      {experience.addOns.map((ao, index) => (
                                        <div className="add-ons" key={index}>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "10px",
                                            }}
                                          >
                                            <h4>{ao.addOnTitle}</h4>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              <span style={{ width: "100px" }}>
                                                Description:
                                              </span>
                                              <p
                                                style={{
                                                  width: "calc(100% - 100px)",
                                                }}
                                              >
                                                {ao.addOnDescription}
                                              </p>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              <span style={{ width: "100px" }}>
                                                Price:
                                              </span>
                                              <p
                                                style={{
                                                  width: "calc(100% - 100px)",
                                                }}
                                              >
                                                ${ao.addOnPrice}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </ul>
                                  </>
                                ) : (
                                  <p>No add ons for this listing.</p>
                                )}
                              </div>
                            </>
                          ) : reviewsActivitiy ? (
                            <>
                              <p>
                                Browse the reviews that other teachers who have
                                taken this experiences have left.
                              </p>
                              {experience.reviews &&
                              experience.reviews?.length ? (
                                <>
                                  <div>
                                    <Rating
                                      value={
                                        experience.reviews.reduce(
                                          (acc, item) => item.rating + acc,
                                          0,
                                        ) / experience.reviews.length
                                      }
                                      color="grey"
                                      numReviews={experience.reviews.length}
                                    />
                                  </div>
                                  {experience.reviews &&
                                    experience.reviews?.length && (
                                      <div className="reviews">
                                        {experience.reviews.map((r, index) => (
                                          <Review key={index} review={r} />
                                        ))}
                                      </div>
                                    )}
                                </>
                              ) : (
                                <p>No reviews yet.</p>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="experience-validate">
                <button
                  className="btn"
                  style={{ width: "fit-content" }}
                  onClick={validateHandler}
                >
                  validate
                </button>
                <div>
                  {displayReason && (
                    <>
                      <textarea
                        type="text"
                        value={reason}
                        onChange={reasonHandler}
                        placeholder="Please specify a reason."
                        style={{
                          outlineColor: "var(--var-color-vrc-red)",
                        }}
                      ></textarea>
                      <button
                        className="btn decline-btn"
                        style={{
                          width: "fit-content",
                        }}
                        onClick={declineHandler}
                      >
                        decline
                      </button>
                    </>
                  )}
                  {!displayReason && (
                    <button
                      className="btn decline-btn"
                      style={{ width: "fit-content" }}
                      onClick={displayReasonHandler}
                    >
                      decline
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  );
};

export default ExperienceValidateScreen;
