import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FooterContent = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const date = new Date();
  return (
    <>
      <div
        className="footer"
        style={{ backgroundImage: "url(/images/footer.png)" }}
      >
        <div className="container" style={{ position: "relative" }}>
          <div className="footer-content">
            <div className="section">
              <h4>about</h4>
              <Link to="/">
                <img src="/images/logo-v.png" alt="" className="logo-img" />
              </Link>
              <p>
                Classroom Voyager brings together institutions and experts from
                across the world to create an immersive and engaging learning
                experience for students.
              </p>
            </div>
            <div className="section" style={{ width: "fit-content" }}>
              <h4>sitemap</h4>
              <ul>
                <li>
                  <Link to="/">Homepage</Link>
                </li>
                <li>
                  <Link to="/the-platform">The Platform</Link>
                </li>
                <li>
                  <Link to="/our-network">Our Network</Link>
                </li>
                <li>
                  <Link to="/for-teachers">For Teachers</Link>
                </li>
                <li>
                  <Link to="/for-partners">For Partners</Link>
                </li>
                {userInfo && (
                  <li>
                    <Link to="/catalogue">Listings Catalogue</Link>
                  </li>
                )}
                {userInfo &&
                  (userInfo.type === "partner" ||
                    userInfo.type === "admin") && (
                    <>
                      <li>
                        <Link to="/partner-policy">Partner Policy</Link>
                      </li>
                      <li>
                        <Link to="/revenue-share">Partner Revenue Share</Link>
                      </li>
                    </>
                  )}
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="section">
              <h4>contact</h4>
              <div className="cta-section">
                <img
                  src="/images/vrc-icons-beige-phone.png"
                  alt=""
                  className="cta-icons"
                />
                <p>
                  <a href="tel:+12505694401">+1 250 569 4401</a>
                </p>
              </div>
              <div className="cta-section">
                <img
                  src="/images/vrc-icons-beige-mail.png"
                  alt=""
                  className="cta-icons"
                />
                <p>
                  <a href="mailto:vrvclassroom@foragereducation.com">
                    vrvclassroom@foragereducation.com
                  </a>
                </p>
              </div>
              <div className="cta-section">
                <img
                  src="/images/vrc-icons-beige-partnership.png"
                  alt=""
                  className="cta-icons"
                />
                <p>
                  <a href="mailto:partners.ca@foragereducation.com">
                    partners.ca@foragereducation.com
                  </a>
                </p>
              </div>
              {/* <form method="POST">
                <input
                  type="text"
                  placeholder="first name"
                  // onChange={emailLogHandler}
                  // value={emailLog}
                />
                <input
                  type="email"
                  placeholder="your email address *"
                  // onChange={emailLogHandler}
                  // value={emailLog}
                  required
                />
              </form> */}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <p>{`© Copyright ${date.getFullYear()}, Forager Education Ltd. All right reserved.`}</p>
        </div>
      </div>
    </>
  );
};

export default FooterContent;
