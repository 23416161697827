import React, { Fragment, useEffect, useState } from "react";

import Card from "./Card";
import CardH from "./CardH";

const ListingShowcase = ({ listings, users }) => {
  const [filteredListings, setFilteredListings] = useState(listings);

  const [view, setView] = useState("grid");
  const [sort, setSort] = useState("None");

  const subjectHandler = (e) => {
    setFilteredListings(
      listings.filter((item) => item.subject === e.target.id),
    );
  };

  const typeHandler = (e) => {
    setFilteredListings(listings.filter((item) => item.type === e.target.id));
  };

  const cityHandler = (e) => {
    setFilteredListings(
      listings.filter((item) => item.location.city === e.target.id),
    );
  };

  const categoryHandler = (e) => {
    setFilteredListings(
      listings.filter((item) => item.category === e.target.id),
    );
  };

  const resetHandler = () => {
    setFilteredListings(listings);
  };

  const sortHandler = (e) => {
    setSort(e.target.value);
  };

  const viewHandler = (e) => {
    if (view !== e.target.id) {
      setView(e.target.id);
    }
  };

  useEffect(() => {
    // switch (sort) {
    //   case "None":
    //     setFilteredListings(listings);
    //     break;
    //   case "Overall rating":
    //     setFilteredListings(
    //       filteredListings.sort((a, b) => {
    //         return a.rating - b.rating;
    //       })
    //     );
    //     break;
    //   case "Price (High to Low)":
    //     filteredListings.sort((a, b) => {
    //       return a.price.amount - b.price.amount;
    //     });
    //     break;
    //   case "Price (Low to High)":
    //     filteredListings.sort((a, b) => {
    //       return b.price.amount - a.price.amount;
    //     });
    //     break;
    //   case "Recommended":
    //     filteredListings.sort();
    //     break;
    //   case "Most Popular":
    //     filteredListings.sort();
    //     break;
    //   case "Partner":
    //     filteredListings.sort((a, b) => {
    //       return a.partner - b.partner;
    //     });
    //     break;
    //   default:
    //     break;
    // }
  }, [sort, listings, filteredListings]);

  useEffect(() => {}, [view, filteredListings]);

  useEffect(() => {
    setFilteredListings(listings);
  }, [listings]);


  return (
    <div className="partner-showcase">
      <div className="container">
        <div
          className="controls"
          style={{
            justifyContent: "flex-end",
            gap: "10px",
            position: "relative",
            paddingTop: "40px",
            borderTop: "2px solid var(--var-color-vrc-beige)",
          }}
        >
          <div className="navigation"></div>
          <button
            className="btn"
            style={
              window.innerWidth < 835
                ? { display: "none" }
                : { position: "absolute", left: "0" }
            }
            onClick={resetHandler}
          >
            reset
          </button>
          <form className="select">
            <span
              style={{
                position: "absolute",
                border: "none",
                width: "fit-content",
                left: "-60px",
                top: "26%",
              }}
            >
              Sort by:
            </span>
            <select onChange={sortHandler}>
              <option value="hide">Select an option</option>
              <option value="None">None</option>
              <option value="Overall rating">Overall rating</option>
              <option value="Price (High to Low)">Price (High to Low)</option>
              <option value="Price (Low to High)">Price (Low to High)</option>
              <option value="Recommended">Recommended</option>
              <option value="Most Popular">Most Popular</option>
              <option value="Partner">Partner</option>
            </select>
          </form>
          <div
            className="view"
            style={window.innerWidth < 835 ? { display: "none" } : {}}
          >
            <button className="grid-view btn" id="grid" onClick={viewHandler}>
              <span className="grid-row">
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
              </span>
              <span className="grid-row">
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
              </span>
              <span className="grid-row">
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
                <span className="grid-pixel"></span>
              </span>
            </button>
            <button className="tile-view btn" id="tile" onClick={viewHandler}>
              <span className="tile-row">
                <span className="grid-pixel"></span>
                <span className="grid-w-pixel"></span>
              </span>
              <span className="tile-row">
                <span className="grid-pixel"></span>
                <span className="grid-w-pixel"></span>
              </span>
              <span className="tile-row">
                <span className="grid-pixel"></span>
                <span className="grid-w-pixel"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="listing-showcase">
          <div className="listing-criteria">
            <div className="cri-block">
              <span className="btn">subject</span>
              <div
                style={{
                  padding: "20px",
                  color: "var(--var-color-vrc-beige)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {[...new Set(listings.map((listing) => listing.subject))]
                  .sort()
                  .map((item, index) => (
                    <div
                      id={item}
                      key={index}
                      onClick={subjectHandler}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      <span id={item}>{item}</span>
                      <span id={item}>
                        {listings.filter((lst) => lst.subject === item).length}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="cri-block">
              <span className="btn">type</span>
              <div
                style={{
                  padding: "20px",
                  color: "var(--var-color-vrc-beige)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {[...new Set(listings.map((listing) => listing.type))]
                  .sort()
                  .map((item, index) => (
                    <div
                      id={item}
                      key={index}
                      onClick={typeHandler}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      <span id={item}>
                        {item[0].toUpperCase()}
                        {item.slice(1)}
                      </span>
                      <span id={item}>
                        {listings.filter((lst) => lst.type === item).length}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="cri-block">
              <span className="btn">city</span>
              <div
                style={{
                  padding: "20px",
                  color: "var(--var-color-vrc-beige)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {[...new Set(listings.map((listing) => listing.location.city))]
                  .sort()
                  .map((item, index) => (
                    <div
                      id={item}
                      key={index}
                      onClick={cityHandler}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      <span id={item}>
                        {item.split(" ").map((word, index) => (
                          <Fragment key={index}>
                            {word[0]}
                            {word.slice(1).toLowerCase()}{" "}
                          </Fragment>
                        ))}
                      </span>
                      <span id={item}>
                        {
                          listings.filter((lst) => lst.location.city === item)
                            .length
                        }
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="cri-block">
              <span className="btn">category</span>
              <div
                style={{
                  padding: "20px",
                  color: "var(--var-color-vrc-beige)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {[...new Set(listings.map((listing) => listing.category))]
                  .sort()
                  .map((item, index) => (
                    <div
                      id={item}
                      key={index}
                      onClick={categoryHandler}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      <span id={item}>{item}</span>
                      <span id={item}>
                        {listings.filter((lst) => lst.category === item).length}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="partners listings">
            {view === "grid" ? (
              <>
                {filteredListings.map((experience, index) => (
                  <Card
                    key={index}
                    content={experience}
                    cardType="experience"
                    user={users.find((user) => user._id === experience.partner)}
                  />
                ))}
              </>
            ) : view === "tile" ? (
              <>
                {filteredListings.map((experience, index) => (
                  <CardH
                    key={index}
                    content={experience}
                    cardType="experience"
                    user={users.find((user) => user._id === experience.partner)}
                  />
                ))}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingShowcase;
