const experienceSubjects = [
  "Arts Education",
  "Biology",
  "Earth & Space",
  "Social Studies",
  // "Applied Design, Skills, and Technologies",
  // "Career Education",
  // "English Language Arts",
  // "Français Langue première",
  // "French Immersion Language Arts",
  // "Mathematics",
  // "Physical and Health Education",
  // "Languages",
];

export default experienceSubjects;
