import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { io } from "socket.io-client";
import ReactTimeAgo from "react-time-ago";

import {
  addNewMessage,
  getConversations,
  getMessages,
  getSocketConnected,
} from "../actions/connectActions";
import { listAllUsers, listUserDetails } from "../actions/userActions";

import {
  USER_DETAILS_RESET,
  USER_LIST_ALL_RESET,
} from "../constants/userConstants";
import {
  CONV_GET_CONVERSATIONS_RESET,
  MSG_GET_MESSAGES_RESET,
  WS_GET_URL_RESET,
} from "../constants/connectConstants";

import Loader from "../components/Loader";
import Message from "../components/Message";

const MessagesScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [submittedMessage, setSubmittedMessage] = useState(``);
  const [conversationId, setConversationId] = useState(``);
  const [receiverName, setReceiverName] = useState(``);
  const [receiverId, setReceiverId] = useState(null);
  const [threadDisplayValue, setThreadsDisplayValue] = useState(`flex`);
  const [buttonDisplayValue, setButtonDisplayValue] = useState(`none`);
  const [thread, setThread] = useState([]);

  const scrollRef = useRef();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const conversationList = useSelector((state) => state.conversationList);
  const {
    loading: loadingConv,
    error: errorConv,
    conversations,
  } = conversationList;

  const messageList = useSelector((state) => state.messageList);
  const { loading: loadingMsg, error: errorMsg, messages } = messageList;

  const newMessage = useSelector((state) => state.newMessage);
  const { loading: sending, error: failedSending, message } = newMessage;

  const userListAll = useSelector((state) => state.userListAll);
  const { loading: loadingUsers, error: errorUsers, users } = userListAll;

  const hideThreads = () => {
    if (window.innerWidth < 1199) {
      setThreadsDisplayValue(`none`);
      setButtonDisplayValue(`block`);
    }
  };

  const showThreads = () => {
    setThreadsDisplayValue(`flex`);
    setButtonDisplayValue(`none`);
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    } else {
      dispatch({ type: WS_GET_URL_RESET });
      dispatch({ type: CONV_GET_CONVERSATIONS_RESET });
      dispatch({ type: MSG_GET_MESSAGES_RESET });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LIST_ALL_RESET });
      dispatch(listUserDetails(userInfo._id, userInfo.type));
      dispatch(getConversations(userInfo._id));
      dispatch(listAllUsers());
    }
  }, [userInfo, history, dispatch]);

  useEffect(() => {
    setThread(messages);
  }, [messages]);

  useEffect(() => {
    if (message) {
      setThread([...thread, message]);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (thread?.length && message) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [thread, message]);

  const conversationHandler = (e) => {
    e.preventDefault();
    dispatch(getMessages(e.target.id));
    setConversationId(e.target.id);
    if (users) {
      setReceiverName(
        users.find(
          (user) =>
            user.id ===
            conversations
              .find((conv) => conv._id === e.target.id)
              .members.find((member) => member !== userInfo._id),
        )?.name,
      );
      setReceiverId(
        users.find(
          (user) =>
            user.id ===
            conversations
              .find((conv) => conv._id === e.target.id)
              .members.find((member) => member !== userInfo._id),
        )?.id,
      );
    }
  };

  const newMessageHandler = (e) => {
    e.preventDefault();
    setSubmittedMessage(e.target.value);
    // here we go
  };

  const submitNewMessage = (e) => {
    e.preventDefault();
    if (conversationId?.length && userInfo && submittedMessage?.length) {
      dispatch(addNewMessage(conversationId, userInfo._id, submittedMessage));
      setSubmittedMessage("");

      // socket
      socket.current.emit("sendMessage", {
        senderId: userInfo._id,
        receiverId: users.find(
          (user) =>
            user.id ===
            conversations
              .find((conv) => conv._id === conversationId)
              .members.find((member) => member !== userInfo._id),
        ).id,
        text: submittedMessage,
      });
    }
  };

  // socket
  const connectSocket = useSelector((state) => state.connectSocket);
  const { loading: loadingWs, error: errorWs, url: ws } = connectSocket;

  useEffect(() => {
    if (errorWs) {
      setTimeout(() => {
        dispatch({ type: WS_GET_URL_RESET });
      }, 5000);
    }
  }, [errorWs, dispatch]);

  const socket = useRef();

  const [arrivalMessage, setArrivalMessage] = useState(null);

  useEffect(() => {
    if (ws) {
      socket.current = io();

      socket.current.on("getMessage", (data) => {
        setArrivalMessage({
          sender: data.sender,
          text: data.text,
          createdAt: Date.now(),
        });
      });

      if (userInfo && socket.current) {
        socket.current.emit("addUser", userInfo._id);
        socket.current.on("getUsers", (users) => {});
      }
    } else {
      dispatch(getSocketConnected());
    }
  }, [ws, userInfo, dispatch]);

  useEffect(() => {}, [userInfo]);

  useEffect(() => {
    arrivalMessage &&
      setThread((prev) => {
        if (prev) {
          return [...prev, arrivalMessage];
        }
      });
  }, [arrivalMessage]);

  useEffect(() => {
    if (thread && thread?.length && arrivalMessage) {
      scrollRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [thread, arrivalMessage]);

  useEffect(() => {
    if (messages && messages?.length && thread && thread?.length) {
      scrollRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, thread]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
        paddingBottom: "0",
      }}
    >
      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        userInfo && (
          <>
            {conversations && conversations?.length ? (
              <>
                <div className="messages">
                  <div
                    className="threads"
                    onClick={hideThreads}
                    style={{
                      display: `${threadDisplayValue}`,
                    }}
                  >
                    {failedSending && <Message body={failedSending} />}
                    {loadingWs ? (
                      <Loader />
                    ) : loadingConv ? (
                      <Loader />
                    ) : loadingUsers ? (
                      <Loader />
                    ) : errorWs ? (
                      <Message body={errorWs} />
                    ) : errorConv ? (
                      <Message body={errorConv} />
                    ) : errorUsers ? (
                      <Message body={errorUsers} />
                    ) : (
                      conversations &&
                      users &&
                      users?.length && (
                        <div className="conversations">
                          <input
                            type="search"
                            className="search-friends"
                            placeholder="search"
                          />
                          {conversations.map((conv, index) => (
                            <div
                              className={`conversation`}
                              key={index}
                              id={conv._id}
                              onClick={conversationHandler}
                            >
                              <span
                                className="user-avatar"
                                style={{
                                  backgroundImage: `url(${
                                    users.find(
                                      (user) =>
                                        user.id ===
                                        conv.members.find(
                                          (m) => m !== userInfo._id,
                                        ),
                                    )?.avatar &&
                                    users.find(
                                      (user) =>
                                        user.id ===
                                        conv.members.find(
                                          (m) => m !== userInfo._id,
                                        ),
                                    )?.avatar?.length
                                      ? users.find(
                                          (user) =>
                                            user.id ===
                                            conv.members.find(
                                              (m) => m !== userInfo._id,
                                            ),
                                        )?.avatar
                                      : "/images/avatar-user.png"
                                  })`,
                                }}
                              ></span>
                              <h6 id={conv._id}>
                                {
                                  users.find(
                                    (user) =>
                                      user.id ===
                                      conv.members.find(
                                        (m) => m !== userInfo._id,
                                      ),
                                  )?.name
                                }
                              </h6>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                  <div className="chat-box">
                    {loadingMsg ? (
                      <Loader />
                    ) : errorMsg ? (
                      <Message body={errorMsg} />
                    ) : (
                      conversations &&
                      users &&
                      user && (
                        <>
                          {thread && thread?.length ? (
                            <>
                              <div className="chat">
                                {thread.map((msg, index) => (
                                  <div
                                    className={`chat-message ${
                                      msg.sender === userInfo._id
                                        ? "chat-message-reverse"
                                        : ""
                                    }`}
                                    key={index}
                                    ref={scrollRef}
                                  >
                                    {/* <span
                                      className="user-avatar"
                                      style={{
                                        backgroundImage: `url(${
                                          msg.sender === userInfo._id
                                            ? userInfo.avatar
                                            : users.find(
                                                (user) =>
                                                  user.id === msg.sender,
                                              ).avatar
                                        })`,
                                      }}
                                    ></span> */}
                                    <div>
                                      <p>{msg.text}</p>
                                      <div className="message-time">
                                        <ReactTimeAgo
                                          date={new Date(msg.createdAt)}
                                          locale="en-US"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {sending && (
                                  <div
                                    className={`chat-message chat-message-reverse`}
                                  >
                                    <div>
                                      <p>...</p>
                                      <div className="message-time">
                                        <ReactTimeAgo
                                          date={Date.now()}
                                          locale="en-US"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <form onSubmit={submitNewMessage}>
                                <input
                                  type="text"
                                  placeholder={`send message to ${receiverName}`}
                                  value={submittedMessage}
                                  onChange={newMessageHandler}
                                  id={`${userInfo._id}-${receiverId}`}
                                />
                                <button
                                  className="btn"
                                  onSubmit={submitNewMessage}
                                >
                                  send
                                </button>
                              </form>
                            </>
                          ) : (
                            <span>open a conversation to start chat</span>
                          )}
                          <button
                            className="btn"
                            onClick={showThreads}
                            style={{
                              display: `${buttonDisplayValue}`,
                              minHeight: "60px",
                            }}
                          >
                            select from conversations
                          </button>
                        </>
                      )
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="container">
                <span className="no-messages">nothing to show here</span>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default MessagesScreen;
