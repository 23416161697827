import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import Message from "../components/Message";

import {
  listUserDetails,
  setUserAvatar,
  setUserBanner,
  resetUserPassword,
} from "../actions/userActions";
import {
  addNewMessage,
  createNewConversation,
} from "../actions/connectActions";
import {
  CONV_CREATE_NEW_RESET,
  MSG_ADD_NEW_RESET,
} from "../constants/connectConstants";
import { listRelatedMedia, uploadMedia } from "../actions/mediaActions";
import { Link } from "react-router-dom";

const TeacherScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const {
    loading: loadingPwd,
    error: errorPwd,
    updatedUser,
  } = userResetPassword;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createConversation = useSelector((state) => state.createConversation);
  const { conversation } = createConversation;

  const newMessage = useSelector((state) => state.newMessage);
  const { message: nMessage } = newMessage;

  // admin only
  const [passwordDisplayWindow, setPasswordDisplayWindow] = useState(false);

  const [password, setPassword] = useState(``);
  const [confirmedPassword, setConfirmedPassword] = useState(``);
  const [passwordStatus, setPasswordStatus] = useState(`password`);
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] =
    useState(`password`);
  const [passwordIconStatus, setPasswordIconStatus] = useState(`show`);
  const [confirmedPasswordIconStatus, setConfirmedPasswordIconStatus] =
    useState(`show`);

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    e.preventDefault();
    setConfirmedPassword(e.target.value);
  };

  const passwordStatusHandler = (e) => {
    e.preventDefault();
    if (passwordStatus === "text") {
      setPasswordStatus("password");
    } else {
      setPasswordStatus("text");
    }

    if (passwordIconStatus === "show") {
      setPasswordIconStatus("hide");
    } else {
      setPasswordIconStatus("show");
    }
  };

  const confirmedPasswordStatusHandler = (e) => {
    e.preventDefault();
    if (confirmedPasswordStatus === "text") {
      setConfirmedPasswordStatus("password");
    } else {
      setConfirmedPasswordStatus("text");
    }

    if (confirmedPasswordIconStatus === "show") {
      setConfirmedPasswordIconStatus("hide");
    } else {
      setConfirmedPasswordIconStatus("show");
    }
  };

  const resetPasswordFields = () => {
    setPassword(``);
    setConfirmedPassword(``);
    setPasswordStatus("password");
    setConfirmedPasswordStatus("password");
    setPasswordIconStatus("show");
    setConfirmedPasswordIconStatus("show");
  };

  const resetPassword = (e) => {
    e.preventDefault();

    if (
      password?.length &&
      confirmedPassword?.length &&
      password === confirmedPassword
    ) {
      dispatch(resetUserPassword(user.type, password, user._id));
      resetPasswordFields();
      setPasswordDisplayWindow(false);
    }
  };

  const displayPasswordMenu = (e) => {
    e.preventDefault();
    resetPasswordFields();
    setPasswordDisplayWindow(true);
  };

  const hidePasswordMenu = (e) => {
    e.preventDefault();
    resetPasswordFields();
    setPasswordDisplayWindow(false);
  };

  useEffect(() => {
    if (updatedUser) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [updatedUser]);

  //

  const uploadFileHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(uploadMedia(formData));
  };

  const [messageDisplayWindow, setMessageDisplayWindow] = useState(false);
  const [message, setMessage] = useState(``);

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const addMessage = (e) => {
    e.preventDefault();
    if (message?.length) {
      dispatch(
        createNewConversation(userInfo._id, user._id, userInfo.type, user.type),
      );
    }
  };

  useEffect(() => {
    if (conversation && message?.length) {
      dispatch(addNewMessage(conversation._id, userInfo._id, message));
      setMessage(``);
      setMessageDisplayWindow(false);
    }
    // eslint-disable-next-line
  }, [dispatch, conversation]);

  useEffect(() => {}, [message]);

  useEffect(() => {
    if (nMessage) {
      dispatch({ type: CONV_CREATE_NEW_RESET });
      dispatch({ type: MSG_ADD_NEW_RESET });
    }
    // eslint-disable-next-line
  }, [dispatch, nMessage]);

  const displayMessageMenu = (e) => {
    e.preventDefault();
    setMessage(``);
    setMessageDisplayWindow(true);
  };

  const hideMessageMenu = (e) => {
    e.preventDefault();
    setMessageDisplayWindow(false);
    setMessage(``);
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    } else {
      if (
        match.params.id &&
        match.params.id?.length &&
        match.url.split("/")[1] &&
        match.url.split("/")[1]?.length
      ) {
        dispatch(listUserDetails(match.params.id, match.url.split("/")[1]));
      }
    }
  }, [userInfo, history, match, dispatch]);

  ////////////////////////////////////
  const mediaList = useSelector((state) => state.mediaList);
  const { loading: loadingFiles, error: errorFiles, files } = mediaList;

  const mediaUpload = useSelector((state) => state.mediaUpload);
  const { loading: loadingFile, error: errorFile, file } = mediaUpload;

  const [elementType, setElementType] = useState(``);
  const [elementContent, setElementContent] = useState(``);

  const [displayWindow, setDisplayWindow] = useState(false);

  const displayMenu = (e) => {
    e.preventDefault();
    if (!files) {
      getMediaFiles(e);
    }
    setElementType(e.target.id);
    setElementContent(``);
    setDisplayWindow(true);
  };

  const hideMenu = (e) => {
    e.preventDefault();
    setDisplayWindow(false);
    setElementContent(``);
    setElementType(``);
  };

  const elementContentHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.value);
  };

  const getMediaFiles = async (e) => {
    e.preventDefault();
    dispatch(listRelatedMedia(userInfo._id, userInfo.type));
  };

  const mediaFileUrlHandler = (e) => {
    e.preventDefault();
    setElementContent(e.target.id);
  };

  const setVisual = () => {
    if (elementContent && elementType?.length) {
      switch (elementType) {
        case "profile-picture":
          dispatch(
            setUserAvatar(
              userInfo._id,
              userInfo.type,
              elementContent.replace("\\", "/"),
            ),
          );
          break;
        case "banner":
          dispatch(
            setUserBanner(
              userInfo._id,
              userInfo.type,
              elementContent.replace("\\", "/"),
            ),
          );
          break;
        default:
          return;
      }
      window.location.reload();
    }
  };

  useEffect(() => {
    if (file && file?.length) {
      setElementContent(file);
    }
  }, [file]);

  ////////////////////////////////////

  return (
    <>
      {loadingPwd && <Loader />}
      {loadingPwd && <Loader />}
      {errorPwd && <Message body={errorPwd} />}
      {updatedUser && (
        <Message body="Password has been set successfully!" type="info" />
      )}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message body={error} />
      ) : (
        user && (
          <>
            {userInfo && userInfo._id === user._id && displayWindow && (
              <>
                <div className="popup" style={{ display: "flex" }}>
                  <div className="overlay"></div>
                  <div className="container">
                    {loadingFiles && <Loader />}
                    {loadingFile && <Loader />}
                    {errorFiles && <Message body={errorFiles} />}
                    {errorFile && <Message body={errorFile} />}
                    <form className="popup-content" onSubmit={resetPassword}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <input
                          type="text"
                          onChange={elementContentHandler}
                          placeholder="enter image url"
                          min="0"
                          className="experience-new-name"
                          value={elementContent}
                          style={{ flexGrow: "1" }}
                        />

                        <input
                          type="file"
                          className="experience-new-name"
                          onChange={uploadFileHandler}
                          id="upload-inline-image"
                          style={{ display: "none" }}
                        />
                        <label htmlFor="upload-inline-image" className="btn">
                          upload file
                        </label>
                        <button className="btn" onClick={getMediaFiles}>
                          refresh list
                        </button>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <button
                          className="btn"
                          onClick={setVisual}
                          style={{ marginTop: "20px" }}
                        >
                          set
                        </button>
                        <button
                          className="btn"
                          onClick={hideMenu}
                          style={{ marginTop: "20px" }}
                        >
                          cancel
                        </button>
                      </div>
                      {files && files?.length && (
                        <div className="media-lib">
                          {files.map((file, index) => (
                            <div
                              key={index}
                              className="media-file"
                              style={{ backgroundImage: `url(${file}` }}
                              id={file}
                              onClick={mediaFileUrlHandler}
                            ></div>
                          ))}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </>
            )}
            <div className="user">
              <div
                className="banner"
                style={{
                  backgroundImage: user.head.banner?.length
                    ? `url(${user.head.banner}`
                    : "url(/images/user-hero.png)",
                }}
              >
                {userInfo && userInfo._id === user._id && (
                  <label
                    id="banner"
                    className="edit-banner"
                    onClick={displayMenu}
                  >
                    <img alt="" src="/images/vrc-icons-beige-edit.png" />
                  </label>
                )}
              </div>
              <div className="container">
                <div className="user-profile">
                  <div
                    style={
                      window.innerWidth < 835
                        ? { left: "50%", transform: "translateX(-50%)" }
                        : {}
                    }
                    className="user-logo"
                  >
                    <span
                      className="logo"
                      style={{
                        backgroundImage: `url(${
                          user.head.avatar?.length
                            ? user.head.avatar
                            : "/images/avatar-user.png"
                        })`,
                      }}
                    ></span>
                    {userInfo && userInfo._id === user._id && (
                      <label
                        id="profile-picture"
                        className="edit-logo"
                        onClick={displayMenu}
                      >
                        <img alt="" src="/images/vrc-icons-beige-edit.png" />
                      </label>
                    )}
                  </div>
                  <div className="user-title">
                    <h2>{user.head.name}</h2>
                    <h3>
                      {user.grade} | {user.city}, {user.country}
                    </h3>
                  </div>
                  {userInfo && userInfo._id === user._id && (
                    <Link
                      className="btn send-message"
                      to={`/${userInfo.type}/${userInfo._id}/edit`}
                      style={
                        window.innerWidth < 835
                          ? {
                              position: "relative",
                            }
                          : {}
                      }
                    >
                      edit profile
                    </Link>
                  )}
                  {userInfo && userInfo._id !== user._id && (
                    <>
                      <div
                        className="popup"
                        style={
                          messageDisplayWindow === true
                            ? {
                                display: "flex",
                              }
                            : messageDisplayWindow === false
                            ? {
                                display: "none",
                              }
                            : ""
                        }
                      >
                        <div className="overlay"></div>
                        <div className="container">
                          <div className="popup-content">
                            <textarea
                              placeholder="write your message here"
                              onChange={messageHandler}
                              value={message}
                            ></textarea>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "50px",
                                gap: "10px",
                              }}
                            >
                              <button
                                className="btn message-btn"
                                onClick={addMessage}
                              >
                                send
                              </button>
                              <button
                                className="btn message-btn"
                                onClick={hideMessageMenu}
                              >
                                cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn send-message"
                        onClick={displayMessageMenu}
                        style={
                          window.innerWidth < 835
                            ? {
                                position: "relative",
                              }
                            : {}
                        }
                      >
                        send message
                      </button>
                    </>
                  )}
                </div>
              </div>
              {userInfo &&
                (userInfo.type === "admin" || userInfo.type === "teacher") && (
                  <>
                    <div
                      className="popup"
                      style={
                        passwordDisplayWindow === true
                          ? {
                              display: "flex",
                            }
                          : passwordDisplayWindow === false
                          ? {
                              display: "none",
                            }
                          : ""
                      }
                    >
                      <div className="overlay"></div>
                      <div className="container">
                        <div className="popup-content">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <input
                                type={passwordStatus}
                                placeholder="password *"
                                onChange={passwordHandler}
                                value={password}
                                required
                                style={{ width: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={passwordStatusHandler}
                              >
                                <img
                                  alt=""
                                  src={`/images/vrc-icons-beige-passowrd-${passwordIconStatus}.png`}
                                  style={{ width: "30px" }}
                                />
                              </button>
                            </div>
                            <div style={{ position: "relative" }}>
                              <input
                                type={confirmedPasswordStatus}
                                placeholder="confirm password *"
                                onChange={confirmedPasswordHandler}
                                value={confirmedPassword}
                                required
                                style={{ width: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                onClick={confirmedPasswordStatusHandler}
                              >
                                <img
                                  alt=""
                                  src={`/images/vrc-icons-beige-passowrd-${confirmedPasswordIconStatus}.png`}
                                  style={{ width: "30px" }}
                                />
                              </button>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "50px",
                              gap: "10px",
                            }}
                          >
                            <button
                              className="btn message-btn"
                              onClick={resetPassword}
                            >
                              send
                            </button>
                            <button
                              className="btn message-btn"
                              onClick={hidePasswordMenu}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div
                        className="user-profile"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn send-message"
                          onClick={displayPasswordMenu}
                          style={{
                            position: "relative",
                            top: "auto",
                            right: "auto",
                            transform: "translateY(0)",
                          }}
                        >
                          reset password
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </>
        )
      )}
    </>
  );
};

export default TeacherScreen;
