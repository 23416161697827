import axios from "axios";

import {
  EXPERIENCE_LIST_REQUEST,
  EXPERIENCE_LIST_SUCCESS,
  EXPERIENCE_LIST_FAIL,
  EXPERIENCE_DETAILS_REQUEST,
  EXPERIENCE_DETAILS_SUCCESS,
  EXPERIENCE_DETAILS_FAIL,
  EXPERIENCE_DELETE_REQUEST,
  EXPERIENCE_DELETE_SUCCESS,
  EXPERIENCE_DELETE_FAIL,
  EXPERIENCE_SET_NAME_REQUEST,
  EXPERIENCE_SET_NAME_SUCCESS,
  EXPERIENCE_SET_NAME_FAIL,
  EXPERIENCE_SET_HERO_REQUEST,
  EXPERIENCE_SET_HERO_SUCCESS,
  EXPERIENCE_SET_HERO_FAIL,
  EXPERIENCE_SET_BANNER_REQUEST,
  EXPERIENCE_SET_BANNER_SUCCESS,
  EXPERIENCE_SET_BANNER_FAIL,
  EXPERIENCE_SET_GALLERY_THUMBNAIL_REQUEST,
  EXPERIENCE_SET_GALLERY_THUMBNAIL_SUCCESS,
  EXPERIENCE_SET_GALLERY_THUMBNAIL_FAIL,
  EXPERIENCE_SET_NUM_PURCHASED_REQUEST,
  EXPERIENCE_SET_NUM_PURCHASED_SUCCESS,
  EXPERIENCE_SET_NUM_PURCHASED_FAIL,
  EXPERIENCE_ADD_REVIEW_REQUEST,
  EXPERIENCE_ADD_REVIEW_SUCCESS,
  EXPERIENCE_ADD_REVIEW_FAIL,
  EXPERIENCE_VALIDATE_REQUEST,
  EXPERIENCE_VALIDATE_SUCCESS,
  EXPERIENCE_VALIDATE_FAIL,
} from "../constants/experienceConstants";

// get all experiences
export const listExperiences = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPERIENCE_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/experiences`);

    dispatch({
      type: EXPERIENCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get single experience details
export const listExperienceDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/experiences/${id}`, config);

    dispatch({
      type: EXPERIENCE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// delete experience
export const deleteExperience = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/experiences/${id}`, config);

    dispatch({
      type: EXPERIENCE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// request experience validation from admin
export const requestExperienceValidate =
  (experience, stamp) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_VALIDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/experiences/requestValidation`,
        { experience, stamp },
        config,
      );

      dispatch({
        type: EXPERIENCE_VALIDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_VALIDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// set experience name
export const setExperienceName = (id, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_SET_NAME_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/experiences/name`,
      { id, name },
      config,
    );

    dispatch({
      type: EXPERIENCE_SET_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_SET_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// set experience hero
export const setExperienceHero = (id, hero) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_SET_HERO_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/experiences/hero`,
      { id, hero },
      config,
    );

    dispatch({
      type: EXPERIENCE_SET_HERO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_SET_HERO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// set experience banner
export const setExperienceBanner =
  (id, banner) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_SET_BANNER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/experiences/banner`,
        { id, banner },
        config,
      );

      dispatch({
        type: EXPERIENCE_SET_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_SET_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// set experience gallery thumbnail
export const setGalleryThumbnail =
  (id, thumbnail, index) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_SET_GALLERY_THUMBNAIL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/experiences/thumbnail`,
        { id, thumbnail, index },
        config,
      );

      dispatch({
        type: EXPERIENCE_SET_GALLERY_THUMBNAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_SET_GALLERY_THUMBNAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// add experience review
export const addExperienceReview =
  (experienceId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXPERIENCE_ADD_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `/api/experiences/review`,
        { id: experienceId, review },
        config,
      );

      dispatch({
        type: EXPERIENCE_ADD_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPERIENCE_ADD_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// increase experience purchase count
export const setExperienceNumPurchased = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPERIENCE_SET_NUM_PURCHASED_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/experiences/purchase`,
      { id },
      config,
    );

    dispatch({
      type: EXPERIENCE_SET_NUM_PURCHASED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_SET_NUM_PURCHASED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
