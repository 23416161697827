import axios from "axios";

import {
  HOST_CREATE_REQUEST,
  HOST_CREATE_SUCCESS,
  HOST_CREATE_FAIL,
  HOST_DETAILS_REQUEST,
  HOST_DETAILS_SUCCESS,
  HOST_DETAILS_FAIL,
  HOST_LIST_REQUEST,
  HOST_LIST_SUCCESS,
  HOST_LIST_FAIL,
} from "../constants/hostConstants";

// create a host
export const createNewHost = (host) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HOST_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/hosts`, { host }, config);

    dispatch({
      type: HOST_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HOST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get single host details
export const checkFinancialProfile =
  (partnerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HOST_DETAILS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/hosts/${partnerId}`, config);

      dispatch({
        type: HOST_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: HOST_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// get all hosts
export const listAllHosts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HOST_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/hosts`, config);

    dispatch({
      type: HOST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HOST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
