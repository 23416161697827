import {
  EXPERIENCE_LIST_REQUEST,
  EXPERIENCE_LIST_SUCCESS,
  EXPERIENCE_LIST_FAIL,
  EXPERIENCE_DETAILS_REQUEST,
  EXPERIENCE_DETAILS_SUCCESS,
  EXPERIENCE_DETAILS_FAIL,
  EXPERIENCE_VALIDATE_REQUEST,
  EXPERIENCE_VALIDATE_SUCCESS,
  EXPERIENCE_VALIDATE_FAIL,
  EXPERIENCE_VALIDATE_RESET,
  EXPERIENCE_DELETE_REQUEST,
  EXPERIENCE_DELETE_SUCCESS,
  EXPERIENCE_DELETE_FAIL,
  EXPERIENCE_DELETE_RESET,
  EXPERIENCE_SET_NAME_REQUEST,
  EXPERIENCE_SET_NAME_SUCCESS,
  EXPERIENCE_SET_NAME_FAIL,
  EXPERIENCE_SET_HERO_REQUEST,
  EXPERIENCE_SET_HERO_SUCCESS,
  EXPERIENCE_SET_HERO_FAIL,
  EXPERIENCE_SET_BANNER_REQUEST,
  EXPERIENCE_SET_BANNER_SUCCESS,
  EXPERIENCE_SET_BANNER_FAIL,
  EXPERIENCE_SET_GALLERY_THUMBNAIL_REQUEST,
  EXPERIENCE_SET_GALLERY_THUMBNAIL_SUCCESS,
  EXPERIENCE_SET_GALLERY_THUMBNAIL_FAIL,
  EXPERIENCE_SET_NUM_PURCHASED_REQUEST,
  EXPERIENCE_SET_NUM_PURCHASED_SUCCESS,
  EXPERIENCE_SET_NUM_PURCHASED_FAIL,
  EXPERIENCE_LIST_RESET,
  EXPERIENCE_ADD_REVIEW_REQUEST,
  EXPERIENCE_ADD_REVIEW_SUCCESS,
  EXPERIENCE_ADD_REVIEW_FAIL,
} from "../constants/experienceConstants";

// all experiences
export const experienceListReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case EXPERIENCE_LIST_REQUEST:
      return {
        loading: true,
        experiences: [],
      };
    case EXPERIENCE_LIST_SUCCESS:
      return {
        loading: false,
        experiences: action.payload,
      };
    case EXPERIENCE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case EXPERIENCE_LIST_RESET:
      return {
        experiences: [],
      };
    default:
      return state;
  }
};

// single experience details
export const experienceDetailsReducer = (
  state = {
    loading: true,
    experience: {
      description: [],
      coreCompetencies: [],
      curriculumCompetencies: [],
      about: [],
      features: [],
      priorKnowledge: [],
      reviews: [],
      addOns: [],
    },
  },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case EXPERIENCE_DETAILS_SUCCESS:
      return {
        loading: false,
        experience: action.payload,
      };
    case EXPERIENCE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// request experience validation from admin
export const experienceRequestValidateReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_VALIDATE_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_VALIDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case EXPERIENCE_VALIDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case EXPERIENCE_VALIDATE_RESET:
      return {};
    default:
      return state;
  }
};

// delete experience
export const experienceDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_DELETE_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case EXPERIENCE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case EXPERIENCE_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case EXPERIENCE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

// set experience name
export const experienceSetNameReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_SET_NAME_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_SET_NAME_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case EXPERIENCE_SET_NAME_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set experience name
export const experienceAddReviewReducer = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case EXPERIENCE_ADD_REVIEW_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_ADD_REVIEW_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case EXPERIENCE_ADD_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set experience hero visual
export const experienceSetHeroReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_SET_HERO_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_SET_HERO_SUCCESS:
      return {
        loading: false,
        file: action.payload,
      };
    case EXPERIENCE_SET_HERO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set experience banner
export const experienceSetBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_SET_BANNER_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_SET_BANNER_SUCCESS:
      return {
        loading: false,
        file: action.payload,
      };
    case EXPERIENCE_SET_BANNER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set experience gallery thumbnail by index
export const experienceSetGalleryThumbnailReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_SET_GALLERY_THUMBNAIL_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_SET_GALLERY_THUMBNAIL_SUCCESS:
      return {
        loading: false,
        file: action.payload,
      };
    case EXPERIENCE_SET_GALLERY_THUMBNAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// set experience gallery thumbnail by index
export const experienceSetNumPurchasedReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPERIENCE_SET_NUM_PURCHASED_REQUEST:
      return {
        loading: true,
      };
    case EXPERIENCE_SET_NUM_PURCHASED_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case EXPERIENCE_SET_NUM_PURCHASED_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
