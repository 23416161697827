import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { adminDeleteUser } from "../actions/adminActions";
import { listUsers } from "../actions/userActions";
import { listAllHosts } from "../actions/hostActions";

import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AllHostsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // add hostListReducer
  const hostList = useSelector((state) => state.hostList);
  const { loading, error, hosts } = hostList;

  const userList = useSelector((state) => state.userList);
  const { loading: loadingPtr, error: errorPtr, users } = userList;

  useEffect(() => {
    if (
      !userInfo ||
      (userInfo.type !== "admin" && userInfo.type !== "reviewer")
    ) {
      history.push("/");
    } else {
      dispatch(listAllHosts());
      dispatch(listUsers("partner"));
    }
  }, [dispatch, history, userInfo]);

  const [remaining, setRemaining] = useState([]);

  useEffect(() => {
    if (users && hosts) {
      setRemaining(
        users.filter(
          (user) => !hosts.map((host) => host.partner).includes(user._id),
        ),
      );
    }
  }, [users, hosts]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : hosts && hosts?.length ? (
          <>
            <>
              <h5 style={{ marginBottom: "20px" }}>
                all hosts ({hosts.length})
              </h5>
              <div className="user-list">
                {hosts
                  .sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((host) => (
                    <div
                      style={{ display: "flex", gap: "10px" }}
                      key={host._id}
                    >
                      <Link
                        to={`/partner/${host.partner}`}
                        className="btn"
                        style={{ flexGrow: "1" }}
                      >
                        <span>{host._id}</span>
                        <span>{host.name}</span>
                        <span>{host.category}</span>
                      </Link>
                    </div>
                  ))}
              </div>
            </>
            <>
              <h5 style={{ margin: "20px 0" }}>
                remaining partners ({remaining.length})
              </h5>
              <div className="user-list">
                {loadingPtr ? (
                  <Loader />
                ) : errorPtr ? (
                  <Message body={errorPtr} />
                ) : (
                  remaining
                    .sort(function (a, b) {
                      if (
                        a.head.name.toLowerCase() < b.head.name.toLowerCase()
                      ) {
                        return -1;
                      }
                      if (
                        a.head.name.toLowerCase() > b.head.name.toLowerCase()
                      ) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((user) => (
                      <div
                        style={{ display: "flex", gap: "10px" }}
                        key={user._id}
                      >
                        <Link
                          to={`/${user.type}/${user._id}`}
                          className="btn"
                          style={{ flexGrow: "1" }}
                        >
                          <span>{user._id}</span>
                          <span>{user.head.name}</span>
                          <span>{user.category}</span>
                        </Link>
                      </div>
                    ))
                )}
              </div>
            </>
          </>
        ) : (
          <p className="no-messages">No hosts available</p>
        )}
      </div>
    </div>
  );
};

export default AllHostsScreen;
