import React from "react";

import SidebarItem from "./SidebarItem";

const Sidebar = ({ collection }) => {
  return (
    <div className="related-sidebar">
      <div className="sidebar-collection">
        {collection
          ? collection?.length
            ? collection.map((item, index) => (
                <SidebarItem
                  key={index}
                  thumbnail={item.banner}
                  id={item._id}
                  name={item.name}
                  city={item.location.city}
                  province={item.location.province
                    .split(" ")
                    .map((ele) => ele[0])}
                />
              ))
            : ""
          : ""}
      </div>
    </div>
  );
};

export default Sidebar;
