import React from "react";
import { Link } from "react-router-dom";

const SidebarItem = ({ thumbnail, name, city, province, id }) => {
  return (
    <Link className="sidebar-item" to={`/experience/${id}`} title={name}>
      <div
        className="img"
        style={{
          backgroundImage: thumbnail
            ? `url(${thumbnail})`
            : "/images/avatar-experience.png",
        }}
      >
        <div className="overlay"></div>
      </div>
      <div className="text">
        <h6>
          {name.substring(0, 20)}
          {name.length > 20 && "..."}
        </h6>
        <span>
          {city
            .split("")
            .map((letter, index) =>
              index === 0 ? letter.toUpperCase() : letter
            )
            .join("")}{" "}
          | {province}
        </span>
      </div>
    </Link>
  );
};

export default SidebarItem;
