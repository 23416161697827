import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import FooterContent from "./FooterContent";

const Footer = ({ history }) => {
  const [element, setElement] = useState(<FooterContent />);

  useEffect(() => {
    if (
      window.location.pathname.split("/")[1] !== "messages" &&
      window.location.pathname.split("/")[1] !== "new-newsletter"
    ) {
      setElement(<FooterContent />);
    } else {
      setElement(null);
    }

    const unlisten = history.listen(() => {
      if (
        window.location.pathname.split("/")[1] !== "messages" &&
        window.location.pathname.split("/")[1] !== "new-newsletter"
      ) {
        setElement(<FooterContent />);
      } else {
        setElement(null);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return element;
};

export default withRouter(Footer);
