import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import Message from "../components/Message";

import { listUserDetails, updateUser } from "../actions/userActions";
import { Link } from "react-router-dom";
import { USER_UPDATE_RESET } from "../constants/userConstants";

const TeacherEditScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success } = userUpdate;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [name, setName] = useState(``);
  const [phone, setPhone] = useState(``);
  const [website, setWebsite] = useState(``);
  const [city, setCity] = useState(``);
  const [country, setCountry] = useState(``);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const phoneHandler = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  const websiteHandler = (e) => {
    e.preventDefault();
    setWebsite(e.target.value);
  };

  const cityHandler = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const countryHandler = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      name?.length &&
      phone?.length &&
      website?.length &&
      city?.length &&
      country?.length
    ) {
      const fields = {
        type: userInfo.type,
        name,
        phone,
        website,
        city,
        country,
      };

      dispatch(updateUser(fields, userInfo._id, userInfo.type));
    }
  };

  useEffect(() => {
    if (!user) {
      dispatch(
        listUserDetails(match.url.split("/").reverse()[1], userInfo.type),
      );
    }
  }, [dispatch, match, user, userInfo]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET });
        history.push(`/${userInfo.type}/${userInfo._id}`);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [history, success]);

  useEffect(() => {
    if (user) {
      setName(user.head.name);
      setPhone(user.phone);
      setWebsite(user.website);
      setCity(user.city);
      setCountry(user.country);
    }
  }, [user]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      {loading && <Loader />}
      {loadingUpdate && <Loader />}
      {error && <Message body={error} />}
      {errorUpdate && <Message body={errorUpdate} />}
      {success && <Message body={"User updated successfully!"} type="info" />}
      {user && (
        <div className="container" style={{ paddingTop: "40px" }}>
          <h3>Edit {userInfo.type} Profile Information</h3>
          <form style={{ paddingTop: "20px" }} onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="name *"
              onChange={nameHandler}
              value={name}
              required
            />
            <input
              type="number"
              placeholder="phone *"
              onChange={phoneHandler}
              value={phone}
              required
            />
            <input
              type="text"
              placeholder="website *"
              onChange={websiteHandler}
              value={website}
              required
            />
            <input
              type="text"
              placeholder="city *"
              onChange={cityHandler}
              value={city}
              required
            />
            <input
              type="text"
              placeholder="country *"
              onChange={countryHandler}
              value={country}
              required
            />
            <button className="btn" type="submit" onSubmit={submitHandler}>
              submit changes
            </button>
            <Link
              className="btn send-message"
              to={`/${userInfo.type}/${userInfo._id}`}
              style={{
                position: "relative",
                width: "fit-content",
              }}
            >
              back to profile
            </Link>
          </form>
        </div>
      )}
    </div>
  );
};

export default TeacherEditScreen;
