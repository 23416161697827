import {
  GET_DISCOUNT_CODE_REQUEST,
  GET_DISCOUNT_CODE_SUCCESS,
  GET_DISCOUNT_CODE_FAIL,
  GET_DISCOUNT_CODE_RESET,
  BOOKING_SET_REQUEST,
  BOOKING_SET_SUCCESS,
  BOOKING_SET_FAIL,
  BOOKING_SET_RESET,
  BOOKING_DELETE_REQUEST,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_DELETE_RESET,
  BOOKING_DETAILS_REQUEST,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_DETAILS_FAIL,
  BOOKING_DETAILS_RESET,
  BOOKING_CONFIRM_REQUEST,
  BOOKING_CONFIRM_SUCCESS,
  BOOKING_CONFIRM_FAIL,
  BOOKING_CONFIRM_RESET,
  BOOKING_DECLINE_REQUEST,
  BOOKING_DECLINE_SUCCESS,
  BOOKING_DECLINE_FAIL,
  BOOKING_DECLINE_RESET,
  BOOKING_PAY_REQUEST,
  BOOKING_PAY_SUCCESS,
  BOOKING_PAY_FAIL,
  BOOKING_PAY_RESET,
  BOOKING_PAY_LATER_REQUEST,
  BOOKING_PAY_LATER_SUCCESS,
  BOOKING_PAY_LATER_FAIL,
  BOOKING_PAY_LATER_RESET,
  BOOKING_MARK_PAID_REQUEST,
  BOOKING_MARK_PAID_SUCCESS,
  BOOKING_MARK_PAID_FAIL,
  BOOKING_MARK_PAID_RESET,
  BOOKING_LIST_MY_BOOKINGS_REQUEST,
  BOOKING_LIST_MY_BOOKINGS_SUCCESS,
  BOOKING_LIST_MY_BOOKINGS_FAIL,
  // BOOKING_LIST_MY_BOOKINGS_RESET,
  BOOKING_LIST_ALL_BOOKINGS_REQUEST,
  BOOKING_LIST_ALL_BOOKINGS_SUCCESS,
  BOOKING_LIST_ALL_BOOKINGS_FAIL,
} from "../constants/bookingConstants";

export const discountCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DISCOUNT_CODE_REQUEST:
      return {
        loading: true,
      };
    case GET_DISCOUNT_CODE_SUCCESS:
      return {
        loading: false,
        code: action.payload,
      };
    case GET_DISCOUNT_CODE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_DISCOUNT_CODE_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingSetReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_SET_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_SET_SUCCESS:
      return {
        loading: false,
        success: true,
        booking: action.payload,
      };
    case BOOKING_SET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_SET_RESET:
      return {};
    default:
      return state;
  }
};

//
//
//

export const bookingDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_DELETE_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BOOKING_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

//
//
//
export const bookingDetailsReducer = (
  state = { loading: true, booking: null },
  action,
) => {
  switch (action.type) {
    case BOOKING_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BOOKING_DETAILS_SUCCESS:
      return {
        loading: false,
        booking: action.payload,
      };
    case BOOKING_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_CONFIRM_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_CONFIRM_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BOOKING_CONFIRM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_CONFIRM_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingDeclineReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_DECLINE_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_DECLINE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BOOKING_DECLINE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_DECLINE_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingPayReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_PAY_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BOOKING_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_PAY_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingPayLaterReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_PAY_LATER_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_PAY_LATER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BOOKING_PAY_LATER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_PAY_LATER_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingMarkPaidReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_MARK_PAID_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_MARK_PAID_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BOOKING_MARK_PAID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BOOKING_MARK_PAID_RESET:
      return {};
    default:
      return state;
  }
};

export const bookingListMyBookingsReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_LIST_MY_BOOKINGS_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_LIST_MY_BOOKINGS_SUCCESS:
      return {
        loading: false,
        bookings: action.payload,
      };
    case BOOKING_LIST_MY_BOOKINGS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const bookingListAllBookingsReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOKING_LIST_ALL_BOOKINGS_REQUEST:
      return {
        loading: true,
      };
    case BOOKING_LIST_ALL_BOOKINGS_SUCCESS:
      return {
        loading: false,
        bookings: action.payload,
      };
    case BOOKING_LIST_ALL_BOOKINGS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
