import React from "react";

const Benefit = ({ visual, heading, copy }) => {
  return (
    <div
      className="benefit"
      style={
        visual && visual.includes("benefit")
          ? {}
          : {
              width: "fit-content",
            }
      }
    >
      <div
        className="icon"
        style={
          visual && visual.includes("benefit")
            ? {
                padding: "20px",
                marginBottom: "30px",
              }
            : {}
        }
      >
        <span
          className="benefit-img"
          style={
            visual && visual.includes("benefit")
              ? {
                  backgroundImage: `url("/images/${visual}.png")`,
                }
              : {
                  backgroundImage: `url(${
                    visual && visual?.length
                      ? visual
                      : "/images/avatar-user.png"
                  })`,
                  backgroundColor: "var(--var-color-vrc-grey)",
                  borderRadius: "50px",
                }
          }
        ></span>
      </div>
      {heading && <h4>{heading}</h4>}
      {copy && <p>{copy}</p>}
    </div>
  );
};

export default Benefit;
