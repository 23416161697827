import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addNewMessage,
  createNewConversation,
} from "../actions/connectActions";
import { listUserDetails } from "../actions/userActions";

import {
  CONV_CREATE_NEW_RESET,
  MSG_ADD_NEW_RESET,
} from "../constants/connectConstants";

import Loader from "../components/Loader";
import Message from "../components/Message";

const ReviewerScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createConversation = useSelector((state) => state.createConversation);
  const { conversation } = createConversation;

  const newMessage = useSelector((state) => state.newMessage);
  const { message: nMessage } = newMessage;

  const [messageDisplayWindow, setMessageDisplayWindow] = useState(false);
  const [message, setMessage] = useState(``);

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const addMessage = (e) => {
    e.preventDefault();
    if (message?.length) {
      dispatch(
        createNewConversation(userInfo._id, user._id, userInfo.type, user.type),
      );
    }
  };

  useEffect(() => {
    if (conversation && message?.length) {
      dispatch(addNewMessage(conversation._id, userInfo._id, message));
      setMessage(``);
      setMessageDisplayWindow(false);
    }
    // eslint-disable-next-line
  }, [dispatch, conversation]);

  useEffect(() => {}, [message]);

  useEffect(() => {
    if (nMessage) {
      dispatch({ type: CONV_CREATE_NEW_RESET });
      dispatch({ type: MSG_ADD_NEW_RESET });
    }
    // eslint-disable-next-line
  }, [dispatch, nMessage]);

  const displayMessageMenu = (e) => {
    e.preventDefault();
    setMessage(``);
    setMessageDisplayWindow(true);
  };

  const hideMessageMenu = (e) => {
    e.preventDefault();
    setMessageDisplayWindow(false);
    setMessage(``);
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/account");
    } else {
      if (
        match.params.id &&
        match.params.id?.length &&
        match.url.split("/")[1] &&
        match.url.split("/")[1]?.length
      ) {
        dispatch(listUserDetails(match.params.id, match.url.split("/")[1]));
      }
    }
  }, [userInfo, history, match, dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message body={error} />
  ) : (
    user && (
      <>
        <div className="user">
          <div
            className="banner"
            style={{
              backgroundImage: user.head.banner?.length
                ? `url(${user.head.banner}`
                : "url(/images/user-hero.png)",
            }}
          ></div>
          <div className="container">
            <div className="user-profile">
              <div
                style={
                  window.innerWidth < 835
                    ? { left: "50%", transform: "translateX(-50%)" }
                    : {}
                }
                className="user-logo"
              >
                <span
                  className="logo"
                  style={{
                    backgroundImage: `url(${
                      user.head.avatar?.length
                        ? user.head.avatar
                        : "/images/avatar-user.png"
                    })`,
                  }}
                ></span>
              </div>
              <div className="user-title">
                <h2>{user.head.name}</h2>
                <h3>Educator | Vancouver, Canada</h3>
              </div>
              {userInfo && userInfo._id !== user._id && (
                <>
                  <div
                    className="popup"
                    style={
                      messageDisplayWindow === true
                        ? {
                            display: "flex",
                          }
                        : messageDisplayWindow === false
                        ? {
                            display: "none",
                          }
                        : ""
                    }
                  >
                    <div className="overlay"></div>
                    <div className="container">
                      <div className="popup-content">
                        <textarea
                          placeholder="write your message here"
                          onChange={messageHandler}
                          value={message}
                        ></textarea>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "50px",
                            gap: "10px",
                          }}
                        >
                          <button
                            className="btn message-btn"
                            onClick={addMessage}
                          >
                            send
                          </button>
                          <button
                            className="btn message-btn"
                            onClick={hideMessageMenu}
                          >
                            cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn send-message"
                    onClick={displayMessageMenu}
                    style={
                      window.innerWidth < 835
                        ? {
                            position: "relative",
                          }
                        : {}
                    }
                  >
                    send message
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ReviewerScreen;
