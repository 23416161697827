import grades from "./grades";
import experienceSubjects from "./experienceSubjects";

const contents = [
  {
    subject: experienceSubjects[0],
    data: [
      {
        grade: grades[0],
        contents: [
          `Elements in the arts (including but not limited to):
          •Dance: body, dynamics, form, relationships, space, time
          •Drama: character, place, plot, time
          •Music: beat/pulse, dynamics, pitch, rhythm, tempo
          •Visual Arts:
          ○Elements of Design: colour, line, shape, texture
          ○Principles of Design: pattern, repetition`,
          "Materials, movements, processes, techniques, technologies and tools that can be used to support arts activities",
          "Using notation to represent different ideas, movements and sounds",
          "Various dramatic forms",
          "Expressions of meaning through symbolism",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions and local works of art",
          "Individual and group responsibility when creating, experiencing, or sharing in a safe learning environment",
        ],
      },
      {
        grade: grades[1],
        contents: [
          `Elements in the arts (including but not limited to):
          •Dance: body, dynamics, form, relationships, space, time
          •Drama: character, place, plot, time
          •Music: beat/pulse, dynamics, form, pitch, rhythm, tempo
          •Visual Arts: 
          ○Elements of Design: colour, line, shape, texture
          ○Principles of Design: pattern, repetition
          `,
          "Materials, movements, processes, techniques, technologies and tools that can be used to support arts activities",
          "Using notation to represent different ideas, movements and sounds",
          "Various dramatic forms",
          "Expressions of specific meaning through symbolism",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions from a mix of cultures and communities as well as local works of art",
          "Individual and group responsibility when creating, experiencing, or sharing in a safe learning environment",
        ],
      },
      {
        grade: grades[2],
        contents: [
          `Elements in the arts (including but not limited to):
          •Dance: body, dynamics, form, relationships, space, time
          •Drama: character, place, plot, tension, time
          •Music: beat/pulse, duration, dynamics, form, pitch, rhythm, tempo, texture, timbre
          •Visual arts: 
          ○Elements of Design: colour, form, line, shape, texture
          ○Principles of Design: contrast, pattern, repetition, rhythm
          `,
          "Materials, movements, processes, techniques, technologies and tools that can be used to support arts activities",
          "Using notation to represent different ideas, movements and sounds",
          "Various dramatic forms",
          "Expressions of specific meaning through symbolism",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions from a mix of cultures, communities, times and places as well as local works of art",
          "Individual and group responsibility when creating, experiencing, or sharing in a safe learning environment",
        ],
      },
      {
        grade: grades[3],
        contents: [
          `Elements in the arts (including but not limited to):
          •Dance: body, dynamics, form, relationships, space, time
          •Drama: character, place, plot, tension, time
          •Music: beat/pulse, duration, dynamics, form, pitch, rhythm, tempo, texture, timbre
          •Visual arts: 
          ○Elements of Design: colour, form, line, shape, space, texture
          ○Principles of Design: contrast, emphasis, pattern, repetition, rhythm
          `,
          "Materials, movements, processes, techniques, technologies and tools that can be used to support arts activities",
          "Using notation to represent different ideas, movements and sounds",
          "Various dramatic forms",
          "Strategies for developing images",
          "Choreographic devices",
          "Using symbolism to create and represent meaning",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions from a mix of cultures, communities, times and places as well as local works of art",
          "Individual and group responsibility when creating, experiencing, or sharing in a safe learning environment",
        ],
      },
      {
        grade: grades[4],
        contents: [
          `Elements and principles in the arts that combine to create meaning (including but not limited to):
          •Dance: body, dynamics, form, movement principles, relationships, space and time
          •Drama: character, focus, mood, place, plot, tension and time
          •Music: beat/pulse, duration, dynamics, form, pitch, rhythm, tempo, texture and timbre 
          •Visual Arts: 
          ○Elements of Design: colour, form, line, shape, space, texture
          ○Principles of Design: balance, contrast, emphasis, pattern, repetition, rhythm, variety
          `,
          "Materials, movements, processes, techniques, technologies and tools that can be used to support arts activities",
          "Choreographic devices",
          "Various dramatic forms",
          "Using notation to represent different actions, elements, ideas, movements and sounds ",
          "Strategies for developing images",
          "Using metaphor and symbolism to create and represent meaning",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions from a mix of cultures, communities, times and places as well as regional and national works of art",
          "Individual and group responsibility when creating, experiencing, or presenting in a safe learning environment",
        ],
      },
      {
        grade: grades[5],
        contents: [
          `Elements and principles in the arts that combine to create meaning (including but not limited to):
          •Dance: body, dynamics, form, movement principles, relationships, space and time
          •Drama: character, focus, mood, place, plot, tension and time
          •Music: beat/pulse, duration, dynamics, form, meter, pitch, rhythm, tempo, texture and timbre 
          •Visual Arts: 
          ○Elements of Design: colour, form, line, shape, space, texture, value
          ○Principles of Design: balance, contrast, emphasis, harmony, pattern, repetition, rhythm, unity, variety`,
          "Materials, movements, processes, techniques, technologies and tools that can be used to support creative works",
          "Choreographic devices",
          "Various dramatic forms",
          "Using notation in music and dance to represent different actions, elements, ideas, movements and sounds",
          "Strategies for developing images",
          "Using metaphor and symbolism to explore ideas and perspective",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions from a mix of cultures, communities, times and places as well as regional and national works of art",
          "Individual and group responsibility when creating, experiencing, or presenting in a safe learning environment",
        ],
      },
      {
        grade: grades[6],
        contents: [
          `Purposeful use of elements and principles in the arts that combine to create meaning (including but not limited to):
          •Dance: body, dynamics, form, movement principles, relationships, space and time
          •Drama: character, contrast, focus, mood, place, plot, tension and time
          •Music: beat/pulse, duration, dynamics, form, meter, pitch, rhythm, tempo, texture and timbre 
          •Visual Arts: 
          ○Elements of Design: colour, form, line, shape, space, texture, value
          ○Principles of Design: balance, contrast, emphasis, harmony, pattern, repetition, rhythm, unity, variety`,
          "Materials, movements, processes, strategies, techniques, technologies and tools that can be used to support creative works",
          "Choreographic devices",
          "Various dramatic forms",
          "Using notation in music and dance to represent different actions, elements, ideas, movements and sounds",
          "Strategies for developing images",
          "Using metaphor and symbolism to explore ideas and perspective",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various artistic traditions from a mix of cultures, communities, times and places as well as national and international works of art",
          "Individual and group responsibility when creating, experiencing, or presenting in a safe learning environment",
        ],
      },
      {
        grade: grades[7],
        contents: [
          `Manipulation of elements and principles in the arts that combine to create meaning (including but not limited to):
          •Dance: body, dynamics, form, movement principles, relationships, space and time
          •Drama: character, contrast, focus, mood, place, plot, tension and time
          •Music: beat/pulse, duration, dynamics, form, meter, notation, pitch, rhythm, tempo, texture and timbre 
          •Visual Arts: 
          ○Elements of Design: colour, form, line, shape, space, texture, value
          ○Principles of Design: balance, contrast, emphasis, harmony, pattern, proportion, repetition, rhythm, unity, variety`,
          "Materials, movements, processes, strategies, techniques, technologies and tools that can be used to support creative works",
          "Choreographic devices",
          "Forms and conventions of drama",
          "Using notation in music and dance to represent different actions, elements, ideas, movements and sounds",
          "Strategies for developing images",
          "Using metaphor and symbolism to explore ideas and perspective",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various art and artistic traditions from a mix of cultures, communities, times and places as well as national and international works of art",
          "Ethics of art and cultural appropriation",
          "Individual and group responsibility when creating, experiencing, or presenting in a safe learning environment",
        ],
      },
      {
        grade: grades[8],
        contents: [
          `Manipulation of elements, principles and design strategies in the arts that combine to create mood and convey ideas (including but not limited to):
          •Dance: body, dynamics, form, movement principles, relationships, space and time
          •Drama: balance, character, contrast, focus, mood, place, plot, tension and time
          •Music: beat/pulse, duration, dynamics, form, meter, notation, pitch, rhythm, tempo, texture and timbre 
          •Visual Arts: 
          ○Elements of Design: colour, form, line, shape, space, texture, value
          ○Principles of Design: balance, contrast, emphasis, harmony, pattern, proportion, repetition, rhythm, unity, variety`,
          "Materials, movements, processes, strategies, techniques, technologies and tools that can be used to support creative works",
          "Choreographic devices",
          "Forms and conventions of drama",
          "Using notation in music, dance and drama to represent different actions, elements, ideas, movements and sounds",
          "Strategies for developing images",
          "Using metaphor and symbolism to explore ideas and perspective",
          "Aboriginal arts and arts-making processes, both traditional and contemporary",
          "Exploring various art and artistic traditions from a mix of cultures, communities, times and places as well as national and international works of art",
          "Ethics of art and cultural appropriation",
          "Individual and group responsibility when creating, experiencing, or presenting in a safe learning environment",
        ],
      },
      {
        grade: grades[9],
        contents: [
          "The specific elements, principles, symbols, techniques and vocabulary used to create mood and convey ideas in dance, drama, music, and visual arts",
          "Performers’ and audiences’ roles in various contexts",
          "Exploring cross-cultural perspectives and Aboriginal worldviews, both traditional and contemporary, and how they can be communicated through artistic works",
          "Innovative artists’ contributions across a variety of genres, communities, times, and places",
          "The role of personal and social responsibility when creating, performing, and responding to the arts",
          "Ethical considerations around cultural appropriation and plagiarism",
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[1],
    data: [
      {
        grade: grades[0],
        contents: [
          "The basic needs of animals and plants",
          "How local animals and plants have adapted",
          "How local First Peoples use animals and plants",
        ],
      },
      {
        grade: grades[1],
        contents: [
          "Ways people classify living and nonliving things",
          "The names of local animals and plants",
          "The structural features of the local environment’s living things",
          "How animals have adapted their behaviours in the local environment",
        ],
      },
      {
        grade: grades[2],
        contents: [
          "The life cycles of different kinds of organisms, both metamorphic and non-metamorphic",
          "Ways that parents and their offspring can be similar or different",
          "How First Peoples use what they know about life cycles",
        ],
      },
      {
        grade: grades[3],
        contents: [
          "Finding biodiversity in the local environment",
          "Things the local First Peoples know about ecosystems",
          "Life requires energy",
        ],
      },
      {
        grade: grades[4],
        contents: [
          "Sensing and responding in plants, humans and other animals",
          "The concept of biomes (large regions that share similar environmental features)",
        ],
      },
      {
        grade: grades[5],
        contents: [
          `The basics of body systems, how they look and what they do, including:
          •Digestive system
          •Musculoskeletal system
          •Respiratory system
          •Circulatory system`,
        ],
      },
      {
        grade: grades[6],
        contents: [
          `The basics of body systems, how they look and what they do, including:
          •Excretory system
          •Reproductive system
          •Hormonal system
          •Nervous system
          `,
        ],
      },
      {
        grade: grades[7],
        contents: [
          "Over time, organisms have evolved",
          "The needs required for survival",
          "Natural selection",
        ],
      },
      {
        grade: grades[8],
        contents: [
          "The characteristics of life",
          "Cell theory",
          "Types of cells",
          "Photosynthesis and cellular respiration",
          `How microorganisms relate to  living things:
          •The immune system and its basic functions
          •Vaccination and antibiotics
          •How epidemics and pandemics affect human populations
          `,
        ],
      },
      {
        grade: grades[9],
        contents: [
          `Asexual reproduction, including:
          •Mitosis
          •Other forms
          `,
          `Sexual reproduction, including:
          •Meiosis
          •Sexual reproduction of humans
          `,
        ],
      },
      {
        grade: grades[10],
        contents: [
          "The function and structure of DNA",
          "Inheritance patterns",
          `Mechanisms that support the diversity of life:
          •Mutation and how it affects evolution
          •Natural and artificial selection
          `,
          "How genetics gets used, and ethical considerations for those who use it",
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[2],
    data: [
      {
        grade: grades[0],
        contents: [
          "Changes in weather",
          "Changes in the seasons",
          "Living things change throughout the day and seasons to accommodate their cycles",
          "Things First Peoples know about the changing seasons",
        ],
      },
      {
        grade: grades[1],
        contents: [
          "Objects that can commonly be found in the sky",
          `Things First Peoples know about:
          •The sky
          •The local landscape, plants and animals
          •Seasonal rounds
          `,
          "Patterns on Earth and in the sky that we can observe in the local area",
        ],
      },
      {
        grade: grades[2],
        contents: [
          "Sources of water, including local watersheds",
          "Conserving water",
          "The water cycle",
          `Things local First Peoples know about water, including:
          •Water cycles
          •Conservation
          •How water is connected to other systems
          `,
        ],
      },
      {
        grade: grades[3],
        contents: [
          "Major landforms in the local area",
          "What local First Peoples know about landforms in the local area",
          "How erosion and deposition by wind, water and ice create observable changes in the local environment",
        ],
      },
      {
        grade: grades[4],
        contents: [
          "How Earth’s axis, rotation and orbit cause changes in the local area",
          "How the relative positions of the sun, moon, and Earth affect us, including the perspectives of local First Peoples",
        ],
      },
      {
        grade: grades[5],
        contents: [
          "The rock cycle",
          "Earth materials that can be found locally",
          "How First Peoples understand interconnectedness in the environment",
          "Sustainable practices for BC’s resources",
          "What First Peoples know about sustainable practices",
        ],
      },
      {
        grade: grades[6],
        contents: [
          "The universe: its overall scale, structure and age",
          "Our solar system: its components, motion and position within our galaxy",
        ],
      },
      {
        grade: grades[7],
        contents: [
          "Evidence for changes in biodiversity over geological time stored in the fossil record",
          "What First Peoples know about changes in biodiversity over time",
          `Ways we can identify the effects of humans and climate change, both recently and over geological time:
          •Physical records
          •How local First Peoples understand climate change
          `,
        ],
      },
      {
        grade: grades[8],
        contents: [
          "The movements involved in plate tectonics",
          "Significant/major geological events in the local area",
          `Things First Peoples know about:
          •Geological formations in the local area
          •Significant geological events in the local area
          `,
          "Earth’s layers",
        ],
      },
      {
        grade: grades[9],
        contents: [
          "How solar radiation affects the cycling of matter and energy",
          "Biotic and abiotic components of ecosystems and their matter cycles",
          "How sustainable systems are",
          "What First Peoples know about interconnectedness and sustainability",
        ],
      },
      {
        grade: grades[10],
        contents: [
          `How the universe was formed
          •The Big Bang theory
          •Parts of the universe over time
          `,
          "Astronomical data and collection methods",
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[3],
    data: [
      {
        grade: grades[0],
        contents: [
          "How individual people and families can be different and the same",
          "People and their families have history and traditions",
          "Individual people and families’ wants and needs",
          "Individual people and groups have rights, roles and responsibilities",
          "Local (and local First Peoples communities') people, places and events",
        ],
      },
      {
        grade: grades[1],
        contents: [
          "How local communities organize and meet community needs",
          "How a variety of backgrounds, cultures and perspectives make up communities (local and other)",
          "How a community is related to its environment",
          "Roles, rights and responsibilities within the local community",
          "The local community’s most important events and developments, including those in local First Peoples communities",
          "Parts of the local surroundings that are natural, and parts that were made by humans",
        ],
      },
      {
        grade: grades[2],
        contents: [
          "Canadian and international cultures and the varied characteristics of their communities, including a minimum of one Canadian First peoples community",
          "The ways communities meet people’s wants and needs",
          "How people and the environments are related to each other in different communities",
          "The variety of environmental features we can find in Canada and around the world",
          "Individual peoples’ rights and responsibilities, both regionally and around the world",
          "Regional governments’ roles and responsibilities",
        ],
      },
      {
        grade: grades[3],
        contents: [
          "How local First Peoples and Indigenous people around the world live, and some characteristics of their cultures",
          "Peoples’ and cultures’ shared aspects of life",
          "Local and global Indigenous peoples’ innovations when it comes to culture and technology, and how these are interconnected",
          "How local and global Indigenous societies are organized and governed",
          "Learning about past First Peoples cultures through artifacts, oral history and traditional stories",
          "How humans and their environment affect each other",
        ],
      },
      {
        grade: grades[4],
        contents: [
          "Early contact between First Peoples and European peoples, and the resulting trade, cooperation and conflict",
          "The fur trade and its role in British Columbia and Canada before Confederation",
          "Shifts in the demographics of British Columbia before Confederation, including (but not exclusively) those in First Peoples communities",
          "Why and how British Columbia was colonized and entered into Confederation, with a focus on economic and political factors",
          "How colonization affected First Peoples in British Columbia and Canada",
          "Local history, including local First Peoples communities",
          "Canada’s natural resources and physiographic features",
        ],
      },
      {
        grade: grades[5],
        contents: [
          "How Canadian identity has changed and developed over time",
          "How immigration to Canada has changed over time",
          "How governments have discriminated against specific populations in the past, looking at the Chinese Head Tax, Komagata Maru, internments and residential schools",
          "Canada’s (and Canadians’) understanding of human rights and response to discrimination",
          "What the different levels of government (First Peoples, federal, provincial, and municipal) do and how they are funded",
          "How citizens can participate in and be represented by the Canadian government",
          "Economic development and resources in various Canadian regions",
          "The land ownership and use of First Peoples",
        ],
      },
      {
        grade: grades[6],
        contents: [
          "Population migration and urbanization",
          "Class, gender and issues of poverty and inequality around the world",
          "How roles differ for individual people, governmental organizations and NGOs (including groups representing Indigenous peoples)",
          "Types of systems of government",
          "Management of resources, economic policies, and how those things affect Indigenous peoples",
          "Globalization and how nations depend on each other from an economic perspective",
          "How nations cooperate and respond to global issues",
          "Regional and international conflict",
          "Current events, media coverage and technologies",
        ],
      },
      {
        grade: grades[7],
        contents: [
          "Where humans come from, from an anthropological perspective",
          "How humans have responded to the challenges and opportunities afforded by the land around them, including climates, landforms and natural resources",
          "Civilizations’ characteristics, features and the reasons for their rise and fall",
          "Religions: where they come from, what they practice, what they believe, their stories and their influences (including a minimum of one religion Indigenous to the Americas)",
          "Developments in science, philosophy and technology",
          "How past civilization and cultures interacted, including conflict and peace, trade, expansion and migration",
          "Systems and structures (including a minimum of one Indigenous to the Americas): economic, governmental, legal, political and social",
        ],
      },
      {
        grade: grades[8],
        contents: [
          "A variety of social, political and economic systems and structures (including a minimum of one Indigenous civilization)",
          "Innovations in science and technology",
          "Shifts in philosophy and culture",
          "How civilizations interacted internally and with other civilizations, including the exchange of arts, culture, ideas and resources",
          "Exploration, expansion, and colonization",
          "How population and living standards are changing",
        ],
      },
      {
        grade: grades[9],
        contents: [
          "Revolutions (economic, political, social and technological)",
          "How imperialism and colonialism continue to affect Indigenous peoples in Canada and around the world",
          "Demographic shifts around the world, including migration patterns and growth of the global population",
          "Nationalism and how modern nation-states (including Canada) developed",
          "Conflicts (local, regional and international)",
          "Injustice and discrimination in Canada and globally, looking at the Chinese Head Tax, Komagata Maru, internments and residential schools",
          "Canada’s natural resources and physiographic features",
        ],
      },
      {
        grade: grades[10],
        contents: [
          "Government and ideologies, including political institutions and First Peoples governance",
          "Economic, environmental and political policies",
          "Canadian autonomy and identities",
          "Injustice and discrimination in Canada and globally, looking at the Chinese Head Tax, Komagata Maru, internments and residential schools",
          "Advocating for human rights",
          "The Truth and Reconciliation Commission, including its findings and recommendations",
          "Conflicts and cooperation within Canada and internationally",
        ],
      },
    ],
  },
];

export default contents;

/*
<div>
        {contents[0].data[0].contents.map((lll, indd) => (
          <div
            key={indd}
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <input
              type="checkbox"
              id={indd}
              style={{ height: "fit-content", margin: "10px" }}
            ></input>
            <label htmlFor={indd}>
              {lll.split("•").map((ele, index) => (
                <p
                  key={index}
                  style={{
                    paddingLeft: index > 0 ? "20px" : "",
                  }}
                >
                  {index === 0 ? "" : "• "}
                  {ele.includes("○")
                    ? ele.split("○").map((eee, ind) => (
                        <>
                          <span
                            key={ind}
                            style={{ paddingLeft: ind > 0 ? "20px" : "" }}
                          >
                            {ind === 0 ? "" : "• "}
                            {eee}
                          </span>
                          <br></br>
                        </>
                      ))
                    : ele}
                </p>
              ))}
            </label>
          </div>
        ))}
      </div>
*/
