import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import Card from "./Card";

const Collection = ({ collectionType, items, heading, users }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const cardType = collectionType.slice(0, collectionType.length - 1);
  const settings = {
    dots: false,
    navs: false,
    arrows: window.innerWidth > 835,
    autoplay: true,
    swipeToSlide: true,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: items.length < 4 ? items.length : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-content">
        <div className="container">
          {heading && (
            <div className="collection-heading">
              <h4>{heading}</h4>
            </div>
          )}
          {items?.length ? (
            <>
              {items.length < 4 ? (
                <div
                  className="experiences listings"
                  style={{ display: "flex" }}
                >
                  {items.map(
                    (item, index) =>
                      users && (
                        <Card
                          key={index}
                          content={item}
                          cardType="experience"
                          user={users.find((user) => user._id === item.partner)}
                        />
                      ),
                  )}
                </div>
              ) : (
                <Slider {...settings} className="slider_outer transform_y">
                  {items.map((item, index) =>
                    user ? (
                      <Card
                        key={index}
                        content={item}
                        cardType={cardType}
                        user={user}
                      />
                    ) : users ? (
                      <Card
                        key={index}
                        content={item}
                        cardType={cardType}
                        user={users.find((user) => user._id === item.partner)}
                      />
                    ) : (
                      <Card key={index} content={item} cardType={cardType} />
                    ),
                  )}
                </Slider>
              )}
            </>
          ) : (
            <p>
              {cardType === "experience"
                ? "No experiences available"
                : cardType === "partner"
                ? "No partners available"
                : "Not available"}
            </p>
          )}
          {userInfo &&
            userInfo.type === "partner" &&
            user &&
            user._id === userInfo._id && (
              <p style={{ margin: "60px 0" }}>
                <Link to="/add-experience" className="btn">
                  add new experience
                </Link>
              </p>
            )}
        </div>
      </div>
    </>
  );
};

export default Collection;
