import React from "react";
import Landing2 from "../components/Landing2";

const NotFoundScreen = () => {
  return (
    <Landing2
      bg="notfound"
      height="100vh"
      position="40%"
      horizontal="flex-start"
      vertical="center"
      heading="page not found"
      copy="It looks like this page has moved somewhere else. Come back to the homepage."
      btn="back to home"
      link=""
      color="yellow"
      cColor="beige"
    />
  );
};

export default NotFoundScreen;
