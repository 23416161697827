import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteContactMail,
  listContactMails,
} from "../actions/contactMailActions";
import { CONTACTMAIL_DELETE_RESET } from "../constants/contactMailConstants";

import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const AllMailsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const contactMailList = useSelector((state) => state.contactMailList);
  const { loading, error, contactMails } = contactMailList;

  const contactMailDelete = useSelector((state) => state.contactMailDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success,
  } = contactMailDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || (userInfo && userInfo.type !== "admin")) {
      history.push("/");
    } else {
      dispatch(listContactMails());
    }
  }, [dispatch, history, userInfo]);

  const deleteMailHandler = (e) => {
    e.preventDefault();
    dispatch(deleteContactMail(e.target.id));
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_DELETE_RESET });
        dispatch(listContactMails());
      }, 3000);
    }
  }, [success, dispatch]);

  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        {loadingDelete && <Loader />}
        {errorDelete && <Message body={errorDelete} />}
        {success && (
          <Message
            body={"Mail deleted successfully! Please wait for the reload."}
            type="info"
          />
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message body={error} />
        ) : contactMails && contactMails?.length ? (
          <>
            <h5 style={{ marginBottom: "20px" }}>
              all mails ({contactMails.length})
            </h5>
            <div className="user-list">
              {contactMails.map((mail) => (
                <div style={{ display: "flex", gap: "10px" }} key={mail._id}>
                  <Link key={mail._id} to={`/mail/${mail._id}`} className="btn">
                    <span>{mail.email}</span>
                    <span>{mail.subject}</span>
                    <span>
                      {mail.message.substring(0, 100)}
                      {mail.message.length > 100 && "..."}
                    </span>
                  </Link>
                  <span
                    className="btn"
                    style={{ width: "fit-content" }}
                    onClick={deleteMailHandler}
                    id={mail._id}
                  >
                    x
                  </span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="no-messages">No contact mails available</p>
        )}
      </div>
    </div>
  );
};

export default AllMailsScreen;
