import React from "react";

import Benefit from "../components/Benefit";
import Landing2 from "../components/Landing2";

import { benefits } from "../components/data";

const ForPartnersScreen = () => {
  return (
    <>
      <Landing2
        height="1080px"
        bg="adapting"
        position="30%"
        horizontal="flex-start"
        vertical="center"
        heading="Adapting Your Story to A New World"
        copy="Forget endless sales emails and taking focus away from programming. the Classroom Voyager is developing a teacher community from across BC and beyond. Our goal is to create connections between your organization and BC classrooms. Whether you offer field trips, presentations, or digital educational materials, we are here to promote you to a wider audience and support your mandate."
        btn="Join Today"
        link="account"
        color="beige"
        cColor="beige"
      />
      <div
        style={{
          backgroundImage: "url(./images/landing-benefits.png)",
          backgroundRepeat: "norepeat",
          backgroundSize: "cover",
          height: "fit-content",
        }}
      >
        <Landing2
          height="1080px"
          bg="strengthen"
          position="10%"
          horizontal="flex-start"
          vertical="flex-start"
          heading="Strength Through Community"
          copy="Classroom Voyager is a community platform first and foremost. By bringing together the unique stories that institutions and experts around the province have to share in one centralized place, we are able to work together to reach out to classrooms across British Columbia. Let us take care of attracting the attention of school districts and teachers so that you can focus on what you do best, producing exceptional educational programming."
          cWidth="700px"
          btn="Join Today"
          link="account"
          color="beige"
          cColor="beige"
        />
        <Landing2
          horizontal="flex-start"
          height="100px"
          customHeight="240px"
          position="-100%"
          vertical="flex-start"
          heading="Benefits of Partnership"
          color="red"
        />
        <div className="container">
          <div className="benefits">
            {benefits.map((benefit, index) => (
              <Benefit
                key={index}
                visual={`vrc-icons-grey-benefit-${index + 1}`}
                heading={benefit.heading}
                copy={benefit.copy}
              />
            ))}
          </div>
        </div>
        <Landing2
          height="450px"
          bg="database"
          position="5%"
          horizontal="flex-start"
          vertical="center"
          heading="A Database of Digital Teaching Resources"
          copy="A resource unlike any other. This database is populated by digital resources to help BC teachers with lesson planning. Engaging virtual tours, scavenger hunts, interactive quizzes, and digital workbooks, all meant to make learning fun. Access to this database is by student license and will increase your revenue potential to support your organization. To learn more about how you can create content for this database, get in touch."
          cWidth="700px"
          btn="contact"
          link="contact"
          color="yellow"
          cColor="beige"
        />
      </div>
    </>
  );
};

export default ForPartnersScreen;
