import grades from "./grades";
import experienceSubjects from "./experienceSubjects";

const bigIdeas = [
  {
    subject: experienceSubjects[0],
    data: [
      {
        grade: grades[0],
        ideas: [
          "People make art to show their identity, both as a person and as part of a community.",
          "Being engaged with the arts helps people find new ways to explore through purposeful play.",
          "Drama, dance, visual arts, and music all have their own ways of expressing meaning.",
          "People use the arts to share and connect with each other.",
        ],
      },
      {
        grade: grades[1],
        ideas: [
          "People make art to show their identity, both as a person and as part of a community.",
          "Being engaged with the arts helps people find new ways to explore through purposeful play.",
          "Drama, dance, visual arts, and music all have their own ways of expressing meaning.",
          "People use the arts to share and connect with each other.",
        ],
      },
      {
        grade: grades[2],
        ideas: [
          "Expressing ourselves in creative ways helps us figure out who we are.",
          "Learning through the arts involves taking risks.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "The arts allow people to understand the perspectives of people across time and space, providing insight into their hearts and minds.",
        ],
      },
      {
        grade: grades[3],
        ideas: [
          "People use their bodies and minds in tandem when creating art.",
          "A creative experience involves exploring, inquiring, and making intentional choices.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "The arts help us connect our own experiences to those of others.",
        ],
      },
      {
        grade: grades[4],
        ideas: [
          "Being able to express yourself creatively is a way of exploring and sharing who you are and where you fit in your community.",
          "Artists try lots of different things to uncover new artistic possibilities.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "Discovering and learning about different works of art helps us see a variety of values, knowledge, and perspectives.",
        ],
      },
      {
        grade: grades[5],
        ideas: [
          "Creative expression helps people build a better understanding of their own identities and feel a sense of belonging.",
          "Experimentation is a way for artists to find new possibilities and perspectives.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "Art of all kinds is in conversation with the world; it influences and is influenced by it.",
        ],
      },
      {
        grade: grades[6],
        ideas: [
          "Creative expression helps people build a better understanding of their own identities and their communities.",
          "The ways people express themselves artistically are different in different times and places.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "Discovering art helps people understand and empathize with the perspectives and experiences of other people and communities.",
        ],
      },
      {
        grade: grades[7],
        ideas: [
          "Peoples’ ideas about their identity and community shift constantly as they make art.",
          "Art helps us consider different points of view and understand others better.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "Making or experiencing art helps people understand and communicate complex ideas.",
        ],
      },
      {
        grade: grades[8],
        ideas: [
          "The arts provide an outlet for individual and collective expression.",
          "To grow as an artist, you must be patient and willing to try new approaches and take risks.",
          "Drama, dance, visual arts, and music function as unique ways to create and communicate.",
          "People who create art frequently push boundaries and help us discover new perspectives and experiences.",
        ],
      },
      {
        grade: grades[9],
        ideas: [
          "People explore and express their identities through arts experiences, and may find that these experiences change them.",
          "People can learn about, appreciate and understand the perspective and experiences of people from different times, places, and cultures through the arts.",
          "Drama, dance, visual arts and music have their own languages for creating and communicating with a variety of senses.",
          "Experiencing creative arts together can help build relationships and communities.",
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[1],
    data: [
      {
        grade: grades[0],
        ideas: [
          "Living things (plants and animals) have features we can observe.",
        ],
      },
      {
        grade: grades[1],
        ideas: [
          "Certain features and behaviours help living things survive in the environment they call home.",
        ],
      },
      {
        grade: grades[2],
        ideas: [
          "Different living things have different life cycles depending on what makes sense in their environment.",
        ],
      },
      {
        grade: grades[3],
        ideas: [
          "There are many different kinds of living things, and they interact with others in their ecosystems. We can group them.",
        ],
      },
      {
        grade: grades[4],
        ideas: [
          "Living things respond to and sense the environment around them.",
        ],
      },
      {
        grade: grades[5],
        ideas: [
          "Organ systems help multicellular organisms survive and interact with the environment around them.",
        ],
      },
      {
        grade: grades[6],
        ideas: [
          "Internal systems help multicellular organisms survive, reproduce and interact with the environment around them.",
        ],
      },
      {
        grade: grades[7],
        ideas: [
          "We can explain the diversity and survival of living things through a process of evolution by natural selection.",
        ],
      },
      {
        grade: grades[8],
        ideas: ["Life processes happen at the cellular level."],
      },
      {
        grade: grades[9],
        ideas: ["Cells come from cells."],
      },
      {
        grade: grades[10],
        ideas: [
          "Living things are diverse; that diversity starts with their DNA.",
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[2],
    data: [
      {
        grade: grades[0],
        ideas: [
          "All living things are affected by changes that occur daily and seasonally.",
        ],
      },
      {
        grade: grades[1],
        ideas: [
          "If we look at the local sky and landscape we will see patterns and cycles.",
        ],
      },
      {
        grade: grades[2],
        ideas: [
          "All living things need water. It moves through the environment in a cycle.",
        ],
      },
      {
        grade: grades[3],
        ideas: ["The shape of the land can be changed by wind, water and ice."],
      },
      {
        grade: grades[4],
        ideas: [
          "Earth and the moon move, and we can see the ways those movements affect both living and non-living systems.",
        ],
      },
      {
        grade: grades[5],
        ideas: [
          "We can use Earth materials as natural resources. They change as they move through the rock cycle.",
        ],
      },
      {
        grade: grades[6],
        ideas: [
          "There are billions of galaxies; our solar system is part of the Milky Way.",
        ],
      },
      {
        grade: grades[7],
        ideas: [
          "Over geological time, the earth and its climate have changed.",
        ],
      },
      {
        grade: grades[8],
        ideas: [
          "Earth’s geological processes can be explained through the unifying theory of plate tectonics.",
        ],
      },
      {
        grade: grades[9],
        ideas: [
          "Matter cycles and energy flows through the biosphere, geosphere, hydrosphere and atmosphere; they are all interconnected.",
        ],
      },
      {
        grade: grades[10],
        ideas: [
          "The Big Bang Theory can explain the creation of the universe.",
        ],
      },
    ],
  },
  {
    subject: experienceSubjects[3],
    data: [
      {
        grade: grades[0],
        ideas: [
          "Diverse communities are filled with different kinds of people who also have a lot in common.",
          "Individuals and families have stories and traditions about ourselves, and they help show who we are and where we are from.",
          "We shape our own identity and build healthy relationships with others through rights, roles and responsibilities.",
        ],
      },
      {
        grade: grades[1],
        ideas: [
          "People who care for the local environment are diverse, and healthy communities recognize and respect this diversity.",
          "Who we are and how we live shapes the local environment, and it shapes us as well.",
          "We need to have rights, roles and responsibilities to build a strong community.",
        ],
      },
      {
        grade: grades[2],
        ideas: [
          "In Canada we have lots of different and unique regions and communities.",
          "Actions we take locally have an impact on the rest of the world, and actions from around the world can be felt here at home.",
          "Individuals are global citizens with rights and responsibilities.",
        ],
      },
      {
        grade: grades[3],
        ideas: [
          "We can build multicultural awareness and learn to respect diversity by learning about Indigenous peoples.",
          "Oral history, traditions and collective memory are all ways that Indigenous knowledge is preserved and passed down to others.",
          "Around the world, Indigenous societies respect many different kinds of well-being: of the self, the land, the spirits and the ancestors.",
          "Even though people come from many different cultures and societies, they have some life experiences in common.",
        ],
      },
      {
        grade: grades[4],
        ideas: [
          "Canada’s identity has been, and continues to be, shaped by interactions between First Peoples and Europeans (both conflict and cooperation).",
          "People seeking gain by pursuing natural materials has had significant effects on Canada’s land, people and communities.",
          "Economic and political power in North America have shifted as the demographics of the content have changed.",
          "The way British Columbia became part of Canada was unique.",
        ],
      },
      {
        grade: grades[5],
        ideas: [
          "Multiculturalism and immigration help shape our society and identity as Canadians.",
          "Some regions of Canada still have economies and identities that are shaped by natural resources.",
          "The way Canada has treated and made policies about minority peoples has had both negative and positive legacies.",
          "The Canadian government and national institutions reflect challenges arising from the diversity of our regions.",
        ],
      },
      {
        grade: grades[6],
        ideas: [
          "Media sources affect the way we understand important events and issues, both positively and negatively.",
          "When people or governments act in their own economic self-interest, it can be a significant cause of conflict.",
          "Different systems of government have different levels of respect for human rights and freedoms.",
          "To deal with complex global problems, the international community will need to work together and make difficult choices.",
        ],
      },
      {
        grade: grades[7],
        ideas: [
          "Historical religious and cultural practices have lasted and still affect people today.",
          "Existing geographic conditions affected the way civilizations emerged.",
          "As societies became more complex, they needed new types of government and laws to function.",
          "When societies specialize in certain things and build trade networks, the results can be both conflict and cooperation.",
        ],
      },
      {
        grade: grades[8],
        ideas: [
          "Different groups of people have been affected in different ways by exploration, expansion and colonization.",
          "Population and living standards are influenced by both human and environmental factors.",
          "As ideas about the world changed, tension arose between people who were ready for new ideas and people who wanted to stick with existing traditions.",
          "When groups of people made contact with each other, this had wide-ranging effects including conflict and cultural, social and political changes.",
        ],
      },
      {
        grade: grades[9],
        ideas: [
          "Collective identity is something that gets built over time, and changes over time as well.",
          "Political, social and economic change are all affected by the physical environment they occur in.",
          "New ideas and ideologies have a big effect on societies and events.",
          "The inequitable distribution of power changes how relationships work, both for individuals and for societies.",
        ],
      },
      {
        grade: grades[10],
        ideas: [
          "Canada is sometimes seen as an inclusive, multicultural society, but this narrative isn’t always consistent with its historical and current injustices.",
          "Economy, social norms, ideology and geography all shape the development of political institutions.",
          "People have different perspectives and ideas about certain developments in Canadian society depending on their worldviews.",
          "Conflict, both global and regional, has been a powerful way of shaping the world as it is today.",
        ],
      },
    ],
  },
];

export default bigIdeas;
