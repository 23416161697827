import React from "react";

import { Link } from "react-router-dom";

const Landing = ({
  height,
  bg,
  vertical,
  horizontal,
  subheading,
  heading,
  hWidth,
  copy,
  cWidth,
  fullWidth,
  btn,
  link,
  color,
  cColor,
  btn2,
  link2,
}) => {
  return (
    <div
      className="landing"
      style={{
        height: height && height,
        backgroundImage: bg && `url(./images/landing-${bg}.png)`,
        alignItems: `${vertical}`,
      }}
    >
      <div
        className="container"
        style={
          window.innerWidth < 835
            ? {
                justifyContent: `center`,
              }
            : {
                justifyContent: `${horizontal}`,
              }
        }
      >
        <div
          className="landing-content"
          style={
            window.innerWidth < 835
              ? {
                  margin: "0 auto",
                }
              : {}
          }
        >
          <div>
            {subheading && (
              <h4
                style={
                  window.innerWidth < 835
                    ? {
                        width: window.innerWidth - 80,
                        textAlign: "center",
                      }
                    : {}
                }
              >
                {subheading}
              </h4>
            )}
            <h1
              style={
                window.innerWidth < 835
                  ? {
                      textAlign: "center",
                      color: `var(--var-color-vrc-${color})`,
                      width: window.innerWidth - 80,
                    }
                  : {
                      color: `var(--var-color-vrc-${color})`,
                      width: `${hWidth}`,
                    }
              }
            >
              {heading}
            </h1>
          </div>
          {copy && (
            <p
              style={
                window.innerWidth < 835
                  ? {
                      width: window.innerWidth - 80,
                      textAlign: "center",
                      color: `var(--var-color-vrc-${cColor})`,
                    }
                  : fullWidth
                  ? { width: "100%", color: `var(--var-color-vrc-${cColor})` }
                  : { width: cWidth, color: `var(--var-color-vrc-${cColor})` }
              }
            >
              {copy}
            </p>
          )}
          <div
            className="btn-container"
            style={window.innerWidth < 835 ? { justifyContent: "center" } : {}}
          >
            {btn && (
              <Link className="btn" to={link}>
                {btn}
              </Link>
            )}
            {btn2 && (
              <Link className="btn" to={link2}>
                {btn2}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
