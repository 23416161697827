export const MEDIA_LIST_REQUEST = "MEDIA_LIST_REQUEST";
export const MEDIA_LIST_SUCCESS = "MEDIA_LIST_SUCCESS";
export const MEDIA_LIST_FAIL = "MEDIA_LIST_FAIL";
export const MEDIA_LIST_RESET = "MEDIA_LIST_RESET";

export const MEDIA_UPLOAD_REQUEST = "MEDIA_UPLOAD_REQUEST";
export const MEDIA_UPLOAD_SUCCESS = "MEDIA_UPLOAD_SUCCESS";
export const MEDIA_UPLOAD_FAIL = "MEDIA_UPLOAD_FAIL";
export const MEDIA_UPLOAD_RESET = "MEDIA_UPLOAD_RESET";

export const MEDIA_DELETE_REQUEST = "MEDIA_DELETE_REQUEST";
export const MEDIA_DELETE_SUCCESS = "MEDIA_DELETE_SUCCESS";
export const MEDIA_DELETE_FAIL = "MEDIA_DELETE_FAIL";
export const MEDIA_DELETE_RESET = "MEDIA_DELETE_RESET";
