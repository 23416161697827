import React from "react";

const PrivacyPolicyScreen = () => {
  return (
    <div
      className="landing-generic"
      style={{
        backgroundImage: "url(/images/landing-generic.png)",
      }}
    >
      <div className="container">
        <div className="policies">
          <div>
            <h3>Privacy Policy</h3>
            <i>This Policy was last updated 08 April 2022.</i>
          </div>
          <p>
            Privacy of our partners, teachers, and students is top priority for
            Forager Education Ltd. (Forager Education) and the work we do
            through the Classroom Voyager platform. This platform is meant to be
            a community space to share Canada’s unique story, not a space for
            mining personal data. The only information collected is necessary
            for our partners and users to utilize the platform in the best
            manner. We will always protect and safeguard personal data. We are
            committed to transparency about what data we collect. This policy
            describes how we use and process personal data, provided in a
            readable and transparent manner.
          </p>
          <ul>
            <li>
              Types of Personal Data Collected
              <ul>
                <li>
                  User-Submitted Data
                  <ol>
                    <li>
                      <strong>Teacher Data:</strong> The information submitted
                      on the registration form is used to tailor content
                      relevent for each teacher’s grade level to avoid an
                      overwhelming volume of irrelevent content. Other data is
                      used for demographic statistics for Forager Education and
                      our institutional partners to understant their audiences.
                      Photos uploaded to teacher profiles are only for a
                      personal touch and are not used for any purposes other
                      than a profile image. Credit card information submitted
                      when bookings are made is not saved or stored on the Classroom Voyager servers.
                    </li>
                    <li>
                      Reviews submitted after a classroom experience are used
                      only for promotional purposes and attached to the listing
                      page to help inform other teachers about what to expect
                      from the specific listing.
                    </li>
                    <li>
                      <strong>Partner Data:</strong> The information submitted
                      on the registration form is used to build Partner Profiles
                      as a way to showcase the institution in a more in-depth
                      way to teachers and students. Banking information is only
                      used for depositing sales revenue.
                    </li>
                    <li>
                      <strong>School/District Data:</strong> The information
                      submitted on the registration form is used only to set up
                      a profile and associate relevant teachers to the same
                      account so that district or school users can see which
                      teachers in their network are using the platform.
                    </li>
                  </ol>
                </li>
                <li>
                  Data Given by Other Sources
                  <p>
                    Forager Education may receive information about users from
                    other sources. These include partners, such as regional
                    partners, other companies in the Forager International Ltd.
                    network, and other independent third parties. Data received
                    by others includes data about teachers and students that
                    schools or school districts submit on bulk registration from
                    their regions. This data is treated with the same privacy as
                    data submitted directly from the user. With teachers and
                    students being within the jurisdiction of these
                    organizations, the assumption is that they have the
                    appropriate authority to share this data with Forager
                    Education for the purposes of registration and usage of the Classroom Voyager platform. Anything received from these
                    partners may be combined with information provided by
                    individual users.
                  </p>
                </li>
                <li>
                  Data Collected Automatically
                  <p>
                    When a user visits the Classroom Voyager, Forager Education
                    automatically collects certain information, including IP
                    addresses, the date and time accounts are accessed, and
                    information about a user’s computer hardware and software
                    (ie. device, operating system, internet browser, and
                    language settings). Forager Education also collects
                    information about which pages are viewed and items that
                    users click on.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              Data Sharing
              <ul>
                <li>
                  Third Parties
                  <p>
                    Forager Education only shares relevant information with
                    third parties in relation to use of the Classroom Voyager
                    platform. These third parties include:
                  </p>
                  <ol>
                    <li style={{ listStyleType: "decimal" }}>
                      <strong>Institutional Partners:</strong> In order for
                      these partners to deliver their programming, information
                      on the teachers or classes that book with them are
                      provided. The extent of this information is limited to
                      name, grade, school, and number of students. Communication
                      between teachers and partners through the Classroom Voyager messaging system is only used for the purpose of
                      communication between these two parties.
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                      <strong>School Districts & Individual Schools:</strong>{" "}
                      These organizations have accounts that give them access to
                      usership of the platform for teachers and students within
                      their region. If it is an individual school, they will be
                      able to see data on the sales and usage of the platform by
                      teachers in their school. The same is for school districts
                      but broadens to teachers within their entire district.
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                      <strong>Ministry of Education:</strong> Basic user data
                      may be used from time to time to present broad demographic
                      and usage data to the Ministry of Education for the
                      purposes of promoting and integrating the platform more
                      within the provincial school system.
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                      <strong>Donors:</strong> Forager Education and our
                      partners approach potential donors from time to time.
                      Basic demographic data is used to showcase the potential
                      sponsorship opportunities for these donors.
                    </li>
                  </ol>
                </li>
                <li>
                  Forager International network
                  <p>
                    Forager Education is part of the Forager International
                    network. A main drive for the partnerships created through
                    Forager Education is to provide new support mechanisms that
                    benefit our partners. We may receive personal data from
                    other companies in the Forager International network, or
                    share partners’ personal data with them, for the following
                    purposes:
                  </p>
                  <ol>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      To provide support services (including to web, design,
                      marketing, collections management, VR production,
                      accounting, and admin) for the purpose of building
                      capacity within our partners
                    </li>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      To provide customer service
                    </li>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      To identify and investigate potential fraudulent
                      activities or data breaches
                    </li>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      For analytical and product improvement purposes
                    </li>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      To provide personalized offers or send marketing with your
                      consent or as otherwise permitted by the applicable laws
                    </li>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      For hosting, technical support, overall maintenance and
                      maintaining security of such shared data
                    </li>
                    <li style={{ listStyleType: "upper-alpha" }}>
                      To ensure compliance with applicable laws
                    </li>
                  </ol>
                  <p>
                    As applicable and unless otherwise indicated, Forager
                    Education relies on its legitimate interests to share and
                    receive personal data. For purpose G, Forager Education will
                    utilize this purpose in compliance with legal obligations
                    (such as lawful law enforcement requests).
                  </p>
                </li>
              </ul>
            </li>
            <li>
              Personal Data Belonging to Students
              <p>
                For the purposes of running the Classroom Voyager platform and
                to optimize the user experience for students, Forager Education
                only collects basic information for account and demographic
                purposes. This information includes:
              </p>
              <ol>
                <li>First name</li>
                <li>Last name, first initial</li>
                <li>Gender</li>
                <li>Grade</li>
                <li>Teacher</li>
                <li>School</li>
                <li>School district</li>
              </ol>
              <p>
                This information is provided by the school or school district
                during the initial student license activation process.
              </p>
            </li>
            <li>
              How to contact us
              <p>
                If you have any questions about this policy, please send an
                email to info@foragereducation.com. Our cookie statement may
                also be updated from time to time. If these updates are
                substantial, particularly relevant to you or impact your data
                protection rights, we’ll get in touch with you about them.
                However, we recommend that you visit this page regularly to stay
                up to date with any other (less substantial or relevant)
                updates.
              </p>
              <p>
                The best way to contact Forager Education is through each
                Partner’s Partner Manager. If no Partner Manager has been
                assigned, use partners.ca@foragereducation.com and an agent will
                be in touch. We are always looking for feedback on our services
                and would love to hear from our Partners.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyScreen;
