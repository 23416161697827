import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import {
  /*createNewUser, */ listUsers,
  login,
  register,
} from "../actions/userActions";
import cities from "../data/cities";
// import countries from "../data/countries";
import {
  correspondingProvinces,
  profileTags,
  techSupportEmail,
} from "../data/data";
import grades from "../data/grades";
import { awarenessChannels, profileCategories, provinces } from "./data";
import Loader from "./Loader";
import Message from "./Message";
import {
  USER_LOGIN_RESET,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,
} from "../constants/userConstants";

const AccountForm = () => {
  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo: success } = userRegister;

  const userList = useSelector((state) => state.userList);
  const {
    loading: loadingInstitutions,
    error: errorInstitutions,
    users: institutions,
  } = userList;

  useEffect(() => {
    if (error || success) {
      setTimeout(() => {
        if (success) resetFields();
        dispatch({ type: USER_REGISTER_RESET });
      }, 5000);
    }
  }, [error, success, dispatch]);

  // const userCreate = useSelector((state) => state.userCreate);
  // const {
  //   loading: loadingCreate,
  //   error: errorCreate,
  //   userInfo: success,
  // } = userCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { loading: loadingLog, error: errorLog } = userLogin;

  useEffect(() => {
    if (errorLog) {
      setTimeout(() => {
        dispatch({ type: USER_LOGIN_RESET });
      }, 5000);
    }
  }, [errorLog, dispatch]);

  const [accountAction, setAccountAction] = useState("login");

  const [type, setType] = useState("hide");
  const [emailLog, setEmailLog] = useState("");
  const [passwordLog, setPasswordLog] = useState("");

  const [name, setName] = useState("");
  const [bio, setBio] = useState(``);
  const [emailReg, setEmailReg] = useState("");
  const [category, setCategory] = useState("");
  const [landAcknowledgment, setLandAcknowledgment] = useState(``);
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Canada");
  const [phone, setPhone] = useState("");

  //
  const [school, setSchool] = useState("");
  const [schoolDistrict, setSchoolschoolDistrict] = useState("");
  const [grade, setGrade] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [province, setProvince] = useState("");
  const [channel, setChannel] = useState("hide");
  const [institution, setInstitution] = useState("hide");

  useEffect(() => {
    setInstitution("hide");
    if (channel === "Institution") {
      dispatch(listUsers("partner"));
    }
  }, [channel, dispatch]);

  //

  const [checkedTags, setCheckedTags] = useState(
    new Array(profileTags.length).fill(false),
  );
  const [selectedTags, setSelectedTags] = useState([]);

  const handleOnChangeTags = (positionTags) => {
    const updatedCheckedTags = checkedTags.map((itemTags, indexTags) =>
      indexTags === positionTags ? !itemTags : itemTags,
    );

    setCheckedTags(updatedCheckedTags);
  };

  useEffect(() => {
    setSelectedTags(
      profileTags.filter(
        (ele) => checkedTags[profileTags.indexOf(ele)] === true,
      ),
    );
  }, [checkedTags]);

  //

  const [loginActivitiy, setLoginActivity] = useState("login");
  const [registerActivitiy, setRegisterActivity] = useState("");

  const loginSwitch = () => {
    if (accountAction !== "login") {
      setAccountAction("login");
      setLoginActivity("login");
      setRegisterActivity(null);
      setType("hide");
    }
  };

  const regSwitch = () => {
    if (accountAction !== "register") {
      setAccountAction("register");
      setRegisterActivity("register");
      setLoginActivity(null);
      setType("hide");
    }
  };

  const typeHandler = (e) => {
    setType(e.target.value);
  };

  // Log
  const emailLogHandler = (e) => {
    setEmailLog(e.target.value);
  };
  const passwordLogHandler = (e) => {
    setPasswordLog(e.target.value);
  };

  // Reg
  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const bioHandler = (e) => {
    e.preventDefault();
    setBio(e.target.value);
  };

  const emailRegHandler = (e) => {
    setEmailReg(e.target.value);
  };

  const categoryHandler = (e) => {
    setCategory(e.target.value);
  };

  const landAcknowledgmentHandler = (e) => {
    e.preventDefault();
    setLandAcknowledgment(e.target.value);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const confirmedPasswordHandler = (e) => {
    setConfirmedPassword(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  // const countryHandler = (e) => {
  //   setCountry(e.target.value);
  // };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  //
  const schoolHandler = (e) => {
    setSchool(e.target.value);
  };

  const schoolDistrictHandler = (e) => {
    setSchoolschoolDistrict(e.target.value);
  };

  const gradeHandler = (e) => {
    setGrade(e.target.value);
  };

  const streetAddressHandler = (e) => {
    setStreetAddress(e.target.value);
  };

  const provinceHandler = (e) => {
    setProvince(e.target.value);
  };

  const channelHandler = (e) => {
    setChannel(e.target.value);
  };

  const institutionHandler = (e) => {
    setInstitution(e.target.value);
  };

  const resetFields = () => {
    setEmailReg("");
    setEmailLog("");
    setPassword("");
    setConfirmedPassword("");
    setWebsite("");
    setName("");
    setBio(``);
    setWebsite("");
    setCity("");
    setProvince("");
    setCountry("");
    setPhone("");
    setCategory("");
    setLandAcknowledgment("");
    setSchool("");
    setSchoolschoolDistrict("");
    setGrade("");
    setStreetAddress("");
    setProvince("");
    setCheckedTags(new Array(profileTags.length).fill(false));
    setSelectedTags([]);
    setChannel("hide");
    setInstitution("hide");
  };

  // login handler
  const submitLogHandler = (e) => {
    e.preventDefault();
    if (accountAction === "login") {
      if (type === "partner" || type === "teacher") {
        if (emailLog?.length && passwordLog?.length) {
          dispatch(login(emailLog, passwordLog, type));
        }
      }

      setEmailLog("");
      setPasswordLog("");
    }
  };

  // register handler
  const submitRegHandler = (e) => {
    e.preventDefault();

    try {
      if (
        !emailReg?.length ||
        !name?.length ||
        !password?.length ||
        !confirmedPassword?.length ||
        !phone?.length ||
        !website?.length ||
        !city?.length ||
        city === "hide" ||
        !country?.length
      ) {
        throw new Error("All fields marked with an * are required!");
      }

      if (type === "teacher") {
        if (
          !province?.length ||
          !school?.length ||
          !schoolDistrict?.length ||
          !grade?.length ||
          grade === "hide" ||
          !streetAddress?.length
        ) {
          throw new Error("All fields marked with an * are required!");
        }
      }

      if (type === "partner") {
        if (
          !bio?.length ||
          !category?.length ||
          category === "hide" ||
          !landAcknowledgment?.length
        ) {
          throw new Error("All fields marked with an * are required!");
        }

        if (!selectedTags?.length) {
          throw new Error("Please select at least one tag!");
        }
      }

      if (password !== confirmedPassword) {
        throw new Error("Passwords don't match!");
      }

      if (type === "partner") {
        const partner = {
          name,
          emailReg,
          bio,
          phone,
          website,
          password,
          category,
          landAcknowledgment,
          selectedTags,
          city,
          country,
        };

        dispatch(register(partner, type));
      }

      if (type === "teacher") {
        const teacher = {
          name,
          emailReg,
          phone,
          website,
          password,
          city,
          province,
          country,
          school,
          schoolDistrict,
          grade,
          streetAddress,
          channel: channel === "hide" ? "N/A" : channel,
          institution: institution === "hide" ? "N/A" : institution,
        };

        dispatch(register(teacher, type));
      }
    } catch (error) {
      dispatch({ type: USER_REGISTER_FAIL, payload: error.message });
    }
  };

  useEffect(() => {}, [accountAction]);

  return (
    <>
      {loading && <Loader />}
      {loadingLog && <Loader />}
      {loadingInstitutions && <Loader />}
      <div>
        {error && <Message body={error} />}
        {errorLog && <Message body={errorLog} />}
        {errorInstitutions && <Message body={errorInstitutions} />}
        {success && <Message body={success} type="info" />}
      </div>
      <div
        className="account-content"
        style={{
          height: "fit-content",
          paddingBottom: "100px",
        }}
      >
        <div className="controls">
          <div className="navigation">
            <button
              className={`btn ${loginActivitiy && "active"}`}
              onClick={loginSwitch}
            >
              login
            </button>
            <button
              className={`btn ${registerActivitiy && "active"}`}
              onClick={regSwitch}
            >
              register
            </button>
          </div>
        </div>
        {accountAction === "login" ? (
          <>
            <form onSubmit={submitLogHandler}>
              <div className="select">
                <select onChange={typeHandler}>
                  <option value="hide">choose {accountAction} type</option>
                  <option value="teacher">{accountAction} as a teacher</option>
                  <option value="partner">{accountAction} as a partner</option>
                </select>
              </div>
              {type !== "hide" && (
                <>
                  <input
                    type="email"
                    placeholder="your email address *"
                    onChange={emailLogHandler}
                    value={emailLog}
                    required
                  />
                  <input
                    type="password"
                    placeholder="your password *"
                    onChange={passwordLogHandler}
                    value={passwordLog}
                    required
                  />
                  <button
                    className="btn"
                    type="submit"
                    onSubmit={submitLogHandler}
                  >
                    {accountAction}
                  </button>
                  <Link className="lost-password" to="/lost-password">
                    Lost your password?
                  </Link>
                </>
              )}
            </form>
          </>
        ) : accountAction === "register" ? (
          <>
            <div
              style={{
                paddingBottom: "40px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <h1
                style={
                  window.innerWidth < 835
                    ? { width: "100%", textAlign: "center" }
                    : { width: "700px" }
                }
              >
                Become A Part of the Classroom Voyager Network!
              </h1>
              <p
                style={
                  window.innerWidth < 835
                    ? { textAlign: "center" }
                    : { textAlign: "justify" }
                }
              >
                Becoming the newest member to join the Classroom Voyager is easy
                and free. Fill out the form below to register as a teacher or a
                partner. If you are a prospective partner, one of our team will
                be in touch to get your experiences listed and help you plan
                exciting digital resources to publish on our growing resource
                database. By signing up, you will be added to our mailing list
                where you will receive updates about new features and updates to
                the platform. If you are a prospective teacher, one of our team
                will be in touch to get your account set up and explain how you
                can start utilizing the resources on our platform.
              </p>
            </div>
            <form onSubmit={submitRegHandler}>
              <div
                style={{
                  paddingLeft: "30px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <p>Fields marked with an * are required</p>
                <p className="disclaimer">
                  Your personal data will be used to support your experience
                  throughout this website, to manage access to your account, and
                  for other purposes described in our{" "}
                  <Link to="/privacy-policy">privacy policy</Link>.
                </p>
              </div>
              <div style={{ gap: "10px", alignItems: "flex-start" }}>
                <div className="col">
                  <div className="select">
                    <select onChange={typeHandler}>
                      <option value="hide">choose {accountAction} type</option>
                      <option value="teacher">
                        {accountAction} as a teacher
                      </option>
                      <option value="partner">
                        {accountAction} as a partner
                      </option>
                    </select>
                  </div>
                  {type !== "hide" && (
                    <>
                      <input
                        type="email"
                        placeholder="your email address *"
                        onChange={emailRegHandler}
                        value={emailReg}
                      />
                      <input
                        type="text"
                        placeholder="name *"
                        onChange={nameHandler}
                        value={name}
                        required
                      />
                      <input
                        type="password"
                        placeholder="password *"
                        onChange={passwordHandler}
                        value={password}
                        required
                      />
                      <div className="password-note">
                        <p>* Note:</p>
                        <p>
                          Password must be more than 8 characters length and
                          contain at least 1 UPPERCASE, 1 lowercase, 1 number,
                          and 1 special character
                        </p>
                      </div>
                      <input
                        type="password"
                        placeholder="confirm password *"
                        onChange={confirmedPasswordHandler}
                        value={confirmedPassword}
                        required
                      />
                      {type === "teacher" && (
                        <>
                          <input
                            type="text"
                            placeholder="school *"
                            onChange={schoolHandler}
                            value={school}
                            required
                          />
                          <div className="select">
                            <select onChange={channelHandler}>
                              <option value="hide">
                                How did you hear about us?
                              </option>
                              {awarenessChannels.map((channel, index) => (
                                <option key={index} value={channel}>
                                  {channel}
                                </option>
                              ))}
                            </select>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                {type !== "hide" && (
                  <div className="col">
                    {type === "teacher" && (
                      <input
                        type="text"
                        placeholder="school district *"
                        onChange={schoolDistrictHandler}
                        value={schoolDistrict}
                        required
                      />
                    )}
                    <input
                      type="text"
                      placeholder={`${
                        type === "teacher" ? "school " : ""
                      }website *`}
                      onChange={websiteHandler}
                      value={website}
                      required
                    />
                    {/* <div className="select">
                      <select onChange={countryHandler} defaultValue={country}>
                        <option value="hide">select country *</option>
                        {countries.map((ele, index) => (
                          <option key={index} value={ele.name}>
                            {ele.name}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    {type === "teacher" && (
                      <>
                        <input
                          type="text"
                          placeholder="street address *"
                          onChange={streetAddressHandler}
                          value={streetAddress}
                          required
                        />
                        <div className="select">
                          <select onChange={provinceHandler} value={province}>
                            <option value="hide">
                              select province/ territory *
                            </option>
                            {provinces.sort().map((prov, index) => (
                              <option key={index} value={prov}>
                                {prov}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                    <div className="select">
                      <select onChange={cityHandler} defaultValue={city}>
                        <option value="hide">select city *</option>
                        {cities
                          .filter((cityArray) =>
                            correspondingProvinces.includes(cityArray[1]),
                          )
                          .map((cityArray) => cityArray[0])
                          .map((ele, index) => (
                            <option key={index} value={ele}>
                              {ele}
                            </option>
                          ))}
                      </select>
                    </div>
                    <input
                      type="number"
                      placeholder="phone *"
                      onChange={phoneHandler}
                      value={phone}
                      required
                    />
                    {type === "partner" && (
                      <div className="select">
                        <select onChange={categoryHandler} value={category}>
                          <option value="hide">select category *</option>
                          {profileCategories.map((ele, index) => (
                            <option key={index} value={ele}>
                              {ele}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {type === "teacher" && (
                      <>
                        <div className="select">
                          <select onChange={gradeHandler} value={grade}>
                            <option value="hide">select grade *</option>
                            {grades.map((grade, index) => (
                              <option key={index} value={grade}>
                                {grade}
                              </option>
                            ))}
                          </select>
                        </div>
                        {channel === "Institution" && institutions?.length && (
                          <div className="select">
                            <select onChange={institutionHandler}>
                              <option value="hide">Select institution</option>
                              {institutions.map((institution, index) => (
                                <option
                                  key={index}
                                  value={institution.head.name}
                                >
                                  {institution.head.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              {type !== "hide" && (
                <>
                  {type === "partner" && (
                    <>
                      <textarea
                        onChange={bioHandler}
                        placeholder="bio *"
                        value={bio}
                      />
                      <input
                        type="text"
                        placeholder="land acknowledgment *"
                        onChange={landAcknowledgmentHandler}
                        value={landAcknowledgment}
                      />

                      <div
                        className="profile-tags-container"
                        style={{ alignItems: "flex-start" }}
                      >
                        <div>
                          <p style={{ fontWeight: "400" }}>select tags *</p>
                          <p style={{ fontWeight: "200", opacity: "50%" }}>
                            You have to select at least 1 tag from the following
                            list:
                          </p>
                        </div>
                        <div className="profile-tags">
                          {profileTags.map((ele, indexCores) => (
                            <div key={indexCores}>
                              <input
                                type="checkbox"
                                id={indexCores + 100}
                                name={indexCores}
                                checked={checkedTags[indexCores]}
                                onChange={() => handleOnChangeTags(indexCores)}
                              />
                              <label htmlFor={indexCores + 100}>
                                <p style={{ fontWeight: "400" }}>{ele}</p>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  <p className="disclaimer" style={{ paddingLeft: "30px" }}>
                    {type === "partner" ? (
                      <>
                        By filling out this partner registration form, you are
                        agreeing to a partnership with Forager Education through
                        the Classroom Voyager. Please read through the{" "}
                        <Link to="/partner-policy">partner policy</Link> to
                        fully understand our partnership program.
                      </>
                    ) : type === "teacher" ? (
                      <>
                        {/* By filling out this teacher registration form, you agree to the Classroom Voyager{" "}
                        <Link to="/privacy-policy">terms and conditions</Link>. */}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <button
                    className="btn"
                    type="submit"
                    onClick={submitRegHandler}
                  >
                    {accountAction}
                  </button>
                  <p style={{ paddingLeft: "30px" }}>
                    Need technical help?{" "}
                    <a href={`mailto:${techSupportEmail}`}>
                      Contact our support team
                    </a>
                  </p>
                </>
              )}
            </form>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AccountForm;
