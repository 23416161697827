import axios from "axios";

import {
  STRIPE_PUBLISHABLE_KEY_REQUEST,
  STRIPE_PUBLISHABLE_KEY_SUCCESS,
  STRIPE_PUBLISHABLE_KEY_FAIL,
} from "../constants/stripeConstants";

// get stripe publishable key
export const getStripePublishableKey = () => async (dispatch) => {
  try {
    dispatch({
      type: STRIPE_PUBLISHABLE_KEY_REQUEST,
    });

    const { data } = await axios.get(`/api/stripe/key`);

    dispatch({
      type: STRIPE_PUBLISHABLE_KEY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STRIPE_PUBLISHABLE_KEY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
